//import ApiService from './api.service'
//import { async } from 'q';
//import { API, graphqlOperation } from 'aws-amplify';
//import * as Queries from '../../graphql/queriesCustom';

// TODO: Delete this if possible

const ProfessionalService = {
  /*
  GetMyBookings: async function (status) {

    const requestData = {
      method: 'get',
      url: "api/Professional/GetMyBookings?Status=" + status,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    }

    try {
      return ApiService.customRequest(requestData);
    }
    catch (error) {
      console.log(error);
    }
  },

  // Get the Landing Page Data for a user
  GetLandingPageInfo: async function () {

    const requestData = {
      method: 'get',
      url: "api/Professional/GetLandingPageInfo",
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    }

    try {
      return API.graphql(graphqlOperation(Queries.getEmployerBrands, parameters));
    }
    catch (error) {
      console.log(error);
    }
  },

  GetBookingInfo: async function (bookingId) {

    const requestData = {
      method: 'get',
      url: "api/Professional/GetBookingInfo",
      data: { BookingId: bookingId },
      headers: {
        'Content-Type': 'application/json'
      }
    }

    try {
      return ApiService.customRequest(requestData);
    }
    catch (error) {
      console.log(error);
    }
  },

  GetEventInfo: async function (eventId,requestForPayment) {

    var url = "api/Professional/GetEventInfo?EventId=" + eventId;

    if (requestForPayment) {
      url += "&requestForPayment=" + requestForPayment;
    }

    const requestData = {
      method: 'get',
      url: url,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    }

    try {
      return ApiService.customRequest(requestData);
    }
    catch (error) {
      console.log(error);
    }
  },

  GetPersonalDetails: async function () {

    const requestData = {
      method: 'get',
      url: "api/Professional/GetPersonalDetails",
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    }

    try {
      return ApiService.customRequest(requestData);
    }
    catch (error) {
      console.log(error);
    }
  },

  GetCertificate: async function (bookingId) {

    const requestData = {
      method: 'get',
      url: "api/Professional/GetCertificate?BookingId=" + bookingId,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    }

    try {
      return ApiService.customRequest(requestData);
    }
    catch (error) {
      console.log(error);
    }
  },

  DownloadCertificate: async function (url) {

    const requestData = {
      method: 'get',
      url: url,
    }

    try {
      return ApiService.customRequest(requestData);
    }
    catch (error) {
      console.log(error);
    }
  },

  UploadCertificate: async function (formData) {

    const requestData = {
      method: 'post',
      url: "api/Professional/UploadCertificate",
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    try {
      return ApiService.customRequest(requestData);
    }
    catch (error) {
      console.log(error);
    }
  },

  SavePersonalDetails: async function (formData) {

    const requestData = {
      method: 'post',
      url: "api/Professional/SavePersonalDetails",
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    try {
      return ApiService.customRequest(requestData);
    }
    catch (error) {
      console.log(error);
    }
  },

  CancelBooking: async function (bookingId) {

    const requestData = {
      method: 'get',
      url: "api/Professional/CancelBooking?BookingId=" + bookingId,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    }

    try {
      return ApiService.customRequest(requestData);
    }
    catch (error) {
      console.log(error);
    }
  },

  BookEvent: async function (eventId, paymentIntentId) {

    const requestData = {
      method: 'get',
      url: "api/Professional/BookEvent?EventId=" + eventId + "&PaymentIntentId=" + paymentIntentId,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    }

    try {
      return ApiService.customRequest(requestData);
    }
    catch (error) {
      console.log(error);
    }
  },

  AddToWaitingList: async function (eventId) {

    const requestData = {
      method: 'get',
      url: "api/Professional/AddToWaitingList?EventId=" + eventId,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    }

    try {
      return ApiService.customRequest(requestData);
    }
    catch (error) {
      console.log(error);
    }
  },

  FilterEvent: async function (formData) {

    const requestData = {
      method: 'post',
      url: "api/Professional/FilterEvent",
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    try {
      return ApiService.customRequest(requestData);
    }
    catch (error) {
      console.log(error);
    }
  },

  GetMyNotifications: async function () {

    const requestData = {
      method: 'get',
      url: "api/Professional/GetMyNotifications",
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    }

    try {
      return ApiService.customRequest(requestData);
    }
    catch (error) {
      console.log(error);
    }
  },

  GetProviderContactDetails: async function (eventId) {

    const requestData = {
      method: 'get',
      url: "api/Professional/GetProviderContactDetails?EventId=" + eventId,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    }

    try {
      return ApiService.customRequest(requestData);
    }
    catch (error) {
      console.log(error);
    }
  },

  LoadStripe: async function (formData) {
    const requestData = {
      method: 'post',
      url: 'api/Professional/LoadStripe',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
       // 'Content-Type': 'application/json'
      }
    }

    try {
      return ApiService.customRequest(requestData);
    }
    catch (error) {
      console.log(error);
    }
  },

  GetProviderAboutDetails: async function (eventId) {

    const requestData = {
      method: 'get',
      url: "api/Professional/GetProviderAboutDetails?EventId=" + eventId,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    }

    try {
      return ApiService.customRequest(requestData);
    }
    catch (error) {
      console.log(error);
    }
  },

  GetMyCertificate: async function (eventCode) {

    const requestData = {
      method: 'get',
      url: "api/Professional/GetMyCertificate?EventCode=" + eventCode,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    }

    try {
      return ApiService.customRequest(requestData);
    }
    catch (error) {
      console.log(error);
    }
  },

  AddToCalendar: async function (EventId) {

    const requestData = {
      method: 'get',
      url: "api/Professional/AddToCalendar?EventId=" + EventId,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    }

    try {
      return ApiService.customRequest(requestData);
    }
    catch (error) {
      console.log(error);
    }
  },

  RetrieveSession: async function (sessionId,eventId) {
    const requestData = {
      method: 'get',
      url: "api/Professional/RetrieveSession?sessionId=" + sessionId + "&eventId=" + eventId,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    }

    try {
      return ApiService.customRequest(requestData);
    }
    catch (error) {
      console.log(error);
    }
  }
  */
}

export default ProfessionalService

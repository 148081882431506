<template>
  <v-container pa-0 ma-0 fluid v-if="is_data_fetched">
    <v-img :src="require('../images/contactdetailsbackground.png')" style="height:150px;" class="container-rounded-subheader">
      <v-layout>
        <v-flex xs12>
          <v-layout style="position:relative;">
            <v-flex x12>
              <v-layout row wrap>
                <v-flex>
                  <table style="width:97%;">
                    <tr>
                      <td style="width:10%">
                        <event-logo v-bind:imageKey="providerDetails.logo.imageKey" level="public" class="contact-details-logo" />
                      </td>
                      <td>
                        <div style="position:relative;margin-left:5px;vertical-align:bottom;bottom:-80px;">
                          <div style="float:left;font-size: 16px; font-weight: bold;">{{providerDetails.name}}</div>
                        </div>
                      </td>
                      <td>
                        <div class="personal-id-container">
                          <div class="personal-id"><strong>Provider ID: </strong>{{providerDetails.id}}</div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-img>
    <v-layout row wrap justify-center>
      <v-flex xs10>
        <h2 style="text-align:center;color:#045c5d;font-weight:700;">{{providerDetails.name}}</h2>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center>
      <v-flex xs10 md6 mt-3 style="font-weight:normal;">
        <div v-html="providerDetails.courseProviderDetails.description"></div>
      </v-flex>
    </v-layout>
    <br />
    <br />
    <v-layout row wrap justify-center>
      <v-flex xs5>
        <h5 style="text-align: center;color: rgb(4, 92, 93);font-size: 16px;font-weight: 700;">Why Choose {{providerDetails.name}}</h5>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center>
      <v-flex xs10 md6 mt-3 style="font-weight:normal;">
        <div v-html="providerDetails.courseProviderDetails.whyChooseUs"></div>
      </v-flex>
    </v-layout>
    <br />
    <v-layout row wrap justify-center>
      <v-flex xs12 md3 mt-3>
        <h2 style="text-align: center;color: rgb(4, 92, 93);font-weight: 700;">Our Partners / Accreditations</h2>
      </v-flex>
    </v-layout>
    <br />

    <v-layout justify-center>
      <v-flex xs12>
        <div class='accreditation-container' :style="getAccreditationGridLayout">
          <div v-for="(accreditation) in filteredAccreditations" v-bind:key="accreditation.id">
            <div class="accreditation-holder">
              <event-logo v-bind:imageKey="accreditation.imageKey" level="public" style="margin: auto;height:100px;width:100px;"/>
              <!--<p class="accreditation-name">{{ accreditation.name }}</p>-->
            </div>
          </div>
        </div>
      </v-flex>
    </v-layout>

    <v-layout justify-right>
      <v-flex xs12 class="text-center">
        <router-link :to="{ name: 'UserBookCourseDisplay', params: { eventId: $route.params.eventId } }" class="v-btn v-btn-secondary">
          <div style="text-align:center;font-size: 18px;">Back</div>
        </router-link>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
  //import ProfessionalService from '../services/professional.service'
  import EventService from '../services/event.service'
  //import 'swiper/dist/css/swiper.css'

  import EventLogo from './eventlogo-container'
  import { IMAGE_STATUS } from '@/constants/ImageConstants'
  //import { swiper, swiperSlide } from 'vue-awesome-swiper'

  export default {
    data() {
      return {
        is_data_fetched: false,
        windowWidth: window.innerWidth,
        eventIdProvided: false,
        errorFetchingDetails: false,
        swiperOption: {
          slidesPerView: 5,
          spaceBetween: 50,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          breakpoints: {
            1024: {
              slidesPerView: 4,
              spaceBetween: 40
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 10
            }
          }
        },
        /*
        providerDetails: {
          id: 0,
          personalId: 0,
          logo: '',
          fileName: '',
          organizationName: '',
          postCode: '',
          address: '',
          webSite: '',
          registrationNumber: '',
          registrationAddress: '',
        },
        aboutDetails: {
          id: 0,
          description: '',
          whyChooseUs: '',
          images: []
        }
        */
        providerDetails: {}
      }
    },

    methods: {
      onResize() {
        this.windowWidth = window.innerWidth
      }
    },

    components: {
      //swiper,
      //swiperSlide,
      'event-logo': EventLogo
    },

    computed: {
      companyId: function() {
        try { return this.$store.getters.employer.id } catch (e) { return '' }
      },
      filteredAccreditations: function() {
        try {
          return this.providerDetails.accreditationLogos.items.filter((accreditation) => {
            return accreditation.status === IMAGE_STATUS.LIVE || !accreditation.status
          })
        } catch (err) {
          console.warn("Unable to get provider accreditation logos")
          return []
        }
      },
      getAccreditationGridLayout: function() {
        try {
          const numAccreditationLogos = this.filteredAccreditations.length
          if (this.windowWidth < 420 ) { return `grid-template-columns: repeat(${numAccreditationLogos > 2 ? 2 : numAccreditationLogos}, minmax(100px, 1fr));` }
          if (this.windowWidth < 768 ) { return `grid-template-columns: repeat(${numAccreditationLogos > 3 ? 3 : numAccreditationLogos}, minmax(100px, 1fr));` }
          if (this.windowWidth < 1024 ) { return `grid-template-columns: repeat(${numAccreditationLogos > 5 ? 5 : numAccreditationLogos}, minmax(100px, 1fr));` }
          return `grid-template-columns: repeat(${numAccreditationLogos > 6 ? 6 : numAccreditationLogos}, minmax(100px, 1fr)); max-width: 50%;`
        } catch (err) {
          return ""
        }
      }
    },

    async mounted() {
      // Initialise the parameters
      var parameters = undefined

      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      })

      try {
        // Get the parameters from the router
        console.log(this.$route.params)
        if (this.$route.params.eventId) {
          this.eventIdProvided = true
          parameters = {
            id: this.$route.params.eventId
          }
        }

        // If there was an event id, fetch it's details
        if (parameters) {
          // Fetch the event details from the server
          const eventService = new EventService(this.$store)
          const providerDetails = await eventService.GetEventProviderDetails(parameters)
          console.log(providerDetails)
          this.providerDetails = providerDetails
          this.is_data_fetched = true;
          return
        }
      } catch (err) {
        console.warn("Unable to get event details:", err)
        this.errorFetchingDetails = true
      }

      // If there wasn't an event id, show an error
      this.is_data_fetched = true
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    }
  }
</script>


<style scoped>

  .readable-text {
    max-width: min(70ch, 80%) !important;
  }

  .v-btn {
    text-decoration: none;
    text-transform: capitalize;
  }
  /* style the container */

  body {
    background-color: rgba(243,246,248,1);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  .container-rounded-subheader {
    padding-left: 25px;
    margin-top: 0px;
    margin-bottom: 50px;
    min-height: 40px;
    overflow: visible;
  }

  .container-rounded-mini {
    background-color: #FFFFFF;
    padding-left: 50px;
    margin-left: 40px;
    margin-right: 40px;
    min-height: 40px;
  }

  .container-rounded {
    background-color: #FFFFFF;
    padding-left: 50px;
    margin: 40px;
    min-height: 350px;
  }

  .container-rounded-about {
    background-color: #FFFFFF;
    padding-left: 50px;
    margin: 40px;
    min-height: 500px;
  }

  .swiper-button-prev, .swiper-button-next {
    height: 24px;
  }

  .contact-details-logo {
    border: 2px solid #fafafa;
    position: relative;
    height: 60px;
    width: 60px;
    bottom: -95px;
    background: #fafafa
  }

  .personal-id-container {
    position: absolute;
    margin-left: 5px;
    vertical-align: bottom;
    bottom: 0;
    width: 100%;
    left: -15px;
  }

  .personal-id {
    float: left;
    font-size: 11px;
    font-weight: normal
  }

  .accreditation-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 1rem;
    margin: auto;
    justify-items: center;
  }

  .accreditation-holder {
    text-align: center;
    margin-bottom: 1em;
  }

  .accreditation-holder p {
    margin-top: 0.33em;
  }

  @media (min-width: 768px) {
    .container-rounded-subheader {
      margin: 0 40px 40px;
      padding-left: 50px;
    }

    .contact-details-logo {
      height: 100px;
      width: 100px;
      bottom: -75px;
    }

    .personal-id-container {
      position: relative;
      margin-left: 5px;
      vertical-align: bottom;
      bottom: -80px;
    }

    .personal-id {
      float: right;
    }
  }
</style>

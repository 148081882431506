
import { BOOKING_STATUS } from '../constants/BookingConstants'

export const ADDRESS_FIELDS = {
  LINE1: 'LINE1',
  LINE2: 'LINE2',
  LINE3: 'LINE3',
  CITY: 'CITY',
  REGION: 'REGION',
  POSTALCODE: 'POSTALCODE',
  COUNTRY: 'COUNTRY'
}

export const ADDRESS_FIELD_LOOKUP = {
  LINE1: 'line1',
  LINE2: 'line2',
  LINE3: 'line3',
  CITY: 'city',
  REGION: 'region',
  POSTALCODE: 'postalCode',
  COUNTRY: 'country'
}


export const EventDurationInMinutes = (event) => {
  try {
    const { startDateTime, endDateTime, attendanceHours } = event
    // If an attendance hours has been added to the event, return it in minutes
    if (attendanceHours > 0) {
      return Math.ceil(attendanceHours * 60)
    }
    const startDateObject = new Date(startDateTime)
    const endDateObject = new Date(endDateTime)
    return (endDateObject - startDateObject) / ( 1000 * 60 )
  } catch (err) {
    console.warn("Event provided without start or end time;", err)
    return 0
  }
}


export const EventCPDDurationInMinutes = (eventObject) => {
  return Math.ceil(eventObject.cpdHours * 60)
}


export const EventDateTimeStringToDate = (dateTimeString) => {
  try {
    const dateObject = new Date(dateTimeString)
    return dateObject.toDateString()
  } catch (err) {
    console.warn("Cannot get date from event")
    return "Unknown"
  }
}

export const EventDateTimeStringToTime = (dateTimeString) => {
  try {
    const dateObject = new Date(dateTimeString)
    return dateObject.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' } )
  } catch (err) {
    console.warn("Cannot get time from event")
    return "Unknown"
  }
}


export const FormatAddressObjectToString = (address, fieldsToAdd) => {
  const { line1, line2, line3, city, region, postalCode, country } = address
  var fieldsShouldShow = [
    ADDRESS_FIELDS.LINE1,
    ADDRESS_FIELDS.LINE2,
    ADDRESS_FIELDS.LINE3,
    ADDRESS_FIELDS.CITY,
    ADDRESS_FIELDS.REGION,
    ADDRESS_FIELDS.POSTALCODE,
    ADDRESS_FIELDS.COUNTRY
  ]
  if (fieldsToAdd) { fieldsShouldShow = fieldsToAdd }

  var addressString = ''
  if (line1 && fieldsShouldShow.includes(ADDRESS_FIELDS.LINE1)) { addressString = addressString.length > 0 ? `${addressString}, ${line1}` : `${line1}` }
  if (line2 && fieldsShouldShow.includes(ADDRESS_FIELDS.LINE2)) { addressString = addressString.length > 0 ? `${addressString}, ${line2}` : `${line2}` }
  if (line3 && fieldsShouldShow.includes(ADDRESS_FIELDS.LINE3)) { addressString = addressString.length > 0 ? `${addressString}, ${line3}` : `${line3}` }

  if (city && fieldsShouldShow.includes(ADDRESS_FIELDS.CITY)) { addressString = addressString.length > 0 ? `${addressString}, ${city}` : `${city}` }
  if (region && fieldsShouldShow.includes(ADDRESS_FIELDS.REGION)) { addressString = addressString.length > 0 ? `${addressString}, ${region}` : `${region}` }
  if (postalCode && fieldsShouldShow.includes(ADDRESS_FIELDS.POSTALCODE)) { addressString = addressString.length > 0 ? `${addressString}, ${postalCode}` : `${postalCode}` }
  if (country && fieldsShouldShow.includes(ADDRESS_FIELDS.COUNTRY)) { addressString = addressString.length > 0 ? `${addressString}, ${country}` : `${country}` }

  return addressString
}

export const UserIsAlreadyBookedOntoEvent = (userId, eventId, userBookings) => {
  const { items } = userBookings
  return items.filter((item) => {
    return item.status === BOOKING_STATUS.BOOKED && item.event.id === eventId
  }).length > 0
}

export const UserIsOnWaitingList = (userId, eventId, userBookings) => {
  const { items } = userBookings
  return items.filter((item) => {
    return item.status === BOOKING_STATUS.ONWAITINGLIST && item.event.id === eventId
  }).length > 0
}

export const GetInstructorsName = (event) => {
  try {
    const { firstName, lastName } = event.instructor
    return `${firstName} ${lastName}`
  } catch (err) {
    console.warn("Error getting instructor's name for event:", err)
    return ""
  }
}

export const AvailableSeats = (event) => {
  const { attendees, maximumAttendees } = event
  const bookedSeats = attendees.items.length
  if (bookedSeats > maximumAttendees) { return 0 }
  return maximumAttendees - bookedSeats
}

export const EventTypeToDisplayText = {
  FREE: 'Free',
  PAID: 'Paid',
  CONFERENCE: 'Conference'
}

export function GenerateRandomEventCode() {
  const letterString = getRandomLetterCharacterString(4)
  const numberString = getRandomNumberCharacterString(4)
  return `${letterString}-${numberString}`
}

function getRandomLetterCharacterString(length) {
  var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var result = '';
  for ( var i = 0; i < length; i++ ) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return result;
}

function getRandomNumberCharacterString(length) {
  var randomChars = '0123456789';
  var result = '';
  for ( var i = 0; i < length; i++ ) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return result;
}

import { render, staticRenderFns } from "./provider-allcourses.vue?vue&type=template&id=4d846cd5&scoped=true"
import script from "./provider-allcourses.vue?vue&type=script&lang=js"
export * from "./provider-allcourses.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d846cd5",
  null
  
)

export default component.exports
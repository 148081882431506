<template>
  <v-container class='screen-content' justify-center align-content-center>

    <!-- White box -->
    <v-container class='white-box' xs12>
      <v-layout grid-container login-container>

        <!-- Left column -->
        <v-flex text-center left-shadow class="left-content">
          <img :src="logoImage" class="logo" />

          <div v-if='!isFISHost' class="welcome">
            <h4>Welcome to MyProPass</h4>
            <span>Your professional passport - Let your career take off</span>
          </div>
          <div v-if='isFISHost' class="welcome">
            <h4>Welcome to your FIS Competency Passport</h4>
          </div>

          <!-- Powered by MyProPass Holder -->
          <div v-if='isFISHost' class="powered-by-mpp">
            <p>Powered by</p>
            <img src="@/assets/images/logo.png" class="powered-by-logo" />
          </div>
        </v-flex>

        <!-- Right column -->
        <v-flex right-shadow class='right-box'>
          <v-flex class="right-container xs12 md8 offset-md-2">
            <form id="login" class="form-horizontal" @submit.prevent="login">
              <v-layout justify-center>
                <h5 style="color: #007AFF;font-size: 30px;margin-top:30px;">Log In</h5>
              </v-layout>

              <br />
              <div>
                <div class="form-group">
                  <label for="email">Email</label>
                  <input id="Email" type="email" placeholder="Email" class="col-12 form-control" v-model="email"/>
                </div>
                <div class="form-group">
                  <label for="password">Password:</label>
                  <input id="password" type="password" placeholder="Password" class="col-12 form-control" v-model="password"/>
                </div>
                <div class="form-group">
                  <input id="rememberMe" type="checkbox" v-model="rememberMe" style="width:16px; height:16px;vertical-align:middle;margin-right:1em;" />
                  <label class="control-label" style="margin-bottom:0;">Remember Me</label>
                </div>

              </div>
              <v-layout justify-end style="font-weight:normal;padding: 0 10px">
                <div style="color:rgb(0,171,173);float:left;width:50%">
                  <router-link :to="{ name: 'UserForgotPassword' }">
                    <div style="color:#a3a3a3;font-size:12px;">Forgot Password?</div>
                  </router-link>
                </div>
                <div justify-center style="font-weight:normal;float:right;width:50%;text-align: right">
                  <router-link :to="{ name: 'UserSignUp' }">
                    <div style="color:#a3a3a3;font-size:12px;">Not a Member yet?</div>
                  </router-link>
                </div>
              </v-layout>
              <div style="text-align: center;">
                <button class="btn btn-primary btn-block" type="submit" style="background-color: #F5740E;border: none;height: 60px;border-radius: 6px;width: 180px;font-size: 16px;font-weight: bold;color:#fff;margin: 30px auto">
                  Sign In
                </button>
                <!--
                <div class="text-center" style="font-size: 12px;font-weight: normal;color: #666;">or</div><br />
                -->
              </div>

                <v-layout justify-center style="font-weight:normal;">

                  <!--
                  <button class='google-button' v-on:click.prevent="GoogleSignIn"><img src="@/assets/images/btn_google_signin_light_normal_web.png" /></button>

                  <v-layout justify-center>
                    <a style="margin: 5px 10px"
                    asp-action="ExternalLogin"
                    asp-route-role="Professional"
                    asp-route-provider="Facebook"
                    asp-route-returnUrl="@Model.ReturnUrl">
                    <img src="/images/facebook32b.png" />
                  </a>
                  <a style="margin: 5px 10px"
                    asp-action="ExternalLogin"
                    asp-route-role="Professional"
                    asp-route-provider="Twitter"
                    asp-route-returnUrl="@Model.ReturnUrl">
                    <img src="/images/twitter32b.png" />
                  </a>
                  <a style="margin: 5px 10px"
                    asp-action="ExternalLogin"
                    asp-route-role="Professional"
                    asp-route-provider="LinkedIn"
                    asp-route-returnUrl="@Model.ReturnUrl">
                    <img src="/images/linkedin32b.png" />
                  </a>
                </v-layout>
                -->

              </v-layout>
              <!--
              <v-layout justify-center style="font-weight:normal;">
                <button class="btn btn-primary btn-block" type="submit" style="background-color:rgb(0,171,173);border:none;display:none;">
                  Continue as Guest
                </button>
              </v-layout>
              -->
              <br />
            </form>
          </v-flex>
        </v-flex>

      </v-layout>
    </v-container>

    <!-- Popover box -->
    <v-container>
      <v-dialog persistent v-model="messageDialog.show" transition="dialog-transition" max-width="350">
        <v-card>
          <v-card-text>
            <v-flex>
              <span style="font-weight:normal;" v-html="messageDialog.message"></span>
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="CloseMessageDialog" small color=rgb(0,171,173)>OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- Popover box -->
    <v-container>
      <v-dialog persistent v-model="actionDialog.show" transition="dialog-transition" max-width="350">
        <v-card>
          <v-card-text>
            <v-flex>
              <span style="font-weight:normal;" v-html="actionDialog.message"></span>
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="CloseActionDialog" small color=rgb(0,171,173)>No</v-btn>
            <v-btn @click="PerformActionDialogAction" small color=rgb(0,171,173)>Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- Loading overlay -->
    <v-container>
      <loading :active.sync="isLoggingIn" :is-full-page="true" loader="dots"></loading>
    </v-container>

  </v-container>
</template>

<script>

  import { Auth } from 'aws-amplify';
  import * as StoreActions from '@/constants/StoreActions'
  import AuthService from '@/services/auth.service'
  import UserService from '@/services/user.service'
  import { isFISHost } from '@/constants/Validations'

  const REQUEST_ACTIONS = {
    SendConfirmationEmail: 'SendConfirmationEmail',
    SendTemporaryPassword: 'SendTemporaryPassword'
  }

  export default {
    name: 'Login',
    data() {
      return {
        email: '',
        password: '',
        rememberMe: true,
        isLoggingIn: false,
        messageDialog: {
          show: false,
          message: ""
        },
        actionDialog: {
          show: false,
          message: "",
          action: null
        },
        isFISHost
      }
    },

    methods: {
      async login() {
        this.isLoggingIn = true;
        try {
          // Attempt to sign the user in using Cognito
          const parameters = {
            email: this.email,
            password: this.password
          }
          const user = await AuthService.SignIn(parameters);
          // On success, print the details (for debugging)
          //console.log('user', user);
          //alert('Successfully logged in');
          this.saveUserAndLogin(user, true)
        } catch (error) {
          console.warn("Error signing in:", error)
          // If this is a password reset error, ask them to check their emails
          if (error.code === "PasswordResetRequiredException") {
            this.PasswordResetRequiredException()
            return
          }
          // If this is a password reset error, ask them to check their emails
          if (error.code === "UserNotConfirmedException") {
            this.UserNotConfirmedException()
            return
          }
          // If the temporary password has expired, send a new password to the user
          if (error.code === "NotAuthorizedException" && error.message === "Temporary password has expired and must be reset by an administrator.") {
            this.TemporaryPasswordExpiredException()
            return
          }
          // If this isn't a captured error, ask them to try again
          this.messageDialog.message = "<div><p>There was an error signing in.  Please check your username and password and try again.</p><p>If the problem still persists, please check your emails for a reset password link.</p></div>"
          this.messageDialog.show = true
          this.isLoggingIn = false;
        }
      },

      PasswordResetRequiredException() {
        this.messageDialog.message = "<div><p>You have requested a password reset.  Please check your emails for the password reset email.</p><p>If you need to reset your password again, please use the reset password link below.</p></div>"
        this.messageDialog.show = true
        this.isLoggingIn = false;
      },

      UserNotConfirmedException() {
        this.actionDialog.message = "<div><p>Your account has not been confirmed yet.  Please check your emails, including the spam folder, for the confirmation link.</p><p>Would like another confirmation email sent to you?</p></div>"
        this.actionDialog.action = REQUEST_ACTIONS.SendConfirmationEmail
        this.actionDialog.show = true
        this.isLoggingIn = false
      },

      TemporaryPasswordExpiredException() {
        this.actionDialog.message = "<div><p>Your temporary password has expired.  You will require a new temporary password to proceed.</p><p>Would like another temporary password emailed to you?</p></div>"
        this.actionDialog.action = REQUEST_ACTIONS.SendTemporaryPassword
        this.actionDialog.show = true
        this.isLoggingIn = false
      },

      ShowErrorDialog() {
        this.messageDialog.message = "There was an error processing your request. Please try again."
        this.messageDialog.show = true
        this.isLoggingIn = false;
      },

      async PerformActionDialogAction() {
        this.isLoggingIn = true
        if (this.actionDialog.action === REQUEST_ACTIONS.SendConfirmationEmail) {
          try {
            const response = await AuthService.RequestResendConfirmationEmail({ email: this.email })
            const result = response.data.resendConfirmationEmail
            if (result.statusCode > 299) { throw new Error(result.body) }
            this.messageDialog.message = "<div><p>A new confirmation email has been sent to you.  Please check your emails and click the confirmation link.</p></div>"
            this.messageDialog.show = true
            this.actionDialog.message = ''
            this.actionDialog.action = null
            this.actionDialog.show = false
            this.isLoggingIn = false
            return
          } catch (err) {
            console.warn("There was an error resending the confirmation email!", err)
            this.ShowErrorDialog()
          }
        }

        if (this.actionDialog.action === REQUEST_ACTIONS.SendTemporaryPassword) {
          try {
            const response = await AuthService.ResendUserTemporaryPassword({ email: this.email })
            const result = response.data.resendTemporaryPassword
            if (result.statusCode > 299) { throw new Error(JSON.parse(result.body)) }
            this.messageDialog.message = "<div><p>A new temporary password email has been sent to you.  Please check your emails and click the link.</p></div>"
            this.messageDialog.show = true
            this.actionDialog.message = ''
            this.actionDialog.action = null
            this.actionDialog.show = false
            this.isLoggingIn = false
            return
          } catch (err) {
            console.warn("There was an error resending the temporary password!", err)
            this.ShowErrorDialog()
          }
        }
        this.isLoggingIn = false
      },

      async saveUserAndLogin(cognito, alertUser) {
        //console.log("saveUserAndLogin:", cognito, alertUser)
        try {
          // Store the cognito information in the store
          this.$store.commit(StoreActions.SET_COGNITO, cognito);
          // Make a call to the user service to get the users details from the database
          // Fetch all the events that are currently available
          const userService = new UserService(this.$store)
          await userService.GetDetails(cognito.username)
        } catch (err) {
          console.warn("Error getting user details:", err)
          if (alertUser) {
            alert(`Unable to Login: Could not fetch data from server.  Please try again.`);
          }
          this.isLoggingIn = false;
          return
        }

        // Ask the router to navigate to the right page
        // If the user tried to access a secured page before, send them to that
        const lastRequiresAuthPage = { ...this.$store.getters.lastRequiresAuthPage }
        //console.log(lastRequiresAuthPage)
        if (lastRequiresAuthPage.name) {
          this.$router.push({
            name: lastRequiresAuthPage.name,
            params: lastRequiresAuthPage.params,
            query: lastRequiresAuthPage.query
          })
          return
        }
        // Otherwise, send them to the UserLanding page
        this.$router.push({ name: 'UserLanding' })
      },
      CloseMessageDialog() {
        this.messageDialog = {
          show: false,
          message: ""
        }
      },
      CloseActionDialog() {
        this.actionDialog = {
          show: false,
          message: "",
          action: null
        }
      },

      async GoogleSignIn(e) {
        console.log("GoogleSignIn:", e)
        const result = await Auth.federatedSignIn({ provider: 'Google' })
        console.log(result)
      }
    },

    computed: {
      logoImage: function() {
        let imageName = "logo"
        if (this.isFISHost) { imageName = "logo-fis" }
        return require(`@/assets/images/${imageName}.png`)
      }
    },

    async mounted() {
      try {
        this.isLoggingIn = true
        const user = await AuthService.GetCurrentAuthenticatedUser();
        //console.log(user)
        //console.log("User is already logged in")
        await this.saveUserAndLogin(user, false)
      } catch (err) {
        //console.log("User is not logged in:", err)
      }
      this.isLoggingIn = false
    }
  }
</script>

<style scoped>

</style>

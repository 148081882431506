
const DateHelper = {

  FormatDate(date) {
    if (!date || !isNaN(date.split('-').length)) return '';
    const [year, month, day] = date.split('-')
    if (!year || !month) {
      return this.FormatDate2(date);
    }
    return `${day}/${month}/${year}`
  },

  FormatDate2(date) {
    if (!date) return null;
    if (!date || !isNaN(date.split('/').length)) return '';
    const [day, month, year] = date.split('/')
    if (!year || !month) {
      return this.FormatDate(date);
    }
    return `${day}/${month}/${year}`
  },

  ParseDate(date) {
    if (!date || !isNaN(date.split('/').length)) return '';

    const [day, month, year] = date.split('/')
    if (!year || !month) {
      return this.ParseDate2(date);
    }
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  },

  ParseDate2(date) {
    if (!date || !isNaN(date.split('-').length)) return '';

    const [day, month, year] = date.split('-')
    if (!year || !month) {
      return this.ParseDate(date);
    }
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  },
}

export default DateHelper

export const GetDateFromDateTime = (dateTimeString) => {
  // TODO: FUTURE: Add some error handling to this
  const dateObject = new Date(dateTimeString)
  return dateObject.toDateString()
}

export const GetTimeFromDateTime = (dateTimeString) => {
  // TODO: FUTURE: Add some error handling to this
  const dateObject = new Date(dateTimeString)
  return dateObject.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' } )
}

export const GetLocaleDateFromDateTime = (dateTimeString) => {
  // TODO: FUTURE: Add some error handling to this
  const dateObject = new Date(dateTimeString)
  return dateObject.toLocaleDateString()
}

export const GetLocaleDateTimeFromDateTime = (dateTimeString) => {
  // TODO: FUTURE: Add some error handling to this
  //console.log("dateTimeString:", dateTimeString)
  const dateObject = new Date(dateTimeString)
  return dateObject.toLocaleString()
}

export function DateInThePast(dateTimeString) {
  return (new Date(dateTimeString)) < new Date()
}

<template>
  <v-container pa-0 ma-0 fluid v-if="is_data_fetched" class="vld-parent">
    <loading :active.sync="isLoading" :is-full-page="fullPage" loader="dots"></loading>
    <v-layout ma-0 justify-center align-content-center>
      <v-flex xs12>
        <v-container fluid grid-list-md>

          <v-layout grid-container column pa-2 ma-0>
            <v-flex md1>
              <v-layout>
                <v-flex xs12 md6>
                  <h5 class="page-header">System Statistics</h5>
                </v-flex>
              </v-layout>
              <v-layout>

                <v-flex class='date-filter-item' grid-search xs12 md3>
                  <div>Start Date:</div>
                  <input type='date' v-model="startDate" class='form-control' />
                </v-flex>

                <v-flex class='date-filter-item' grid-search xs12 md3>
                  <div>End Date:</div>
                  <input type='date' v-model="endDate" class='form-control' />
                </v-flex>

                <v-flex class='date-filter-item' grid-search xs12 md3>
                  <div>Period Length:</div>
                  <v-select
                    v-model="periodLength"
                    v-bind:items="Object.keys(PERIOD_OPTIONS)"
                    background-color="white"
                    hide-details
                    outlined={true}
                    class="p-1"
                    style='margin-top: 0; border: 1px solid #ccc;'>
                  </v-select>
                </v-flex>

                <v-flex class='date-filter-item' grid-search xs12 md3>
                  <div>Metric to show:</div>
                  <v-select
                    v-model="metric"
                    v-bind:items="Object.keys(METRIC_OPTIONS)"
                    background-color="white"
                    hide-details
                    outlined={true}
                    class="p-1"
                    style='margin-top: 0; border: 1px solid #ccc;'>
                  </v-select>
                </v-flex>

              </v-layout>
            </v-flex>

            <v-flex md10 class='admin-statistics-data-table'>

              <admin-statistic-user-status-counts
                :startDateValue='startDateValue'
                :endDateValue='endDateValue'
                :periodLength='periodLength'
                :metric='metric'>
              </admin-statistic-user-status-counts>

              <admin-statistic-events-created-per-provider
                :startDateValue='startDateValue'
                :endDateValue='endDateValue'
                :periodLength='periodLength'
                :metric='metric'>
              </admin-statistic-events-created-per-provider>

              <admin-statistic-certificates-issued-per-provider
                :startDateValue='startDateValue'
                :endDateValue='endDateValue'
                :periodLength='periodLength'
                :metric='metric'>
              </admin-statistic-certificates-issued-per-provider>

              <admin-statistic-certificates-by-user
                :startDateValue='startDateValue'
                :endDateValue='endDateValue'
                :periodLength='periodLength'
                :metric='metric'>
              </admin-statistic-certificates-by-user>

            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>

    </v-layout>
  </v-container>
</template>



<script>

  import EmployerService from '@/services/employer.service'

  import AdminStatisticsUserStatusCounts from '@/components/admin-statistic-user-status-counts'
  import AdminStatisticsCertificatesByUser from '@/components/admin-statistic-certificates-by-user'
  import AdminStatisticsCertificatesPerProvider from '@/components/admin-statistic-certificates-issued-per-provider'
  import AdminStatisticsEventsCreatedPerProvider from '@/components/admin-statistic-events-created-per-provider'
  import { METRIC_OPTIONS, PERIOD_OPTIONS } from '@/constants/StatisticsConstants'

  export default {

    components: {
      'admin-statistic-user-status-counts': AdminStatisticsUserStatusCounts,
      'admin-statistic-certificates-by-user': AdminStatisticsCertificatesByUser,
      'admin-statistic-certificates-issued-per-provider': AdminStatisticsCertificatesPerProvider,
      'admin-statistic-events-created-per-provider': AdminStatisticsEventsCreatedPerProvider
    },

    data() {

      const startDateTime = new Date()
      //startDateTime.setUTCFullYear(startDateTime.getUTCFullYear() - 1)
      //startDateTime.setUTCMonth(0)
      startDateTime.setUTCDate(startDateTime.getUTCDate() - 7)
      startDateTime.setUTCHours(0)
      startDateTime.setUTCMinutes(0)
      startDateTime.setUTCSeconds(0)
      startDateTime.setUTCMilliseconds(0)

      const endDateTime = new Date()
      endDateTime.setUTCDate(endDateTime.getUTCDate() - 1)
      endDateTime.setUTCHours(23)
      endDateTime.setUTCMinutes(59)
      endDateTime.setUTCSeconds(59)
      endDateTime.setUTCMilliseconds(999)

      return {
        is_data_fetched: false,
        isLoading: false,
        errorLoadingData: false,
        fullPage: true,
        searchText: '',
        pagination: {
          rowsPerPage: 10,
          totalItems: 0,
          sortBy: 'employeeName',
          sortDesc: true,
          page: 1,
        },
        employeeRecords: {
          items: [],
          nextToken: null
        },
        startDateValue: startDateTime.toISOString(),
        endDateValue: endDateTime.toISOString(),
        periodLength: PERIOD_OPTIONS.DAILY,
        metric: METRIC_OPTIONS.DELTA,
        PERIOD_OPTIONS,
        METRIC_OPTIONS,
      }
    },



    methods: {
    },

    computed: {
      startDate: {
        get: function() {
          const dateObject = new Date(this.startDateValue)
          return dateObject.toISOString().split('T')[0]
        },
        set: function(newValue) {
          const oldDateStringParts = this.startDateValue.split('T')
          const newDateString = `${newValue}T${oldDateStringParts[1]}`
          //console.log("start date newDateString:", newDateString)
          this.startDateValue = newDateString
        }
      },
      endDate: {
        get: function() {
          const dateObject = new Date(this.endDateValue)
          return dateObject.toISOString().split('T')[0]
        },
        set: function(newValue) {
          const oldDateStringParts = this.endDateValue.split('T')
          const newDateString = `${newValue}T${oldDateStringParts[1]}`
          //console.log("end date newDateString:", newDateString)
          this.endDateValue = newDateString
        }
      },
    },

    async mounted() {
      try {
        const employerService = new EmployerService(this.$store)
        this.employeeRecords = await employerService.GetAllEmployeesAndTrainingRecordsForEmployer()
        //console.log("employeeRecords:", this.employeeRecords)
        this.$parent.$parent.$parent.refreshMenu('employeeTrainingRecords');
      } catch (err) {
        console.warn("error loading data for employee training records:", err)
        // TODO: Implement errorLoadingData
        this.errorLoadingData = true
      }

      this.is_data_fetched = true;
    }
  }

</script>

<style>

  .grid-container .v-datatable thead th, .grid-container .v-datatable tbody td {
    text-align: center;
    min-width: 0px;
  }

  .admin-statistic-table table {
    table-layout: fixed;
  }

</style>


<style scoped>

  .date-filter-item {
    flex-direction: column;
  }

  .date-filter-item {
    background-color: white !important;
  }

  a.download-list > img {
    margin-top: 2em;
  }

  .admin-staistics-data-table-container {
    display: flex;
    flex-direction: column;
  }

</style>

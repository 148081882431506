
export const SplitUpArray = (arrayToSplit, chunkSize) => {
  const arrayLength = arrayToSplit.length
  // Get the number of chunks needed
  const numChunks = Math.ceil(arrayLength/chunkSize)
  var returnArray = []
  // Cycle through the number of chunks and put them into a return array
  let iteration = 0
  do {
    const startPos = iteration * chunkSize
    returnArray.push(arrayToSplit.slice(startPos, startPos + chunkSize))
    iteration = iteration + 1
  } while (iteration < numChunks)
  return returnArray
}

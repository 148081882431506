import { v4 as uuidv4 } from 'uuid';
import store from '@/store';

class EmployerGroup {

  constructor(parameters) {
    if (!parameters) {
      parameters = {}
    }

    this.id = parameters.id || uuidv4();
    this.name = parameters.name || '';
    this.description = parameters.description || '';
    this.employerId = parameters.employerId || store.getters.employer.id;
    this.groupsCanRead = parameters.groupsCanRead || ["USER"];
    this.groupsCanUpdate = parameters.groupsCanUpdate || [];
    this.groupsFullControl = parameters.groupsFullControl || [`${this.employerId}_ADMINISTRATOR`];
  }

  getDatabaseEntry() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      employerId: this.employerId,
      groupsCanRead: this.groupsCanRead,
      groupsCanUpdate: this.groupsCanUpdate,
      groupsFullControl: this.groupsFullControl,
    }
  }
}

export default EmployerGroup
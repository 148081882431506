
class Attendee {

  constructor(attendee) {
    this.attendee = attendee
  }

  getAttendee() {
    return this.attendee
  }
}

export default Attendee

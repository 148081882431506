<template>
  <v-card>
    <v-card-title>
      <div class="headline" style="color:rgba(254,138,125,1);">
      {{ groupDetails.name ? groupDetails.name : "Error getting group name" }}
      </div>
    </v-card-title>
    <v-divider style="border-width:1px;"></v-divider>

    <div class="scroll-container">
      <v-layout layout group-members-container>
        <div class="employee-container" v-for="employeeRole in employees" v-bind:key="employeeRole.id">
          <event-logo v-bind:imageKey="GetEmployeeAvatar(employeeRole.employee)" level="protected" :imageType="IMAGE_TYPES.AVATAR" style="border: 2px solid #fafafa; position: relative; height: 80px; width: 80px; background: #fafafa"/>
          <div>
            <p>{{ employeeRole.employee.user.firstName }} {{ employeeRole.employee.user.lastName }}</p>
          </div>
        </div>
      </v-layout>
    </div>


    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small color=rgb(0,171,173) @click="CloseDialog">Close</v-btn>
    </v-card-actions>

    <loading :active.sync="isProcessing" :is-full-page="true" loader="dots"></loading>

  </v-card>
</template>


<script>

import { IMAGE_TYPES } from '@/constants/ImageConstants'
import EventLogo from '@/components/eventlogo-container'

const DEFAULT_EMPLOYEE = {
  user: {
    firstName: "Deleted",
    lastName: "User",
  },
}

export default {

  props: {
    closePopover: Function,
    groups: Array,
    groupDetails: {
      type: Object,
      default: () => { return {} }
    },
  },

  components: {
    'event-logo': EventLogo,
  },

  data() {
    return {
      IMAGE_TYPES,
      isProcessing: false,
      errorWhenProcessing: false,
    }
  },

  methods: {
    CloseDialog() {
      try {
        this.closePopover();
      } catch (e) {
        console.warn("No close popover provided;", e);
      }
    },
    GetEmployeeAvatar(employee) {
      try {
        return employee.user.userDetail.avatar.imageKey;
      } catch (e) {
        return process.env.VUE_APP_placeholder_avatar_url
      }
    },
  },

  computed: {
    employees: function () {
      try {
        // Map the group members so that missing employee or user data can be fixed
        let returnMembers = this.groupDetails.groupMembers.items.map((member) => {
          // If the employee is missing, use the default employee
          if (!member.employee) {
            console.warn("Missing employee data for member", member);
            return {
              id: member.id,
              employee: DEFAULT_EMPLOYEE
            }
          }
          // If the user is missing, use the default employee
          if (!member.employee.user) {
            console.warn("Missing user data for member", member);
            return {
              id: member.id,
              employee: DEFAULT_EMPLOYEE
            }
          }
          return member
        });
        // Return the fixed member list
        return returnMembers;
      } catch (e) {
        return [];
      }
    }
  },
}
</script>




<style scoped>
.layout {
  display: block;
  margin-bottom: 2em;
  max-width: 90%;
  margin: auto;
}

.horizontal-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.single-line {
  white-space: nowrap;
}

label {
  padding-right: 1em;
}

.scroll-container {
  overflow-y: scroll;
  max-height: 50vh;
}

.group-members-container {
  display: grid;
  grid-gap: 0.5em;
}

.employee-container {
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
  grid-gap: 2em;
}

.employee-container p:last-of-type {
  margin-bottom: 0;
}

.add-button {
  margin-left: auto;
  margin-right: 0;
}

.course-instructor-details,
.details-container {
  display: grid;
  grid-gap: 0.5em 1em;
  grid-template-columns: 1fr 4fr;
  margin: 0;
  margin-bottom: 2em;
  align-items: center;
}
</style>

export const SET_LAYOUT = "SET_LAYOUT"
export const SET_LAST_REQUIRES_AUTH_PAGE = "SET_LAST_REQUIRES_AUTH_PAGE"
export const RESET_LAST_REQUIRES_AUTH_PAGE = "RESET_LAST_REQUIRES_AUTH_PAGE"
export const SET_COGNITO = "SET_COGNITO"
export const RESET_COGNITO = "RESET_COGNITO"
export const RESET_AFTER_LOGOUT = "RESET_AFTER_LOGOUT"

export const SET_BOOKINGS = "SET_BOOKINGS"
export const EXTEND_BOOKINGS = "EXTEND_BOOKINGS"

export const SET_USER_DETAILS = "SET_USER_DETAILS"
export const SET_CUSTOM_GET_USER_DETAILS = "SET_CUSTOM_GET_USER_DETAILS"
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS"
export const SET_ACCREDITATION_LOGOS = "SET_ACCREDITATION_LOGOS"

export const SET_PAST_EVENTS = "SET_PAST_EVENTS"
export const EXTEND_PAST_EVENTS = "EXTEND_PAST_EVENTS"
export const SET_FUTURE_EVENTS = "SET_FUTURE_EVENTS"
export const EXTEND_FUTURE_EVENTS = "EXTEND_FUTURE_EVENTS"

export const SET_UPLOADED_EVENTS = "SET_UPLOADED_EVENTS"
export const EXTEND_UPLOADED_EVENTS = "EXTEND_UPLOADED_EVENTS"

export const SET_ISSUED_EXPERIENCE_CERTIFICATES = "SET_ISSUED_EXPERIENCE_CERTIFICATES"
export const EXTEND_ISSUED_EXPERIENCE_CERTIFICATES = "EXTEND_ISSUED_EXPERIENCE_CERTIFICATES"

export const SET_EMPLOYER = "SET_EMPLOYER"

export const SET_EMPLOYER_LOGO = "SET_EMPLOYER_LOGO"

export const SET_PROVIDER_LIST = "SET_PROVIDER_LIST"

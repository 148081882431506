
import { API, graphqlOperation } from 'aws-amplify';
//import * as QueriesCustom from '../graphql/queriesCustomEmployee';
import * as MutationsCustom from '../graphql/mutationsCustomEmployee';
import { v4 as uuidv4 } from 'uuid';

class EmployeeService {

  constructor(store) {
    this.$store = store
  }

  /**
   * @function 
   * @name UpdateEmployeeGroups
   * @description Updates the groups that an employee is connected to
   * @param {Object}    originalEmployee The originally supplied employee object
   * @param {[String]}  newGroupIds An array of the new groupIds the employee should be connected to
   */
  async UpdateEmployeeGroups(originalEmployee, newGroupIds) {
    if (!originalEmployee) { throw new Error('employeeId is required') }
    if (!newGroupIds) { throw new Error('newGroupIds are required') }
    // Get the original group IDs from the employee object
    //console.log('originalEmployee', originalEmployee)
    //console.log('newGroupIds', newGroupIds)
    const originalGroupAllocations = originalEmployee.employerGroups.items
    const originalGroupIds = originalGroupAllocations.map(group => group.group.id)

    // Work out which groups to delete and which to add
    let deletedGroups = originalGroupIds.filter(groupId => !newGroupIds.includes(groupId))
    // Find the group allocation IDs that were deleted
    let deletedGroupAllocationIds = originalGroupAllocations.filter(groupAllocation => deletedGroups.includes(groupAllocation.group.id)).map(groupAllocation => groupAllocation.id)
    //console.log('deletedGroupAllocationIds', deletedGroupAllocationIds)

    // Delete the group allocations
    for (let groupAllocationId of deletedGroupAllocationIds) {
      await API.graphql(graphqlOperation(MutationsCustom.deleteEmployeeGroupAllocation, { input: { id: groupAllocationId }}));
    }

    // Work out which groups to add
    let addedGroupIds = newGroupIds.filter(group => !originalGroupIds.includes(group))
    // Add the group allocations
    for (let groupAllocationId of addedGroupIds) {
      const input = {
        id: uuidv4(),
        employeeId: originalEmployee.id,
        groupId: groupAllocationId,
        groupsCanRead: ["USER"],
        groupsCanUpdate: [],
        groupsFullControl: [`${this.$store.getters.employer.id}_ADMINISTRATOR`],
      }
      //console.log("creating group allocation: ", input)
      await API.graphql(graphqlOperation(MutationsCustom.createEmployeeGroupAllocation, { input }));
    }
  }


}

export default EmployeeService

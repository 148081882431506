<template>
  <v-container pa-0 ma-0 mt-3 fluid>
    <loading :active.sync="actionInProgress" :is-full-page="true" loader="dots"></loading>

    <v-layout>
      <v-flex>
        <v-card class="container-rounded">
          <h1>Create New Provider</h1>
          <v-layout wrap centered>
            <v-flex xs12 md8>
              <form id="createProvider" method="post" class="form-horizontal" enctype="multipart/form-data">

                <div class="form-row" style="font-weight:normal;">
                  <div class="col-sm-4 control-label">Organisation Name <span class="red-text">*</span></div>
                  <input type="text" class="form-control col-sm-6" v-model="employerDetails.name" id="name" name="name" placeholder="Organisation Name" :disabled="!userIsAdmin">
                </div>
                <div v-if="$v.employerDetails.name.$error" class="text-danger">
                  <small v-for='[errName, errVal] in Object.entries($v.employerDetails.name.$params)' v-bind:key='`name-${errName}`' v-show="!$v.employerDetails.name[errName]">{{errVal.$message}}</small>
                </div>

                <br />

                <div class="form-row" style="font-weight:normal;">
                  <div class="col-sm-4 control-label">Address Line 1 <span class="red-text">*</span></div>
                  <input type="text" class="form-control col-sm-8" id="line1" name="line1" v-model="employerDetails.address.line1" placeholder="Line 1"  :disabled="!userIsAdmin"/>
                </div>
                <div v-if="$v.employerDetails.address.line1.$error" class="text-danger">
                  <small v-for='[errName, errVal] in Object.entries($v.employerDetails.address.line1.$params)' v-bind:key='`addressline1-${errName}`' v-show="!$v.employerDetails.address.line1[errName]">{{errVal.$message}}</small>
                </div>

                <div class="form-row" style="font-weight:normal;">
                  <div class="col-sm-4 control-label">Address Line 2</div>
                  <input type="text" class="form-control col-sm-8" id="line2" name="line2" v-model="employerDetails.address.line2" placeholder="Line 2"  :disabled="!userIsAdmin"/>
                </div>
                <div v-if="$v.employerDetails.address.line2.$error" class="text-danger">
                  <small v-for='[errName, errVal] in Object.entries($v.employerDetails.address.line2.$params)' v-bind:key='`addressline2-${errName}`' v-show="!$v.employerDetails.address.line2[errName]">{{errVal.$message}}</small>
                </div>

                <div class="form-row" style="font-weight:normal;">
                  <div class="col-sm-4 control-label">Address Line 3</div>
                  <input type="text" class="form-control col-sm-8" id="line3" name="line3" v-model="employerDetails.address.line3" placeholder="Line 3"  :disabled="!userIsAdmin"/>
                </div>
                <div v-if="$v.employerDetails.address.line3.$error" class="text-danger">
                  <small v-for='[errName, errVal] in Object.entries($v.employerDetails.address.line3.$params)' v-bind:key='`addressline3-${errName}`' v-show="!$v.employerDetails.address.line3[errName]">{{errVal.$message}}</small>
                </div>

                <div class="form-row" style="font-weight:normal;">
                  <div class="col-sm-4 control-label">City <span class="red-text">*</span></div>
                  <input type="text" class="form-control col-sm-8" id="city" name="city" v-model="employerDetails.address.city" placeholder="City (to be displayed on certificate)"  :disabled="!userIsAdmin"/>
                </div>
                <div v-if="$v.employerDetails.address.city.$error" class="text-danger">
                  <small v-for='[errName, errVal] in Object.entries($v.employerDetails.address.city.$params)' v-bind:key='`addresscity-${errName}`' v-show="!$v.employerDetails.address.city[errName]">{{errVal.$message}}</small>
                </div>

                <div class="form-row" style="font-weight:normal;">
                  <div class="col-sm-4 control-label">State/County <span class="red-text">*</span></div>
                  <input type="text" class="form-control col-sm-8" id="region" name="region" v-model="employerDetails.address.region" placeholder="State/County"  :disabled="!userIsAdmin"/>
                </div>
                <div v-if="$v.employerDetails.address.region.$error" class="text-danger">
                  <small v-for='[errName, errVal] in Object.entries($v.employerDetails.address.region.$params)' v-bind:key='`addressregion-${errName}`' v-show="!$v.employerDetails.address.region[errName]">{{errVal.$message}}</small>
                </div>

                <div class="form-row" style="font-weight:normal;">
                  <div class="col-sm-4 control-label">Post Code <span class="red-text">*</span></div>
                  <input type="text" class="form-control col-sm-8" id="postalCode" name="postalCode" v-model="employerDetails.address.postalCode" placeholder="Post Code"  :disabled="!userIsAdmin"/>
                </div>
                <div v-if="$v.employerDetails.address.postalCode.$error" class="text-danger">
                  <small v-for='[errName, errVal] in Object.entries($v.employerDetails.address.postalCode.$params)' v-bind:key='`addresspostalCode-${errName}`' v-show="!$v.employerDetails.address.postalCode[errName]">{{errVal.$message}}</small>
                </div>

                <div class="form-row" style="font-weight:normal;">
                  <div class="col-sm-4 control-label">Country <span class="red-text">*</span></div>
                  <input type="text" class="form-control col-sm-8" id="country" name="country" v-model="employerDetails.address.country" placeholder="Country"  :disabled="!userIsAdmin"/>
                </div>
                <div v-if="$v.employerDetails.address.country.$error" class="text-danger">
                  <small v-for='[errName, errVal] in Object.entries($v.employerDetails.address.country.$params)' v-bind:key='`addresscountry-${errName}`' v-show="!$v.employerDetails.address.country[errName]">{{errVal.$message}}</small>
                </div>

                <br />

                <div class="form-row" style="font-weight:normal;">
                  <div class="col-sm-4 control-label">Contact Email Address <span class="red-text">*</span></div>
                  <input type="text" class="form-control col-sm-6" v-model="employerDetails.contactDetails.emailBookings" id="emailBookings" name="emailBookings" placeholder="Contact Email Address" :disabled="!userIsAdmin">
                </div>
                <div v-if="$v.employerDetails.contactDetails.emailBookings.$error" class="text-danger">
                  <small v-for='[errName, errVal] in Object.entries($v.employerDetails.contactDetails.emailBookings.$params)' v-bind:key='`employerDetailsEmailBooking-${errName}`' v-show="!$v.employerDetails.contactDetails.emailBookings[errName]">{{errVal.$message}}</small>
                </div>

                <div class="form-row" style="font-weight:normal;">
                  <div class="col-sm-4 control-label">Contact Phone Number</div>
                  <input type="text" class="form-control col-sm-6" v-model="employerDetails.contactDetails.phoneBookings" id="phoneBookings" name="phoneBookings" placeholder="Contact Phone Number" :disabled="!userIsAdmin">
                </div>
                <div v-if="$v.employerDetails.contactDetails.phoneBookings.$error" class="text-danger">
                  <small v-for='[errName, errVal] in Object.entries($v.employerDetails.contactDetails.phoneBookings.$params)' v-bind:key='`employerDetailsPhoneBooking-${errName}`' v-show="!$v.employerDetails.contactDetails.phoneBookings[errName]">{{errVal.$message}}</small>
                </div>

                <div class="form-row" style="font-weight:normal;">
                  <div class="col-sm-4 control-label">Website Address</div>
                  <input type="text" class="form-control col-sm-6" v-model="employerDetails.courseProviderDetails.websiteURL" id="websiteURL" name="websiteURL" placeholder="Website Address" :disabled="!userIsAdmin">
                </div>
                <div v-if="$v.employerDetails.courseProviderDetails.websiteURL.$error" class="text-danger">
                  <small v-for='[errName, errVal] in Object.entries($v.employerDetails.courseProviderDetails.websiteURL.$params)' v-bind:key='`contactDetailsPhoneBooking-${errName}`' v-show="!$v.employerDetails.courseProviderDetails.websiteURL[errName]">{{errVal.$message}}</small>
                </div>

                <br />

                <div class="form-row" style="font-weight:normal;">
                  <label class="col-sm-4 control-label" for="RegistrationNumber">Registration Number</label>
                  <input type="text" class="form-control col-sm-6" v-model="employerDetails.courseProviderDetails.registrationNumber" id="RegistrationNumber" name="RegistrationNumber" placeholder="Registration Number" :disabled="!userIsAdmin">
                </div>
                <div v-if="$v.employerDetails.courseProviderDetails.registrationNumber.$error" class="text-danger">
                  <small v-for='[errName, errVal] in Object.entries($v.employerDetails.courseProviderDetails.registrationNumber.$params)' v-bind:key='`contactDetailsRegistration-${errName}`' v-show="!$v.employerDetails.courseProviderDetails.registrationNumber[errName]">{{errVal.$message}}</small>
                </div>

              </form>
            </v-flex>
            <v-flex xs12 offset-md8 md4 style="text-align:end;">
              <div v-if="checkCurrentData && $v.$invalid" class="text-danger" style="float:right">
                <small>Please review your input. Errors are highlighted in red.</small>
              </div>
            </v-flex>
            <v-flex xs12 offset-md8 md4 style="text-align:end;">
              <v-btn color="rgb(0,171,173)" @click="SaveEmployerDetails">
                Save
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>

      </v-flex>


      <v-dialog persistent v-model="messageDialog.show" transition="dialog-transition" max-width="500">
        <v-card>
          <v-card-text>
            <v-flex>
              <span style="font-weight:normal;">
                <div v-html="messageDialog.message"></div>
              </span>
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="CloseErrorDialog" small color=rgb(0,171,173)>Ok</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


    </v-layout>
  </v-container>
</template>



<script>
  import { validationMixin } from 'vuelidate'
  import { required, maxLength, minLength, helpers } from 'vuelidate/lib/validators'

  import AdminService from '@/services/admin.service'
  import EmployerService from '@/services/employer.service'

  //import { LAYOUTS } from '@/constants/LayoutConstants'
  //import { IMAGE_TYPES } from '@/constants/ImageConstants'
  //import { Storage } from 'aws-amplify';

  //import { ObjectsAreDifferent } from '@/helpers/general.helper'


  export default {

    data() {
      return {
        actionInProgress: false,
        messageDialog: {
          show: false,
          message: '',
        },
        pagination: {
          rowsPerPage: 10,
          totalItems: 0
        },
        checkCurrentData: false,
        employerDetails: {
          name: '',
          description: '',
          address: {
            line1: '',
            line2: '',
            line3: '',
            city: '',
            region: '',
            postalCode: '',
            country: '',
          },
          logo: {
            id: '',
            name: '',
            imageKey: '',
          },
          contactDetails: {
            emailBookings: '',
            phoneBookings: '',
          },
          courseProviderDetails: {
            description: '',
            whyChooseUs: '',
            websiteURL: '',
            registrationNumber: '',
          },
          isCourseProvider: true,
        }
      }
    },


    mixins: [validationMixin],
    validations: {
      employerDetails: {
        name: {
          required: helpers.withParams({ $message: "Name is required" }, required),
          maxLength: helpers.withParams({ $message: "Maximum length of 100 characters exceeded" }, maxLength(100)),
          minLength: helpers.withParams({ $message: "Name must be at least 2 characters" }, minLength(2))
        },
        address: {
          line1: {
            required: helpers.withParams({ $message: "Name is required" }, required),
            maxLength: helpers.withParams({ $message: "Maximum length of 100 characters exceeded" }, maxLength(100)),
          },
          line2: {
            maxLength: helpers.withParams({ $message: "Maximum length of 100 characters exceeded" }, maxLength(100)),
          },
          line3: {
            maxLength: helpers.withParams({ $message: "Maximum length of 100 characters exceeded" }, maxLength(100)),
          },
          city: {
            required: helpers.withParams({ $message: "City is required" }, required),
            maxLength: helpers.withParams({ $message: "Maximum length of 100 characters exceeded" }, maxLength(100)),
          },
          region: {
            required: helpers.withParams({ $message: "State/county is required" }, required),
            maxLength: helpers.withParams({ $message: "Maximum length of 100 characters exceeded" }, maxLength(100)),
          },
          postalCode: {
            required: helpers.withParams({ $message: "Post code is required" }, required),
            maxLength: helpers.withParams({ $message: "Maximum length of 10 characters exceeded" }, maxLength(10)),
          },
          country: {
            required: helpers.withParams({ $message: "Country is required" }, required),
            maxLength: helpers.withParams({ $message: "Maximum length of 100 characters exceeded" }, maxLength(100)),
          },
        },

        contactDetails: {
          emailBookings: {
            required: helpers.withParams({ $message: "Contact email address is required" }, required),
            maxLength: helpers.withParams({ $message: "Maximum length of 200 characters exceeded" }, maxLength(200)),
          },
          phoneBookings: {
            maxLength: helpers.withParams({ $message: "Maximum length of 25 characters exceeded" }, maxLength(25)),
          },
        },

        courseProviderDetails: {
          websiteURL: {
            maxLength: helpers.withParams({ $message: "Maximum length of 200 characters exceeded" }, maxLength(200)),
          },
          registrationNumber: {
            maxLength: helpers.withParams({ $message: "Maximum length of 20 characters exceeded" }, maxLength(20)),
          },
        }
      }
    },


    methods: {

      async SaveEmployerDetails() {
        this.actionInProgress = true
        this.checkCurrentData = true;

        // Touch everything and check if any data is invalid
        this.$v.$touch();
        if (this.$v.$invalid) {
          console.warn("Some fields are invalid:", this.$v)
          this.actionInProgress = false
          return;
        }

        try {
          const employerService = new EmployerService()
          await employerService.CreateEmployer(this.employerDetails)
          await new AdminService().GetProviderList()
          this.messageDialog.message = 'Provider created successfully';
          this.messageDialog.show = true;
        } catch (err) {
          console.warn("Error creating employer:", err)
          this.messageDialog.message = `Error creating employer:, ${err}`;
          this.messageDialog.show = true;
        }
        this.actionInProgress = false
      },

      CloseErrorDialog() {
        this.messageDialog.message = ''
        this.messageDialog.show = false
      }

    },

    computed: {
      userIsAdmin: function() {
        try {
          // If this user is a MyProPass employee, they are an admin for everyone
          if (this.$store.getters.cognito.signInUserSession.accessToken.payload['cognito:groups'].includes(`ADMINISTRATOR`)) {
            return true
          }
          // Otherwise, check if they are an admin for the current provider
          const employerId = this.$store.getters.employer.id
          return this.$store.getters.cognito.signInUserSession.accessToken.payload['cognito:groups'].includes(`${employerId}_ADMINISTRATOR`)
        } catch (err) {
          console.warn("There was an error checking user admin status:", err)
          return false
        }
      }
    },

    async mounted() {},

  }
</script>


<style scoped>

  .v-btn {
    text-decoration: none;
    text-transform: capitalize;
  }
  /* style the container */


  body {
    background-color: rgba(243,246,248,1);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  .action-cell {
    display: flex;
  }

  .container-rounded-subheader {
    padding-left: 50px;
    margin: 0 0 40px;
    min-height: 40px;
    overflow: visible;
  }

  .container-rounded-mini {
    background-color: #FFFFFF;
    min-height: 40px;
  }

  .container-rounded {
    background-color: #FFFFFF;
    margin: 12px 0px;
    min-height: 350px;
    padding-left: 0px;
  }

  .container-rounded h1 {
    margin-bottom: 24px;
  }

  .container-rounded-about {
    background-color: #FFFFFF;
    margin: 12px 0px;
    min-height: 500px;
    padding-bottom: 20px;
  }

  .contact-details-logo {
    border: 2px solid #fafafa;
    position: relative;
    height: 60px;
    width: 60px;
    bottom: -95px;
    background: #fafafa;
  }

  .personal-id-container {
    position: absolute;
    margin-left: 5px;
    vertical-align: bottom;
    bottom: 0;
    width: 100%;
    left: -15px;
  }

  .personal-id {
    float: right;
    font-size: 11px;
    font-weight: normal;
  }

  .theme--light.v-sheet {
    border: solid 1px #b5b5b5;
  }

  @media (min-width: 768px) {
    .container-rounded-subheader {
      margin: 0 40px 40px;
    }

    .contact-details-logo {
      height: 100px;
      width: 100px;
      bottom: -75px;
    }

    .personal-id-container {
      position: relative;
      margin-left: 5px;
      vertical-align: bottom;
      bottom: -80px;
    }

    .container-rounded-mini {
      margin-left: 40px;
      margin-right: 40px;
      padding-left: 50px;
    }

    .container-rounded, .container-rounded-about {
      margin: 12px 40px;
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  .red-text {
    color: red;
    margin: 0;
    padding: 0;
  }

  .text-danger {
    margin-top: -1em;
  }

</style>

<template>
  <v-container pa-0 ma-0 fluid v-if="is_data_fetched" class="vld-parent">
    <loading :active.sync="isLoading" :is-full-page="fullPage" loader="dots"></loading>
    <v-layout ma-0 justify-center align-content-center>
      <v-flex xs12>
        <v-container fluid grid-list-md>

          <v-layout grid-container column pa-2 ma-0>
            <v-flex md1>
              <v-layout>
                <v-flex xs12 md6>
                  <h5 class="page-header">Employee Training Records</h5>
                </v-flex>
              </v-layout>
              <v-layout>

                <!-- // TODO: Uncomment if Pia wants the add employee button on this page
                <a v-if="userIsAdmin" style="color:rgb(0,171,173);font-weight:normal;text-align:right;margin-right: 30px" @click="ToggleAddEmployeeDialog()">
                  <v-icon color="rgb(0,171,173)">add_circle</v-icon> &nbsp;Add Employee
                </a>
                -->

                <v-flex grid-search xs12 md3>
                  <label class="control-label" style="margin: 0; height: 1.33em;" for="selectedStatus">Filter By Group:</label>
                  <select class="form-control" id="selectedStatus" v-model="selectedGroup" style="height: 32px;">
                    <option value="ALL">All</option>
                    <option v-for="group in employerGroups" :value="group.id" v-bind:key="group.id">{{ group.name }}</option>
                  </select>
                </v-flex>


                <v-flex class='date-filter-item' grid-search xs12 md3>
                  <div>Start Date:</div>
                  <input type='date' v-model="startDate" class='form-control' />
                </v-flex>

                <v-flex class='date-filter-item' grid-search xs12 md3>
                  <div>End Date:</div>
                  <input type='date' v-model="endDate" class='form-control' />
                </v-flex>

                <v-flex grid-search xs12 md3>
                  <div>Search:</div>
                  <v-text-field v-model="searchText"
                                append-icon="search"
                                label="Search for an employee name or event title"
                                single-line
                                hide-details></v-text-field>
                </v-flex>
                <a class='download-list'>
                  <img :src="require('../images/file-download.svg')" @click="OnDownloadRecords" class="grid-actions-icon" />
                </a>
              </v-layout>
            </v-flex>

            <v-flex md10 class='employee-record-data-table'>

              <v-data-table :headers="HEADERS"
                            :items="processedBookings"
                            :search="searchText"
                            hide-actions
                            :pagination.sync="pagination"
                            :custom-sort="CustomSort"
                            :custom-filter="CustomFilter"
                            :sort-by.sync="pagination.sortBy"
                            :sort-desc.sync="pagination.sortDesc"
                            disable-initial-sort
                            class="elevation-1">

                <template slot="headerCell" slot-scope="{ header }">
                  <span class="table-head font-weight-bold text--darken-3" style='margin-right: 20px;' v-text="header.text" />
                </template>

                <template v-slot:items="props">

                  <td>
                    {{ `${props.item.user.firstName} ${props.item.user.lastName}` }}
                  </td>

                  <td style='text-align: left;'>
                    {{ props.item.event.title }}
                  </td>

                  <td>
                    {{ getDateFromDateTime(props.item.event.startDateTime) }}
                  </td>

                  <td>
                    {{ getDurationFromEvent(props.item.event, true) }}
                  </td>

                  <td>
                    {{ getCertificateExpiry(props.item) }}
                  </td>

                  <td>
                    {{ getBookingStatus(props.item) }}
                  </td>

                  <td style='font-size: 22px;'>
                      <a v-if="bookingHasCertificate(props.item)" @click="GetCertificate(props.item)"><img src="../images/file-certificate.svg" alt="Certificate" style="height:22px;width:22px; transform:translateY(-2px); opacity: 0.67;" /></a>
                  </td>

                </template>
              </v-data-table>

              <v-layout>
                <v-flex style="margin-top:9px; display: flex; flex-direction: row; gap: 10px; align-items: center;">
                  <v-flex text-right style="margin: 0; padding: 0;">
                    <v-pagination v-model="pagination.page" style="vertical-align:top;" :length="Math.ceil(pagination.totalItems / pagination.rowsPerPage)"></v-pagination>
                  </v-flex>
                </v-flex>
              </v-layout>

              <v-layout>
                
              </v-layout>

            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>

      <!-- // TODO: Uncomment if Pia wants the add employee button on this page
      <v-dialog v-model="showAddEmployeeDialog" persistent transition="dialog-transition" max-width="50%">
        <add-employee-dialog :employees="employees" :pendingEmployees="pendingEmployees" :closePopover="ToggleAddEmployeeDialog" />
      </v-dialog>

      <v-dialog v-model="showEditEmployeeDialog" persistent transition="dialog-transition" max-width="50%">
        <edit-employee-dialog :userDetails="employeeToEdit" :closePopover="ToggleEditEmployeeDialog" />
      </v-dialog>
      -->

    </v-layout>
  </v-container>
</template>



<script>

  // TODO: Uncomment if Pia wants the add employee button on this page
  //import ProviderAddEmployee from './provider-addemployee'
  //import ProviderEditEmployee from './provider-editemployee'

  import EmployerService from '@/services/employer.service'
  import CertificateService from '@/services/certificate.service'
  import EmployeeTrainingRecords from '@/services/employeeTrainingRecords.service'
  import ExperienceCertificateService from '@/services/experienceCertificate.service'

  import { GetLocaleDateFromDateTime } from '@/helpers/date.helper'
  import { EventDurationInMinutes } from '@/helpers/event.helper'
  import { FormatDuration, DESIRED_DURATIONS } from '@/helpers/duration.helper'
  import { BOOKING_ATTENDANCE, BOOKING_STATUS, BOOKING_CERTIFICATE_STATUS } from '@/constants/BookingConstants'
  import { EVENT_STATUS } from '@/constants/EventConstants'
  import CERTIFICATE_TYPES from '@/constants/CertificateTypes'


  const HEADERS = [
    { text: 'Employee',     align: 'left',    value: 'employeeName'                     },
    { text: 'Course Name',  align: 'left',    value: 'courseName'                       },
    { text: 'Date',         align: 'center',  value: 'eventDate'                        },
    { text: 'Duration',     align: 'center',  value: 'duration'                         },
    { text: 'Expiry',       align: 'center',  value: 'expires'                          },
    { text: 'Status',       align: 'center',  value: 'status'                           },
    { text: 'Certificate',  align: 'center',  value: 'certificate',   sortable: false,  width: "1%"   },
  ]

  export default {

    /*
    // TODO: Uncomment if Pia wants the add employee button on this page
    components: {
      'add-employee-dialog': ProviderAddEmployee,
      'edit-employee-dialog': ProviderEditEmployee
    },
    */

    data() {

      const oneYearAgo = new Date()
      oneYearAgo.setFullYear(oneYearAgo.getUTCFullYear() - 1)
      oneYearAgo.setHours(0)
      oneYearAgo.setMinutes(0)
      oneYearAgo.setSeconds(0)
      oneYearAgo.setMilliseconds(0)

      const today = new Date()
      today.setHours(23)
      today.setMinutes(59)
      today.setSeconds(59)
      today.setMilliseconds(999)

      return {
        is_data_fetched: false,
        isLoading: false,
        errorLoadingData: false,
        fullPage: true,
        searchText: '',
        pagination: {
          rowsPerPage: 10,
          totalItems: 0,
          sortBy: 'employeeName',
          sortDesc: true,
          page: 1,
        },
        employeeRecords: {
          items: [],
          nextToken: null
        },
        startDateValue: oneYearAgo.toISOString(),
        endDateValue: today.toISOString(),
        HEADERS,
        // TODO: Uncomment if Pia wants the add employee button on this page
        /*
        employees: [],
        pendingEmployees: [],
        showAddEmployeeDialog: false,
        showEditEmployeeDialog: false,
        employeeToEdit: {},
        */
        employerGroups: [],
        selectedGroup: 'ALL',
      }
    },

    watch: {
      processedBookings: function(newValue) {
        this.pagination.totalItems = newValue.length
      }
    },

    methods: {
      CustomSort(items, index, isDesc) {
        try {
          const result = items.sort((a, b) => {
            this.sortBy = index;
            this.sortDesc = isDesc;

            // Employee Name Sort
            if (index === "employeeName") {
              if (!isDesc) { return `${a.user.firstName} ${a.user.lastName}` < `${b.user.firstName} ${b.user.lastName}` ? -1 : 1 }
              return `${a.user.firstName} ${a.user.lastName}` > `${b.user.firstName} ${b.user.lastName}` ? -1 : 1
            }

            // Course Title Sort
            if (index === "courseName") {
              if (!isDesc) { return a.event.title < b.event.title ? -1 : 1 }
              return a.event.title > b.event.title ? -1 : 1
            }

            // Event Date Sort
            // Can be sorted as a string because the format is ISO8601 for event datetimes
            if (index === "eventDate") {
              if (!isDesc) { return a.event.startDateTime < b.event.startDateTime ? -1 : 1; }
              return a.event.startDateTime > b.event.startDateTime ? -1 : 1;
            }

            // Duration Sort
            if (index === "duration") {
              const aDuration = this.getDurationFromEvent(a.event, false)
              const bDuration = this.getDurationFromEvent(b.event, false)
              if (!isDesc) { return aDuration < bDuration ? -1 : 1 }
              return aDuration > bDuration ? -1 : 1
            }

            // Expires Sort
            if (index === "expires") {
              const aExpiry = a.event.certificatesExpire ? a.event.certificateExpiryDateTime : null
              const bExpiry = b.event.certificatesExpire ? b.event.certificateExpiryDateTime : null
              // If neither expire
              if (!aExpiry && !bExpiry) { return 0 }
              // If only A expires
              if (!!aExpiry && !bExpiry) { return isDesc ? -1 : 1 }
              // If only B expires
              if (!aExpiry && !!bExpiry) { return isDesc ? 1 : -1 }
              // If both expire
              const aDateTime = a.event.certificateExpiryDateTime
              const bDateTime = b.event.certificateExpiryDateTime
              if (!isDesc) { return aDateTime < bDateTime ? -1 : 1 }
              return aDateTime > bDateTime ? -1 : 1
            }

            // Status Sort
            if (index === "status") {
              const aStatus = this.getBookingStatus(a)
              const bStatus = this.getBookingStatus(b)
              if (!isDesc) { return aStatus < bStatus ? -1 : 1 }
              return aStatus > bStatus ? -1 : 1
            }

          });
          return result;
        } catch (e) {
          console.warn("Error when custom sorting:", e)
        }
        return items
      },

      CustomFilter(processedBookings) {
        try {
          if (!this.searchText) { return processedBookings }
          const itemsToFilter = processedBookings.filter((booking) => {
            const userFullName = `${booking.user.firstName} ${booking.user.lastName}`.toLowerCase()
            if (userFullName.includes(this.searchText.toLowerCase())) {
              return true
            }
            if (booking.event.title.toLowerCase().includes(this.searchText.toLowerCase())) {
              return true
            }
            return false
          });
          this.pagination.totalItems = itemsToFilter.length
          return itemsToFilter;
        } catch (e) {
          console.warn("Error when custom filtering;", e)
          return processedBookings
        }
      },
      getDateFromDateTime(dateTimeString) {
        return GetLocaleDateFromDateTime(dateTimeString)
      },
      getDurationFromEvent(eventObject, shouldFormat) {
        if (eventObject.isUploaded) {
          return FormatDuration(eventObject.duration, DESIRED_DURATIONS.ClockTimeHoursMinutes)
        }
        if (shouldFormat == null) {
          shouldFormat = true
        }
        try {
          const eventDuration = EventDurationInMinutes(eventObject)
          if (shouldFormat) {
            if (eventObject.isExperience) {
              return FormatDuration(eventDuration, DESIRED_DURATIONS.Days)
            }
            return FormatDuration(eventDuration, DESIRED_DURATIONS.ClockTimeHoursMinutes)
          }
          return eventDuration
        } catch (e) {
          return ''
        }
      },
      getBookingStatus(booking) {
        const { certificateSaved, isUploaded, isExperience, event } = booking
        if (isUploaded) {
          return "Certificate From User"
        }
        if (isExperience) {
          return "Certificate Available"
        }
        if (event.status === EVENT_STATUS.CANCELLED ) {
          return "Event Cancelled"
        }
        if (booking.status === BOOKING_STATUS.CANCELLED) {
          return "Booking Cancelled"
        }
        if (booking.status === BOOKING_STATUS.ONWAITINGLIST) {
          return "On Waiting List"
        }
        if (booking.status === BOOKING_STATUS.BOOKED) {
          return "Pending"
        }
        if (booking.attendance === BOOKING_ATTENDANCE.NOSHOW) {
          return "Absent"
        }
        if (certificateSaved) {
          return "Certificate Available"
        }
        //console.warn("error getting status:", booking)
        return "TBC"
      },
      bookingHasCertificate(booking) {
        return booking.certificateSaved && booking.attendance === BOOKING_ATTENDANCE.ATTENDED
      },
      async GetCertificate(booking) {
        //console.log("Getting a certificate")
        //console.log("booking:", booking);
        const { user, event } = booking
        const certificateService = new CertificateService(this.$store)

        var signedUrl;
        var downloadName;
        if (!booking.isUploaded) {
          signedUrl = await certificateService.GetCertificateSignedURL(event.id, user.id, user.identityId)
          downloadName = `Certificate - ${user.firstName} ${user.lastName} - ${booking.id}.pdf`;
        }
        if (booking.isUploaded) {
          signedUrl = await certificateService.GetUploadedCertificateSignedURL(booking.fileName, user.identityId)
          downloadName = `${booking.fileName}`;
        }
        if (booking.isExperience) {
          const experienceCertificateService = new ExperienceCertificateService(this.$store)
          const parameters = {
            experienceEventId: event.experienceEventId,
            user: {
              id: user.id,
              identityId: user.identityId
            }
          }
          signedUrl = await experienceCertificateService.GetExperienceCertificateSignedURL(parameters)
          downloadName = `Experience Certificate - ${booking.id}.pdf`;
        }
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = signedUrl;
        a.download = downloadName;
        a.target = "_blank"
        a.rel = "noopener"
        a.click();
        document.body.removeChild(a);
      },
      async OnDownloadRecords() {
        try {
          const filteredRecords = this.CustomFilter(this.processedBookings)
          const employeeTrainingRecordsService = new EmployeeTrainingRecords(this.$store)
          await employeeTrainingRecordsService.DownloadRecords(filteredRecords, this.employerGroups)
        } catch (err) {
          console.warn("Error when downloading records;", err)
        }
      },
      getCertificateExpiry(item) {
        try {
          if (item.event.certificatesExpire) {
            if (item.event.certificateExpiryDateTime) {
              return (new Date(item.event.certificateExpiryDateTime)).toLocaleDateString()
            }
          }
          return "-"
        } catch (err) {
          console.warn("Error getting certificate Expiry;", err)
          return "-"
        }
      },
      // TODO: Uncomment if Pia wants the add employee button on this page
      /*
      async ToggleAddEmployeeDialog(shouldReload) {
        var reloadRequired = false
        try {
          //console.log("shouldReload:", shouldReload)
          reloadRequired = shouldReload
        } catch (err) {
          reloadRequired = false;
        }
        if (reloadRequired) {
          await this.FetchEmployerDetails()
        }
        this.showAddEmployeeDialog = !this.showAddEmployeeDialog
      },
      async ToggleEditEmployeeDialog(shouldReload) {
        var reloadRequired = false
        try {
          //console.log("shouldReload:", await shouldReload)
          reloadRequired = await shouldReload
        } catch (err) {
          reloadRequired = false;
        }
        if (reloadRequired) {
          await this.FetchEmployerDetails()
        }
        this.employeeToEdit = {}
        this.showEditEmployeeDialog = !this.showEditEmployeeDialog
      },
      */
    },

    computed: {
      processedBookings: function() {
        // If there are no employees yet, return an empty array
        if (this.employeeRecords.items.length === 0) {
          return []
        }

        const employees = this.employeeRecords.items
        var result = []
        // For each employee booking, create an entry in the return array
        // TODO: Have bookings handle nextTokens so users aren't limited to 100 bookings

        const currentISODateTimeString = (new Date()).toISOString()


        employees.forEach((employee) => {
          // Check if the employee is part of the selectedGroup
          if (this.selectedGroup !== "ALL") {
            const groupIds = employee.employerGroups.items.map((group) => group.group.id)
            if (!groupIds.includes(this.selectedGroup)) {
              // If not part of the selected group, skip this employee
              return
            }
          }

          const employerGroups = employee.employerGroups.items.map((group) => group.group)

          // Convert all the bookings into table entry format
          //console.log("bookings:", employee.user.bookings.items)
          employee.user.bookings.items.map((booking) => {
            const bookingBetweenSelectedDates = booking.event.endDateTime >= this.startDateValue && booking.event.endDateTime <= this.endDateValue
            if (booking.event.endDateTime <= currentISODateTimeString && bookingBetweenSelectedDates) {
              result.push({
                id: booking.id,
                status: booking.status,
                attendance: booking.attendance,
                certificateSent: booking.certificateSent,
                certificateSaved: booking.certificateSaved,
                isUploaded: false,
                user: {
                  id: employee.user.id,
                  firstName: employee.user.firstName,
                  lastName: employee.user.lastName,
                  emailAddress: employee.user.emailAddress,
                  identityId: employee.user.identityId
                },
                event: {
                  ...booking.event,
                  issueDateTime: booking.event.endDateTime,
                },
                meta: {
                  type: CERTIFICATE_TYPES.TRAINING,
                  issuedBy: booking.event.provider.name, 
                  employerGroups,
                }
              })
            }
          })
          // Convert all the uploaded certificates into table entry format
          //console.log("uploadedEventRecords:", employee.user.uploadedEventRecords.items)
          employee.user.uploadedEventRecords.items.map((uploadedRecord) => {
            const bookingBetweenSelectedDates = uploadedRecord.eventDateTime >= this.startDateValue && uploadedRecord.eventDateTime <= this.endDateValue
            if (bookingBetweenSelectedDates) {
              //console.log("uploadedRecord:", uploadedRecord)
              result.push({
                id: uploadedRecord.id,
                status: BOOKING_STATUS.COMPLETED,
                attendance: BOOKING_ATTENDANCE.ATTENDED,
                certificateSent: BOOKING_CERTIFICATE_STATUS.SENTDIGITALLY,
                certificateSaved: true,
                isUploaded: true,
                fileName: uploadedRecord.fileName,
                user: {
                  id: employee.user.id,
                  firstName: employee.user.firstName,
                  lastName: employee.user.lastName,
                  emailAddress: employee.user.emailAddress,
                  identityId: employee.user.identityId
                },
                event: {
                  title: uploadedRecord.title,
                  description: uploadedRecord.description,
                  startDateTime: uploadedRecord.eventDateTime,
                  endDateTime: uploadedRecord.eventDateTime,
                  issueDateTime: uploadedRecord.eventDateTime,
                  isUploaded: true,
                  duration: uploadedRecord.cpdHours * 60,
                  certificatesExpire: uploadedRecord.expiryDateTime ? true : false,
                  certificateExpiryDateTime: `${uploadedRecord.expiryDateTime}T12:00:00.000Z`,
                },
                meta: {
                  type: CERTIFICATE_TYPES.SELF_UPLOADED,
                  issuedBy: uploadedRecord.providerName,
                  employerGroups,
                }
              })
            }
          })
          // Convert all the experience certificates into table entry format
          //console.log("experienceRecords:", employee.user.experienceRecords.items)
          employee.user.experienceRecords.items.map((record) => {
            const bookingBetweenSelectedDates = record.startDateTime >= this.startDateValue && record.endDateTime <= this.endDateValue
            if (bookingBetweenSelectedDates) {
              result.push({
                id: record.id,
                status: BOOKING_STATUS.COMPLETED,
                attendance: BOOKING_ATTENDANCE.ATTENDED,
                certificateSent: BOOKING_CERTIFICATE_STATUS.SENTDIGITALLY,
                certificateSaved: true,
                isExperience: true,
                user: {
                  id: employee.user.id,
                  firstName: employee.user.firstName,
                  lastName: employee.user.lastName,
                  emailAddress: employee.user.emailAddress,
                  identityId: employee.user.identityId
                },
                event: {
                  title: record.title,
                  description: record.description,
                  startDateTime: record.startDateTime,
                  endDateTime: record.endDateTime,
                  issueDateTime: record.issueDateTime,
                  isExperience: true,
                  experienceEventId: record.experienceEventId
                },
                meta: {
                  type: CERTIFICATE_TYPES.EXPERIENCE,
                  issuedBy: record.provider.name,
                  employerGroups,
                }
              })
            }
          })
        })
        return result
      },
      startDate: {
        get: function() {
          const dateObject = new Date(this.startDateValue)
          return dateObject.toISOString().split('T')[0]
        },
        set: function(newValue) {
          const oldDateStringParts = this.startDateValue.split('T')
          const newDateString = `${newValue}T${oldDateStringParts[1]}`
          //console.log("start date newDateString:", newDateString)
          this.startDateValue = newDateString
        }
      },
      endDate: {
        get: function() {
          const dateObject = new Date(this.endDateValue)
          return dateObject.toISOString().split('T')[0]
        },
        set: function(newValue) {
          const oldDateStringParts = this.endDateValue.split('T')
          const newDateString = `${newValue}T${oldDateStringParts[1]}`
          //console.log("end date newDateString:", newDateString)
          this.endDateValue = newDateString
        }
      },
      // TODO: Uncomment if Pia wants the add employee button on this page
      /*
      userIsAdmin: function() {
        try {
          // If this user is a MyProPass employee, they are an admin for everyone
          if (this.$store.getters.cognito.signInUserSession.accessToken.payload['cognito:groups'].includes(`ADMINISTRATOR`)) {
            return true
          }
          // Otherwise, check if they are an admin for the current provider
          const employerId = this.$store.getters.employer.id
          return this.$store.getters.cognito.signInUserSession.accessToken.payload['cognito:groups'].includes(`${employerId}_ADMINISTRATOR`)
        } catch (err) {
          console.warn("There was an error checking user admin status:", err)
          return false
        }
      }
      */
    },

    async mounted() {
      try {
        const employerService = new EmployerService(this.$store)
        this.employeeRecords = await employerService.GetAllEmployeesAndTrainingRecordsForEmployer()
        this.employerGroups = await employerService.GetGroups(this.$store.getters.employer.id)
        //console.log("employerGroups:", this.employerGroups)
        //console.log("employeeRecords:", this.employeeRecords)
        this.$parent.$parent.$parent.refreshMenu('employeeTrainingRecords');
      } catch (err) {
        console.warn("error loading data for employee training records:", err)
        // TODO: Implement errorLoadingData
        this.errorLoadingData = true
      }

      this.is_data_fetched = true;
    }
  }

</script>

<style scoped>

  .date-filter-item {
    flex-direction: column;
  }

  a.download-list > img {
    margin-top: 2em;
  }
  
  .form-control {
    cursor: pointer;
  }

</style>

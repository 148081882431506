<template>
  <v-flex class="centered-flex" xs12 md3 ma-0 pa-0>
    <loading :active.sync="awaitingServerResponse" :is-full-page="true" loader="dots"></loading>
    <v-layout column pa-0 ma-0>
      <v-flex xs12 md5>
        <v-card class="pl-3 pr-3 pb-3">
          <v-card-title class="justify-center pb-2">
            <h4 class="page-header">Change Password</h4>
          </v-card-title>

          <div class="pb-2" style="align-content:center;width:100%;" v-if='!hideContent'>Please enter your new password</div>

          <div class="pb-2" v-if='!hideContent'>
            <label for="newPassword">New Password</label>
            <input id="newPassword" type="password" class="form-control" v-model="newPassword" autocomplete="new-password">
            <div v-if="submitted && $v.newPassword.$error" class="text-danger">
              <small v-if="!$v.newPassword.required">New password has not been provided<br /></small>
              <small v-if="!$v.newPassword.minLength">New password is too short<br /></small>
              <small v-if="!$v.newPassword.containsUpperCaseCharacter">New password does not contain an upper case character<br /></small>
              <small v-if="!$v.newPassword.containsLowerCaseCharacter">New password does not contain a lower case character<br /></small>
              <small v-if="!$v.newPassword.containsNumericCharacter">New password does not contain a number character<br /></small>
              <small v-if="!$v.newPasswordDuplicate.checkPasswordsMatch">New passwords do not match<br /></small>
            </div>
          </div>

          <div class="pb-2" v-if='!hideContent'>
            <label for="newPasswordDuplicate">Repeat New Password</label>
            <input id="newPasswordDuplicate" type="password" class="form-control" v-model="newPasswordDuplicate" autocomplete="new-password">
            <div v-if="submitted && $v.newPasswordDuplicate.$error" class="text-danger">
              <small v-if="!$v.newPasswordDuplicate.required">Repeated password has not been provided<br /></small>
              <small v-if="!$v.newPasswordDuplicate.checkPasswordsMatch">New passwords do not match<br /></small>
            </div>
          </div>

          <div class="warning-box" v-if="passwordChangingFailed">
            {{ warningBoxText }}
          </div>

          <v-btn class="mb-3" color="rgb(0,171,173)" :block="true" @click="ChangeUserPassword" :disabled='changePasswordDisabled()'>
            {{ awaitingServerResponse ? "Please Wait..." : "Change Password" }}
          </v-btn>

        </v-card>
      </v-flex>
    </v-layout>
  </v-flex>
</template>


<script>

  import { Auth } from 'aws-amplify';
  import * as StoreActions from '../constants/StoreActions'
  import AuthService from '../services/auth.service'
  import UserService from '../services/user.service'
  import { validationMixin } from 'vuelidate'
  import { required, minLength } from 'vuelidate/lib/validators'
  import { containsUpperCaseCharacter, containsLowerCaseCharacter, containsNumericCharacter } from '../constants/Validations'

  // Validation for matching passwords
  const checkPasswordsMatch = (value, vm) => {
    return vm.newPassword === vm.newPasswordDuplicate
  }


  export default {

    data() {
      return {
        newPassword: '',
        newPasswordDuplicate: '',
        submitted: '',
        awaitingServerResponse: false,
        passwordChangingFailed: false,
        disableChangePasswordButton: false,
        warningBoxText: '',
        hideContent: false,
        acceptTANDC: false,
      }
    },

    // Validations
    mixins: [validationMixin],
    validations: {
      newPassword: {
        required,
        minLength: minLength(8),
        checkPasswordsMatch,
        containsUpperCaseCharacter,
        containsLowerCaseCharacter,
        containsNumericCharacter
      },
      newPasswordDuplicate: {
        required,
        minLength: minLength(8),
        checkPasswordsMatch
      }
    },

    // Methods
    methods: {
      async ChangeUserPassword() {
        console.log("Change user password clicked")
        this.submitted = true;
        this.awaitingServerResponse = true
        this.passwordChangingFailed = false

        // Check that all the fields are valid
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.awaitingServerResponse = false
          this.passwordChangingFailed = true
          this.warningBoxText = "The new password could not be submitted.  Please check the warnings above and resubmit."
          return;
        }

        //const { user_name, confirmation_code, /*(email_address*/ } = this.$router.currentRoute.query

        // Send the request to cognito
        try {
          const jsonResult = this.parseSearch()
          console.log("jsonResult:", jsonResult)
          const { confirmation_code, email_address } = jsonResult
          const parameters = {
            email: email_address,
            password: confirmation_code
          }
          // Log the user in
          var userLogin;
          try {
            userLogin = await AuthService.SignIn(parameters)
            console.log("User login:", userLogin)
          } catch (err) {
            console.warn("Error: unable to sign the user in:", err)
            // If this error is because the user's password has expired, send them a new one
            if (err.code === "NotAuthorizedException" && err.message === "Temporary password has expired and must be reset by an administrator.") {
              await AuthService.ResendUserTemporaryPassword(parameters)
              this.passwordChangingFailed = true
              this.disableChangePasswordButton = true
              this.awaitingServerResponse = false
              this.warningBoxText = "Your temporary sign up link has expired.  We have sent you a new link.  Please close this window and check your email."
              return
            }
            // If its not an expired code, throw the error higher
            throw err
          }
          // If there is an auth challenge, respond to it
          try {
            if (userLogin.challengeName) {
              if (userLogin.challengeName === "NEW_PASSWORD_REQUIRED") {
                userLogin = await AuthService.RespondToChallengeNewPasswordRequired(userLogin, confirmation_code);
              }
            }
          } catch (err) {
            console.warn("Error when responding to auth challenge:", err)
            throw err
          }
          // Now change the users password to their requested password
          await AuthService.ChangePassword(confirmation_code, this.newPassword);
          this.saveUserAndLogin(userLogin)
        } catch (error) {
          // On error, alert the user
          console.warn("Unable to change user password:", error)
          this.passwordChangingFailed = true
          this.awaitingServerResponse = false
          this.warningBoxText = `Error when changing password.  Please try again.  If this problem persists, please contact the administrator quoting the following message: ${error.message}`
        }
      },
      async saveUserAndLogin(user) {
        // First, save the users identityId to the server
        const credentials = await Auth.currentCredentials();
        const parameters = {
          id: user.username,
          identityId: credentials.identityId
        }
        console.log("parameters:", parameters)
        const userService = new UserService(this.$store)
        await userService.UpdateUserDetails(parameters)
        // Wait 1 second for the user to be updated
        await new Promise(resolve => setTimeout(resolve, 1000));
        // Store the cognito information in the store
        this.$store.commit(StoreActions.SET_COGNITO, user);
        // Make a call to the user service to get the users details from the database
        // Fetch all the events that are currently available
        await Promise.all([
          userService.GetDetails(user.username)
        ]).catch(function(error) {
          console.warn(error)
          return
        });
        // Ask the router to navigate to the user landing page
        this.$router.push({ name: 'UserLanding' })
      },
      changePasswordDisabled() {
        return this.awaitingServerResponse || this.disableChangePasswordButton
      },
      parseSearch() {
        try {
          const search = location.href.split('?')[1]
          let jsonResult
          if (search.includes("%26")) {
            // Use only this parsing after 16th April 2022
            let fixedSearch = search.replace(/%26/g, '","')
            fixedSearch = decodeURIComponent(fixedSearch)
            fixedSearch = '{"' + fixedSearch + '"}'
            fixedSearch = fixedSearch.replace(/=/g,'":"')
            jsonResult = JSON.parse(fixedSearch, function(key, value) { return key===""?value:decodeURIComponent(value) })
          } else {
            // This is to help with codes requested before 15th March 2022.  Remove on 16th April 2022
            let fixedSearch =  search.replace(/&user_/g, '","user_')
            fixedSearch = fixedSearch.replace(/&confirmation_/g, '","confirmation_')
            fixedSearch = fixedSearch.replace(/&email_/g, '","email_')
            fixedSearch = fixedSearch.replace(/=/g, '":"')
            jsonResult = JSON.parse('{"' + fixedSearch + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
            // If the string ended in an &, it was removed by the browser; replace it.
            if (jsonResult.confirmation_code.length === 7) {
              jsonResult.confirmation_code = `${jsonResult.confirmation_code}&`
            }
          }
          return jsonResult
        } catch (err) {
          console.warn("There was an error parsing the arguments;", err)
          this.passwordChangingFailed = true
          this.disableChangePasswordButton = true
          this.awaitingServerResponse = false
          this.warningBoxText = "The incorrect arguments were provided to this page.  Did you click the correct link to get here?"
          this.hideContent = true
          return "error"
        }
      }
    },

    mounted() {
      console.log("jsonResult:", this.parseSearch())
    }

  }

</script>


<style scoped>

  .centered-flex {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 4em auto 0 auto !important;
  }

  .warning-box {
    width: 100%;
    background-color: #FFCCCC;
    color: black;
    padding: 1em;
    border-radius: 5px;
  }

  .tick-box-form-group {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1em;
    justify-items: start;
    align-items: center;
  }

  .tick-box-form-group > label {
    margin: 0;
  }

  input[type='checkbox'] {
    height: initial;
    width: initial;
  }

</style>

<template>
  <v-layout column pa-0 ma-0 v-if="is_data_fetched">
    <v-flex xs12 md12 pa-0 ma-0>
      <v-layout row wrap pa-0 ma-0 mt-4>
        <v-flex xs12 md2 mr-lg-5 mb-3>
          <v-layout column pa-0 ma-0>
            <v-flex xs12 md8>
              <v-card pa-0 ma-0>
                <v-card-title class="justify-center">
                  <v-avatar size="75px" class="align-content-center">
                    <img :src="companyLogoSignedURL" height="75px" width="75px" style="background-color: rgba(240,240,240,1); align-content:center; object-fit:contain;" />
                  </v-avatar>
                </v-card-title>
                <v-divider></v-divider>
                <v-list three-line>
                  <v-list-tile>
                    <v-list-tile-content style="font-size:14px;text-align:center;">
                      <div style="color:rgb(0,171,173);align-content:center;width:100%">{{ employerName }}</div>
                      <div style="align-content:center;font-weight:bold;width:100%">Company ID: {{ companyId }}</div>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 md7 mr-lg-5 mb-3 class="container-rounded" pa-3>
          <v-layout column pa-0 ma-0>
            <v-flex xs12 md2>
              <v-layout wrap>
                <v-flex xs12>
                  <div>
                    <h4 style="color: #404e67;margin-top: 16px;font-weight: 700;text-align: center;margin-top:0;">Your Provider Scheduled Courses</h4>
                    <v-divider mt-0 mb-2></v-divider>
                  </div>
                </v-flex>
                <v-container xs12 offset-md4 md8 pb-0>
                  <v-layout>
                    <v-flex v-if="showFilter==false">
                      <table class="user-landing-search">
                        <tr>
                          <td style="vertical-align:bottom; border-style:none; width:min-content;">
                            <v-img :src="require('../images/filterfunnel.png')" style="border-style:none;" height="20px" width="14px" @click="OnToggleFilter"></v-img>
                          </td>
                          <td style="width:5px;"></td>
                          <td class="grid-search">
                            <v-text-field v-model="searchText"
                                          append-icon="search"
                                          label="Search for an event"
                                          single-line
                                          hide-details></v-text-field>
                          </td>
                        </tr>
                      </table>
                    </v-flex>
                    <v-flex v-if="showFilter==true" offset-xs1 xs10 offset-md11 md1 style="font-weight:normal;">
                      <div>
                        <v-img :src="require('../images/filterfunnel.png')" style="border-style:none;float: right" height="20px" width="14px" @click="OnToggleFilter"></v-img>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-card v-if="showFilter==true" class="filter-panel">
            <v-layout justify-center style="padding:15px 20px 0 15px;">
              <v-flex xs12 md2>
                <p>Type</p>
                <div style="font-weight:normal;">
                  <label><input type="checkbox" value="1" v-model="eventFilter.free" /> Free</label><br />
                  <label><input type="checkbox" value="2" v-model="eventFilter.paid" /> Paid</label><br />
                  <label><input type="checkbox" value="3" v-model="eventFilter.conference" /> Conference</label>
                </div>
              </v-flex>
              <v-flex xs12 md2>
                <p>Duration</p>
                <div style="font-weight:normal;">
                  <label><input type="checkbox" value="1" v-model="eventFilter.lessThanOneHour" /> &lt; 1 hr</label><br />
                  <label><input type="checkbox" value="2" v-model="eventFilter.oneToFourHours" /> 1-4 hrs</label><br />
                  <label><input type="checkbox" value="4" v-model="eventFilter.fourToEightHours" /> 4-8 hrs</label><br />
                  <label><input type="checkbox" value="8" v-model="eventFilter.eightPlusHours" /> 8+ hrs</label>
                </div>
              </v-flex>
              <v-flex xs12 md2>
                <p>Schedule</p>
                <div style="font-weight:normal;">
                  <label><input type="checkbox" value="1" v-model="eventFilter.weekdays" /> Weekdays</label><br />
                  <label><input type="checkbox" value="2" v-model="eventFilter.weekends" /> Weekends</label>
                </div>
              </v-flex>
              <!--
              <v-flex xs12 md2>
                <p>Location</p>
                <div style="font-weight:normal;">
                  <label><input type="checkbox" value="1" v-model="eventFilter.lessThanFiveMiles" /> &lt; 5 mi</label><br />
                  <label><input type="checkbox" value="1" v-model="eventFilter.fiveToTenMiles" /> 5-10 mi</label><br />
                  <label><input type="checkbox" value="2" v-model="eventFilter.tenToTwentyMiles" /> 10-20 mi</label>
                  <label><input type="checkbox" value="4" v-model="eventFilter.greaterThanTwenty" /> 20+ mi</label>
                </div>
              </v-flex>
              <v-flex xs12 md2>
                <p>Price</p>
                <div style="font-weight:normal;">
                  <label><input type="checkbox" value="1" v-model="eventFilter.lessThanTwoHundred" /> £200</label><br />
                  <label><input type="checkbox" value="2" v-model="eventFilter.twoHundredToThreeHundred" /> £200 - £300</label>
                  <label><input type="checkbox" value="4" v-model="eventFilter.greaterThanThreeHundred" /> > £300</label>
                </div>
              </v-flex>
            </v-layout>
            <v-layout style="padding:15px 25px 0px 25px;">
              <v-flex offset-md2 xs12>
                <div class="form-row">
                  <label class="control-label mt-1">From Address</label>&nbsp;&nbsp;
                  <input type="text" class="form-control col-sm-7" id="address" name="address" />
                  <div v-if="eventFilter.submitted && $v.eventFilter.address.$error" class="text-danger">
                    <small v-if="!$v.eventFilter.address.validAddress">Address is required because Location filter is selected.</small>
                  </div>
                </div>
              </v-flex>
              -->
            </v-layout>
            <!--
            <v-layout style="padding:0px 25px 0px 25px;">
              <v-flex offset-md2 xs12>
                <div class="form-row">
                  <label class="control-label mt-3">Start Date</label>
                  <v-menu ref="eventFilter.startDateMenu"
                          v-model="eventFilter.startDateMenu"
                          lazy
                          :close-on-content-click="false"
                          offset-y>
                    <template style="width:100px">
                      <v-text-field v-model="eventFilter.startDateFormatted"
                                    prepend-icon="event" class="col-sm-6" style="font-weight:normal;"
                                    @blur="eventFilter.startDate = parseDate(eventFilter.startDateFormatted)"
                                    ></v-text-field>
                    </template>
                    <v-date-picker dark
                                   class="mt-4" color="green lighten-1" event-color="green" header-color="primary" v-model="eventFilter.startDate" @input="eventFilter.startDateMenu = false"></v-date-picker>
                  </v-menu>
                  <div v-if="eventFilter.submitted && $v.eventFilter.startDate.$error" class="text-danger">
                    <small v-if="!$v.eventFilter.startDate.validDate">Start Date is invalid.</small>
                  </div>
                </div>
              </v-flex>
              <v-flex xs12>
                <div class="form-row">
                  <label class="control-label mt-3">End Date</label>
                  <v-menu ref="eventFilter.endDateMenu"
                          v-model="eventFilter.endDateMenu"
                          lazy
                          :close-on-content-click="false"
                          offset-y>
                    <template style="width:100px">
                      <v-text-field v-model="eventFilter.endDateFormatted"
                                    prepend-icon="event" class="col-sm-6" style="font-weight:normal;"
                                    @blur="eventFilter.endDate = parseDate(eventFilter.endDateFormatted)"
                                    ></v-text-field>
                    </template>
                    <v-date-picker dark
                                   class="mt-4" color="green lighten-1" event-color="green" header-color="primary" v-model="eventFilter.endDate" @input="eventFilter.endDateMenu = false"></v-date-picker>
                  </v-menu>
                  <div v-if="eventFilter.submitted && $v.eventFilter.endDate.$error" class="text-danger">
                    <small v-if="!$v.eventFilter.endDate.validDate">End Date is invalid.</small>
                    <small v-if="!$v.eventFilter.endDate.validEndTime">End Date should be greater than start date.</small>
                  </div>
                </div>
              </v-flex>
            </v-layout>
            -->
            <v-layout style="margin-top: 2em; padding:0px 25px 0px 25px;">
              <v-flex style="display:flex; justify-content:flex-end; align-items:flex-end;">
                <v-btn color="rgb(0,171,173)" small @click="ResetFilter(true)">Clear</v-btn>
                <v-btn color="rgb(0,171,173)" small @click="CloseFilter(true)">Close</v-btn>
              </v-flex>
            </v-layout>
            <br />
          </v-card>
          <v-layout class="container">
            <v-flex xs12 md12 mt-3>
              <v-layout>
                <v-flex>
                  <v-data-iterator
                    :items="events"
                    :custom-filter="customEventSearchFilter"
                    hide-actions
                    :search="searchText"
                    :pagination.sync="pagination"
                    :sort-by="sortBy"
                    :sort-desc="sortDesc"
                    :custom-sort="CustomSort"
                    content-tag="v-layout"
                    wrap >
                    <template v-slot:item="props">
                      <v-container class="container-rounded-grey pa-0 mb-3">
                        <v-layout>
                          <v-flex xs12 md3 style="max-width: 85px;">
                            <event-logo v-bind:imageKey="props.item.eventLogo.imageKey" level="public" />
                          </v-flex>
                          <v-flex xs12 md9 style="margin-left:10px">
                            <v-container ma-0 pa-0>
                              <v-layout ma-0 pa-0 class="book-course-direction">
                                <v-flex xs12 md8>
                                  <div class="ABC_in_Scaffolding">
                                    <span style="font-size: 15px;">{{ props.item.title }}</span><br />
                                    <div class="Duration__1_hour book-course-secondary-data">
                                      <span>Start Date: {{ getDateFromDateTime(props.item.startDateTime) }} {{ getTimeFromDateTime(props.item.startDateTime) }}</span>
                                      <span>End Date: {{ getDateFromDateTime(props.item.endDateTime) }} {{ getTimeFromDateTime(props.item.endDateTime) }}</span>
                                      <span>Duration: {{ getDurationFromEvent(props.item, true) }}</span>
                                      <span v-if="props.item.eventMode === EVENT_MODE.INPERSON">Location: {{ getAddressStringFromEvent(props.item) }}</span>
                                      <span v-if="props.item.eventMode === EVENT_MODE.VIRTUAL">Location: Online</span>
                                    </div>
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </template>
                  </v-data-iterator>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs12 text-right>
                  <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 md2 ma-0 pa-0 mb-3>
          <v-layout column pa-0 ma-0>
            <v-flex xs12 md8 fluid fluid-height>
              <v-card>
                <v-card-title><h6 class="page-header ma-0" style="width: 100%; text-align: center; margin-bottom:0;">Upcoming Events</h6></v-card-title>
                <v-list two-line class="pl-3 pr-3">
                  <div pa-0 class="scheduled-event">
                    <v-data-iterator :items="providerUpcomingEvents"
                                     :sort-by="sortBy"
                                     :sort-desc="sortDesc"
                                     :custom-sort="CustomSort"
                                     content-tag="v-layout"
                                     hide-actions
                                     wrap>
                      <template v-slot:item="props">
                        <div role="listitem">
                          <span style="font-size:15px;font-weight:bold;">{{ getDateFromDateTime(props.item.startDateTime) }}</span>
                          <span>{{ props.item.title }}</span>
                        </div>
                      </template>
                    </v-data-iterator>
                  </div>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>


<script>

  //import ProviderService from '../services/provider.service'
  import { validationMixin } from 'vuelidate'
  //import { required, maxLength } from 'vuelidate/lib/validators'

  //import UserService from '../services/user.service'
  import EventService from '../services/event.service'
  import EmployerService from '../services/employer.service'
  //import { EVENT_MODE, EVENT_STATUS, EVENT_TYPE, EVENT_DURATION, EVENT_SCHEDULE, EVENT_LOCATION, EVENT_PRICE } from '../constants/EventConstants'
  import { EVENT_MODE, EVENT_TYPE, EVENT_DURATION, EVENT_SCHEDULE } from '../constants/EventConstants'
  import { FormatDuration, DESIRED_DURATIONS } from '../helpers/duration.helper'
  import { GetTimeFromDateTime, GetLocaleDateFromDateTime } from '../helpers/date.helper'
  import { EventDurationInMinutes, FormatAddressObjectToString } from '../helpers/event.helper'
  import { validDate } from '../constants/Validations'

  import EventLogo from './eventlogo-container'

  const validAddress = (value, vm) => {
    if (vm.lessThanFiveMiles == true || vm.fiveToTenMiles == true || vm.tenToTwentyMiles == true || vm.greaterThanTwenty == true) {
      if (vm.address == '') {
        return false;
      }
    }
    return true;
  }

  export default {

    mixins: [validationMixin],
    validations: {
      eventFilter: {
        startDate: { validDate },
        endDate: { validDate },
        address: { validAddress }
      }
    },

    components: {
      'event-logo': EventLogo
    },

    data() {
      return {
        is_data_fetched: false,
        showFilter: false,
        eventFilter: {
          free: false,
          paid: false,
          conference: false,
          lessThanOneHour: false,
          oneToFourHours: false,
          fourToEightHours: false,
          eightPlusHours: false,
          weekdays: false,
          weekends: false,
          //lessThanFiveMiles: false,
          //fiveToTenMiles: false,
          //tenToTwentyMiles: false,
          //greaterThanTwenty: false,
          //lessThanTwoHundred: false,
          //twoHundredToThreeHundred: false,
          //greaterThanThreeHundred: false,
          //startDateMenu: false,
          //startDate: '',//new Date().toISOString().substr(0, 10),
          //startDateFormatted: '',//this.formatDate(new Date().toISOString().substr(0, 10)),
          //endDateMenu: false,
          //endDate: '',//new Date().toISOString().substr(0, 10),
          //endDateFormatted: '',//this.formatDate(new Date().toISOString().substr(0, 10)),
          //address: '',
          submitted: false
        },
        searchText: '',
        sortBy: 'startDateTime',
        sortDesc: false,
        pagination: {
          rowsPerPage: 5,
          page: 1,
          totalItems: 0,
        },
        Email: '',
        Phone: '',
        Location: '',
        myUpcomingEvents: [],
        companyLogoSignedURL: process.env.VUE_APP_placeholder_company_logo_url,
        EVENT_MODE
      }
    },

    watch: {
      events(newValue) {
        //console.log("WATCH: event triggered; new value:", newValue)
        this.pagination.totalItems = newValue.length
        //console.log("pagination is now:", this.pagination)
      },
      'pagination.page': function(newValue) {
        //console.log("WATCHER: pagination page value updated", newValue)
        // Check if the user is getting close to the end of the fetched items
        if (newValue + 1 >= Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)) {
          new EventService(this.$store).ExtendFutureEventsForEmployee()
        }
      },
      '$store.getters.employer.logo.id': async function() {
        await this.GetCompanyLogo()
      }
    },

    methods: {
      CustomSort(items, index, isDesc) {
        //console.log(items, index, isDesc)
        try {
          items.sort((a, b) => {
            this.sortBy = "startDateTime";
            this.sortDesc = isDesc;
            if (!isDesc) { return new Date(a.startDateTime) < new Date(b.startDateTime) ? -1 : 1; }
            return new Date(b.startDateTime) < new Date(a.startDateTime) ? -1 : 1;
          });
          return items;
        } catch (e) {
          console.warn("Error when custom sorting")
        }
      },

      OnToggleFilter() {
        this.ResetFilter(false);
        this.showFilter = !this.showFilter;
        if (this.showFilter == true) {
          var self = this;
          setTimeout(function () { self.SetUpAutoComplete(); }, 100);
        }
      },

      SetUpAutoComplete() {
        var input = document.getElementById('address');
        console.log('input');
        console.log(input);
        /*
        this.autocomplete = new google.maps.places.Autocomplete(input);
        this.autocomplete.addListener('place_changed', () => {
          let place = this.autocomplete.getPlace();
          if (place) {
            this.eventFilter.address = place.name + ',' + place.formatted_address;
          }
          else {
            this.eventFilter.address = input.value;
          }
          console.log(this.eventFilter.address);
        });
        */
        // FUTURE: Restore the autocomplete functionality
      },

      ResetFilter(/*isSubmit*/) {
        this.eventFilter.free = false;
        this.eventFilter.paid = false;
        this.eventFilter.conference = false;
        this.eventFilter.lessThanOneHour = false;
        this.eventFilter.oneToFourHours = false;
        this.eventFilter.fourToEightHours = false;
        this.eventFilter.eightPlusHours = false;
        this.eventFilter.weekdays = false;
        this.eventFilter.weekends = false;
        //this.eventFilter.lessThanFiveMiles = false;
        //this.eventFilter.fiveToTenMiles = false;
        //this.eventFilter.tenToTwentyMiles = false;
        //this.eventFilter.greaterThanTwenty = false;
        //this.eventFilter.lessThanTwoHundred = false;
        //this.eventFilter.twoHundredToThreeHundred = false;
        //this.eventFilter.greaterThanThreeHundred = false;
        //this.eventFilter.startDate = '';
        //this.eventFilter.startDateFormatted = '';
        //this.eventFilter.endDateMenu = false;
        //this.eventFilter.endDate = '';
        //this.eventFilter.endDateFormatted = '';
        //this.eventFilter.address = '';
        this.eventFilter.submitted = false;
        /*
        if (isSubmit) {
          this.OnFilterClick();
        }
        */
      },

      CloseFilter() {
        this.showFilter = false
      },

      customEventSearchFilter(items, searchString) {
        // Make a copy of the items for filtering against
        var filteredItemsBySearchString = items

        //console.log("searchString:", searchString)

        // First, for efficiency, check if the search string is empty and if it isn't, filter the items
        if (searchString.length > 0) {
          // Make everything lower case to make searching accurate
          const lowerCaseSearchString = searchString.toLowerCase()
          // Filter all the event items by the search string
          filteredItemsBySearchString = items.map((item) => {
            if (item.provider.name.toLowerCase().includes(lowerCaseSearchString)) {
              return item
            }
            if (item.title.toLowerCase().includes(lowerCaseSearchString)) {
              return item
            }
            return null
          }).filter(item => item != null)
        }

        // Get all the active filters
        const { activeFilterCategories } = this
        //console.log("activeFilterCategories", activeFilterCategories)

        // Make a copy of the items that can be further filtered
        var filteredItemsToReturn = filteredItemsBySearchString


        //console.log("activeFilterCategories:", activeFilterCategories)

        // Filter by type if necessary
        if (activeFilterCategories.type.length > 0) {
          filteredItemsToReturn = filteredItemsToReturn.filter((item) => {
            return activeFilterCategories.type.includes(item.eventType)
          })
        }

        // Filter by duration if necessary
        if (activeFilterCategories.duration.length > 0) {
          filteredItemsToReturn = filteredItemsToReturn.filter((item) => {
            const eventDuration = this.getDurationFromEvent(item, false)
            if (activeFilterCategories.duration.includes(EVENT_DURATION.LESSTHANONEHOUR) && eventDuration < 60) { return true }
            if (activeFilterCategories.duration.includes(EVENT_DURATION.ONETOFOURHOURS) && eventDuration >= 60 && eventDuration < 240) { return true }
            if (activeFilterCategories.duration.includes(EVENT_DURATION.FOURTOEIGHTHOURS) && eventDuration >= 240 && eventDuration < 480) { return true }
            if (activeFilterCategories.duration.includes(EVENT_DURATION.EIGHTPLUSHOURS) && eventDuration >= 480) { return true }
            return false
          })
        }

        // Filter by schedule if necessary
        if (activeFilterCategories.schedule.length > 0) {
          filteredItemsToReturn = filteredItemsToReturn.filter((item) => {
            const eventDate = new Date(item.startDateTime)
            if (activeFilterCategories.schedule.includes(EVENT_SCHEDULE.WEEKDAYS) && eventDate.getDay() >= 1 && eventDate.getDay() <= 5) { return true }
            if (activeFilterCategories.schedule.includes(EVENT_SCHEDULE.WEEKENDS) && (eventDate.getDay() === 0 || eventDate.getDay() === 6)) { return true }
            return false
          })
        }

        // TODO: FUTURE: Reinstate filter by location
        // Filter by location if necessary
        /*
        if (activeFilterCategories.location.length > 0) {
          filteredItemsToReturn = filteredItemsToReturn.filter((item) => {
            return item !== null
          })
        }
        */

        // Filter by price if necessary
        /*
        if (activeFilterCategories.price.length > 0) {
          filteredItemsToReturn = filteredItemsToReturn.filter((item) => {
            return item !== null
          })
        }

        // Filter by start date if necessary
        if (activeFilterCategories.startDate.length > 0) {
          filteredItemsToReturn = filteredItemsToReturn.filter((item) => {
            const eventDate = new Date(item.startDateTime)
            const requestedDate = new Date(activeFilterCategories.startDate[0])
            return eventDate - requestedDate >= 0
          })
        }

        // Filter by end date if necessary
        if (activeFilterCategories.endDate.length > 0) {
          filteredItemsToReturn = filteredItemsToReturn.filter((item) => {
            const eventDate = new Date(item.startDateTime)
            const requestedDate = new Date(activeFilterCategories.endDate[0])
            return eventDate - requestedDate <= 0
          })
        }
        */

        return filteredItemsToReturn
      },

      //showPosition(position) {
      //  var formData = this.GetFilterData();
      //  formData.append("latitude", position.coords.latitude);
      //  formData.append("longitude", position.coords.longitude);

      //  this.FilterEvent(formData);
      //},

      //showError(error) {
      //  var formData = this.GetFilterData();
      //  this.FilterEvent(formData);
      //},

      /*
      FilterEvent(formData) {

        this.eventFilter.submitted = true;
        // stop here if form is invalid
        this.$v.eventFilter.$touch();
        if (this.$v.eventFilter.$invalid) {
          return;
        }

        var geocoder = this.$geocoder;
        var address = this.eventFilter.address;
        var self = this;
        geocoder.geocode({ 'address': address }, function (results, status) {
          if (status === 'OK') {
            formData.append("Latitude", results[0].geometry.location.lat());
            formData.append("Longitude", results[0].geometry.location.lng());
          }

          ProviderService.FilterEvent(formData)
            .then(response => {
              console.log('FilterEvent response.data');
              console.log(response.data);
              var result = response.data;
              self.events = JSON.parse(JSON.stringify(result));
              self.pagination.totalItems = self.events.length;
            })
            .catch(function (err) {
              console.log(err);
            });
        });
      },
      */

      /*
      OnFilterClick() {

        console.log(this.eventFilter);
        var formData = this.GetFilterData();
        this.FilterEvent(formData);

        //if (this.eventFilter.lessThanFiveMiles == true || this.eventFilter.fiveToTenMiles == true || this.eventFilter.tenToTwentyMiles == true || this.eventFilter.greaterThanTwenty == true) {

        //  if (navigator.geolocation) {
        //    //var optn = {
        //    //  enableHighAccuracy: true,
        //    //  timeout: Infinity,
        //    //  maximumAge: 0
        //    //};
        //    navigator.geolocation.getCurrentPosition(this.showPosition, this.showError);
        //  }
        //  else {
        //    this.FilterEvent(formData);
        //  }
        //}
        //else {
        //  this.FilterEvent(formData);
        //}
      },
      */
      async GetCompanyLogo() {
        try {
          const employerService = new EmployerService(this.$store)
          const companyLogoSignedURL = await employerService.GetCompanyLogoSignedURL(this.$store.getters.employer)
          this.companyLogoSignedURL = companyLogoSignedURL
        } catch (e) {
          return process.env.VUE_APP_placeholder_company_logo_url
        }
      },
      getAddressStringFromEvent(event) {
        try { return FormatAddressObjectToString(event.address) } catch { return "" }
      },
      getDateFromDateTime(dateTimeString) {
        return GetLocaleDateFromDateTime(dateTimeString)
      },
      getTimeFromDateTime(dateTimeString) {
        return GetTimeFromDateTime(dateTimeString)
      },
      getDurationFromEvent(eventObject, shouldFormat) {
        if (shouldFormat == null) {
          shouldFormat = true
        }
        try {
          const eventDuration = EventDurationInMinutes(eventObject)
          if (shouldFormat) {
            return FormatDuration(eventDuration, DESIRED_DURATIONS.HoursMinutes)
          }
          return eventDuration
        } catch (e) {
          return ''
        }
      }
    },


    computed: {
      pages() {
        try {
          //console.log("pagination in pages:", this.pagination)
          if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 1
          return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        } catch (err) {
          console.warn("error getting pages;", err)
          return 1
        }
      },
      companyId: function() {
        try { return this.$store.getters.employer.id } catch (e) { return '' }
      },
      employerName: function() {
        try { return this.$store.getters.employer.name } catch (e) { return '' }
      },
      events: function() {
        return this.$store.getters.providerFutureLiveEvents
      },
      providerUpcomingEvents: function() {
        return this.$store.getters.providerUpcomingEvents
      },
      activeFilterCategories: function() {
        const type = [
          this.eventFilter.free ? EVENT_TYPE.FREE : null,
          this.eventFilter.paid ? EVENT_TYPE.PAID : null,
          this.eventFilter.conference ? EVENT_TYPE.CONFERENCE : null
        ].filter((item) => item != null)

        const duration = [
          this.eventFilter.lessThanOneHour ? EVENT_DURATION.LESSTHANONEHOUR : null,
          this.eventFilter.oneToFourHours ? EVENT_DURATION.ONETOFOURHOURS : null,
          this.eventFilter.fourToEightHours ? EVENT_DURATION.FOURTOEIGHTHOURS : null,
          this.eventFilter.eightPlusHours ? EVENT_DURATION.EIGHTPLUSHOURS : null,
        ].filter((item) => item != null)

        const schedule = [
          this.eventFilter.weekdays ? EVENT_SCHEDULE.WEEKDAYS : null,
          this.eventFilter.weekends ? EVENT_SCHEDULE.WEEKENDS : null
        ].filter((item) => item != null)

        /*
        const location = [
          this.eventFilter.lessThanFiveMiles ? EVENT_LOCATION.LESSTHANFIVEMILES : null,
          this.eventFilter.fiveToTenMiles ? EVENT_LOCATION.FIVETOTENMILES : null,
          this.eventFilter.tenToTwentyMiles ? EVENT_LOCATION.TENTOTWENTYMILES : null,
          this.eventFilter.greaterThanTwenty ? EVENT_LOCATION.TWENTYPLUSMILES : null
        ].filter((item) => item != null)

        const price = [
          this.eventFilter.lessThanTwoHundred ? EVENT_PRICE.LESSTHANTWOHUNDREDPOUNDS : null,
          this.eventFilter.twoHundredToThreeHundred ? EVENT_PRICE.TWOHUNDREDTOTHREEHUNDREDPOUNDS : null,
          this.eventFilter.greaterThanThreeHundred ? EVENT_PRICE.THREEHUNDREDPLUSPOUNDS : null
        ].filter((item) => item != null)

        const startDate = [
          this.eventFilter.startDate.length > 0 ? this.eventFilter.startDate : null
        ].filter((item) => item != null)

        const endDate = [
          this.eventFilter.endDate.length > 0 ? this.eventFilter.endDate : null
        ].filter((item) => item != null)
        */

        return {
          type,
          duration,
          schedule,
          //location,
          //price,
          //startDate,
          //endDate
        }

      }
    },

    mounted() {
      this.$parent.$parent.$parent.refreshMenu('home');
      this.is_data_fetched = true;
      this.pagination.totalItems = this.events.length
      this.GetCompanyLogo()
      //console.log("pagination on mount:", this.pagination)
    }
  }
</script>


<style scoped>

  .v-btn {
    text-decoration: none;
    text-transform: capitalize;
  }

  .container-rounded {
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 5px;
    margin: 0 0 10px;
  }

  .container-rounded-grey {
    border-radius: 10px;
    background-color: rgba(243, 246,248, 0.7);
    padding: 5px;
  }

  .ABC_in_Scaffolding {
    opacity: 1;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(39,62,75,1);
    letter-spacing: 0.2px;
  }

  .Duration__1_hour {
    opacity: 1;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(139,139,139,1);
    letter-spacing: 0.2px;
  }

  .right-align-button {
    margin-left: auto;
    margin-right: 0;
  }

  .event-button-container {
    align-items: flex-end;
    justify-content: center;
    display: flex;
    flex: 0 0 1;
    margin-bottom: 0.33em;
  }

  .img-circle {
    border-radius: 60%;
    box-sizing: border-box;
  }

  .achievements {
    margin: 0;
    padding: 0;
    list-style: none;
    border-radius: 6px;
    background: #FFA374;
  }

    .achievements li {
      padding: 2px 10px;
      color: #fff;
      width: 100%;
    }

      .achievements li span {
        float: right;
      }

  .filter-panel {
    margin-right: 60px;
    margin-left: 30px;
    margin-top: -25px;
    border-radius: 6px;
    margin-bottom: 10px;
  }

    .filter-panel label, .filter-panel p {
      margin: 0;
    }

    .filter-panel label {
      font-size: 13px;
    }

    .filter-panel:before {
      content: '';
      border-left-color: #808080 !important;
      width: 0px;
      height: 0px;
      border: solid 10px transparent;
      position: absolute;
      left: 100%;
      top: 5px;
      z-index: 1;
    }

    .filter-panel:after {
      content: '';
      border-left-color: #ffffff !important;
      width: 0px;
      height: 0px;
      border: solid 10px transparent;
      position: absolute;
      left: 100%;
      top: 5px;
      z-index: 2;
    }

  .scheduled-event div[role="listitem"] {
    background: #f5f5f5;
    padding: 0;
    margin-bottom: 7px;
    box-shadow: 0 0 3px 0px #ccc;
    width: 100%;
  }

    .scheduled-event div[role="listitem"] * {
      height: auto !important;
      display: block;
      padding: 0 10px;
      color: #019B91;
    }
</style>

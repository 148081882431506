
import * as Strings from '../constants/Strings'

export const GetLocaleString = (stringCategory, stringId) => {

  // Get the language from the locale (temporarily)
  const locale = navigator.language.split("-")[0]

  try {
    return Strings[locale][stringCategory][stringId]
  } catch (err) {
    console.log("Error getting locale string:", err)
    return stringId
  }
}

<template>
  <v-container pa-0 ma-0 fluid>
    <loading :active="!is_data_fetched" :is-full-page="true" loader="dots"></loading>
    <v-layout row wrap ma-0 justify-center align-content-center v-if="is_data_fetched">
      Create this page
    </v-layout>
  </v-container>
</template>

<script>

  export default {

    components: {},

    data() {
      return {
        is_data_fetched: false,
      }
    },

    watch: {},

    methods: {},

    computed: {},

    async mounted() {
      this.is_data_fetched = true;
    }

  }
</script>

<style scoped>

</style>

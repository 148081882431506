<template>
  <v-container pa-0 ma-0 fluid v-if="is_data_fetched">
    <loading :active.sync="actionInProgress" :is-full-page="true" loader="dots"></loading>
    <v-img :src="require('../images/contactdetailsbackground.png')" style="height:150px;" class="container-rounded-subheader">
      <v-layout>
        <v-flex xs12>
          <v-layout style="position:relative;">
            <v-flex x12>
              <v-layout row wrap>
                <v-flex>
                  <table style="width:97%;">
                    <tr>
                      <td style="width:10%">
                        <event-logo v-bind:imageKey="imageKey" level="public" style="border: 2px solid #fafafa; position: relative; height: 80px; width: 80px; bottom: -95px; background: #fafafa"/>
                      </td>
                      <td>
                        <div style="position:relative;margin-left:5px;vertical-align:bottom;bottom:-80px;">
                          <div style="float:left;font-size: 16px;">{{ name }}</div>
                          <div style="max-height:5px;max-width:5px;margin-top:-12px">
                            <v-icon v-if="userIsAdmin" style="float:left;" @click="$refs.logoUpload.click()">photo_camera</v-icon>
                            <input v-if="userIsAdmin" style="float:left;" v-show="false" ref="logoUpload" type="file" accept="image/png, image/gif, image/jpeg" @change="logoChange">
                          </div>
                          <div v-if="userIsAdmin" style="font-size:9px;font-weight:normal;float:left;margin-top:5px;">Change company logo</div>
                        </div>
                      </td>
                      <td>
                        <div class="personal-id-container">
                          <div class="personal-id"><strong>Company ID: </strong>{{ id }}</div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-img>
    <v-card class="container-rounded-mini">
      <v-layout row wrap justify-center>
        <v-flex xs10 md6>
          <v-tabs v-model="model" slider-color="cyan">
            <v-tab key="contactdetails" href="#tab-contactdetails">
              <b style="text-transform: capitalize;font-size: 16px">Contact Details</b>
            </v-tab>
            <v-spacer></v-spacer>
            <v-tab key="about" href="#tab-about">
              <b style="text-transform: capitalize;font-size: 16px">About</b>
            </v-tab>
            <v-spacer></v-spacer>
            <v-tab key="employeeGroups" href="#tab-employee-groups">
              <b style="text-transform: capitalize;font-size: 16px">Employee Groups</b>
            </v-tab>
            <v-spacer></v-spacer>
            <v-tab key="employees" href="#tab-employees">
              <b style="text-transform: capitalize;font-size: 16px">Employees</b>
            </v-tab>
          </v-tabs>
        </v-flex>
      </v-layout>
    </v-card>
    <v-layout>
      <v-flex>
        <v-tabs-items v-model="model">

          <!-- Start Contact Tab -->
          <v-tab-item value="tab-contactdetails" key="contactdetails">
            <v-card class="container-rounded">
              <v-layout wrap centered>
                <v-flex xs12 md8>
                  <form id="saveContact" method="post" class="form-horizontal" enctype="multipart/form-data" style="padding: 0 15px;">
                    <div>
                      <br />
                      <div class="form-row" style="font-weight:normal;">
                        <div class="col-sm-4 control-label">Organisation Name <span class="red--text">*</span></div>
                        <input type="text" class="form-control col-sm-6" v-model="name" id="name" name="name" :disabled="!userIsAdmin">
                        <div v-if="checkCurrentData && $v.name.$error" class="text-danger">
                          <small v-if="!$v.name.required">Organization Name is required</small>
                          <small v-if="!$v.name.maxLength">Organization Name should be less than 500 characters.</small>
                        </div>
                      </div>
                      <br />

                      <!-- Start Address -->
                      <div class="form-row" style="font-weight:normal;">
                        <div class="col-sm-4 control-label">Address Line 1 <span class="red--text">*</span></div>
                        <input type="text" class="form-control col-sm-8" id="line1" name="line1" v-model="addressLine1" placeholder="Line 1"  :disabled="!userIsAdmin"/>
                        <div v-if="checkCurrentData && $v.addressLine1.$error" class="text-danger">
                          <small v-if="!$v.addressLine1.required">Address is required</small>
                          <small v-if="!$v.addressLine1.maxLength">Address Line 1 should be less than 100 characters</small>
                        </div>
                      </div>

                      <div class="form-row" style="font-weight:normal;">
                        <div class="col-sm-4 control-label">Address Line 2</div>
                        <input type="text" class="form-control col-sm-8" id="line2" name="line2" v-model="addressLine2" placeholder="Line 2"  :disabled="!userIsAdmin"/>
                        <div v-if="checkCurrentData && $v.addressLine2.$error" class="text-danger">
                          <small v-if="!$v.addressLine2.maxLength">Address Line 2 should be less than 100 characters</small>
                        </div>
                      </div>

                      <div class="form-row" style="font-weight:normal;">
                        <div class="col-sm-4 control-label">Address Line 3</div>
                        <input type="text" class="form-control col-sm-8" id="line3" name="line3" v-model="addressLine3" placeholder="Line 3"  :disabled="!userIsAdmin"/>
                        <div v-if="checkCurrentData && $v.addressLine3.$error" class="text-danger">
                          <small v-if="!$v.addressLine3.maxLength">Address Line 3 should be less than 100 characters</small>
                        </div>
                      </div>

                      <div class="form-row" style="font-weight:normal;">
                        <div class="col-sm-4 control-label">City <span class="red--text">*</span></div>
                        <input type="text" class="form-control col-sm-8" id="city" name="city" v-model="addressCity" placeholder="City (to be displayed on certificate)"  :disabled="!userIsAdmin"/>
                        <div v-if="checkCurrentData && $v.addressCity.$error" class="text-danger">
                          <small v-if="!$v.addressCity.required">City is required</small>
                          <small v-if="!$v.addressCity.maxLength">City should be less than 100 characters</small>
                        </div>
                      </div>

                      <div class="form-row" style="font-weight:normal;">
                        <div class="col-sm-4 control-label">State/County</div>
                        <input type="text" class="form-control col-sm-8" id="region" name="region" v-model="addressRegion" placeholder="State/County"  :disabled="!userIsAdmin"/>
                        <div v-if="checkCurrentData && $v.addressRegion.$error" class="text-danger">
                          <small v-if="!$v.addressRegion.required">State/County is required</small>
                          <small v-if="!$v.addressRegion.maxLength">State/County should be less than 100 characters</small>
                        </div>
                      </div>

                      <div class="form-row" style="font-weight:normal;">
                        <div class="col-sm-4 control-label">Post Code <span class="red--text">*</span></div>
                        <input type="text" class="form-control col-sm-8" id="postalCode" name="postalCode" v-model="addressPostalCode" placeholder="Post Code"  :disabled="!userIsAdmin"/>
                        <div v-if="checkCurrentData && $v.addressPostalCode.$error" class="text-danger">
                          <small v-if="!$v.addressPostalCode.required">Post Code is required</small>
                          <small v-if="!$v.addressPostalCode.maxLength">Post Code should be less than 10 characters</small>
                        </div>
                      </div>

                      <div class="form-row" style="font-weight:normal;">
                        <div class="col-sm-4 control-label">Country <span class="red--text">*</span></div>
                        <input type="text" class="form-control col-sm-8" id="country" name="country" v-model="addressCountry" placeholder="Country"  :disabled="!userIsAdmin"/>
                        <div v-if="checkCurrentData && $v.addressCountry.$error" class="text-danger">
                          <small v-if="!$v.addressCountry.required">Country is required</small>
                          <small v-if="!$v.addressCountry.maxLength">Country should be less than 100 characters</small>
                        </div>
                      </div>
                      <br />
                      <!-- End Address -->

                      <div class="form-row" style="font-weight:normal;">
                        <div class="col-sm-4 control-label">Contact Email Address <span class="red--text">*</span></div>
                        <input type="text" class="form-control col-sm-6" v-model="contactEmail" id="contactEmail" name="contactEmail" :disabled="!userIsAdmin">
                        <div v-if="checkCurrentData && $v.websiteURL.$error" class="text-danger">
                          <small v-if="!$v.websiteURL.required">Email is required</small>
                        </div>
                      </div>

                      <div class="form-row" style="font-weight:normal;">
                        <div class="col-sm-4 control-label">Contact Phone Number</div>
                        <input type="text" class="form-control col-sm-6" v-model="contactPhone" id="contactPhone" name="contactPhone" :disabled="!userIsAdmin">
                        <div v-if="checkCurrentData && $v.websiteURL.$error" class="text-danger">
                          <small v-if="!$v.websiteURL.required">Phone number is required</small>
                        </div>
                      </div>

                      <div class="form-row" style="font-weight:normal;">
                        <div class="col-sm-4 control-label">Website Address</div>
                        <input type="text" class="form-control col-sm-6" v-model="websiteURL" id="websiteURL" name="websiteURL" :disabled="!userIsAdmin">
                        <div v-if="checkCurrentData && $v.websiteURL.$error" class="text-danger">
                          <small v-if="!$v.websiteURL.required">Website is required</small>
                          <small v-if="!$v.websiteURL.maxLength">Website should be less than 500 characters.</small>
                        </div>
                      </div>
                      <br />

                      <div class="form-row" style="font-weight:normal;">
                        <label class="col-sm-4 control-label" for="RegistrationNumber">Registration Number</label>
                        <input type="text" class="form-control col-sm-6" v-model="registrationNumber" id="RegistrationNumber" name="RegistrationNumber" :disabled="!userIsAdmin">
                        <div v-if="checkCurrentData && $v.registrationNumber.$error" class="text-danger">
                          <small v-if="!$v.registrationNumber.required">Registration Number is required</small>
                          <small v-if="!$v.registrationNumber.maxLength">Registration Number should be less than 100 characters.</small>
                        </div>
                      </div>
                      <br />

                      <!-- TODO: FUTURE: Check if this should be removed.  I assume this is the same address as above in all instances? -->
                      <!--
                      <div class="form-row" style="font-weight:normal;">
                        <label class="col-sm-4 control-label" for="RegistrationAddress">Registration Address</label>
                        <select class="form-control col-sm-6" v-model="contactDetails.selectedRegistrationPlaceId">
                          <option v-for="(registrationPlace, index) in contactDetails.registrationPlaces" v-bind:key="index" v-bind:value="registrationPlace.id">
                            {{registrationPlace.name}}
                          </option>
                        </select>
                        <div v-if="contactDetailsSubmitted && $v.contactDetails.selectedRegistrationPlaceId.$error" class="text-danger">
                          <small v-if="!$v.contactDetails.selectedRegistrationPlaceId.validIndex">Registration Address is required</small>
                        </div>
                      </div>
                      <br />
                      -->

                    </div>
                  </form>
                </v-flex>
                <v-flex xs12 offset-md8 md4 style="text-align:end;">
                  <div v-if="checkCurrentData && $v.$invalid" class="text-danger" style="float:right">
                    <small>Please review your input. Errors are highlighted in red.</small>
                  </div>
                </v-flex>
                <v-flex xs12 offset-md8 md4 style="text-align:end;">
                  <v-btn color="rgb(0,171,173)" @click="SaveEmployerDetails" :disabled="!dataHasChanged">
                    Save
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card>
          </v-tab-item>
          <!-- End Contact Tab -->

          <!-- Start About Tab -->
          <v-tab-item value="tab-about" key="about">
            <v-card class="container-rounded-about">
              <v-layout wrap centered>
                <v-flex xs12>
                  <form id="saveAbout" method="post" class="form-horizontal" enctype="multipart/form-data" style="padding: 0 15px">
                    <div>
                      <br />
                      <div class="form-row" style="font-weight:normal;">
                        <div class="col-sm-3" style="font-size:10px; float:left;">
                          <p style="font-size:14px; font-weight:normal;">Description</p>
                          <p>(this information will be displayed as general information on the course booking tab.</p>
                          <p>You can promote services your organization has to offer to potential trainees.)</p>
                        </div>
                        <div class="col-sm-8 offset-sm-1 p-0 mt-4">
                          <vue-editor v-model="aboutDescription" :disabled="!userIsAdmin"></vue-editor>
                        </div>
                        <div v-if="aboutDetailsSubmitted && $v.aboutDescription.$error" class="text-danger">
                          <small v-if="!$v.aboutDescription.required">Description is required</small>
                          <small v-if="!$v.aboutDescription.maxLength">Description should be less than 10000 characters.</small>
                        </div>
                      </div>
                      <br />
                      <div class="form-row" style="font-weight:normal;">
                        <label class="col-sm-3 mt-4 control-label pl-0" for="whyChooseUs">Why Choose Us</label>
                        <div class="col-sm-8 offset-sm-1 p-0 mt-4">
                          <vue-editor v-model="aboutWhyChooseUs" :disabled="!userIsAdmin"></vue-editor>
                        </div>
                        <div v-if="aboutDetailsSubmitted && $v.aboutWhyChooseUs.$error" class="text-danger">
                          <small v-if="!$v.aboutWhyChooseUs.required">Why Choose Us is required</small>
                          <small v-if="!$v.aboutWhyChooseUs.maxLength">Why Choose Us should be less than 10000 characters.</small>
                        </div>
                      </div>

                      <!-- Accreditation image uploader -->
                      <div class="form-row mt-4" style="font-weight:normal;">
                        <label class="col-sm-3 control-label" for="uploadaccreditationlogo">Upload Accreditation / Partner logos</label>
                        <div class="col-sm-8 offset-sm-1 p-0 mt-4">
                          <image-uploader
                            pickerId="accreditationLogo"
                            :isPicker="false"
                            :disabled="!userIsAdmin"
                            :closePopover="ToggleImagePopover"
                            :imageType="IMAGE_TYPES.ACCREDITATION_LOGO"
                            :imageOptions="accreditationLogos"
                            :onImageSelectionChange="OnImageSelectionChange" />
                        </div>
                      </div>
                      <br />
                    </div>
                  </form>
                </v-flex>
                <v-flex xs12 offset-md8 md4 style="text-align:end;">
                  <div v-if="aboutDetailsSubmitted && $v.$invalid" class="text-danger" style="float:right">
                    <small>Please review your input. Errors are highlighted in red.</small>
                  </div>
                </v-flex>
                <v-flex xs12 offset-md8 md4 style="text-align:end;">
                  <v-btn color="rgb(0,171,173)" @click="SaveEmployerDetails" :disabled="!dataHasChanged">
                    Save
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card>
          </v-tab-item>
          <!-- End About Tab -->

          <!-- Start Employee Groups Tab -->
          <v-tab-item value="tab-employee-groups" key="employeeGroups">
            <v-card class="container-rounded">
              <v-layout column style="background-color:white;padding: 0 10px;">
                <br />
                <v-flex>
                  <v-flex xs12><h5 class="page-header">Employee Groups</h5></v-flex>
                </v-flex>
                <v-layout>
                  <v-flex xs12 style="text-align: right" class="grid-search">
                    <a v-if="userIsAdmin" style="color:rgb(0,171,173);font-weight:normal;text-align:right;" @click="ToggleAddEmployerGroupDialog()">
                      <v-icon color="rgb(0,171,173)">add_circle</v-icon> &nbsp;Add Group
                    </a>
                  </v-flex>
                </v-layout>
                <br />

                <!-- Employee Group Table -->
                <v-flex md12>
                  <v-data-table
                    :headers="employeeGroupTableHeaders"
                    :items="employerGroups"
                    :search="searchText"
                    hide-actions
                    :pagination.sync="pagination"
                    class="elevation-1 mb-3">

                    <template slot="headerCell" slot-scope="{ header }">
                      <span class="subheading font-weight-bold text--darken-3" v-text="header.text" />
                    </template>

                    <template v-slot:items="props">
                      <td>
                        <a v-if="userIsAdmin" href="#" style="color:rgb(0,171,173);" @click="ShowEmployerGroupMembersDialog(props.item)">
                          {{ props.item.name }}
                        </a>
                        <div v-else style="color:rgb(0,171,173);">
                          {{ props.item.name }}
                        </div>
                      </td>

                      <td>
                        <div class="action-cell">
                          <v-img class='image' v-if="userIsAdmin" :src="require('@/images/pencil-edit-button.png')" contain height="15px" width="15px" @click="ShowEditEmployerGroupDialog(props.item)"></v-img>
                          <v-img class='image' v-if="userIsAdmin" :src="require('@/images/trash32.png')" contain height="15px" width="15px" @click="DeleteEmployerGroup(props.item.id)"></v-img>
                        </div>
                      </td>

                    </template>

                  </v-data-table>
                </v-flex>


                <v-dialog v-model="showAddEmployerGroupDialog" persistent transition="dialog-transition" max-width="50%">
                  <employer-groups-dialog :groups="employerGroups" :closePopover="ToggleAddEmployerGroupDialog" />
                </v-dialog>

                <v-dialog v-model="showEditEmployerGroupDialog" persistent transition="dialog-transition" max-width="50%">
                  <employer-groups-dialog :groups="employerGroups" :groupToEdit="groupToEdit" :closePopover="ToggleEditEmployerGroupDialog" />
                </v-dialog>

                <v-dialog v-model="showEmployerGroupMembersDialog" persistent transition="dialog-transition" max-width="50%">
                  <employer-group-members-dialog :groupDetails="groupToViewMembersOf" :closePopover="ToggleEmployerGroupMembersDialog" />
                </v-dialog>

                

                <v-layout v-if="this.pagination.totalItems > 0">
                  <v-flex xs12 text-right>
                    <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
                  </v-flex>
                </v-layout>

                <v-layout row justify-center>
                  <v-dialog persistent v-model="messageDialog.show" transition="dialog-transition" max-width="450">
                    <v-card>
                      <v-card-text>
                        <v-flex>
                          <div id="message" style="font-weight:normal;">
                            {{messageDialog.message}}
                          </div>
                        </v-flex>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="CloseMessageDialog" small color=rgb(0,171,173)>OK</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-layout>

              </v-layout>
            </v-card>
          </v-tab-item>
          <!-- End Employee Groups Tab -->

          <!-- Start Employees Tab -->
          <v-tab-item value="tab-employees" key="employees">
            <v-card class="container-rounded">
              <v-layout column style="background-color:white;padding: 0 10px;">
                <br />
                <v-flex>
                  <v-flex xs12><h5 class="page-header">Employees</h5></v-flex>
                </v-flex>
                <v-layout>
                  <v-flex xs12 style="text-align: right" class="grid-search">
                    <a v-if="userIsAdmin" style="color:rgb(0,171,173);font-weight:normal;text-align:right;margin-right: 30px" @click="ToggleAddEmployeeDialog()">
                      <v-icon color="rgb(0,171,173)">add_circle</v-icon> &nbsp;Add Employee
                    </a>
                    <v-text-field v-model="searchText" append-icon="search" label="Search for an employee" single-line class="float-right" hide-details></v-text-field>
                  </v-flex>
                </v-layout>
                <br />

                <!-- Confirmed Employee Table -->
                <v-flex md12>
                  <v-data-table
                    :headers="headers"
                    :items="employees"
                    :search="searchText"
                    hide-actions
                    :pagination.sync="pagination"
                    :custom-sort="customSortEmployees"
                    class="elevation-1 mb-3">

                    <template slot="headerCell" slot-scope="{ header }">
                      <span class="subheading font-weight-bold text--darken-3" v-text="header.text" />
                    </template>
                    <template v-slot:items="props">
                      <td>
                        <a v-if="userIsAdmin" href="#" style="color:rgb(0,171,173);" @click="ShowEditEmployeeDialog(props.item)">{{ props.item.user.firstName }} {{ props.item.user.lastName }}</a>
                        <div v-else style="color:rgb(0,171,173);">{{ props.item.user.firstName }} {{ props.item.user.lastName }}</div>
                      </td>
                      <td>{{ props.item.accessRights.isCourseInstructor ? "Yes" : "No" }}</td>
                      <td>{{ props.item.accessRights.isAdministrator ? "Yes" : "No" }}</td>
                      <td>
                        <div class="group-name" v-if="props.item.employerGroups.items.length > 0">
                          {{ getGroupNames(props.item.employerGroups.items) }}
                        </div>
                        <div class="group-name" v-if="props.item.employerGroups.items.length == 0" >
                          No Groups
                        </div>
                      </td>
                      <td>
                        <div class="action-cell">
                          <v-img class='image' v-if="userIsAdmin" :src="require('@/images/pencil-edit-button.png')" contain height="15px" width="15px" @click="ShowEditEmployeeDialog(props.item)"></v-img>
                          <v-img class='image' v-if="userIsAdmin" :src="require('@/images/trash32.png')" contain height="15px" width="15px" @click="DeleteEmployee(props.item.id)"></v-img>
                        </div>
                      </td>
                    </template>
                  </v-data-table>
                </v-flex>

                <br />
                <!-- Pending Employee Table -->
                <v-flex md12>
                  <v-flex xs12>
                    <h5 class="page-header mb-3">Pending Employees</h5>
                  </v-flex>

                  <v-data-table
                    :headers="pendingEmployeeHeaders"
                    :items="pendingEmployees"
                    :search="searchText"
                    hide-actions
                    :pagination.sync="pagination"
                    :custom-sort="customSortEmployees"
                    no-data-text="No pending employees"
                    class="elevation-1 mb-3">

                    <template slot="headerCell" slot-scope="{ header }">
                      <span class="subheading font-weight-bold text--darken-3" v-text="header.text" />
                    </template>
                    <template v-slot:items="props">
                      <td>
                        <div style="color:rgb(0,171,173);">{{ props.item.emailAddress }}</div>
                      </td>
                      <td>{{ props.item.accessRights.isCourseInstructor ? "Yes" : "No" }}</td>
                      <td>{{ props.item.accessRights.isAdministrator ? "Yes" : "No" }}</td>
                      <td>
                        <div class="action-cell">
                          <v-img class='image' v-if="userIsAdmin" :src="require('@/images/trash32.png')" contain height="15px" width="15px" @click="DeletePendingEmployee(props.item.id)"></v-img>
                        </div>
                      </td>
                    </template>
                  </v-data-table>
                </v-flex>



                <v-dialog v-model="showAddEmployeeDialog" persistent transition="dialog-transition" max-width="50%">
                  <add-employee-dialog :employees="employees" :pendingEmployees="pendingEmployees" :closePopover="ToggleAddEmployeeDialog" />
                </v-dialog>

                <v-dialog v-model="showEditEmployeeDialog" persistent transition="dialog-transition" max-width="50%">
                  <edit-employee-dialog :userDetails="employeeToEdit" :closePopover="ToggleEditEmployeeDialog" />
                </v-dialog>

                <v-layout v-if="this.pagination.totalItems > 0">
                  <v-flex xs12 text-right>
                    <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
                  </v-flex>
                </v-layout>

                <v-layout row justify-center>
                  <v-dialog persistent v-model="messageDialog.show" transition="dialog-transition" max-width="450">
                    <v-card>
                      <v-card-text>
                        <v-flex>
                          <div id="message" style="font-weight:normal;">
                            {{messageDialog.message}}
                          </div>
                        </v-flex>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="CloseMessageDialog" small color=rgb(0,171,173)>OK</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-layout>

              </v-layout>
            </v-card>
          </v-tab-item>
          <!-- End Employees Tab -->

        </v-tabs-items>
      </v-flex>


      <v-dialog persistent v-model="errorDialogPopover.show" transition="dialog-transition" max-width="500">
        <v-card>
          <v-card-text>
            <v-flex>
              <span style="font-weight:normal;">
                <div v-html="errorDialogPopover.message"></div>
              </span>
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="CloseErrorDialog" small color=rgb(0,171,173)>Ok</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


    </v-layout>
  </v-container>
</template>



<script>
  import { validationMixin } from 'vuelidate'
  import { required, maxLength } from 'vuelidate/lib/validators'
  import { VueEditor } from "vue2-editor"

  import ProviderService from '../services/provider.service'
  import EventLogo from './eventlogo-container'
  import ProviderAddEmployee from './provider-addemployee'
  import ProviderEditEmployee from './provider-editemployee'
  import ProviderEmployerGroupDialog from './provider-employergroupdialog'
  import ProviderEmployerGroupMembersDialog from '@/components/provider-employergroupmembersdialog'
  import EmployerService from '../services/employer.service'
  import CommonImageUploader from './common-image-uploader'

  import { LAYOUTS } from '@/constants/LayoutConstants'
  import { IMAGE_TYPES } from '../constants/ImageConstants'
  import { Storage } from 'aws-amplify';
  //import lodash from 'lodash'
  import { ObjectsAreDifferent } from '../helpers/general.helper'

  import 'vue2-editor/dist/vue2-editor.css'

  /*
  const validIndex = (value, vm) => {
    if (vm.selectedRegistrationPlaceId == -1)
      return false;
    return true;
  }
  */

  // TODO: FUTURE: Indication that save has worked

  const DEFAULT_EMPLOYER_DATA = {
    id: '',
    name: '',
    description: '',
    address: {
      line1: '',
      line2: '',
      line3: '',
      city: '',
      region: '',
      postalCode: '',
      country: ''
    },
    logo: {
      id: '',
      name: '',
      imageKey: ''
    },
    contactDetails: {
      emailBookings: '',
      phoneBookings: ''
    },
    isCourseProvider: true,
    courseProviderDetails: {
      description: '',
      whyChooseUs: '',
      websiteURL: '',
      registrationNumber: ''
    },
  }

  export default {

    components: {
      VueEditor,
      'event-logo': EventLogo,
      'add-employee-dialog': ProviderAddEmployee,
      'edit-employee-dialog': ProviderEditEmployee,
      'employer-groups-dialog': ProviderEmployerGroupDialog,
      'employer-group-members-dialog': ProviderEmployerGroupMembersDialog,
      'image-uploader': CommonImageUploader,
    },


    mixins: [validationMixin],
    validations: {
      name: { required, maxLength: maxLength(500) },
      addressLine1: { required, maxLength: maxLength(100) },
      addressLine2: { maxLength: maxLength(100) },
      addressLine3: { maxLength: maxLength(100) },
      addressCity: { required, maxLength: maxLength(100) },
      addressRegion: { maxLength: maxLength(100) },
      addressPostalCode: { required, maxLength: maxLength(10) },
      addressCountry: { required, maxLength: maxLength(100) },
      contactEmail: { required },
      websiteURL: { maxLength: maxLength(500) },
      registrationNumber: { maxLength: maxLength(100) },
      aboutDescription: { maxLength: maxLength(10000) },
      aboutWhyChooseUs: { maxLength: maxLength(10000) },
    },

    data() {
      return {
        model: 'tab-contactdetails',
        is_data_fetched: false,
        actionInProgress: false,
        messageDialog: {
          show: false,
          message: '',
          redirect: false,
          tab: 1
        },
        searchText: '',
        pagination: {
          rowsPerPage: 10,
          totalItems: 0
        },
        headers: [
          { text: 'Employee',         align: 'left',    value: 'employeeName',    sortable: true    },
          { text: 'Is Instructor',    align: 'left',    value: 'isInstructor',    sortable: true    },
          { text: 'Is Administrator', align: 'left',    value: 'isAdministrator', sortable: true    },
          { text: 'Groups',           align: 'left',    value: 'groups',          sortable: true    },
          { text: 'Actions',          align: 'center',  value: 'action',          sortable: false,  width: "10%"   }
        ],
        pendingEmployeeHeaders: [
          { text: 'Email Address',    align: 'left',    value: 'emailAddress',    sortable: true    },
          { text: 'Is Instructor',    align: 'left',    value: 'isInstructor',    sortable: true    },
          { text: 'Is Administrator', align: 'left',    value: 'isAdministrator', sortable: true    },
          { text: 'Actions',          align: 'center',  value: 'action',          sortable: false   }
        ],
        employeeGroupTableHeaders: [
          { text: 'Group Name',       align: 'left',    value: 'name',      sortable: true    },
          { text: 'Actions',          align: 'center',  value: 'action',    sortable: false,  width: "10%" }
        ],
        content: "<h1>Some initial content</h1>",
        contactDetailsSubmitted: true,
        contactDetails: {
          id: 0,
          personalId: 0,
          logo: '',
          fileName: '',
          organizationName: '',
          postCode: '',
          address: '',
          webSite: '',
          registrationNumber: '',
          selectedRegistrationPlaceId: -1,
          registrationPlaces: []
        },
        imageAttributes: {
          maxImage: 6,
          browseText: 'Browse',
          primaryText: 'Browse',
          markIsPrimaryText: 'Browse',
          dragText: 'Upload your accreditation logos here',
          popupText: 'Browse',
          multiple: true,
          accept: 'image/gif,image/jpeg,image/png,image/bmp,image/jpg',
        },
        aboutDetailsSubmitted: false,
        aboutDetails: {
          id: 0,
          description: '',
          whyChooseUs: '',
          images: []
        },
        editInstructorSubmitted: false,
        editInstructor: {
          id: 0,
          instructor: '',
          phone: '',
          email: '',
          aboutInstructor: '',
        },
        originalCompanyData: JSON.parse(JSON.stringify(DEFAULT_EMPLOYER_DATA)),
        changedData: JSON.parse(JSON.stringify(DEFAULT_EMPLOYER_DATA)),
        employees: [],
        pendingEmployees: [],
        checkCurrentData: false,
        showAddEmployeeDialog: false,
        showEditEmployeeDialog: false,
        error_updating_details: false,
        employeeToEdit: {},
        instructorIdToEdit: '',
        showImageUploaderPopover: false,
        IMAGE_TYPES,
        errorDialogPopover: {
          show: false,
          message: ''
        },
        showAddEmployerGroupDialog: false,
        showEditEmployerGroupDialog: false,
        showEmployerGroupMembersDialog: false,
        employerGroups: [],
        groupToEdit: {},
        groupToViewMembersOf: {},
      }
    },

    watch: {
      /*
      aboutDescription: function() {
        console.log("WATCHER: aboutDescription changed")
        console.log("this.dataHasChanged:", this.dataHasChanged)
      },
      originalCompanyData: function() {
        console.log("WATCHER: originalCompanyData changed")
      },
      changedData: function() {
        console.log("WATCHER: changedData changed")
      },
      "changedData.courseProviderDetails.whyChooseUs": function() {
        console.log("WATCHER: changedData.courseProviderDetails.whyChooseUs changed")
      },
      "changedData.courseProviderDetails.description": function() {
        console.log("WATCHER: changedData.courseProviderDetails.description changed")
      },
      */
      '$store.getters.employer.id': function() {
        // Fetches the employer details if the chosen provider changes in admin mode
        this.FetchEmployerDetails()
      }
    },

    methods: {
      customSortEmployees(items, index, isDesc) {
        try {
          const result = items.sort((a, b) => {
            this.sortBy = index;
            this.sortDesc = isDesc;

            // Employee Name Sort
            if (index === "employeeName") {
              if (!isDesc) { return `${a.user.firstName} ${a.user.lastName}` < `${b.user.firstName} ${b.user.lastName}` ? -1 : 1 }
              return `${a.user.firstName} ${a.user.lastName}` > `${b.user.firstName} ${b.user.lastName}` ? -1 : 1
            }

            // Is Instructor Sort
            if (index === "isInstructor") {
              if (!isDesc) { return a.accessRights.isCourseInstructor < b.accessRights.isCourseInstructor ? -1 : 1 }
              return a.accessRights.isCourseInstructor > b.accessRights.isCourseInstructor ? -1 : 1
            }

            // Is Administrator Sort
            if (index === "isAdministrator") {
              if (!isDesc) { return a.accessRights.isAdministrator < b.accessRights.isAdministrator ? -1 : 1; }
              return a.accessRights.isAdministrator > b.accessRights.isAdministrator ? -1 : 1;
            }

          });
          return result;
        } catch (e) {
          console.warn("Error when custom sorting:", e)
        }
        return items
      },
      // TODO: FUTURE: Ensure image refresh works.
      async logoChange(e) {
        const logoName = `${this.$store.getters.employer.id}_${(new Date()).toISOString()}`

        // Ensure that you have a file before attempting to read it
        var input = e.target;
        if (!input.files || !input.files[0]) {
          console.log("No files present")
          return
        }

        // Extract the file and it's details
        const file = input.files[0]

        // Check if the image is too large to display.
        const fileSize = file.size
        if (fileSize > (2 * 1024 * 1024)) {
          this.errorDialogPopover.message = `The chosen image is too large (${(fileSize / 1024).toFixed(0)} KB).  The maximum size is 2048 KB (2 MB).  Please make the image smaller and attempt to upload again.`
          this.errorDialogPopover.show = true
          return;
        }

        // Upload the image to the store
        try {
          // upload the file
          // eslint-disable-next-line
          const uploadResult = await Storage.put(logoName, file, {
            level: 'public',
            //contentType: 'image/png'
            progressCallback(progress) { console.log(`Uploaded: ${progress.loaded}/${progress.total}`); } //Remove this if not required.  Debug only
          });
          // log the resulting filename
          //console.log(uploadResult)
        } catch (error) {
          console.warn('Error uploading new logo: ', error);
        }

        // Update the server with the new logo name
        const parameters = {
          id: this.$store.getters.employer.id,
          logo: {
            ...this.$store.getters.employer.logo,
            id: this.$store.getters.employer.id,
            imageKey: logoName
          }
        }
        // Fetch the updated user details from the database
        const employerService = new EmployerService(this.$store)
        await employerService.UpdateDetails(parameters)
        await this.FetchEmployerDetails()
        await employerService.UpdateLogo({
          imageKey: logoName
        })
      },

      CloseMessageDialog() {
        this.messageDialog.show = false;
        this.messageDialog.message = '';
        if (this.messageDialog.redirect == true) {
          this.$router.push({ name: `${ this.$store.getters.layout === LAYOUTS.ADMIN ? 'AdminContactDetails' : 'ProviderContactDetails' }`, params: { tab: this.messageDialog.tab } })
          this.messageDialog.redirect = false;
          this.messageDialog.tab = 1;
        }
      },

      async DeleteEmployee(employeeRoleId) {
        //console.log("Deleting employee with Id:", employeeRoleId)
        try {
          this.actionInProgress = true;
          const employerService = new EmployerService(this.$store)
          await employerService.RemoveEmployee(employeeRoleId)
          //console.log(result)
          await this.FetchEmployerDetails()
        } catch (err) {
          console.warn("Error deleting employee:", err)
        }
        this.actionInProgress = false;
      },

      async DeleteEmployerGroup(employerGroupId) {
        //console.log("Deleting employee with Id:", employerGroupId)
        try {
          this.actionInProgress = true;
          const employerService = new EmployerService(this.$store)
          await employerService.RemoveEmployerGroup(employerGroupId)
          //console.log(result)
          await this.FetchEmployerDetails()
        } catch (err) {
          console.warn("Error deleting employer group:", err)
        }
        this.actionInProgress = false;
      },

      async DeletePendingEmployee(pendingEmployeeId) {
        //console.log("Deleting pending employee with Id:", pendingEmployeeId)
        try {
          this.actionInProgress = true;
          const employerService = new EmployerService(this.$store)
          await employerService.RemovePendingEmployee(pendingEmployeeId)
          //console.log(result)
          await this.FetchEmployerDetails()
        } catch (err) {
          console.warn("Error deleting pending employee:", err)
        }
        this.actionInProgress = false;
      },

      ShowEditEmployeeDialog(employee) {
        //console.log("ShowEditEmployeeDialog")
        //console.log("employee:", employee);
        this.employeeToEdit = employee
        this.showEditEmployeeDialog = true;
      },

      ShowEditEmployerGroupDialog(employerGroup) {
        // console.log("ShowEditEmployerGroupDialog")
        // console.log("employerGroup:", employerGroup);
        this.groupToEdit = employerGroup
        this.showEditEmployerGroupDialog = true;
      },

      ShowEmployerGroupMembersDialog(employerGroup) {
        // console.log("ShowEmployerGroupMembersDialog")
        // console.log("employerGroup:", employerGroup);
        this.groupToViewMembersOf = employerGroup
        this.showEmployerGroupMembersDialog = true;
      },

      // Ben Functions Start Here
      async ToggleAddEmployeeDialog(shouldReload) {
        var reloadRequired = false
        try {
          //console.log("shouldReload:", shouldReload)
          reloadRequired = shouldReload
        } catch (err) {
          reloadRequired = false;
        }
        if (reloadRequired) {
          await this.FetchEmployerDetails()
        }
        this.showAddEmployeeDialog = !this.showAddEmployeeDialog
      },
      async ToggleAddEmployerGroupDialog(shouldReload) {
        var reloadRequired = false
        try {
          reloadRequired = shouldReload
        } catch (err) {
          reloadRequired = false;
        }
        if (reloadRequired) {
          await this.FetchEmployerDetails()
        }
        this.showAddEmployerGroupDialog = !this.showAddEmployerGroupDialog
      },
      async ToggleEditEmployerGroupDialog(shouldReload) {
        var reloadRequired = false
        try {
          reloadRequired = shouldReload
        } catch (err) {
          reloadRequired = false;
        }
        if (reloadRequired) {
          await this.FetchEmployerDetails()
        }
        this.showEditEmployerGroupDialog = !this.showEditEmployerGroupDialog
      },
      async ToggleEditEmployeeDialog(shouldReload) {
        var reloadRequired = false
        try {
          //console.log("shouldReload:", await shouldReload)
          reloadRequired = await shouldReload
        } catch (err) {
          reloadRequired = false;
        }
        if (reloadRequired) {
          await this.FetchEmployerDetails()
        }
        this.employeeToEdit = {}
        this.showEditEmployeeDialog = !this.showEditEmployeeDialog
      },
      async ToggleEmployerGroupMembersDialog() {
        this.showEmployerGroupMembersDialog = !this.showEmployerGroupMembersDialog
      },
      async FetchEmployerDetails() {
        try {
          this.actionInProgress = true;
          const employerService = new EmployerService(this.$store)
          const response = await employerService.GetDetailsForContactDetailsScreen(this.$store.getters.employer.id)
          //console.log(response)
          this.originalCompanyData = JSON.parse(JSON.stringify(response))
          this.changedData = JSON.parse(JSON.stringify(response))
          this.employees = JSON.parse(JSON.stringify(response.teamMembers.items))
          this.pendingEmployees = JSON.parse(JSON.stringify(response.pendingTeamMembers.items))
          await this.FetchEmployerGroups()
        } catch (err) {
          console.warn("Error getting employer details", err)
        }
        this.actionInProgress = false;
      },
      async FetchEmployerGroups() {
        try {
          this.actionInProgress = true;
          const employerService = new EmployerService(this.$store)
          const response = await employerService.GetGroups(this.$store.getters.employer.id)
          this.employerGroups = response
        } catch (err) {
          console.warn("Error getting employer groups", err)
        }
        this.actionInProgress = false;
      },
      async SaveEmployerDetails() {
        this.error_updating_details = false
        this.checkCurrentData = true;

        // Touch everything and check if any data is invalid
        this.$v.$touch();
        if (this.$v.$invalid) {
          console.warn("Some fields are invalid:", this.$v)
          this.error_updating_details = true
          return;
        }

        this.actionInProgress = true

        try {
          const employerService = new EmployerService(this.$store)
          //eslint-disable-next-line
          const response = await employerService.UpdateAndMergeEmployerDetails(this.changedData, this.originalCompanyData)
          //console.log(response)
          await this.FetchEmployerDetails()
          this.messageDialog.message = 'Contact details successfully updated';
          this.messageDialog.show = true;
        } catch (err) {
          console.warn("Error updating employer details:", err)
          this.error_updating_details = true
        }
        this.actionInProgress = false
      },

      async OnImageSelectionChange(pickerId, imageId, imageType, shouldFetchImages) {
        //console.log("OnImageSelectionChange:", pickerId, imageId, imageType, shouldFetchImages)
        // If this is the eventLogo that was changed, update eventLogoId
        if (pickerId === "accreditationLogo") {
          // If this is a newly uploaded image that requires the image array downloading
          if (shouldFetchImages) { await this.GetAccreditationLogos() }
          //console.log("Updating list of accreditation logos")
        }
      },

      async GetAccreditationLogos() {
        try {
          await ProviderService.GetAccreditationLogos(this.$store.getters.employer.id, this.$store)
        } catch (err) {
          console.warn("Error getting accreditation logo list", err)
        }
      },

      ToggleImagePopover() {
        this.showImageUploaderPopover = !this.showImageUploaderPopover
      },

      CloseErrorDialog() {
        this.errorDialogPopover.message = ''
        this.errorDialogPopover.show = false
      },

      getGroupNames(groups) {
        var groupNames = ''
        for (const group of groups) {
          groupNames = `${groupNames}${group.group.name}, `
        }
        return groupNames.substring(0, groupNames.length - 2)
      },

    },

    computed: {
      pages() {
        if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) { return 0 }
        return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
      },
      id: function() {
        try {
          return this.$store.getters.employer.id
        } catch (err) {
          console.warn("Unable to get employer ID")
          return ""
        }
      },
      name: {
        get: function() {
          try {
            try { if (this.changedData.name) return this.changedData.name } catch (err) { console.warn("Unable to get changed data for name") }
            return this.originalCompanyData.name
          } catch (err) {
            console.warn("Unable to get original company name")
            return ""
          }
        },
        set: function(newValue) {
          //console.log("Setting name to:", newValue)
          this.changedData.name = newValue
        }
      },
      imageKey: function() {
        try {
          return this.$store.getters.employer.logo.imageKey
        } catch (err) {
          console.warn("Unable to get provider logo imageKey")
          return ""
        }
      },
      // Accreditation Logos
      accreditationLogos: function() {
        try {
          return this.$store.getters.employer.accreditationLogos.items
        } catch (err) {
          console.warn("Unable to get accreditation logos")
          return []
        }
      },
      // Employer Address Line 1
      addressLine1: {
        get: function() {
          try {
            try { if (this.changedData.address.line1) return this.changedData.address.line1 } catch (err) { console.warn("Unable to get changed data for address line 1") }
            return this.originalCompanyData.address.line1
          } catch (err) {
            console.warn("Unable to get original address line1")
            return ""
          }
        },
        set: function(newValue) {
          //console.log("Setting addressLine1 to:", newValue)
          this.changedData.address.line1 = newValue
        }
      },
      // Employer Address Line 2
      addressLine2: {
        get: function() {
          try {
            try { if (this.changedData.address.line2) return this.changedData.address.line2 } catch (err) { console.warn("Unable to get changed data for address line 2") }
            return this.originalCompanyData.address.line2
          } catch (err) {
            console.warn("Unable to get original address line2")
            return ""
          }
        },
        set: function(newValue) {
          //console.log("Setting addressLine2 to:", newValue)
          this.changedData.address.line2 = newValue
        }
      },
      // Employer Address Line 3
      addressLine3: {
        get: function() {
          try {
            try { if (this.changedData.address.line3) return this.changedData.address.line3 } catch (err) { console.warn("Unable to get changed data for address line 3") }
            return this.originalCompanyData.address.line3
          } catch (err) {
            console.warn("Unable to get original address line3")
            return ""
          }
        },
        set: function(newValue) {
          //console.log("Setting addressLine3 to:", newValue)
          this.changedData.address.line3 = newValue
        }
      },
      // Employer Address City
      addressCity: {
        get: function() {
          try {
            try { if (this.changedData.address.city) return this.changedData.address.city } catch (err) { console.warn("Unable to get changed data for address city") }
            return this.originalCompanyData.address.city
          } catch (err) {
            console.warn("Unable to get original address city")
            return ""
          }
        },
        set: function(newValue) {
          //console.log("Setting addressCity to:", newValue)
          this.changedData.address.city = newValue
        }
      },
      // Employer Address Region
      addressRegion: {
        get: function() {
          try {
            try { if (this.changedData.address.region) return this.changedData.address.region } catch (err) { console.warn("Unable to get changed data for address region") }
            return this.originalCompanyData.address.region
          } catch (err) {
            console.warn("Unable to get original address region")
            return ""
          }
        },
        set: function(newValue) {
          //console.log("Setting addressRegion to:", newValue)
          this.changedData.address.region = newValue
        }
      },
      // Employer Address Postal Code
      addressPostalCode: {
        get: function() {
          try {
            try { if (this.changedData.address.postalCode) return this.changedData.address.postalCode } catch (err) { console.warn("Unable to get changed data for address postalCode") }
            return this.originalCompanyData.address.postalCode
          } catch (err) {
            console.warn("Unable to get original address postalCode")
            return ""
          }
        },
        set: function(newValue) {
          try {
            //console.log("Setting addressPostalCode to:", newValue)
            this.changedData.address.postalCode = newValue
          } catch (err) {
            console.warn("Error setting postal code:", err)
          }
        }
      },
      // Employer Address Country
      addressCountry: {
        get: function() {
          try {
            try { if (this.changedData.address.country) return this.changedData.address.country } catch (err) { console.warn("Unable to get changed data for address country") }
            return this.originalCompanyData.address.country
          } catch (err) {
            console.warn("Unable to get original address country")
            return ""
          }
        },
        set: function(newValue) {
          //console.log("Setting addressCountry to:", newValue)
          this.changedData.address.country = newValue
        }
      },
      contactPhone: {
        get: function() {
          try {
            try { if (this.changedData.contactDetails.phoneBookings) return this.changedData.contactDetails.phoneBookings } catch (err) { console.warn("Unable to get changed data for course provider contact phone number") }
            return this.originalCompanyData.contactDetails.phoneBookings
          } catch (err) {
            console.warn("Unable to get original course provider contact phone number")
            return ""
          }
        },
        set: function(newValue) {
          //console.log("Setting phoneBookings to:", newValue)
          this.changedData.contactDetails.phoneBookings = newValue
        }
      },
      contactEmail: {
        get: function() {
          try {
            try { if (this.changedData.contactDetails.emailBookings) return this.changedData.contactDetails.emailBookings } catch (err) { console.warn("Unable to get changed data for course provider contact email") }
            return this.originalCompanyData.contactDetails.emailBookings
          } catch (err) {
            console.warn("Unable to get original course provider contact email")
            return ""
          }
        },
        set: function(newValue) {
          //console.log("Setting emailBookings to:", newValue)
          this.changedData.contactDetails.emailBookings = newValue
        }
      },
      // Employer Website URL
      websiteURL: {
        get: function() {
          try {
            try { if (this.changedData.courseProviderDetails.websiteURL) return this.changedData.courseProviderDetails.websiteURL } catch (err) { console.warn("Unable to get changed data for course provider website url") }
            return this.originalCompanyData.courseProviderDetails.websiteURL
          } catch (err) {
            console.warn("Unable to get original course provider website url")
            return ""
          }
        },
        set: function(newValue) {
          //console.log("Setting websiteURL to:", newValue)
          this.changedData.courseProviderDetails.websiteURL = newValue
        }
      },
      registrationNumber: {
        get: function() {
          try {
            try { if (this.changedData.courseProviderDetails.registrationNumber) return this.changedData.courseProviderDetails.registrationNumber } catch (err) { console.warn("Unable to get changed data for course provider registration number") }
            return this.originalCompanyData.courseProviderDetails.registrationNumber
          } catch (err) {
            console.warn("Unable to get original course provider registration number")
            return ""
          }
        },
        set: function(newValue) {
          //console.log("Setting registrationNumber to:", newValue)
          this.changedData.courseProviderDetails.registrationNumber = newValue
        }
      },
      aboutDescription: {
        get: function() {
          try {
            try { if (this.changedData.courseProviderDetails.description) return this.changedData.courseProviderDetails.description } catch (err) { console.warn("Unable to get changed data for course provider registration number") }
            return this.originalCompanyData.courseProviderDetails.description
          } catch (err) {
            console.warn("Unable to get original course provider about description")
            return ""
          }
        },
        set: function(newValue) {
          //console.log("Setting aboutDescription to:", newValue)
          this.changedData.courseProviderDetails.description = newValue
        }
      },
      aboutWhyChooseUs: {
        get: function() {
          try {
            try { if (this.changedData.courseProviderDetails.whyChooseUs) return this.changedData.courseProviderDetails.whyChooseUs } catch (err) { console.warn("Unable to get changed data for course provider registration number") }
            return this.originalCompanyData.courseProviderDetails.whyChooseUs
          } catch (err) {
            console.warn("Unable to get original course provider why choose us")
            return ""
          }
        },
        set: function(newValue) {
          //console.log("Setting whyChooseUs to:", newValue)
          this.changedData.courseProviderDetails.whyChooseUs = newValue
        }
      },
      dataHasChanged: function() {
        //console.log("this.changedData:", this.changedData)
        //console.log("this.originalCompanyData:", this.originalCompanyData)
        //console.log("dataHasChanged:", ObjectsAreDifferent(this.changedData, this.originalCompanyData))
        return ObjectsAreDifferent(this.changedData, this.originalCompanyData)
      },
      userIsAdmin: function() {
        try {
          // If this user is a MyProPass employee, they are an admin for everyone
          if (this.$store.getters.cognito.signInUserSession.accessToken.payload['cognito:groups'].includes(`ADMINISTRATOR`)) {
            return true
          }
          // Otherwise, check if they are an admin for the current provider
          const employerId = this.$store.getters.employer.id
          return this.$store.getters.cognito.signInUserSession.accessToken.payload['cognito:groups'].includes(`${employerId}_ADMINISTRATOR`)
        } catch (err) {
          console.warn("There was an error checking user admin status:", err)
          return false
        }
      }
    },

    async mounted() {

      //console.log("this.dataHasChanged:", this.dataHasChanged)
      //console.log("this.changedData:", this.changedData)
      //console.log("this.originalCompanyData:", this.originalCompanyData)

      if (this.$route.params.tab == 1) { this.model = 'tab-contactdetails'; }
      else if (this.$route.params.tab == 2) { this.model = 'tab-about'; }
      else if (this.$route.params.tab == 3) { this.model = 'tab-employees'; }
      else { this.model = 'tab-contactdetails'; }

      await this.FetchEmployerDetails()
      this.is_data_fetched = true;

      /*
      ProviderService.GetContactDetails()
        .then(response => {
          console.log(response);
          if (response.data) {
            var result = response.data;
            this.contactDetails.id = result.id;
            this.contactDetails.personalId = result.personalId.toLocaleString().replace(/,/g, '-');
            this.contactDetails.logo = result.logo;
            this.contactDetails.organizationName = result.organizationName;
            this.contactDetails.address = result.address;
            this.contactDetails.postCode = result.postalCodeLookup;
            this.contactDetails.webSite = result.webSite;
            this.contactDetails.registrationNumber = result.registrationNumber;
            this.contactDetails.registrationPlaces = result.registrationPlaces;
            this.contactDetails.selectedRegistrationPlaceId = result.registrationPlaceId;
            this.is_data_fetched = true;
          }
        })
        .catch(function (err) {
          console.log(err);
        });

      ProviderService.GetAboutDetails()
        .then(response => {
          console.log(response);
          var result = response.data;
          if (result) {
            this.aboutDetails.id = result.id;
            this.aboutDetails.description = result.description;
            this.aboutDetails.whyChooseUs = result.whyChooseUs;
            this.aboutDetails.images = result.images;
          }
        })
        .catch(function (err) {
          console.log(err);
        });

      ProviderService.GetEmployees()
        .then(response => {
          console.log(response);
          if (response.data) {
            var result = response.data;
            console.log(result);
            this.employees = result.employees.filter((ins) => ins.id != -1 && ins.isActive != false);
            this.pagination.totalItems = this.employees.length;
            console.log(this.pagination.totalItems);
            this.is_data_fetched = true;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
      */

    },
  }
</script>


<style scoped>

  .v-btn {
    text-decoration: none;
    text-transform: capitalize;
  }
  /* style the container */


  body {
    background-color: rgba(243,246,248,1);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  .action-cell {
    display: flex;
  }

  .container-rounded-subheader {
    padding-left: 50px;
    margin: 0 0 40px;
    min-height: 40px;
    overflow: visible;
  }

  .container-rounded-mini {
    background-color: #FFFFFF;
    min-height: 40px;
  }

  .container-rounded {
    background-color: #FFFFFF;
    margin: 12px 0px;
    min-height: 350px;
    padding-left: 0px;
  }

  .container-rounded-about {
    background-color: #FFFFFF;
    margin: 12px 0px;
    min-height: 500px;
    padding-bottom: 20px;
  }

  .contact-details-logo {
    border: 2px solid #fafafa;
    position: relative;
    height: 60px;
    width: 60px;
    bottom: -95px;
    background: #fafafa;
  }

  .personal-id-container {
    position: absolute;
    margin-left: 5px;
    vertical-align: bottom;
    bottom: 0;
    width: 100%;
    left: -15px;
  }

  .personal-id {
    float: right;
    font-size: 11px;
    font-weight: normal;
  }

  .theme--light.v-sheet {
    border: solid 1px #b5b5b5;
  }

  @media (min-width: 768px) {
    .container-rounded-subheader {
      margin: 0 40px 40px;
    }

    .contact-details-logo {
      height: 100px;
      width: 100px;
      bottom: -75px;
    }

    .personal-id-container {
      position: relative;
      margin-left: 5px;
      vertical-align: bottom;
      bottom: -80px;
    }

    .container-rounded-mini {
      margin-left: 40px;
      margin-right: 40px;
      padding-left: 50px;
    }

    .container-rounded, .container-rounded-about {
      margin: 12px 40px;
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  .action-cell .image {
    cursor: pointer;
  }
</style>

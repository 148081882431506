<template>
  <v-app id="app">
    <v-container class='not-signed-in-container' fluid :style='containerStyle'>
      <router-view />
    </v-container>
  </v-app>
</template>


<script>
  import { isFISHost } from '@/constants/Validations'

  export default {
    data() {
      return {}
    },

    computed: {
      containerStyle: function() {
        if (isFISHost) { return { backgroundColor: 'var(--fis-colour)' } }
        return {}
      }
    }
  }
</script>

<style>
  @import '../css/not-signed-in.css';
</style>

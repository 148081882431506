<template>
  <v-container class='screen-content' justify-center align-content-center>

    <!-- White box -->
    <v-container class='white-box' xs12>
      <v-layout grid-container login-container>

        <!-- Left column -->
        <v-flex text-center left-shadow>
          <img :src="logoImage" class="logo" />

          <div v-if='!isFISHost' class="welcome">
            <h4>Welcome to MyProPass</h4>
            <span>Your professional passport - Let your career take off</span>
          </div>
          <div v-if='isFISHost' class="welcome">
            <h4>Welcome to your FIS Competency Passport</h4>
          </div>

          <!-- Powered by MyProPass Holder -->
          <div v-if='isFISHost' class="powered-by-mpp">
            <p>Powered by</p>
            <img src="@/assets/images/logo.png" class="powered-by-logo" />
          </div>
        </v-flex>

        <!-- Right column -->
        <v-flex right-shadow class='right-box'>
          <v-flex class="right-container xs12 md8 offset-md-2">
            <v-layout justify-center>
              <h5 style="color: #007AFF;font-size: 30px;margin-top:30px;">Reset Password</h5>
            </v-layout>
            <br />

            <div>
              <div class="pb-2" v-if='!hideContent'>
                <label for="newPassword">New Password</label>
                <input id="newPassword" type="password" class="form-control" v-model="newPassword">
                <div v-if="submitted && $v.newPassword.$error" class="text-danger">
                  <small v-if="!$v.newPassword.required">New password has not been provided<br /></small>
                  <small v-if="!$v.newPassword.minLength">New password is too short<br /></small>
                  <small v-if="!$v.newPasswordDuplicate.checkPasswordsMatch">New passwords do not match<br /></small>
                </div>
              </div>

              <div class="pb-2" v-if='!hideContent'>
                <label for="newPasswordDuplicate">Repeat New Password</label>
                <input id="newPasswordDuplicate" type="password" class="form-control" v-model="newPasswordDuplicate">
                <div v-if="submitted && $v.newPasswordDuplicate.$error" class="text-danger">
                  <small v-if="!$v.newPasswordDuplicate.required">New password has not been provided<br /></small>
                  <small v-if="!$v.newPasswordDuplicate.minLength">New password is too short<br /></small>
                  <small v-if="!$v.newPasswordDuplicate.checkPasswordsMatch">New passwords do not match<br /></small>
                </div>
              </div>

              <div class="warning-box" v-if="errorRequestingReset">
                {{ warningBoxText }}
              </div>

              <div style="text-align: center;" v-if='!hideContent'>
                <button @click="RequestResetPassword" :disabled="isProcessing" class="btn btn-primary btn-block" type="submit" style="background-color: #F5740E;border: none;height: 60px;border-radius: 6px;width: 180px;font-size: 16px;font-weight: bold;color:#fff;margin: 30px auto">
                  {{ isProcessing ? "Please wait..." : "Reset Password" }}
                </button>
              </div>

            </div>
          </v-flex>
        </v-flex>

      </v-layout>
    </v-container>

    <!-- Loading overlay -->
    <v-container>
      <loading :active.sync="isProcessing" :is-full-page="true" loader="dots"></loading>
    </v-container>

  </v-container>
</template>


<script>
  import AuthService from '@/services/auth.service'
  import { isFISHost } from '@/constants/Validations'
  import { validationMixin } from 'vuelidate'
  import { required, minLength } from 'vuelidate/lib/validators'

  // Validation for matching passwords
  const checkPasswordsMatch = (value, vm) => {
    return vm.newPassword === vm.newPasswordDuplicate
  }


  export default {
    name: 'ForgotPassword',
    data() {
      return {
        newPassword: '',
        newPasswordDuplicate: '',
        hasCompletedReset: false,
        errorRequestingReset: false,
        submitted: false,
        isProcessing: false,
        hideContent: false,
        warningBoxText: "The link you clicked to reset your password has expired.  Did you click an old link?",
        isFISHost
      }
    },

    // Validations
    mixins: [validationMixin],
    validations: {
      newPassword: { required, minLength: minLength(8), checkPasswordsMatch },
      newPasswordDuplicate: { required, minLength: minLength(8), checkPasswordsMatch }
    },

    // Methods
    methods: {
      async RequestResetPassword() {
        const search = this.parseSearch()
        const { confirmation_code, user_name } = search
        this.submitted = true
        this.isProcessing = true
        this.errorRequestingReset = false

        // Check that all the fields are valid
        this.$v.$touch();
        if (this.$v.$invalid) {
          console.warn("invalid", this.$v)
          this.isChangingPassword = false
          this.errorRequestingReset = true
          this.isProcessing = false
          this.warningBoxText = "Not all fields are valid.  Please check the fields about and resubmit."
          return;
        }

        try {
          await AuthService.ResetPasswordSubmit(user_name, confirmation_code, this.newPassword);
          this.hasCompletedReset = true;
          this.$router.push({ name: "Login" })
        } catch (err) {
          console.warn("Error when submitting password reset;", err)
          this.isChangingPassword = false
          this.errorRequestingReset = true
          this.isProcessing = false
          // If this error is because the user used an old code, tell them
          if (err.code === "CodeMismatchException") {
            this.warningBoxText = "The link you clicked to reset your password has expired. Please ensure you click the newest password reset link that you have received. If you are still experiencing issues, please contact us at contact@mypropass.co.uk"
            return
          }
          this.warningBoxText = "An error occured when updating you password. Please try again.  If problems persist, please request a new password reset."
          return
        }
      },
      parseSearch() {
        try {
          const search = location.href.split('?')[1]
          let jsonResult
          if (search.includes("%26")) {
            // Use only this parsing after 16th April 2022
            let fixedSearch = search.replace(/%26/g, '","')
            fixedSearch = decodeURIComponent(fixedSearch)
            fixedSearch = '{"' + fixedSearch + '"}'
            fixedSearch = fixedSearch.replace(/=/g,'":"')
            jsonResult = JSON.parse(fixedSearch, function(key, value) { return key===""?value:decodeURIComponent(value) })
          } else {
            // This is to help with codes requested before 15th March 2022.  Remove on 16th April 2022
            let fixedSearch =  search.replace(/&user_/g, '","user_')
            fixedSearch = fixedSearch.replace(/&confirmation_/g, '","confirmation_')
            fixedSearch = fixedSearch.replace(/&email_/g, '","email_')
            fixedSearch = fixedSearch.replace(/=/g, '":"')
            jsonResult = JSON.parse('{"' + fixedSearch + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
            // If the string ended in an &, it was removed by the browser; replace it.
            if (jsonResult.confirmation_code.length === 7) {
              jsonResult.confirmation_code = `${jsonResult.confirmation_code}&`
            }
          }
          return jsonResult
        } catch (err) {
          console.warn("There was an error parsing the arguments;", err)
          this.isChangingPassword = false
          this.errorRequestingReset = true
          this.isProcessing = false
          this.warningBoxText = "The incorrect arguments were provided to this page.  Did you click the correct link to get here?"
          this.hideContent = true
          return "error"
        }
      }
    },

    computed: {
      logoImage: function() {
        let imageName = "logo"
        if (this.isFISHost) { imageName = "logo-fis" }
        return require(`@/assets/images/${imageName}.png`)
      }
    },

    // Mounted Lifecycle Hook
    mounted() {
      console.log(this.parseSearch())
    }
  }
</script>


<style scoped>

  .warning-box {
    width: 100%;
    background-color: #FFCCCC;
    color: black;
    padding: 1em;
    border-radius: 5px;
  }

</style>

<template>
  <v-app>
    <v-toolbar color="#F9F9F9" fixed style="padding:0px;">
      <div class="logo-container">
        <img :src="logoImage" class="logo header-bar-logo desktop-logo" />
        <img :src="logoImage" class="header-bar-logo mobile-logo" />
      </div>
      <div class='provider-picker-container'>
        <v-select
          v-model="selectedProviderId"
          v-bind:items="providerList"
          item-text="name"
          item-value="id"
          placeholder="Please select a provider"
          background-color="white"
          hide-details
          hide-selected
          no-data-text="No other providers"
          class="p-1">
        </v-select>
      </div>
      <div class="header-mobile-links">
        <div class="mobile-menu">
          <i class="mobile-menu-link" id="mobile-menu-link" @click="toggleMobileMenuDisplay">
          </i>
          <div class="mobile-menu-links" id="mobile-menu-links" v-if="mobileMenuDisplay" @click="toggleMobileMenuDisplay">

            <router-link :to="{ name: 'AdminLanding' }">
              Home
            </router-link>

            <router-link :to="{ name: 'AdminAllEvents', params: { status: PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.EVENTS } }" exact @click="refreshMenu('events')" v-bind:class="{ 'active': activeMenu ==='events' }">
              Events
            </router-link>

            <router-link :to="{ name: 'AdminAllExperience' }" class="experience-link" exact @click="refreshMenu('courses')" v-bind:class="{ 'active': activeMenu ==='courses' }">
              Experience
            </router-link>

            <!--
            <router-link :to="{ name: 'AdminAllCourses' }" exact @click="refreshMenu('courses')" v-bind:class="{ 'active': activeMenu ==='courses' }">
              Courses
            </router-link>
            -->

            <router-link :to="{ name: 'AdminAllEvents', params: { status: PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.RECORDS } }" exact @click="refreshMenu('records')" v-bind:class="{ 'active': activeMenu ==='records' }">
              Records
            </router-link>

            <router-link :to="{ name: 'AdminEmployeeTrainingRecords' }" exact @click="refreshMenu('employeeTrainingRecords')" v-bind:class="{ 'active': activeMenu ==='employeeTrainingRecords' }">
              Employees
            </router-link>

            <router-link :to="{ name: 'AdminStatistics' }" exact @click="refreshMenu('statistics')" v-bind:class="{ 'active': activeMenu ==='statistics' }">
              Statistics
            </router-link>

            <div>
              <v-menu bottom left>
                <a slot="activator" @click="toggleNotifications" class="ma-0" id="mobileNotifications" dark icon>
                  Notifications
                </a>
                <v-card style="width:300px;float: left" elevation="0">
                  <v-card-text style="float: left">
                    <div v-for="notification in notifications" :key="notification.id" class="notification">
                      <div class="notification-img"></div>
                      <div class="notification-content">
                        <span style="font-weight:bold;color:cornflowerblue">{{ notification.title }}</span><br />
                        {{ notification.displayText }}
                      </div>
                      <div class="notification-date">
                        {{ notification.displayDateTime }}
                      </div>
                    </div>
                    <div v-if="notifications.length == 0">
                      <span style="font-weight:bold;color:cornflowerblue">No notifications.</span><br />
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>

            <v-divider></v-divider>

            <router-link :to="{ name: 'AdminCompanyOverview' }">
              <div>Company Overview</div>
            </router-link>
            <router-link :to="{ name: 'AdminContactDetails', params: { tab: 1 } }">
              <div>Edit Company Details</div>
            </router-link>
            <router-link :to="{ name: 'AdminCreateEmployer' }">
              <div>Create Provider</div>
            </router-link>
            <router-link :to="{ name: 'UserLanding' }">
              <div>Switch To User Mode</div>
            </router-link>
            <router-link :to="{ name: 'ProviderLanding' }">
              <div>Switch To Provider Mode</div>
            </router-link>
            <a href="#" style="font-weight:normal;" @click="changePassword" v-if="!isExternalLogin">
              <div>Change Password</div>
            </a>
            <!--
            <a href="#" style="font-weight:normal;" @click="twoStepAuthentication" v-if="!isExternalLogin">
              <div>Two Step Authentication</div>
            </a>
            -->
            <v-divider></v-divider>
            <a href="#" @click="signOut">
              <div>Log Out</div>
            </a>
          </div>
        </div>
        <!--<p class="usrnam">Welcome! <span class="ussr">{{userName}}</span></p>-->
      </div>
      <div class="header-web-links">
        <div class="notifications-link">
          <v-menu bottom left>
            <a slot="activator" @click="toggleNotifications" class="ma-0"
               dark
               icon>
              Notifications
            </a>
            <v-card style="width:300px;float: left" elevation="0">
              <v-card-text style="float: left">
                <div v-for="notification in notifications" :key="notification.id" class="notification">
                  <div class="notification-img">
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.USER_USER_BOOKED_COURSE" :src="require('../images/32x32-Book.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.USER_PROVIDER_BOOKED_COURSE" :src="require('../images/32x32-Book.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.USER_USER_CANCELLED_COURSE_BOOKING" :src="require('../images/32x32-No.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.USER_PROVIDER_CANCELLED_COURSE_BOOKING" :src="require('../images/32x32-No.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.PROVIDER_ATTENDEE_ISSUE_CERTIFICATE" :src="require('../images/32x32-Email.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.USER_USER_ISSUED_WITH_CERTIFICATE_FROM_CONFERENCE" :src="require('../images/32x32-Events Enter Code.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.PROVIDER_ATTENDEE_COURSE_LOCATION_CHANGED" :src="require('../images/32x32 Location.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.PROVIDER_ATTENDEE_CANCELLED_COURSE_BOOKING" :src="require('../images/32x32-No.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.PROVIDER_PROVIDER_CANCELLED_COURSE" :src="require('../images/32x32-No.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.USER_PROVIDER_ISSUED_WITH_CERTIFICATE_FROM_CONFERENCE" :src="require('../images/32x32-Events Enter Code.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.SYSTEM_USER_MISSED_COURSE" :src="require('../images/32x32-Profile Absent.png')"></v-img>
                    <v-img v-else :src="require('../images/events.png')"></v-img>
                  </div>
                  <div class="notification-content">
                    <span style="font-weight:bold;color:cornflowerblue">{{ notification.title }}</span><br />
                    {{ notification.description }}
                  </div>
                  <div class="notification-date">
                    {{ formatDate(notification.updatedAt) }}
                  </div>
                </div>
                <div v-if="notifications.length == 0">
                  <span style="font-weight:bold;color:cornflowerblue">No notifications.</span><br />
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <div class="profile-link" v-bind:class="{ 'active': activeMenu ==='profile' }" exact @click="refreshMenu('profile')">
          <v-menu bottom left>
            <v-btn slot="activator"
                   class="mt-1"
                   dark
                   icon>
              <b style="font-size:11px">Menu</b> <v-icon>arrow_drop_down</v-icon>
            </v-btn>
            <v-card style="width:200px;" class="profile-links" elevation="0" exact @click="refreshMenu('profile')">
              <v-card-text>
                <router-link :to="{ name: 'AdminCompanyOverview' }">
                  <div>Company Overview</div>
                </router-link>
                <router-link :to="{ name: 'AdminContactDetails', params: { tab: 1 } }">
                  <div>Edit Company Details</div>
                </router-link>
                <router-link :to="{ name: 'AdminCreateEmployer' }">
                  <div>Create Provider</div>
                </router-link>
                <router-link :to="{ name: 'UserLanding' }">
                  <div>Switch To User Mode</div>
                </router-link>
                <router-link :to="{ name: 'ProviderLanding' }">
                  <div>Switch To Provider Mode</div>
                </router-link>
                <a href="#" @click="changePassword" v-if="!isExternalLogin">
                  <div>Change Password</div>
                </a>
                <!--
                <a href="#" @click="twoStepAuthentication" v-if="!isExternalLogin">
                  <div>Two Step Authentication</div>
                </a>
                -->
                <a href="#" @click="signOut">
                  <div>Log Out</div>
                </a>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>


        <router-link :to="{ name: 'AdminStatistics' }" exact @click="refreshMenu('statistics')" class="statistics-link" v-bind:class="{ 'active': activeMenu ==='statistics' }">
          Statistics
        </router-link>

        <router-link :to="{ name: 'AdminEmployeeTrainingRecords' }" exact @click="refreshMenu('employeeTrainingRecords')" class="employees-link" v-bind:class="{ 'active': activeMenu ==='employeeTrainingRecords' }">
          Employees
        </router-link>

        <router-link :to="{ name: 'AdminAllEvents', params: { status: PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.RECORDS } }" class="records-link" exact @click="refreshMenu('records')" v-bind:class="{ 'active': activeMenu ==='records' }">
          Records
        </router-link>

        <!--
        <router-link :to="{ name: 'AdminAllCourses' }" class="courses-link" exact @click="refreshMenu('courses')" v-bind:class="{ 'active': activeMenu ==='courses' }">
          Courses
        </router-link>
        -->

        <router-link :to="{ name: 'AdminAllExperience' }" class="experience-link" exact @click="refreshMenu('courses')" v-bind:class="{ 'active': activeMenu ==='courses' }">
          Experience
        </router-link>

        <router-link :to="{ name: 'AdminAllEvents', params: { status: PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.EVENTS } }" class="events-link" exact @click="refreshMenu('events')" v-bind:class="{ 'active': activeMenu ==='events' }">
          Events
        </router-link>

        <router-link :to="{ name: 'AdminLanding' }" exact @click="refreshMenu('home')" class="home-link" v-bind:class="{ 'active': activeMenu ==='home' }">
          Home
        </router-link>

        <!--<span style="color:cornflowerblue;font-size:22px;font-weight:normal;">Welcome! {{userName}}</span>-->
      </div>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-content class="content">
      <v-container fluid pt-0>
        <v-layout>
          <v-flex>
            <router-view v-bind:key="$route.fullPath"></router-view>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>


<script>
  import { Auth } from 'aws-amplify';
  import * as StoreActions from '../constants/StoreActions'
  //import JQuery from 'jquery'
  //import UserService from '../services/user.service'

  import AdminService from '@/services/admin.service'
  import UserService from '@/services/user.service'
  import EventService from '@/services/event.service'
  import ProviderService from '@/services/provider.service'
  import ExperienceRecordService from '@/services/experienceRecord.service'
  import { NOTIFICATION_TYPES } from '@/constants/NotificationConstants'
  import { PROVIDER_ALL_EVENTS_PAGE_PARAMETERS } from '@/constants/PageParameterConstants'
  import { isFISHost } from '@/constants/Validations'

  //let $ = JQuery
  export default {
    data() {
      return {
        userName: '',
        isExternalLogin: false,
        mobileMenuDisplay: false,
        showNotifications: false,
        activeMenu: 'home',
        isAnAdmin: false,
        selectedProviderId: '',
        NOTIFICATION_TYPES,
        PROVIDER_ALL_EVENTS_PAGE_PARAMETERS,
        isFISHost
      }
    },

    watch: {
      selectedProviderId: async function(newValue) {
        //console.log("selectedProviderId changed to:", newValue)
        const adminService = new AdminService(this.$store)
        await adminService.UpdateCurrentProvider(newValue)
      }
    },


    methods: {

      async signOut() {
        await Auth.signOut()
        console.log("user is signed out")
        this.$store.commit(StoreActions.RESET_AFTER_LOGOUT);
        this.$router.push({ name: 'Login' });
      },
      displayNotifications() {
        this.refreshMenu('notifications');
        ProviderService.GetMyNotifications()
          .then((response) => {
            this.notifications = response.data;
            console.log('this.notifications');
            console.log(this.notifications);
          });
      },
      changePassword() {
        this.$router.push({ name: 'UserChangePassword', params: { returnToPage: this.$router.currentRoute.name } })
      },
      twoStepAuthentication() {
        window.location.href = process.env.VUE_APP_twoStepAuthenticationPath;
      },
      toggleMobileMenuDisplay(event) {
        if (event.target.id !== "mobileNotifications") {
          this.mobileMenuDisplay = !this.mobileMenuDisplay;
        }
      },
      refreshMenu(activeMenu) {
        this.activeMenu = '';
        if (activeMenu) {
          this.activeMenu = activeMenu;
          //console.log(this.activeMenu);
        }
      },
      toggleNotifications() {
        this.showNotifications = !this.showNotifications
      },
      formatDate(dateTimeString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' }
        return (new Date(dateTimeString)).toLocaleString(options)
      }
    },


    computed: {
      notifications: function() {
        try {
          return this.$store.getters.notifications.items
        } catch (e) {
          console.warn("error getting notifications:", e)
          return []
        }
      },
      adminModeAvailable: function() {
        return this.isAnAdmin
      },
      isProviderAdmin: function() {
        return true
      },
      logoImage: function() {
        let imageName = "logo"
        if (this.isFISHost) { imageName = "logo-fis" }
        return require(`@/assets/images/${imageName}.png`)
      },
      providerList: function() {
        try {
          return this.$store.getters.providerList
        } catch (err) {
          return []
        }
      }
    },


    async mounted() {
      // Get the notifications for this user
      const userService = new UserService(this.$store)
      const eventService = new EventService(this.$store)
      const adminService = new AdminService(this.$store)
      const experienceRecordService = new ExperienceRecordService(this.$store)
      await userService.GetNotifications({ userId: this.$store.getters.cognito.attributes.sub })

      await Promise.all([
        eventService.GetFutureEventsForEmployee(),
        eventService.GetPastEventsForEmployee(),
        adminService.GetProviderList(),
        experienceRecordService.GetExperienceRecordsForEmployee()
      ])
      //console.log("results:", results)

      // Set the current selection of the provider drop down
      this.selectedProviderId = this.$store.getters.employer.id

      try {
        // Get the current user session
        const user = await Auth.currentAuthenticatedUser();
        const userGroups = user.signInUserSession.accessToken.payload['cognito:groups']
        //console.log("user groups:", userGroups)

        // Set the admin mode boolean
        if (userGroups.includes("ADMINISTRATOR")) {
          this.isAnAdmin = true;
        }
      } catch (err) {
        console.warn("Unable to check for administrator rights", err)
      }

      // If this user has a saved identityId, do nothing
      if (this.$store.getters.user.identityId) {
        return
      }

      // If they don't have an identityId, then save it
      const credentials = await Auth.currentCredentials();
      console.log('Cognito identity ID:', credentials.identityId);

      // Update the user details in the database
      const parameters = {
        id: this.$store.getters.cognito.attributes.sub,
        identityId: credentials.identityId
      }
      await userService.UpdateUserDetails(parameters)
    }
  }

</script>
<style scoped>

  .content {
    padding-top: 62px !important;
    background-color: rgb(239, 246, 254);
  }

  body {
    background-color: rgb(243,246,248);
  }

  input[readonly] {
    width: 0;
  }

  .v-toolbar__extension {
    padding: 0px;
  }
  .v-menu__content {
    overflow: visible !important;
    contain: none;
    border-radius: 5px;
  }

  .profile-links {
    border-radius: 5px;
  }

  .profile-links:before {
    content: '';
    border-bottom-color: #999 !important;
    position: absolute;
    border: solid 10px transparent;
    top: -20px;
    right: 6px;
    z-index: 1;
  }

  .profile-links:after {
    content: '';
    border-bottom-color: #fff !important;
    position: absolute;
    border: solid 10px transparent;
    top: -20px;
    right: 6px;
    z-index: 2;
  }

  .profile-links a:nth-child(2) {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 6px;
  }

  .profile-links a:nth-child(3) {
    border-bottom: solid 1px #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-left: -15px;
    padding-left: 15px;
    width: calc(100% + 30px);
  }

  .notification {
    padding: 1px 0px 5px;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 5px;
    border-bottom: solid 1px #ccc;
    word-break: break-word;
    float: left;
  }
  .notification-img {
    float: left;
    width: 35px;
    height: 35px;
    background: #ccc;
    margin-right: 5px;
    margin-top: 5px;
  }
  .notification-content {
    float: left;
    width: calc(100% - 40px);
  }
  .notification-date {
    clear: both;
    float: left;
    width: 100%;
  }

  .provider-picker-container {
    margin-left: 2rem;
  }

  .employees-link {
    background: url(../images/employees-dark.png) no-repeat center top;
    float: right;
    text-align: center;
    padding-top: 24px;
    background-size: 33px 22px;
    margin-right: 30px;
    font-size: 11px;
    min-width: 40px;
    color: #999 !important;
  }

  .employees-link.router-link-active {
    background-image: url(../images/employees-turquoise.png);
  }

  .statistics-link {
    background: url(../images/tachometer-alt-fast.svg) no-repeat center top;
    float: right;
    text-align: center;
    padding-top: 24px;
    background-size: 33px 25px;
    margin-right: 30px;
    font-size: 11px;
    min-width: 40px;
    color: #999 !important;
  }

  .statistics-link.router-link-active {
    background: url(../images/tachometer-alt-fast-active.svg) no-repeat center top;
    float: right;
    text-align: center;
    padding-top: 24px;
    background-size: 33px 25px;
    margin-right: 30px;
    font-size: 11px;
    min-width: 40px;
  }

  .header-bar-logo {
    max-height: 50px;
    max-width: 150px;
  }

</style>

<template>
  <div id="app">
    <v-app>
      <v-toolbar id=top color="white" fixed>
        <img src="../images/logo.png" style="max-height:150px;max-width:150px; margin-bottom:5px;margin-left:25px;" />
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-toolbar-title></v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-content id="below">
        <v-container fluid fluid-height style="padding:0;margin-top:50px">
          <v-layout>
            <v-flex>
              <router-view />
            </v-flex>
          </v-layout>
        </v-container>
      </v-content>
      <v-footer color="rgb(243,246,248)" app>
        <v-spacer></v-spacer>
        <span class="white--text">&copy; 2019</span>
      </v-footer>
    </v-app>
  </div>
</template>
<script>
  export default {

    data() {
      return {}
    },
    created() {
    },

    updated() {
    },

    methods: {
    }

  }

</script>
<style>

  body {
    background-color: rgb(243,246,248);
  }

  html body {
    padding-top: 62px;
  }


  .v-btn {
    text-decoration: none;
    text-transform: capitalize;
  }

  .v-toolbar__extension {
    padding: 0px;
  }

  nav {
    height: 60px;
    width: 100%;
    z-index: 1;
  }

  nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: table;
    margin: 0 auto;
  }

  nav ul li {
    display: table-cell;
  }

  nav ul li a {
    padding: 10px 20px;
    display: block;
    color: black;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
  }

  nav ul li a:hover {
    color: rgba(0,171,173,1);
  }

  nav ul .active a {
    color: rgba(0,171,173,1);
    border-bottom: 3px solid rgba(0,171,173,1);
  }

  html, body {
    height: 100%;
    margin: 0px;
  }
</style>


export const BOOKING_ATTENDANCE = {
  EVENTNOTYETHELD: 'EVENTNOTYETHELD',
  EVENTCANCELLED: 'EVENTCANCELLED',
  ATTENDED: 'ATTENDED',
  NOSHOW: 'NOSHOW'
}

export const BOOKING_STATUS = {
  ONWAITINGLIST: 'ONWAITINGLIST',
  BOOKED: 'BOOKED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED'
}

export const BOOKING_CERTIFICATE_STATUS = {
  NOTSENT: "NOTSENT",
  REQUESTED: "REQUESTED",
  SENTDIGITALLY: "SENTDIGITALLY",
  SENTPHYSICALLY: "SENTPHYSICALLY"
}

<template>
  <v-flex class='admin-staistics-data-table-container'>
    <loading :active.sync="isLoading" :is-full-page="fullPage" loader="dots"></loading>
    <h5 class="table-header">Events Created Per Provider</h5>

    <v-data-table :headers="tableHeaders"
                  :items="processedInfo"
                  hide-actions
                  :pagination.sync="pagination"
                  :custom-sort="CustomSort"
                  :custom-filter="CustomFilter"
                  :sort-by.sync="pagination.sortBy"
                  :sort-desc.sync="pagination.sortDesc"
                  no-data-text="No statistics to show; please change date range"
                  class="elevation-1 admin-statistic-table">

      <template slot="headerCell" slot-scope="{ header }">
        <span class="table-head font-weight-bold text--darken-3" style='margin-right: 20px;' v-text="header.text" />
      </template>

      <template v-slot:items="props">

        <td style='text-align: left; height: auto;'>
          {{ props.item.providerName }}
        </td>

        <template v-for='(period, index) in periods'>
          <td style='height: auto;' v-bind:key="index">
            {{ metric === METRIC_OPTIONS.DELTA ? props.item[`deltaPeriod${index + 1}`] || 0 : props.item[`attPeriod${index + 1}`] || 0 }}
          </td>
        </template>

      </template>
    </v-data-table>
    <v-layout>
      <v-flex xs12 text-right>
        <v-pagination v-model="pagination.page" style="vertical-align:top;" :length="Math.ceil(pagination.totalItems / pagination.rowsPerPage)"></v-pagination>
      </v-flex>
    </v-layout>

  </v-flex>
</template>



<script>

  import StatisticsService from '@/services/statistics.service'
  import { METRIC_OPTIONS, PERIOD_OPTIONS } from '@/constants/StatisticsConstants'

  export default {

    props: {
      startDateValue: String,
      endDateValue: String,
      periodLength: String,
      metric: String,
    },

    data() {

      return {
        is_data_fetched: false,
        isLoading: false,
        errorLoadingData: false,
        fullPage: true,
        searchText: '',
        tableData: [],
        periods: [],
        pagination: {
          rowsPerPage: 20,
          totalItems: 0,
          sortBy: 'accountStatus',
          sortDesc: true,
          page: 1,
        },
        PERIOD_OPTIONS,
        METRIC_OPTIONS,
      }
    },

    watch: {
      tableData: function(newValue) {
        this.pagination.totalItems = newValue.length
      },
      startDateValue: function() {
        this.getDataForTable()
      },
      endDateValue: function() {
        this.getDataForTable()
      },
      periodLength: function() {
        this.getDataForTable()
      }
    },


    methods: {
      // eslint-disable-next-line
      CustomSort(items, index, isDesc) {
        return items
      },

      CustomFilter(items) {
        try {
          return items;
        } catch (e) {
          console.warn("Error when custom filtering;", e)
          return items
        }
      },
      async getDataForTable() {
        this.isLoading = true
        try {
          const statisticService = new StatisticsService()
          const statistics = await statisticService.getEventCountsByProvider(this.startDateValue, this.endDateValue, this.periodLength)
          //console.log("statistics:", statistics)
          this.tableData = statistics.data
          this.periods = statistics.periods
        } catch (err) {
          console.warn("Error loading data for admin statistics:", err)
          // TODO: Implement errorLoadingData
          this.errorLoadingData = true
        }
        this.isLoading = false
      }
    },

    computed: {
      processedInfo: function() {
        return this.tableData
      },
      tableHeaders: function() {

        const headers = [
          { text: 'Provider Name',     align: 'left',    value: 'providerName',       sortable: false,  width: 200   },
        ]

        this.periods.forEach(function (period, index) {
          const periodDateString = (new Date(period)).toLocaleDateString()
          headers.push({
            text: periodDateString,
            align: 'center',
            value: `period${index + 1}`,
            sortable: false,
            width: 100
          })
        })

        return headers
      }
    },

    async mounted() {
      this.getDataForTable()
      this.is_data_fetched = true;
    }
  }

</script>

<style scoped>

</style>

<template>
  <v-container pa-0>
    <v-layout column pa-0 ma-0>
      <v-flex md1>
        <v-container pa-0 pl-0 pb-3>
          <v-layout pa-0>
            <v-flex xs12 mt-4>
              <h5 class="page-header">Create New Event</h5>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
      <v-flex md11 ma-0>
        <provider-eventstepper :event="event" :stepperState="stepperState"></provider-eventstepper>
        <v-btn small color=rgb(0,171,173) @click="CloseCreateEventDialog" style="margin: 10px 30px;">Close</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>


<script>
  import ProviderEventStepper from './provider-eventstepper'
  //import ProviderService from '../services/provider.service'

  import { LAYOUTS } from '@/constants/LayoutConstants'
  import { EVENT_STEPPER_STATE } from '../constants/EventConstants'
  import { PROVIDER_ALL_EVENTS_PAGE_PARAMETERS } from '../constants/PageParameterConstants'


  export default {

    data: () => ({
      stepperState: EVENT_STEPPER_STATE.CREATE,
      event: {}
    }),

    methods: {
      CloseCreateEventDialog() {
        this.$router.push({ name: `${ this.$store.getters.layout === LAYOUTS.ADMIN ? 'AdminAllEvents' : 'ProviderAllEvents' }`, params: { status: PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.EVENTS } });
      },
    },

    components: {
      'provider-eventstepper': ProviderEventStepper,
    },
  }
</script>


<style scoped>

  .container-rounded {
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 5px;
    margin: 5px;
  }

  .container-rounded-grey {
    border-radius: 10px;
    background-color: rgba(243, 246,248, 0.7);
    padding: 5px;
    margin: 5px;
  }

  .ABC_in_Scaffolding {
    opacity: 1;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(39,62,75,1);
    letter-spacing: 0.2px;
  }

  .Duration__1_hour {
    opacity: 1;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    letter-spacing: 0.2px;
  }
</style>

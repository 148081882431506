
import ProviderLanding from '../components/provider-landing'
import ProviderCompanyOverview from '../components/provider-companyoverview'
import ProviderAllEvents from '../components/provider-allevents'
import ProviderAllCourses from '../components/provider-allcourses'
import ProviderAllExperience from '../components/provider-allexperience'
import ProviderCreateNewExperience from '../components/provider-createnewexperience'
import ProviderCreateNewEvent from '../components/provider-createnewevent'
import ProviderPayment from '../components/provider-payment'
import ProviderPaymentConfirmation from '../components/provider-paymentconfirmation'
import ProviderContactDetails from '../components/provider-contactdetails'
import ProviderIssueCertificatesStep1 from '../components/provider-issuecertificates-step1'
import ProviderIssueCertificatesStep2 from '../components/provider-issuecertificates-step2'
import ProviderManageAttendees from '../components/provider-manageattendees'
import ProviderViewIssuedCertificates from '../components/provider-viewissuedcertificates'
import ProviderEmployeeTrainingRecords from '@/components/provider-employee-training-records'

import { LAYOUTS } from '@/constants/LayoutConstants'
import * as StoreActions from '../constants/StoreActions'
import store from '../store/index'

const baseUrl = process.env.BASE_URL;

export const routes = [
  {
    name: 'ProviderLanding',
    path: baseUrl + 'ProviderLanding',
    meta: { title: 'MyProPass - Provider Landing', requiresAuth: true, requiresProvider: true, requiresAdmin: false },
    component: ProviderLanding,
    display: 'Provider Landing',
    beforeEnter: (to, from, next) => { SetProviderLayout(to, from, next); }
  },
  {
    name: 'ProviderCompanyOverview',
    path: baseUrl + 'ProviderCompanyOverview',
    meta: { title: 'MyProPass - Provider Company Overview', requiresAuth: true, requiresProvider: true, requiresAdmin: false },
    component: ProviderCompanyOverview,
    display: 'Provider Company Overview',
    beforeEnter: (to, from, next) => { SetProviderLayout(to, from, next); }
  },
  {
    name: 'ProviderAllEvents',
    path: baseUrl + 'ProviderAllEvents/:status',
    meta: { title: 'MyProPass - Provider All Events', requiresAuth: true, requiresProvider: true, requiresAdmin: false },
    component: ProviderAllEvents,
    display: 'Provider All Events',
    beforeEnter: (to, from, next) => { SetProviderLayout(to, from, next); }
  },
  {
    name: 'ProviderAllCourses',
    path: baseUrl + 'ProviderAllCourses',
    meta: { title: 'MyProPass - Provider All Courses', requiresAuth: true, requiresProvider: true, requiresAdmin: false },
    component: ProviderAllCourses,
    display: 'Provider All Courses',
    beforeEnter: (to, from, next) => { SetProviderLayout(to, from, next); }
  },
  {
    name: 'ProviderAllExperience',
    path: baseUrl + 'ProviderAllExperience',
    meta: { title: 'MyProPass - Provider All Experience', requiresAuth: true, requiresProvider: true, requiresAdmin: false },
    component: ProviderAllExperience,
    display: 'Provider All Experience',
    beforeEnter: (to, from, next) => { SetProviderLayout(to, from, next); }
  },
  {
    name: 'ProviderCreateNewExperience',
    path: baseUrl + 'ProviderCreateNewExperience',
    meta: { title: 'MyProPass - Provider Create New Experience', requiresAuth: true, requiresProvider: true, requiresAdmin: false },
    component: ProviderCreateNewExperience,
    display: 'Provider Create New Experience',
    beforeEnter: (to, from, next) => { SetProviderLayout(to, from, next); }
  },
  {
    name: 'ProviderCreateNewEvent',
    path: baseUrl + 'ProviderCreateNewEvent',
    meta: { title: 'MyProPass - Provider Create New Event', requiresAuth: true, requiresProvider: true, requiresAdmin: false },
    component: ProviderCreateNewEvent,
    display: 'Provider Create New Event',
    beforeEnter: (to, from, next) => { SetProviderLayout(to, from, next); }
  },
  {
    name: 'ProviderPayment',
    path: baseUrl +'ProviderPayment',
    meta: { title: 'MyProPass - Provider Payment', requiresAuth: true, requiresProvider: true, requiresAdmin: false },
    component: ProviderPayment,
    display: 'Provider Payment',
    beforeEnter: (to, from, next) => { SetProviderLayout(to, from, next); }
  },
  {
    name: 'ProviderPaymentConfirmation',
    path: baseUrl +'ProviderPaymentConfirmation',
    meta: { title: 'MyProPass - Provider Payment Confirmation', requiresAuth: true, requiresProvider: true, requiresAdmin: false },
    component: ProviderPaymentConfirmation,
    display: 'Provider Payment Confirmation',
    beforeEnter: (to, from, next) => { SetProviderLayout(to, from, next); }
  },
  {
    name: 'ProviderContactDetails',
    path: baseUrl +'ProviderContactDetails/:tab',
    meta: { title: 'MyProPass - Provider Contact Details', requiresAuth: true, requiresProvider: true, requiresAdmin: false },
    component: ProviderContactDetails,
    display: 'Provider Contact Details',
    beforeEnter: (to, from, next) => { SetProviderLayout(to, from, next); }
  },
  {
    name: 'ProviderIssueCertificatesStep1',
    path: baseUrl + 'ProviderIssueCertificatesStep1/:eventId',
    meta: { title: 'MyProPass - Provider Issue Certificates Step 1', requiresAuth: true, requiresProvider: true, requiresAdmin: false },
    component: ProviderIssueCertificatesStep1,
    display: 'Provider Issue Certificates Step1',
    beforeEnter: (to, from, next) => { SetProviderLayout(to, from, next); }
  },
  {
    name: 'ProviderIssueCertificatesStep2',
    path: baseUrl + 'ProviderIssueCertificatesStep2/:eventId',
    meta: { title: 'MyProPass - Provider Issue Certificates Step 2', requiresAuth: true, requiresProvider: true, requiresAdmin: false },
    component: ProviderIssueCertificatesStep2,
    display: 'Provider Issue Certificates Step2',
    beforeEnter: (to, from, next) => { SetProviderLayout(to, from, next); }
  },
  {
    name: 'ProviderManageAttendees',
    path: baseUrl +'ProviderManageAttendees/:eventId',
    meta: { title: 'MyProPass - Provider Manage Attendees', requiresAuth: true, requiresProvider: true, requiresAdmin: false },
    component: ProviderManageAttendees,
    display: 'Provider Manage Attendees',
    beforeEnter: (to, from, next) => { SetProviderLayout(to, from, next); }
  },
  {
    name: 'ProviderViewIssuedCertificates',
    path: baseUrl +'ProviderViewIssuedCertificates/:eventId/:status',
    meta: { title: 'MyProPass - Provider View Issued Certificates', requiresAuth: true, requiresProvider: true, requiresAdmin: false },
    component: ProviderViewIssuedCertificates,
    display: 'Provider View Issued Certificates',
    beforeEnter: (to, from, next) => { SetProviderLayout(to, from, next); }
  },
  {
    name: 'ProviderEmployeeTrainingRecords',
    path: baseUrl +'ProviderEmployeeTrainingRecords',
    meta: { title: 'MyProPass - Provider View Employee Training Records', requiresAuth: true, requiresProvider: true, requiresAdmin: false },
    component: ProviderEmployeeTrainingRecords,
    display: 'Provider View Employee Training Records',
    beforeEnter: (to, from, next) => { SetProviderLayout(to, from, next); }
  },
]

async function SetProviderLayout(to, from, next) {
  await store.commit(StoreActions.SET_LAYOUT, LAYOUTS.PROVIDER);
  next();
}

export default routes


export const uploadedEventRecordByUserAndDate = /* GraphQL */ `
  query UploadedEventRecordByUserAndDate(
    $userId: ID
    $eventDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUploadedEventRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    uploadedEventRecordByUserAndDate(
      userId: $userId
      eventDateTime: $eventDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        providerName
        location
        eventDateTime
        expiryDateTime
        cpdHours
        fileName
      }
      nextToken
    }
  }
`;

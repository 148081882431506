<template>
  <v-container>
    <loading :active.sync="taskInProgress" :is-full-page="true" loader="dots"></loading>
    <v-container fluid grid-list-md v-if="is_data_fetched">
      <v-layout column pa-0 ma-0>
        <v-flex xs12 md1>
          <v-layout>
            <v-flex xs12 md2>
              <h5 class="page-header">Review Booking</h5>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs10 md10 class="container-rounded">
          <v-container fluid>
            <v-layout ma-0 justify-center align-content-center>
              <v-flex xs12 md12>
                <v-container ma-0 pa-0 fluid fill-height style="background-color: rgba(243, 246,248, 0.7);">
                  <v-layout>
                    <v-flex xs12 md2>
                      <v-container fill-height container-image>
                        <v-layout ma-0 pa-0>
                          <event-logo v-bind:imageKey="imageKey" level="public" imageStyle="background-color: transparent; margin:0.33em;" />
                        </v-layout>
                      </v-container>
                    </v-flex>
                    <v-flex xs12 md10>
                      <v-layout align-content-start ma-1>
                        <div class="ABC_in_Scaffolding">
                          <span>{{ title }}</span><br />
                          <div class="Duration__1_hour book-course-secondary-data">
                            <span style="margin-bottom: 5px;margin-top:5px;">{{ duration }} | {{ eventType }}</span>
                            <span style="float:left;margin-right: 5px;font-weight: bold;">Provider:</span><a href="#" @click="ShowProviderInfo()"><span style="color:#2385E6;">{{ providerName }}</span></a>
                            <div style="margin-top: 5px; font-size:12px;" v-html="providerDescription" />
                          </div>
                        </div>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-flex>
            </v-layout>
            <v-layout pa-0 ma-0 justify-center align-content-center>
              <v-flex xs12 md12>
                <div class="ABC_in_Scaffolding" style="margin-top:10px;margin-bottom:10px;">
                  <span>Description</span><br />
                  <div class="Duration__1_hour">
                    <span style="color: #666" v-html="description"></span>
                    <br />
                  </div>
                </div><br />
                <div class="ABC_in_Scaffolding" style="margin-bottom:10px;">
                  <span>Instructor</span><br />
                  <div class="Duration__1_hour">
                    <span style="color: #666">{{ instructor }}</span><br />
                    <span style="color: #666" v-html="aboutInstructor"></span>
                    <br />
                  </div>
                </div><br />
                <div class="ABC_in_Scaffolding" style="margin-bottom:10px;">
                  <span>Requirements</span><br />
                  <div class="Duration__1_hour">
                    <span style="color: #666">{{ requirements }}</span>
                    <br />
                  </div>
                </div><br />
                <div class="ABC_in_Scaffolding">
                  <span>Target Audience</span><br />
                  <div class="Duration__1_hour">
                    <span style="color: #666">{{ targetAudience }}</span>
                  </div>
                </div>
              </v-flex>
            </v-layout>
            <v-layout pa-0 ma-0 justify-center align-content-center>
              <v-flex xs12 md12>
                <v-container ma-0 pa-0 fluid fill-height style="background-color: rgba(243, 246,248, 0.7);">
                  <v-layout class="details-holder">
                    <v-flex xs12 md9>
                      <v-container fill-height ma-0 pa-2 pl-4>
                        <v-layout ma-0 pa-0>
                          <div class="Duration__1_hour">
                            Date: <b style="color: #333">{{ GetDateForSession(eventDetails) }}</b><br />
                            Time: <b style="color: #333">{{ startTime }} - {{ endTime }}</b><br />
                            Location: <b style="color: #333">{{ GetLocationForSession(eventDetails) }}</b><br />
                            Total: <b style="color: #333">
                              <span v-if="GetEventType(eventDetails) === EVENT_TYPE.PAID">£{{ selectedPrice.priceGBP }}</span>
                              <span v-else>Free</span>
                            </b><br />
                          </div>
                        </v-layout>
                      </v-container>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-container>
                        <v-layout style="justify-content: flex-end;">
                          <div class="Duration__1_hour" style="display: flex; flex-direction: column; justify-content: flex-end;">
                            <label style="color: #333;font-size: 12px;" v-if="GetAvailableSeatsForSession(eventDetails) > 0 && GetEventType(eventDetails) === EVENT_TYPE.CONFERENCE">
                              <input type="checkbox" :disabled="AlreadyBookedCheck(eventDetails)" id="chkTerms" name="chkTerms" value="value" style="height:16px;width:16px;vertical-align:middle">&nbsp;&nbsp;
                              <a href="https://www.mypropass.co.uk/terms-and-conditions" target="_blank">I read and accept Terms & Conditions.</a>
                            </label>
                            <div id="divTermsError" class="text-danger" style="display:none">
                              <small>Please agree to Terms and Condtion</small>
                            </div>
                            <router-link :to="{ name: 'UserBookCourseDisplay' , params: { eventId: id } }" class="v-btn v-btn--small">
                              <div style="text-align:center;font-size: 13px;">Cancel</div>
                            </router-link>
                            <v-btn v-if="GetAvailableSeatsForSession(eventDetails) > 0 && GetEventType(eventDetails) === EVENT_TYPE.PAID" small color="rgb(0,171,173)" @click="PayForBooking(eventDetails)" :disabled="AlreadyBookedCheck(eventDetails)">
                              Pay Now
                            </v-btn>
                            <v-btn v-if="GetAvailableSeatsForSession(eventDetails) > 0 && GetEventType(eventDetails) === EVENT_TYPE.FREE" small color="rgb(0,171,173)" @click="BookEvent(eventDetails)" :disabled="AlreadyBookedCheck(eventDetails)">
                              Book Course
                            </v-btn>
                          </div>
                        </v-layout>
                      </v-container>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-flex>
            </v-layout>

          </v-container>
        </v-flex>
        <v-flex xs12 md1>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container>
      <v-dialog persistent v-model="messageDialog.show" transition="dialog-transition" max-width="350">
        <v-card>
          <v-card-text>
            <v-flex>
              <span style="font-weight:normal;">
                {{messageDialog.message}}
              </span>
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="CloseMessageDialog" small color=rgb(0,171,173)>OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-container>
</template>


<script>
  import UserService from '../services/user.service'
  import EventService from '../services/event.service'
  import BookingService from '../services/booking.service'
  import StripeService from '@/services/stripe.service'

  import { EventDateTimeStringToDate, EventDateTimeStringToTime, EventDurationInMinutes, UserIsAlreadyBookedOntoEvent, UserIsOnWaitingList } from '@/helpers/event.helper'
  import { FormatDuration, DESIRED_DURATIONS } from '@/helpers/duration.helper'

  import { EVENT_MODE, EVENT_TYPE, ACTION_BUTTONS } from '@/constants/EventConstants'
  import { USER_EVENTS_PAGE_PARAMETERS } from '@/constants/PageParameterConstants'
  import { BOOKING_STATUS } from '@/constants/BookingConstants'

  import EventLogo from './eventlogo-container'

  // NOTE: Conferences don't have a book or register button, only an "add to calendar" button on the display page

  export default {
    data() {
      return {
        taskInProgress: false,
        is_data_fetched: false,
        disabled: false,
        messageDialog: {
          show: false,
          message: ''
        },
        paymentconfirmation: {
          show: false,
          message: ''
        },
        headers: [
          {
            text: 'Available Date',
            align: 'left',
            sortable: false,
            value: 'eventDate'
          },
          { text: 'Price', align: 'left', value: 'price', sortable: false },
          { text: 'Location', align: 'left', value: 'location', sortable: false },
          { text: 'Available Seats', align: 'left', value: 'availableseats', sortable: false },
          { text: 'Actions', align: 'left', value: 'action', sortable: false }
        ],
        errorFetchingDetails: false,
        eventIdProvided: true,
        eventDetails: {},
        ACTION_BUTTONS,
        EVENT_TYPE
      }
    },


    components: {
      'event-logo': EventLogo
    },


    methods: {
      /*
      RedirectStripe() {
        // TODO: FUTURE: Fix the RedirectStripe function
        document.getElementById('divTermsError').style.display = 'none';
        var chk = document.getElementById('chkTerms');
        if (chk.checked != true) {
          document.getElementById('divTermsError').style.display = 'block';
          return;
        }
        var basepath = "basepath";
        var successUrl = basepath + '/UserBookCourseReview/' + this.eventDetails.id + '?session_id={CHECKOUT_SESSION_ID}';
        var cancelURL = basepath + '/UserBookCourseReview/' + this.eventDetails.id;

        const parameters = {
          Name: this.title,
          Description: this.description,
          Amount: this.price,
          Quantity: 1,
          SuccessURL: successUrl,
          CancelURL: cancelURL
        }

        ProfessionalService.LoadStripe(parameters).then(response => {
          console.log(response);
          console.log("/src/components/user-bookcourse-review has method ProfessionalService.LoadStripe removed because it was broken")
          if (response.data && response.data.id) {
            var dataPm = response.data;
            var stripe = Stripe(this.PaymentAppKey, {});
            stripe.redirectToCheckout({
              sessionId: dataPm.id//'{{CHECKOUT_SESSION_ID}}'
            }).then(function (result) {
              console.log('came back from payment');
            }, function (stripeerror) {
              console.log('came back  from payment error');
            });
          }
          else {
            console.log('unable to load payment');
          }

        }).catch(function (err) {
        //  debugger;
          console.log(err);
        });
      },
      */

      ShowProviderInfo() {
        this.$router.push({ name: 'UserProviderInfo', params: { eventId: this.$route.params.eventId } });
      },

      showPaymentConfirmation(message) {
        this.paymentconfirmation.message = message;
        this.paymentconfirmation.show = true;
      },
      async PayForBooking(event) {
        this.taskInProgress = true;
        //console.log("Pay for booking clicked;", event)
        //console.log("price;", this.selectedPrice.priceGBP)
        // If the price is 0, skip straight to the callback function
        if (parseFloat(this.selectedPrice.priceGBP) === 0) {
          await this.BookEvent(event)
          return
        }
        // Create the stripe service and request a checkout URL
        const stripeService = new StripeService()
        var checkoutURL;
        try {
          checkoutURL = await stripeService.createCheckoutSession(event.id, this.selectedPrice.id)
        } catch (err) {
          console.warn("Error fetching checkout URL;", err)
          this.messageDialog.message = "There was an error taking payment; no money has been taken from your account.  Please try again."
          this.messageDialog.show = true
          this.taskInProgress = false;
          return
        }
        // If there is a URL, add to the document and tick it
        try {
          console.log("checkoutURL:", checkoutURL)
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = checkoutURL;
          a.click();
          document.body.removeChild(a);
        } catch (err) {
          console.warn("Error when opening checkout URL;", err)
          this.messageDialog.message = "There was an error taking payment; no money has been taken from your account.  Please try again."
          this.messageDialog.show = true
          this.taskInProgress = false;
          return
        }
      },


      async BookEvent(event) {
        //console.log(event.id);
        this.taskInProgress = true;

        if (this.AlreadyBookedCheck({id: event.id})) {
          this.messageDialog.message = "Unable to book you onto the event because you are already booked onto the event.  This is an error; please contact the administrator.";
          this.messageDialog.show = true;
          this.taskInProgress = false;
          return;
        }


        console.log("user is not booked onto event yet")
        try {
          document.getElementById('divTermsError').style.display = 'none';
          // TODO: FUTURE: Ask if the confirmation of terms is needed for free events...
          /*
          var chk = document.getElementById('chkTerms');
          if (chk.checked != true) {
            document.getElementById('divTermsError').style.display = 'block';
            return;
          }
          console.log("user has confirmed the terms")
          */
        } catch (err) {
          console.warn("Error setting checked T&C box style")
        }
        // Get all the necessary details
        const { user, bookings } = this.$store.getters
        const { providerId } = event
        const userService = new UserService(this.$store)
        console.log("Bookings:", bookings)

        try {

          if (UserIsOnWaitingList(user.id, event.id, bookings)) {
            // If the user is on the waiting list, they need to update their existing booking
            const existingBookings = bookings.items.filter((booking) => {
              return booking.status === BOOKING_STATUS.ONWAITINGLIST && booking.event.id === event.id
            })
            // If there are too many bookings for this user on this event, throw an error
            if (existingBookings.length > 1) {
              throw `There were more existing bookings than there should be; expected: 0 or 1; received: ${existingBookings.length}`
            }
            // Update the booking status for the single booking that there should be
            const updateParams = {
              id: existingBookings[0].id,
              status: BOOKING_STATUS.BOOKED,
              bookingDateTime: (new Date()).toISOString()
            }
            if (this.$route.query.session_id) {
              updateParams.extraParameters = {
                stripeSessionId: this.$route.query.session_id
              }
            }
            await BookingService.UpdateBookingStatus(updateParams)
          } else {
            // If the user is not the waiting list, they need a new booking
            const createParams = {}
            if (this.$route.query.session_id) {
              createParams.stripeSessionId = this.$route.query.session_id
            }
            await BookingService.CreateBooking(user.id, event.id, providerId, createParams)
          }

          // Get new details for the user
          await Promise.all([
            userService.GetDetails(),
            userService.GetNotifications(),
            userService.RefreshBookings()
          ])
        } catch (err) {
          console.warn("Error when creating booking", err)
          this.messageDialog.message = "There was an error when booking you onto the event.  Please try again.";
          this.messageDialog.show = true;
          this.taskInProgress = false;
          return
        }
        this.messageDialog.message = "Event booked successfully";
        this.messageDialog.show = true;
        this.taskInProgress = false;
      },

      CloseMessageDialog() {
        this.$router.push({ name: 'UserMyEvents', params: { status: USER_EVENTS_PAGE_PARAMETERS.EVENTS } });
      },
      getDisplayTime(time) {
        var hours = parseInt(time.split(":")[0]);
        var minutes = parseInt(time.split(":")[1]);
        if (minutes > 0)
          return hours + " hours " + minutes + " minutes";
        else
          return hours + " hours ";
      },

      // Ben functions start here
      GetDateForSession(eventDetails) {
        try {
          return EventDateTimeStringToDate(eventDetails.startDateTime)
        } catch (e) {
          return ''
        }
      },
      GetEventType(eventDetails) {
        try {
          return eventDetails.eventType
        } catch (e) {
          return ''
        }
      },
      GetLocationForSession(session) {
        if (session.eventMode === EVENT_MODE.INPERSON) {
          return session.address.city
        }
        if (session.eventMode === EVENT_MODE.VIRTUAL) {
          return "Online"
        }
        return "TBC"
      },
      GetAvailableSeatsForSession(session) {
        try {
          const { attendees, maximumAttendees } = session
          const bookedSeats = attendees.items.length
          if (bookedSeats > maximumAttendees) { return 0 }
          return maximumAttendees - bookedSeats
        } catch (err) {
          console.warn("Error getting available seats:", err)
          return 0
        }
      },
      AlreadyBookedCheck(event) {
        try {
          const { user, bookings } = this.$store.getters
          return UserIsAlreadyBookedOntoEvent(user.id, event.id, bookings)
        } catch (err) {
          console.warn("Error performing already booked onto event check:", err)
          return true
        }
      }
    },

    computed: {
      id: function() {
        try { return this.eventDetails.id } catch (e) { return '' }
      },
      title: function() {
        try { return this.eventDetails.title } catch (e) { return '' }
      },
      startTime: function() {
        try { return EventDateTimeStringToTime(this.eventDetails.startDateTime) } catch (e) { return '' }
      },
      endTime: function() {
        try { return EventDateTimeStringToTime(this.eventDetails.endDateTime) } catch (e) { return '' }
      },
      eventDate: function() {
        try { return EventDateTimeStringToDate(this.eventDetails.startDateTime) } catch (e) { return '' }
      },
      description: function() {
        try { return this.eventDetails.description } catch (e) { return '' }
      },
      requirements: function() {
        try { return this.eventDetails.preRequisites } catch (e) { return '' }
      },
      targetAudience: function() {
        try { return this.eventDetails.targetAudience } catch (e) { return '' }
      },
      duration: function() {
        try {
          const eventDuration = EventDurationInMinutes(this.eventDetails)
          return FormatDuration(eventDuration, DESIRED_DURATIONS.HoursMinutes)
        } catch (e) {
          return ''
        }
      },
      eventType: function() {
        try { return this.eventDetails.eventType } catch (e) { return '' }
      },
      instructor: function() {
        try {
          return `${this.eventDetails.instructor.firstName} ${this.eventDetails.instructor.lastName}`
        } catch (e) {
          //console.warn("Error when getting instructor name:", e)
          return 'No instructor assigned'
        }
      },
      aboutInstructor: function() {
        try { return this.eventDetails.instructor.employer.items[0].courseInstructorDetails.aboutText } catch (e) { return 'Instructor details to follow' }
      },
      providerName: function() {
        try { return this.eventDetails.provider.name } catch (e) { return '' }
      },
      providerDescription: function() {
        try { return this.eventDetails.provider.description } catch (e) { return '' }
      },
      sessions: function() {
        try { return [this.eventDetails] } catch (e) { return '' }
      },
      eventId: function() {
        try {
          if (this.$route.params.eventId) { return this.$route.params.eventId }
          if (this.$route.query.eventId) { return this.$route.query.eventId }
          return undefined
        } catch (err) { return undefined }
      },
      priceId: function() {
        try {
          if (this.$route.params.priceId) { return this.$route.params.priceId }
          if (this.$route.query.priceId) { return this.$route.query.priceId }
          return undefined
        } catch (err) { return undefined }
      },
      imageKey: function() {
        try { return this.eventDetails.eventLogo.imageKey } catch (err) { return '' }
      },
      selectedPrice: function() {
        //console.log('this.priceId:', this.priceId)
        if (this.priceId) {
          //console.log('this.eventDetails.prices:', this.eventDetails.prices)
          return this.eventDetails.prices.items.filter((priceItem) => {
            return priceItem.id === this.priceId
          })[0]
        }
        //console.log('this.eventDetails.prices:', this.eventDetails.prices)
        return this.eventDetails.prices.items[0]
      },
    },



    async mounted() {
      console.log("Params:", this.$route.params)
      console.log("Query:", this.$route.query)

      try {
        // Fetch the event details from the server
        const eventService = new EventService(this.$store)
        //console.log("this.eventId:", this.eventId)
        const eventDetails = await eventService.GetEventDetailsForBookingScreen(this.eventId)
        //console.log(eventDetails)
        this.eventDetails = eventDetails
        this.is_data_fetched = true;
        // If this is a successful return from Stripe, then handle the return
        if (this.$route.query.stripe_payment_success === "True") {
          console.log("Stripe payment successful")
          await this.BookEvent(eventDetails)
          return
        }
        return
      } catch (err) {
        console.warn("Unable to get event details:", err)
        this.errorFetchingDetails = true
      }

      // If there wasn't an event id, show an error
      this.eventIdProvided = false
      this.is_data_fetched = true
    }
  }

</script>

<style scoped>
  .v-btn {
    text-decoration: none;
    text-transform: capitalize;
  }

  .container-rounded {
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 5px;
    margin: 5px;
  }

  .container-image {
    padding: 0;
  }

  .container-rounded-grey {
    border-radius: 10px;
    background-color: rgba(243, 246,248, 0.7);
    padding: 5px;
    margin: 5px;
  }

  .ABC_in_Scaffolding {
    opacity: 1;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: left;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(39,62,75,1);
    letter-spacing: 0.2px;
    font-family: basefont;
  }

  .Duration__1_hour {
    opacity: 1;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: left;
    font-style: normal;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: basefont;
    color: #999;
  }

  .terms-popup h2 {
    font-weight: bold;
  }

  .terms-popup h2, .terms-popup p, .terms-popup li {
    font-size: 14px;
    font-family: basefont;
  }

  .terms-popup p, .terms-popup li {
    font-weight: normal;
  }

  .terms-popup ol {
    margin-bottom: 0;
    padding-left: 15px;
  }

  .details-holder {
    flex-direction: column;
  }

  .details-holder .v-btn {
    width: 100%;
    max-width: 160px;
    margin: auto;
    margin-bottom: 10px;
  }

  @media (min-width: 600px) {
    .details-holder {
      flex-direction: row;
    }

    .details-holder .v-btn {
      margin-left: auto;
      margin-right: 0;
    }
  }

</style>


import { API, Storage, graphqlOperation } from 'aws-amplify';
//import * as Queries from '../graphql/queries';
import * as QueriesCustom from '../graphql/queriesCustom';
import * as Mutations from '../graphql/mutations';
import * as MutationsCustom from '@/graphql/mutationsCustom'
import * as StoreActions from '../constants/StoreActions'

import { cleanObjectOfEmptyParameters } from '../helpers/general.helper'

// TODO: FUTURE: Make these functions do all the store stuff here
class UserService {


  constructor(store) {
    this.$store = store
  }

  // Get the details for a user.  Parameters has the following structure:
  // {
  //    id: ...Cognito Username...
  // }
  async GetDetails(userId) {
    //console.log("Fetching user details from the user service")
    try {
      const parameters = {
        id: userId ? userId : this.$store.getters.cognito.username
      }
      if (!parameters.id) {
        console.warn("User ID was not provided or found in the store")
        return {}
      }
      //console.log("parameters:", parameters)
      const response = await API.graphql(graphqlOperation(QueriesCustom.getUserDetails, parameters));
      const result = response.data.getUser
      //console.log("UserService Get Details fetch result:", result)
      if (!result) { throw new Error("Unable to retrieve details; user does not exist in the database")}
      this.$store.commit(StoreActions.SET_CUSTOM_GET_USER_DETAILS, result);
      return result
    } catch (err) {
      console.warn("Error getting user details:", err)
      // If there was some data returned anyway, store what you have
      if (err.data) {
        console.log("attempting recovery of user details")
        const result = err.data.getUser
        console.log(result)
        this.$store.commit(StoreActions.SET_CUSTOM_GET_USER_DETAILS, result);
        return result
      }
      return {}
    }
  }
  // Get the notifications for a user.  Parameters has the following structure:
  // {
  //    id: ...Cognito Username...
  // }
  async GetNotifications() {
    //console.log("Fetching user notifications from the user service")
    try {
      const parameters = {
        userId: this.$store.getters.user.id
      }
      const response = await API.graphql(graphqlOperation(QueriesCustom.notificationsByUser, parameters));
      const result = response.data.notificationsByUser
      this.$store.commit(StoreActions.SET_NOTIFICATIONS, result);
      return result
    } catch (e) {
      console.warn("Error getting user notifications:", e)
      return {}
    }
  }



  async UpdateUserDetails(parameters) {
    //console.log("Updating user details in the user service")
    //console.log("Update parameters:", parameters)
    const filteredParameters = cleanObjectOfEmptyParameters(parameters)
    const queryParameters = {
      input: {
        ...filteredParameters,
      }
    }
    try {
      await API.graphql(graphqlOperation(Mutations.updateUser, queryParameters));
      await this.GetDetails(parameters.id)
      return {
        status: "SUCCESS",
      }
    } catch (err) {
      console.warn("Error updating user details:", err)
      return {
        status: "FAILED",
        error: err
      }
    }
  }

  async GetUserAvatarSignedURL(user) {
    // First, check that the user has an avatar
    var userAvatarKeyString
    try {
      userAvatarKeyString = user.userDetail.avatar.imageKey
    } catch (err) {
      //console.log("Error getting user avatar; using default")
      return process.env.VUE_APP_placeholder_avatar_url
    }
    // Check that the avatar isn't blank
    if (userAvatarKeyString === '') {
      return process.env.VUE_APP_placeholder_avatar_url
    }
    // Build the parameters for the signed URL
    const splitAvatarKey = userAvatarKeyString.split('/')
    var parameters = {
      level: splitAvatarKey[0],
      identityId: splitAvatarKey[1],
      expires: 3600
    }
    // Fetch the link
    const result = await Storage.get(splitAvatarKey.slice(2), parameters);
    //console.log(result)
    return result
  }

  async FindUserByEmailAddress(emailAddress) {
    //console.log("Finding user from their email address")
    //console.log(emailAddress)
    const parameters = {
      emailAddress: emailAddress.toLowerCase()
    }
    try {
      const response = await API.graphql(graphqlOperation(QueriesCustom.userByEmail, parameters));
      const result = response.data.userByEmail.items
      console.log("FindUserByEmailAddress result:", result)
      return result
    } catch (e) {
      console.warn("Error getting user details by email address:", e)
      // If some data was recovered, return it anyway as we can work with partial data
      if (e.data) {
        const result = e.data.userByEmail.items
        return result
      }
      return {}
    }
  }

  /**
   * @function
   * @name RefreshBookings returns a graphql promise to fetch the booking record for the user
   * and save it to the Vuex store
   * @return {promise} The graphql promise to fetch the details
   */
  async RefreshBookings() {
    //console.log("Fetching user bookings from the user service")
    const parameters = {
      userId: this.$store.getters.user.id
    }
    try {
      const response = await API.graphql(graphqlOperation(QueriesCustom.eventBookingByUserId, parameters));
      const result = response.data.eventBookingByUserId
      //console.log(result)
      this.$store.commit(StoreActions.SET_BOOKINGS, result);
      return result
    } catch (e) {
      console.warn("Error getting user bookings:", e)
      // If there was some data returned anyway, store what you have
      if (e.data) {
        console.log("Attempting recovery of user bookings")
        const result = e.data.eventBookingByUserId
        console.log(result)
        this.$store.commit(StoreActions.SET_BOOKINGS, result);
        return result
      }
      return {}
    }
  }


  async ExtendBookingsForUser() {
    //console.log("Extending bookings for user")
    // If there are no more events to fetch, return and do nothing
    if (!this.$store.getters.bookings.nextToken) {
      console.log("Requested extending bookings for user, but there are no more bookings")
      return
    }
    const parameters = {
      userId: this.$store.getters.user.id,
      nextToken: this.$store.getters.bookings.nextToken
    }
    try {
      const response = await API.graphql(graphqlOperation(QueriesCustom.eventBookingByUserId, parameters));
      //console.log("eventBookingByUserId response:", response)
      const result = response.data.eventBookingByUserId
      this.$store.commit(StoreActions.EXTEND_BOOKINGS, result);
      return result
    } catch (e) {
      console.warn("Error getting booking list:", e)
      // If some data was recovered, save it anyway
      if (e.data) {
        const result = e.data.eventBookingByUserId
        this.$store.commit(StoreActions.EXTEND_BOOKINGS, result);
        return result
      }
      return {}
    }
  }

  async AcceptPendingEmployeeInvitation(invite) {
    const createParameters = {
      input: invite
    }
    delete createParameters.input.emailAddress
    delete createParameters.input.employer
    delete createParameters.input.createdAt
    //console.log("createParameters:", createParameters)
    const deleteParameters = {
      input: {
        id: invite.id
      }
    }
    await API.graphql(graphqlOperation(MutationsCustom.createEmployeeRole, createParameters));
    await API.graphql(graphqlOperation(MutationsCustom.deletePendingEmployee, deleteParameters));
  }

  async RejectPendingEmployeeInvitation(invite) {
    const deleteParameters = {
      input: {
        id: invite.id
      }
    }
    await API.graphql(graphqlOperation(MutationsCustom.deletePendingEmployee, deleteParameters));
  }

  async DeleteEmployeeRole(employeeId) {
    const deleteParameters = {
      input: {
        id: employeeId
      }
    }
    await API.graphql(graphqlOperation(MutationsCustom.deleteEmployeeRole, deleteParameters));
  }

}

export default UserService

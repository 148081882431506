import { render, staticRenderFns } from "./admin-statistic-certificates-by-user.vue?vue&type=template&id=1186cc5a&scoped=true"
import script from "./admin-statistic-certificates-by-user.vue?vue&type=script&lang=js"
export * from "./admin-statistic-certificates-by-user.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1186cc5a",
  null
  
)

export default component.exports
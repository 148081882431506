
import { EventDurationInMinutes } from '@/helpers/event.helper'
import { FormatDuration, DESIRED_DURATIONS } from '@/helpers/duration.helper'
import { BOOKING_ATTENDANCE, BOOKING_STATUS } from '@/constants/BookingConstants'
import CERTIFICATE_TYPES from '@/constants/CertificateTypes'
import { EVENT_STATUS } from '@/constants/EventConstants'

class EmployeeTrainingRecords {

  constructor() {
  }

  getBookingStatus(booking) {

    const { certificateSaved, isUploaded, event } = booking

    if (isUploaded) {
      return "Certificate From User"
    }
    if (event.status === EVENT_STATUS.CANCELLED ) {
      return "Event Cancelled"
    }
    if (booking.status === BOOKING_STATUS.CANCELLED) {
      return "Booking Cancelled"
    }
    if (booking.status === BOOKING_STATUS.ONWAITINGLIST) {
      return "On Waiting List"
    }
    if (booking.status === BOOKING_STATUS.BOOKED) {
      return "Pending"
    }
    if (booking.attendance === BOOKING_ATTENDANCE.NOSHOW) {
      return "Absent"
    }
    if (certificateSaved) {
      return "Certificate Available"
    }
    console.log("error getting status:", booking)
    return "Error getting status"
  }


  bookingHasCertificate(booking) {
    try {
      return booking.certificateSaved && booking.attendance === BOOKING_ATTENDANCE.ATTENDED
    } catch (e) {
      return false
    }
  }


  async DownloadRecords(filteredRecords, employerGroups) {

    const headingTitlesPreGroups = [
      "Employee Name",
      "Employee Email",
    ]

    const headingTitlesPostGroups = [
      "Course/Experience Name",
      "Course/Experience Description",
      "Certificate Type",
      "Course Start",
      "Course End",
      "Course Duration",
      "Course Certificate Expires",
      "Course Attendance Status",
      "Experience Start",
      "Experience End",
      "Experience Duration",
      "Experience Certificate Issued",
      "Has Certificate",
      "Issuing Organisation"
    ]

    let headingTitles = [
      ...headingTitlesPreGroups
    ]

    for (let group of employerGroups) {
      headingTitles.push(group.name)
    }

    headingTitles = [...headingTitles, ...headingTitlesPostGroups]

    const headerRow = `data:text/csv;charset=utf-8,"${headingTitles.join('","')}"\r\n`
    var csvContent = headerRow
    // Extract the content from each attendee
    const contentRows = filteredRecords.map((record) => {
      const certType = record.meta.type
      const startDateObject = new Date(record.event.startDateTime)
      const endDateObject = new Date(record.event.endDateTime)
      const issueDateObject = new Date(record.event.issueDateTime)
      const eventDuration = certType === CERTIFICATE_TYPES.SELF_UPLOADED ? record.event.duration : EventDurationInMinutes(record.event)
      const desiredFormatting = certType === CERTIFICATE_TYPES.EXPERIENCE ? DESIRED_DURATIONS.Days : DESIRED_DURATIONS.ClockTimeHoursMinutes
      const formattedEventDuration = FormatDuration(eventDuration, desiredFormatting)

      const rowDataPreGroups = [
        `${record.user.firstName} ${record.user.lastName}`,
        `${record.user.emailAddress}`,
      ]

      const rowDataPostGroups = [
        `${record.event.title}`,
        `${record.event.description.replace(/(<([^>]+)>)/gi, "")}`,
        `${certType}`,
        `${certType === CERTIFICATE_TYPES.EXPERIENCE ? "" : startDateObject.toLocaleDateString()}`,
        `${certType === CERTIFICATE_TYPES.EXPERIENCE ? "" : endDateObject.toLocaleDateString()}`,
        `${certType === CERTIFICATE_TYPES.EXPERIENCE ? "" : formattedEventDuration}`,
        `${record.event.certificatesExpire ? new Date(record.event.certificateExpiryDateTime).toLocaleDateString() : ""}`,
        `${this.getBookingStatus(record)}`,
        `${certType !== CERTIFICATE_TYPES.EXPERIENCE ? "" : startDateObject.toLocaleDateString()}`,
        `${certType !== CERTIFICATE_TYPES.EXPERIENCE ? "" : endDateObject.toLocaleDateString()}`,
        `${certType !== CERTIFICATE_TYPES.EXPERIENCE ? "" : formattedEventDuration}`,
        `${certType !== CERTIFICATE_TYPES.EXPERIENCE ? "" : issueDateObject.toLocaleDateString()}`,
        `${this.bookingHasCertificate(record)}`,
        `${record.meta.issuedBy}`,
      ]

      let rowData = [
        ...rowDataPreGroups,
      ]

      const groupIds = record.meta.employerGroups.map((group) => group.id)
      // Add the group membership information to the row
      for (let group of employerGroups) {
        if (groupIds.includes(group.id)) {
          rowData.push("Yes")
        } else {
          rowData.push("No")
        }
      }
      // Add the rest of the row data
      rowData = [...rowData, ...rowDataPostGroups]
      return `"${rowData.join('","')}"`
    })
    //console.log("contentRows:", contentRows)

    // Join all the attendee rows to the heading
    for (const row of contentRows) {
      csvContent = csvContent + row + "\r\n"
    }
    //console.log("csvContent:", csvContent)

    // Create the label for the file
    const label = `Training Records - ${new Date().toLocaleDateString()}`

    var encodedUri = encodeURI(csvContent);
    // Encode the octothorpes because they cause the output to be truncated
    encodedUri = encodedUri.replace(/#/g, "%23")
    //console.log("encodedUri:", encodedUri)
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${label}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file
    document.body.removeChild(link);
  }

}

export default EmployeeTrainingRecords

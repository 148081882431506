
//import router from '../router'
import { Auth } from 'aws-amplify';
import { API, /*graphqlOperation*/ } from 'aws-amplify';
import * as Mutations from '../graphql/mutations';

const AuthService = {
  // Get the current session and it's details for the user
  async GetCurrentSession() {
    return Auth.currentSession();
  },
  // Get the current authenticated user
  async GetCurrentAuthenticatedUser() {
    return Auth.currentAuthenticatedUser();
  },
  // Sign the user in using their username and password
  SignIn(parameters) {
    //console.log("Requesting Sign In via Cognito")
    return Auth.signIn(parameters.email, parameters.password);
  },
  // Sign the user up using their details
  SignUp(parameters) {
    //console.log("Requesting Sign Up via Cognito")
    return Auth.signUp(parameters);
  },
  // Sign the user up using their details
  ConfirmSignUp(username, code) {
    //console.log("Requesting Sign Up via Cognito")
    return Auth.confirmSignUp(username, code);
  },
  // Request the users password is reset
  RequestResetPassword(username) {
    //console.log("Requesting Reset Password via Cognito")
    return Auth.forgotPassword(username);
  },
  // Reset the users password
  ResetPasswordSubmit(username, code, new_password) {
    //console.log("Submitting Reset Password via Cognito")
    return Auth.forgotPasswordSubmit(username, code, new_password);
  },
  async ChangePassword(oldPassword, newPassword) {
    //console.log("oldPassword:", oldPassword)
    //console.log("newPassword:", newPassword)
    const user = await Auth.currentAuthenticatedUser()
    //console.log("user:", user)
    return Auth.changePassword(user, oldPassword, newPassword);
  },
  // Log the user out from Cognito and the platform
  LogOut() {
    //console.log("Requesting Sign Out via Cognito")
    // TODO: FUTURE: Add the reset of the store to this
    return Auth.signOut();
  },
  RespondToChallengeNewPasswordRequired(userLogin, newPassword) {
    //console.log("Responding to Auth Challenge to change password")
    /*
    const parameters = {
      ChallengeName: userLogin.challengeName,
      ChallengeResponses: {
        USERNAME: userLogin.username,
        NEW_PASSWORD: newPassword,
      },
      ClientId: userLogin.pool.clientId,
      Session: userLogin.Session
    }
    */
    return Auth.completeNewPassword(userLogin, newPassword)
  },
  ResendUserTemporaryPassword(parameters) {
    //console.log("Requesting password reset for user with expired temporary password")
    //console.log("Parameters:", parameters)
    return API.graphql({
      query: Mutations.resendTemporaryPassword,
      variables: { userEmail: parameters.email },
      authMode: 'AWS_IAM'
    });
  },
  // Reset the users password
  RequestResendConfirmationEmail(parameters) {
    //console.log("Requesting new confirmation code via Cognito")
    return API.graphql({
      query: Mutations.resendConfirmationEmail,
      variables: { userEmail: parameters.email },
      authMode: 'AWS_IAM'
    });
  },
}

export default AuthService


import { API, graphqlOperation } from 'aws-amplify';
import * as Mutations from '../graphql/mutations';
import EventPrice from '@/models/eventprice.model'
import { EVENT_PRICE_STATUS } from '@/constants/EventPriceConstants'
import { v4 as uuidv4 } from 'uuid';

class PriceService {

  /**
   * CreatePriceForEvent creates a single price for a given event
   * @param  {EventPrice} eventPrice  An EventPrice model of the price to create
   * @return {Promise}                A promise containing a request to update the database
   * @throws {error}                  passes any error up to the calling function
   */
  async CreatePriceForEvent(eventPrice) {
    // If this isn't an instance of EventPrice, throw an error
    if (!(eventPrice instanceof EventPrice)) {
      throw "Argument 'eventPrice' was not an instance of EventPrice"
    }
    // If this price hasn't got an ID yet, give it one
    if (eventPrice.getId() === "0" || !eventPrice.getId()) {
      eventPrice.setField("id", uuidv4())
    }
    // Create the args for the AppSync request
    const details = {
      input: eventPrice.getGraphQLObject()
    }
    console.log("details:", details)
    return API.graphql(graphqlOperation(Mutations.createEventPrice, details));
  }


  /**
   * UpdatePriceForEvent updates a single price for a given event
   * @param  {EventPrice} eventPrice  An EventPrice model of the price to create
   * @return {Promise}                A promise containing a request to update the database
   * @throws {error}                  passes any error up to the calling function
   */
  async UpdatePriceForEvent(eventPrice) {
    // If this isn't an instance of EventPrice, throw an error
    if (!(eventPrice instanceof EventPrice)) {
      throw "Argument 'eventPrice' was not an instance of EventPrice"
    }
    console.log("eventPrice.getGraphQLObject():", eventPrice.getGraphQLObject())
    // Create the args for the AppSync request
    const updatedDetails = {
      input: eventPrice.getGraphQLObject()
    }
    return API.graphql(graphqlOperation(Mutations.updateEventPrice, updatedDetails));
  }

  /**
   * DeletePriceOption creates a single price for a given event
   * @param  {EventPrice} eventPrice  An EventPrice model of the price to delete
   * @return {Promise}                A promise containing a request to update the database
   * @throws {error}                  passes any error up to the calling function
   */
  DeletePriceOption(eventPrice){
    // If this isn't an instance of EventPrice, throw an error
    if (!(eventPrice instanceof EventPrice)) {
      throw "Argument 'eventPrice' was not an instance of EventPrice"
    }
    // Change the status to deleted (so that the database payments still reference this price)
    eventPrice.setField('status', EVENT_PRICE_STATUS.DELETED)
    // Create the args for the AppSync request
    const updatedDetails = {
      input: eventPrice.getGraphQLObject()
    }
    //
    return API.graphql(graphqlOperation(Mutations.updateEventPrice, updatedDetails));
  }
}

export default PriceService


export const eventByEmployerAndStartDate = /* GraphQL */ `
  query EventByEmployerAndStartDate(
    $providerId: ID
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByEmployerAndStartDate(
      providerId: $providerId
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        code
        targetAudience
        isExternal
        externalType
        eventType
        eventMode
        accessLevel
        status
        possibleActions
        providerId
        provider {
          id
          name
          description
          logo {
            id
            name
            imageKey
          }
        }
        instructorId
        instructor {
          id
          firstName
          lastName
          employer {
            items {
              courseInstructorDetails {
                phone
                email
                aboutText
              }
            }
            nextToken
          }
        }
        eventLogoId
        eventLogo {
          id
          name
          imageKey
        }
        signatureId
        preRequisites
        proofOfIdentity
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
        }
        onlineURL
        prices {
          items {
            id
            title
            description
            priceGBP
            priceUSD
            priceEUR
            eventId
            status
          }
          nextToken
        }
        maximumAttendees
        startDateTime
        endDateTime
        cpdHours
        attendanceHours
        latitude
        longitude
        eventValidityDateTime
        certificatesExpire
        certificateExpiryDateTime
        accreditationLogoId
        accreditationLogo {
          id
          name
          imageKey
        }
        attendees {
          items {
            id
            status
            attendance
            certificateSent
            certificateSaved
          }
          nextToken
        }
        attendeeStatistics {
          booked
          onWaitingList
          attended
          noShow
        }
        certificateTemplateId
        certificateCustomisation {
          colorBackground
          colorFontPrimary
          colorFontSecondary
          useCertificateSignerName
          certificateSignerName
        }
      }
      nextToken
    }
  }
`;


export const eventByStatusAndStartDate = /* GraphQL */ `
  query EventByStatusAndStartDate(
    $status: String
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByStatusAndStartDate(
      status: $status
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        code
        targetAudience
        isExternal
        externalType
        eventType
        eventMode
        accessLevel
        status
        possibleActions
        providerId
        provider {
          id
          name
          description
          logo {
            id
            name
            imageKey
          }
        }
        instructorId
        instructor {
          id
          firstName
          lastName
          employer {
            items {
              courseInstructorDetails {
                phone
                email
                aboutText
              }
            }
            nextToken
          }
        }
        eventLogoId
        eventLogo {
          id
          name
          imageKey
        }
        signatureId
        preRequisites
        proofOfIdentity
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
        }
        onlineURL
        prices {
          items {
            id
            title
            description
            priceGBP
            priceUSD
            priceEUR
            eventId
            status
          }
          nextToken
        }
        maximumAttendees
        startDateTime
        endDateTime
        cpdHours
        attendanceHours
        latitude
        longitude
        eventValidityDateTime
        certificatesExpire
        certificateExpiryDateTime
        accreditationLogoId
        accreditationLogo {
          id
          name
          imageKey
        }
        attendees {
          items {
            id
            status
            attendance
            certificateSent
            certificateSaved
          }
          nextToken
        }
        attendeeStatistics {
          booked
          onWaitingList
          attended
          noShow
        }
        certificateTemplateId
        certificateCustomisation {
          colorBackground
          colorFontPrimary
          colorFontSecondary
          useCertificateSignerName
          certificateSignerName
        }
      }
      nextToken
    }
  }
`;

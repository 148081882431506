
import NotLoggedInRoutes from './not-logged-in-routes'
import UserRoutes from './user-routes'
import ProviderRoutes from './provider-routes'
import AdminRoutes from './admin-routes'

export const routes = [
  ...NotLoggedInRoutes,
  ...UserRoutes,
  ...ProviderRoutes,
  ...AdminRoutes,
  { path: '*', redirect: { name: 'Login' }},
]

export default routes

<template>
  <v-container fluid fill-height v-if="is_data_fetched">
    <v-layout column>
      <v-flex xs2>
      </v-flex>
      <v-flex xs10>
        <v-layout justify-center xs8 ma-0 pa-0>
          <v-flex xs10>
            <h5>Confirmation</h5>
            <v-layout justify-center class="container-rounded">
              <v-flex xs3>
              </v-flex>
              <v-flex xs6>
                <v-container fluid fill-height>
                  <v-layout column>
                    <v-flex xs2>
                      <div class="center">
                        <v-img :src="require('../images/paymentconfirmationtick.png')" height="50px" width="50px"></v-img>
                      </div>
                      <div style="text-align:center;">
                        Payment Confirmation
                        <br /><br />
                      </div>
                    </v-flex>
                    <v-flex xs10 class="container-rounded-grey">
                      <v-layout column style="padding:5px;">
                        <v-flex xs2>
                          <div style="height:25px;"></div>
                        </v-flex>
                        <v-flex xs4 fluid fill-height>
                          <div style="text-align:center;font-weight:normal;font-style:italic;">
                            <span>Thank you for your booking.</span><br />
                            <span>This course will now be added to your CPD records.</span><br />
                            <span>An invoice will be sent to your e-mail address.</span>
                          </div>
                        </v-flex>
                        <v-flex xs6>
                          <hr />
                          <div style="text-align:left;padding-left:10px;">
                            <span style="font-weight:normal;">
                              Course: <span style="font-weight:bold;">{{Title}}</span>
                            </span><br />
                          </div>
                          <div style="text-align:left;padding-left:10px;">
                            <span style="font-weight:normal;">
                              Date: <span style="font-weight:bold;">{{EventDate}}</span>
                            </span><br />
                          </div>
                          <div style="text-align:left;padding-left:10px;">
                            <span style="font-weight:normal;">
                              Time: <span style="font-weight:bold;">{{StartTime}} - {{EndTime}} Hrs</span>
                            </span><br /><br />
                          </div>
                          <div style="text-align:left;padding-left:10px;">
                            <span style="font-weight:normal;">
                              Location: <span style="font-weight:bold;">{{Location}}</span>
                            </span><br /><br />
                          </div>
                          <div style="text-align:left;padding-left:10px;">
                            <span style="font-weight:normal;">
                              Total: <span style="font-weight:bold;">{{Price}}</span>
                            </span><br /><br />
                          </div>
                          <div style="text-align:center;">
                            <v-btn color="rgb(0,171,173)" small="true" :to="{ name: 'UserLanding', params: { status: USER_EVENTS_PAGE_PARAMETERS.EVENTS }}">
                              Close
                            </v-btn><br />
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-flex>
              <v-flex xs3></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>

</template>
<script>
  import ProfessionalService from '../services/professional.service'
  import { USER_EVENTS_PAGE_PARAMETERS } from '../constants/PageParameterConstants'

  export default {
    data() {
      return {
        is_data_fetched: false,
        Title: '',
        EventDate: '',
        Price: '',
        Location: '',
        StartTime: '',
        EndTime: '',
        USER_EVENTS_PAGE_PARAMETERS
      }
    },
    methods: {

    },

    mounted() {

      ProfessionalService.GetEventInfo(this.$route.params.eventId)
        .then(response => {
          console.log(response);
          var result = response.data;
          this.EventDate = result.eventDate;
          this.Price = result.price;
          this.Location = result.address;
          this.Title = result.title;
          this.StartTime = result.startTime;
          this.EndTime = result.endTime;
          this.is_data_fetched = true;
        })
        .catch(function (err) {
          console.log(err);
        });

    }
  }
</script>
<style scoped>
  .v-btn {
    text-decoration: none;
    text-transform: capitalize;
  }

  .center {
    position: relative; /* where the next element will be automatically positioned */
    display: inline-block; /* causes element width to shrink to fit content */
    left: 50%; /* moves left side of image/element to center of parent element */
    transform: translate(-50%); /* centers image/element on "left: 50%" position */
  }

  .container-rounded {
    border-radius: 10px;
    background-color: #FFFFFF;
    padding-top: 0px;
    margin: 0px;
  }

  .container-rounded-grey {
    border-radius: 10px;
    background-color: rgba(243, 246,248, 0.7);
    padding: 0px;
    margin: 0px;
  }

</style>


import { API, graphqlOperation } from 'aws-amplify';
import { PERIOD_OPTIONS, CERTIFICATES_BY_USER_STATISTIC_NAMES, STATISTICS_COGNITO } from '@/constants/StatisticsConstants'
import * as QueriesCustom from '../graphql/queriesCustom';

class StatisticsService {

  constructor() {
  }

  async getEventCountsByProvider(startDateTime, endDateTime, periodLength) {
    // Check that a startDateTime was provided that can be used
    const startDateTimeObject = new Date(startDateTime)
    if (!(startDateTimeObject instanceof Date) || isNaN(startDateTimeObject)) {
      console.warn("Invalid startDateTime provided to getEventCountsByProvider")
      return {
        data: [],
        periods: []
      }
    }
    // Check that a endDateTime was provided that can be used
    const endDateTimeObject = new Date(endDateTime)
    if (!(endDateTimeObject instanceof Date) || isNaN(endDateTimeObject)) {
      console.warn("Invalid endDateTime provided to getEventCountsByProvider")
      return {
        data: [],
        periods: []
      }
    }
    // Check that a period was provided that can be used
    if (!PERIOD_OPTIONS[periodLength]) {
      console.warn("No period provided to getEventCountsByProvider")
      return {
        data: [],
        periods: []
      }
    }

    // Get all the dates to fetch data for
    const statisticDates = this.getListOfStatisticDatesToFetch(startDateTime, endDateTime, periodLength)
    const earlierStatisticDate = this.getStatisticDateForPeriodBeforeFirstPeriodRequested(startDateTime, periodLength)

    try {
      // For each date to fetch, fetch it concurrently
      const results = await Promise.all(statisticDates.map((date) => {
        const parameters = {
          dateTime: date,
          countryCodeStatisticName: {
            beginsWith: {
              countryCode: "GB",
              statisticName: "Provider_Events_Created_"
            }
          },
          countryCode: "GB"
        }
        //console.log("parameters:", parameters)
        return API.graphql(graphqlOperation(QueriesCustom.getStatisticsByDateAndCountry, parameters));
      }))
      //console.log("results:", results)

      // Fetch the data for the period before the first period as this will be needed
      // for the first column of a non-daily period
      var earlierDateItems = []
      try {
        const parameters = {
          dateTime: earlierStatisticDate,
          countryCodeStatisticName: {
            beginsWith: {
              countryCode: "GB",
              statisticName: "Provider_Events_Created_"
            }
          },
          countryCode: "GB"
        }
        //console.log("parameters:", parameters)
        const tempResult = await API.graphql(graphqlOperation(QueriesCustom.getStatisticsByDateAndCountry, parameters));
        earlierDateItems = tempResult.data.getStatisticsByDateAndCountry.items
      } catch (err) {
        console.warn("Unable to get earlier date items:", err)
      }
      //console.log("earlierDateItems:", earlierDateItems)

      // Initialise an array to hold all the returned items
      var allItems = []

      // Extract all the items from the returned data
      results.forEach(function (fetchResult) {
        try {
          allItems = allItems.concat(fetchResult.data.getStatisticsByDateAndCountry.items)
        } catch (err) {
          console.warn("unable to extract data for entry in returned data;", fetchResult)
        }
      })

      //console.log("allItems:", allItems)

      // Initialise an object to hold all the returned items
      var returnArray = []

      // Collect all the unique statistic names
      const statisticNames = [ ...new Set(allItems.map(item => item.statisticName))]

      // For each item, add its data to the return array
      statisticNames.forEach(function (statisticName) {

        const itemsForCurrentStatistic = allItems.filter((item) => {
          return item.statisticName === statisticName
        })

        const itemsForEarlierDate = earlierDateItems.filter((item) => {
          return item.statisticName === statisticName
        })

        // If there is nothing to add, return
        if (itemsForCurrentStatistic.length === 0) {
          return
        }

        const additionalData = JSON.parse(itemsForCurrentStatistic[0].additionalData)
        const providerName = additionalData.providerName

        const pushObject = {
          providerName
        }

        const pivotedItems = itemsForCurrentStatistic.reduce((returnItem, item) => {
          returnItem[item.dateTime] = item
          return returnItem
        }, {})

        statisticDates.forEach(function (statisticDate, index) {
          if (!pivotedItems[statisticDate]) { return }
          // If it's a daily figure required, just add the period delta
          if (periodLength === PERIOD_OPTIONS.DAILY) {
            pushObject[`deltaPeriod${index + 1}`] = pivotedItems[statisticDate].periodDelta
          }
          // If it's a longer period, we need to calculate the delta using ATT from this and the last entry
          if (periodLength !== PERIOD_OPTIONS.DAILY) {
            // If this is the first date, then use the earlier period data
            if (index === 0) {
              const lastATT = itemsForEarlierDate[0].allTimeTotal || 0
              pushObject[`deltaPeriod${index + 1}`] = pivotedItems[statisticDate].allTimeTotal - lastATT
            }
            // If this is not the first date, then use the previous period data
            if (index !== 0) {
              var lastATT = pushObject[`attPeriod${index}`] || 0
              pushObject[`deltaPeriod${index + 1}`] = pivotedItems[statisticDate].allTimeTotal - lastATT
            }
          }
          // Add the ATT to the push object
          pushObject[`attPeriod${index + 1}`] = pivotedItems[statisticDate].allTimeTotal
        })

        returnArray.push(pushObject)
      })

      return {
        data: returnArray,
        periods: statisticDates
      }
    } catch (err) {
      console.warn("Error getting system statistics for certificates issued per provider", err)
      return {
        data: [],
        periods: []
      }
    }
  }




  async getCertificateCountsByProvider(startDateTime, endDateTime, periodLength) {
    // Check that a startDateTime was provided that can be used
    const startDateTimeObject = new Date(startDateTime)
    if (!(startDateTimeObject instanceof Date) || isNaN(startDateTimeObject)) {
      console.warn("Invalid startDateTime provided to getCertificateCountsByProvider")
      return {
        data: [],
        periods: []
      }
    }
    // Check that a endDateTime was provided that can be used
    const endDateTimeObject = new Date(endDateTime)
    if (!(endDateTimeObject instanceof Date) || isNaN(endDateTimeObject)) {
      console.warn("Invalid endDateTime provided to getCertificateCountsByProvider")
      return {
        data: [],
        periods: []
      }
    }
    // Check that a period was provided that can be used
    if (!PERIOD_OPTIONS[periodLength]) {
      console.warn("No period provided to getCertificateCountsByProvider")
      return {
        data: [],
        periods: []
      }
    }

    // Get all the dates to fetch data for
    const statisticDates = this.getListOfStatisticDatesToFetch(startDateTime, endDateTime, periodLength)
    const earlierStatisticDate = this.getStatisticDateForPeriodBeforeFirstPeriodRequested(startDateTime, periodLength)

    try {
      // For each date to fetch, fetch it concurrently
      const results = await Promise.all(statisticDates.map((date) => {
        const parameters = {
          dateTime: date,
          countryCodeStatisticName: {
            beginsWith: {
              countryCode: "GB",
              statisticName: "Provider_Certificates_Issued_"
            }
          },
          countryCode: "GB"
        }
        //console.log("parameters:", parameters)
        return API.graphql(graphqlOperation(QueriesCustom.getStatisticsByDateAndCountry, parameters));
      }))
      //console.log("results:", results)

      // Fetch the data for the period before the first period as this will be needed
      // for the first column of a non-daily period
      var earlierDateItems = []
      try {
        const parameters = {
          dateTime: earlierStatisticDate,
          countryCodeStatisticName: {
            beginsWith: {
              countryCode: "GB",
              statisticName: "Provider_Certificates_Issued_"
            }
          },
          countryCode: "GB"
        }
        //console.log("parameters:", parameters)
        const tempResult = await API.graphql(graphqlOperation(QueriesCustom.getStatisticsByDateAndCountry, parameters));
        earlierDateItems = tempResult.data.getStatisticsByDateAndCountry.items
      } catch (err) {
        console.warn("Unable to get earlier date items:", err)
      }
      //console.log("earlierDateItems:", earlierDateItems)

      // Initialise an array to hold all the returned items
      var allItems = []

      // Extract all the items from the returned data
      results.forEach(function (fetchResult) {
        try {
          allItems = allItems.concat(fetchResult.data.getStatisticsByDateAndCountry.items)
        } catch (err) {
          console.warn("unable to extract data for entry in returned data;", fetchResult)
        }
      })

      //console.log("allItems:", allItems)

      // Initialise an object to hold all the returned items
      var returnArray = []

      // Collect all the unique statistic names
      const statisticNames = [ ...new Set(allItems.map(item => item.statisticName))]

      // For each item, add its data to the return array
      statisticNames.forEach(function (statisticName) {

        const itemsForCurrentStatistic = allItems.filter((item) => {
          return item.statisticName === statisticName
        })

        const itemsForEarlierDate = earlierDateItems.filter((item) => {
          return item.statisticName === statisticName
        })

        // If there is nothing to add, return
        if (itemsForCurrentStatistic.length === 0) {
          return
        }

        const additionalData = JSON.parse(itemsForCurrentStatistic[0].additionalData)
        const providerName = additionalData.providerName

        const pushObject = {
          providerName
        }

        const pivotedItems = itemsForCurrentStatistic.reduce((returnItem, item) => {
          returnItem[item.dateTime] = item
          return returnItem
        }, {})

        statisticDates.forEach(function (statisticDate, index) {
          if (!pivotedItems[statisticDate]) { return }
          // If it's a daily figure required, just add the period delta
          if (periodLength === PERIOD_OPTIONS.DAILY) {
            pushObject[`deltaPeriod${index + 1}`] = pivotedItems[statisticDate].periodDelta
          }
          // If it's a longer period, we need to calculate the delta using ATT from this and the last entry
          if (periodLength !== PERIOD_OPTIONS.DAILY) {
            // If this is the first date, then use the earlier period data
            if (index === 0) {
              const lastATT = itemsForEarlierDate[0].allTimeTotal || 0
              pushObject[`deltaPeriod${index + 1}`] = pivotedItems[statisticDate].allTimeTotal - lastATT
            }
            // If this is not the first date, then use the previous period data
            if (index !== 0) {
              var lastATT = pushObject[`attPeriod${index}`] || 0
              pushObject[`deltaPeriod${index + 1}`] = pivotedItems[statisticDate].allTimeTotal - lastATT
            }
          }
          // Add the ATT to the push object
          pushObject[`attPeriod${index + 1}`] = pivotedItems[statisticDate].allTimeTotal
        })

        returnArray.push(pushObject)
      })

      return {
        data: returnArray,
        periods: statisticDates
      }
    } catch (err) {
      console.warn("Error getting system statistics for certificates issued per provider", err)
      return {
        data: [],
        periods: []
      }
    }
  }




  async getUserStatus(startDateTime, endDateTime, periodLength) {
    // Check that a startDateTime was provided that can be used
    const startDateTimeObject = new Date(startDateTime)
    if (!(startDateTimeObject instanceof Date) || isNaN(startDateTimeObject)) {
      console.warn("Invalid startDateTime provided to getUserStatus")
      return {
        data: [],
        periods: []
      }
    }
    // Check that a endDateTime was provided that can be used
    const endDateTimeObject = new Date(endDateTime)
    if (!(endDateTimeObject instanceof Date) || isNaN(endDateTimeObject)) {
      console.warn("Invalid endDateTime provided to getUserStatus")
      return {
        data: [],
        periods: []
      }
    }
    // Check that a period was provided that can be used
    if (!PERIOD_OPTIONS[periodLength]) {
      console.warn("No period provided to getUserStatus")
      return {
        data: [],
        periods: []
      }
    }

    // Get all the dates to fetch data for
    const statisticDates = this.getListOfStatisticDatesToFetch(startDateTime, endDateTime, periodLength)
    const earlierStatisticDate = this.getStatisticDateForPeriodBeforeFirstPeriodRequested(startDateTime, periodLength)

    try {
      // For each date to fetch, fetch it concurrently
      const results = await Promise.all(statisticDates.map((date) => {
        const parameters = {
          dateTime: date,
          countryCodeStatisticName: {
            beginsWith: {
              countryCode: "GB",
              statisticName: "Cognito_"
            }
          },
          countryCode: "GB"
        }
        //console.log("parameters:", parameters)
        return API.graphql(graphqlOperation(QueriesCustom.getStatisticsByDateAndCountry, parameters));
      }))
      //console.log("results:", results)

      // Fetch the data for the period before the first period as this will be needed
      // for the first column of a non-daily period
      var earlierDateItems = []
      try {
        const parameters = {
          dateTime: earlierStatisticDate,
          countryCodeStatisticName: {
            beginsWith: {
              countryCode: "GB",
              statisticName: "Cognito_"
            }
          },
          countryCode: "GB"
        }
        //console.log("parameters:", parameters)
        const tempResult = await API.graphql(graphqlOperation(QueriesCustom.getStatisticsByDateAndCountry, parameters));
        earlierDateItems = tempResult.data.getStatisticsByDateAndCountry.items
      } catch (err) {
        console.warn("Unable to get earlier date items:", err)
      }
      //console.log("earlierDateItems:", earlierDateItems)

      // Initialise an array to hold all the returned items
      var allItems = []

      // Extract all the items from the returned data
      results.forEach(function (fetchResult) {
        try {
          allItems = allItems.concat(fetchResult.data.getStatisticsByDateAndCountry.items)
        } catch (err) {
          console.warn("unable to extract data for entry in returned data;", fetchResult)
        }
      })

      //console.log("allItems:", allItems)

      // Initialise an object to hold all the returned items
      var returnArray = []

      // Collect all the unique statistic names
      //const statisticNames = [ ...new Set(allItems.map(item => item.statisticName))]

      // For each item, add its data to the return array
      STATISTICS_COGNITO.forEach(function (statisticName) {

        const itemsForCurrentStatistic = allItems.filter((item) => {
          return item.statisticName === statisticName
        })

        const itemsForEarlierDate = earlierDateItems.filter((item) => {
          return item.statisticName === statisticName
        })

        // If there is nothing to add, return
        if (itemsForCurrentStatistic.length === 0) {
          return
        }

        var accountStatus = statisticName.replace('Cognito_', '')
        accountStatus = accountStatus.replace('_Users', '')

        const pushObject = {
          accountStatus
        }

        const pivotedItems = itemsForCurrentStatistic.reduce((returnItem, item) => {
          returnItem[item.dateTime] = item
          return returnItem
        }, {})

        statisticDates.forEach(function (statisticDate, index) {
          if (!pivotedItems[statisticDate]) { return }
          // If it's a daily figure required, just add the period delta
          if (periodLength === PERIOD_OPTIONS.DAILY) {
            pushObject[`deltaPeriod${index + 1}`] = pivotedItems[statisticDate].periodDelta
          }
          // If it's a longer period, we need to calculate the delta using ATT from this and the last entry
          if (periodLength !== PERIOD_OPTIONS.DAILY) {
            // If this is the first date, then use the earlier period data
            if (index === 0) {
              const lastATT = itemsForEarlierDate[0].allTimeTotal || 0
              pushObject[`deltaPeriod${index + 1}`] = pivotedItems[statisticDate].allTimeTotal - lastATT
            }
            // If this is not the first date, then use the previous period data
            if (index !== 0) {
              var lastATT = pushObject[`attPeriod${index}`] || 0
              pushObject[`deltaPeriod${index + 1}`] = pivotedItems[statisticDate].allTimeTotal - lastATT
            }
          }
          // Add the ATT to the push object
          pushObject[`attPeriod${index + 1}`] = pivotedItems[statisticDate].allTimeTotal
        })

        returnArray.push(pushObject)
      })

      return {
        data: returnArray,
        periods: statisticDates
      }
    } catch (err) {
      console.warn("Error getting system statistics for user status", err)
      return {
        data: [],
        periods: []
      }
    }
  }




  async getUserCertificateCounts(startDateTime, endDateTime, periodLength) {
    // Check that a startDateTime was provided that can be used
    const startDateTimeObject = new Date(startDateTime)
    if (!(startDateTimeObject instanceof Date) || isNaN(startDateTimeObject)) {
      console.warn("Invalid startDateTime provided to getUserCertificateCounts")
      return {
        data: [],
        periods: []
      }
    }
    // Check that a endDateTime was provided that can be used
    const endDateTimeObject = new Date(endDateTime)
    if (!(endDateTimeObject instanceof Date) || isNaN(endDateTimeObject)) {
      console.warn("Invalid endDateTime provided to getUserCertificateCounts")
      return {
        data: [],
        periods: []
      }
    }
    // Check that a period was provided that can be used
    if (!PERIOD_OPTIONS[periodLength]) {
      console.warn("No period provided to getUserCertificateCounts")
      return {
        data: [],
        periods: []
      }
    }

    // Get all the dates to fetch data for
    const statisticDates = this.getListOfStatisticDatesToFetch(startDateTime, endDateTime, periodLength)
    const earlierStatisticDate = this.getStatisticDateForPeriodBeforeFirstPeriodRequested(startDateTime, periodLength)

    try {
      // For each date to fetch, fetch it concurrently
      const results = await Promise.all(statisticDates.map((date) => {
        const parameters = {
          dateTime: date,
          countryCodeStatisticName: {
            beginsWith: {
              countryCode: "GB",
              statisticName: "Certificates_Issued_By_User_"
            }
          },
          countryCode: "GB"
        }
        //console.log("parameters:", parameters)
        return API.graphql(graphqlOperation(QueriesCustom.getStatisticsByDateAndCountry, parameters));
      }))
      //console.log("results:", results)

      // Fetch the data for the period before the first period as this will be needed
      // for the first column of a non-daily period
      var earlierDateItems = []
      try {
        const parameters = {
          dateTime: earlierStatisticDate,
          countryCodeStatisticName: {
            beginsWith: {
              countryCode: "GB",
              statisticName: "Certificates_Issued_By_User_"
            }
          },
          countryCode: "GB"
        }
        //console.log("parameters:", parameters)
        const tempResult = await API.graphql(graphqlOperation(QueriesCustom.getStatisticsByDateAndCountry, parameters));
        earlierDateItems = tempResult.data.getStatisticsByDateAndCountry.items
      } catch (err) {
        console.warn("Unable to get earlier date items:", err)
      }
      //console.log("earlierDateItems:", earlierDateItems)

      // Initialise an array to hold all the returned items
      var allItems = []

      // Extract all the items from the returned data
      results.forEach(function (fetchResult) {
        try {
          allItems = allItems.concat(fetchResult.data.getStatisticsByDateAndCountry.items)
        } catch (err) {
          console.warn("unable to extract data for entry in returned data;", fetchResult)
        }
      })

      // Initialise an object to hold all the returned items
      var returnArray = []

      // Collect all the unique statistic names
      //CERTIFICATES_BY_USER_STATISTIC_NAMES

      // For each item, add its data to the return array
      CERTIFICATES_BY_USER_STATISTIC_NAMES.forEach(function (statisticName) {

        const itemsForCurrentStatistic = allItems.filter((item) => {
          return item.statisticName === statisticName
        })

        const itemsForEarlierDate = earlierDateItems.filter((item) => {
          return item.statisticName === statisticName
        })

        //console.log("itemsForCurrentStatistic:", itemsForCurrentStatistic)

        var certificateRange = statisticName.replace('Certificates_Issued_By_User_Not_Confirmed_', "")
        certificateRange = certificateRange.replace('Certificates_Issued_By_User_Confirmed_', "")
        certificateRange = certificateRange.replace('_Certificates', "")
        certificateRange = certificateRange.replace(/_/g, " ")

        const pushObject = {
          accountStatus: `${statisticName.includes('Not_Confirmed') ? "Not Confirmed" : "Confirmed"}`,
          certificateRange,
        }

        const pivotedItems = itemsForCurrentStatistic.reduce((returnItem, item) => {
          returnItem[item.dateTime] = item
          return returnItem
        }, {})

        statisticDates.forEach(function (statisticDate, index) {
          if (!pivotedItems[statisticDate]) { return }
          // If it's a daily figure required, just add the period delta
          if (periodLength === PERIOD_OPTIONS.DAILY) {
            pushObject[`deltaPeriod${index + 1}`] = pivotedItems[statisticDate].periodDelta
          }
          // If it's a longer period, we need to calculate the delta using ATT from this and the last entry
          if (periodLength !== PERIOD_OPTIONS.DAILY) {
            // If this is the first date, then use the earlier period data
            if (index === 0) {
              const lastATT = itemsForEarlierDate[0].allTimeTotal || 0
              pushObject[`deltaPeriod${index + 1}`] = pivotedItems[statisticDate].allTimeTotal - lastATT
            }
            // If this is not the first date, then use the previous period data
            if (index !== 0) {
              var lastATT = pushObject[`attPeriod${index}`] || 0
              pushObject[`deltaPeriod${index + 1}`] = pivotedItems[statisticDate].allTimeTotal - lastATT
            }
          }
          // Add the ATT to the push object
          pushObject[`attPeriod${index + 1}`] = pivotedItems[statisticDate].allTimeTotal
        })

        returnArray.push(pushObject)
      })

      return {
        data: returnArray,
        periods: statisticDates
      }
    } catch (err) {
      console.warn("Error getting system statistics for certificate counts per user", err)
      return {
        data: [],
        periods: []
      }
    }
  }

  getListOfStatisticDatesToFetch(startDateTime, endDateTime, periodLength) {

    const startDateObject = new Date(startDateTime)
    startDateObject.setUTCHours(0)
    startDateObject.setUTCMinutes(0)
    startDateObject.setUTCSeconds(0)
    startDateObject.setUTCMilliseconds(0)

    const endDateObject = new Date(endDateTime)
    endDateObject.setUTCHours(23)
    endDateObject.setUTCMinutes(59)
    endDateObject.setUTCSeconds(59)
    endDateObject.setUTCMilliseconds(999)

    var datesToReturn = []
    while (startDateObject.toISOString() <= endDateObject.toISOString()) {
      datesToReturn.push(`${startDateObject.toISOString()}`)
      if (periodLength === PERIOD_OPTIONS.DAILY) {
        startDateObject.setUTCDate(startDateObject.getUTCDate() + 1);
      }
      if (periodLength === PERIOD_OPTIONS.WEEKLY) {
        startDateObject.setUTCDate(startDateObject.getUTCDate() + 7);
      }
      if (periodLength === PERIOD_OPTIONS.MONTHLY) {
        startDateObject.setUTCMonth(startDateObject.getUTCMonth() + 1);
      }
      if (periodLength === PERIOD_OPTIONS.YEARLY) {
        startDateObject.setUTCFullYear(startDateObject.getUTCFullYear() + 1);
      }
    }
    //console.log("datesToReturn:", datesToReturn)

    return datesToReturn
  }

  getStatisticDateForPeriodBeforeFirstPeriodRequested(firstPeriodDateTime, periodLength) {
    const firstPeriodDateObject = new Date(firstPeriodDateTime)

    if (periodLength === PERIOD_OPTIONS.DAILY) {
      firstPeriodDateObject.setUTCDate(firstPeriodDateObject.getUTCDate() - 1)
      return firstPeriodDateObject.toISOString()
    }
    if (periodLength === PERIOD_OPTIONS.WEEKLY) {
      firstPeriodDateObject.setUTCDate(firstPeriodDateObject.getUTCDate() - 7)
      return firstPeriodDateObject.toISOString()
    }
    if (periodLength === PERIOD_OPTIONS.MONTHLY) {
      firstPeriodDateObject.setUTCMonth(firstPeriodDateObject.getUTCMonth() - 1)
      return firstPeriodDateObject.toISOString()
    }
    if (periodLength === PERIOD_OPTIONS.YEARLY) {
      firstPeriodDateObject.setUTCFullYear(firstPeriodDateObject.getUTCFullYear() - 1)
      return firstPeriodDateObject.toISOString()
    }
  }

}

export default StatisticsService

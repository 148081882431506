<template>
  <v-container pa-0 ma-0 fluid>
    <loading :active="!is_data_fetched" :is-full-page="true" loader="dots"></loading>
    <v-layout row wrap ma-0 justify-center align-content-center v-if="is_data_fetched">
      <v-flex xs12>
        <v-container fluid grid-list-md>
          <v-layout row>
            <v-flex xs12 md3 offset-md9 class="grid-actions">
              <div class="text-right">
                <router-link :to="{ name: `${ $store.getters.layout === LAYOUTS.ADMIN ? 'AdminCreateNewEvent' : 'ProviderCreateNewEvent' }` }" class="grid-actions-link" v-if="pageDisplayType === PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.EVENTS && userIsAdmin">
                  <v-icon color="rgb(0,171,173)" style="vertical-align:middle">add_circle</v-icon> &nbsp;Create New Event
                </router-link>
                <a>
                  <img :src="require('../images/export.png')" @click="generatePdf" class="grid-actions-icon" />
                </a>
                <!-- Printer option removed
                <a>
                  <img :src="require('../images/printer.png')" @click="print" class="grid-actions-icon" />
                </a>
                -->
              </div>
            </v-flex>
          </v-layout>
          <v-layout column pa-2 ma-0 class="grid-container">
            <v-flex md1>
              <v-layout>
                <v-flex xs12 md2 pt-2 pl-2>
                  <h5 class="page-header" v-if="pageDisplayType === PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.EVENTS">Events</h5>
                  <h5 class="page-header" v-if="pageDisplayType === PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.RECORDS">Records</h5>
                </v-flex>
                <v-flex xs12 md3 offset-md7 class="grid-search">
                  <v-text-field v-model="searchText"
                                append-icon="search"
                                label="Search for a course"
                                single-line
                                hide-details></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex md11>
              <v-data-table :headers="headers"
                            :items="GetFilteredEvents()"
                            :search="searchText"
                            hide-actions
                            :pagination.sync="pagination"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :custom-sort="CustomSort"
                            disable-initial-sort>
                <template slot="headerCell" slot-scope="{ header }">
                  <span class="table-head font-weight-bold text--darken-3" v-text="header.text" />
                </template>
                <template v-slot:items="props">
                  <td><span>{{ props.item.title }}</span></td>
                  <td>{{ getDateFromDateTime(props.item.startDateTime) }}</td>
                  <td>{{ getTimeFromDateTime(props.item.startDateTime) }}</td>
                  <td>{{ getDurationFromEvent(props.item) }}</td>
                  <td>{{ getLocationFromEvent(props.item )}}</td>

                  <td>{{ getLocaleStringForStatus(props.item) }}</td>

                  <td v-if="pageDisplayType === PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.EVENTS && props.item.eventType === EVENT_TYPE.CONFERENCE">
                    0/{{ props.item.maximumAttendees }} (0)
                  </td>
                  <td v-if="pageDisplayType === PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.RECORDS && props.item.eventType === EVENT_TYPE.CONFERENCE">
                    {{ props.item.attendeeStatistics.attended }}/{{ props.item.maximumAttendees }} ({{ props.item.attendeeStatistics.onWaitingList }})
                  </td>

                  <td v-if="pageDisplayType === PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.EVENTS && props.item.eventType !== EVENT_TYPE.CONFERENCE">
                    {{ props.item.attendeeStatistics.booked }}/{{ props.item.maximumAttendees }} ({{ props.item.attendeeStatistics.onWaitingList }})
                  </td>
                  <td v-if="pageDisplayType === PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.RECORDS && props.item.eventType !== EVENT_TYPE.CONFERENCE">
                    {{ props.item.attendeeStatistics.attended }}/{{ props.item.maximumAttendees }} ({{ props.item.attendeeStatistics.onWaitingList }})
                  </td>

                  <td style="display: flex; align-items: center; justify-content: flex-end;">

                    <v-btn ma-0 pa-0 style="width: 80%; min-width: min-content; text-transform:none;margin-left:0px; margin-right:0px;" color="rgb(254,138,125)" small v-if="ButtonActive(props.item) === EVENT_POSSIBLE_ACTIONS.EDITCONTENT" @click="ShowEditEventDialog(props.item)">
                      <span style="font-size:12px;">Edit Event</span>
                    </v-btn>

                    <v-btn ma-0 pa-0 style="width: 80%; min-width: min-content; text-transform:none;margin-left:0px; margin-right:0px;" color="rgb(254,138,125)" small v-if="ButtonActive(props.item) === EVENT_POSSIBLE_ACTIONS.DELETEEVENT" @click="OnDeleteEventClick(props.item.id)">
                      <span style="font-size:12px;">Delete Event</span>
                    </v-btn>

                    <v-btn ma-0 pa-0 style="width: 80%; min-width: min-content; text-transform:none;margin-left:0px; margin-right:0px;" color="rgb(254,138,125)" small v-if="ButtonActive(props.item) === EVENT_POSSIBLE_ACTIONS.MANAGEATTENDEES" @click="GoToManageAttendees(props.item.id)">
                      <span style="font-size:12px;">Manage Attendees</span>
                    </v-btn>

                    <v-btn ma-0 pa-0 style="width: 80%; min-width: min-content; text-transform:none;margin-left:0px; margin-right:0px;" color="rgb(254,138,125)" small v-if="ButtonActive(props.item) === EVENT_POSSIBLE_ACTIONS.ISSUECERTIFICATES" @click="GoToIssueCertificates(props.item.id)">
                      <span style="font-size:12px;">Issue Certificates</span>
                    </v-btn>

                    <v-btn ma-0 pa-0 style="width: 80%; min-width: min-content; text-transform:none;margin-left:0px; margin-right:0px;" color="rgb(0,171,173)" small v-if="ButtonActive(props.item) === EVENT_POSSIBLE_ACTIONS.VIEWCERTIFICATES" @click="GoToViewCertificates(props.item.id)">
                      <span style="font-size:12px;">View Certificates</span>
                    </v-btn>

                    <v-btn ma-0 pa-0 style="width: 80%; min-width: min-content; text-transform:none;margin-left:0px; margin-right:0px;" color="rgb(0,171,173)" small v-if="ButtonActive(props.item) === EVENT_POSSIBLE_ACTIONS.COPYEVENT" @click="CopyEvent(props.item)">
                      <span style="font-size:12px;">Copy Event</span>
                    </v-btn>

                    <!--
                    <a href="#" style="color:rgb(254,138,125);" v-if="props.item.status === EVENT_STATUS.CERTIFICATESSENT && eventHasAction(props.item, EVENT_POSSIBLE_ACTIONS.VIEWCERTIFICATES)" @click="GoToViewCertificates(props.item.id)">
                      <span style="font-size:12px;">View Certificates</span>
                    </a>
                    <a href="#" style="color:rgb(254,138,125);" v-if="props.item.status === EVENT_STATUS.LIVE && eventHasAction(props.item, EVENT_POSSIBLE_ACTIONS.MANAGEATTENDEES)" @click="GoToManageAttendees(props.item.id)">
                      <span style="font-size:12px;">Manage Attendees</span>
                    </a>
                    -->
                    <span style="color:rgb(254,138,125);" v-if="props.item.status == 'Draft'">Edit</span>
                    <v-menu bottom right style="float:right">
                      <v-btn slot="activator" icon>
                        <v-icon color="rgb(177,177,177)">more_vert</v-icon>
                      </v-btn>

                      <v-card>
                        <v-card-text style='display:flex; flex-direction: column;'>
                          <a href="#" style="font-weight:normal;font-size:12px;color:rgb(0,171,173); margin-bottom: 3px;" @click="ShowEditEventDialog(props.item)">
                            {{ eventInPast(props.item) || !userIsAdmin ? "View Event" : "Edit/View Event" }}
                          </a>
                          <a href="#" v-if="userIsAdmin" style="font-weight:normal;font-size:12px;color:rgb(0,171,173); margin-bottom: 3px;" @click="CopyEvent(props.item)">
                            Copy Event
                          </a>
                          <a href="#" v-if="userIsAdmin && props.item.status === EVENT_STATUS.DRAFT" style="font-weight:normal;font-size:12px;color:rgb(0,171,173); margin-bottom: 3px;" @click="OnDeleteEventClick(props.item.id)">
                            Delete Event
                          </a>
                          <a href="#" v-if="![EVENT_STATUS.DRAFT, EVENT_STATUS.DELETED].includes(props.item.status)" style="font-weight:normal;font-size:12px;color:rgb(0,171,173); margin-bottom: 3px;" @click="OnDownloadAttendeeListClick(props.item)">
                            Download Attendee List
                          </a>
                          <!-- Allow additional certificates to be issued for an event after completion -->
                          <a href="#" v-if="userIsAdmin && canIssueCertificatesAfterFirstIssueOfCertificates(props.item)" style="font-weight:normal;font-size:12px;color:rgb(0,171,173); margin-bottom: 3px;" @click="GoToIssueCertificates(props.item.id)">
                            Issue Certificates
                          </a>


                          <div v-if="props.item.status === EVENT_STATUS.LIVE && pageDisplayType === PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.EVENTS">
                            <div style="font-weight:normal;font-size:12px;color:rgb(0,171,173); margin-bottom: 3px;">Share via</div>

                              <ShareNetwork
                                network="facebook"
                                :url="GetSharingURL(props.item)"
                                :title="props.item.title"
                                :description="props.item.description"
                                :quote="`${props.item.title}\r\n${sanitiseEventDescription(props.item.description)}`"
                                hashtags="MyProPass,Professionals,CPD">
                                <img src="@/images/facebook32b.png" max-height="50px" max-width="75px" contain style="margin-left:4px;" />
                              </ShareNetwork>

                              <ShareNetwork
                                network="twitter"
                                :url="GetSharingURL(props.item)"
                                :title="`Check out this course on MyProPass:\r\n${props.item.title}`"
                                :description="sanitiseEventDescription(props.item.description)"
                                hashtags="MyProPass,Professionals,CPD">
                                <img src="@/images/twitter32b.png" max-height="50px" max-width="75px" contain style="margin-left:4px;" />
                              </ShareNetwork>

                              <a :href="`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(GetSharingURL(props.item))}`" target="_blank">
                                <img src="@/images/linkedin32b.png" max-height="50px" max-width="75px" contain style="margin-left:4px;" />
                              </a>

                              <a :href="GetEmailURI(props.item)" target="_blank">
                                <img src="@/images/email.png" max-height="50px" max-width="75px" contain style="margin-left:4px;" />
                              </a>

                          </div>
                        </v-card-text>
                      </v-card>

                    </v-menu>
                  </td>
                </template>
              </v-data-table>
              <v-dialog v-model="editEventdialog.show" persistent transition="dialog-transition" max-width="90vw">
                <v-container fluid pa-0 style="background: #fff">
                  <v-layout ma-0>
                    <v-flex xs12 class="position-relative">
                      <provider-eventstepper v-if="editEventdialog.show" :event="editEventdialog.event" :stepperState="editEventdialog.state" :onClose="CloseEventStepper"></provider-eventstepper>
                      <v-btn small color=rgb(0,171,173) @click="CloseEventStepper(false)" style="margin: 10px 30px;">Close</v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-dialog>
              <v-dialog persistent v-model="deleteEventDialog.show" transition="dialog-transition" max-width="450">
                <v-card>
                  <v-card-text>
                    <v-flex>
                      <span style="font-weight:normal;">
                        Are you sure you want to delete event?
                      </span>
                    </v-flex>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="CloseDeleteEventDialog" small color=rgb(0,171,173)>Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="DeleteEvent" small color=rgb(0,171,173)>Ok</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-layout>
                <v-flex xs12 sm1 style="margin-top:9px;">
                  <label class="col-sm-3 control-label" for="status">Status:</label>
                </v-flex>
                <v-flex xs12 sm3 text-left style="margin-top:6px;">

                  <select v-if="pageDisplayType === PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.EVENTS" class="form-control" id="selectedStatus" v-bind:value="selectedStatus" @change="onFilterSelectionChange($event)" style="height: auto;padding:3px 10px !important">
                    <option :value="PROVIDER_EVENTS_PAGE_FILTER_STATUSES.ALL">All</option>
                    <option :value="PROVIDER_EVENTS_PAGE_FILTER_STATUSES.DRAFT">Draft</option>
                    <option :value="PROVIDER_EVENTS_PAGE_FILTER_STATUSES.LIVE">Scheduled</option>
                    <option :value="PROVIDER_EVENTS_PAGE_FILTER_STATUSES.CANCELLED">Cancelled</option>
                    <option :value="PROVIDER_EVENTS_PAGE_FILTER_STATUSES.DELETED">Deleted</option>
                  </select>

                  <select v-if="pageDisplayType === PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.RECORDS" class="form-control" id="selectedStatus" v-bind:value="selectedStatus" @change="onFilterSelectionChange($event)" style="height: auto;padding:3px 10px !important">
                    <option :value="PROVIDER_EVENTS_PAGE_FILTER_STATUSES.ALL">All</option>
                    <option :value="PROVIDER_EVENTS_PAGE_FILTER_STATUSES.DRAFT">Draft</option>
                    <option :value="PROVIDER_EVENTS_PAGE_FILTER_STATUSES.COMPLETED">Completed</option>
                    <option :value="PROVIDER_EVENTS_PAGE_FILTER_STATUSES.CANCELLED">Cancelled</option>
                    <option :value="PROVIDER_EVENTS_PAGE_FILTER_STATUSES.CERTIFICATESSENT">Certificates Sent</option>
                    <option :value="PROVIDER_EVENTS_PAGE_FILTER_STATUSES.DELETED">Deleted</option>
                  </select>
                </v-flex>
                <v-flex xs12 text-right>
                  <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import ProviderEventStepper from './provider-eventstepper'
  import EventService from '../services/event.service'
  import jsPDF from 'jspdf'
  import lodash from 'lodash'

  import { GetTimeFromDateTime, GetLocaleDateFromDateTime, DateInThePast } from '../helpers/date.helper'
  import { EventDurationInMinutes } from '../helpers/event.helper'
  import { FormatDuration, DESIRED_DURATIONS } from '../helpers/duration.helper'

  import { LAYOUTS } from '@/constants/LayoutConstants'
  import { EVENT_MODE, EVENT_STATUS, EVENT_POSSIBLE_ACTIONS, EVENT_STEPPER_STATE, EVENT_TYPE } from '@/constants/EventConstants'
  import { BOOKING_STATUS, BOOKING_ATTENDANCE } from '@/constants/BookingConstants'
  import { PROVIDER_ALL_EVENTS_PAGE_PARAMETERS, PROVIDER_EVENTS_PAGE_FILTER_STATUSES } from '@/constants/PageParameterConstants'

  import { GetLocaleString } from '../helpers/strings.helper'

  // TODO: FUTURE: Replace all 'tab numbers with constants'

  export default {

    components: {
      'provider-eventstepper': ProviderEventStepper
    },

    data() {
      return {
        is_data_fetched: false,
        editEventdialog: {
          show: false,
          event: {},
          editEventHeaders: [],
          editEventGridData: [],
          state: EVENT_STEPPER_STATE.UPDATE
        },
        deleteEventDialog: {
          show: false,
          eventId: 0
        },
        searchText: '',
        sortBy: 'eventDate',
        sortDesc: true,
        selectedStatus: PROVIDER_EVENTS_PAGE_FILTER_STATUSES.ALL,
        pagination: {
          rowsPerPage: 10,
          totalItems: 0,
          sortBy: 'eventDate',
          page: 1,
        },
        headers: [
          { text: 'Event',      align: 'left',    value: 'title',     },
          { text: 'Date',       align: 'center',  value: 'eventDate'  },
          { text: 'Time',       align: 'center',  value: 'startTime'  },
          { text: 'Duration',   align: 'center',  value: 'duration'   },
          { text: 'Location',   align: 'center',  value: 'location'   },
          { text: 'Status',     align: 'center',  value: 'status'     },
          { text: 'Attendees',  align: 'center',  value: 'attendees'  },
          { text: 'Action',     align: 'center',  value: 'action'     }
        ],
        PROVIDER_EVENTS_PAGE_FILTER_STATUSES,
        PROVIDER_ALL_EVENTS_PAGE_PARAMETERS,
        EVENT_POSSIBLE_ACTIONS,
        EVENT_STATUS,
        EVENT_MODE,
        EVENT_TYPE,
        EVENT_STEPPER_STATE,
        LAYOUTS,
      }
    },

    watch: {
      GetFilteredEvents: function(newValue) {
        //console.log("WATCHER: GetFilteredEvents value updated")
        this.pagination.totalItems = newValue.length
      },
      pagination: function() {
        //console.log("WATCHER: pagination value updated", newValue)
      },
      'pagination.page': function(newValue) {
        //console.log("WATCHER: pagination page value updated", newValue)
        // Check if the user is getting close to the end of the fetched items
        if (newValue + 1 >= Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)) {
          // For future events
          if (this.pageDisplayType === PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.EVENTS) {
            new EventService(this.$store).ExtendFutureEventsForEmployee()
          }
          // For past events
          if (this.pageDisplayType === PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.RECORDS) {
            new EventService(this.$store).ExtendPastEventsForEmployee()
          }
        }
      },
    },

    methods: {
      CustomSort(items, index, isDesc) {
        //console.log("items:", items)
        //console.log("index:", index)
        //console.log("isDesc:", isDesc)
        try {
          const result = items.sort((a, b) => {
            this.sortBy = index;
            this.sortDesc = isDesc;

            // Title Sort
            if (index === "title" || index === "status") {
              if (!isDesc) { return a[index] < b[index] ? -1 : 1; }
              return b[index] < a[index] ? -1 : 1;
            }

            // Event Date
            if (index === "eventDate") {
              if (!isDesc) { return new Date(a.startDateTime) < new Date(b.startDateTime) ? -1 : 1; }
              return new Date(b.startDateTime) < new Date(a.startDateTime) ? -1 : 1;
            }

            // Start Time Sort
            if (index === "startTime") {
              const aTimeOnly = a.startDateTime.split('T')[1]
              const bTimeOnly = b.startDateTime.split('T')[1]
              if (!isDesc) { return aTimeOnly < bTimeOnly ? -1 : 1 }
              return aTimeOnly > bTimeOnly ? -1 : 1
            }

            // Duration Sort
            if (index === "duration") {
              const aDuration = this.getDurationFromEvent(a, true)
              const bDuration = this.getDurationFromEvent(b, true)
              if (!isDesc) { return aDuration < bDuration ? -1 : 1 }
              return aDuration > bDuration ? -1 : 1
            }

            // Location Sort
            if (index === "location") {
              const aLocation = this.getLocationFromEvent(a)
              const bLocation = this.getLocationFromEvent(b)
              if (!isDesc) { return aLocation < bLocation ? -1 : 1 }
              return aLocation > bLocation ? -1 : 1
            }

            // Attendees Sort
            if (index === "attendees") {
              const aLocation = this.getEventBookedSeats(a)
              const bLocation = this.getEventBookedSeats(b)
              if (!isDesc) { return aLocation < bLocation ? -1 : 1 }
              return aLocation > bLocation ? -1 : 1
            }

            // Action Sort
            if (index === "action") {
              const aButton = this.ButtonActive(a)
              const bButton = this.ButtonActive(b)
              if (!isDesc) { return aButton < bButton ? -1 : 1 }
              return aButton > bButton ? -1 : 1
            }

          });
          return result;
        } catch (e) {
          console.warn("Error when custom sorting", e)
          return items
        }
      },

      onFilterSelectionChange(event) {
        console.log("Changing filter to:", event.target.value)
        this.selectedStatus = event.target.value
        //this.$route.query.selectedStatus = event.target.value
      },

      ButtonActive(event) {
        const eventIsLive = event.status === EVENT_STATUS.LIVE
        const eventNotConference = event.eventType !== EVENT_TYPE.CONFERENCE

        // Edit
        if (event.status === EVENT_STATUS.DRAFT && !this.eventInPast(event)) {
          return EVENT_POSSIBLE_ACTIONS.EDITCONTENT
        }
        // Delete
        if (event.status === EVENT_STATUS.DRAFT && this.eventInPast(event)) {
          return EVENT_POSSIBLE_ACTIONS.DELETEEVENT
        }
        // Manage Attendees
        if (eventIsLive && !this.eventInPast(event)) {
          return EVENT_POSSIBLE_ACTIONS.MANAGEATTENDEES
        }
        // Issue Certificates
        if (eventIsLive && eventNotConference && this.eventInPast(event) && ( this.userIsAdmin || this.userIsInstructor(event)  )) {
          return EVENT_POSSIBLE_ACTIONS.ISSUECERTIFICATES
        }
        // Copy Event
        if (event.status === EVENT_STATUS.DELETED || event.status === EVENT_STATUS.CANCELLED) {
          return EVENT_POSSIBLE_ACTIONS.COPYEVENT
        }
        // View Certificates
        if ((this.eventInPast(event) && event.eventType === EVENT_TYPE.CONFERENCE) && event.status !== EVENT_STATUS.DRAFT || event.status === EVENT_STATUS.CERTIFICATESSENT) {
          return EVENT_POSSIBLE_ACTIONS.VIEWCERTIFICATES
        }
        return "None"
      },

      canIssueCertificatesAfterFirstIssueOfCertificates(event) {
        return event.eventType !== EVENT_TYPE.CONFERENCE && this.eventInPast(event) && (this.userIsAdmin || this.userIsInstructor(event)) && event.status === EVENT_STATUS.CERTIFICATESSENT
      },

      userIsInstructor(event) {
        return event.instructorId === this.$store.getters.user.id
      },

      GoToIssueCertificates: function (eventId) {
        this.$router.push({ name: `${ this.$store.getters.layout === LAYOUTS.ADMIN ? 'AdminIssueCertificatesStep1' : 'ProviderIssueCertificatesStep1' }`, params: { eventId: eventId, status: this.pageDisplayType }, query: { searchText: this.searchText, sortBy: this.sortBy, sortDesc: this.sortDesc, pageNumber: this.pagination.page, selectedStatus: this.selectedStatus } });
      },

      GoToViewCertificates: function (eventId) {
        this.$router.push({ name: `${ this.$store.getters.layout === LAYOUTS.ADMIN ? 'AdminViewIssuedCertificates' : 'ProviderViewIssuedCertificates' }`, params: { eventId: eventId, status: this.pageDisplayType }, query: { searchText: this.searchText, sortBy: this.sortBy, sortDesc: this.sortDesc, pageNumber: this.pagination.page, selectedStatus: this.selectedStatus } });
      },

      GoToManageAttendees: function (eventId) {
        this.$router.push({ name: `${ this.$store.getters.layout === LAYOUTS.ADMIN ? 'AdminManageAttendees' : 'ProviderManageAttendees' }`, params: { eventId: eventId, status: this.pageDisplayType }, query: { searchText: this.searchText, sortBy: this.sortBy, sortDesc: this.sortDesc, pageNumber: this.pagination.page, selectedStatus: this.selectedStatus } });
      },

      eventInPast: function(event) {
        return (new Date(event.endDateTime)) < new Date()
      },

      CopyEvent: function (event) {
        console.log("event:", event);
        const newEvent = JSON.parse(JSON.stringify(event))
        this.editEventdialog.event = newEvent
        this.editEventdialog.state = EVENT_STEPPER_STATE.COPY
        this.editEventdialog.show = true;
      },

      SetSearchText() {
        if (this.$route.query.searchText) {
          this.searchText = this.$route.query.searchText;
        }
      },

      SetPage() {
        if (this.$route.query.pageNumber) {
          this.pagination.page = this.$route.query.pageNumber;
        }
      },

      CloseDeleteEventDialog() {
        this.deleteEventDialog.show = false;
      },

      OnDeleteEventClick(eventId) {
        this.deleteEventDialog.show = true;
        this.deleteEventDialog.eventId = eventId;
      },

      // Sets the event status to deleted
      async DeleteEvent() {
        const eventId = this.deleteEventDialog.eventId
        console.log(eventId);
        this.error_deleting_event = false;
        try {
          const eventService = new EventService(this.$store)
          await eventService.DeleteEvent(eventId)
          await eventService.GetFutureEventsForEmployee()
          await eventService.GetPastEventsForEmployee()
          // Close the event delete window
          this.deleteEventDialog.show = false;
        } catch (err) {
          // TODO: FUTURE: Use the error_deleting_event status
          console.log("Error when setting status of event to deleted", err)
          this.error_deleting_event = true;
        }
      },

      OnDownloadAttendeeListClick(event) {
        try {
          const eventService = new EventService(this.$store)
          eventService.DownloadAttendeeList(event.id, event.title)
        } catch (err) {
          console.warn("Error when downloading attendee list;", err)
        }
      },

      GetSharingURL(event) {
        //console.log("event:", event)
        const sharingURL = `https://${window.location.hostname}/UserBookCourseDisplay/${event.id}`
        //console.log("sharingURL:", sharingURL)
        return sharingURL
      },

      ShowEditEventDialog(selectedEventDetails) {
        //console.log(selectedEventDetails);
        var clonedSelectedEventDetails = lodash.cloneDeep(selectedEventDetails);
        const state = this.eventInPast(selectedEventDetails) ? EVENT_STEPPER_STATE.VIEWONLY : EVENT_STEPPER_STATE.UPDATE
        this.editEventdialog = Object.assign({}, this.editEventdialog, { event: clonedSelectedEventDetails, state } );
        this.editEventdialog.show = true;
      },


      async CloseEventStepper(shouldRefresh) {
        this.is_data_fetched = false
        this.editEventdialog.show = false
        this.editEventdialog.status = EVENT_STEPPER_STATE.UPDATE
        // Fetch the latest event list (lazy, but efficient)
        if (shouldRefresh) {
          const eventService = new EventService(this.$store)
          await Promise.all([
            eventService.GetFutureEventsForEmployee(),
            eventService.GetPastEventsForEmployee()
          ])
        }
        this.is_data_fetched = true
      },

      generatePdf: function () {
        var doc = new jsPDF();
        var rows = [];
        this.GetFilteredEvents().forEach(element => {
          var temp = [
            element.title,
            this.getDateFromDateTime(element.startDateTime),
            this.getTimeFromDateTime(element.startDateTime),
            this.getDurationFromEvent(element),
            this.getLocationFromEvent(element),
            this.getLocaleStringForStatus(element),
            this.getEventBookedSeats(element)
          ];
          rows.push(temp);
        });
        doc.autoTable({
          head: [['Event', 'Date', 'Time', 'Duration', 'Address', 'Status', 'Attendees']],
          body: rows
        });
        doc.save();
      },

      /*
      print: function () {
        var doc = new jsPDF();
        var rows = [];
        this.GetFilteredEvents().forEach(element => {
          var temp = [element.title, element.eventDate, element.startTime, element.duration, element.address, element.status, element.attendees];
          rows.push(temp);
        });
        doc.autoTable({
          head: [['Event', 'Date', 'Time', 'Duration', 'Address', 'Status', 'Attendees']],
          body: rows
        });
        doc.autoPrint();
        doc.output('dataurlnewwindow');
      },
      */


      GetFilteredEvents() {
        // The list of events shown is different for events and records pages, so get the right list
        const eventList = this.pageDisplayType === PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.EVENTS ? this.$store.getters.providerEventsFuture : this.$store.getters.providerEventsPast
        // If the filter is set to all, then return all the events
        if (this.selectedStatus === PROVIDER_EVENTS_PAGE_FILTER_STATUSES.ALL) {
          this.pagination.totalItems = eventList.length
          return eventList
        }
        // Otherwise, return all the filtered events
        const filteredList = eventList.filter((event) => {
          // If this is a completed event (i.e. in the past and live) and the filter is completed events, show it
          if (event.status === EVENT_STATUS.LIVE && DateInThePast(event.endDateTime) && this.selectedStatus === EVENT_STATUS.COMPLETED) { return true }
          return event.status === this.selectedStatus
        });
        this.pagination.totalItems = filteredList.length
        return filteredList
      },
      getDateFromDateTime(dateTimeString) {
        return GetLocaleDateFromDateTime(dateTimeString)
      },
      getTimeFromDateTime(dateTimeString) {
        return GetTimeFromDateTime(dateTimeString)
      },
      getDurationFromEvent(eventObject, shouldFormat) {
        if (shouldFormat == null) {
          shouldFormat = true
        }
        try {
          const eventDuration = EventDurationInMinutes(eventObject)
          if (shouldFormat) {
            return FormatDuration(eventDuration, DESIRED_DURATIONS.ClockTimeHoursMinutes)
          }
          return eventDuration
        } catch (e) {
          return ''
        }
      },
      getEventBookedSeats(event) {
        // If this is a conference, just show the number of certificates sent
        if (event.eventType === EVENT_TYPE.CONFERENCE) {
          return event.attendees.items.filter((attendee) => {
            return attendee.attendance === BOOKING_ATTENDANCE.ATTENDED
          }).length
        }

        return event.attendees.items.filter((attendee) => {
          return attendee.status === BOOKING_STATUS.BOOKED || attendee.status === BOOKING_STATUS.COMPLETED
        }).length
      },
      getEventWaitingListLength(event) {
        return event.attendees.items.filter((attendee) => {
          return attendee.status === BOOKING_STATUS.ONWAITINGLIST
        }).length
      },
      eventHasAction(event, requestedAction) {
        return event.possibleActions.includes(requestedAction)
      },
      getLocaleStringForStatus(event) {
        //console.log(navigator.language)
        // If the event is in the past and is live, make it save "completed"
        var statusToCheck = event.status
        if (event.status === EVENT_STATUS.LIVE && DateInThePast(event.endDateTime)) {
          statusToCheck = EVENT_STATUS.COMPLETED
        }
        return GetLocaleString("EVENT_STATUS", statusToCheck)
      },
      getLocationFromEvent(event) {
        //console.log(navigator.language)
        return event.eventMode === EVENT_MODE.INPERSON ? event.address.city : "Online"
      },

      GetEmailURI(event) {
        // Build all the details for the email
        const subject = `${event.title} - ${new Date(event.startDateTime).toLocaleDateString()}`
        const body = `Hey,%0d%0a%0d%0aI thought you might be interested in this event on MyProPass.%0d%0a%0d%0a${encodeURIComponent(event.title)}%0d%0a${encodeURIComponent(this.sanitiseEventDescription(event.description))}%0d%0a%0d%0a${encodeURIComponent(this.GetSharingURL(event))}`
        const url = `mailto:?subject=${subject}&body=${body}`
        return url
      },
      sanitiseEventDescription(description) {
        /*
        Replace `<p>` tags with a new line characters
        Replace `<ul>` tags with a new line characters
        Replace `<li>` tags with a bullet point
        Replace HTML string for space with a space
        Strip out all other tags using regular expressions (as per attachment)
        */
        var cleanDescription = description
        cleanDescription = cleanDescription.replace(/<p>/g, '\r\n')
        cleanDescription = cleanDescription.replace(/<ul>/g, '\r\n')
        cleanDescription = cleanDescription.replace(/<li>/g, '\r\n• ')
        cleanDescription = cleanDescription.replace(/&nbsp;/g, " ");
        cleanDescription = cleanDescription.replace(/(<([^>]+)>)/gi, "");
        return cleanDescription
      }

    },

    computed: {
      pages() {
        if (this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        ) return 0

        return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
      },
      userIsAdmin: function() {
        try {
          // If this user is a MyProPass employee, they are an admin for everyone
          if (this.$store.getters.cognito.signInUserSession.accessToken.payload['cognito:groups'].includes(`ADMINISTRATOR`)) {
            return true
          }
          // Otherwise, check if they are an admin for the current provider
          const employerId = this.$store.getters.employer.id
          return this.$store.getters.cognito.signInUserSession.accessToken.payload['cognito:groups'].includes(`${employerId}_ADMINISTRATOR`)
        } catch (err) {
          console.warn("There was an error checking user admin status:", err)
          return false
        }
      },
      userInAdminMode: function() {
        return this.$store.getters.layout === LAYOUTS.ADMIN
      },
      pageDisplayType: function() {
        if (!this.$route.params.status) { return PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.EVENTS }
        return this.$route.params.status
      }
    },

    async mounted() {


      if (this.pageDisplayType === PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.EVENTS) {
        this.headers[0].text = "Event";
        this.$parent.$parent.$parent.refreshMenu('events');
        // Set it up so the next event to occur is at the top of the screen
        this.pagination = {
          ...this.pagination,
          descending: false,
          sortBy: 'eventDate',
          sortDesc: false
        }
        this.sortDesc = false
      }
      if (this.pageDisplayType === PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.RECORDS) {
        this.headers[0].text = "Record";
        // Oh dear!!!
        this.$parent.$parent.$parent.refreshMenu('records');
        // Set it up so the most recent event to have passed is at the top of the screen
        this.pagination = {
          ...this.pagination,
          descending: true,
          sortBy: 'eventDate',
          sortDesc: true
        }
        this.sortDesc = true
      }

      // If the router query is asking for a specific filter, apply it
      if (this.$route.query.selectedStatus) {
        this.selectedStatus = this.$route.query.selectedStatus;
      }

      // Fetch the latest event list (lazy, but efficient)
      const eventService = new EventService(this.$store)
      await Promise.all([
        eventService.GetFutureEventsForEmployee(),
        eventService.GetPastEventsForEmployee()
      ])
      this.is_data_fetched = true;
    }

  }
</script>

<style>
  tbody tr:nth-of-type(even) {
    background-color: rgba(243, 246,248, 0.7);
  }
</style>
<style scoped>

  .container-rounded {
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 5px;
    margin: 5px;
  }

  .container-rounded-grey {
    border-radius: 10px;
    background-color: rgba(243, 246,248, 0.7);
    padding: 5px;
    margin: 5px;
  }

  .ABC_in_Scaffolding {
    opacity: 1;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(39,62,75,1);
    letter-spacing: 0.2px;
  }

  .Duration__1_hour {
    opacity: 1;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    letter-spacing: 0.2px;
  }

  .grid-container .v-datatable tbody td:first-child {
    padding: 0 15px;
  }

  .grid-container .v-datatable td {
    width: 10%;
  }

  .grid-container .v-datatable td:first-child, .grid-container .v-datatable td:last-child {
    width: 20%;
  }
</style>

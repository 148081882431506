<template>
  <v-container v-if="is_data_fetched">
    <v-layout wrap justify-center>
      <v-flex xs12>
        <div style="color:rgb(0,171,173);text-align:center;"><h3><b>{{event.title}}</b></h3></div>
        <dl class="d-list center-d-list">
          <dt>Date of Course:</dt><dd>{{ eventDate }}</dd>
          <dt>Location:</dt><dd>{{ eventLocation }}</dd>
          <dt>Time:</dt> <dd>{{ eventStartTime }}</dd>
          <dt>Instructor:</dt> <dd>{{ eventInstructor }}</dd>
          <dt>Event Code:</dt> <dd>{{ event.code }}</dd>
        </dl>
      </v-flex>
    </v-layout>
    <br />
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card class="container-rounded-mini">
          <v-layout row wrap justify-center>
            <v-flex xs10>
              <h5 style="text-align:center;margin-top:10px;" class="page-header">Registrations</h5>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <br />
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-data-table :headers="headers" :items="attendeeList" hide-actions :pagination.sync="pagination" class="elevation-1 table">
          <template slot="headerCell" slot-scope="{ header }">
            <span class="subheading font-weight-bold text--darken-3" v-text="header.text" />
          </template>
          <template v-slot:items="props">
            <td>{{ props.item.seatNumber }}</td>
            <td>{{ props.item.user.firstName }}</td>
            <td>{{ props.item.user.lastName }}</td>
            <td>
              <span v-if="props.item.status === BOOKING_STATUS.ONWAITINGLIST">Waiting List</span>
              <span v-else>{{ props.item.id }}</span>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <br />
    <div class="button-row">
      <div class="pull-left">
        <v-btn color="rgb(254,138,125)" small @click="back">
          Back
        </v-btn>
      </div>
      <div class="text-right pull-right">
        <v-btn color="rgba(254,138,125,1)" small @click="OpenEmailClientWithAttendeeDetails">
          Email Attendees
        </v-btn>
        <v-btn color="rgba(254,138,125,1)" small @click="PrintRegisterPDF">
          Print Register
        </v-btn>
        <!--
        <v-btn color="rgba(254,138,125,1)" small @click="Print">
          Print Register
        </v-btn>
        -->
      </div>
    </div>

  </v-container>
</template>



<script>

  import EventService from '../services/event.service'
  import EventRegisterService from '@/services/eventregister.service'
  import * as EventHelper from '../helpers/event.helper'

  import { LAYOUTS } from '@/constants/LayoutConstants'
  import { EVENT_MODE } from '../constants/EventConstants'
  import { BOOKING_STATUS } from '../constants/BookingConstants'
  //import jsPDF from 'jspdf'


  export default {

    data() {
      return {
        is_data_fetched: false,
        pagination: {
          rowsPerPage: 5,
          totalItems: 0
        },
        attendeeList: [],
        event: {},
        headers: [
          { text: 'S.No', align: 'center', value: 'sNo' },
          { text: 'First Name', align: 'center', value: 'firstName' },
          { text: 'Last Name', align: 'center', value: 'lastName' },
          { text: 'Registration No', align: 'center', value: 'registrationNo' }
        ],
        BOOKING_STATUS
      }
    },

    methods: {


      OpenEmailClientWithAttendeeDetails() {
        // Build all the details for the email
        const subject = `${this.event.title} - ${this.eventDate}`
        const body = `Hello,%0A%0AYou are booked to attend the course "${this.event.title}" happening on ${this.eventDate}.%0A%0A[YOUR MESSAGE HERE]%0A%0AWith best regards,%0A${this.$store.getters.user.firstName} ${this.$store.getters.user.lastName}%0A${this.$store.getters.employer.roleName} - ${this.$store.getters.employer.name}`
        const bccAttendeeEmails = this.attendeeList.reduce((prevString, attendee) => {
          if (prevString === "") { return `${attendee.user.emailAddress}` }
          return `${prevString},${attendee.user.emailAddress}`
        }, "")
        const url = `mailto:?subject=${subject}&body=${body}&bcc=${bccAttendeeEmails}`
        // Open the email client
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.click();
        document.body.removeChild(a);
      },


      back() {
        this.$router.push({ name: `${ this.$store.getters.layout === LAYOUTS.ADMIN ? 'AdminAllEvents' : 'ProviderAllEvents' }`, params: { status: this.$route.params.status }, query: this.$route.query });
      },


      PrintRegisterPDF() {
        const eventRegisterService = new EventRegisterService()
        eventRegisterService.setBookingList(this.attendeeList)
        eventRegisterService.setEventDetails(this.event)
        eventRegisterService.generateDocument()
      },
    },


    computed: {
      pages() {
        if (this.attendeeList === null || this.pagination.totalItems === null) { return 0 }
        return Math.ceil(this.attendeeList.length / this.pagination.rowsPerPage)
      },
      eventDate: function() {
        try {
          return (new Date(this.event.startDateTime)).toLocaleString()
        } catch (err) {
          console.warn("Error getting event date:", err)
          return "Error fetching date"
        }
      },
      eventLocation: function() {
        try {
          if (this.event.eventMode === EVENT_MODE.INPERSON) return EventHelper.FormatAddressObjectToString(this.event.address)
          return this.event.onlineURL
        } catch (err) {
          console.warn("Error getting event location:", err)
          return "Error fetching location"
        }
      },
      eventStartTime: function() {
        try {
          return EventHelper.EventDateTimeStringToTime(this.event.startDateTime)
        } catch (err) {
          console.warn("Error getting event Start Time:", err)
          return "TBC"
        }
      },
      eventInstructor: function() {
        try {
          return EventHelper.GetInstructorsName(this.event)
        } catch (err) {
          console.warn("Error getting instructor's name for event:", err)
          return ""
        }
      },
    },

    async mounted() {
      // Done
      const eventService = new EventService(this.$store)
      // TODO: FUTURE: Add some error checking to the event Id fetching
      try {
        const response = await eventService.GetEventAttendees(this.$route.params.eventId)
        //console.log(response)
        // Add the event and attendee list to the data
        this.attendeeList = response.items.filter(attendee => attendee.status !== BOOKING_STATUS.CANCELLED)
        this.event = response.event
      } catch (err) {
        console.warn("Unable to fetch event attendees", err)
      }
      this.is_data_fetched = true;
    }
  }
</script>


<style scoped>

  .button-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
  }

  .pull-right {
    margin-left: auto;
  }

  .v-btn {
    text-decoration: none;
    text-transform: capitalize;
  }
  /* style the container */
  .container {
    position: relative;
    border-radius: 5px;
    padding: 20px 0 30px 0;
  }

  /* bottom container */
  .bottom-container {
    text-align: center;
    background-color: #666;
    border-radius: 0px 0px 4px 4px;
  }

  body {
    background-color: rgba(243,246,248,1);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  .container-rounded-mini {
    background-color: #FFFFFF;
    min-height: 40px;
  }

  .container-rounded {
    background-color: #FFFFFF;
    padding-left: 50px;
    margin: 40px;
    min-height: 350px;
  }
</style>

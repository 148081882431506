
import { DEFAULT_EVENT_STRUCTURE, EVENT_MODE, EVENT_TYPE, EVENT_STEPPER_STATE, EVENT_STATUS, EVENT_POSSIBLE_ACTIONS } from '@/constants/EventConstants'
import { DEFAULT_PRICE_ITEM, EVENT_PRICE_STATUS, EVENT_PRICE_SERVER_STATUS } from '@/constants/EventPriceConstants'
import { ADDRESS_FIELDS, ADDRESS_FIELD_LOOKUP, FormatAddressObjectToString, GenerateRandomEventCode } from '@/helpers/event.helper'
import EventPrice from '@/models/eventprice.model'
import Attendee from '@/models/attendee.model'
import PriceService from '@/services/price.service'
import { v4 as uuidv4 } from 'uuid';

class Event {

  constructor(event, eventStepperState) {
    //console.log("Constructing Event;", event)
    // If no event details were provided, create the default event
    this.hasChanges = false

    if (!event) {
      this.event = DEFAULT_EVENT_STRUCTURE
      this.event.id = uuidv4()
      this.priceOptions = []
      this.attendees = []
      //console.log("Event instance for new event created with id:", this.event.id)
      return
    }
    // If this is supposed to be copying another event, copy all the args across
    if (event instanceof Event) {
      this.event = JSON.parse(JSON.stringify(event.event))
      this.priceOptions = [ ...event.getPriceOptions(true) ]
      this.attendees = [ ...event.attendees ]
      //console.log("Event instance for copied event created with id:", this.event.id)
      //console.log("Original event:", event)
      //console.log("Original event priceOptions:", event.getPriceOptions(true))
      return
    }
    // If this instance is just being populated
    this.event = {
      ...DEFAULT_EVENT_STRUCTURE,
      ...event,
      address: {
        ...DEFAULT_EVENT_STRUCTURE.address,
        ...event.address
      },
      certificateCustomisation: {
        ...DEFAULT_EVENT_STRUCTURE.certificateCustomisation,
        ...event.certificateCustomisation
      }
    }
    // Extract the price options for this event and create EventPrice instances
    try {
      if (event.prices.items.length === 0) {
        throw "No prices in event; throwing error so that default is used"
      }
      //console.log('event.prices.items:', event.prices.items)
      this.priceOptions = event.prices.items.map((price) => {
        const eventPrice = new EventPrice(price)
        eventPrice.setStatus(EVENT_PRICE_SERVER_STATUS.NO_CHANGE)
        return eventPrice
      })
    } catch (err) {
      console.warn("Error when processing event prices; creating default price", err)
      const defaultPrice = new EventPrice(DEFAULT_PRICE_ITEM)
      defaultPrice.setStatus(EVENT_PRICE_SERVER_STATUS.TO_CREATE)
      this.priceOptions = [defaultPrice]
    }
    try {
      // Extract the attendees and convert to attendee model
      this.attendees = event.attendees.items.map((attendee) => {
        return new Attendee(attendee)
      })
    } catch (err) {
      console.warn("Error when processing event attendees; using default attendees", err)
      this.attendees = []
    }

    // Extra check for the certificate customisation to fix bug from old DB
    if (!this.event.certificateCustomisation.colorBackground) {
      this.event.certificateCustomisation = DEFAULT_EVENT_STRUCTURE.certificateCustomisation
    }

    // If this is a copy event, change the id and code of the event
    if (eventStepperState === EVENT_STEPPER_STATE.COPY) {
      this.event.id = uuidv4()
      this.event.code = GenerateRandomEventCode()
      this.event.status = EVENT_STATUS.DRAFT,
      this.event.possibleActions = [
        EVENT_POSSIBLE_ACTIONS.EDITCONTENT,
        EVENT_POSSIBLE_ACTIONS.MANAGEATTENDEES
      ]
      this.attendees = []

      // Take each price that isn't deleted and change their status to create
      const prices = this.getPriceOptions(false)
      console.log("prices:", prices)
      for (const price of prices) {
        console.log("price:", price)
        price.setStatus(EVENT_PRICE_SERVER_STATUS.TO_CREATE)
        price.setField("id", uuidv4())
      }

      // Reset the event statistics
      this.event.attendeeStatistics = {
        booked: 0,
        onWaitingList: 0,
        attended: 0,
        noShow: 0,
      }
    }

    //console.log("Event instance for existing event created with id:", this.event.id)
  }

  // Gets the location of the event
  getLocation() {
    if (this.event.eventMode === EVENT_MODE.VIRTUAL) {
      return 'Online'
    }
    return this.event.address
  }

  getLocationString() {
    if (this.event.eventMode === EVENT_MODE.VIRTUAL) {
      return 'Online'
    }
    return FormatAddressObjectToString(this.event.address, [ ADDRESS_FIELDS.LINE1, ADDRESS_FIELDS.LINE2, ADDRESS_FIELDS.LINE3, ADDRESS_FIELDS.CITY, ADDRESS_FIELDS.POSTALCODE, ADDRESS_FIELDS.COUNTRY ])
  }

  getData() {
    return this.event
  }

  getAttendees() {
    return this.attendees
  }

  getField(fieldName) {
    //console.log("Getting field:", fieldName)
    try {
      // If the field is attendanceHours and it's null, return 0
      if (fieldName === 'attendanceHours') {
        return this.event[fieldName] || 0
      }
      return this.event[fieldName]
    } catch (err) {
      console.warn("Error getting field with name:", fieldName, err)
      return ""
    }
  }

  setField(fieldName, value) {
    // Special case because date and time elements can be set separately
    if (fieldName === "DATEONLY") {
      try {
        //console.log("New value for date:", newValue)
        const currentStartDateTime = this.event.startDateTime
        const currentEndDateTime = this.event.endDateTime
        // Update the current dateTimes with the new date only
        const newStartDateTimeString = `${value}T${currentStartDateTime.split('T')[1]}`
        const newEndDateTimeString = `${value}T${currentEndDateTime.split('T')[1]}`
        if (newStartDateTimeString !== this.event.startDateTime) {
          // Set the new values
          this.hasChanges = true
          this.event.startDateTime = newStartDateTimeString
        }
        if (newEndDateTimeString !== this.event.endDateTime) {
          // Set the new values
          this.hasChanges = true
          this.event.endDateTime = newEndDateTimeString
        }
      } catch (err) {
        console.warn("Unable to set date for event;", err)
      }
      return
    }
    // For all other keys, just update normally
    try {
      if (this.event[fieldName] !== value) {
        this.hasChanges = true
        this.event[fieldName] = value
      }
    } catch (err) {
      console.warn("Error setting field with name:", fieldName, err)
    }
  }

  getAddressItem(addressItem) {
    if (!ADDRESS_FIELDS[addressItem]) {
      console.warn("Non address field used to request address item;", addressItem)
      return ""
    }
    // If the correct type of field was requested, get the details
    try {
      //console.log("This event address:", this.event.address)
      //console.log("address item:", addressItem)
      return this.event.address[ADDRESS_FIELD_LOOKUP[addressItem]]
    } catch (err) {
      console.warn("Error getting address field with name:", addressItem, err)
      return ""
    }
  }

  setAddressItem(addressItem, value) {
    if (!ADDRESS_FIELDS[addressItem]) {
      console.warn("Non address field used to request address item;", addressItem)
      return
    }
    try {
      if (this.event.address[ADDRESS_FIELD_LOOKUP[addressItem]] !== value) {
        this.hasChanges = true
        this.event.address[ADDRESS_FIELD_LOOKUP[addressItem]] = value
      }
    } catch (err) {
      console.warn("Error setting address field with name:", ADDRESS_FIELD_LOOKUP[addressItem], err)
    }
  }

  /**
  * addPriceOption updates the price database and returns the new price ID
  * @param  {string} title        The title of the event price option
  * @param  {string} description  The description of the event price option
  * @param  {string} priceGBP     The price in GBP for this price option
  * @param  {string} priceUSD     The price in USD for this price option
  * @param  {string} priceEUR     The price in EUR for this price option
  * @return {EventPrice}          The EventPrice instance for this price
  */
  async addPriceOption(title, description, priceGBP, priceUSD, priceEUR) {
    const parameters = {
      id: uuidv4,
      eventId: this.event.id,
      title: title ? title : "Standard Price",
      description: description ? description : "Standard Price",
      priceGBP: priceGBP ? priceGBP : "0",
      priceUSD: priceUSD ? priceUSD : "0",
      priceEUR: priceEUR ? priceEUR : "0",
      groupsCanRead: ["USER"],
      groupsCanUpdate: [`${this.event.providerId}_ADMINISTRATOR`],
      groupsFullControl: []
    }
    // Create the EventPrice instance
    const eventPrice = new EventPrice(parameters)
    eventPrice.setStatus(EVENT_PRICE_SERVER_STATUS.TO_CREATE)
    const priceService = new PriceService(this.$store)
    await priceService.CreatePriceForEvent(eventPrice)
    // Add the new price to the price options
    this.priceOptions = this.priceOptions.concat([eventPrice])
    this.hasChanges = true
    // Return the new price object
    return eventPrice
  }

  /**
  * addPriceOptionFromEventPrice updates the price database and returns the new price ID
  * @param  {EventPrice} eventPrice    The title of the event price option
  * @return {null}
  */
  async addPriceOptionFromEventPrice(eventPrice, removeDefaultPrice) {
    // Create the EventPrice instance
    //const priceService = new PriceService(this.$store)
    eventPrice.setField('id', uuidv4())
    eventPrice.setField('eventId', this.event.id)
    eventPrice.setStatus(EVENT_PRICE_SERVER_STATUS.TO_CREATE)
    //await priceService.CreatePriceForEvent(eventPrice)
    // Add the new price to the price options
    this.priceOptions = this.priceOptions.concat([eventPrice])
    // Filter out any standard prices that were replaced by changing their status to NO_CHANGE
    if (removeDefaultPrice) {
      this.priceOptions = this.priceOptions.filter((item) => {
        if (item.getId() !== "0") { item.setStatus(EVENT_PRICE_SERVER_STATUS.NO_CHANGE) }
        return true
      })
    }
    this.hasChanges = true
    // Return the new price object
    return eventPrice
  }

  /**
  * updatePriceOptionFromEventPrice updates the price item in the database
  * @param  {EventPrice} eventPrice    The title of the event price option
  * @return {null}
  */
  async updatePriceOptionFromEventPrice(eventPrice) {
    //console.log("eventPrice:", eventPrice)
    // If this price is already in create status, don't change the status
    if (eventPrice.status !== EVENT_PRICE_SERVER_STATUS.TO_CREATE) {
      eventPrice.setStatus(EVENT_PRICE_SERVER_STATUS.TO_UPDATE)
    }
    // Replace the existing price with the new price object
    this.priceOptions = this.priceOptions.map((priceItem) => {
      if (priceItem.getId() === eventPrice.getId()) { return eventPrice }
      return priceItem
    })
    this.hasChanges = true
    // Return the new price object
    return eventPrice
  }


  /**
  * removePriceOption removes the price from the database and the instance of the event
  * @param  {string} priceOptionId    The ID of the event price option to remove
  * @return {null}
  */
  async removePriceOption(priceOptionId) {
    // Extract all the price option IDs so that checks can be performed
    const priceOptionIds = this.priceOptions.map((item) => {
      return item.getId()
    })
    //console.log("priceOptionIds:", priceOptionIds)
    //console.log("priceOptionId:", priceOptionId)
    // If a delete was requested for an ID that doesn't exist, throw an error
    if (!priceOptionIds.includes(priceOptionId)) {
      throw `Event with ID ${this.event.id} does not include a price option with ID ${priceOptionId}`
    }
    // Get the eventPrice Object from the list of event prices
    const priceOptionToDelete = this.priceOptions.filter((item) => {
      return item.getId() === priceOptionId
    })[0]
    //console.log("priceOptionToDelete:", priceOptionToDelete)
    // Mark the eventPrice as deleted
    //const priceService = new PriceService(this.$store)
    //await priceService.DeletePriceOption(priceOptionToDelete)
    // Update the list of event price options in the instance of the event
    /*
    this.priceOptions = this.priceOptions.filter((item) => {
      return item.getId() !== priceOptionId
    })
    */
    priceOptionToDelete.setField('status', EVENT_PRICE_STATUS.DELETED)
    priceOptionToDelete.setStatus(EVENT_PRICE_SERVER_STATUS.TO_DELETE)
    this.hasChanges = true
    //console.log("priceOptions after:", this.priceOptions)
  }

  /**
  * getPriceOptions returns an array of EventPrice instances
  * @return {Array}
  */
  getPriceOptions(showDeleted) {
    try {
      //console.log("this.priceOptions:", this.priceOptions)
      return this.priceOptions.filter((item) => {
        return item.getField('status') !== EVENT_PRICE_STATUS.DELETED || showDeleted
      })
    } catch (err) {
      console.warn("error when fetching price options;", err)
      return []
    }
  }


  getGraphQLObject() {

    const returnObject = {
      ...this.event
    }

    // Some housekeeping to ensure creation happens correctly
    if (this.event.eventType !== EVENT_TYPE.CONFERENCE) {
       delete returnObject.eventValidityDateTime
    }
    delete returnObject.attendees
    delete returnObject.instructor
    delete returnObject.eventLogo
    delete returnObject.accreditationLogo
    delete returnObject.provider
    delete returnObject.prices

    // Return the object
    return returnObject
  }

  async updatePriceOptionsOnServer() {
    const priceService = new PriceService()
    // Create all the new price options
    const pricesToCreate = this.priceOptions.filter((item) => {
      return item.status === EVENT_PRICE_SERVER_STATUS.TO_CREATE
    })
    await Promise.all(pricesToCreate.map((item) => {
      item.setField('eventId', this.event.id)
      return priceService.CreatePriceForEvent(item)
    }))
    // Update all the updated price options
    const pricesToUpdate = this.priceOptions.filter((item) => {
      return item.status === EVENT_PRICE_SERVER_STATUS.TO_UPDATE
    })
    await Promise.all(pricesToUpdate.map((item) => {
      item.setField('eventId', this.event.id)
      return priceService.UpdatePriceForEvent(item)
    }))
    // Delete all the deleted price options
    const pricesToDelete = this.priceOptions.filter((item) => {
      return item.status === EVENT_PRICE_SERVER_STATUS.TO_DELETE
    })
    await Promise.all(pricesToDelete.map((item) => {
      item.setField('eventId', this.event.id)
      return priceService.DeletePriceOption(item)
    }))
    // Once all the changes are made, set all the events back to NO_CHANGE
    for (const priceOption of this.priceOptions) {
      priceOption.setStatus(EVENT_PRICE_SERVER_STATUS.NO_CHANGE)
    }
  }


}

export default Event

<template>
  <v-container fluid fill-height>
    <v-layout column>
      <v-flex xs10>
        <v-layout xs8 ma-0 pa-0>
          <v-flex xs12 offset-md3>
            <h5>Checkout</h5>
            <v-layout class="container-rounded">
              <v-flex xs6>
                <v-container fluid fill-height>
                  <v-layout column>
                    <v-flex xs10 class="container-rounded-grey">
                      <v-layout style="padding:5px;">
                        <v-flex xs6>
                          <div style="text-align:center;">
                            <v-btn color="rgb(0,171,173)" :small="true" @click="pay">
                              Pay 250
                            </v-btn><br />
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-flex>
              <v-flex xs3></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>


<script>
  //import router from '../router'
  export default {
    data() {
      return {

      }
    },
    methods: {
      pay() {}
    }
  }

</script>
<style scoped>

  .v-btn {
    text-decoration: none;
    text-transform: capitalize;
  }

  .center {
    position: relative; /* where the next element will be automatically positioned */
    display: inline-block; /* causes element width to shrink to fit content */
    left: 50%; /* moves left side of image/element to center of parent element */
    transform: translate(-50%); /* centers image/element on "left: 50%" position */
  }

  .container-rounded {
    border-radius: 10px;
    background-color: #FFFFFF;
    padding-top: 0px;
    margin: 0px;
  }

  .container-rounded-grey {
    border-radius: 10px;
    background-color: rgba(243, 246,248, 0.7);
    padding: 0px;
    margin: 0px;
  }
</style>

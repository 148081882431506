
import { EVENT_STATUS } from './EventConstants'

export const USER_EVENTS_PAGE_PARAMETERS = {
  RECORDS: "RECORDS",
  EVENTS: "EVENTS"
}

export const PROVIDER_ALL_EVENTS_PAGE_PARAMETERS = {
  RECORDS: "RECORDS",
  EVENTS: "EVENTS"
}

export const USER_EVENTS_PAGE_FILTER_STATUSES = {
  ALL: "ALL",
  WAITING_LIST: "WAITING_LIST",
  BOOKED: "BOOKED",
  CANCELLED: "CANCELLED"
}

export const PROVIDER_EVENTS_PAGE_FILTER_STATUSES = {
  ALL: "ALL",
  ...EVENT_STATUS
}

export const USER_EDIT_UPLOADED_EVENT_RECORD_STATUS = {
  CREATE: "CREATE",
  UPDATE: "UPDATE"
}

export const PRICE_WINDOW_OPTIONS = {
  CREATE: "CREATE",
  UPDATE: "UPDATE"
}

<template>
  <v-card>
    <v-card-title>
      <div class="headline" style="color:rgba(254,138,125,1);">Edit Employee</div>
    </v-card-title>
    <v-divider style="border-width:1px;"></v-divider>


    <v-layout layout user-returned-details>
      <div class="user-details-row">
        <div>
          <h4>{{ employee.user.firstName }} {{ employee.user.lastName }}</h4>
        </div>
      </div>

      <!-- User Details Container -->
      <h5 style="margin: 1em 0 0.5em 0;">Employee Details</h5>
      <div class="user-details-container">

        <!-- Role Name Row -->
        <label class="control-label single-line" for="roleName">Employee Job Title</label>
        <input type="text" class="form-control" id="roleName" v-model="employee.roleName">

        <!-- Access Rights Section -->
        <label class="control-label single-line" for="isCourseInstructor">Instructor?</label>
        <input type="checkbox" id="isCourseInstructor" v-model="employee.accessRights.isCourseInstructor">
        <label class="control-label single-line" for="isAdministrator">Has Admin Access?</label>
        <input type="checkbox" id="isAdministrator" v-model="employee.accessRights.isAdministrator">
      </div>

      <!-- Course Instructor Details Container -->
      <v-divider v-if="employee.accessRights.isCourseInstructor" style="border-width:1px;" />
      <h5 v-if="employee.accessRights.isCourseInstructor" style="margin: 1em 0 0.5em 0;">Instructor Details</h5>
      <div class="course-instructor-details" v-if="employee.accessRights.isCourseInstructor">

        <label class="control-label single-line" for="isActive">Active</label>
        <input type="checkbox" id="isActive" v-model="employee.courseInstructorDetails.isActive">

        <label class="control-label single-line" for="phone">About Instructor</label>
        <vue-editor v-model="employee.courseInstructorDetails.aboutText" />

      </div>

      <!-- Group Selection Section -->
      <fieldset class="group-fieldset">
        <legend>Groups</legend>
        <template v-for="group in employerGroups">
          <div class="group-row" v-bind:key="group.id">
            <input type="checkbox" :value='group.id' :checked="selectedGroups.includes(group.id)" @change="groupChanged(group)">
            <label class="control-label single-line">{{ group.name }}</label>
          </div>
        </template>
      </fieldset>

    </v-layout>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small color=rgb(0,171,173) @click="CloseEditInstructorDialog">Cancel</v-btn>
      <v-btn color="rgb(0,171,173)" small @click="UpdateEmployee" class='add-button'>Save Changes</v-btn>
      <!--<v-btn small color="rgb(0,171,173)" @click="saveInstructor">Save</v-btn>-->
    </v-card-actions>

  </v-card>
</template>


<script>

  import EmployerService from '@/services/employer.service'
  import EmployeeService from '@/services/employee.services'
  import { VueEditor } from "vue2-editor"

  const DEFAULT_EMPLOYEE = {
  user: {
    firstName: '',
    lastName: '',
  },
  accessRights: {},
  courseInstructorDetails: {}
}

  export default {

    props: {
      closePopover: Function,
      userDetails: Object
    },

    components: {
      VueEditor,
    },


    data() {
      return {
        errorUpdatingEmployee: false,
        updatingEmployee: false,
        employee: DEFAULT_EMPLOYEE,
        employerGroups: [],
        selectedGroups: [],
        groupsHasChanged: false,
      }
    },


    methods: {
      CloseEditInstructorDialog() {
        this.closePopover(false)
      },

      async UpdateEmployee() {
        //console.log("Updating userDetails with details:", this.employee)
        this.updatingEmployee = true;
        this.errorUpdatingEmployee = false
        // Add userDetails as an employee
        try {
          const employerService = new EmployerService(this.$store)
          await employerService.UpdateEmployee(this.employee)
          // TODO: If the employee groups have changed, update the groups
          if (this.groupsHasChanged) {
            const employeeService = new EmployeeService(this.$store)
            const employee = JSON.parse(JSON.stringify(this.userDetails))
            await employeeService.UpdateEmployeeGroups(employee, this.selectedGroups)
          }
          this.employee = DEFAULT_EMPLOYEE
          this.closePopover(true)
        } catch (err) {
          console.log("Error adding employee:", err)
          this.errorUpdatingEmployee = true
        }
        this.updatingEmployee = false;
      },

      groupChanged(group) {
        this.groupsHasChanged = true;
        // If this group has already been selected, remove it
        if (this.selectedGroups.includes(group.id)) {
          this.selectedGroups = this.selectedGroups.filter(g => g !== group.id)
        } else {
          this.selectedGroups.push(group.id)
        }
      },

      processNewUser(userDetails) {
        //console.log("Processing new user:", userDetails)
        if (Object.keys(userDetails).length !== 0) {
          let employee = JSON.parse(JSON.stringify(userDetails))
          this.selectedGroups = employee.employerGroups.items.map(g => g.group.id)
          delete employee.employerGroups
          this.employee = employee
          //console.log("selectedGroups:", this.selectedGroups)
        }
      }
    },

    watch: {
      userDetails: async function() {
        //console.log("WATCHER: userDetails changed to:", this.userDetails)
        this.processNewUser(this.userDetails)
        const employerService = new EmployerService(this.$store)
        this.employerGroups = await employerService.GetGroups(this.$store.getters.employer.id)
      }
    },

    async mounted() {
      //console.log("Edit Employee Mounted with userDetails:", this.userDetails)
      this.processNewUser(this.userDetails)
      const employerService = new EmployerService(this.$store)
      this.employerGroups = await employerService.GetGroups(this.$store.getters.employer.id)
    }

  }
</script>




<style scoped>

  .layout {
    display: block;
    margin-bottom: 2em;
    max-width: 90%;
    margin: auto;
  }

  .horizontal-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .single-line {
    white-space: nowrap;
  }

  label {
    padding-right: 1em;
  }

  .user-returned-details {
    margin-top: 2em;
  }

  .user-details-row {
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    grid-gap: 2em;
  }

  .user-details-row p:last-of-type {
    margin-bottom: 0;
  }

  .add-button {
    margin-left: auto;
    margin-right: 0;
  }

  .course-instructor-details,
  .user-details-container {
    display: grid;
    grid-gap: 0.5em 1em;
    grid-template-columns: 1fr 4fr;
    margin: 0;
    margin-bottom: 2em;
    align-items: center;
    justify-content: flex-start;
  }

  .user-details-container input[type=checkbox],
  .course-instructor-details input[type=checkbox] {
    width: 1em;
  }

  .group-row {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1em;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 0.5em;
  }

  .group-row label {
    margin: 0;
  }

</style>

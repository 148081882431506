<template>
  <v-container>
    <loading :active="!is_data_fetched" :is-full-page="true" loader="dots"></loading>
    <v-layout wrap justify-center>
      <v-flex xs12 md6>
        <div class="ABC_in_Scaffolding">
          <span>Issue Certificates</span><br />
          <dl class="d-list">
            <dt>Course Name:</dt><dd>{{ event.title }}</dd>
            <dt>Date of Course:</dt><dd>{{ eventDate }}</dd>
            <dt>Location:</dt><dd>{{ eventLocation }}</dd>
          </dl>
        </div>
      </v-flex>
      <v-flex xs12 md1 offset-md5 class="actions">
          <a>
            <img :src="require('../images/export.png')" @click="generatePdf" style="margin-bottom:5px;height:18px;width:18px" />
          </a>
          <!--
          <a>
            <img :src="require('../images/printer.png')" @click="print" style="margin-bottom:5px;height:18px;width:18px" />
          </a>
          -->
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card class="container-rounded-mini">
          <v-layout row wrap justify-center>
            <v-flex xs10>
              <h6 style="text-align:center;margin-top:10px;color: #5a6f95"><b>Mark Attendance</b></h6>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center mt-3>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="attendeeList"
          hide-actions
          :pagination.sync="pagination"
          class="table"
          id="printMe">
          <template slot="headerCell" slot-scope="{ header }">
            <span class="subheading font-weight-bold text--darken-3" v-text="header.text" />
          </template>
          <template v-slot:items="props">
            <td><input type="checkbox" :disabled="props.item.id === 'manual'" :checked="props.item.attendance === BOOKING_ATTENDANCE.ATTENDED" @change="attendanceChanged(props.item)" /></td>
            <td>{{ props.item.seatNumber }}</td>
            <td>{{ props.item.user.firstName }} {{ props.item.user.lastName }}</td>
            <td>{{ props.item.id }}</td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <div style="background: #fff">
      <v-layout nowrap pt-3 pr-3>
        <v-flex xs12 text-right>
          <v-pagination style="vertical-align:top;" v-model="pagination.page" :length="pages"></v-pagination>
        </v-flex>
      </v-layout>
      <br />
      <v-layout nowrap pb-3 pr-3>
        <v-flex xs12 class="text-right">
          <v-btn color="rgb(254,138,125)" small @click="cancel">
            Back
          </v-btn>
          <v-btn color="rgb(0,171,173)" small @click="OpenAddAttendeePopover" style="margin-right: 0">
            {{ manualAttendees.length > 0 ? "Update Manual Attendees" : "Add Manual Attendees" }}
          </v-btn>
          <v-btn color="rgb(0,171,173)" small @click="RemoveManualAttendees" style="margin-right: 0" v-if='manualAttendees.length > 0'>
            Remove Manual Attendees
          </v-btn>
          <v-btn color="rgb(0,171,173)" small @click="SaveAttendees" style="margin-right: 0">
            Save Attendees
          </v-btn>
        </v-flex>
      </v-layout>
    </div>
    <!-- Add Manual Attendees Popover -->
    <v-dialog v-model="addAttendeePopover.show" persistent transition="dialog-transition" max-width="90vw">
      <v-container fluid pa-0 style="background: #fff">
        <v-layout ma-0>
          <v-flex xs12 class="position-relative">
            <provider-addmanualattendees
              :closePopover="CloseAddAttendeePopover"
              :manualAttendees="manualAttendees"
              :onManualAttendeesChange="OnManualAttendeeListChange">
            </provider-addmanualattendees>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>
  </v-container>
</template>


<script>
  import jsPDF from 'jspdf'
  import 'jspdf-autotable';

  import ProviderAddManualAttendees from '@/components/provider-addmanualattendees'

  import EventService from '../services/event.service'
  //import BookingService from '../services/booking.service'
  import * as EventHelper from '../helpers/event.helper'

  import { LAYOUTS } from '@/constants/LayoutConstants'
  import { EVENT_MODE } from '../constants/EventConstants'
  import { BOOKING_ATTENDANCE, BOOKING_STATUS } from '../constants/BookingConstants'


  export default {

    components: {
      'provider-addmanualattendees': ProviderAddManualAttendees
    },

    watch: {
      attendeeList: function(newValue) {
        //console.log("WATCHER: new value for attendeeList:", newValue)
        this.pagination.totalItems = newValue.length;
      },
    },

    data() {
      return {
        is_data_fetched: false,
        // TODO: FUTURE: Use error_saving_attendance
        error_saving_attendance: false,
        search: '',
        pagination: {
          rowsPerPage: 10,
          totalItems: 0,
          sortBy: "id"
        },
        headers: [
          { text: 'Select',           align: 'center',    value: 'select', sortable: false  },
          { text: 'S.No',             align: 'left',      value: 'sNo'                      },
          { text: 'Name',             align: 'center',    value: 'name'                     },
          { text: 'Registration No',  align: 'center',    value: 'id'                       }
        ],
        addAttendeePopover: {
          show: false
        },
        selfBookedAttendees: [],
        manualAttendees: [],
        event: {},
        BOOKING_ATTENDANCE
      }
    },

    methods: {

      // TODO: FUTURE: Add more content to the PDF pages (headings, file name, etc)
      generatePdf: function () {
        var doc = new jsPDF();
        var rows = [];
        this.attendeeList.forEach(element => {
          var temp = [element.seatNumber, element.user.firstName + ' ' + element.user.lastName, element.id];
          rows.push(temp);
        });
        doc.autoTable({
          head: [['S.No', 'Name', 'Registration No']],
          body: rows
        });
        doc.save();
      },
      /*
      print: function () {
        var doc = new jsPDF();
        var rows = [];
        this.attendeeList.forEach(element => {
          var temp = [element.sNo, element.firstName + ' ' + element.lastName, element.bookingId];
          rows.push(temp);
        });
        doc.autoTable({
          head: [['S.No', 'Name', 'Registration No']],
          body: rows
        });
        doc.autoPrint();
        doc.output('dataurlnewwindow');
      },
      */

      cancel() {
        this.$router.push({ name: `${ this.$store.getters.layout === LAYOUTS.ADMIN ? 'AdminAllEvents' : 'ProviderAllEvents' }`, params: { status: this.$route.params.status }, query: this.$route.query });
      },

      SaveAttendees: async function () {
        // Go to step 2
        this.$router.push({
          name: `${ this.$store.getters.layout === LAYOUTS.ADMIN ? 'AdminIssueCertificatesStep2' : 'ProviderIssueCertificatesStep2' }`,
          params: {
            eventDetails: this.event,
            status: this.$route.params.status,
            selfBookedAttendees: this.selfBookedAttendees.filter((booking) => { return [ BOOKING_STATUS.BOOKED ].includes(booking.status) }),
            manualAttendees: this.manualAttendees
          },
          query: this.$route.query
        });
      },

      attendanceChanged: function(booking) {
        //console.log("booking:", booking)
        // Remove the attendee from the master list of Self Booked Attendees
        var tempAttendeeList = this.selfBookedAttendees.filter(item => item.id !== booking.id)
        // Take the requested booking and change the attendance status to the opposite
        const updatedBooking = {
          ...booking,
          attendance: booking.attendance === BOOKING_ATTENDANCE.ATTENDED ? BOOKING_ATTENDANCE.NOSHOW : BOOKING_ATTENDANCE.ATTENDED
        }
        // Add the updated booking back into the attendee list, and update the component data
        tempAttendeeList.push(updatedBooking)
        this.selfBookedAttendees = tempAttendeeList
      },

      OpenAddAttendeePopover() {
        this.addAttendeePopover.show = true
      },

      CloseAddAttendeePopover() {
        this.addAttendeePopover.show = false
      },

      RemoveManualAttendees() {
        this.manualAttendees = []
      },

      OnManualAttendeeListChange(manualAttendeeList)  {
        //console.log("manualAttendeeList:", manualAttendeeList)
        this.manualAttendees = manualAttendeeList
        this.addAttendeePopover.show = false
      }
    },


    computed: {
      pages() {
        if (this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        ) return 0

        return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
      },
      eventLocation: function() {
        try {
          if (this.event.eventMode === EVENT_MODE.INPERSON) return EventHelper.FormatAddressObjectToString(this.event.address)
          return "Online"
        } catch (err) {
          return "Error fetching location"
        }
      },
      eventDate: function() {
        try {
          return ((new Date(this.event.startDateTime)).toLocaleString()).slice(0,-3)
        } catch (err) {
          return "Error fetching date"
        }
      },
      attendeeList: function() {
        try {
          // Remove all self booked attendees on waiting list, who have already been issued with certificates, or who have cancelled
          var tempAttendeeList = this.selfBookedAttendees.filter((booking) => {
            return [ BOOKING_STATUS.BOOKED ].includes(booking.status)
          })
          // Set all self booked attendees as no shows if they are currently marked as event not yet held
          tempAttendeeList = tempAttendeeList.map(item => {
            return {
              ...item,
              attendance: item.attendance === BOOKING_ATTENDANCE.EVENTNOTYETHELD ? BOOKING_ATTENDANCE.NOSHOW : item.attendance
            }
          })
          // Create the attendee entries for all manual attendees
          const manualAttendees = this.manualAttendees.map((attendee) => {
            return {
              id: "manual",
              seatNumber: "",
              user: {
                firstName: attendee.firstName,
                lastName: attendee.lastName
              },
              attendance: BOOKING_ATTENDANCE.ATTENDED
            }
          })
          //console.log(tempAttendeeList.concat(manualAttendees))
          return tempAttendeeList.concat(manualAttendees)
        } catch (err) {
          console.warn("Error fetching attendeeList;", err)
          return []
        }
      }
    },


    async mounted() {
      // TODO: FUTURE: Add some error checking to the event Id fetching
      try {
        // Create an event service instance
        const eventService = new EventService(this.$store)
        // Fetch the details of the specified event
        const response = await eventService.GetEventAttendees(this.$route.params.eventId)
        //console.log(response)
        this.event = response.event
        // If this is a return from step 2, use step 2s attendees list
        if (this.$route.params.selfBookedAttendees) {
          this.selfBookedAttendees = this.$route.params.selfBookedAttendees
          this.manualAttendees = this.$route.params.manualAttendees
        } else {
          this.selfBookedAttendees = response.items
        }
      } catch (err) {
        console.warn("Unable to fetch event attendees", err)
      }
      this.is_data_fetched = true;
    },


  }
</script>

<style>

  .v-btn {
    text-decoration: none;
    text-transform: capitalize;
  }
  /* style the container */
  .container {
    position: relative;
    border-radius: 5px;
    padding: 20px 0 30px 0;
  }

  /* bottom container */
  .bottom-container {
    text-align: center;
    background-color: #666;
    border-radius: 0px 0px 4px 4px;
  }

  body {
    background-color: rgba(243,246,248,1);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  .container-rounded-mini {
    background-color: #FFFFFF;
    min-height: 40px;
    border: solid 1px #ccc !important;
    border-radius: 3px;
  }

  .container-rounded {
    background-color: #FFFFFF;
    padding-left: 50px;
    margin: 40px;
    min-height: 350px;
  }

</style>


import UserChangePassword from '../components/user-changepassword'
import UserLanding from '../components/user-landing'
import UserPaymentConfirmation from '../components/user-paymentconfirmation'
import UserBookCourseDisplay from '../components/user-bookcourse-display'
import UserProviderInfo from '../components/user-provider-info'
import UserBookCourseReview from '../components/user-bookcourse-review'
import UserMyEvents from '../components/user-myevents'
import UserUploadCertificate from '../components/user-uploadcertificate'
import UserPersonalDetails from '../components/user-personaldetails'

import { LAYOUTS } from '@/constants/LayoutConstants'
import * as StoreActions from '../constants/StoreActions'
import store from '../store/index'

const baseUrl = process.env.BASE_URL;

export const routes = [
  {
    name: 'UserChangePassword',
    path: baseUrl +'change-password',
    meta: { requiresAuth: true, requiresProvider: false, requiresAdmin: false },
    component: UserChangePassword,
    display: 'Change Password',
    beforeEnter: (to, from, next) => { SetUserLayout(to, from, next); }
  },
  {
    name: 'UserLanding',
    path: baseUrl +'UserLanding',
    meta: { title: 'MyProPass - User Landing', requiresAuth: true, requiresProvider: false, requiresAdmin: false },
    component: UserLanding,
    display: 'User Landing',
    beforeEnter: (to, from, next) => { SetUserLayout(to, from, next); }
  },
  {
    name: 'UserUploadCertificate',
    path: baseUrl +'UserUploadCertificate/:status',
    meta: { title: 'MyProPass - User Upload Certificate', requiresAuth: true, requiresProvider: false, requiresAdmin: false },
    component: UserUploadCertificate,
    display: 'User Upload Certificate',
    beforeEnter: (to, from, next) => { SetUserLayout(to, from, next); }
  },
  {
    name: 'UserPaymentConfirmation',
    path: baseUrl +'UserPaymentConfirmation/:eventId',
    meta: { title: 'MyProPass - User Payment Confirmation', requiresAuth: true, requiresProvider: false, requiresAdmin: false },
    component: UserPaymentConfirmation,
    display: 'User Book Course Payment Confirmation',
    beforeEnter: (to, from, next) => { SetUserLayout(to, from, next); }
  },
  {
    name: 'UserBookCourseDisplay',
    path: baseUrl + 'UserBookCourseDisplay/:eventId',
    meta: { title: 'MyProPass - User Book Course Display', requiresAuth: true, requiresProvider: false, requiresAdmin: false },
    component: UserBookCourseDisplay,
    display: 'User Book Course Display',
    beforeEnter: (to, from, next) => { SetUserLayout(to, from, next); }
  },
  {
    name: 'UserProviderInfo',
    path: baseUrl + 'UserProviderInfo/:eventId',
    meta: { title: 'MyProPass - User Course Provider Information', requiresAuth: true, requiresProvider: false, requiresAdmin: false },
    component: UserProviderInfo,
    display: 'User Provider Info',
    beforeEnter: (to, from, next) => { SetUserLayout(to, from, next); } },
  {
    name: 'UserBookCourseReview',
    path: baseUrl +'UserBookCourseReview/:eventId',
    meta: { title: 'MyProPass - User Book Course Review', requiresAuth: true, requiresProvider: false, requiresAdmin: false },
    component: UserBookCourseReview,
    display: 'User Book Course Review',
    beforeEnter: (to, from, next) => { SetUserLayout(to, from, next); }
  },
  {
    name: 'UserMyEvents',
    path: baseUrl +'UserMyEvents/:status',
    meta: { title: 'MyProPass - User My Events', requiresAuth: true, requiresProvider: false, requiresAdmin: false },
    component: UserMyEvents,
    display: 'User My Events',
    beforeEnter: (to, from, next) => { SetUserLayout(to, from, next); }
  },
  {
    name: 'UserPersonalDetails',
    path: baseUrl +'UserPersonalDetails',
    meta: { title: 'MyProPass - User Profile', requiresAuth: true, requiresProvider: false, requiresAdmin: false },
    component: UserPersonalDetails,
    display: 'User Personal Details',
    beforeEnter: (to, from, next) => { SetUserLayout(to, from, next); }
  },
]

async function SetUserLayout(to, from, next) {
  //console.log("Changing layout to user")
  await store.commit(StoreActions.SET_LAYOUT, LAYOUTS.USER);
  next();
}

export default routes

<template>
  <v-flex class="centered-flex" xs12 md3 ma-0 pa-0>
    <v-layout column pa-0 ma-0>
      <v-flex xs12 md5>
        <v-card class="pl-3 pr-3 pb-3">
          <v-card-title class="justify-center pb-2">
            <h4 class="page-header">Change Password</h4>
          </v-card-title>

          <div v-if='!passwordChangedSuccessfully'>

            <div class="pb-2" style="align-content:center;width:100%;">Please enter your current and new passwords</div>

            <div class="pb-2">
              <label for="currentPassword">Current Password</label>
              <input id="currentPassword" type="password" class="form-control" v-model="currentPassword" autocomplete="off">
              <div v-if="submitted && $v.currentPassword.$error" class="text-danger">
                <small v-if="!$v.currentPassword.required">Current password has not been provided<br /></small>
                <small v-if="!$v.currentPassword.minLength">Current password is too short<br /></small>
              </div>
            </div>

            <div class="pb-2">
              <label for="newPassword">New Password</label>
              <input id="newPassword" type="password" class="form-control" v-model="newPassword" autocomplete="new-password">
              <div v-if="submitted && $v.newPassword.$error" class="text-danger">
                <small v-if="!$v.newPassword.required">New password has not been provided<br /></small>
                <small v-if="!$v.newPassword.minLength">New password is too short<br /></small>
                <small v-if="!$v.newPassword.containsUpperCaseCharacter">New password does not contain an upper case character<br /></small>
                <small v-if="!$v.newPassword.containsLowerCaseCharacter">New password does not contain a lower case character<br /></small>
                <small v-if="!$v.newPassword.containsNumericCharacter">New password does not contain a number character<br /></small>
                <small v-if="!$v.newPassword.containsSpecialCharacter">New password does not contain a special character<br /></small>
                <small v-if="!$v.newPasswordDuplicate.checkPasswordsMatch">New passwords do not match<br /></small>
              </div>
            </div>

            <div class="pb-2">
              <label for="newPasswordDuplicate">Repeat New Password</label>
              <input id="newPasswordDuplicate" type="password" class="form-control" v-model="newPasswordDuplicate" autocomplete="new-password">
              <div v-if="submitted && $v.newPasswordDuplicate.$error" class="text-danger">
                <small v-if="!$v.newPasswordDuplicate.required">Repeated password has not been provided<br /></small>
                <small v-if="!$v.newPasswordDuplicate.checkPasswordsMatch">New passwords do not match<br /></small>
              </div>
            </div>

            <div class="warning-box" v-if="passwordChangingFailed">
              {{ warningBoxText }}
            </div>

            <v-btn class="mb-3" color="rgb(0,171,173)" :block="true" @click="ChangeUserPassword" :disabled="awaitingServerResponse">
              {{ awaitingServerResponse ? "Please Wait..." : "Change Password" }}
            </v-btn>

            <v-btn color="rgba(243, 246,248, 0.7)" :block="true" @click="Cancel" :disabled="awaitingServerResponse">
              <span style="color: black;">Cancel</span>
            </v-btn>
          </div>

          <div v-if="passwordChangedSuccessfully">
            <p>Your password was changed successfully.</p>
            <v-btn color="rgb(0,171,173)" :block="true" @click="Cancel">
              Return to home page
            </v-btn>
          </div>

        </v-card>
      </v-flex>
    </v-layout>
  </v-flex>
</template>


<script>

  import AuthService from '../services/auth.service'
  import { validationMixin } from 'vuelidate'
  import { required, minLength } from 'vuelidate/lib/validators'
  import { containsUpperCaseCharacter, containsLowerCaseCharacter, containsNumericCharacter, containsSpecialCharacter } from '@/constants/Validations'

  // Validation for matching passwords
  const checkPasswordsMatch = (value, vm) => {
    return vm.newPassword === vm.newPasswordDuplicate
  }


  export default {

    data() {
      return {
        currentPassword: '',
        newPassword: '',
        newPasswordDuplicate: '',
        submitted: '',
        awaitingServerResponse: false,
        passwordChangingFailed: false,
        passwordChangedSuccessfully: false,
        warningBoxText: '',
      }
    },

    // Validations
    mixins: [validationMixin],
    validations: {
      currentPassword: {
        required, minLength: minLength(8)
      },
      newPassword: {
        required,
        minLength: minLength(8),
        checkPasswordsMatch,
        containsUpperCaseCharacter,
        containsLowerCaseCharacter,
        containsNumericCharacter,
        containsSpecialCharacter
      },
      newPasswordDuplicate: {
        required,
        minLength: minLength(8),
        checkPasswordsMatch
      }
    },

    watch: {
      passwordChangingFailed: function(newValue) {
        console.log("WATCHER: passwordChangingFailed is now:", newValue)
      },
      warningBoxText: function(newValue) {
        console.log("WATCHER: warningBoxText is now:", newValue)
      },
    },


    // Methods
    methods: {
      async ChangeUserPassword() {
        console.log("Change user password clicked")
        this.submitted = true;
        this.awaitingServerResponse = true

        // Check that all the fields are valid
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.awaitingServerResponse = false
          this.passwordChangingFailed = true
          this.warningBoxText = "The new password could not be submitted.  Please check the warnings above and resubmit."
          return;
        }

        // Send the request to cognito
        try {
          await AuthService.ChangePassword(this.currentPassword, this.newPassword);
          this.submitted = false
          this.awaitingServerResponse = false
          this.passwordChangingFailed = false
          this.passwordChangedSuccessfully = true
        } catch (error) {
          // On error, alert the user
          console.warn("Unable to change user password", error)
          this.passwordChangingFailed = true
          this.awaitingServerResponse = false
          this.warningBoxText = "Error when changing password.  Was your current password correct?"
        }

      },

      Cancel() {
        const name = this.$route.params.returnToPage ? this.$route.params.returnToPage : "UserLanding"
        this.$router.push({ name })
      }
    }

  }

</script>


<style scoped>

  .centered-flex {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 4em auto 0 auto !important;
  }

  .warning-box {
    width: 100%;
    background-color: #FFCCCC;
    color: black;
    padding: 1em;
    border-radius: 5px;
  }

</style>

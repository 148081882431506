
import _ from 'lodash';

export const cleanObjectOfEmptyParameters = (objectToClean) => {
  let returnObject = {}
  for (const key in objectToClean) {
    if (objectToClean[key]) {
      returnObject[key] = objectToClean[key]
    }
  }
  return returnObject
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Boolean}       Return a boolean to indicate if the objects are different (true = different)
 */
export const ObjectsAreDifferent = (object, base) => {
	function changes(object, base) {
		return _.transform(object, function(result, value, key) {
			if (!_.isEqual(value, base[key])) {
				result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
			}
		});
	}
  const result = changes(object, base);
  //console.log("changes:", result)
	return Object.keys(result).length > 0;
}

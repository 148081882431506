import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './routes'
import store from '../store/index'

import AuthService from '../services/auth.service'
import UserService from '../services/user.service'
import EventService from '../services/event.service'
import * as StoreActions from '../constants/StoreActions'

Vue.use(VueRouter)

let router = new VueRouter({
  mode: 'history',
  routes
})

const originalPush = router.push
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }
    return Promise.reject(err)
  })
}


router.beforeEach( async (to, from, next) => {
  //console.log('router.beforeEach');
  //console.log("to:", to)
  //console.log("from:", from)

  try {
    // If required, update the page title
    if (to.meta.title) {
      document.title = to.meta.title
    } else {
      document.title = "MyProPass"
    }

    // If the user tried to access a protected page, store the path the user tried to access
    if (to.meta.requiresAuth) {
      store.commit(StoreActions.SET_LAST_REQUIRES_AUTH_PAGE, to)
    }

    // Check if the page requires a login check; if not, go to it
    const pageRequiresLogin = to.meta.requiresAuth
    if (!pageRequiresLogin) {
      //console.log("Page does not require a login")
      next()
      return
    }

    // Check if the user is logged in.
    let cognito = null
    try {
      cognito = await AuthService.GetCurrentAuthenticatedUser();
      // Store the cognito information in the store
      store.commit(StoreActions.SET_COGNITO, cognito);
    } catch (err) {
      //console.warn("User is not logged in")
      router.push({ name: "Login" })
      return
    }

    // At this point, we know the user is logged in

    // Check if the user has access to the page if the page requires a login
    const userHasAccess = await UserCanAccessRequestedPage(cognito, to)

    // If the user tried to access a page they shouldn't send them back to the user landing page
    if (!userHasAccess.canAccess) {
      router.push( { name: "UserLanding" } )
      return
    }

    // Check if the requested page is the first secured page the user is visiting
    const firstPage = store.getters.user.username === null || from.name === null
    if (firstPage) {
      // Fetch the details for the user as they visit their first protected page
      await OnFirstProtectedPageFunctions(cognito.attributes.sub)
    }


  } catch (err) {
    // If there is an error, send the user to the login page
    console.warn("Error in router:", err)
    router.push({ name: "Login" })
    return
  }

  next();
  return
});

router.afterEach((to, from) => {
  //console.log('router.afterEach');
  //console.log("next:", next)
  localStorage.setItem('LS_ROUTE_KEY', from.path);
});

async function OnFirstProtectedPageFunctions(username) {
  // Fetch all the events that are currently available
  await Promise.all([
    new UserService(store).GetDetails(username),
    new EventService(store).GetFutureEventsForUser()
  ])
}


async function UserCanAccessRequestedPage(cognito, to) {
  var returnParameters = {
    canAccess: false
  }

  try {
    const cognitoId = cognito.username
    const cognitoGroups = cognito.signInUserSession.accessToken.payload['cognito:groups']
    const userIsProvider = cognitoGroups.includes("PROVIDER")
    const userIsAdmin = cognitoGroups.includes("ADMINISTRATOR")
    //const userHasGroups = cognitoGroups.length > 0

    // If this page requires provider rights, does the user have them
    if (to.meta.requiresProvider && !userIsProvider && !userIsAdmin) {
      console.warn("User attempted to visit provider page, but is not a provider or admin; pushing to user landing")
      return returnParameters
    }
    // If this page requires admin rights, does the user have them
    if (to.meta.requiresAdmin && !userIsAdmin) {
      console.warn("User attempted to visit administrator page, but is not an administrator; pushing to user landing")
      return returnParameters
    }
    // If this page requires admin rights, and the current portal is the launch portal
    if (to.meta.requiresAdmin && (window.location.hostname === "launch.mypropass.co.uk")) {
      console.warn("User attempted to visit administrator page, but is on the launch portal; pushing to user landing")
      return returnParameters
    }

    // If it's not a provider or admin page, allow the user to go there (they're logged in already)
    if (cognitoId) {
      returnParameters.canAccess = true
    }

    // Return the result
    return returnParameters
  } catch (err) {
    console.warn("Error checking is user has access to the page;", err)
    return {
      canAccess: false
    }
  }
}


export default router

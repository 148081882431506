
import { API, graphqlOperation } from 'aws-amplify';
//import { v4 as uuidv4 } from 'uuid';
//import * as Queries from '../graphql/queries';
import * as QueriesCustom from '../graphql/queriesCustom';
//import * as Mutations from '../graphql/mutations';
//import * as MutationsCustom from '../graphql/mutationsCustom';
//import { BOOKING_STATUS, BOOKING_ATTENDANCE, BOOKING_CERTIFICATE_STATUS } from '../constants/BookingConstants'
import * as StoreActions from '../constants/StoreActions'

const ProviderService = {
  /**
   * ListEmployees returns a graphql promise to list all the employees for a given provider
   * @param  {object} parameters A POJO containing one key; id (the ID of the provider)
   * @return {promise} The graphql promise to list all the employees for a given provider
   */
  ListEmployees(parameters) {
    //console.log("Requesting a list of employees")
    //console.log(parameters)
    return API.graphql(graphqlOperation(QueriesCustom.listEmployees, parameters));
  },

  /**
   * GetAccreditationLogos returns a graphql promise to return all the accreditation logos held by the provider
   * @param  {string} providerId The ID of the provider
   * @param  {object} store The vuex store of this project
   * @return {array} An array of the accreditation logos for the given provider
   */
  // TODO: Have this handle more than 100 logos by fetching using the nextToken
  async GetAccreditationLogos(providerId, store) {
    try {
      //console.log("Requesting a list of accreditation logos")
      const parameters = { id: providerId }
      //console.log(parameters)
      const response = await API.graphql(graphqlOperation(QueriesCustom.getAccreditationLogos, parameters));
      //console.log(response)
      const result = response.data.getEmployer.accreditationLogos
      store.commit(StoreActions.SET_ACCREDITATION_LOGOS, result);
      return result.items
    } catch (err) {
      console.warn("Error getting accreditation logos;", err)
      return []
    }
  },

  /**
   * GetEventLogos returns all the event logos created by the provider
   * @param  {string} providerId The ID of the provider
   * @return {array} An array of all the event logos for a given provider
   */
  // TODO: Have this handle more than 100 logos by fetching using the nextToken
  async GetEventLogos(providerId) {
    try {
      const parameters = { id: providerId }
      //console.log(parameters)
      const response = await API.graphql(graphqlOperation(QueriesCustom.getEventLogos, parameters));
      //console.log(response)
      const result = response.data.getEmployer.eventLogos
      //console.log("eventLogos:", result)
      return result.items
    } catch (err) {
      console.warn("Error getting event logos;", err)
      return []
    }
  },


  /**
   * GetEmployerSignatures returns a graphql promise to return all the signatures held created by the provider
   * @param  {string} providerId The ID of the provider
   * @return {promise} The graphql promise to list all the signatures for a given provider
   */
  // TODO: Have this handle more than 100 logos by fetching using the nextToken
  GetEmployerSignatures(providerId) {
    //console.log("Requesting a list of provider signatures")
    if (!providerId) {
      console.warn("No provider ID given to ProviderService.GetEmployerSignatures()")
      return {}
    }
    const parameters = { id: providerId }
    //console.log(parameters)
    return API.graphql(graphqlOperation(QueriesCustom.getSignatures, parameters));
  },

}

export default ProviderService

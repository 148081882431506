<template>
  <v-container class='screen-content' justify-center align-content-center>

    <!-- White box -->
    <v-container class='white-box' xs12>
      <v-layout grid-container login-container>

        <!-- Left column -->
        <v-flex text-center left-shadow>
          <img :src="logoImage" class="logo" />

          <div v-if='!isFISHost' class="welcome">
            <h4>Welcome to MyProPass</h4>
            <span>Your professional passport - Let your career take off</span>
          </div>
          <div v-if='isFISHost' class="welcome">
            <h4>Welcome to your FIS Competency Passport</h4>
          </div>

          <!-- Powered by MyProPass Holder -->
          <div v-if='isFISHost' class="powered-by-mpp">
            <p>Powered by</p>
            <img src="@/assets/images/logo.png" class="powered-by-logo" />
          </div>
        </v-flex>

        <!-- Right column -->
        <v-flex right-shadow class='right-box'>
          <v-flex class="right-container xs12 md8 offset-md-2">
            <form v-show="!hasCompletedSignUp" id="signUp" class="form-horizontal" @submit.prevent="signUp">
              <v-layout justify-center>
                <h5 style="color: #007AFF;font-size: 30px;margin-top:30px;">Sign Up</h5>
              </v-layout>

              <br />

              <!--
              <div class='social-button-wrapper'>
                <button class='google-button' v-on:click.prevent="GoogleSignIn"><img src="@/assets/images/btn_google_signup_light_normal_web.png" /></button>
              </div>

              <div class="text-center" style="font-size: 12px;font-weight: normal;color: #666;">or</div><br />
              -->
              <div>
                <div class="form-group">
                  <label for="firstName">First Name</label>
                  <input id="firstName" type="text" placeholder="First Name" class="col-12 form-control" v-model="firstName"/>
                  <div v-if="validations.firstNameIsBlank" class="text-danger">
                    <small>First name cannot be blank</small>
                  </div>
                </div>
                <div class="form-group">
                  <label for="lastName">Last Name</label>
                  <input id="lastName" type="text" placeholder="Password" class="col-12 form-control" v-model="lastName"/>
                  <div v-if="validations.lastNameIsBlank" class="text-danger">
                    <small>Last name cannot be blank</small>
                  </div>
                </div>
                <div class="form-group">
                  <label for="email">Email</label>
                  <input id="email" type="email" placeholder="Email" class="col-12 form-control" v-model="email"/>
                  <div v-if="validations.emailIsBlank" class="text-danger">
                    <small>Email cannot be blank</small>
                  </div>
                </div>
                <div class="form-group">
                  <label for="password">Password</label>
                  <input id="password" type="password" placeholder="Password" class="col-12 form-control" v-model="password"/>
                  <div v-if="validations.passwordTooShort" class="text-danger">
                    <small>Passwords must be at least 8 characters</small>
                  </div>
                  <div v-if="validations.passwordNoNumber" class="text-danger">
                    <small>Password must have at least one number</small>
                  </div>
                  <div v-if="validations.passwordNoLowerCaseLetter" class="text-danger">
                    <small>Password must have at least one lower case letter</small>
                  </div>
                  <div v-if="validations.passwordNoUpperCaseLetter" class="text-danger">
                    <small>Password must have at least one lower case letter</small>
                  </div>
                  <div v-if="validations.passwordNoSpecialCharacter" class="text-danger">
                    <small>Password must have at least one special character</small>
                  </div>
                  <div v-if="validations.passwordsDontMatch" class="text-danger">
                    <small>Passwords do not match</small>
                  </div>
                </div>
                <div class="form-group">
                  <label for="confirmPassword">Confirm Password</label>
                  <input id="confirmPassword" type="password" placeholder="Confirm Password" class="col-12 form-control" v-model="confirmPassword"/>
                  <div v-if="validations.passwordsDontMatch" class="text-danger">
                    <small>Passwords do not match</small>
                  </div>
                </div>

                <!--
                <div class="form-group tick-box-form-group">
                  <input id="acceptTANDC" type="checkbox" class="form-control" v-model="acceptTANDC"/>
                  <label for="acceptTANDC">I have read and accept the <a href='https://www.mypropass.co.uk/terms-and-conditions' target='_blank'>terms and conditions</a></label>
                </div>
                -->

              </div>
              <v-layout justify-end style="font-weight:normal;padding: 0 10px">
                <div justify-center style="font-weight:normal;float:right;width:50%;text-align: right">
                  <router-link :to="{ name: 'Login' }">
                    <div style="color:#a3a3a3;font-size:12px;">Already a Member?</div>
                  </router-link>
                </div>
              </v-layout>
              <div style="text-align: center;">
                <button class="btn btn-primary btn-block" type="submit" style="border: none;height: 60px;border-radius: 6px;width: 180px;font-size: 16px;font-weight: bold;margin: 30px auto">
                  Sign Up
                </button>
              </div>
              <br />
            </form>
            <div  v-show="hasCompletedSignUp">
              <v-layout justify-center>
                <h5 style="color: #007AFF;font-size: 30px;margin-top:30px;">Success</h5>
              </v-layout>
              <br />
              <p>
                You have successfully signed up for the MyProPass system.
              </p>
              <p>
                Please confirm your email address using the email we have sent to you.
              </p>
              <p>
                You can click the button below to return to the login page
              </p>
              <button class="btn btn-primary btn-block" type="submit" @click="GoToLoginPage" style="background-color: #F5740E;border: none;height: 60px;border-radius: 6px;width: 180px;font-size: 16px;font-weight: bold;color:#fff;margin: 30px auto">
                Return to login
              </button>
            </div>
          </v-flex>
        </v-flex>

      </v-layout>
    </v-container>

    <!-- Popover box -->
    <v-container>
      <v-dialog persistent v-model="messageDialog.show" transition="dialog-transition" max-width="350">
        <v-card>
          <v-card-text>
            <v-flex>
              <span style="font-weight:normal;" v-html="messageDialog.message"></span>
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="CloseMessageDialog" small color=rgb(0,171,173)>OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- Loading overlay -->
    <v-container>
      <loading :active.sync="isSigningUp" :is-full-page="true" loader="dots"></loading>
    </v-container>

  </v-container>
</template>

<script>
  import { Auth } from 'aws-amplify';
  import AuthService from '@/services/auth.service'
  import { isFISHost } from '@/constants/Validations'

  export default {
    name: 'SignUp',
    data() {
      return {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        isSigningUp: false,
        hasCompletedSignUp: false,
        acceptTANDC: false,
        validations: {
          passwordTooShort: false,
          passwordNoNumber: false,
          passwordNoLowerCaseLetter: false,
          passwordNoUpperCaseLetter: false,
          passwordNoSpecialCharacter: false,
          passwordsDontMatch: false,
          firstNameIsBlank: false,
          lastNameIsBlank: false,
          emailIsBlank: false
        },
        messageDialog: {
          show: false,
          message: ''
        },
        isFISHost
      }
    },

    async mounted() {},

    methods: {
      async signUp() {

        this.isSigningUp = true
        var invalid = false

        this.validations.passwordTooShort = false
        this.validations.passwordNoNumber = false
        this.validations.passwordNoLowerCaseLetter = false
        this.validations.passwordNoUpperCaseLetter = false
        this.validations.passwordNoSpecialCharacter = false
        this.validations.passwordsDontMatch = false
        this.validations.firstNameIsBlank = false
        this.validations.lastNameIsBlank = false
        this.validations.emailIsBlank = false

        // Do the validations
        if (this.password.length < 8) {
          this.validations.passwordTooShort = true
          invalid = true
        }
        if (!/\d/.test(this.password)) {
          this.validations.passwordNoNumber = true
          invalid = true
        }
        if (this.password.toUpperCase() === this.password) {
          this.validations.passwordNoLowerCaseLetter = true
          invalid = true
        }
        if (this.password.toLowerCase() === this.password) {
          this.validations.passwordNoUpperCaseLetter = true
          invalid = true
        }
        // eslint-disable-next-line
        if (!(/[\*\.\!\@\#\$\%\^\&\(\)\{\}\[\]\:\"\;\'\<\>\,\.\?\/\~\`\_\+\-\=\|\\]/gm.test(this.password))) {
          this.validations.passwordNoSpecialCharacter = true
          invalid = true
        }
        if (this.password !== this.confirmPassword) {
          this.validations.passwordsDontMatch = true
          invalid = true
        }
        if (this.firstName.length < 1) {
          this.validations.firstNameIsBlank = true
          invalid = true
        }
        if (this.lastName.length < 1) {
          this.validations.lastNameIsBlank = true
          invalid = true
        }
        if (this.email.length < 1) {
          this.validations.emailIsBlank = true
          invalid = true
        }

        // If it's invalid, don't go any further
        if (invalid) {
          this.isSigningUp = false
          return
        }

        // If everything is valid, create the user
        try {
          // Attempt to sign the user in using Cognito
          const parameters = {
            username: this.email,
            password: this.password,
            attributes: {
              given_name: this.firstName,
              family_name: this.lastName,
              email: this.email
            }
          }
          await AuthService.SignUp(parameters);
          // On success, print the details (for debugging)
          //console.log('user', user);
          //alert('Successfully signed up');
          this.hasCompletedSignUp = true
          this.isSigningUp = false
        } catch (error) {
          // On error, alert the user
          console.warn("Error creating user account;", error)
          //alert(`Unable to sign up: ${error.message}`);
          if (error.code === 'UsernameExistsException') {
            this.messageDialog.message = 'An account with this email address already exists.  Please return to the login page and try signing in instead.'
            this.messageDialog.show = true
            this.isSigningUp = false
            return
          }
          // Other errors
          this.messageDialog.message = 'There was an error creating your account.  Please try signing up again.'
          this.messageDialog.show = true
          this.isSigningUp = false
        }
      },
      GoToLoginPage() {
        this.hasCompletedSignUp = false
        // Ask the router to navigate to the user landing page
        this.$router.push({ name: 'Login' })
      },
      async GoogleSignIn(e) {
        console.log("GoogleSignIn:", e)
        await Auth.federatedSignIn({ provider: 'Google' })
      },
      CloseMessageDialog() {
        this.messageDialog = {
          show: false,
          message: ""
        }
      },
    },

    computed: {
      logoImage: function() {
        let imageName = "logo"
        if (this.isFISHost) { imageName = "logo-fis" }
        return require(`@/assets/images/${imageName}.png`)
      }
    },
  }
</script>


<style scoped>

  .social-button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
  }

  .google-button {
    margin: auto;
  }

</style>

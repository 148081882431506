import Vue from 'vue'
import Vuex from 'vuex'

import { LAYOUTS } from '@/constants/LayoutConstants'
import { BOOKING_ATTENDANCE, BOOKING_STATUS, BOOKING_CERTIFICATE_STATUS } from '@/constants/BookingConstants'
import { EVENT_STATUS, EVENT_TYPE } from '@/constants/EventConstants'
import { PORTAL_MODE } from '@/constants/PortalConstants'
import { EventDurationInMinutes, EventCPDDurationInMinutes } from '@/helpers/event.helper'

Vue.use(Vuex)

const DEFAULT_AUTH_PAGE_STATE = {
  name: null,
  path: "",
  hash: "",
  fullPath: "",
  params: {},
  meta: {},
  query: {},
  matched: []
}

// STATE
const state = {
  layout: LAYOUTS.NOT_SIGNED_IN,
  mode: PORTAL_MODE.USER,
  lastRequiresAuthPage: {
    ...DEFAULT_AUTH_PAGE_STATE
  },
  cognito: {
    attributes: {},
    signInUserSession: {
      accessToken: {
        payload: {
          ['cognito:groups']: []
        }
      }
    },
    error: null
  },
  user: {
    error: null
  },
  employer: {
    logo: {},
    error: null
  },
  currentEmployeeRole: {
    roleName: null,
    accessRights: {
      isAdministrator: false,
      isCourseInstructor: false
    }
  },
  bookings: {
    items: [],
    error: null
  },
  pastevents: {
    items: [],
    nextToken: null,
    error: null
  },
  futureevents: {
    items: [],
    nextToken: null,
    error: null
  },
  uploadedevents: {
    items: [],
    nextToken: null,
    error: null
  },
  notifications: {
    items: [],
    nextToken: null,
    error: null
  },
  issuedExperience: {
    items: [],
    nextToken: null,
    error: null
  },
  providerList: []
}

// MUTATIONS
const mutations = {

  SET_LAYOUT(state, payload) {
    state.layout = payload
  },
  SET_LAST_REQUIRES_AUTH_PAGE(state, payload) {
    state.lastRequiresAuthPage = payload
  },
  RESET_LAST_REQUIRES_AUTH_PAGE(state) {
    state.lastRequiresAuthPage = {
      ...DEFAULT_AUTH_PAGE_STATE
    }
  },
  SET_COGNITO(state, payload) {
    state.cognito = {
      ...payload,
      error: null
    }
  },
  RESET_COGNITO(state) {
    state.cognito = {
      error: null
    }
  },
  //eslint-disable-next-line
  RESET_AFTER_LOGOUT(state) {
    //console.log(state)
    state = {
      layout: LAYOUTS.NOT_SIGNED_IN,
      mode: PORTAL_MODE.USER,
      cognito: {
        attributes: {},
        error: null
      },
      user: {
        error: null
      },
      employer: {
        error: null
      },
      currentEmployeeRole: {
        roleName: null,
        accessRights: {
          isAdministrator: false,
          isCourseInstructor: false
        }
      },
      bookings: {
        items: [],
        error: null
      },
      pastevents: {
        items: [],
        nextToken: null,
        error: null
      },
      futureevents: {
        items: [],
        nextToken: null,
        error: null
      },
      uploadedevents: {
        items: [],
        nextToken: null,
        error: null
      },
      notifications: {
        items: [],
        nextToken: null,
        error: null
      },
      issuedExperience: {
        items: [],
        nextToken: null,
        error: null
      }
    }
  },
  SET_FUTURE_EVENTS(state, payload) {
    //console.log("SET_FUTURE_EVENTS: Payload:", payload)
    state.futureevents = {
      ...state.futureevents,
      ...payload
    }
  },
  EXTEND_FUTURE_EVENTS(state, payload) {
    //console.log("EXTEND_FUTURE_EVENTS: Payload:", payload)
    state.futureevents = {
      ...state.futureevents,
      ...payload,
      items: [
        ...state.futureevents.items,
        ...payload.items
      ]
    }
  },
  SET_ISSUED_EXPERIENCE_CERTIFICATES(state, payload) {
    //console.log("SET_ISSUED_EXPERIENCE_CERTIFICATES: Payload:", payload)
    state.issuedExperience = {
      ...state.issuedExperience,
      ...payload
    }
  },
  EXTEND_ISSUED_EXPERIENCE_CERTIFICATES(state, payload) {
    //console.log("EXTEND_ISSUED_EXPERIENCE_CERTIFICATES: Payload:", payload)
    state.issuedExperience = {
      ...state.issuedExperience,
      ...payload,
      items: [
        ...state.issuedExperience.items,
        ...payload.items
      ]
    }
  },
  SET_UPLOADED_EVENTS(state, payload) {
    //console.log("SET_UPLOADED_EVENTS: Payload:", payload)
    state.uploadedevents = {
      ...state.uploadedevents,
      ...payload
    }
  },
  EXTEND_UPLOADED_EVENTS(state, payload) {
    //console.log("EXTEND_UPLOADED_EVENTS: Payload:", payload)
    state.uploadedevents = {
      ...state.uploadedevents,
      ...payload,
      items: [
        ...state.uploadedevents.items,
        ...payload.items
      ]
    }
  },
  SET_PAST_EVENTS(state, payload) {
    //console.log("SET_PAST_EVENTS: Payload:", payload)
    state.pastevents = {
      ...state.pastevents,
      ...payload
    }
  },
  EXTEND_PAST_EVENTS(state, payload) {
    //console.log("EXTEND_PAST_EVENTS: Payload:", payload)
    state.pastevents = {
      ...state.pastevents,
      ...payload,
      items: [
        ...state.pastevents.items,
        ...payload.items
      ]
    }
  },
  SET_BOOKINGS(state, payload) {
    //console.log("SET_BOOKINGS: Payload:", payload)
    state.bookings = {
      ...state.bookings,
      ...payload
    }
  },
  EXTEND_BOOKINGS(state, payload) {
    //console.log("EXTEND_BOOKINGS: Payload:", payload)
    state.bookings = {
      ...state.bookings,
      ...payload,
      items: [
        ...state.bookings.items,
        ...payload.items
      ]
    }
  },
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = {
      ...state.notifications,
      ...payload,
      error: null
    }
  },
  SET_USER_DETAILS(state, payload) {
    // Set the user state, merging in the new details
    state.user = {
      ...state.user,
      ...payload,
      error: null
    }
  },
  SET_CUSTOM_GET_USER_DETAILS(state, payload) {
    //console.log("SET_CUSTOM_GET_USER_DETAILS: Payload:", payload)
    // Set the user state with everything
    state.user = {
      ...state.user,
      ...payload,
      error: null
    }
    // Try to destructure the employer part of the call
    try {
      const employeeRole = payload.employer.items[0]
      const employer = employeeRole.employer

      state.employer = {
        ...state.employer,
        ...employer,
        roleName: employeeRole.roleName,
        error: null
      }

      state.currentEmployeeRole = employeeRole

    } catch (error) {
      //console.warn("Error getting employer from user details", error)
      state.employer = {
        error
      }
    }
    //console.log("employer set successfully")
    // Try to destructure the bookings part of the call
    try {
      state.bookings = {
        ...payload.bookings
      }
    } catch (error) {
      //console.warn("Error getting bookings from user details", error)
      state.bookings = {
        items: [],
        error
      }
    }
  },
  SET_ACCREDITATION_LOGOS(state, payload) {
    // Set the user state with everything
    state.employer.accreditationLogos = payload
  },
  SET_EMPLOYER(state, payload) {
    // Set the employer state, merging in the new details
    state.employer = {
      ...state.employer,
      ...payload,
      error: null
    }
  },
  SET_EMPLOYER_LOGO(state, payload) {
    // Set the employer logo state
    state.employer.logo = {
      ...state.employer.logo,
      ...payload
    }
  },
  SET_PROVIDER_LIST(state, payload) {
    state.providerList = [
      ...payload
    ]
  },
}

const actions = {

}

const getters = ({
  layout(state) {
    return state.layout
  },
  lastRequiresAuthPage(state) {
    return state.lastRequiresAuthPage
  },
  cognito(state) {
    return state.cognito
  },
  user(state) {
    return state.user
  },
  currentEmployeeRole(state) {
    return state.currentEmployeeRole
  },
  employer(state) {
    return state.employer
  },
  bookings(state) {
    return state.bookings
  },
  pastevents(state) {
    return state.pastevents
  },
  futureevents(state) {
    return state.futureevents
  },
  uploadedevents(state) {
    return state.uploadedevents
  },
  issuedExperience(state) {
    return state.issuedExperience
  },
  providerList(state) {
    return state.providerList
  },
  notifications(state) {
    return {
      ...state.notifications,
      items: state.notifications.items.sort((a, b) => {
        return new Date(b.updatedAt) < new Date(a.updatedAt) ? -1 : 1;
      })
    }
  },
  completedMinutes(state, getters) {
    const filteredBookingsAttendedOnly = getters.bookings.items.filter((booking) => {
      return booking.attendance === BOOKING_ATTENDANCE.ATTENDED
    })
    //console.log("filteredBookingsAttendedOnly:", filteredBookingsAttendedOnly)
    const minutesArrayFromAttendedSessons = filteredBookingsAttendedOnly.map((booking) => {
      return EventDurationInMinutes(booking.event)
    })
    //console.log("minutesArrayFromAttendedSessons:", minutesArrayFromAttendedSessons)
    if (minutesArrayFromAttendedSessons.length === 0) { return 0 }
    return minutesArrayFromAttendedSessons.reduce((a,b)=>a+b)
  },
  // This will be a problem at some point because any attendee with over 100 courses completed won't have all their records
  // fetched and therefore the number will be off.
  // TODO: Calculate the minutes and store against the user in the database rather than calculating on the fly
  plannedMinutes(state, getters) {
    const filteredBookingsNotYetHeldOnly = getters.bookings.items.filter((booking) => {
      return booking.attendance === BOOKING_ATTENDANCE.EVENTNOTYETHELD && booking.status !== BOOKING_STATUS.CANCELLED
    })
    const minutesArrayFromUpcomingSessons = filteredBookingsNotYetHeldOnly.map((booking) => {
      return EventDurationInMinutes(booking.event)
    })
    if (minutesArrayFromUpcomingSessons.length === 0) { return 0 }
    return minutesArrayFromUpcomingSessons.reduce((a,b)=>a+b)
  },
  cpdAccreditedMinutes(state, getters) {
    const filteredBookingsAttendedOnly = getters.bookings.items.filter((booking) => {
      return booking.attendance === BOOKING_ATTENDANCE.ATTENDED
    })
    const minutesArrayFromAttendedSessons = filteredBookingsAttendedOnly.map((booking) => {
      return EventCPDDurationInMinutes(booking.event)
    })
    if (minutesArrayFromAttendedSessons.length === 0) { return 0 }
    return minutesArrayFromAttendedSessons.reduce((a,b)=>a+b)
  },
  upcomingBookings(state, getters) {
    return getters.bookings.items.filter((booking) => {
      const notYetHeld = booking.attendance === BOOKING_ATTENDANCE.EVENTNOTYETHELD
      const validBookingStatus = [BOOKING_STATUS.BOOKED].includes(booking.status)
      const bookingInTheFuture = booking.event.startDateTime > (new Date()).toISOString()
      return notYetHeld && validBookingStatus && bookingInTheFuture
    })
  },
  pastBookings(state, getters) {
    const pastInternalBookings = getters.bookings.items.filter((booking) => {
      const bookingIsInThePast = new Date(booking.event.startDateTime) < new Date()
      const bookingIsForConference = booking.event.eventType === EVENT_TYPE.CONFERENCE
      const bookingHasAttendanceThatShouldShow = [ BOOKING_ATTENDANCE.ATTENDED, BOOKING_ATTENDANCE.EVENTNOTYETHELD ].includes(booking.attendance)
      const bookingHasCertificateStatusThatShouldShow = [ BOOKING_CERTIFICATE_STATUS.SENTDIGITALLY, BOOKING_CERTIFICATE_STATUS.SENTPHYSICALLY ].includes(booking.certificateSent)

      const bookingForNotConferenceAndShouldShow = !bookingIsForConference && bookingHasAttendanceThatShouldShow
      const bookingForConferenceAndHasCertificate = bookingIsForConference && bookingHasCertificateStatusThatShouldShow

      return bookingIsInThePast && ( bookingForNotConferenceAndShouldShow || bookingForConferenceAndHasCertificate )
    })
    const uploadedEventRecords = getters.uploadedevents.items.map((pastEvent) => {
      const eventStartDateTime = new Date(pastEvent.eventDateTime)
      const eventEndDateTime = new Date(pastEvent.eventDateTime)
      eventEndDateTime.setHours(eventEndDateTime.getHours() + pastEvent.cpdHours)
      return {
        id: pastEvent.id,
        attendance: "ATTENDED",
        status: "COMPLETED",
        certificateSent: "SENTDIGITALLY",
        fileName: pastEvent.fileName,
        event: {
          title: pastEvent.title,
          startDateTime: eventStartDateTime.toISOString(),
          endDateTime: eventEndDateTime.toISOString()
        },
        isUploaded: true,
        isExperience: false,
        userRecord: pastEvent
      }
    })
    const experienceCertificates = getters.user.experienceRecords.items.map((experienceCertificate) => {
      return {
        id: experienceCertificate.id,
        attendance: "ATTENDED",
        status: "COMPLETED",
        certificateSent: "SENTDIGITALLY",
        event: {
          title: experienceCertificate.title,
          startDateTime: experienceCertificate.startDateTime,
          endDateTime: experienceCertificate.endDateTime
        },
        isUploaded: false,
        isExperience: true,
        userRecord: experienceCertificate
      }
    })

    return pastInternalBookings.concat(uploadedEventRecords).concat(experienceCertificates)
  },
  providerFutureLiveEvents(state, getters) {
    return getters.futureevents.items.filter((event) => {
      return event.status === EVENT_STATUS.LIVE
    })
  },
  providerEventsFuture(state, getters) {
    return getters.futureevents.items
  },
  providerEventsPast(state, getters) {
    return getters.pastevents.items
  },
  // Return the next 10 events
  providerUpcomingEvents(state, getters) {
    return getters.providerFutureLiveEvents.sort((a, b) => {
      return a.startDateTime > b.startDateTime
    }).slice(0, 10)
  },
  userFutureLiveEvents(state, getters) {
    return getters.futureevents.items
  },
  lastAcceptedTandCDateTime(state, getters) {
    try {
      if (getters.user.lastAcceptedTandCDateTime) {
        return getters.user.lastAcceptedTandCDateTime
      }
      return "2000-01-01T00:00:00.000Z"
    } catch (err) {
      return "2000-01-01T00:00:00.000Z"
    }
  },
  pendingEmployeeRoles(state, getters) {
    try {
      // TODO: Probably not required, but handle nextToken
      return getters.user.pendingEmployeeRoles.items
    } catch (err) {
      return []
    }
  },
  providerIssuedExperience(state, getters) {
    try {
      return getters.issuedExperience.items
    } catch (err) {
      console.warn("Error when returning issued experience certificates;", err)
      return []
    }
  }
})

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})

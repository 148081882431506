
import { API, graphqlOperation } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
//import * as Queries from '../graphql/queries';
import * as Mutations from '../graphql/mutations';
import * as MutationsCustom from '../graphql/mutationsCustom';
import { BOOKING_STATUS, BOOKING_ATTENDANCE, BOOKING_CERTIFICATE_STATUS } from '../constants/BookingConstants'

const BookingService = {
  // Delete the booking
  CancelBooking(parameters) {
    console.log("Requesting delete booking")
    console.log(parameters)
    const queryParameters = {
      input: {
        ...parameters,
        status: BOOKING_STATUS.CANCELLED
      }
    }
    return API.graphql(graphqlOperation(MutationsCustom.updateEventBooking, queryParameters));
  },


  /**
   * CreateBooking returns a graphql promise to create a booking
   * @param  {string} userId The userId who wishes to book onto the event
   * @param  {string} eventId The eventId that the user wishes to book
   * @param  {string} providerId The ID of the provider of the event
   * @param  {string} extraParameters Any other parameters that the booking table can accept
   * @return {promise} The graphql promise to create an event booking
   */
  CreateBooking(userId, eventId, providerId, extraParameters) {
    console.log("Requesting create booking")
    console.log("userId:", userId)
    console.log("eventId:", eventId)
    console.log("providerId:", providerId)
    console.log("extraParameters:", extraParameters)
    if (!extraParameters) {
      extraParameters = {}
    }
    const queryParameters = {
      input: {
        id: uuidv4(),
        userId,
        eventId,
        status: BOOKING_STATUS.BOOKED,
        attendance: BOOKING_ATTENDANCE.EVENTNOTYETHELD,
        certificateSent: BOOKING_CERTIFICATE_STATUS.NOTSENT,
        bookingDateTime: (new Date()).toISOString(),
        groupsCanRead: ["USER"],
        groupsFullControl: [providerId],
        ...extraParameters
      }
    }
    return API.graphql(graphqlOperation(MutationsCustom.createEventBooking, queryParameters));
  },


  /**
   * CreateWaitingListBooking returns a graphql promise to create a booking
   * @param  {object} parameters A POJO containing two keys; userId and eventId
   * @param  {string} providerId The ID of the provider of the event
   * @return {promise} The graphql promise to create an event booking on the waiting list
   */
  CreateWaitingListBooking(parameters, providerId) {
    console.log("Requesting create booking")
    console.log(parameters)
    const queryParameters = {
      input: {
        ...parameters,
        id: uuidv4(),
        status: BOOKING_STATUS.ONWAITINGLIST,
        attendance: BOOKING_ATTENDANCE.EVENTNOTYETHELD,
        certificateSent: BOOKING_CERTIFICATE_STATUS.NOTSENT,
        bookingDateTime: (new Date()).toISOString(),
        groupsCanRead: ["USER"],
        groupsFullControl: [providerId]
      }
    }
    return API.graphql(graphqlOperation(MutationsCustom.createEventBooking, queryParameters));
  },


  /**
   * UpdateBookingStatus returns a graphql promise to update an event booking
   * @param  {object} parameters A POJO containing the id and details to update
   * @return {promise} The graphql promise to update an event booking
   */
  UpdateBookingStatus(parameters) {
    console.log("Requesting update booking status")
    console.log(parameters)
    // If this is a manual user, don't do anything
    if (parameters.id === "manual") { return "SUCCESS" }
    // Build the query parameters
    const queryParameters = {
      input: {
        ...parameters,
      }
    }
    // Send the query
    return API.graphql(graphqlOperation(MutationsCustom.updateEventBooking, queryParameters));
  },


  /**
   * SendCertificatesToAttendees returns a graphql promise to send a certificate to an attendee
   * NOTE: All booking IDs must be from the same event!!!
   * @param  {array} eventId    The event ID that all these bookings are for
   * @param  {array} bookingIds The booking IDs to send certificates for
   * @return {promise} The graphql promise to send certificates to attendees
   */
  async SendCertificatesToAttendees(eventId, bookingIds) {
    console.log("Requesting sending certificate to attendee")
    const parameters = {
      eventId,
      bookingIds
    }
    console.log(parameters)
    return API.graphql(graphqlOperation(Mutations.sendCertificatesToAttendee, parameters));
  },


  /**
   * GetCertificateForConference returns a graphql promise to send a certificate to an attendee
   * @param  {string} eventId The eventId that was found from the code used by the user
   * @param  {string} bookingId The bookingId that the user has for this event
   * @param  {string} code The code that the user entered
   * @return {promise} The graphql promise to return from the function
   */
  async GetCertificateForConference(eventId, bookingId, code) {
    console.log("Requesting a certificate for a conference")
    console.log("EventId and Code:", eventId, code)
    const parameters = {
      eventId,
      bookingId,
      code
    }
    return await API.graphql(graphqlOperation(Mutations.getCertificateForConference, parameters));
  },




}

export default BookingService

import { render, staticRenderFns } from "./eventlogo-container.vue?vue&type=template&id=00e63379&scoped=true"
import script from "./eventlogo-container.vue?vue&type=script&lang=js"
export * from "./eventlogo-container.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00e63379",
  null
  
)

export default component.exports
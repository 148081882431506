
export const createEmployeeGroupAllocation = /* GraphQL */ `
  mutation CreateEmployeeGroupAllocation(
    $input: CreateEmployeeGroupAllocationInput!
    $condition: ModelEmployeeGroupAllocationConditionInput
  ) {
    createEmployeeGroupAllocation(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteEmployeeGroupAllocation = /* GraphQL */ `
  mutation DeleteEmployeeGroupAllocation(
    $input: DeleteEmployeeGroupAllocationInput!
    $condition: ModelEmployeeGroupAllocationConditionInput
  ) {
    deleteEmployeeGroupAllocation(input: $input, condition: $condition) {
      id
    }
  }
`;
<template>
  <v-container>
    <loading :active="!is_data_fetched" :is-full-page="true" loader="dots"></loading>
    <loading :active="is_loading" :is-full-page="true" loader="dots"></loading>
    <v-layout column pa-0 ma-0>
      <v-flex md1>
        <v-container pa-0 pl-0 pb-3>
          <v-layout pa-0>
            <v-flex xs12 mt-4>
              <h5 class="page-header">Issue Experience</h5>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
      <v-flex md11 pa-4 class='experience-builder-container'>

        <v-form ref="form" lazy-validation>
          <form class="grid-form-two-column">

              <label class="control-label" for="title">Experience Name <span class="red--text">*</span></label>
              <div class='input-container'>
                <input type="text" class="form-control" id="title" v-model="values.title">
                <div v-show="showCurrentErrors && $v.values.title.$error" class="text-danger warning-holder">
                  <small v-if="!$v.values.title.required">Experience name is required</small>
                  <small v-if="!$v.values.title.maxLength">Experience name should be less than 100 characters</small>
                </div>
              </div>

              <label class="control-label" for="description">Additional Information</label>
              <div class='input-container'>
                <textarea class="form-control" id="description" :rows="5" v-model="values.description" />
                <div v-show="showCurrentErrors && $v.values.description.$error" class="text-danger warning-holder">
                  <small v-if="!$v.values.description.maxLength">Experience description should be less than 600 characters</small>
                </div>
              </div>

              <label class="control-label" for="startDateTime">Start Date <span class="red--text">*</span></label>
              <div class='input-container'>
                <input type="date" class="form-control" id="startDateTime" v-model="startDateOnly">
                <div v-show="showCurrentErrors && $v.values.startDateTime.$error" class="text-danger warning-holder">
                  <small v-if="!$v.values.startDateTime.required">Start date is required</small>
                  <small v-if="!$v.values.startDateTime.pastDateTime">Start date must be in the past</small>
                </div>
              </div>

              <label class="control-label" for="endDateTime">End Date <span class="red--text">*</span></label>
              <div class='input-container'>
                <input type="date" class="form-control" id="endDateTime" v-model="endDateOnly">
                <div v-show="showCurrentErrors && $v.values.endDateTime.$error" class="text-danger warning-holder">
                  <small v-if="!$v.values.endDateTime.required">End date is required</small>
                  <small v-if="!$v.values.endDateTime.todayOrEarlierDateTime">End date must end today or in the past</small>
                </div>
              </div>
          </form>
        </v-form>

        <!-- Professional Table -->

        <v-data-table
          id="professionalTable"
          class="table"
          :headers="PROFESSIONAL_TABLE_HEADER"
          :items="professionalList"
          :pagination.sync="professionalTablePagination"
          no-data-text="No professionals added"
          hide-actions>

          <template slot="headerCell" slot-scope="{ header }">
            <span class="subheading font-weight-bold text--darken-3" v-text="header.text" />
          </template>

          <template v-slot:items="props">
            <td>
              <input type="text"  class="form-control" placeholder="First Name" v-model="professionalList[props.index].firstName" />
            </td>
            <td>
              <input type="text"  class="form-control" placeholder="Last Name" v-model="professionalList[props.index].lastName" />
            </td>
            <td>
              <input type="email" class="form-control" placeholder="Email Address" v-model="professionalList[props.index].emailAddress" />
            </td>
            <td style="text-align: center">
              <v-img :src="require('@/images/trash32.png')" style="margin: auto; margin-top: 6px; cursor: pointer;" contain height="15px" width="15px" @click="RemoveProfessional(props.index)"></v-img>
            </td>
          </template>
        </v-data-table>

        <v-layout nowrap pt-3 pr-3>
          <v-flex xs12 text-right>
            <v-btn color="rgb(0,171,173)" small @click="AddProfessionalRow" class='add-button'>Add Professional</v-btn>
            <v-pagination style="vertical-align:top;" v-model="professionalTablePagination.page" :length="professionalTablePagination.pages"></v-pagination>
          </v-flex>
        </v-layout>

      </v-flex>
    </v-layout>

    <div style="background: #fff">
      <v-layout nowrap pb-3 pr-3>
        <v-flex xs12 class="text-right">
          <v-btn color="rgb(254,138,125)" small @click="Cancel">
            Back
          </v-btn>
          <v-btn color="rgb(0,171,173)" small @click="DownloadAttendeeCSVTemplate" style="margin-right: 0">
            Download List Template
          </v-btn>
          <v-btn color="rgb(0,171,173)" small @click="OpenUploadAttendeesCSV" style="margin-right: 0">
            Upload List
          </v-btn>
          <input ref="uploader" class="d-none" type="file" accept=".csv" @change="ProcessSubjectCSVFile">
          <v-btn color="rgb(0,171,173)" small @click="PreviewCertificate" style="margin-right: 0">
            Preview Certificate
          </v-btn>
          <v-btn color="rgb(0,171,173)" small @click="IssueCertificates" style="margin-right: 0" :disabled="IssueButtonDisabled">
            Issue Certificates
          </v-btn>
        </v-flex>
      </v-layout>
    </div>

    <v-dialog persistent v-model="messageDialog.show" transition="dialog-transition" max-width="450">
      <v-card>
        <v-card-text>
          <v-flex v-html='messageDialog.message'></v-flex>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CloseMessageDialog" small color=rgb(0,171,173)>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>

  import CSV from 'csvtojson'
  import { v4 as uuidv4 } from 'uuid';

  import { LAYOUTS } from '@/constants/LayoutConstants'
  import { validationMixin } from 'vuelidate'
  import { required, /*requiredIf, minLength,*/ maxLength, /*integer*/ } from 'vuelidate/lib/validators'
  import { pastDateTime, todayOrEarlierDateTime } from '@/constants/Validations'
  import ExperienceCertificateService from '@/services/experienceCertificate.service'
  import ExperienceRecordService from '@/services/experienceRecord.service'

  const PROFESSIONAL_TABLE_HEADER = [
    { text: 'First Name',       align: 'left',    value: 'firstName',     sortable: true                 },
    { text: 'Last Name',        align: 'left',    value: 'lastName',      sortable: true                 },
    { text: 'Email',            align: 'left',    value: 'emailAddress',  sortable: true                 },
    { text: 'Action',           align: 'center',  value: 'action',        sortable: false,  width: "1%"  }
  ]

  const DEFAULT_NEW_SUBJECT = {
    firstName: null,
    lastName: null,
    emailAddress: null
  }

  const DEFAULT_CERTIFICATE_ID = "37cfdcd6-7761-4577-8712-aaaaaaaaa001"

  export default {

    components: {},

    props: {
      copyDetails: {
        type: Object,
        required: false,
        default: function() { return null }
      }
    },

    data() {

      const todayStart = new Date()
      todayStart.setHours(0,0,0,0)
      const todayEnd = new Date()
      todayEnd.setHours(23,59,59,999)

      return {
        is_data_fetched: false,
        is_loading: false,
        showCurrentErrors: false,
        professionalTablePagination: {
          rowsPerPage: 10,
          totalItems: 0,
          pages: 1,
          sortBy: "firstName"
        },
        values: {
          experienceEventId: null,
          title: null,
          description: null,
          startDateTime: todayStart.toISOString(),
          endDateTime: todayEnd.toISOString(),
          certificateTemplateId: DEFAULT_CERTIFICATE_ID,
          subjects: []
        },
        messageDialog: {
          show: false,
          message: '',
          navigateToExperiencePage: false
        },
        PROFESSIONAL_TABLE_HEADER
      }
    },

    mixins: [ validationMixin ],
    validations: {
      values: {
        title: { required, maxLength: maxLength(100) },
        description: { maxLength: maxLength(600) },
        startDateTime: { required, pastDateTime },
        endDateTime: { required, todayOrEarlierDateTime }
      }
    },

    watch: {},

    methods: {
      Cancel() {
        this.$router.push({
          name: `${ this.$store.getters.layout === LAYOUTS.ADMIN ? 'AdminAllExperience' : 'ProviderAllExperience' }`
        });
      },
      RemoveProfessional(index) {
        this.values.subjects.splice(index, 1);
      },
      AddProfessionalRow() {
        this.values.subjects = this.values.subjects.concat([ { ...DEFAULT_NEW_SUBJECT } ])
      },
      DownloadAttendeeCSVTemplate() {
        const headerRow = "data:text/csv;charset=utf-8,firstName,lastName,emailAddress\r\n"
        var csvContent = headerRow
        var encodedUri = encodeURI(csvContent);
        // Encode the octothorpes because they cause the output to be truncated
        encodedUri = encodedUri.replace(/#/g, "%23")
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `Experience Professional Upload Template.csv`);
        document.body.appendChild(link); // Required for FF
        link.click(); // This will download the data file
        document.body.removeChild(link);
      },
      OpenUploadAttendeesCSV() {
        this.is_loading = true
        window.addEventListener('focus', () => {
          this.is_loading = false
        }, { once: true })
        this.$refs.uploader.click()
      },
      async PreviewCertificate() {
        this.showCurrentErrors = false
        this.is_loading = true
        // Check if the fields are valid
        this.$v.$touch();
        if (this.$v.$invalid) {
          console.warn("Invalid fields:", this.$v)
          this.showCurrentErrors = true
          this.is_loading = false
          return
        }
        // Build the parameters
        const parameters = {
          providerId: this.$store.getters.employer.id,
          issueDateTime: new Date().toISOString(),
          ...this.values
        }
        try {
          const experienceCertificateService = new ExperienceCertificateService(this.$store)
          const result = await experienceCertificateService.GetPreviewCertificate(parameters)
          // If there was no response, there was an error
          if (!result) {
            this.messageDialog.message = 'There was an error creating the example certificate.  Please try again.'
            this.messageDialog.show = true
          }
        } catch (err) {
          console.warn("Error getting example experience certificate:", err)
          this.messageDialog.message = 'There was an error creating the example certificate.  Please try again.'
          this.messageDialog.show = true
        }
        this.is_loading = false
      },
      async IssueCertificates() {
        console.log("Issue Certificates")
        console.log("values:", this.values)
        this.showCurrentErrors = false
        this.is_loading = true
        // Check if the fields are valid
        this.$v.$touch();
        if (this.$v.$invalid) {
          console.warn("Invalid fields:", this.$v)
          this.showCurrentErrors = true
          this.is_loading = false
          return
        }
        const missingFirstName = this.professionalList.filter((professional) => !professional.firstName)
        const missingLastName = this.professionalList.filter((professional) => !professional.firstName)
        const missingEmailAddress = this.professionalList.filter((professional) => !professional.firstName)

        if (missingFirstName.length > 0 || missingLastName.length > 0 || missingEmailAddress.length > 0) {
          this.messageDialog.message = 'Please ensure all details are completed for all professionals.  Please delete any empty rows prior to proceeding to issue the certificates.'
          this.messageDialog.show = true
          this.is_loading = false
          return
        }

        // Build the parameters
        const parameters = {
          providerId: this.$store.getters.employer.id,
          issueDateTime: new Date().toISOString(),
          ...this.values,
          subjects: this.values.subjects.map((subject) => JSON.stringify(subject))
        }
        try {
          const experienceCertificateService = new ExperienceCertificateService(this.$store)
          const result = await experienceCertificateService.IssueCertificates(parameters)
          // If there was no response, there was an error
          if (!result) {
            this.messageDialog.message = 'There was an error issuing the experience certificates.  Please try again.'
            this.messageDialog.show = true
            this.is_loading = false
            return
          }
          // Delay by one second to allow database to update
          await new Promise(resolve => setTimeout(resolve, 1000));
          // Get the latest records from the database of issued certificates
          await new ExperienceRecordService(this.$store).GetExperienceRecordsForEmployee()
          this.messageDialog.message = 'Experience certificates issued successfully.'
          this.messageDialog.show = true
          this.messageDialog.navigateToExperiencePage = true
        } catch (err) {
          console.warn("Error issuing experience certificate:", err)
          this.messageDialog.message = 'There was an error issuing the experience certificates.  Please try again.'
          this.messageDialog.show = true
        }
        this.is_loading = false
      },
      ProcessSubjectCSVFile(fileEvent) {
        console.log("Process Subject CSV File")
        this.is_loading = false
        if (fileEvent.target.files.length === 0) {
          console.log("no file selected")
          return
        }
        const selectedFile = fileEvent.target.files[0]
        const reader = new FileReader();
        reader.onload = (fileString) => {
          this.UpdateSubjects(fileEvent, fileString.target.result);
        }
        reader.readAsText(selectedFile);
      },

      async UpdateSubjects(e, fileString) {
        // If the file does not match the template, reject it
        if (!fileString.startsWith("firstName,lastName,emailAddress")) {
          this.messageDialog.message = 'The uploaded file does not match the template file.<br /><br />Please download the template and populate it with the professionals details.'
          this.messageDialog.show = true
          e.target.value = ''
          return
        }
        // Convert the file string into a JSON object
        const result = await CSV({ noheader:false }).fromString(fileString)
        //console.log("CSV to JSON Result:", result)
        const filteredProfessionals = result.filter((row) => {
          return row.emailAddress !== "" && row.firstName !== "" && row.lastName !== ""
        })
        // If there are more attendees than 500, this cannot be uploaded by the user because the back end cannot handle this many requests
        if (filteredProfessionals.length > 500) {
          this.messageDialog.message = `The uploaded file contains too many professionals (Found in file: ${filteredProfessionals.length}; maximum: 500)<br /><br />If you need to upload more than 500 attendees to a single event, please email <a href='mailto:contact@mypropass.co.uk'>contact@mypropass.co.uk</a> to request a limit extension.`
          this.messageDialog.show = true
          e.target.value = ''
          return
        }
        // Check if all the users were correctly uploaded.
        if (result.length !== filteredProfessionals.length) {
          const difference = result.length - filteredProfessionals.length
          this.messageDialog.message = `${difference} of the attendees in the file was missing some of the required data.<br /><br />${filteredProfessionals.length} of ${result.length} attendees added.`
          this.messageDialog.show = true
        } else {
          this.messageDialog.message = `All attendees added successfully.<br /><br />${filteredProfessionals.length} attendees added.`
          this.messageDialog.show = true
        }
        // Save the new attendee list
        this.values.subjects = this.values.subjects.concat(filteredProfessionals)
        e.target.value = ''
      },

      async CloseMessageDialog() {
        this.messageDialog.show = false
        this.messageDialog.message = ''
        if (this.messageDialog.navigateToExperiencePage) {
          await new ExperienceRecordService(this.$store).GetExperienceRecordsForEmployee()
          this.messageDialog.navigateToExperiencePage = false
          this.Cancel()
        }
      },
    },

    computed: {
      professionalList: function() {
        // Made into computed property in case it needs some mapping later
        console.log("professionalList:", this.values.subjects)
        return this.values.subjects
      },
      startDateOnly: {
        get: function() { return this.values.startDateTime.split("T")[0] },
        set: function(newValue) { this.values.startDateTime = `${newValue}T00:00:00.000Z` }
      },
      endDateOnly: {
        get: function() { return this.values.endDateTime.split("T")[0] },
        set: function(newValue) { this.values.endDateTime = `${newValue}T23:59:59.999Z` }
      },
      IssueButtonDisabled: function() {
        if (!this.values.title) { return true }
        if (!this.values.description) { return true }
        if (this.professionalList.length === 0) { return true }
        /*
        const missingFirstName = this.professionalList.filter((professional) => !professional.firstName)
        if (missingFirstName.length > 0) { return true }
        const missingLastName = this.professionalList.filter((professional) => !professional.firstName)
        if (missingLastName.length > 0) { return true }
        const missingEmailAddress = this.professionalList.filter((professional) => !professional.firstName)
        if (missingEmailAddress.length > 0) { return true }
        */
        return false
      }
    },

    async mounted() {
      try {
        if (this.$route.params.copyDetails) {
          const { copyDetails } = this.$route.params
          this.values = {
            title: `${copyDetails.title}`,
            description: `${copyDetails.description}`,
            startDateTime: `${copyDetails.startDateTime}`,
            endDateTime: `${copyDetails.endDateTime}`,
            certificateTemplateId: DEFAULT_CERTIFICATE_ID,
            subjects: []
          }
        }
      } catch (err) {
        console.warn("Error when reading details from route params;", err)
      }
      this.values.experienceEventId = uuidv4()
      this.is_data_fetched = true;
    }

  }
</script>

<style scoped>

  .experience-builder-container {
    background: white;
  }

  .grid-form-two-column {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 10px;
    margin-bottom: 30px;
  }

  .input-container {
    display: flex;
    flex-direction: column;
  }

  .input-container > input[type="date"],
  .input-container > input[type="datetime-local"] {
    max-width: 300px;
  }

  .input-container > textarea {
    resize: vertical;
  }

  #professionalTable td {
    text-align: left;
  }

  .warning-holder {
    margin-top: 0.5em;
    display: flex;
    flex-direction: column;
  }

</style>

<template>
  <div class="image-uploader-container form-control" v-bind:class="{ showPicker: showPicker }" :style="componentStyle">
    <loading :active.sync="isLoading" :is-full-page="fullPage" loader="dots"></loading>

    <!-- The headings row -->
    <strong v-if="showPicker"><p> Current Image </p></strong>
    <strong><p> Image Options </p></strong>

    <!-- The existing image cell -->
    <div class="current-image-container" v-if="hasExistingImage && showPicker">
      <event-logo v-bind:imageKey="computedCurrentImageKey" level="public" imageStyle="height: 100%; width: 100%;" :imageTitle="computedCurrentImageName" />
    </div>
    <div class="current-image-container no-image" v-if="!hasExistingImage && showPicker">
      No Image Selected
    </div>

    <!-- The image gallery cell -->
    <div class="existing-image-options-container">
      <p v-show="imageOptions.length === 0">No images available</p>

      <strong><p v-show="imageOptions.length > 0 && showImageHideMode" class="hidden-images-label-first">Visible Images</p></strong>
      <p v-show="imageOptions.length > 0 && showImageHideMode" class="red--text">Click on these images to hide them</p>
      <div class="image-grid" v-show="imageOptions.length > 0">
        <div v-for="image in visibleImageOptions" :key="image.id" class="existing-image-holder" @click="OnImageSelected(image)">
          <event-logo v-bind:imageKey="image.imageKey" level="public" :imageStyle="GetImageStyle(image)" :imageTitle="image.name" />
        </div>
      </div>

      <strong><p v-show="imageOptions.length > 0 && showImageHideMode" class="hidden-images-label">Hidden images</p></strong>
      <p v-show="imageOptions.length > 0 && showImageHideMode" class="red--text">Click on these images to make them visible</p>
      <div class="image-grid" v-show="imageOptions.length > 0 && showImageHideMode">
        <div v-for="image in invisibleImageOptions" :key="image.id" class="existing-image-holder" @click="OnImageSelected(image)">
          <event-logo v-bind:imageKey="image.imageKey" level="public" :imageStyle="GetImageStyle(image)" :imageTitle="image.name" />
        </div>
      </div>
    </div>

    <!-- The button row -->
    <div class="button-row">
      <button type="button" v-if="!showImageHideMode" :disabled="disabled" class="file-button hide-image-button" v-on:click.prevent="ToggleHideImageMode()">Hide Images</button>
      <button type="button" v-if="showImageHideMode" :disabled="disabled" class="file-button hide-image-button" v-on:click.prevent="ToggleHideImageMode()">Exit Hide Mode</button>
      <div :class="getButtonClass">
        <p>Upload new image</p>
        <input
          id="file-upload-input"
          type="file"
          :disabled="disabled"
          @change="onFileSelected"
          style="opacity: 0.0; position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%;"
          accept="image/png, image/gif, image/jpeg" />
      </div>
    </div>

    <!-- The file details popover -->
    <v-dialog persistent v-model="showFileDetailsPopover" transition="dialog-transition" width="50vw">
      <v-card>
        <v-card-text>
          <div class="two-column-grid">
            <div style="font-weight:normal;">
              <img class="preview-image" :src="fileDetailsURL" alt="" />
            </div>
            <div class="image-detail-container">
              <h5>Image Label</h5>
              <input id="imageName" name="imageName" type="text" placeholder="Image Label" v-model='chosenFileName' />
            </div>
          </div>
        </v-card-text>
        <v-card-actions style="justify-content: flex-end;">
          <v-btn @click="CloseFileDetailsPopover" small color=rgb(0,171,173)>Cancel</v-btn>
          <v-btn @click="uploadFile" small color=rgb(0,171,173)>Upload</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="messageDialogPopover.show" transition="dialog-transition" max-width="500">
      <v-card>
        <v-card-text>
          <v-flex>
            <span style="font-weight:normal;">
              <div v-html="messageDialogPopover.message"></div>
            </span>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="CloseMessageDialog" small color=rgb(0,171,173)>Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import EventLogo from './eventlogo-container'
  import ImageService from '../services/image.service'
  import { IMAGE_TYPES, IMAGE_STATUS } from '../constants/ImageConstants'

  // TODO: SOON: Add delete functionality

  export default {

    // Props
    props: {
      pickerId: String,
      isPicker: Boolean,
      disabled: Boolean,
      closePopover: Function,
      onImageSelectionChange: Function,
      imageType: String,
      imageOptions: Array,
      currentImage: {
        id: String,
        name: String,
        imageKey: String
      },
      givenStyle: String,
    },

    // Imported Components
    components: {
      'event-logo': EventLogo,
    },

    // Component Data
    data() {
      return {
        isLoading: false,
        fullPage: true,
        componentStyle: "",
        showPicker: true,
        showFileDetailsPopover: false,
        messageDialogPopover: {
          show: false,
          message: ''
        },
        showImageHideMode: false,
        fileDetailsURL: "",
        chosenFile: null,
        chosenFileName: '',
        chosenFileEventTarget: null,
        IMAGE_TYPES
      }
    },

    // Some watchers for debugging
    watch: {
      /*
      imageOptions: function(newValue) {
        console.log("WATCHER: IMAGE UPLOADER: image options changed to:", newValue)
      },
      currentImage: function(newValue) {
        console.log("WATCHER: IMAGE UPLOADER: current image changed to:", newValue)
      },
      */
      isPicker: function(newValue) {
        //console.log("WATCHER: IMAGE UPLOADER: isPicker changed to:", newValue)
        // Set the picker section visibility; default true
        if(newValue === undefined) {
          this.showPicker = true
          return;
        }
        this.showPicker = newValue
      },
    },

    // Component Methods
    methods: {

      OnImageSelected(image) {
        // if the picker is disabled, don't do anything
        if (this.disabled) { return }
        // If the picker is in picking mode, pick the image
        if (!this.showImageHideMode) {
          this.onImageSelectionChange(this.pickerId, image.id, this.imageType, false)
          return
        }
        // If the picker is in hide mode, toggle the image visibility
        this.ToggleImageVisibility(image)
      },

      async onFileSelected(e) {
        console.log("event;", e)
        console.log("Files selected:", e.target.files)

        if (!e.target.files[0]) {
          //console.log("No file selected")
          e.target.value = ''
          return;
        }
        // Check if the image is too large to display.
        const fileSize = e.target.files[0].size
        if (fileSize > (2 * 1024 * 1024)) {
          this.messageDialogPopover.message = `The chosen image is too large (${(fileSize / 1024).toFixed(0)} KB).  The maximum size is 2048 KB (2 MB).  Please make the image smaller and attempt to upload again.`
          this.messageDialogPopover.show = true
          return;
        }
        this.showFileDetailsPopover = true;
        this.chosenFile = e.target.files[0]
        this.chosenFileEventTarget = e.target
        this.fileDetailsURL = URL.createObjectURL(e.target.files[0])
      },

      async uploadFile() {
        //console.log("File details:", this.chosenFile)
        try {
          this.isLoading = true;
          const imageService = new ImageService(this.$store)
          const imageKey = await imageService.UploadImage(this.chosenFile, this.chosenFileName, this.imageType)
          //console.log(imageKey)
          // Wait 1 second for the image to be updated in the database
          await new Promise(resolve => setTimeout(resolve, 1000));
          this.onImageSelectionChange(this.pickerId, imageKey, this.imageType, true)
          this.showFileDetailsPopover = false;
          this.chosenFileName = ''
          this.fileDetailsURL = ''
          this.chosenFile = null
        } catch (err) {
          console.warn("Error uploading image to the server;", err)
          this.messageDialogPopover.message = `There was an error uploading the image.  Please make the image smaller and attempt to upload again.`
          this.messageDialogPopover.show = true
        }
        this.isLoading = false;
      },

      CloseFileDetailsPopover() {
        this.showFileDetailsPopover = false;
        if (this.chosenFileEventTarget) {
          this.chosenFileEventTarget.value = ''
        }
        this.chosenFileName = ''
        this.fileDetailsURL = ''
        this.chosenFile = null
      },

      ToggleHideImageMode() {
        //console.log("Toggling hide mode")
        this.showImageHideMode = !this.showImageHideMode
      },

      GetImageStyle(image){
        if (this.showImageHideMode) {
          if (image.status !== IMAGE_STATUS.LIVE && !!image.status) {
            return "height: 50px; width: 50px; opacity: 0.5;"
          }
        }
        return "height: 50px; width: 50px;"
      },

      async ToggleImageVisibility(image) {
        //console.log("Toggling image visibility")
        this.isLoading = true;
        const imageService = new ImageService(this.$store)

        try {
          if (image.status === IMAGE_STATUS.HIDDEN) {
            await imageService.MakeImageVisible(image.id, this.imageType)
            image.status = IMAGE_STATUS.LIVE
            this.isLoading = false;
            return
          }
          if (image.status === IMAGE_STATUS.LIVE || !image.status) {
            await imageService.MakeImageHidden(image.id, this.imageType)
            image.status = IMAGE_STATUS.HIDDEN
            this.isLoading = false;
            return
          }
        } catch (err) {
          console.warn("Error when changing image status;", err)
          this.isLoading = false;
        }
      },

      CloseMessageDialog() {
        this.messageDialogPopover.message = ''
        this.messageDialogPopover.show = false
      }
    },

    // Component Computed Values
    computed: {
      hasExistingImage: function() {
        try {
          //console.log(this.currentImage)
          if (!this.currentImage) { return false }
          return this.currentImage.imageKey !== undefined
        } catch (err) {
          console.warn("Error getting current image url", err)
          return false
        }
      },
      computedCurrentImageKey: function() {
        try {
          return this.currentImage.imageKey ? this.currentImage.imageKey : ""
        } catch (err) {
          console.warn("Error getting current image url", err)
          return ""
        }
      },
      computedCurrentImageName: function() {
        try {
          return this.currentImage.name
        } catch (err) {
          console.warn("Error getting current image name", err)
          return ""
        }
      },
      getButtonClass: function() {
        return `file-button ${ this.disabled ? "disabled-button" : ""}`
      },
      visibleImageOptions: function() {
        const visibleImages = this.imageOptions.filter((image) => {
          return image.status === IMAGE_STATUS.LIVE || !image.status
        })
        return visibleImages
      },
      invisibleImageOptions: function() {
        const invisibleImages = this.imageOptions.filter((image) => {
          return !this.visibleImageOptions.includes(image)
        })
        return invisibleImages
      }
    },

    // Component Mounted Function
    async mounted() {
      //console.log("Props are:", this)
      if(this.givenStyle) {
        this.componentStyle = this.givenStyle
      }
      // Set the picker section visibility; default true
      if(this.isPicker === undefined) {
        this.showPicker = true
        return;
      }
      this.showPicker = this.isPicker
    }

  }

</script>

<style scoped>

  .image-uploader-container {
    border: 1px solid #ced4da;
    width: 100%;
    display: grid;
    grid-gap: 0 2em;
    grid-template-columns: 1fr;
    height: min-content;
    min-width: 250px;
  }

  .current-image-container {
    border: 1px dashed black;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .existing-image-options-container {

  }

  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 50px);
    grid-auto-rows: 50px;
  }

  .no-image {
    text-align: center;
    background-color: lightblue;
  }

  .button-row {
    grid-column-start: 1;
    grid-column-end: -1;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 1em;
  }

  .file-button {
    background-color: rgb(0, 171, 173) !important;
    border-color: rgb(0, 171, 173);
    color: white;
    align-items: center;
    border-radius: 2px;
    display: inline-flex;
    min-height: 36px;
    flex: 0 0 auto;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    margin: 6px 8px;
    outline: 0;
    text-transform: uppercase;
    text-decoration: none;
    transition: .3s cubic-bezier(.25,.8,.5,1),color 1ms;
    position: relative;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0 16px;
    width: 45%;
    text-align: center;
    max-width: 170px;
  }

  .file-button:disabled {
    background-color: #cccccc !important;
    color: white !important;
    border-color: #cccccc !important;;
  }

  .file-button p {
    margin: 0;
  }

  .hide-image-button {
    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(0, 171, 173);
    border: 1px solid rgb(0, 171, 173);
    color: black;
  }

  .existing-image-holder {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

  .preview-image {
    height: min(200px, 20vw);
    aspect-ratio: 1;
    border: 1px solid black;
  }

  .two-column-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 2em;
  }

  .image-detail-container {

  }

  .image-detail-container input {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  .showPicker {
    grid-template-columns: auto 1fr !important;
  }

  .disabled-button {
    background-color: #cccccc !important;
  }

  .hidden-images-label-first {
    padding-top: 0;
    margin-bottom: 0;
  }

  .hidden-images-label {
    padding-top: 1em;
    margin-bottom: 0;
  }

  .red--text {
    margin: 0;
    padding: 0;
  }

</style>

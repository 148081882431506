
export const CURRENT_DURATIONS = {
  Seconds: 'Seconds',
  Minutes: 'Minutes',
  Hours: 'Hours',
  Days: 'Days'
}

export const DESIRED_DURATIONS = {
  Seconds: 'Seconds',
  Minutes: 'Minutes',
  MinutesSeconds: 'Minutes_Seconds',
  Hours: 'Hours',
  HoursMinutes: 'Hours_Minutes',
  HoursMinutesSeconds: 'Hours_Minutes_Seconds',
  Days: 'Days',
  DaysHours: 'Days_Hours',
  DaysHoursMinutes: 'Days_Hours_Minutes',
  DaysHoursMinutesSeconds: 'Days_Hours_Minutes_Seconds',
  ClockTimeHoursMinutes: 'ClockTimeHoursMinutes'
}

const DURATION_SUFFIXES = {
  Seconds: 'Secs',
  Minutes: 'Mins',
  Hours: 'Hrs',
  Days: 'Days',
  Day: 'Day'
}

export const FormatDuration = (durationInMinutes, desiredUnit) => {

  if (!desiredUnit) {
    desiredUnit = DESIRED_DURATIONS.HoursMinutes
  }

  if (desiredUnit === DESIRED_DURATIONS.Days) {
    const days = Math.round(durationInMinutes / 1440, 0)
    return `${days} ${days === 1 ? DURATION_SUFFIXES.Day : DURATION_SUFFIXES.Days}`
  }
  if (desiredUnit === DESIRED_DURATIONS.Minutes) { return `${durationInMinutes} ${DURATION_SUFFIXES.Minutes}` }
  if (desiredUnit === DESIRED_DURATIONS.HoursMinutes) {
    const hours = Math.floor(durationInMinutes / 60)
    const minutes = durationInMinutes % 60
    return `${hours} ${DURATION_SUFFIXES.Hours} ${minutes} ${DURATION_SUFFIXES.Minutes}`
  }
  if (desiredUnit === DESIRED_DURATIONS.ClockTimeHoursMinutes) {
    var hours = `${Math.floor(durationInMinutes / 60)}`
    var minutes = `${durationInMinutes % 60}`
    if (hours.length === 1) {
      hours = `0${hours}`
    }
    if (minutes.length === 1) {
      minutes = `0${minutes}`
    }
    return `${hours}:${minutes}`
  }
  return ''
}

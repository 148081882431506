<template>
  <v-container v-if="is_data_fetched" class="vld-parent">
    <loading :active.sync="isLoading"
             :is-full-page="fullPage"
             loader="dots"></loading>
    <v-layout wrap justify-center>
      <v-flex xs9 md6>
        <div class="ABC_in_Scaffolding">
          <span>Issued Certificates</span><br />
          <dl class="d-list">
            <dt>Course Name:</dt><dd>{{ eventName }}</dd>
            <dt>Date of Course:</dt><dd>{{ eventDate }}</dd>
            <dt>Location:</dt><dd>{{ eventLocation }}</dd>
          </dl>
        </div>
      </v-flex>
      <v-flex xs3 md1 offset-md5 class="actions">
        <a>
          <img :src="require('../images/export.png')" @click="generatePdf" style="margin-bottom:5px;height:18px;width:18px" />
        </a>
        <!--
        <a>
          <img :src="require('../images/printer.png')" @click="print" style="margin-bottom:5px;height:18px;width:18px" />
        </a>
        -->
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card class="container-rounded-mini">
          <v-layout row wrap justify-center>
            <v-flex xs10>
              <h6 style="text-align:center;margin-top:10px;color: #273e4b"><b>Certificates Issued</b></h6>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center mt-3>
      <v-flex xs12>
        <v-data-table :headers="headers1" :items="certificatesIssued" hide-actions :pagination.sync="paginationCertificatesIssued" :custom-sort="CustomSort" class="table bordered-table">
          <template slot="headerCell" slot-scope="{ header }">
            <span class="subheading font-weight-bold text--darken-3" v-text="header.text" />
          </template>
          <template v-slot:items="props">
            <td>{{ props.item.seatNumber }}</td>
            <td>{{ props.item.user ? props.item.user.firstName : 'Unconfirmed' }}</td>
            <td>{{ props.item.user ? props.item.user.lastName : 'User' }}</td>
            <td>{{ props.item.id }}</td>
            <td>
              <a v-if="props.item.user" @click="GetCertificate(props.item)"><img src="../images/certificate.png" alt="Certificate" style="height:25px;width:25px;" /></a>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout class="mb-5">
      <v-flex xs12 text-right>
        <v-pagination style="vertical-align:top;" v-model="paginationCertificatesIssued.page" :length="pagesCertificatesIssued"></v-pagination>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card class="container-rounded-mini">
          <v-layout row wrap justify-center>
            <v-flex xs10>
              <h6 style="text-align:center;margin-top:10px;color: #273e4b"><b>Did Not Attend</b></h6>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 mt-3>
        <v-data-table :headers="headers2" :items="didNotAttend" hide-actions :pagination.sync="paginationDidNotAttend" :custom-sort="CustomSort" class="table bordered-table">
          <template slot="headerCell"   slot-scope="{ header }">
            <span class="subheading font-weight-bold text--darken-3" v-text="header.text" />
          </template>
          <template v-slot:items="props">
            <td>{{ props.item.seatNumber }}</td>
            <td>{{ props.item.user ? props.item.user.firstName : 'Unconfirmed' }}</td>
            <td>{{ props.item.user ? props.item.user.lastName : 'User' }}</td>
            <td>{{ props.item.id }}</td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-layout class="mb-3">
      <v-flex xs12 text-right>
        <v-pagination style="vertical-align:top;" v-model="paginationDidNotAttend.page" :length="pagesDidNotAttend"></v-pagination>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex xs12 class="text-right">
        <v-btn color="rgba(254,138,125,1)" @click="GoBack" class="mr-0">
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row justify-center>

      <v-dialog persistent v-model="messageDialog.show" transition="dialog-transition" max-width="450">
        <v-card>
          <v-card-text>
            <v-flex>
              {{ messageDialog.message }}
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="CloseMessageDialog" small color=rgb(0,171,173)>Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-layout>
  </v-container>
</template>


<script>

  import EventService from '@/services/event.service'
  import CertificateService from '@/services/certificate.service'
  import * as EventHelper from '@/helpers/event.helper'
  import { EVENT_MODE } from '@/constants/EventConstants'
  import { BOOKING_ATTENDANCE } from '@/constants/BookingConstants'
  import { LAYOUTS } from '@/constants/LayoutConstants'

  import jsPDF from 'jspdf'

  export default {

    data() {
      return {
        isLoading: false,
        fullPage: true,
        search: '',
        is_data_fetched: false,
        event: {},
        messageDialog: {
          show: false,
          message: ''
        },
        paginationCertificatesIssued: {
          rowsPerPage: 5,
          totalItems: 0
        },
        paginationDidNotAttend: {
          rowsPerPage: 5,
          totalItems: 0
        },
        headers1: [
          { text: 'S.No',             align: 'center', value: 'sNo',            sortable: false },
          { text: 'First Name',       align: 'center', value: 'firstName'                       },
          { text: 'Last Name',        align: 'center', value: 'lastName'                        },
          { text: 'Registration No',  align: 'center', value: 'registrationNo'                  },
          { text: 'Certificate',      align: 'center', value: 'certificate',    sortable: false },
        ],
        headers2: [
          { text: 'S.No',             align: 'center', value: 'sNo',           sortable: false  },
          { text: 'First Name',       align: 'center', value: 'firstName'                       },
          { text: 'Last Name',        align: 'center', value: 'lastName'                        },
          { text: 'Registration No',  align: 'center', value: 'registrationNo'                  }
        ]
      }
    },


    methods: {

      GoBack: function () {
        this.$router.push({ name: `${ this.$store.getters.layout === LAYOUTS.ADMIN ? 'AdminAllEvents' : 'ProviderAllEvents' }`, params: { status: this.$route.params.status }, query: this.$route.query });
      },

      generatePdf: function () {
        // TODO: FUTURE: Add more content to the PDF pages (headings, file name, etc)
        var doc = new jsPDF();
        var rows = [];
        this.certificatesIssued.forEach(element => {
          var temp = [element.seatNumber, element.user.firstName, element.user.lastName, element.id];
          rows.push(temp);
        });
        this.didNotAttend.forEach(element => {
          var temp = [element.seatNumber, element.user.firstName, element.user.lastName, element.id];
          rows.push(temp);
        });
        doc.autoTable({
          head: [['S.No', 'First Name', 'Last Name', 'Registration No']],
          body: rows
        });
        doc.save();
      },

      /*
      print: function () {
        var doc = new jsPDF();
        var rows = [];
        this.certificatesIssued.forEach(element => {
          var temp = [element.sNo, element.firstName, element.lastName, element.bookingId];
          rows.push(temp);
        });
        this.didNotAttend.forEach(element => {
          var temp = [element.sNo, element.firstName, element.lastName, element.bookingId];
          rows.push(temp);
        });
        doc.autoTable({
          head: [['S.No', 'First Name', 'Last Name', 'Registration No']],
          body: rows
        });
        doc.autoPrint();
        doc.output('dataurlnewwindow');
      },
      */
      CustomSort(items, index, isDesc) {
        try {
          const result = items.sort((a, b) => {
            this.sortBy = index;
            this.sortDesc = isDesc;

            // Title Sort
            if (index === "firstName") {
              const firstNameA = a.user ? a.user.firstName : "Unconfirmed";
              const firstNameB = b.user ? b.user.firstName : "Unconfirmed";
              if (!isDesc) { return firstNameA < firstNameB ? -1 : 1 }
              return firstNameA > firstNameB ? -1 : 1
            }

            // Event Date Sort
            if (index === "lastName") {
              const lastNameA = a.user ? a.user.lastName : "User";
              const lastNameB = b.user ? b.user.lastName : "User";
              if (!isDesc) { return lastNameA < lastNameB ? -1 : 1 }
              return lastNameA > lastNameB ? -1 : 1
            }

            // Start Time Sort
            if (index === "registrationNo") {
              if (!isDesc) { return a.id < b.id ? -1 : 1 }
              return a.id > b.id ? -1 : 1
            }
          });
          return result;
        } catch (e) {
          console.warn("Error when custom sorting:", e)
        }
      },


      async GetCertificate(booking) {
        //console.log("Getting a certificate")
        //console.log("booking:", booking);
        //console.log("event:", this.event);
        const certificateService = new CertificateService(this.$store)
        const certificateExists = await certificateService.CheckCertificateExists(this.event.id, booking.user.id, booking.user.identityId)
        // If the certificate doesn't exist, tell the provider and do nothing else
        if (!certificateExists) {
          this.messageDialog.message = "The certificate is unavailable because the user has not yet viewed this certificate online. Please contact us at contact@mypropass.co.uk in case you need to view a copy of this certificate."
          this.messageDialog.show = true;
          return
        }
        // If it does exist, fetch it
        const signedURL = await certificateService.GetCertificateSignedURL(this.event.id, booking.user.id, booking.user.identityId)
        //console.log("signedURL:", signedURL)
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = signedURL;
        a.download = `Certificate - ${booking.id}.pdf`;
        a.target = "_blank"
        a.rel = "noopener"
        a.click();
        document.body.removeChild(a);
      },

      CloseMessageDialog() {
        // Done
        this.messageDialog.show = false;
      },
    },

    computed: {
      eventName: function () {
        return this.event.title || ""
      },
      pagesCertificatesIssued() {
        if (this.certificatesIssued == null ||this.paginationCertificatesIssued.totalItems == null) { return 0 }
        return Math.ceil(this.certificatesIssued.length / this.paginationCertificatesIssued.rowsPerPage)
      },
      pagesDidNotAttend() {
        if (this.didNotAttend == null || this.paginationDidNotAttend.totalItems == null) { return 0 }
        return Math.ceil(this.didNotAttend.length / this.paginationDidNotAttend.rowsPerPage)
      },
      eventLocation: function() {
        try {
          if (this.event.eventMode === EVENT_MODE.INPERSON) { return EventHelper.FormatAddressObjectToString(this.event.address) }
          return "Online"
        } catch (err) {
          return "Error fetching location"
        }
      },
      eventDate: function() {
        try {
          return ((new Date(this.event.startDateTime)).toLocaleString()).slice(0,-3)
        } catch (err) {
          return "Error fetching date"
        }
      },
      certificatesIssued: function () {
        return this.attendeeList.filter(attendee => attendee.attendance === BOOKING_ATTENDANCE.ATTENDED)
      },
      didNotAttend: function () {
        return this.attendeeList.filter(attendee => attendee.attendance === BOOKING_ATTENDANCE.NOSHOW)
      },
    },

    async mounted() {
      // Done
      const eventService = new EventService(this.$store)
      // TODO: FUTURE: Add some error checking to the event Id fetching
      try {
        const response = await eventService.GetEventAttendees(this.$route.params.eventId)
        //console.log(response)
        // Add the event and attendee list to the data
        this.attendeeList = response.items
        this.event = response.event
      } catch (err) {
        console.warn("Unable to fetch event attendees", err)
      }
      this.is_data_fetched = true;
    }

  }
</script>


<style scoped>

  .v-btn {
    text-decoration: none;
    text-transform: capitalize;
  }
  /* style the container */
  .container {
    position: relative;
    border-radius: 5px;
    padding: 20px 0 30px 0;
  }

  .bottom-container {
    text-align: center;
    background-color: #666;
    border-radius: 0px 0px 4px 4px;
  }

  body {
    background-color: rgba(243,246,248,1);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  .container-rounded-mini {
    background-color: #FFFFFF;
    min-height: 40px;
  }

  .container-rounded {
    background-color: #FFFFFF;
    padding-left: 50px;
    margin: 40px;
    min-height: 350px;
  }
</style>

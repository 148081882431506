<template>
  <v-card>
    <v-card-title>
      <div class="headline" style="color:rgba(254,138,125,1);">Add / Remove Manual Attendees</div>
    </v-card-title>
    <v-divider style="border-width:1px;"></v-divider>

    <v-layout justify-center layout style="margin-bottom: 2em;">
      <v-data-table
        :headers="headers"
        :items="attendeeList"
        hide-actions
        no-data-text="No Attendees Added"
        class="table">
        <template slot="headerCell" slot-scope="{ header }">
          <span class="subheading font-weight-bold text--darken-3" v-text="header.text" />
        </template>
        <template v-slot:items="props">
          <td><input type="text"  class="form-control" placeholder="First Name"     v-model="attendeeList[props.index].firstName" /></td>
          <td><input type="text"  class="form-control" placeholder="Last Name"      v-model="attendeeList[props.index].lastName" /></td>
          <td><input type="email" class="form-control" placeholder="Email Address"  v-model="attendeeList[props.index].emailAddress" /></td>
          <td><a @click="RemoveAttendeeRow(props.index)"><i class="far fa-trash-alt" style="margin-top: 10px;" /></a></td>
        </template>
      </v-data-table>
      <v-btn color="rgb(0,171,173)" small @click="AddAttendeeRow" class='add-button'>Add Row</v-btn>
    </v-layout>


    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small color=rgb(254,138,125) @click="ClosePopover">Cancel</v-btn>
      <v-btn class="mr-2" small color=rgb(0,171,173) @click="DownloadAttendeeCSVTemplate">Download CSV Template</v-btn>
      <div class="mr-2">
        <v-btn small color=rgb(0,171,173) @click="onButtonClick" >
          Upload Attendee CSV
        </v-btn>
        <input ref="uploader" class="d-none" type="file" accept=".csv" @change="ProcessCSVFile">
      </div>
      <v-btn small color=rgb(0,171,173)   @click="SaveAttendees" class='add-button' :disabled="DisableAddButton" >Add Attendees</v-btn>
    </v-card-actions>

    <v-dialog persistent v-model="messageDialog.show" transition="dialog-transition" max-width="450">
      <v-card>
        <v-card-text>
          <v-flex>
            <span style="font-weight:normal;">
              <div v-html="messageDialog.message"></div>
            </span>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CloseErrorDialog" small color=rgb(0,171,173)>Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>


<script>

  //import Vue from 'vue'
  import CSV from 'csvtojson'

  const DEFAULT_NEW_ATTENDEE = {
    emailAddress: "",
    firstName: "",
    lastName: ""
  }

  export default {

    props: {
      closePopover: Function,
      manualAttendees: {
        type: Array,
        required: false,
        default: function() { return [] }
      },
      onManualAttendeesChange: Function
    },


    data() {
      return {
        attendeeList: [ { ...DEFAULT_NEW_ATTENDEE } ],
        headers: [
          { text: 'First Name',       align: 'left',    value: 'firstName',     width: '30%', sortable: false },
          { text: 'Last Name',        align: 'left',    value: 'lastName',      width: '30%', sortable: false },
          { text: 'Email Address',    align: 'left',    value: 'emailAddress',  width: '30%', sortable: false },
          { text: 'Actions',          align: 'center',  value: 'actions',       width: '10%', sortable: false },
        ],
        messageDialog: {
          message: "",
          show: false
        }
      }
    },


    methods: {
      ClosePopover() {
        this.attendeeList = [ { ...DEFAULT_NEW_ATTENDEE } ]
        this.closePopover()
      },

      AddAttendeeRow() {
        this.attendeeList = this.attendeeList.concat([ { ...DEFAULT_NEW_ATTENDEE } ])
      },

      RemoveAttendeeRow(index) {
        this.attendeeList.splice(index, 1)
      },

      SaveAttendees() {
        console.log("Saving attendees")
        // Get the number of invalid rows
        const invalidRows = this.attendeeList.filter((row) => {
          return row.emailAddress === "" || row.firstName === "" || row.lastName === ""
        })
        console.log("invalidRows:", invalidRows)
        if (invalidRows.length > 0) {
          console.warn("There were rows that are not filled in")
          return
        }


        this.onManualAttendeesChange(this.attendeeList)
        //this.closePopover()
      },

      onButtonClick() {
        this.isSelecting = true
        window.addEventListener('focus', () => {
          this.isSelecting = false
        }, { once: true })
        this.$refs.uploader.click()
      },

      async ProcessCSVFile(e) {
        console.log("File Uploaded:", e.target.files)
        if (e.target.files.length === 0) {
          //console.log("no file selected")
          return
        }
        const selectedFile = e.target.files[0]
        const reader = new FileReader();
        reader.onload = (fileString) => {
          this.UpdateManualAttendees(e, fileString.target.result);
        }
        reader.readAsText(selectedFile);
      },

      async UpdateManualAttendees(e, fileString) {
        // If the file does not match the template, reject it
        if (!fileString.startsWith("firstName,lastName,emailAddress")) {
          this.messageDialog.message = 'The uploaded file does not match the template file.<br /><br />Please download the template and populate it with the attendee details.'
          this.messageDialog.show = true
          e.target.value = ''
          return
        }
        // Convert the file string into a JSON object
        const result = await CSV({ noheader:false }).fromString(fileString)
        //console.log("CSV to JSON Result:", result)
        const filteredAttendees = result.filter((row) => {
          return row.emailAddress !== "" && row.firstName !== "" && row.lastName !== ""
        })
        // If there are more attendees than 500, this cannot be uploaded by the user because the back end cannot handle this many requests
        if (filteredAttendees.length > 500) {
          this.messageDialog.message = `The uploaded file contains too many attendees (Found in file: ${filteredAttendees.length}; maximum: 500)<br /><br />If you need to upload more than 500 attendees to a single event, please email <a href='mailto:contact@mypropass.co.uk'>contact@mypropass.co.uk</a> to request a limit extension.`
          this.messageDialog.show = true
          e.target.value = ''
          return
        }
        // Check if all the users were correctly uploaded.
        if (result.length !== filteredAttendees.length) {
          const difference = result.length - filteredAttendees.length
          this.messageDialog.message = `${difference} of the attendees in the file was missing some of the required data.<br /><br />${filteredAttendees.length} of ${result.length} attendees added.`
          this.messageDialog.show = true
        } else {
          this.messageDialog.message = `All attendees added successfully.<br /><br />${filteredAttendees.length} attendees added.`
          this.messageDialog.show = true
        }
        // Save the new attendee list
        this.attendeeList = filteredAttendees
        e.target.value = ''
      },

      DownloadAttendeeCSVTemplate() {
        const headerRow = "data:text/csv;charset=utf-8,firstName,lastName,emailAddress\r\n"
        var csvContent = headerRow
        var encodedUri = encodeURI(csvContent);
        // Encode the octothorpes because they cause the output to be truncated
        encodedUri = encodedUri.replace(/#/g, "%23")
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `Event Attendee List Upload Template.csv`);
        document.body.appendChild(link); // Required for FF
        link.click(); // This will download the data file
        document.body.removeChild(link);
      },

      CloseErrorDialog() {
        this.messageDialog.message = ''
        this.messageDialog.show = false
      }

    },

    computed: {

      DisableAddButton: function() {
        return this.attendeeList.length === 0
      }
    },

    mounted() {
      if (this.manualAttendees.length > 0) {
        this.attendeeList = this.manualAttendees
      }

    },

  }
</script>




<style scoped>

  .layout {
    display: block;
    margin-bottom: 2em;
    max-width: 90%;
    margin: auto;
  }

  .horizontal-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .single-line {
    white-space: nowrap;
  }

  label {
    padding-right: 1em;
  }

  .user-returned-details {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
  }

  .error-row {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    color: darkred;
    font-weight: bold;
  }

  .user-details-row {
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    grid-gap: 2em;
  }

  .user-details-row p:last-of-type {
    margin-bottom: 0;
  }

  .add-button {
    margin-left: auto;
    margin-right: 0;
  }

  .user-details-container {
    margin: 2em 0;
  }

  .form-row {
    display: grid;
    grid-auto-flow: column;
    justify-items: start;
    align-items: center;
    justify-content: start;
    grid-gap: 1em;
    width: 100%;
    margin-bottom: 0.5em;
  }

  .text-form-row {
    grid-template-columns: auto 1fr;
  }

  .course-instructor-details,
  .user-details-container {
    display: grid;
    grid-gap: 0.5em 1em;
    grid-template-columns: 1fr 4fr;
    margin: 0;
    margin-bottom: 2em;
    align-items: center;
  }

  .table tbody td {
    padding: 10px 24px !important;
  }

</style>


import { API, graphqlOperation } from 'aws-amplify';
import * as StoreActions from '@/constants/StoreActions'
import * as QueriesCustom from '@/graphql/queriesCustom';
import EventService from '@/services/event.service'
import ExperienceRecordService from '@/services/experienceRecord.service'
import store from '@/store'

class AdminService {

  constructor() {
    this.$store = store
  }

  /**
   * GetProviderList fetches a list of all the providers in the system
   * @return {Array}      An array of provider objects
   */
  async GetProviderList() {
    try {
      const response = await API.graphql(graphqlOperation(QueriesCustom.listEmployers));
      const result = response.data.listEmployers.items
      this.$store.commit(StoreActions.SET_PROVIDER_LIST, result)
      return result
    } catch (err) {
      console.warn("Error when fetching provider list;", err)
      return []
    }
  }

  /**
   * UpdateCurrentProvider changes the store to use the selected provider
   * @param  {String} providerId  The id of the provider to change to
   * @return {Array}              An array of provider objects
   */
  async UpdateCurrentProvider(providerId) {
    try {
      //console.log("Request to change to providerId:", providerId)
      // If this provider is already selected, do nothing
      if (providerId === this.$store.getters.employer.id) {
        //console.log("This provider is already selected")
        return
      }
      // Build the parameters for the query
      const parameters = { id: providerId }
      // Update the employer details
      const employerResult = await API.graphql(graphqlOperation(QueriesCustom.getEmployerForAdmin, parameters))

      // Build the employer details for the store
      const employerForStore = {
        roleName: "Administrator",
        ...employerResult.data.getEmployer
      }
      // Update the store with employer details
      await this.$store.commit(StoreActions.SET_EMPLOYER, employerForStore);

      // Fetch the new providers event details
      const eventService = new EventService(this.$store)
      const experienceRecordService = new ExperienceRecordService(this.$store)
      await Promise.all([
        eventService.GetFutureEventsForEmployee(),
        eventService.GetPastEventsForEmployee(),
        experienceRecordService.GetExperienceRecordsForEmployee()
      ])

      return
    } catch (err) {
      console.warn("Error when changing provider;", err)
      return
    }
  }




}

export default AdminService

<template>
  <v-container fluid grid-list-md v-if="is_data_fetched">
    <loading :active.sync="taskInProgress" :is-full-page="true" loader="dots"></loading>
    <v-layout column pa-0 ma-0>
      <v-flex md1>
        <v-layout>
          <v-flex xs12 md2>
            <h5 class="page-header">Book Course</h5>
          </v-flex>
          <v-flex xs10 md10 style="text-align:right;">
            <router-link :to="{ name: 'UserLanding' , params: { status: USER_EVENTS_PAGE_PARAMETERS.EVENTS }}">
              <div style="color:rgb(0,171,173)">All Courses</div>
            </router-link>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 md10 class="container-rounded">
        <v-container fluid>
          <v-layout ma-0>
            <v-flex xs12 md12>
              <v-container ma-0 pa-0 fluid fill-height style="background-color: rgba(243, 246,248, 0.7);">
                <v-layout>
                  <v-flex xs12 md2>
                    <v-container fill-height container-image>
                      <v-layout ma-0 pa-0>
                        <event-logo v-bind:imageKey="eventLogo" level="public" imageStyle="background-color: transparent; max-width: 150px; max-height: 150px; margin:0.33em;" />
                      </v-layout>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md10 pt-0>
                    <v-layout align-content-start ma-1>
                      <div class="ABC_in_Scaffolding">
                        <span>{{ title }}</span><br />
                        <div class="Duration__1_hour book-course-secondary-data">
                          <span style="margin-bottom: 5px;margin-top:5px;">{{ duration }} | {{ eventType }}</span>
                          <span style="float:left;margin-right: 5px;font-weight: bold;">Provider:</span><a href="#" @click="ShowProviderInfo()"><span style="color:#2385E6;">{{ providerName }}</span></a>
                          <div style="margin-top: 5px; font-size:12px;" v-html="providerDescription" />
                        </div>
                      </div>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>
          </v-layout>
          <v-layout pa-0 ma-0 justify-center align-content-center>
            <v-flex xs12 md12>
              <div class="ABC_in_Scaffolding" style="margin-top:10px;margin-bottom:10px;">
                <span>When</span><br />
                <div class="Duration__1_hour">
                  <span style="color: #666"><strong>Start:</strong> {{ startDateAndTime }}</span>
                  <br />
                  <span style="color: #666"><strong>End:</strong> {{ endDateAndTime }}</span>
                  <br />
                </div>
              </div><br /><br />

              <div class="ABC_in_Scaffolding" style="margin-top:10px;margin-bottom:10px;">
                <span>Description</span><br />
                <div class="Duration__1_hour">
                  <span style="color: #666" v-html="description"></span>
                  <br />
                </div>
              </div><br />

              <div class="ABC_in_Scaffolding" style="margin-bottom:10px;">
                <span>Instructor</span><br />
                <div class="Duration__1_hour">
                  <span style="color: #666">{{ instructor }}</span><br />
                  <span style="color: #666" v-html="aboutInstructor"></span>
                  <br />
                </div>
              </div><br />

              <div class="ABC_in_Scaffolding" style="margin-bottom:10px;">
                <span>Requirements</span><br />
                <div class="Duration__1_hour">
                  <span style="color: #666">{{ requirements }}</span>
                  <br />
                </div>
              </div><br />

              <div class="ABC_in_Scaffolding">
                <span>Target Audience</span><br />
                <div class="Duration__1_hour">
                  <span style="color: #666">{{ targetAudience }}</span>
                </div>
              </div>

            </v-flex>
          </v-layout>
          <v-layout pa-0 ma-0 mt-4 row wrap justify-center align-content-center>
            <v-flex xs12 md12>
              <span class="ABC_in_Scaffolding">Sessions</span><br />


              <v-data-table
                v-if="windowWidth >= 768"
                :headers="headers"
                :items="sessions"
                :custom-sort="sortBookingTable"
                style="border:solid 1px #999"
                table-border
                hide-actions>
                <template v-slot:items="props">
                  <td>{{ GetDateForSession(props.item.event.startDateTime) }}</td>
                  <td>
                    <span v-if="props.item.event.eventType === EVENT_TYPE.PAID">{{ props.item.price.title }}</span>
                    <span v-else>Standard Price</span>
                  </td>
                  <td>
                    <span v-if="props.item.event.eventType === EVENT_TYPE.PAID">£{{ props.item.price.priceGBP }}</span>
                    <span v-else>Free</span>
                  </td>
                  <td>{{ GetLocationForSession(props.item.event) }}</td>
                  <td style="text-align:left;">
                    <span>{{ GetAvailableSeatsForSession(props.item.event) }}</span>
                  </td>

                  <td style="margin:0px;padding:0px;text-align:center">

                    <span>
                      <v-btn v-if="GetActionButtonsForSession(props.item.event).includes(ACTION_BUTTONS.BOOK_SESSION)" color="rgb(0,171,173)" small @click="BookEvent(props.item)" :disabled="UserIsBookedStatusForEvent(props.item.event)">
                        Book Course
                      </v-btn>
                      <v-btn v-if="GetActionButtonsForSession(props.item.event).includes(ACTION_BUTTONS.PRIVATE_EVENT)" color="rgb(0,171,173)" small @click="BookEvent(props.item)" disabled>
                        Private Event
                      </v-btn>
                      <v-btn v-if="GetActionButtonsForSession(props.item.event).includes(ACTION_BUTTONS.NOTIFY_ME)" color="rgb(0,171,173)" small @click="AddToWaitingList(props.item)" :disabled="UserIsBookedStatusForEvent(props.item.event) || UserIsOnWaitingListStatusForEvent(props.item.event)">
                        Notify Me
                      </v-btn>
                      <v-btn v-if="GetActionButtonsForSession(props.item.event).includes(ACTION_BUTTONS.ADD_TO_CALENDAR)" color="rgb(0,171,173)" small @click="AddToCalendar(props.item.event)" :disabled="UserIsBookedStatusForEvent(props.item.event) || EventIsPrivate(props.item.event)">
                        Add To Calendar
                      </v-btn>
                      <v-btn v-if="GetActionButtonsForSession(props.item.event).includes(ACTION_BUTTONS.CONTACT_PROVIDER)" color="rgb(0,171,173)" small @click="ContactProvider(props.item.event.provider.contactDetails.emailBookings, props.item.event.title)">
                        Contact Provider
                      </v-btn>
                    </span>
                  </td>

                </template>
              </v-data-table>

              <div v-if="windowWidth < 768" class="sessions-holder">
                <div v-for="(session, index) in sessions" class='session-tile' :key="index">
                  <p>
                    <strong>Date:</strong> {{ GetDateForSession(session.event.startDateTime) }}
                  </p>
                  <p>
                    <strong>Booking Type: </strong>
                    <span v-if="session.event.eventType === EVENT_TYPE.PAID">{{ session.price.title }}</span>
                    <span v-else>Standard Price</span>
                  </p>
                  <p>
                    <strong>Price: </strong>
                    <span v-if="session.event.eventType === EVENT_TYPE.PAID">£{{ props.item.priceGBP }}</span>
                    <span v-else>Free</span>
                  </p>
                  <p>
                    <strong>Location:</strong> {{ GetLocationForSession(session.event) }}
                  </p>
                  <p>
                    <strong>Available Seats:</strong> {{ GetAvailableSeatsForSession(session.event) }}
                  </p>
                  <span>
                    <v-btn v-if="GetActionButtonsForSession(session.event).includes(ACTION_BUTTONS.BOOK_SESSION)" color="rgb(0,171,173)" small @click="BookEvent(session)" :disabled="UserIsBookedStatusForEvent(session.event)">
                      Book Event
                    </v-btn>
                    <v-btn v-if="GetActionButtonsForSession(session.event).includes(ACTION_BUTTONS.NOTIFY_ME)" color="rgb(0,171,173)" small @click="AddToWaitingList(session)" :disabled="UserIsBookedStatusForEvent(session.event) || UserIsOnWaitingListStatusForEvent(session.event)">
                      Notify Me
                    </v-btn>
                    <v-btn v-if="GetActionButtonsForSession(session.event).includes(ACTION_BUTTONS.ADD_TO_CALENDAR)" color="rgb(0,171,173)" small @click="AddToCalendar(session.event)" :disabled="UserIsBookedStatusForEvent(session.event) || EventIsPrivate(session.event)">
                      Add To Calendar
                    </v-btn>
                    <v-btn v-if="GetActionButtonsForSession(session.event).includes(ACTION_BUTTONS.CONTACT_PROVIDER)" color="rgb(0,171,173)" small @click="ContactProvider(session.event.provider.contactDetails.emailBookings, session.event.title)">
                      Contact Provider
                    </v-btn>
                  </span>
                </div>
              </div>

            </v-flex>
          </v-layout>
          <v-flex md1>
            <v-layout>
              <v-flex xs12 style="text-align:right;">
                <router-link :to="{ name: 'UserLanding' , params: { status: USER_EVENTS_PAGE_PARAMETERS.EVENTS }}" class="v-btn v-btn-secondary">
                  <div style="text-align:center;font-size: 18px;">Cancel</div>
                </router-link>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-container>
      </v-flex>
      <v-flex md1>
      </v-flex>
    </v-layout>
    <v-layout row justify-center>

      <v-dialog persistent v-model="messageDialog.show" transition="dialog-transition" max-width="450">
        <v-card>
          <v-card-text>
            <v-flex>
              <div id="message" style="font-weight:normal;">
                {{ messageDialog.message }}
              </div>
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="CloseMessageDialog" small color=rgb(0,171,173)>OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>


<script>
  //import ProfessionalService from '../services/professional.service'
  import EventService from '../services/event.service'
  import BookingService from '../services/booking.service'
  import UserService from '../services/user.service'
  import { EventDurationInMinutes, UserIsAlreadyBookedOntoEvent, UserIsOnWaitingList } from '../helpers/event.helper'
  import { GetLocaleDateTimeFromDateTime, GetLocaleDateFromDateTime } from '../helpers/date.helper'
  import { FormatDuration, DESIRED_DURATIONS } from '../helpers/duration.helper'
  import { EVENT_ACCESS_LEVEL, EVENT_MODE, EVENT_TYPE, ACTION_BUTTONS } from '@/constants/EventConstants'
  import { EVENT_PRICE_STATUS } from '@/constants/EventPriceConstants'
  import { USER_EVENTS_PAGE_PARAMETERS } from '../constants/PageParameterConstants'
  import { BOOKING_STATUS } from '@/constants/BookingConstants'
  import EventLogo from './eventlogo-container'


  export default {
    data() {
      return {
        taskInProgress: false,
        is_data_fetched: false,
        windowWidth: window.innerWidth,
        disabled: false,
        messageDialog: {
          show: false,
          message: '',
          navigateToUserLanding: false,
          navigateToUserEvents: false
        },
        waitingListDialog: {
          show: false,
          message: ''
        },
        headers: [
          {
            text: 'Available Date',
            align: 'left',
            sortable: false,
            value: 'eventDate'
          },
          { text: 'Booking Type', align: 'left', value: 'priceTitle', sortable: false },
          { text: 'Price', align: 'left', value: 'price', sortable: false },
          { text: 'Location', align: 'left', value: 'location', sortable: false },
          { text: 'Available Seats', align: 'left', value: 'availableseats', sortable: false },
          { text: 'Actions', align: 'center', value: 'action', sortable: false }
        ],
        errorFetchingDetails: false,
        eventIdProvided: true,
        eventDetails: {},
        ACTION_BUTTONS,
        EVENT_TYPE,
        USER_EVENTS_PAGE_PARAMETERS
      }
    },


    components: {
      'event-logo': EventLogo
    },


    methods: {

      sortBookingTable(items) {
        try {
          const result = items.sort((a, b) => {
            return parseInt(b.priceGBP) < parseInt(a.priceGBP) ? 1 : -1;
          })
          return result;
        } catch (err) {
          console.warn("Error when custom sorting booking table:", err)
          return items
        }
      },

      async AddToWaitingList(session) {
        this.taskInProgress = true;
        //console.log("session:", session);
        //console.log("eventId:", session.event.id);
        const { event } = session

        if (this.UserIsBookedStatusForEvent(event) || this.UserIsOnWaitingListStatusForEvent(event)) {
          this.messageDialog.message = "You were not added to the waiting list because you are already booked onto this course";
          this.messageDialog.show = true;
          this.taskInProgress = false;
          return;
        }

        // Get the details from the store
        const { user } = this.$store.getters
        const { providerId, id } = event
        const parameters = {
          userId: user.id,
          eventId: id
        }
        //console.log("parameters:", parameters)

        // Create the booking
        try {
          await BookingService.CreateWaitingListBooking(parameters, providerId)
          //console.log(response);
        } catch (err) {
          console.warn("Error when creating waiting list booking", err)
          this.messageDialog.message = "There was an error when adding you to the waiting list.  Please try again";
          this.messageDialog.show = true;
          this.taskInProgress = false;
          return
        }

        // Once booking is created, fetch user details again as there will be a new booking
        try {
          const userService = new UserService(this.$store)
          await userService.GetDetails()
          this.messageDialog.message = "You have been added to waiting list for this event.  You will be notified by email if a space becomes available.";
          this.messageDialog.navigateToUserLanding = true;
          this.messageDialog.show = true;
        } catch (err) {
          console.warn("There was an error when getting updated user details;", err)
          this.messageDialog.message = "There was an error when adding you to the waiting list.  Please try again";
          this.messageDialog.show = true;
        }
        this.taskInProgress = false;
      },

      ContactProvider: function (email, title) {
        const emailTargetURL = `mailto:${email}?subject=MyProPass Enquiry - ${encodeURIComponent(title)}`;
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = emailTargetURL;
        a.target = "_blank"
        a.rel = "noopener"
        a.click();
        document.body.removeChild(a);
      },

      async AddToCalendar(event) {
        this.taskInProgress = true;
        //console.log("EventId:", event.id);
        const { user, bookings } = this.$store.getters
        if (UserIsAlreadyBookedOntoEvent(user.id, event.id, bookings)) {
          this.messageDialog.message = "You were not sent a calendar invitation because you have already received a calendar invite for this course.";
          this.messageDialog.show = true;
          this.taskInProgress = false;
          return;
        }

        // Get the details from the event
        const { providerId } = event

        // Create the booking
        try {
          const response = await BookingService.CreateBooking(user.id, event.id, providerId)
          console.log(response);
        } catch (err) {
          console.warn("Error when creating booking", err)
          this.messageDialog.message = "There was an error when sending you a calendar invitation.  Please try again";
          this.messageDialog.show = true;
          this.taskInProgress = false;
          return
        }

        // Once booking is created, fetch user details again as there will be a new booking
        try {
          const userService = new UserService(this.$store)
          await userService.GetDetails()
          this.messageDialog.message = "Calendar invitation sent successfully.  Please check your email for the calendar invitation containing the event details.";
          this.messageDialog.navigateToUserLanding = true;
          this.messageDialog.show = true;
        } catch (err) {
          console.warn("There was an error when getting updated user details;", err)
          this.messageDialog.message = "There was an error when sending you a calendar invitation.  Please try again";
          this.messageDialog.show = true;
        }
        this.taskInProgress = false;
      },

      CloseMessageDialog() {
        this.messageDialog.show = false;
        document.getElementById('message').innerHTML = '';
        if (this.messageDialog.navigateToUserLanding) {
          this.$router.push({ name: 'UserLanding' });
        }
        if (this.messageDialog.navigateToUserEvents) {
          this.$router.push({ name: 'UserMyEvents', params: { status: USER_EVENTS_PAGE_PARAMETERS.EVENTS } });
        }
      },

      ShowProviderInfo() {
        this.$router.push({ name: 'UserProviderInfo', params: { eventId: this.$route.params.eventId } });
      },

      BookEvent(session) {
        console.log("session:", session)
        this.$router.push({ name: 'UserBookCourseReview', params: { priceId: session.price.id, eventId: session.event.id } });
      },

      // Ben functions start here
      GetDateForSession(dateTimeString) {
        return GetLocaleDateFromDateTime(dateTimeString)
      },
      GetLocationForSession(session) {
        if (session.eventMode === EVENT_MODE.INPERSON) {
          return session.address.city
        }
        if (session.eventMode === EVENT_MODE.VIRTUAL) {
          return "Online"
        }
        return "TBC"
      },
      GetAvailableSeatsForSession(session) {
        const { attendees, maximumAttendees } = session
        const bookedSeats = attendees.items.filter((booking) => {
          return booking.status === BOOKING_STATUS.BOOKED
        }).length
        if (bookedSeats > maximumAttendees) { return 0 }
        return maximumAttendees - bookedSeats
      },
      GetActionButtonsForSession(session) {
        //console.log("session:", session)
        var actionButtons = []
        try {
          // If this is not a conference and has spare spaces...
          if (session.eventType !== EVENT_TYPE.CONFERENCE && this.GetAvailableSeatsForSession(session) > 0  && session.accessLevel === EVENT_ACCESS_LEVEL.PUBLIC) {
            actionButtons.push(ACTION_BUTTONS.BOOK_SESSION)
            actionButtons.push(ACTION_BUTTONS.CONTACT_PROVIDER)
            return actionButtons
          }
          // If this is a non-conference which is also private, remove the book session button and
          // add the disabled private event button
          if (session.eventType !== EVENT_TYPE.CONFERENCE && session.accessLevel === EVENT_ACCESS_LEVEL.PRIVATE) {
            actionButtons.push(ACTION_BUTTONS.PRIVATE_EVENT)
            actionButtons.push(ACTION_BUTTONS.CONTACT_PROVIDER)
            return actionButtons
          }
          // If this is not a conference and has no available seats
          if (session.eventType !== EVENT_TYPE.CONFERENCE && this.GetAvailableSeatsForSession(session) === 0) {
            actionButtons.push(ACTION_BUTTONS.NOTIFY_ME)
            actionButtons.push(ACTION_BUTTONS.CONTACT_PROVIDER)
            return actionButtons
          }
          // If neither of the other if statements were triggered, add the default options
          actionButtons.push(ACTION_BUTTONS.ADD_TO_CALENDAR)
          actionButtons.push(ACTION_BUTTONS.CONTACT_PROVIDER)
          // Return the list of possible actions
          return actionButtons
        } catch (err) {
          console.warn("Error getting action buttons;", err)
          return []
        }
      },
      UserIsBookedStatusForEvent(event) {
        try {
          const { user, bookings } = this.$store.getters
          return UserIsAlreadyBookedOntoEvent(user.id, event.id, bookings)
        } catch (err) {
          console.warn("Unable to check if user was already booked onto this course", err)
          return true
        }
      },
      UserIsOnWaitingListStatusForEvent(event) {
        try {
          const { user, bookings } = this.$store.getters
          return UserIsOnWaitingList(user.id, event.id, bookings)
        } catch (err) {
          console.warn("Unable to check if user was already booked onto this course", err)
          return true
        }
      },
      EventIsPrivate(event) {
        try {
          return event.accessLevel === EVENT_ACCESS_LEVEL.PRIVATE
        } catch (err) {
          console.warn("Unable to check if event is private", err)
          return true
        }
      },
      onResize() {
        this.windowWidth = window.innerWidth
      }
    },

    computed: {
      title: function() {
        try { return this.eventDetails.title } catch (e) { return '' }
      },
      startDateAndTime: function() {
        try { return GetLocaleDateTimeFromDateTime(this.eventDetails.startDateTime).slice(0,-3) } catch (e) { return '' }
      },
      endDateAndTime: function() {
        try { return GetLocaleDateTimeFromDateTime(this.eventDetails.endDateTime).slice(0,-3) } catch (e) { return '' }
      },
      description: function() {
        try { return this.eventDetails.description } catch (e) { return '' }
      },
      requirements: function() {
        try { return this.eventDetails.preRequisites } catch (e) { return '' }
      },
      targetAudience: function() {
        try { return this.eventDetails.targetAudience } catch (e) { return '' }
      },
      /*
      availableWaitingListSeat: function() {
        try { return this.eventDetails.eventType } catch (e) { return '' }
      },
      */
      duration: function() {
        try {
          const eventDuration = EventDurationInMinutes(this.eventDetails)
          return FormatDuration(eventDuration, DESIRED_DURATIONS.HoursMinutes)
        } catch (e) {
          return ''
        }
      },
      eventType: function() {
        try { return this.eventDetails.eventType } catch (e) { return '' }
      },
      instructor: function() {
        try {
          return `${this.eventDetails.instructor.firstName} ${this.eventDetails.instructor.lastName}`
        } catch (e) {
          //console.warn("Error when getting instructor name:", e)
          return 'No instructor assigned'
        }
      },
      aboutInstructor: function() {
        try { return this.eventDetails.instructor.employer.items[0].courseInstructorDetails.aboutText } catch (e) { return 'Instructor details to follow' }
      },
      providerName: function() {
        try { return this.eventDetails.provider.name } catch (e) { return '' }
      },
      providerDescription: function() {
        try { return this.eventDetails.provider.courseProviderDetails.description } catch (e) { return '' }
      },
      sessions: function() {
        try {
          if (this.eventDetails.eventType !== EVENT_TYPE.PAID) {
            return [
              {
                price: {},
                event: this.eventDetails
              }
            ]
          }

          const sessions = this.eventDetails.prices.items.map((price) => {
            return {
              price,
              event: this.eventDetails
            }
          })
          //console.log("Sessions:", sessions)
          return sessions.filter((session) => {
            return session.status !== EVENT_PRICE_STATUS.DELETED
          })
        } catch (err) {
          //console.warn("error when creating sessions:", err)
          return []
        }
      },
      eventLogo: function() {
        try { return this.eventDetails.eventLogo.imageKey } catch (e) {
          //console.warn("Error getting imageKey:", e)
          return ''
        }
      },
      eventId: function() {
        try { return this.$route.params.eventId } catch (err) { return '' }
      },
    },

    async mounted() {
      this.$parent.$parent.$parent.refreshMenu('events');

      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      })

      try {
        // Fetch the event details from the server
        const eventService = new EventService(this.$store)
        const eventDetails = await eventService.GetEventDetailsForBookingScreen(this.eventId)
        console.log(eventDetails)
        this.eventDetails = eventDetails
        this.is_data_fetched = true;
        return
      } catch (err) {
        console.warn("Unable to get event details:", err)
        this.errorFetchingDetails = true
      }

      // If there wasn't an event id, show an error
      this.eventIdProvided = false
      this.is_data_fetched = true
    },


    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    }

  }
</script>

<style scoped>
  .v-btn {
    text-decoration: none;
    text-transform: capitalize;
  }

  .container-image {
    padding: 0;
  }

  .container-rounded {
    border-radius: 10px;
    /*box-shadow: 0 0 5px rgba(1, 1, 1, 0.7);*/
    background-color: #FFFFFF;
    padding: 5px;
    margin: 5px;
    /*min-height: 425px;*/
  }

  .container-rounded-grey {
    border-radius: 10px;
    /*box-shadow: 0 0 5px rgba(1, 1, 1, 0.7);*/
    background-color: rgba(243, 246,248, 0.7);
    padding: 5px;
    margin: 5px;
    /*min-height: 425px;*/
  }

  .ABC_in_Scaffolding {
    opacity: 1;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: left;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(39,62,75,1);
    letter-spacing: 0.2px;
    font-family: basefont;
  }

  .Duration__1_hour {
    opacity: 1;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    /*color: rgba(139,139,139,1);*/
    letter-spacing: 0.2px;
  }

  [role=columnheader] {
    font-weight: bold !important;
  }

  .session-tile {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    padding: 5px;
    margin-bottom: 10px;
  }

  .session-tile > p {
    margin: 0.3em;
  }

</style>


import { DEFAULT_PRICE_ITEM, EVENT_PRICE_SERVER_STATUS } from '@/constants/EventPriceConstants'
//import { v4 as uuidv4 } from 'uuid';

class EventPrice {

  constructor(priceItem) {

    this.status = EVENT_PRICE_SERVER_STATUS.NO_CHANGE
    // If no item was provided, set to default
    if (!priceItem) {
      this.priceItem = DEFAULT_PRICE_ITEM
      this.priceItem.id = "0"
      return
    }
    // If this is a duplication request
    if (priceItem instanceof EventPrice) {
      console.log("Duplication of EventPrice;", priceItem)
      if (priceItem.status === EVENT_PRICE_SERVER_STATUS.TO_CREATE) { this.status = EVENT_PRICE_SERVER_STATUS.TO_CREATE }
      if (priceItem.status === EVENT_PRICE_SERVER_STATUS.TO_UPDATE) { this.status = EVENT_PRICE_SERVER_STATUS.TO_UPDATE }
      if (priceItem.status === EVENT_PRICE_SERVER_STATUS.TO_DELETE) { this.status = EVENT_PRICE_SERVER_STATUS.TO_DELETE }
      this.priceItem = {
        ...DEFAULT_PRICE_ITEM,
        ...priceItem.getGraphQLObject()
      }
      return
    }
    // Otherwise, just combine defaults and given details
    this.priceItem = {
      ...DEFAULT_PRICE_ITEM,
      ...priceItem
    }
  }

  getId() {
    return this.priceItem.id
  }

  getField(fieldName) {
    try {
      return this.priceItem[fieldName]
    } catch (err) {
      console.warn("Error getting field with name:", fieldName, err)
      return ""
    }
  }

  setStatus(status) {
    if (!EVENT_PRICE_SERVER_STATUS[status]) {
      console.warn("setStatus was passed an unexpected status")
      return
    }
    this.status = status
  }

  setField(fieldName, value) {
    try {
      this.priceItem[fieldName] = value
    } catch (err) {
      console.warn("Error setting field with name:", fieldName, err)
    }
  }

  getPrice(currencyCode) {
    if (!currencyCode) {
      return this.priceItem.priceGBP
    }
    if (this.priceItem[`price${currencyCode.toUpperCase()}`]) {
      return this.priceItem[`price${currencyCode.toUpperCase()}`]
    }
    return "0"
  }

  getGraphQLObject() {
    return this.priceItem
  }


}

export default EventPrice


import UserLogin from '../components/notsignedin-login.vue'
import UserSignUp from '../components/notsignedin-signup'
import UserForgotPassword from '../components/notsignedin-forgotpassword'
import UserConfirmEmail from '../components/notsignedin-confirmemail'
import UserResetPassword from '../components/notsignedin-resetpassword'
import UserForceChangePassword from '../components/notsignedin-forcechangepassword.vue'
import UserSocialSignOn from '@/components/notsignedin-social-sign-on.vue'

import { LAYOUTS } from '@/constants/LayoutConstants'
import * as StoreActions from '../constants/StoreActions'
import store from '../store/index'

const baseUrl = process.env.BASE_URL;

export const routes = [
  {
    name: 'Login',
    path: baseUrl,
    meta: { title: 'MyProPass - User Login', requiresAuth: false, requiresProvider: false, requiresAdmin: false },
    component: UserLogin,
    display: 'Login',
    beforeEnter: (to, from, next) => { SetNotSignedInLayout(to, from, next); }
  },
  {
    name: 'UserSignUp',
    path: baseUrl +'signup',
    meta: { title: 'MyProPass - User Sign Up', requiresAuth: false, requiresProvider: false, requiresAdmin: false },
    component: UserSignUp,
    display: 'User Sign Up',
    beforeEnter: (to, from, next) => { SetNotSignedInLayout(to, from, next); }
  },
  {
    name: 'UserForgotPassword',
    path: baseUrl +'forgot-password',
    meta: { title: 'MyProPass - User Forgot Password', requiresAuth: false, requiresProvider: false, requiresAdmin: false },
    component: UserForgotPassword,
    display: 'Forgot Password',
    beforeEnter: (to, from, next) => { SetNotSignedInLayout(to, from, next); }
  },
  {
    name: 'UserConfirmEmail',
    path: baseUrl +'confirm-email',
    meta: { title: 'MyProPass - User Confirm Email', requiresAuth: false, requiresProvider: false, requiresAdmin: false },
    component: UserConfirmEmail,
    display: 'Confirm Email',
    beforeEnter: (to, from, next) => { SetNotSignedInLayout(to, from, next); }
  },
  {
    name: 'UserResetPassword',
    path: baseUrl +'reset-password',
    meta: { title: 'MyProPass - User Reset Password', requiresAuth: false, requiresProvider: false, requiresAdmin: false },
    component: UserResetPassword,
    display: 'Reset Password',
    beforeEnter: (to, from, next) => { SetNotSignedInLayout(to, from, next); }
  },
  {
    name: 'UserForceChangePassword',
    path: baseUrl +'force-reset-password',
    meta: { title: 'MyProPass - User Force Reset Password', requiresAuth: false, requiresProvider: false, requiresAdmin: false },
    component: UserForceChangePassword,
    display: 'Create Password',
    beforeEnter: (to, from, next) => { SetNotSignedInLayout(to, from, next); }
  },
  {
    name: 'UserGoogleSignIn',
    path: baseUrl +'sso-callback',
    meta: { title: 'MyProPass - User Google SSO Callback', requiresAuth: false, requiresProvider: false, requiresAdmin: false },
    component: UserSocialSignOn,
    display: 'Social Sign On',
    beforeEnter: (to, from, next) => { SetNotSignedInLayout(to, from, next); }
  },
]

async function SetNotSignedInLayout(to, from, next)
{
  await store.commit(StoreActions.SET_LAYOUT, LAYOUTS.NOT_SIGNED_IN);
  next();
}

export default routes

<template>
  <v-container id="event-stepper-container" fluid pa-0 class="vld-parent">
    <loading :active.sync="isLoading" :is-full-page="fullPage" loader="dots"></loading>
    <loading :active.sync="isSavingEvent" :is-full-page="fullPage" loader="dots"></loading>
    <v-layout ma-0 row wrap>
      <v-flex xs112 md12>
        <v-stepper v-model="step" vertical class="stepper-container">
          <v-stepper-header>
            <v-stepper-step step="1" :complete="step > 1">Event Details</v-stepper-step>
            <v-stepper-step step="2" :complete="step > 2">Price Options</v-stepper-step>
            <v-stepper-step step="3" :complete="step > 3">Preview Event</v-stepper-step>
            <v-stepper-step step="4">Certificate Options</v-stepper-step>
          </v-stepper-header>
          <v-stepper-items style="font-weight:normal;">


            <!-- Start Step 1 - Basic details -->
            <v-stepper-content step="1">
              <v-form ref="form" class="container-rounded" lazy-validation>
                <v-layout justify-center>
                  <v-flex xs12 md7>
                    <form class="form-horizontal">
                      <div>
                        <br />

                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="title">Course Title  <span class="red--text">*</span></label>
                          <input type="text" class="form-control col-sm-8" id="title" v-model="title" :disabled="(disabled || eventHasAttendees) && !userInAdminMode">
                          <div v-show="showUserCurrentErrors && $v.title.$error" class="text-danger warning-holder">
                            <small v-if="!$v.title.required">Title is required</small>
                            <small v-if="!$v.title.maxLength">Title should be less than 100 characters</small>
                          </div>
                        </div>

                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="description">Description <span class="red--text">*</span></label>
                          <!--<textarea rows="4" class="form-control col-sm-8" id="description" v-model="description" :disabled="disabled"></textarea>-->
                          <div class="col-sm-8 p-0 mb-2">
                            <vue-editor id="description" v-model="description" :disabled="disabled"></vue-editor>
                          </div>
                          <div v-if="showUserCurrentErrors && $v.description.$error" class="text-danger warning-holder">
                            <small v-if="!$v.description.required">Description is required</small>
                            <small v-if="!$v.description.maxLength">Description should be less than 800 characters</small>
                          </div>
                        </div>

                        <div class="form-row vertical-center" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="accessLevel">Visibility / Access Level <span class="red--text">*</span></label>
                          <!-- TODO: FUTURE: Fix how ugly this code is to get the radio buttons side by side -->
                          <input type="radio" name="accessLevel" :value="EVENT_ACCESS_LEVEL.PUBLIC" v-model="accessLevel" :disabled="disabled"><p>Public</p>
                          <input type="radio" name="accessLevel" :value="EVENT_ACCESS_LEVEL.PRIVATE" v-model="accessLevel" :disabled="disabled"><p>Private</p>
                          <div v-if="showUserCurrentErrors && $v.accessLevel.$error" class="text-danger warning-holder">
                            <small v-if="!$v.accessLevel.required">Visibility / access level is required</small>
                          </div>
                        </div>

                        <div class="form-row vertical-center" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="eventMode">Mode of Event <span class="red--text">*</span></label>
                          <!-- TODO: FUTURE: Fix how ugly this code is to get the radio buttons side by side -->
                          <input type="radio" name="eventMode" :value="EVENT_MODE.INPERSON" v-model="eventMode" :disabled="disabled"><p>Offline</p>
                          <input type="radio" name="eventMode" :value="EVENT_MODE.VIRTUAL" v-model="eventMode" :disabled="disabled"><p>Online</p>
                          <div v-if="showUserCurrentErrors && $v.eventMode.$error" class="text-danger warning-holder">
                            <small v-if="!$v.eventMode.required">Mode is required</small>
                          </div>
                        </div>

                        <div v-show="eventMode === EVENT_MODE.INPERSON">

                          <div class="form-row" style="font-weight:normal;">
                            <label class="col-sm-4 control-label" for="address">Search Address</label>
                            <div class='address-search-bar-container col-sm-8 p-0'>
                              <v-autocomplete ref="addressSearch" id="addressSearch" name="addressSearch" class="pt-0" hide-details no-filter :items='addressAutoCompleteOptions' item-text="description" item-value="place_id" :search-input.sync="addressSearch" placeholder="Search" v-model="addressSearchPlaceId" v-bind:disabled="addressDisabled" />
                              <v-btn id="btnFindAddress" class='m-0 ml-2' color="rgb(0,171,173)" @click="RequestAddressSearch">Search</v-btn>
                            </div>
                          </div>

                          <div class="form-row" style="font-weight:normal;">
                            <div class="col-sm-4 control-label">Address Line 1 <span class="red--text">*</span></div>
                            <input type="text" class="form-control col-sm-8" id="line1" name="line1" v-model="addressLine1" placeholder="Line 1" v-bind:disabled="addressDisabled" />
                            <div v-if="showUserCurrentErrors && $v.addressLine1.$error" class="text-danger warning-holder">
                              <small v-if="!$v.addressLine1.required">Address Line 1 is required</small>
                              <small v-if="!$v.addressLine1.maxLength">Address Line 1 should be less than 100 characters</small>
                            </div>
                          </div>

                          <div class="form-row" style="font-weight:normal;">
                            <div class="col-sm-4 control-label">Address Line 2</div>
                            <input type="text" class="form-control col-sm-8" id="line2" name="line2" v-model="addressLine2" placeholder="Line 2" v-bind:disabled="addressDisabled" />
                            <div v-if="showUserCurrentErrors && $v.addressLine2.$error" class="text-danger warning-holder">
                              <small v-if="!$v.addressLine2.maxLength">Address Line 2 should be less than 100 characters</small>
                            </div>
                          </div>

                          <div class="form-row" style="font-weight:normal;">
                            <div class="col-sm-4 control-label">Address Line 3</div>
                            <input type="text" class="form-control col-sm-8" id="line3" name="line3" v-model="addressLine3" placeholder="Line 3" v-bind:disabled="addressDisabled" />
                            <div v-if="showUserCurrentErrors && $v.addressLine3.$error" class="text-danger warning-holder">
                              <small v-if="!$v.addressLine3.maxLength">Address Line 3 should be less than 100 characters</small>
                            </div>
                          </div>

                          <div class="form-row" style="font-weight:normal;">
                            <div class="col-sm-4 control-label">City <span class="red--text">*</span></div>
                            <input type="text" class="form-control col-sm-8" id="city" name="city" v-model="addressCity" placeholder="City (to be displayed on certificate)" v-bind:disabled="addressDisabled" />
                            <div v-if="showUserCurrentErrors && $v.addressCity.$error" class="text-danger warning-holder">
                              <small v-if="!$v.addressCity.required">City is required</small>
                              <small v-if="!$v.addressCity.maxLength">City should be less than 100 characters</small>
                            </div>
                          </div>

                          <div class="form-row" style="font-weight:normal;">
                            <div class="col-sm-4 control-label">State/County <span class="red--text">*</span></div>
                            <input type="text" class="form-control col-sm-8" id="region" name="region" v-model="addressRegion" placeholder="State/County" v-bind:disabled="addressDisabled" />
                            <div v-if="showUserCurrentErrors && $v.addressRegion.$error" class="text-danger warning-holder">
                              <small v-if="!$v.addressRegion.required">State/County is required</small>
                              <small v-if="!$v.addressRegion.maxLength">State/County should be less than 100 characters</small>
                            </div>
                          </div>

                          <div class="form-row" style="font-weight:normal;">
                            <div class="col-sm-4 control-label">Post Code <span class="red--text">*</span></div>
                            <input type="text" class="form-control col-sm-8" id="postalCode" name="postalCode" v-model="addressPostalCode" placeholder="Post Code" v-bind:disabled="addressDisabled" />
                            <div v-if="showUserCurrentErrors && $v.addressPostalCode.$error" class="text-danger warning-holder">
                              <small v-if="!$v.addressPostalCode.required">Post Code is required</small>
                              <small v-if="!$v.addressPostalCode.maxLength">Post Code should be less than 10 characters</small>
                            </div>
                          </div>

                          <!--
                          <div class="form-row" style="font-weight:normal;">
                            <div class="col-sm-4 control-label">Country <span class="red--text">*</span></div>
                            <input type="text" class="form-control col-sm-8" id="country" name="country" v-model="addressCountry" placeholder="Country" v-bind:disabled="addressDisabled" />
                            <div v-if="showUserCurrentErrors && $v.addressCountry.$error" class="text-danger">
                              <small v-if="!$v.addressCountry.required">Country is required</small>
                              <small v-if="!$v.addressCountry.maxLength">Country should be less than 100 characters</small>
                            </div>
                          </div>
                          -->

                        </div>

                        <div v-show="eventMode === EVENT_MODE.VIRTUAL">
                          <div class="form-row" style="font-weight:normal;">
                            <div class="col-sm-4 control-label">Online URL<span class="red--text">*</span></div>
                            <div class="col-sm-8">
                              <input type="text" class="form-control col-sm-8" v-model="onlineURL" id="onlineURL" name="onlineURL" placeholder="Include http(s):// www" v-bind:disabled="addressDisabled" />
                            </div>
                            <div v-if="showUserCurrentErrors && $v.onlineURL.$error" class="text-danger warning-holder">
                              <small v-if="!$v.onlineURL.required">Online URL is required</small>
                              <small v-if="!$v.onlineURL.maxLength">Online URL should be less than 500 characters</small>
                            </div>
                          </div>
                        </div>

                        <div class="form-row vertical-center" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="type">Type of Event <span class="red--text">*</span></label>
                          <input type="radio" name="type" :value="EVENT_TYPE.FREE" v-model="eventType" :disabled="disabled"><p>Free</p>
                          <input type="radio" name="type" :value="EVENT_TYPE.PAID" v-model="eventType" :disabled="disabled"><p>Paid</p>
                          <input type="radio" name="type" :value="EVENT_TYPE.CONFERENCE" v-model="eventType" :disabled="disabled"><p>Conference</p>
                          <div v-if="showUserCurrentErrors && $v.eventType.$error" class="text-danger warning-holder">
                            <small v-if="!$v.eventType.required">Type is required</small>
                          </div>
                        </div>

                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="instructorId">Instructor <span class="red--text">*</span></label>
                          <select id="instructorId" name="instructorId" class="form-control col-sm-8" v-model="instructorId" :disabled="disabled">
                            <option disabled value="0"> -- Please select an instructor -- </option>
                            <option v-for="instructor in this.employeeList" v-bind:key="instructor.user.id" v-bind:value="instructor.user.id">
                              {{ instructor.user.firstName }} {{ instructor.user.lastName }}
                            </option>
                          </select>
                          <div v-if="showUserCurrentErrors && $v.instructorId.$error" class="text-danger warning-holder">
                            <small v-if="!$v.instructorId.validIndex">Instructor is required</small>
                          </div>
                        </div>

                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="logo">Logo/Image <span class="red--text">*</span></label>
                          <div class="col-sm-8" style="padding:0;">
                            <image-uploader
                              pickerId="eventLogo"
                              :isPicker="true"
                              :disabled="disabled"
                              :closePopover="ToggleImagePopover"
                              :imageType="IMAGE_TYPES.EVENT_LOGO"
                              :imageOptions="eventLogos"
                              :currentImage="eventLogo"
                              :onImageSelectionChange="OnImageSelectionChange" />
                          </div>

                        </div>
                        <div v-if="showUserCurrentErrors && $v.eventLogo.$error" class="text-danger warning-holder">
                          <small v-if="!$v.eventLogo.required">Logo is required</small>
                        </div>
                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="prerequisites">Prerequisites</label>
                          <textarea rows="4" class="form-control col-sm-8" id="prerequisites" v-model="preRequisites" :disabled="disabled"></textarea>
                          <div v-if="showUserCurrentErrors && $v.preRequisites.$error" class="text-danger warning-holder">
                            <small v-if="!$v.preRequisites.required">Prerequisites is required</small>
                            <small v-if="!$v.preRequisites.maxLength">Prerequisites should be less than 500 characters</small>
                          </div>
                        </div>
                        <div class="form-row vertical-center" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="identity">Proof of Identity <span class="red--text">*</span></label>
                          <input type="radio" name="identity" :value="EVENT_PROOF_OF_IDENTITY.REQUIRED" v-model="proofOfIdentity" :disabled="disabled"><p>Required</p>
                          <input type="radio" name="identity" :value="EVENT_PROOF_OF_IDENTITY.NOTREQUIRED" v-model="proofOfIdentity" :disabled="disabled"><p>Not required</p>
                          <div v-if="showUserCurrentErrors && $v.proofOfIdentity.$error" class="text-danger warning-holder">
                            <small v-if="!$v.proofOfIdentity.required">Proof of Identity is required</small>
                          </div>
                        </div>
                      </div>
                    </form>
                  </v-flex>

                  <v-flex xs12 md4 offset-md1>
                    <form class="form-horizontal right-column">
                      <div>
                        <br />

                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-5 control-label" for="accreditationLogoId">Course Accreditation Logo</label>
                          <select class="form-control col-sm-7" v-model="accreditationLogoId" :disabled="disabled">
                            <option key="None" value="0" default>
                              None
                            </option>
                            <option v-for="logo in filteredAccreditationLogos" v-bind:key="logo.name" v-bind:value="logo.id">
                              {{ logo.name }}
                            </option>
                          </select>
                        </div>

                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-5 control-label" for="maximumAttendees">Maximum Attendees <span v-if="eventType !== EVENT_TYPE.CONFERENCE" class="red--text">*</span></label>
                          <input type="text" class="form-control col-sm-7" id="maximumAttendees" name="maximumAttendees" v-model="maximumAttendees" :disabled="(attendeesDisabled || eventHasAttendees) && !userInAdminMode">
                          <div v-if="showUserCurrentErrors && $v.maximumAttendees.$error" class="text-danger warning-holder">
                            <small v-if="!$v.maximumAttendees.attendeesRequired">Maximum Attendees is required</small>
                            <small v-if="!$v.maximumAttendees.integer">Maximum Attendees should be an integer</small>
                            <small v-if="!$v.maximumAttendees.attendeesMinValue">Maximum Attendees should be greater than 0</small>
                          </div>
                        </div>

                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-5 control-label" for="targetAudience">Target Audience </label>
                          <textarea rows="4" class="form-control col-sm-7" id="targetAudience" name="targetAudience" v-model="targetAudience" :disabled="disabled"></textarea>
                          <div v-if="showUserCurrentErrors && $v.targetAudience.$error" class="text-danger warning-holder">
                            <small v-if="!$v.targetAudience.required">Target Audience is required</small>
                            <small v-if="!$v.targetAudience.maxLength">Target Audience should be less than 500 characters</small>
                          </div>
                        </div>

                        <div class="form-row" style="font-weight:normal;" v-if="eventType === EVENT_TYPE.CONFERENCE">
                          <label class="col-sm-5 control-label" for="eventCode">Event Code</label>
                          <input type="text" class="form-control col-sm-7" id="eventCode" name="eventCode" v-model="code" disabled>
                        </div>

                        <!--
                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-5 control-label mt-3" for="date">Event Date <span class="red--text">*</span></label>
                          <v-menu v-model="dateMenuOpen" lazy :close-on-content-click="false" offset-y>
                            <template style="width:100px" v-slot:activator="{ on }">
                              <v-text-field v-model="dateString" prepend-icon="event" class="col-sm-7" :disabled="disabled || eventHasAttendees" v-on="on" />
                            </template>
                            <v-date-picker dark class="mt-4" color="green lighten-1" event-color="green" header-color="primary" v-model="date" @input="dateMenuOpen = false"></v-date-picker>
                          </v-menu>
                          <div v-if="showUserCurrentErrors && $v.date.$error" class="text-danger warning-holder">
                            <small v-if="!$v.date.required">Event Date is required</small>
                            <small v-if="!$v.date.validDate">Event Date is not valid.</small>
                            <small v-if="!$v.date.futureDateTime">Event Date should be greater than current date time.</small>
                          </div>
                        </div>
                        -->

                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-5 control-label" for="startTime">Start Date & Time <span class="red--text">*</span></label>
                          <input type="datetime-local" class='col-sm-7 form-control' v-model='startDateTimeLocal' :disabled="(disabled || eventHasAttendees) && !userInAdminMode" />
                          <div v-if="showUserCurrentErrors && $v.startTime.$error" class="text-danger warning-holder">
                            <small v-if="!$v.startTime.required">Start Time is required.</small>
                            <small v-if="!$v.startTime.futureDateTime">Start Time must be in the future.</small>
                            <small v-if="!$v.startTime.validStartTime">Start Time must be before end time.</small>
                          </div>
                        </div>

                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-5 control-label" for="endTime">End Date & Time <span class="red--text">*</span></label>
                          <input type="datetime-local" class='col-sm-7 form-control' v-model='endDateTimeLocal' :disabled="(disabled || eventHasAttendees) && !userInAdminMode" />
                          <div v-if="showUserCurrentErrors && $v.endTime.$error" class="text-danger warning-holder">
                            <small v-if="!$v.endTime.required">End Time is required.</small>
                            <small v-if="!$v.endTime.futureDateTime">End Time must be in the future.</small>
                            <small v-if="!$v.endTime.validEndTime">Minimum event length is 16mins</small>
                          </div>
                        </div>

                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-5 control-label" for="attendanceHours">Duration (hrs)</label>
                          <input type="number" class='col-sm-7 form-control' v-model='attendanceHours' :disabled="(disabled || eventHasAttendees) && !userInAdminMode" />
                          <div v-if="showUserCurrentErrors && $v.attendanceHours.$error" class="text-danger warning-holder">
                            <small v-if="!$v.attendanceHours.validAttendanceHours">Duration is needed for multi-day events</small>
                          </div>
                        </div>

                        <!--
                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-5 control-label" for="cpdHours">Accredited CPD Hours</label>
                          <input type="number" class='col-sm-7 form-control' v-model='cpdHours' :disabled="disabled || eventHasAttendees" />
                          <div v-if="showUserCurrentErrors && $v.cpdHours.$error" class="text-danger warning-holder">
                          </div>
                        </div>
                        -->

                        <div class="form-row align-flex-ss" style="font-weight:normal;" v-if="eventType === EVENT_TYPE.CONFERENCE">
                          <label class="col-sm-5 control-label" for="eventValidityPeriod">Code Expiry Date <span class="red--text">*</span></label>
                          <div style="position: relative;">
                            <v-text-field :value="GetLocaleTimeString(eventValidityDateTime)" class="col-sm-7 mt-0 p-0" readonly :disabled="true"></v-text-field>
                            <datetime id="datetimepicker" input-class="form-control" class="position-over" type="datetime" v-model="eventValidityDateTime" format="dd-MM-yyyy HH:mm"></datetime>
                          </div>

                          <div v-if="showUserCurrentErrors && $v.eventValidityDateTime.$error" class="text-danger warning-holder">
                            <small v-if="!$v.eventValidityDateTime.validDateTime">Validity Period is required.</small>
                            <small v-if="!$v.eventValidityDateTime.futureDateTime">Validity Period end must be in the future.</small>
                            <small v-if="!$v.eventValidityDateTime.isAfterEndTime">Validity Period must be greater than event end time.</small>
                          </div>
                        </div>

                      </div>
                    </form>
                  </v-flex>
                </v-layout>
                <v-layout justify-end class="footer">
                  <v-flex xs12 text-right>
                    <div v-if="showUserCurrentErrors && $v.$invalid" class="text-danger" style="float:right">
                      <small>Please review your input. Errors are highlighted in red.</small>
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout justify-end class="footer">
                  <v-flex xs12 text-right>
                    <!--{{ this.status }} -->
                    <v-btn id="deleteEventBtn" color="rgb(0,171,173)" class="red--text" :disabled="deleteEventButtonDisabled" @click="OnDeleteEventClick">Delete Event</v-btn>
                    <v-btn v-if="![EVENT_STEPPER_STATE.CREATE, EVENT_STEPPER_STATE.COPY].includes(internalStepperState)" color="rgb(0,171,173)" class="red--text" :disabled="cancelEventButtonDisabled" @click="OnCancelClick">Cancel Event</v-btn>
                    <v-btn id="btnNext" color="rgb(0,171,173)" @click="nextStep">Next</v-btn>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-stepper-content>
            <!-- End Step 1 -->

            <!-- Start Step 2 - Price Options -->
            <v-stepper-content step="2" style="border-left:0px;">
              <v-form ref="form" class="container-rounded" lazy-validation>
                <v-layout style='position: relative;'>

                  <v-flex xs12 class="container-rounded pa-0 ma-0">
                    <v-container fluid pa-0 mt-3>

                      <div v-if="eventType !== EVENT_TYPE.FREE" class='ml-3 mb-3' @click="AddPriceOptionClicked">
                        <v-icon color="rgb(0,171,173)">add_circle</v-icon> &nbsp;Add Price Option
                      </div>

                      <!-- Price Table -->
                      <v-data-table :headers="PRICE_TABLE_HEADERS" :items="prices" hide-actions :custom-sort="priceTableSort">
                        <template slot="headerCell" slot-scope="{ header }">
                          <span class="subheading font-weight-bold text--darken-3" v-text="header.text" />
                        </template>
                        <template v-slot:items="props">
                          <td>{{ props.item.getGraphQLObject().title }}</td>
                          <td>{{ props.item.getGraphQLObject().description }}</td>
                          <td>£{{ props.item.getPrice("GBP") }}</td>
                          <!--<td>${{ props.item.priceUSD }}</td>-->
                          <!--<td>€{{ props.item.priceEUR }}</td>-->
                          <td>
                            <div v-if="eventType !== EVENT_TYPE.FREE" class="action-cell">
                              <v-img :src="require('@/images/pencil-edit-button.png')" contain height="15px" width="15px" @click="EditPriceOptionClicked(props.item)"></v-img>
                              <v-img :src="require('@/images/trash32.png')" contain height="15px" width="15px" @click="DeletePriceOption(props.item)"></v-img>
                            </div>
                          </td>
                        </template>
                      </v-data-table>

                    </v-container>
                    <v-container fluid ma-0 pr-0 pb-1 style='position: absolute; bottom: 0px;'>
                      <v-layout justify-end style="position:relative;top:10px;">
                        <v-btn id="stepPricesPrevious" color="rgb(0,171,173)" @click="previousStep">Previous</v-btn>
                        <v-btn id="stepPricesNext" :disabled="step2NextButtonDisabled" color="rgb(0,171,173)" @click="nextStep">Next</v-btn>
                      </v-layout>
                    </v-container>
                  </v-flex>

                </v-layout>
              </v-form>

              <v-layout row justify-center v-if='priceOptionWindow.show'>
                <v-dialog id="priceOptions" v-model="priceOptionWindow.show" persistent transition="dialog-transition" max-width="600">
                  <event-price-popover :closePopover="ToggleEventPricePopover" :eventObject="this.currentEvent" :displayType="priceOptionWindow.displayType" :priceOption="priceOptionWindow.priceOption"></event-price-popover>
                </v-dialog>
              </v-layout>


            </v-stepper-content>
            <!-- End Step 2 -->

            <!-- Start Step 3 - Review -->
            <v-stepper-content id="step2Content" step="3" style="padding:0px;border-left:0px;">
              <v-container fluid grid-list-md pa-0>
                <v-layout pa-0 ma-0>
                  <v-flex xs12 class="container-rounded pa-0 ma-0">
                    <v-container fluid pa-0 mt-3>
                      <v-layout ma-0>
                        <v-flex xs12 pa-0>
                          <v-container ma-0 pa-0 fluid style="background-color: rgba(243, 246,248, 0.7);">
                            <v-layout>
                              <v-flex xs12 md2>
                                <v-container fill-height ma-0 pa-0>
                                  <v-layout ma-0 pa-0>
                                    <event-logo v-bind:imageKey="eventLogo.imageKey" level="public" style="height: 100%; width: 100px;" />
                                  </v-layout>
                                </v-container>
                              </v-flex>
                              <v-flex xs12 md10>
                                <v-container fluid fill-height ma-0 pa-0>
                                  <v-layout align-content-start ma-0 pa-0>
                                    <div class="ABC_in_Scaffolding">
                                      <span>{{ title }}</span><br />
                                      <div class="Duration__1_hour">
                                        <span class="font-12">{{ duration }}</span> |
                                        <span v-if="eventType === EVENT_TYPE.FREE">Free</span>
                                        <span v-else-if="eventType === EVENT_TYPE.PAID">Paid</span>
                                        <span v-else>Conference</span>
                                        <br />
                                        <span class="font-12">Provider: {{ provider.name }}</span>
                                        <br />
                                        <div v-html="provider.courseProviderDetails.description" />
                                      </div>
                                    </div>
                                  </v-layout>
                                </v-container>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-flex>
                      </v-layout>
                      <v-layout ma-0 pa-0 justify-center align-content-center>
                        <v-flex xs12>
                          <div class="ABC_in_Scaffolding" style="margin-top:10px;margin-bottom:10px;">
                            <span>When</span><br />
                            <div class="Duration__1_hour">
                              <span style="color: #666"><strong>Start:</strong> {{ GetLocaleDateTimeString(startDateTime) }}</span>
                              <br />
                              <span style="color: #666"><strong>End:</strong> {{ GetLocaleDateTimeString(endDateTime) }}</span>
                              <br />
                            </div>
                          </div><br /><br />

                          <div class="ABC_in_Scaffolding" style="margin-top:10px;margin-bottom:10px;">
                            <span>Description</span><br />
                            <div class="Duration__1_hour">
                              <span v-html="description"></span>
                              <br />
                            </div>
                          </div><br />

                          <div class="ABC_in_Scaffolding" style="margin-bottom:10px;">
                            <span>Instructor</span><br />
                            <div class="Duration__1_hour">
                              <span style="color: #666">{{ eventInstructor.firstName }} {{ eventInstructor.lastName }}</span><br />
                              <span style="color: #666" v-html="eventInstructorAboutText"></span>
                              <br />
                            </div>
                          </div><br />

                          <div class="ABC_in_Scaffolding" style="margin-bottom:10px;">
                            <span>Requirements</span><br />
                            <div class="Duration__1_hour">
                              <span>{{ preRequisites }}</span>
                              <br />
                            </div>
                          </div><br />

                          <div class="ABC_in_Scaffolding mt-2">
                            <span>Target Audience</span><br />
                            <div class="Duration__1_hour">
                              <span>{{ targetAudience }}</span>
                            </div>
                          </div>

                        </v-flex>
                      </v-layout>
                      <v-layout ma-0 pa-0>
                        <v-flex xs12 ma-0>
                          <div class="ABC_in_Scaffolding mt-3">
                            <span>Schedule</span><br />
                          </div><br />
                          <v-data-table :headers="scheduleHeaders" :items="scheduledEvents" class="elevation-1 table-border" hide-actions :custom-sort="scheduleTableSort">
                            <template v-slot:items="props">
                              <td>{{ GetLocaleDateString(props.item.date) }}</td>
                              <td>
                                <span v-if="props.item.eventType === EVENT_TYPE.PAID">£{{ props.item.price }}</span>
                                <span v-else>Free</span>
                              </td>
                              <td>{{ props.item.location }}</td>
                              <td>{{ props.item.availableseats }}</td>

                              <td style="margin:0px;padding:0px;text-align:center">
                                <span>
                                  <v-btn v-if="GetActionButtonsForSession(props.item).includes(ACTION_BUTTONS.BOOK_SESSION)" color="rgb(0,171,173)" small disabled>
                                    Book Course
                                  </v-btn>
                                  <v-btn v-if="GetActionButtonsForSession(props.item).includes(ACTION_BUTTONS.NOTIFY_ME)" color="rgb(0,171,173)" small disabled>
                                    Notify Me
                                  </v-btn>
                                  <v-btn v-if="GetActionButtonsForSession(props.item).includes(ACTION_BUTTONS.ADD_TO_CALENDAR)" color="rgb(0,171,173)" small disabled>
                                    Add To Calendar
                                  </v-btn>
                                  <v-btn v-if="GetActionButtonsForSession(props.item).includes(ACTION_BUTTONS.CONTACT_PROVIDER)" color="rgb(0,171,173)" small disabled>
                                    Contact Provider
                                  </v-btn>
                                </span>
                              </td>

                            </template>
                          </v-data-table>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-container fluid ma-0 pr-0>
                      <v-layout justify-end style="position:relative;top:10px;">
                        <v-btn id="stepReviewPrevious" color="rgb(0,171,173)" @click="previousStep">Previous</v-btn>
                        <v-btn id="stepReviewNext" color="rgb(0,171,173)" @click="nextStep">Next</v-btn>
                      </v-layout>
                    </v-container>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-stepper-content>
            <!-- End Step 3 -->

            <!-- Start Step 4 - Certificate Options-->
            <v-stepper-content step="4" style="border-left:0px;">
              <v-form ref="form" class="container-rounded" lazy-validation>
                <v-layout>

                  <v-flex xs12 md7>
                    <form class="form-horizontal">
                      <div>
                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="chooseTemplate">Choose a Template</label>
                          <select class="form-control col-sm-7" id="chooseTemplate" v-model="certificateTemplateId" :disabled="disabled">
                            <option key="None" value="" default>
                              Please Select A Certificate Template
                            </option>
                            <option v-for="template in certificateTemplates" v-bind:key="template.id" v-bind:value="template.id">
                              {{ template.name }}
                            </option>
                          </select>
                          <div v-if="showUserCurrentErrors && $v.certificateTemplateId.$error" class="text-danger col-sm-3 warning-holder">
                            <small v-if="!$v.certificateTemplateId.required">Certificate Template is required</small>
                            <small v-if="!$v.certificateTemplateId.isAcceptableValue">Certificate Template is required</small>
                          </div>
                        </div>
                        <br />

                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="signs">Signatures</label>
                          <div class="col-sm-7" style="padding:0;">
                            <image-uploader
                              pickerId="signatureImage"
                              :isPicker="true"
                              :disabled="disabled"
                              :closePopover="ToggleImagePopover"
                              :imageType="IMAGE_TYPES.CERTIFICATE_SIGNATURE"
                              :imageOptions="signatures"
                              :currentImage="signature"
                              :onImageSelectionChange="OnImageSelectionChange" />
                          </div>
                          <div v-if="showUserCurrentErrors && $v.signatureId.$error" class="text-danger col-sm-3 warning-holder">
                            <small v-if="!$v.signatureId.required">Signature is required</small>
                          </div>
                        </div>
                        <br />

                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="useCertificateSignerName">Use Alternative Signatory for Certificate</label>
                          <div class="col-sm-7">
                            <input type='checkbox' id="useCertificateSignerName" v-model="useCertificateSignerName" />
                          </div>
                        </div>

                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="signs">Alternative Signatory Name</label>
                          <input type="text" class="form-control col-sm-7" id="certificateSignerName" name="certificateSignerName" v-model="certificateSignerName" :disabled="disabled || !useCertificateSignerName">
                        </div>
                        <br />

                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="certificatesExpire">Certificates Have Expiry Date</label>
                          <div class="col-sm-7">
                            <input type='checkbox' id="certificatesExpire" v-model="certificatesExpire" />
                          </div>
                        </div>

                        <div class="form-row" style="font-weight:normal;" v-if="certificatesExpire">
                          <label class="col-sm-4 control-label" for="certificateExpiryDateTime">Certificate Expiry Date <span class="red--text">*</span></label>
                          <input type="date" class='col-sm-7 form-control' v-model='certificateExpiryDateTime' :disabled="disabled || eventHasAttendees" />
                          <div v-if="showUserCurrentErrors && $v.certificateExpiryDateTime.$error" class="text-danger warning-holder">
                            <small v-if="!$v.certificateExpiryDateTime.required">Certificate expiry date is required.</small>
                            <small v-if="!$v.certificateExpiryDateTime.futureDateTime">Certificate expiry date must be in the future.</small>
                            <small v-if="!$v.certificateExpiryDateTime.afterEndDate">Certificate expiry date must be after the end date.</small>
                          </div>
                        </div>

                      </div>
                    </form>
                  </v-flex>

                  <v-flex xs12 md4 offset-md1>

                    <!--  Certificate Background Colour  -->
                    <div class="form-row" style="font-weight:normal;">
                      <label class="col-sm-5 control-label" for="colorBackground">Background Colour <span class="red--text">*</span></label>
                      <input type="text" class="form-control col-sm-7" id="colorBackground" name="colorBackground" v-model="colorBackground" :disabled="disabled">
                      <div v-if="showUserCurrentErrors && $v.colorBackground.$error" class="text-danger col-sm-5 warning-holder">
                        <small v-if="!$v.colorBackground.required">Background colour is required</small>
                        <small v-if="!$v.colorBackground.isCSSColor">Background colour should be a css colour definition</small>
                      </div>
                    </div>


                    <!--  Certificate Font Primary Colour  -->
                    <div class="form-row" style="font-weight:normal;">
                      <label class="col-sm-5 control-label" for="colorFontPrimary">Font Primary Colour <span class="red--text">*</span></label>
                      <input type="text" class="form-control col-sm-7" id="colorFontPrimary" name="colorFontPrimary" v-model="colorFontPrimary" :disabled="disabled">
                      <div v-if="showUserCurrentErrors && $v.colorFontPrimary.$error" class="text-danger col-sm-5 warning-holder">
                        <small v-if="!$v.colorFontPrimary.required">Font primary colour is required</small>
                        <small v-if="!$v.colorFontPrimary.isCSSColor">Font primary colour should be a css colour definition</small>
                      </div>
                    </div>


                    <!--  Certificate Font Secondary Colour  -->
                    <div class="form-row" style="font-weight:normal;">
                      <label class="col-sm-5 control-label" for="colorFontSecondary">Font Secondary Colour <span class="red--text">*</span></label>
                      <input type="text" class="form-control col-sm-7" id="colorFontSecondary" name="colorFontSecondary" v-model="colorFontSecondary" :disabled="disabled">
                      <div v-if="showUserCurrentErrors && $v.colorFontSecondary.$error" class="text-danger col-sm-5 warning-holder">
                        <small v-if="!$v.colorFontSecondary.required">Font secondary colour is required</small>
                        <small v-if="!$v.colorFontSecondary.isCSSColor">Font secondary colour should be a css colour definition</small>
                      </div>
                    </div>

                    <!--
                    <div class="certificate-preview-container">
                      <h5>Certificate Preview:</h5>
                      <div class="certificate-preview" :style="`background-color: ${colorBackground};`">
                        <div class="heading" :style="`color: ${colorFontPrimary};`">Heading</div>
                        <div class="body">
                          <div :style="`color: ${colorFontPrimary};`">Name: </div>
                          <div :style="`color: ${colorFontSecondary};`">John Smith</div>
                        </div>
                      </div>
                    </div>
                    -->
                    <v-btn id="btnDownloadExampleCertificate"  class="m-0 mt-3" color="rgb(0,171,173)" @click="GetCertificatePreview">Preview Certificate</v-btn>


                  </v-flex>


                </v-layout>
                <v-layout justify-end class="footer">
                  <v-flex xs12 text-right style="margin-top: 1em;">
                    <div v-if="showUserCurrentErrors && $v.$invalid" class="text-danger" style="float:right">
                      <small>Please review your input. Errors are highlighted in red.</small>
                    </div>
                  </v-flex>
                </v-layout>
                <div class="text-right">
                  <v-btn id="btnCertificatePrevious"  color="rgb(0,171,173)" @click="previousStep">Previous</v-btn>
                  <v-btn id="btnCancelEvent"    color="rgb(0,171,173)" @click="OnCancelClick"  :disabled="cancelEventButtonDisabled" >Cancel Event</v-btn>
                  <v-btn id="btnSaveEvent"      color="rgb(0,171,173)" @click="SaveEvent"      :disabled="saveButtonDisabled" >
                    {{
                      isSavingEvent ? "Please wait..." : "Save"
                    }}
                  </v-btn>
                  <v-btn id="btnPublishEvent"   color="rgb(0,171,173)" @click="OnPublishClick" :disabled="publishButtonDisabled" >
                    {{
                      isPublishingEvent ? "Please wait..." : "Publish"
                    }}
                  </v-btn>
                </div>


                <v-layout row justify-center>

                  <v-dialog id="cancelDialog" persistent v-model="canceldialog.show" transition="dialog-transition" max-width="450">
                    <v-card>
                      <v-card-text>
                        <v-flex>
                          <span style="font-weight:normal;">
                            Attendees may have already registered for the course.<br />
                            Cancellation needs to be in line with Terms and Conditions <br />
                            agreed with the Attendees.
                          </span>
                        </v-flex>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn @click="CloseCancelDialog" small color=rgb(0,171,173)>Do Not Cancel</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="CancelEvent()" small color=rgb(0,171,173)>Proceed to Cancellation</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog id="deleteDialog" persistent v-model="deleteDialog.show" transition="dialog-transition" max-width="450">
                    <v-card>
                      <v-card-text>
                        <v-flex>
                          <span style="font-weight:normal;">
                            Are you sure you want to delete this draft event?
                          </span>
                        </v-flex>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn @click="CloseDeleteDialog" small color=rgb(0,171,173)>Do Not Delete</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="DeleteEvent()" small color=rgb(0,171,173)>Delete</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>


                  <v-dialog id="publishDialog" persistent v-model="publishdialog.show" transition="dialog-transition" max-width="450">
                    <v-card>
                      <v-card-text>
                        <v-flex pa-3>
                          <span style="font-weight:bold;">
                            Are you sure you want to publish?
                          </span>
                        </v-flex>
                      </v-card-text>
                      <v-card-actions style="text-align: right;display: block">
                        <v-btn @click="ClosePublishDialog" small class="v-btn-secondary">Don't Publish</v-btn>
                        <v-btn @click="PublishEvent" small color=rgb(0,171,173)>Ok</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>


                  <v-dialog id="messageDialog" persistent v-model="messagedialog.show" transition="dialog-transition" max-width="450">
                    <v-card>
                      <v-card-text>
                        <v-flex class="pa-3">
                          <div style="font-weight:bold;" v-html="messagedialog.message" />
                        </v-flex>
                      </v-card-text>
                      <v-card-actions class="text-right" style="display: block">
                        <v-btn @click="CloseMessageDialog" small color=rgb(0,171,173)>Ok</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                </v-layout>

              </v-form>
            </v-stepper-content>
            <!-- End Step 4 -->




          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </v-layout>
  </v-container>
</template>




<script>

  import { validationMixin } from 'vuelidate'
  import { required, requiredIf, minLength, maxLength, integer } from 'vuelidate/lib/validators'
  //import VueTimepicker from 'vue2-timepicker'
  import { VueEditor } from "vue2-editor"
  import { Datetime } from 'vue-datetime'
  import 'vue-datetime/dist/vue-datetime.min.css'

  import ProviderService from '../services/provider.service'
  import EventService from '../services/event.service'
  import CertificateService from '../services/certificate.service'
  import AddressService from '@/services/address.service'

  import Event from '@/models/event.model'

  import CommonImageUploader from './common-image-uploader'
  import EventPricePopover from '@/components/provider-eventpricepopover'

  import { LAYOUTS } from '@/constants/LayoutConstants'
  import { EVENT_STEPPER_STATE, EVENT_ACCESS_LEVEL, EVENT_MODE, EVENT_STATUS, EVENT_TYPE, EVENT_PROOF_OF_IDENTITY, /*DEFAULT_EVENT_STRUCTURE,*/ ACTION_BUTTONS } from '@/constants/EventConstants'
  import { IMAGE_STATUS } from '../constants/ImageConstants'
  import { FormatDuration, DESIRED_DURATIONS } from '@/helpers/duration.helper'
  import { EventDurationInMinutes, GenerateRandomEventCode, ADDRESS_FIELDS } from '@/helpers/event.helper'
  import { GetLocaleDateTimeFromDateTime, GetLocaleDateFromDateTime } from '@/helpers/date.helper'
  import { IMAGE_TYPES } from '../constants/ImageConstants'
  //import { v4 as uuidv4 } from 'uuid';

  import EventPrice from '@/models/eventprice.model'

  import EventLogo from './eventlogo-container'
  import { PROVIDER_ALL_EVENTS_PAGE_PARAMETERS, PRICE_WINDOW_OPTIONS } from '../constants/PageParameterConstants'
  import { ObjectsAreDifferent } from '../helpers/general.helper'

  import {
    //priceRequired,
    addressRequired,
    onlineURLRequired,
    attendeesRequired,
    validDate,
    validCSSColour,
    DateOneBeforeDateTwo,
    FutureDateTime,
    isValidCertificateId,
  } from '@/constants/Validations'


  const SCHEDULE_HEADERS = [
    { text: 'Available Date',   align: 'left', value: 'displayEventDate', sortable: false },
    { text: 'Price',            align: 'left', value: 'price',            sortable: false },
    { text: 'Location',         align: 'left', value: 'location',         sortable: false },
    { text: 'Available Seats',  align: 'left', value: 'availableseats',   sortable: false },
    { text: 'Action',           align: 'left', value: 'action',           sortable: false }
  ]

  const PRICE_TABLE_HEADERS = [
    { text: 'Title',        align: 'left', value: 'title',        sortable: false },
    { text: 'Description',  align: 'left', value: 'description',  sortable: false },
    { text: 'Price (GBP)',  align: 'left', value: 'priceGBP',     sortable: false },
    /*
    { text: 'Price (USD)',  align: 'left', value: 'priceUSD',     sortable: false },
    { text: 'Price (EUR)',  align: 'left', value: 'priceEUR',     sortable: false },
    */
    { text: 'Actions',      align: 'center', value: '',             sortable: false }
  ]

  const DEFAULT_LOGO = {
    id: 0,
    name: "No logo",
    imageKey: ""
  }

  //const DEFAULT_CHANGED_DATA = JSON.parse(JSON.stringify(DEFAULT_EVENT_STRUCTURE))

  const INTERNAL_STATES = {
    CHANGES_NONE: "CHANGES_NONE",
    CHANGES_UNSAVED: "CHANGES_UNSAVED",
    PUBLISHED_NOT_PUBLISHED: "PUBLISHED_NOT_PUBLISHED",
    PUBLISHED_PUBLISHED: "PUBLISHED_PUBLISHED",
  }


  export default {

    components: {
      VueEditor,
      //VueTimepicker,
      datetime: Datetime,
      'event-logo': EventLogo,
      'image-uploader': CommonImageUploader,
      'event-price-popover': EventPricePopover
      //verte
    },


    props: {
      stepperState: String,
      isCopy: Boolean,
      event: Object,
      onClose: Function
    },


    data() {
      return {
        isLoading: false,
        isSavingEvent: false,
        isCancellingEvent: false,
        isDeletingEvent: false,
        isPublishingEvent: false,
        fullPage: true,
        step: 1,
        internalStepperState: EVENT_STEPPER_STATE.VIEWONLY,
        internalStates: {
          changes: INTERNAL_STATES.CHANGES_NONE,
          published: INTERNAL_STATES.PUBLISHED_NOT_PUBLISHED
        },
        canceldialog: {
          show: false,
          message: ''
        },
        deleteDialog: {
          show: false,
          message: ''
        },
        publishdialog: {
          show: false,
          message: ''
        },
        messagedialog: {
          show: false,
          message: '',
          saveEvent: false,
          publishEvent: false,
          deleteEvent: false,
          shouldClose: false,
          shouldRefresh: false
        },
        certificatedialog: {
          show: false,
          certificatepath: '',
          message: ''
        },
        priceOptionWindow: {
          show: false,
          displayType: PRICE_WINDOW_OPTIONS.CREATE,
          priceOption: undefined
        },
        disabled: false,
        autocomplete: false,
        addressDisabled: false,
        attendeesDisabled: false,
        reactive: false,
        submitted: false,
        certificateSubmitted: false,
        employeeList: [],
        accreditationLogos: [DEFAULT_LOGO],
        eventLogos: [],
        signatures: [],
        certificateTemplates: [],
        eventDetails: {},
        originalEvent: new Event(),
        currentEvent: new Event(),
        dateMenuOpen: false,
        showUserCurrentErrors: false,
        scheduleHeaders: SCHEDULE_HEADERS,
        showImageUploaderPopover: false,
        addressSearch: '',
        addressAutoCompleteOptions: [],
        addressSearchPlaceId: '',
        addressService: null,
        EVENT_ACCESS_LEVEL,
        EVENT_MODE,
        EVENT_STATUS,
        EVENT_TYPE,
        EVENT_PROOF_OF_IDENTITY,
        EVENT_STEPPER_STATE,
        IMAGE_TYPES,
        INTERNAL_STATES,
        ACTION_BUTTONS,
        PRICE_TABLE_HEADERS,
        PRICE_WINDOW_OPTIONS,
        hasSaved: false,
      }
    },



    mixins: [validationMixin],
    validations: {

      title: { required, maxLength: maxLength(100) },
      description: { required, maxLength: maxLength(10000) },
      accessLevel: { required },
      eventMode: { required },

      addressLine1: { addressRequired, maxLength: maxLength(200) },
      addressLine2: { maxLength: maxLength(200) },
      addressLine3: { maxLength: maxLength(200) },
      addressCity: { addressRequired, maxLength: maxLength(200) },
      addressRegion: { addressRequired, maxLength: maxLength(200) },
      addressPostalCode: { addressRequired, maxLength: maxLength(200) },
      //addressCountry: { addressRequired, maxLength: maxLength(200) },

      onlineURL: { onlineURLRequired, maxLength: maxLength(500) },
      eventType: { required },

      instructorId: {
        required,
        validIndex: function() {
          return this.instructorId && this.instructorId !== "0"
        }
      },

      eventLogos: { required, minLength: minLength(1), $each: { required } },
      eventLogo: { required },

      preRequisites: { maxLength: maxLength(500) },
      proofOfIdentity: { required },

      //price: { priceRequired },
      maximumAttendees: { attendeesRequired, integer },
      targetAudience: { maxLength: maxLength(500) },

      date: { required },
      startTime: {
        required,
        futureDateTime: function() {
          return FutureDateTime(this.startDateTime) || this.disabled || this.userInAdminMode
        },
        validStartTime: function() {
          return DateOneBeforeDateTwo(this.startDateTime, this.endDateTime, 0) || this.disabled
        }
      },
      endTime: {
        required,
        futureDateTime: function() {
          return FutureDateTime(this.endDateTime) || this.disabled || this.userInAdminMode
        },
        validEndTime: function() {
          return DateOneBeforeDateTwo(this.startDateTime, this.endDateTime, 16) || this.disabled
        }
      },
      cpdHours: {

      },
      attendanceHours: {
        validAttendanceHours: function() {
          try {
            const duration = EventDurationInMinutes({ startDateTime: this.startDateTime, endDateTime: this.endDateTime })
            if (duration < 60 * 24) { return true }
            return this.attendanceHours !== 0
          } catch (err) {
            console.warn("Error when checking if attendance hours are valid;", err)
            return false
          }
        },
      },
      eventValidityDateTime: {
        validDateTime: function() {
          if (this.eventType !== EVENT_TYPE.CONFERENCE) { return true }
          return validDate(this.eventValidityDateTime)
        },
        futureDateTime: function() {
          if (this.eventType !== EVENT_TYPE.CONFERENCE) { return true }
          return FutureDateTime(this.eventValidityDateTime)
        },
        isAfterEndTime: function() {
          if (this.eventType !== EVENT_TYPE.CONFERENCE) { return true }
          return DateOneBeforeDateTwo(this.endDateTime, this.eventValidityDateTime, 1)
        }
      },

      // TODO: FUTURE: Get the CSS colour validation working.  The regex seems fine

      signatureId: {
        /*
        required: requiredIf(function(signatureId) {
          return signatureId && this.step === 4
        })
        */
      },
      signature: {
        /*
        required: requiredIf(function(signature) {
          return signature && this.step === 4
        })
        */
      },
      certificateTemplateId: {
        required: requiredIf(function() {
          if (this.step === 4) {
            return false
          }
          return true
        }),
        isAcceptableValue: function() {
          return isValidCertificateId(this.certificateTemplateId, this.step)
        }
      },
      colorBackground: {
        required: requiredIf(function(colorBackground) {
          return colorBackground && this.step === 4
        }),
        isCSSColor: requiredIf(function(colorBackground) {
          return validCSSColour(colorBackground) && this.step === 4
        })
      },
      colorFontPrimary: {
        required: requiredIf(function(colorFontPrimary) {
          return colorFontPrimary && this.step === 4
        }),
        isCSSColor: requiredIf(function(colorFontPrimary) {
          return validCSSColour(colorFontPrimary) && this.step === 4
        })
      },
      colorFontSecondary: {
        required: requiredIf(function(colorFontSecondary) {
          return colorFontSecondary && this.step === 4
        }),
        isCSSColor: requiredIf(function(colorFontSecondary) {
          const isValid = validCSSColour(colorFontSecondary)
          //console.log("isValid:", isValid)
          return isValid && this.step === 4
        })
      },
      certificateExpiryDateTime: {
        required: requiredIf(function() {
          return this.certificatesExpire && this.step === 4
        }),
        futureDateTime: function() {
          if (!this.certificatesExpire) { return true }
          return FutureDateTime(this.certificateExpiryDateTime)
        },
        afterEndDate: function() {
          if (!this.certificatesExpire) { return true }
          return DateOneBeforeDateTwo(this.endDateTime, this.certificateExpiryDateTime, 1)
        }
      }
    },





    watch: {
      event: {
        handler: function (newVal) {
          //console.log('WATCHER: Event Stepper event was changed to:', newVal);
          if (this.internalStepperState === EVENT_STEPPER_STATE.CREATE) { return }
          if (this.internalStepperState === EVENT_STEPPER_STATE.COPY) { return }
          this.originalEvent = new Event(newVal)
          this.currentEvent = new Event(this.originalEvent)
        }
      },
      stepperState: function(newValue) {
        //console.log("WATCHER: stepperState changed to:", newValue)
        this.internalStepperState = newValue
        if (newValue === EVENT_STEPPER_STATE.VIEWONLY && !this.userInAdminMode) {
          this.disabled = true
          this.addressDisabled = true
        }
      },
      /*
      eventValidityDateTime: function(newVal) {
        console.log('WATCHER: Event Stepper eventValidityDateTime was changed to:', newVal);
      },
      instructorId: {
        immediate: true,
        handler(newVal) {
          console.log('WATCHER: Event Stepper instructorId was changed to:', newVal);
        }
      },
      certificateTemplateId: function(newVal) {
        console.log('WATCHER: Event Stepper certificateTemplateId was changed to:', newVal);
      },
      colorFontSecondary: function(newVal) {
        console.log('WATCHER: Event Stepper colorFontSecondary was changed to:', newVal);
      }
      price: function(newVal) {
        console.log('WATCHER: Event Stepper price was changed to:', newVal);
      },
      stepperState: function(newVal) {
        console.log('WATCHER: Event Stepper stepperState was changed to:', newVal);
      },
      startDateTime: {
        immediate: true,
        handler(newValue) {
          console.log('WATCHER: Event Stepper startDateTime was changed to:', newValue);
          console.log('WATCHER: this.originalEvent.startDateTime is:', this.originalEvent.startDateTime);
          console.log('WATCHER: this.currentEvent.startDateTime is:', this.currentEvent.startDateTime);
        }
      },
      endDateTime: {
        immediate: true,
        handler(newValue) {
          console.log('WATCHER: Event Stepper endDateTime was changed to:', newValue);
          console.log('WATCHER: this.originalEvent.endDateTime is:', this.originalEvent.endDateTime);
          console.log('WATCHER: this.currentEvent.endDateTime is:', this.currentEvent.endDateTime);
        }
      },
      signatureId: function(newVal) {
        console.log('WATCHER: Event Stepper signatureId was changed to:', newVal);
      },
      signature: function(newVal) {
        console.log('WATCHER: Event Stepper signature was changed to:', newVal);
      },
      eventLogo: function(newVal) {
        console.log('WATCHER: Event Stepper eventLogo was changed to:', newVal);
      },
      eventLogoId: function(newVal) {
        console.log('WATCHER: Event Stepper eventLogoId was changed to:', newVal);
      },
      code: function(newVal) {
        //console.log('WATCHER: Event Stepper code was changed to:', newVal);
        if (this.code !== newVal) { this.code = newVal }
      },
      */
      addressSearchPlaceId: async function(newVal) {
        //console.log('WATCHER: Event Stepper addressSearchPlaceId was changed to:', newVal);
        const addressResult = await this.addressService.GetAddressDetails(newVal)
        //console.log("addressResult:", addressResult)
        for (const [key, value] of Object.entries(addressResult)) {
          this[key] = value
        }
      },
      useCertificateSignerName: function(newVal) {
        // If the custom name checkbox is turned off, clear the signer name
        if (!newVal) {
          this.certificateSignerName = ""
        }
      }
    },






    methods: {
      GetLocaleTimeString(dateTimeString) {
        try {
          const newDateTimeObject = new Date(dateTimeString)
          return newDateTimeObject.toLocaleTimeString()
        } catch (err) {
          console.warn("Unable to GetLocaleTimeString:", err)
          return "TBC"
        }
      },
      GetLocaleDateString(dateTimeString) {
        try { return GetLocaleDateFromDateTime(dateTimeString) } catch (e) { return '' }
      },
      GetLocaleDateTimeString(dateTimeString) {
        try { return GetLocaleDateTimeFromDateTime(dateTimeString).slice(0,-3) } catch (e) { return '' }
      },
      ToggleImagePopover() {
        this.showImageUploaderPopover = !this.showImageUploaderPopover
      },
      CloseCancelDialog() {
        this.canceldialog.show = false;
      },
      OnCancelClick() {
        this.canceldialog.show = true;
      },
      OnDeleteEventClick() {
        this.deleteDialog.show = true;
      },
      CloseDeleteDialog() {
        this.deleteDialog.show = false;
      },
      // Sets the event status to cancelled, then navigates to the all courses page
      async CancelEvent() {
        this.isCancellingEvent = true
        try {
          const eventService = new EventService(this.$store)
          await eventService.CancelEvent(this.id)
          this.originalEvent.setField('status', EVENT_STATUS.CANCELLED)
          this.currentEvent.setField('status', EVENT_STATUS.CANCELLED)
          this.$router.push({ name: `${ this.$store.getters.layout === LAYOUTS.ADMIN ? 'AdminAllEvents' : 'ProviderAllEvents' }`, params: { status: PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.EVENTS } });
          // Close the event editor window and reset everything
          this.isCancellingEvent = false
          if (this.onClose) {
            this.onClose(true)
            this.step = 1
          }
        } catch (err) {
          console.warn("Error when setting status of event to cancelled", err)
          this.isCancellingEvent = false
          this.messagedialog = {
            show: true,
            message: "Error cancelling event.  Please try again"
          }
        }
      },
      // Sets the event status to deleted
      async DeleteEvent() {
        //console.log("DeleteEvent eventId:", eventId);
        this.isDeletingEvent = true
        try {
          const eventService = new EventService(this.$store)
          await eventService.DeleteEvent(this.id)
          this.originalEvent.setField('status', EVENT_STATUS.DELETED)
          this.currentEvent.setField('status', EVENT_STATUS.DELETED)
          this.$router.push({ name: `${ this.$store.getters.layout === LAYOUTS.ADMIN ? 'AdminAllEvents' : 'ProviderAllEvents' }`, params: { status: PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.EVENTS } });
          // Close the event editor window and reset everything
          this.isDeletingEvent = false
          if (this.onClose) {
            this.onClose(true)
            this.step = 1
          }
        } catch (err) {
          console.warn("Error when setting status of event to deleted", err)
          this.isDeletingEvent = false
          this.messagedialog = {
            show: true,
            shouldClose: true,
            shouldRefresh: true,
            deleteEvent: true,
            message: "Error deleting event.  Please try again"
          }
        }
      },

      async RequestEventCreate() {
        try {
          // Ensure the code is unique
          if (this.eventType ===  EVENT_TYPE.CONFERENCE) {
            this.currentEvent.setField('code', GenerateRandomEventCode())
          }
          console.log("Current Prices:", this.currentEvent.getPriceOptions(true))
          console.log("Current Event:", this.currentEvent.getGraphQLObject())

          // Create an event service and create the event using the service
          const eventService = new EventService(this.$store)
          await eventService.CreateEvent(this.currentEvent)
          // Now that the event has been created, create the price options
          await this.currentEvent.updatePriceOptionsOnServer()
          // Reset the current event status
          this.currentEvent.hasChanges = false
          //console.log("Create New Event Response:", response)
          // Update the original event to be the current event (i.e. they are the same)
          this.originalEvent = new Event(this.currentEvent)
          this.originalEvent.hasChanges = false
          // Fetch the updated list of events from the server
          await Promise.all([
            eventService.GetFutureEventsForEmployee(),
            eventService.GetPastEventsForEmployee()
          ])
          this.messagedialog = {
            show: true,
            saveEvent: true,
            shouldClose: false,
            message: "Event created successfully"
          }
          this.isSavingEvent = false
          this.hasSaved = true
          this.showUserCurrentErrors = false
          this.internalStepperState = EVENT_STEPPER_STATE.UPDATE
        } catch (err) {
          console.warn("Error when creating event;", err)
          this.isSavingEvent = false
          this.hasSaved = false
          this.showUserCurrentErrors = false
          this.messagedialog = {
            show: true,
            message: "Error creating event.  Please try again",
            shouldRefresh: true
          }
        }
      },
      async RequestEventUpdate() {
        const parameters = {}
        try {
          // For each field in the changed data object, check if it has changed
          const currentObjectGraphQL = this.currentEvent.getGraphQLObject()
          const originalObjectGraphQL = this.originalEvent.getGraphQLObject()
          //console.log("originalObjectGraphQL:", originalObjectGraphQL)
          //console.log("currentObjectGraphQL:", currentObjectGraphQL)
          for (const key in currentObjectGraphQL) {
            if (typeof(currentObjectGraphQL[key]) === 'object') {
              if (ObjectsAreDifferent(currentObjectGraphQL[key], originalObjectGraphQL[key])) {
                parameters[key] = currentObjectGraphQL[key]
              }
            }
            if (typeof(currentObjectGraphQL[key]) !== 'object') {
              if (currentObjectGraphQL[key] !== originalObjectGraphQL[key]) {
                parameters[key] = currentObjectGraphQL[key]
              }
            }
          }
          if (Object.keys(parameters).length === 0 && !this.currentEvent.hasChanges) {
            console.warn("There were no changes made; why was the save button available???")
            return
          }
          //console.log("parameters:", parameters)
          // Create an event service and then update the event
          const eventService = new EventService(this.$store)
          await Promise.all([
            eventService.UpdateEventDetails(this.id, parameters),
            this.currentEvent.updatePriceOptionsOnServer()
          ])
          //console.log("UpdateEventDetails Response:", response)
          // Update the original event to matched the current event
          this.originalEvent = new Event(this.currentEvent)
          // Fetch the updated list of events
          await Promise.all([
            eventService.GetFutureEventsForEmployee(),
            eventService.GetPastEventsForEmployee()
          ])
          this.originalEvent.hasChanges = false
          this.currentEvent.hasChanges = false
          this.isSavingEvent = false
          this.hasSaved = true
          this.messagedialog = {
            show: true,
            saveEvent: true,
            message: "Event updated successfully",
            shouldRefresh: true,
            shouldClose: false,
          }
        } catch (err) {
          console.warn("Error when updating event;", err)
          this.isSavingEvent = false
          this.hasSaved = false
          this.showUserCurrentErrors = false
          this.messagedialog = {
            show: true,
            message: "Error updating event.  Please try again"
          }
        }
      },
      async SaveEvent() {
        // Get the errors showing if they exist
        this.showUserCurrentErrors = true;
        this.isSavingEvent = true;
        // Make sure this isn't a cancelled or past event
        if (this.status !== EVENT_STATUS.DRAFT && this.status !== EVENT_STATUS.LIVE) {
          console.warn("Tried to save event that is not draft or live status; event status is:", this.status)
          this.isSavingEvent = false;
          this.isLoading = false;
          this.isPublishingEvent = false;
          this.messagedialog = {
            show: true,
            message: `Error saving changes to event event.  The event status was ${this.status} and should be draft or live.`,
          }
          return;
        }
        // Touch everything and check for invalid data
        this.$v.$touch();
        //console.log("this.$v:", this.$v)
        if (this.$v.$invalid) {
          console.warn("Not all fields are valid during save event")
          console.warn(this.$v)
          this.isSavingEvent = false
          return
        }
        // If this is an update event request, do an update
        if (this.internalStepperState === EVENT_STEPPER_STATE.UPDATE) {
          console.log("This is an update event request inside SaveEvent")
          this.RequestEventUpdate()
          return
        }
        // If this is a create event screen, do a create
        if (this.internalStepperState === EVENT_STEPPER_STATE.CREATE) {
          console.log("This is a create event request inside SaveEvent")
          this.RequestEventCreate()
          return
        }
        // If this is a create event screen, do a create
        if (this.internalStepperState === EVENT_STEPPER_STATE.COPY) {
          console.log("This is a copy event request inside SaveEvent")
          this.RequestEventCreate()
          return
        }
        // If this is a view only state and the user is an admin, do an update
        if (this.internalStepperState === EVENT_STEPPER_STATE.VIEWONLY && this.userInAdminMode) {
          console.log("This is a view only event request inside SaveEvent")
          this.RequestEventUpdate()
          return
        }
        // If the state isn't one of the above, this is a bug
        console.warn("Unknown internalStepperState:", this.internalStepperState)
        this.isSavingEvent = false;
        this.isLoading = false;
        this.isPublishingEvent = false;
        this.messagedialog = {
          show: true,
          message: `Error saving changes to event.  Please close the event and try again.`,
        }
      },
      previousStep() {
        // If this is not a paid event, skip the second step
        if (this.step === 3 && this.currentEvent.getField('eventType') !== EVENT_TYPE.PAID) {
          this.step = this.step - 2
          return
        }
        // Otherwise just go back 1 step
        this.step = this.step - 1
      },
      nextStep() {
        // If this is step one, do some checks
        if (this.step === 1) {
          this.showUserCurrentErrors = true;
          //console.log("currentEvent:", this.currentEvent);
          // If this is an editable event, check if the data is valid
          if (this.status === EVENT_STATUS.DRAFT || this.status === EVENT_STATUS.LIVE) {
            this.$v.$touch();
            // If not valid, stop the navigation and show the warning
            if (this.$v.$invalid) {
              //console.warn("StepOneNext: Invalid fields:", JSON.stringify(this.$v, null, 2))
              return;
            }
          }
          // Otherwise, continue to the next step
          this.showUserCurrentErrors = false;
          // If this isn't a paid event, skip the payment step
          if (this.currentEvent.getField('eventType') !== EVENT_TYPE.PAID) {
            this.step = this.step + 2
            return
          }
        }
        this.step = this.step + 1
      },
      ClosePublishDialog() {
        this.publishdialog.show = false;
      },
      OnPublishClick() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          //console.warn("INVALID!!!")
          //console.log(this.$v)
          return;
        }
        this.publishdialog.show = true;
      },
      async PublishEvent() {
        this.isPublishingEvent = true;
        try {
          const eventService = new EventService(this.$store)
          await eventService.PublishEvent(this.id)
          await Promise.all([
            eventService.GetFutureEventsForEmployee(),
            eventService.GetPastEventsForEmployee()
          ])
          this.messagedialog = {
            show: true,
            publishEvent: true,
            message: "Event published successfully",
            shouldClose: true,
            shouldRefresh: true
          }
          this.publishdialog.show = false;
          this.isPublishingEvent = false;
          this.showUserCurrentErrors = false
          this.originalEvent.setField('status', EVENT_STATUS.LIVE)
          this.currentEvent.setField('status', EVENT_STATUS.LIVE)
        } catch (err) {
          console.warn("Error when setting the event to live", err)
          this.publishdialog.show = false;
          this.isPublishingEvent = false;
          this.showUserCurrentErrors = false
          this.messagedialog = {
            show: true,
            message: "Error publishing event.  Please try again",
          }
        }
      },
      async GetCertificatePreview() {
        this.isLoading = true;
        if (this.dataHasChanged) {
          this.messagedialog.message = "Please save changes before creating an example certificate"
          this.messagedialog.show = true
          this.isLoading = false;
          return
        }
        const eventService = new EventService(this.$store)
        const result = await eventService.GetCertificatePreview(this.id)
        console.log("GetCertificatePreview result:", result)
        // If there is an error getting the string, tell the user there was an error
        if (!result) {
          this.messagedialog.message = "There was an error generating the certificate.  Please try again."
          this.messagedialog.show = true
          this.isLoading = false;
          return
        }
        var downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', `data:application/pdf;base64,${result}`)
        downloadLink.setAttribute('download', `Example_certificate_${this.id}.pdf`)
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this.isLoading = false;
      },
      CloseMessageDialog() {
        this.messagedialog.message = '';
        this.messagedialog.show = false

        if (this.messagedialog.publishEvent) {
          this.$router.push({ name: `${ this.$store.getters.layout === LAYOUTS.ADMIN ? 'AdminAllEvents' : 'ProviderAllEvents' }`, params: { status: PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.EVENTS } });
          if (this.messagedialog.shouldClose) {
            if (this.onClose) {
              this.onClose(this.messagedialog.shouldRefresh)
            }
          }
          return
        }

        if (this.messagedialog.saveEvent) {
          if (this.messagedialog.shouldClose) {
            this.$router.push({ name: `${ this.$store.getters.layout === LAYOUTS.ADMIN ? 'AdminAllEvents' : 'ProviderAllEvents' }`, params: { status: PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.EVENTS } });
            if (this.onClose) {
              this.onClose(this.messagedialog.shouldRefresh)
            }
          }
          return
        }

        if (this.messagedialog.deleteEvent) {
          this.$router.push({ name: `${ this.$store.getters.layout === LAYOUTS.ADMIN ? 'AdminAllEvents' : 'ProviderAllEvents' }`, params: { status: PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.EVENTS } });
          if (this.messagedialog.shouldClose) {
            if (this.onClose) {
              this.onClose(this.messagedialog.shouldRefresh)
            }
          }
          return
        }

        if (this.messagedialog.shouldClose) {
          if (this.onClose) {
            this.onClose(this.messagedialog.shouldRefresh)
          }
        }

        this.messagedialog.show = false
        this.messagedialog.saveEvent = false
        this.messagedialog.publishEvent = false
        this.messagedialog.deleteEvent = false
        this.messagedialog.message = '';
      },
      async OnImageSelectionChange(pickerId, imageId, imageType, shouldFetchImages) {
        //console.log("OnImageSelectionChange:", pickerId, imageId, imageType, shouldFetchImages)
        // If this is the eventLogo that was changed, update eventLogoId
        if (pickerId === "eventLogo") {
          // If this is a newly uploaded image that requires the image array downloading
          if (shouldFetchImages) {
            try {
              this.eventLogos = await ProviderService.GetEventLogos(this.$store.getters.employer.id)
            } catch (err) {
              console.warn("Error getting event logo list", err)
            }
          }
          //console.log("Updating selected image for eventLogo")
          this.eventLogoId = imageId
          return;
        }
        // If this is the signatureImage that was changed, update signatureId
        if (pickerId === "signatureImage") {
          // If this is a newly uploaded image that requires the image array downloading
          if (shouldFetchImages) {
            await this.GetEmployerSignatures()
          }
          //console.log("Updating selected image for signatureImage")
          //console.log("This.currentEvent:", this.currentEvent)
          //console.log("This.originalEvent:", this.originalEvent)
          this.signatureId = imageId
          return;
        }
      },
      async GetEmployerSignatures() {
        try {
          const response = await ProviderService.GetEmployerSignatures(this.$store.getters.employer.id)
          //console.log(response)
          //console.log("signatures:", response.data.getEmployer.signatures.items)
          this.signatures = response.data.getEmployer.signatures.items
        } catch (err) {
          console.warn("Error getting employer signatures list", err)
        }
      },
      async RequestAddressSearch() {
        //console.log("addressSearch string:", this.addressSearch)
        // If the address service hasn't been initialised yet, initialise it
        if (!this.addressService) {
          this.addressService = new AddressService()
          await this.addressService.init()
        }
        // Get a list of possible addresses
        const result = await this.addressService.PerformAddressSearch(this.addressSearch)
        //console.log("result:", result)
        this.addressAutoCompleteOptions = result
        this.$refs["addressSearch"].focus();
        this.$refs["addressSearch"].activateMenu();
      },
      GetActionButtonsForSession(session) {
        //console.log("session:", session)
        var actionButtons = []
        try {
          // If this is a conference with spare spaces...
          if (session.eventType !== EVENT_TYPE.CONFERENCE) {
            actionButtons.push(ACTION_BUTTONS.BOOK_SESSION)
            actionButtons.push(ACTION_BUTTONS.CONTACT_PROVIDER)
            return actionButtons
          }
          // If the other if statements were triggered, add the default options
          actionButtons.push(ACTION_BUTTONS.ADD_TO_CALENDAR)
          actionButtons.push(ACTION_BUTTONS.CONTACT_PROVIDER)
          // Return the list of possible actions
          return actionButtons
        } catch (err) {
          console.warn("Error getting buttons for session:", err)
          return []
        }
      },
      AddPriceOptionClicked() {
        //console.log("priceOption add requested")
        this.priceOptionWindow = {
          show: true,
          displayType: PRICE_WINDOW_OPTIONS.CREATE,
          priceOption: undefined
        }
      },
      EditPriceOptionClicked(priceOption) {
        //console.log("All price items for original Event:", this.originalEvent.getPriceOptions())
        //console.log("All price items for current Event:", this.currentEvent.getPriceOptions())
        //console.log("priceOption edit requested:", priceOption)
        this.priceOptionWindow = {
          show: true,
          displayType: PRICE_WINDOW_OPTIONS.UPDATE,
          priceOption: priceOption
        }
      },
      async DeletePriceOption(priceOption) {
        //console.log("priceOption delete requested:", priceOption)
        try {
          this.isLoading = true
          await this.currentEvent.removePriceOption(priceOption.getId())
          this.isLoading = false
        } catch (err) {
          console.warn("Error when removing price option:", err)
          this.isLoading = false
          this.messagedialog.message = "There was an error deleting the event price; please try again."
          this.messagedialog.show = true
        }
      },
      async ToggleEventPricePopover(shouldReload) {
        var reloadRequired = false
        try {
          reloadRequired = await shouldReload
        } catch (err) {
          reloadRequired = false;
        }
        if (reloadRequired) {
          try {
            const eventService = new EventService(this.$store)
            this.isLoading = true
            await Promise.all([
              eventService.GetFutureEventsForEmployee(),
              eventService.GetPastEventsForEmployee()
            ])
          }
          catch (err) {
            console.warn("Error when fetching events for user;", err)
          }
          this.isLoading = false
        }
        this.priceOptionWindow.show = !this.priceOptionWindow.show
      },
      priceTableSort(items) {
        try {
          const result = items.sort((a, b) => {
            return b.getField('priceGBP') < a.getField('priceGBP') ? 1 : -1;
          })
          return result;
        } catch (err) {
          console.warn("Error when custom sorting event prices:", err)
          return items
        }
      },
      scheduleTableSort(items) {
        try {
          const result = items.sort((a, b) => {
            return parseInt(b.price) < parseInt(a.price) ? 1 : -1;
          })
          return result;
        } catch (err) {
          console.warn("Error when custom sorting schedule table:", err)
          return items
        }
      },
    },





    computed: {
      // Event ID
      id: {
        get: function() {
          return this.originalEvent.getField("id")
        },
        set: function(newValue) {
          console.warn(`Attempted to set the event ID to ${newValue}.  This isn't possible`)
        }
      },
      // Event Title
      title: {
        get: function() {
          return this.currentEvent.getField("title")
        },
        set: function(newValue) {
          //console.log(`Changing title to: ${newValue}`)
          this.currentEvent.setField("title", newValue)
        }
      },
      // Event Description
      description: {
        get: function() {
          return this.currentEvent.getField("description")
        },
        set: function(newValue) {
          //console.log(`Changing description to: ${newValue}`)
          this.currentEvent.setField("description", newValue)
        }
      },
      // Event Access Level
      accessLevel: {
        get: function() {
          return this.currentEvent.getField("accessLevel")
        },
        set: function(newValue) {
          //console.log(`Changing accessLevel to: ${newValue}`)
          this.currentEvent.setField("accessLevel", newValue)
        }
      },
      // Event Mode
      eventMode: {
        get: function() {
          return this.currentEvent.getField("eventMode")
        },
        set: function(newValue) {
          //console.log(`Changing eventMode to: ${newValue}`)
          this.currentEvent.setField("eventMode", newValue)
        }
      },
      // Event Status
      status: {
        get: function() {
          return this.currentEvent.getField("status")
        },
        set: function(newValue) {
          //console.log(`Changing status to: ${newValue}`)
          this.currentEvent.setField("status", newValue)
        }
      },
      // Event Address Line 1
      addressLine1: {
        get: function() {
          //console.log("Getting address line 1:", this.currentEvent.getAddressItem(ADDRESS_FIELDS.LINE1))
          return this.currentEvent.getAddressItem(ADDRESS_FIELDS.LINE1)
        },
        set: function(newValue) {
          //console.log(`Changing address line 1 to: ${newValue}`)
          //console.log(`Original Event Address before:`, JSON.stringify(this.originalEvent.event.address, null, 2))
          //console.log(`Current Event Address before:`, JSON.stringify(this.currentEvent.event.address, null, 2))
          this.currentEvent.setAddressItem(ADDRESS_FIELDS.LINE1, newValue)
          //console.log(`Original Event Address after:`, JSON.stringify(this.originalEvent.event.address, null, 2))
          //console.log(`Current Event Address after:`, JSON.stringify(this.currentEvent.event.address, null, 2))
        }
      },
      // Event Address Line 2
      addressLine2: {
        get: function() {
          return this.currentEvent.getAddressItem(ADDRESS_FIELDS.LINE2)
        },
        set: function(newValue) {
          //console.log(`Changing address line 2 to: ${newValue}`)
          this.currentEvent.setAddressItem(ADDRESS_FIELDS.LINE2, newValue)
        }
      },
      // Event Address Line 3
      addressLine3: {
        get: function() {
          return this.currentEvent.getAddressItem(ADDRESS_FIELDS.LINE3)
        },
        set: function(newValue) {
          //console.log(`Changing address line 3 to: ${newValue}`)
          this.currentEvent.setAddressItem(ADDRESS_FIELDS.LINE3, newValue)
        }
      },
      // Event Address City
      addressCity: {
        get: function() {
          return this.currentEvent.getAddressItem(ADDRESS_FIELDS.CITY)
        },
        set: function(newValue) {
          //console.log(`Changing address city to: ${newValue}`)
          this.currentEvent.setAddressItem(ADDRESS_FIELDS.CITY, newValue)
        }
      },
      // Event Address Region
      addressRegion: {
        get: function() {
          return this.currentEvent.getAddressItem(ADDRESS_FIELDS.REGION)
        },
        set: function(newValue) {
          //console.log(`Changing address region to: ${newValue}`)
          this.currentEvent.setAddressItem(ADDRESS_FIELDS.REGION, newValue)
        }
      },
      // Event Address Postal Code
      addressPostalCode: {
        get: function() {
          return this.currentEvent.getAddressItem(ADDRESS_FIELDS.POSTALCODE)
        },
        set: function(newValue) {
          //console.log(`Changing address postal code to: ${newValue}`)
          this.currentEvent.setAddressItem(ADDRESS_FIELDS.POSTALCODE, newValue)
        }
      },
      // Event Address Country
      addressCountry: {
        get: function() {
          return this.currentEvent.getAddressItem(ADDRESS_FIELDS.COUNTRY)
        },
        set: function(newValue) {
          //console.log(`Changing address country to: ${newValue}`)
          this.currentEvent.setAddressItem(ADDRESS_FIELDS.COUNTRY, newValue)
        }
      },
      // Event Onine URL
      onlineURL: {
        get: function() {
          return this.currentEvent.getField("onlineURL")
        },
        set: function(newValue) {
          //console.log(`Changing onlineURL to: ${newValue}`)
          this.currentEvent.setField("onlineURL", newValue)
        }
      },
      // Event Type
      eventType: {
        get: function() {
          return this.currentEvent.getField("eventType")
        },
        set: function(newValue) {
          //console.log(`Changing eventType to: ${newValue}`)
          this.currentEvent.setField("eventType", newValue)
        }
      },
      // Event Instructor Id
      instructorId: {
        get: function() {
          return this.currentEvent.getField("instructorId")
        },
        set: function(newValue) {
          //console.log(`Changing instructorId to: ${newValue}`)
          this.currentEvent.setField("instructorId", newValue)
        }
      },
      // Event Instructor
      eventInstructor: function() {
        try {
          //console.log("this.employeeList:", this.employeeList)
          const filteredEmployees = this.employeeList.filter((employee) => employee.user.id === this.instructorId)
          //console.log("filteredEmployees:", filteredEmployees)
          if (filteredEmployees.length === 0) {
            return {
              id: "",
              firstName: "Please",
              lastName: "select an instructor",
              aboutText: ""
            }
          }
          //console.log("eventInstructor:", filteredEmployees[0].user)
          return {
            ...filteredEmployees[0].user,
            aboutText: filteredEmployees[0].courseInstructorDetails.aboutText
          }
        } catch (err) {
          console.warn("Unable to get original instructor", err)
          return {}
        }
      },
      // Event Instructor About Text
      eventInstructorAboutText: function() {
        try {
          if (this.eventInstructor.id === "") { return "" }
          return this.eventInstructor.aboutText
        }
        catch (err) {
          console.warn("Error getting course instructor about text", err)
          return ""
        }
      },
      // Event Logo Id
      eventLogoId: {
        get: function() {
          return this.currentEvent.getField("eventLogoId")
        },
        set: function(newValue) {
          //console.log(`Changing eventLogoId to: ${newValue}`)
          this.currentEvent.setField("eventLogoId", newValue)
        }
      },
      // Event Logo Object
      eventLogo: function() {
        try {
          const filteredLogos = this.eventLogos.filter((logo) => { return logo.id === this.eventLogoId })
          if (filteredLogos.length === 0) { return {} }
          if (filteredLogos.length > 0) { return filteredLogos[0] }
          return {}
        } catch (err) {
          console.warn("Error fetching eventLogo;", err)
          return {}
        }
      },
      // Signature Id
      signatureId: {
        get: function() {
          return this.currentEvent.getField("signatureId")
        },
        set: function(newValue) {
          //console.log(`Changing signatureId to: ${newValue}`)
          this.currentEvent.setField("signatureId", newValue)
        }
      },
      // Signature Object
      signature: function() {
        try {
          const filteredSignatures = this.signatures.filter((signature) => { return signature.id === this.signatureId })
          if (filteredSignatures.length === 0) { return {} }
          if (filteredSignatures.length > 0) { return filteredSignatures[0] }
          return {}
        } catch (err) {
          console.warn("Error fetching signature;", err)
          return {}
        }
      },
      // Event Type
      preRequisites: {
        get: function() {
          return this.currentEvent.getField("preRequisites")
        },
        set: function(newValue) {
          //console.log(`Changing preRequisites to: ${newValue}`)
          this.currentEvent.setField("preRequisites", newValue)
        }
      },
      // Event Proof Of Identity
      proofOfIdentity: {
        get: function() {
          return this.currentEvent.getField("proofOfIdentity")
        },
        set: function(newValue) {
          //console.log(`Changing proofOfIdentity to: ${newValue}`)
          this.currentEvent.setField("proofOfIdentity", newValue)
        }
      },
      // Event Accreditation Logo ID
      accreditationLogoId: {
        get: function() {
          return this.currentEvent.getField("accreditationLogoId")
        },
        set: function(newValue) {
          //console.log(`Changing accreditationLogoId to: ${newValue}`)
          this.currentEvent.setField("accreditationLogoId", newValue)
        }
      },
      // Event Prices
      prices: {
        get: function() {
          return this.currentEvent.getPriceOptions()
        },
        set: function(newValue) {
          console.warn(`Set prices was called with: ${newValue}`)
          //this.currentEvent.setField("prices", newValue)
        }
      },
      // Event Maximum Attendees
      maximumAttendees: {
        get: function() {
          return this.currentEvent.getField("maximumAttendees")
        },
        set: function(newValue) {
          //console.log(`Changing maximumAttendees to: ${newValue}`)
          this.currentEvent.setField("maximumAttendees", newValue)
        }
      },
      // Event Target Audience
      targetAudience: {
        get: function() {
          return this.currentEvent.getField("targetAudience")
        },
        set: function(newValue) {
          //console.log(`Changing targetAudience to: ${newValue}`)
          this.currentEvent.setField("targetAudience", newValue)
        }
      },
      // Event Code
      code: {
        get: function() {
          return this.currentEvent.getField("code")
        },
        set: function(newValue) {
          //console.log(`Changing code to: ${newValue}`)
          this.currentEvent.setField("code", newValue)
        }
      },
      // Event Date
      date: {
        get: function() {
          return this.currentEvent.getField("startDateTime").split("T")[0]
        },
        set: function(newValue) {
          //console.log(`Changing code to: ${newValue}`)
          this.currentEvent.setField("DATEONLY", newValue)
        }
      },
      dateString: function() {
        return GetLocaleDateFromDateTime(this.startDateTime)
      },
      // Event Start Time
      startTime: {
        get: function() {
          return (new Date(this.startDateTime)).toTimeString()
        },
        set: function(newValue) {
          //console.log(`Changing startTime to: ${newValue}; current value: ${this.startTime}`)
          const splitTime = newValue.split(":")
          // Create a new date from the existing date
          const newDateObject = new Date(this.startDateTime)
          // Modify the time based on the current time zone
          newDateObject.setHours(splitTime[0])
          newDateObject.setMinutes(splitTime[1])
          newDateObject.setSeconds(0)
          newDateObject.setMilliseconds(0)
          //console.log("Setting startDateTime to:", newDateObject.toISOString())
          // Set the datetime
          this.currentEvent.setField("startDateTime", newDateObject.toISOString())
        }
      },
      // Event End Time
      endTime: {
        get: function() {
          return (new Date(this.endDateTime)).toTimeString()
        },
        set: function(newValue) {
          //console.log(`Changing endTime to: ${newValue}; current value: ${this.endTime}`)
          const splitTime = newValue.split(":")
          // Create a new date from the existing date
          const newDateObject = new Date(this.endDateTime)
          // Modify the time based on the current time zone
          newDateObject.setHours(splitTime[0])
          newDateObject.setMinutes(splitTime[1])
          newDateObject.setSeconds(0)
          newDateObject.setMilliseconds(0)
          //console.log("Setting endDateTime to:", newDateObject.toISOString())
          // Set the datetime
          this.currentEvent.setField("endDateTime", newDateObject.toISOString())
        }
      },
      // Event Start Date Time
      startDateTime: {
        get: function() {
          return this.currentEvent.getField("startDateTime")
        },
        set: function(newValue) {
          //console.log(`Changing startDateTime to: ${newValue}`)
          this.currentEvent.setField("startDateTime", newValue)
        }
      },
      startDateTimeLocal: {
        get: function() {
          const dateTimeString = this.currentEvent.getField("startDateTime")
          const d = new Date(dateTimeString)
          const localeDateTimeString = `${d.getFullYear()}-${("0" + (d.getMonth()+1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}T${("0" + d.getHours()).slice(-2)}:${("0" + d.getMinutes()).slice(-2)}`
          //console.log("localeDateTimeString:", localeDateTimeString)
          return localeDateTimeString
        },
        set: function(newValue) {
          //console.log(`Changing startDateTimeLocal to: ${newValue}`)
          const dateObject = new Date(`${newValue}:00`)
          //console.log("ISO dateTime:", dateObject.toISOString())
          this.currentEvent.setField("startDateTime", dateObject.toISOString())
        }
      },
      // Event End Date Time
      endDateTime: {
        get: function() {
          return this.currentEvent.getField("endDateTime")
        },
        set: function(newValue) {
          //console.log(`Changing endDateTime to: ${newValue}`)
          this.currentEvent.setField("endDateTime", newValue)
        }
      },
      endDateTimeLocal: {
        get: function() {
          const dateTimeString = this.currentEvent.getField("endDateTime")
          const d = new Date(dateTimeString)
          const localeDateTimeString = `${d.getFullYear()}-${("0" + (d.getMonth()+1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}T${("0" + d.getHours()).slice(-2)}:${("0" + d.getMinutes()).slice(-2)}`
          //console.log("localeDateTimeString:", localeDateTimeString)
          return localeDateTimeString
        },
        set: function(newValue) {
          //console.log(`Changing endDateTimeLocal to: ${newValue}`)
          const dateObject = new Date(`${newValue}:00`)
          //console.log("ISO dateTime:", dateObject.toISOString())
          this.currentEvent.setField("endDateTime", dateObject.toISOString())
        }
      },
      // CPD Hours
      cpdHours: {
        get: function() {
          return this.currentEvent.getField("cpdHours")
        },
        set: function(newValue) {
          //console.log(`Changing cpdHours to: ${newValue}`)
          this.currentEvent.setField("cpdHours", newValue)
        }
      },
      // Attendance Hours
      attendanceHours: {
        get: function() {
          //console.log("attendance hours fetch:", this.currentEvent.getField("attendanceHours"))
          // If the event duration is longer than one day (60 mins * 24 hours), return the attendance Hours
          const userEnteredHours = this.currentEvent.getField("attendanceHours")
          const eventDurationCalced = EventDurationInMinutes({ startDateTime: this.startDateTime, endDateTime: this.endDateTime })
          if (eventDurationCalced > 60 * 24) {
            return userEnteredHours
          }
          // If the user has already set the attendance hours, return that value
          if (userEnteredHours !== 0) {
            return userEnteredHours
          }
          // If the time is below 24 hours, and the user hasn't set a number of hours, return the calculated hours
          return eventDurationCalced / 60
        },
        set: function(newValue) {
          //console.log(`Changing attendanceHours to: ${newValue}`)
          var valueRemoveLeadingZeros =  newValue.replace(/^0+(?!\.|$)/, '')
          //console.log("fixed attendance hours:", valueRemoveLeadingZeros)
          this.currentEvent.setField("attendanceHours", parseFloat(valueRemoveLeadingZeros))
        }
      },
      // Event Validity Date Time
      eventValidityDateTime: {
        get: function() {
          return this.currentEvent.getField("eventValidityDateTime")
        },
        set: function(newValue) {
          //console.log(`Changing eventValidityDateTime to: ${newValue}`)
          this.currentEvent.setField("eventValidityDateTime", newValue)
        }
      },
      // Event Code
      provider: {
        get: function() {
          try {
            if (this.$store.getters.employer.id) {
              return this.$store.getters.employer
            }
            return {
              name: "Not found",
              description: "Not found"
            }
          } catch (err) {
            console.warn("Unable to get provider")
            return {
              name: "Not found",
              description: "Not found"
            }
          }
        },
        set: function(newValue) {
          console.warn(`Attempted to set the event provider to ${newValue}.  This isn't possible`)
        }
      },
      scheduledEvents: function() {
        try {
          // Build a fake event that the Available seats function can work on
          const fakeEvent = {
            attendees: this.originalEvent.getField('attendees'),
            maximumAttendees: this.maximumAttendees,
            prices: this.prices
          }
          // If the event prices aren't available yet, create a fake one
          if (!fakeEvent.prices) {
            fakeEvent.prices = [new EventPrice()]
          }
          // If the event prices don't exist, create a fake one
          if (fakeEvent.prices.length === 0) {
            fakeEvent.prices = [new EventPrice()]
          }
          // Return an array of scheduled sessions for this event
          return fakeEvent.prices.map((eventPrice) => {
            return {
              date: this.date,
              price: eventPrice.getPrice("GBP"),
              location: this.location,
              availableseats : this.maximumAttendees,
              eventType: this.eventType
            }
          })
        } catch (err) {
          console.warn("Unable to get scheduled events", err)
          return []
        }
      },
      location: function() {
        return this.currentEvent.getLocationString()
      },
      duration: function() {
        return FormatDuration(this.attendanceHours * 60, DESIRED_DURATIONS.HoursMinutes)
      },
      certificateTemplateId: {
        get: function() {
          return this.currentEvent.getField("certificateTemplateId")
        },
        set: function(newValue) {
          //console.log(`Changing certificateTemplateId to: ${newValue}`)
          this.currentEvent.setField("certificateTemplateId", newValue)
        }
      },
      useCertificateSignerName: {
        get: function() {
          return this.currentEvent.getField('certificateCustomisation').useCertificateSignerName
        },
        set: function(newValue) {
          //console.log(`Changing useCertificateSignerName: ${newValue}`)
          const certificateCustomisation = this.currentEvent.getField('certificateCustomisation')
          const newCertificateCustomisation = {
            ...certificateCustomisation,
            useCertificateSignerName: newValue
          }
          this.currentEvent.setField('certificateCustomisation', newCertificateCustomisation)
        }
      },
      certificateSignerName: {
        get: function() {
          return this.currentEvent.getField('certificateCustomisation').certificateSignerName
        },
        set: function(newValue) {
          //console.log(`Changing certificateSignerName: ${newValue}`)
          const certificateCustomisation = this.currentEvent.getField('certificateCustomisation')
          const newCertificateCustomisation = {
            ...certificateCustomisation,
            certificateSignerName: newValue
          }
          this.currentEvent.setField('certificateCustomisation', newCertificateCustomisation)
        }
      },
      // Certificate Background Colour
      colorBackground: {
        get: function() {
          return this.currentEvent.getField('certificateCustomisation').colorBackground
        },
        set: function(newValue) {
          //console.log(`Changing colorBackground: ${newValue}`)
          const certificateCustomisation = this.currentEvent.getField('certificateCustomisation')
          const newCertificateCustomisation = {
            ...certificateCustomisation,
            colorBackground: newValue
          }
          this.currentEvent.setField('certificateCustomisation', newCertificateCustomisation)
        }
      },
      // Certificate Font Primary Colour
      colorFontPrimary: {
        get: function() {
          return this.currentEvent.getField('certificateCustomisation').colorFontPrimary
        },
        set: function(newValue) {
          //console.log(`Changing colorFontPrimary: ${newValue}`)
          const certificateCustomisation = this.currentEvent.getField('certificateCustomisation')
          const newCertificateCustomisation = {
            ...certificateCustomisation,
            colorFontPrimary: newValue
          }
          this.currentEvent.setField('certificateCustomisation', newCertificateCustomisation)
        }
      },
      // Certificate Font Secondary Colour
      colorFontSecondary: {
        get: function() {
          return this.currentEvent.getField('certificateCustomisation').colorFontSecondary
        },
        set: function(newValue) {
          //console.log(`Changing colorFontSecondary: ${newValue}`)
          const certificateCustomisation = this.currentEvent.getField('certificateCustomisation')
          const newCertificateCustomisation = {
            ...certificateCustomisation,
            colorFontSecondary: newValue
          }
          this.currentEvent.setField('certificateCustomisation', newCertificateCustomisation)
        }
      },
      // Certificates Expire
      certificatesExpire: {
        get: function() {
          return this.currentEvent.getField("certificatesExpire") || false
        },
        set: function(newValue) {
          //console.log(`Changing certificatesExpire to: ${newValue}`)
          this.currentEvent.setField("certificatesExpire", newValue)
        }
      },
      // Certificate Expiry DateTime
      certificateExpiryDateTime: {
        get: function() {
          try {
            const isoDateTime = this.currentEvent.getField("certificateExpiryDateTime")
            console.log("isoDateTime:", isoDateTime)
            return isoDateTime.split("T")[0]
          } catch (err) {
            return ''
          }
        },
        set: function(newValue) {
          //console.log(`Changing certificateExpiryDateTime to: ${newValue}`)
          const isoDateTime = `${newValue}T00:00:00.000Z`
          this.currentEvent.setField("certificateExpiryDateTime", isoDateTime)
        }
      },
      saveButtonDisabled: function() {
        //const { changes } = this.internalStates
        const { status, isSavingEvent, isPublishingEvent, isDeletingEvent, isCancellingEvent, userInAdminMode } = this
        const disablingStatuses = [EVENT_STATUS.COMPLETED, EVENT_STATUS.CERTIFICATESSENT, EVENT_STATUS.DELETED]
        const statusIsBlocking = disablingStatuses.includes(status)
        const isViewOnly = this.internalStepperState === EVENT_STEPPER_STATE.VIEWONLY
        //console.log("saveButtonDisabled: is already saving event:", isSavingEvent)
        //console.log("saveButtonDisabled: is already deleting event:", isDeletingEvent)
        //console.log("saveButtonDisabled: is already cancelling event:", isCancellingEvent)
        //console.log("saveButtonDisabled: is already publishing event:", isPublishingEvent)
        //console.log("saveButtonDisabled: Status is blocking:", statusIsBlocking)
        //console.log("saveButtonDisabled: no changes have been made:", !this.dataHasChanged)
        //console.log("saveButtonDisabled: isViewOnlyView:", isViewOnly)
        if (userInAdminMode && this.dataHasChanged) { return false }
        return isSavingEvent || isPublishingEvent || isDeletingEvent || isCancellingEvent || statusIsBlocking || !this.dataHasChanged || isViewOnly
      },
      deleteEventButtonDisabled: function() {
        const { status, hasSaved, isSavingEvent, isPublishingEvent, isDeletingEvent, isCancellingEvent } = this
        const disablingStatuses = [EVENT_STATUS.LIVE, EVENT_STATUS.COMPLETED, EVENT_STATUS.CERTIFICATESSENT, EVENT_STATUS.DELETED]
        const statusIsBlocking = disablingStatuses.includes(status)
        const isViewOnly = this.internalStepperState === EVENT_STEPPER_STATE.VIEWONLY
        //console.log("deleteEventButtonDisabled: is already saving event:", isSavingEvent)
        //console.log("deleteEventButtonDisabled: is already deleting event:", isDeletingEvent)
        //console.log("deleteEventButtonDisabled: is already cancelling event:", isCancellingEvent)
        //console.log("deleteEventButtonDisabled: is already publishing event:", isPublishingEvent)
        //console.log("deleteEventButtonDisabled: Status is blocking:", statusIsBlocking)
        //console.log("deleteEventButtonDisabled: Event is in the database:", hasSaved)
        //console.log("deleteEventButtonDisabled: isViewOnlyView:", isViewOnly)
        return isSavingEvent || isPublishingEvent || isDeletingEvent || isCancellingEvent || statusIsBlocking || !hasSaved || isViewOnly
      },
      cancelEventButtonDisabled: function() {
        const { status, isSavingEvent, isPublishingEvent, isDeletingEvent, isCancellingEvent } = this
        const disablingStatuses = [EVENT_STATUS.DRAFT, EVENT_STATUS.COMPLETED, EVENT_STATUS.CERTIFICATESSENT, EVENT_STATUS.DELETED]
        const statusIsBlocking = disablingStatuses.includes(status)
        const isViewOnly = this.internalStepperState === EVENT_STEPPER_STATE.VIEWONLY
        //console.log("cancelEventButtonDisabled: is already saving event:", isSavingEvent)
        //console.log("cancelEventButtonDisabled: is already deleting event:", isDeletingEvent)
        //console.log("cancelEventButtonDisabled: is already cancelling event:", isCancellingEvent)
        //console.log("cancelEventButtonDisabled: is already publishing event:", isPublishingEvent)
        //console.log("cancelEventButtonDisabled: Status is blocking:", statusIsBlocking)
        //console.log("cancelEventButtonDisabled: isViewOnlyView:", isViewOnly)
        return isSavingEvent || isPublishingEvent || isDeletingEvent || isCancellingEvent || statusIsBlocking || isViewOnly
      },
      publishButtonDisabled: function() {
        const { status, hasSaved, isSavingEvent, isPublishingEvent, isDeletingEvent, isCancellingEvent, userInAdminMode } = this
        const disablingStatuses = [EVENT_STATUS.LIVE, EVENT_STATUS.COMPLETED, EVENT_STATUS.CERTIFICATESSENT, EVENT_STATUS.DELETED]
        const statusIsBlocking = disablingStatuses.includes(status)
        const isViewOnly = this.internalStepperState === EVENT_STEPPER_STATE.VIEWONLY
        //console.log("publishButtonDisabled: is already saving event:", isSavingEvent)
        //console.log("publishButtonDisabled: is already deleting event:", isDeletingEvent)
        //console.log("publishButtonDisabled: is already cancelling event:", isCancellingEvent)
        //console.log("publishButtonDisabled: is already publishing event:", isPublishingEvent)
        //console.log("publishButtonDisabled: Status is blocking:", statusIsBlocking)
        //console.log("publishButtonDisabled: Event is in the database:", hasSaved)
        //console.log("publishButtonDisabled: isViewOnlyView:", isViewOnly)
        // if this is a draft event, and the user is in admin mode, then enable the button
        if (userInAdminMode && status === EVENT_STATUS.DRAFT && hasSaved) { return false }
        return isSavingEvent || isPublishingEvent || isDeletingEvent || isCancellingEvent || statusIsBlocking || !hasSaved || isViewOnly || this.dataHasChanged
      },
      step2NextButtonDisabled: function() {
        // if there are no live prices, then step 2 cannot be completed
        const priceOptions = this.currentEvent.getPriceOptions(false)
        const eventType = this.currentEvent.getField('eventType')
        const noPrices = priceOptions.length === 0
        const eventIsPaid = eventType === EVENT_TYPE.PAID
        return noPrices && eventIsPaid
      },
      dataHasChanged: function() {
        //console.log("this.currentEvent:", this.currentEvent)
        //console.log("this.originalEvent:", this.originalEvent)
        //console.log("dataHasChanged:", ObjectsAreDifferent(this.currentEvent, this.originalEvent))
        return ObjectsAreDifferent(this.currentEvent.getData(), this.originalEvent.getData()) || this.currentEvent.hasChanges
      },
      filteredAccreditationLogos: function() {
        try {
          //console.log("accreditationLogos:", this.accreditationLogos)
          const filterResult = this.accreditationLogos.filter((accreditationLogo) => {
            const isHidden = accreditationLogo.status === IMAGE_STATUS.HIDDEN
            const isDeleted = accreditationLogo.status === IMAGE_STATUS.DELETED
            return (!isHidden && !isDeleted) || accreditationLogo.id === this.accreditationLogoId
          })
          //console.log("filterResult:", filterResult)
          return filterResult
        } catch (err) {
          console.warn("Unable to filter accreditation logo list")
          return []
        }
      },
      userIsAdmin: function() {
        try {
          // If this user is a MyProPass employee, they are an admin for everyone
          if (this.$store.getters.cognito.signInUserSession.accessToken.payload['cognito:groups'].includes(`ADMINISTRATOR`)) {
            return true
          }
          // Otherwise, check if they are an admin for the current provider
          const employerId = this.$store.getters.employer.id
          return this.$store.getters.cognito.signInUserSession.accessToken.payload['cognito:groups'].includes(`${employerId}_ADMINISTRATOR`)
        } catch (err) {
          console.warn("There was an error checking user admin status:", err)
          return false
        }
      },
      userInAdminMode: function() {
        return this.$store.getters.layout === LAYOUTS.ADMIN
      },
      eventHasAttendees: function() {
        // If this is a create event, it can't have attendees
        if (this.internalStepperState === EVENT_STEPPER_STATE.CREATE ) {
          return false
        }
        // If this is a copy event, it can't have attendees
        if (this.internalStepperState === EVENT_STEPPER_STATE.COPY ) {
          return false
        }
        // If this is a view only event, it doesn't matter, it has finished
        if (this.internalStepperState === EVENT_STEPPER_STATE.VIEWONLY ) {
          return true
        }
        // If there are attendees booked onto the course, return true
        return this.originalEvent.getAttendees().length > 0
      }

    },


    async mounted() {
      //console.log("this.event:", this.event)
      //console.log("this.stepperState:", this.stepperState)
      //console.log("this.currentEvent:", this.currentEvent)

      this.internalStepperState = this.stepperState

      // If this is an event creation...
      if (this.internalStepperState === EVENT_STEPPER_STATE.CREATE) {
        // Create an event object and the Event instances
        this.originalEvent.setField('providerId', this.$store.getters.employer.id)
        this.currentEvent = new Event(this.originalEvent)
      }

      // If this is an event copy...
      if (this.internalStepperState === EVENT_STEPPER_STATE.COPY) {
        // Create event instances based on the event to copy
        this.originalEvent = new Event(this.event, EVENT_STEPPER_STATE.COPY)
        this.currentEvent = new Event(this.originalEvent)
        this.currentEvent.hasChanges = true
      }

      // If this is an update or view only (i.e view an existing event)
      if (this.internalStepperState === EVENT_STEPPER_STATE.UPDATE || this.internalStepperState === EVENT_STEPPER_STATE.VIEWONLY) {
        this.hasSaved = true;
        this.originalEvent = new Event(this.event)
        this.currentEvent = new Event(this.originalEvent)
      }

      // Set the disabled flag depending on stepper state and if the user is an admin
      this.disabled = (this.internalStepperState === EVENT_STEPPER_STATE.VIEWONLY || !this.userIsAdmin) && !this.userInAdminMode

      // Get the employee list for the current provider
      try {
        const response = await ProviderService.ListEmployees({ id: this.$store.getters.employer.id })
        //console.log(response)
        //console.log(response.data.getEmployer.teamMembers.items)
        this.employeeList = response.data.getEmployer.teamMembers.items.filter((instructor) => {
          return instructor.accessRights.isCourseInstructor
        })
        //console.log("employee list successfully fetched")
      } catch (err) {
        console.warn("Error getting employee list", err)
      }

      // Get the accreditation logos
      try {
        const response = await ProviderService.GetAccreditationLogos(this.$store.getters.employer.id, this.$store)
        //console.log(response)
        this.accreditationLogos = response
        //console.log("accreditation logo list successfully fetched")
      } catch (err) {
        console.warn("Error getting accreditation logo list", err)
      }

      // Get the certificate templates
      try {
        const certificateService = new CertificateService(this.$store)
        const response = await certificateService.ListCertificates()
        //console.log("Certificate Templates:", response)
        this.certificateTemplates = response.sort((a, b) => a.name.localeCompare(b.name));
        //console.log("certificate templates list successfully fetched")
      } catch (err) {
        console.warn("Error getting certificate templates list", err)
      }

      // Get the event logos
      try {
        this.eventLogos = await ProviderService.GetEventLogos(this.$store.getters.employer.id)
        //console.log("Event logos successfully fetched")
      } catch (err) {
        console.warn("Error getting event logo list", err)
      }

      // Get the signatures that can be picked from
      try {
        await this.GetEmployerSignatures()
        //console.log("signatures list successfully fetched")
      } catch (err) {
        console.warn("Error getting signatures list", err)
      }

      /*
      const currentObjectGraphQL = this.currentEvent.getGraphQLObject()
      const originalObjectGraphQL = this.originalEvent.getGraphQLObject()
      console.log("originalObjectGraphQL:", originalObjectGraphQL)
      console.log("currentObjectGraphQL:", currentObjectGraphQL)
      */
    }

  }
</script>





<style scoped>
  .container-rounded {
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 5px;
    margin: 5px;
  }

  .container-rounded-grey {
    border-radius: 10px;
    background-color: rgba(243, 246,248, 0.7);
    padding: 5px;
    margin: 5px;
  }

  .ABC_in_Scaffolding {
    opacity: 1;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(39,62,75,1);
    letter-spacing: 0.2px;
  }

  .Duration__1_hour {
    opacity: 1;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    letter-spacing: 0.2px;
  }

  .disabled {
    pointer-events: none;
    color: #666;
    cursor: not-allowed;
    background-image: none;
    background-color: #ccc;
    border-color: #ccc;
  }

  .font-12 {
    font-size: 12px;
  }

  .align-flex-ss {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .position-over {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .certificate-preview-container {
    margin-top: 2em;
    width: 100%;
    text-align: left;
    display: block;
    font-family: 'Calibri'
  }

  .certificate-preview {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto;
    height: 141px;
    width: 100%;
    outline: 2px solid black;
    padding: 1em;
  }

  .certificate-preview .heading {
    margin: 0 auto 0.5em auto;
    font-size: 2em;
    text-align: center;
    font-weight: bold;
  }

  .certificate-preview .body {
    width: auto;
    margin-right: auto;
    margin-left: 0;
    text-align: left;
    font-size: 1.5em;
    display: grid;
    grid-gap: 1em;
    grid-template-columns: min-content auto auto
  }

  .warning-holder {
    margin-top: 0.5em;
    display: flex;
    flex-direction: column;
  }

  .vue__time-picker .controls {
    z-index: 10;
  }

  .address-search-bar-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .action-cell {
    display: flex;
    flex-direction: row;
  }

  .form-row.vertical-center {
    display: flex;
    align-items: center;
  }

  .form-row.vertical-center > input {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .form-row.vertical-center p {
    margin: 0 20px 0 6px;
    padding: 0;
  }

</style>

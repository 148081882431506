/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSignedUrl = /* GraphQL */ `
  query GetSignedUrl($identityId: String!, $fileName: String!) {
    getSignedUrl(identityId: $identityId, fileName: $fileName)
  }
`;
export const getAccountSubscription = /* GraphQL */ `
  query GetAccountSubscription($id: ID!) {
    getAccountSubscription(id: $id) {
      id
      accountTierID
      discount
      paymentCurrency
      groupsCanRead
      createdAt
      updatedAt
      owner
      accountTier {
        id
        tierName
        lifeCycle
        maxMarkets
        maxChannels
        maxBrands
        priceMonthlyGBP
        priceYearlyGBP
        createdAt
        updatedAt
        subscriptions {
          nextToken
          __typename
        }
        owner
        __typename
      }
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listAccountSubscriptions = /* GraphQL */ `
  query ListAccountSubscriptions(
    $filter: ModelAccountSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountTierID
        discount
        paymentCurrency
        groupsCanRead
        createdAt
        updatedAt
        owner
        accountTier {
          id
          tierName
          lifeCycle
          maxMarkets
          maxChannels
          maxBrands
          priceMonthlyGBP
          priceYearlyGBP
          createdAt
          updatedAt
          owner
          __typename
        }
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAccountTier = /* GraphQL */ `
  query GetAccountTier($id: ID!) {
    getAccountTier(id: $id) {
      id
      tierName
      lifeCycle
      maxMarkets
      maxChannels
      maxBrands
      priceMonthlyGBP
      priceYearlyGBP
      createdAt
      updatedAt
      subscriptions {
        items {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listAccountTiers = /* GraphQL */ `
  query ListAccountTiers(
    $filter: ModelAccountTierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountTiers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tierName
        lifeCycle
        maxMarkets
        maxChannels
        maxBrands
        priceMonthlyGBP
        priceYearlyGBP
        createdAt
        updatedAt
        subscriptions {
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAccreditationLogo = /* GraphQL */ `
  query GetAccreditationLogo($id: ID!) {
    getAccreditationLogo(id: $id) {
      id
      employerId
      name
      imageKey
      status
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      owner
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      events {
        items {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listAccreditationLogos = /* GraphQL */ `
  query ListAccreditationLogos(
    $filter: ModelAccreditationLogoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccreditationLogos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employerId
        name
        imageKey
        status
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        events {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const accreditationLogoByEmployerAndStatus = /* GraphQL */ `
  query AccreditationLogoByEmployerAndStatus(
    $employerId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccreditationLogoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accreditationLogoByEmployerAndStatus(
      employerId: $employerId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employerId
        name
        imageKey
        status
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        events {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCertificateTemplate = /* GraphQL */ `
  query GetCertificateTemplate($id: ID!) {
    getCertificateTemplate(id: $id) {
      id
      name
      imageKey
      fileKey
      pageOrientation
      placeholderFields
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      owner
      events {
        items {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listCertificateTemplates = /* GraphQL */ `
  query ListCertificateTemplates(
    $filter: ModelCertificateTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCertificateTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        imageKey
        fileKey
        pageOrientation
        placeholderFields
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        events {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCountry = /* GraphQL */ `
  query GetCountry($id: ID!) {
    getCountry(id: $id) {
      id
      englishShortName
      numericCode
      diallingCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCountries = /* GraphQL */ `
  query ListCountries(
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        englishShortName
        numericCode
        diallingCode
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeRole = /* GraphQL */ `
  query GetEmployeeRole($id: ID!) {
    getEmployeeRole(id: $id) {
      id
      userId
      employerId
      roleName
      accessRights {
        isAdministrator
        isCourseInstructor
        __typename
      }
      courseInstructorDetails {
        phone
        email
        aboutText
        isActive
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      owner
      employerGroups {
        items {
          id
          employeeId
          groupId
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const listEmployeeRoles = /* GraphQL */ `
  query ListEmployeeRoles(
    $filter: ModelEmployeeRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        employerId
        roleName
        accessRights {
          isAdministrator
          isCourseInstructor
          __typename
        }
        courseInstructorDetails {
          phone
          email
          aboutText
          isActive
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        employerGroups {
          nextToken
          __typename
        }
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        user {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeGroupAllocation = /* GraphQL */ `
  query GetEmployeeGroupAllocation($id: ID!) {
    getEmployeeGroupAllocation(id: $id) {
      id
      employeeId
      groupId
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      employee {
        id
        userId
        employerId
        roleName
        accessRights {
          isAdministrator
          isCourseInstructor
          __typename
        }
        courseInstructorDetails {
          phone
          email
          aboutText
          isActive
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        employerGroups {
          nextToken
          __typename
        }
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        user {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      owner
      group {
        id
        name
        description
        employerId
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        groupMembers {
          nextToken
          __typename
        }
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const listEmployeeGroupAllocations = /* GraphQL */ `
  query ListEmployeeGroupAllocations(
    $filter: ModelEmployeeGroupAllocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeGroupAllocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employeeId
        groupId
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        employee {
          id
          userId
          employerId
          roleName
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        group {
          id
          name
          description
          employerId
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const groupAllocationsByGroup = /* GraphQL */ `
  query GroupAllocationsByGroup(
    $groupId: ID
    $employeeId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeGroupAllocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupAllocationsByGroup(
      groupId: $groupId
      employeeId: $employeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employeeId
        groupId
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        employee {
          id
          userId
          employerId
          roleName
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        group {
          id
          name
          description
          employerId
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployer = /* GraphQL */ `
  query GetEmployer($id: ID!) {
    getEmployer(id: $id) {
      id
      name
      description
      address {
        line1
        line2
        line3
        city
        region
        postalCode
        country
        __typename
      }
      logo {
        id
        name
        imageKey
        __typename
      }
      contactDetails {
        emailBookings
        phoneBookings
        __typename
      }
      isCourseProvider
      courseProviderDetails {
        description
        whyChooseUs
        websiteURL
        registrationNumber
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      createdAt
      updatedAt
      accountSubscription {
        id
        accountTierID
        discount
        paymentCurrency
        groupsCanRead
        createdAt
        updatedAt
        owner
        accountTier {
          id
          tierName
          lifeCycle
          maxMarkets
          maxChannels
          maxBrands
          priceMonthlyGBP
          priceYearlyGBP
          createdAt
          updatedAt
          owner
          __typename
        }
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      accreditationLogos {
        items {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      teamMembers {
        items {
          id
          userId
          employerId
          roleName
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      employerGroups {
        items {
          id
          name
          description
          employerId
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      eventLogos {
        items {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      pendingTeamMembers {
        items {
          id
          userId
          employerId
          emailAddress
          roleName
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signatures {
        items {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listEmployers = /* GraphQL */ `
  query ListEmployers(
    $filter: ModelEmployerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployerGroup = /* GraphQL */ `
  query GetEmployerGroup($id: ID!) {
    getEmployerGroup(id: $id) {
      id
      name
      description
      employerId
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      groupMembers {
        items {
          id
          employeeId
          groupId
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listEmployerGroups = /* GraphQL */ `
  query ListEmployerGroups(
    $filter: ModelEmployerGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployerGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        employerId
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        groupMembers {
          nextToken
          __typename
        }
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      description
      code
      targetAudience
      isExternal
      externalType
      eventType
      eventMode
      status
      accessLevel
      possibleActions
      providerId
      instructorId
      preRequisites
      proofOfIdentity
      address {
        line1
        line2
        line3
        city
        region
        postalCode
        country
        __typename
      }
      onlineURL
      maximumAttendees
      startDateTime
      endDateTime
      attendanceHours
      cpdHours
      latitude
      longitude
      eventValidityDateTime
      certificatesExpire
      certificateExpiryDateTime
      accreditationLogoId
      signatureId
      eventLogoId
      certificateTemplateId
      certificateCustomisation {
        providerLogoDimensionsHorizontal
        providerLogoDimensionsVertical
        eventLogoDimensionsHorizontal
        eventLogoDimensionsVertical
        signatureDimensionsHorizontal
        signatureDimensionsVertical
        colorBackground
        colorFontPrimary
        colorFontSecondary
        useCertificateSignerName
        certificateSignerName
        __typename
      }
      attendeeStatistics {
        booked
        onWaitingList
        attended
        noShow
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      createdAt
      updatedAt
      accreditationLogo {
        id
        employerId
        name
        imageKey
        status
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        events {
          nextToken
          __typename
        }
        __typename
      }
      certificateTemplate {
        id
        name
        imageKey
        fileKey
        pageOrientation
        placeholderFields
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        events {
          nextToken
          __typename
        }
        __typename
      }
      provider {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      owner
      attendees {
        items {
          id
          userId
          eventId
          status
          seatNumber
          attendance
          certificateSent
          certificateSaved
          bookingDateTime
          stripeSessionId
          paymentId
          groupsCanRead
          groupsFullControl
          blockAdminCreateNotification
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      eventLogo {
        id
        employerId
        name
        imageKey
        status
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        events {
          nextToken
          __typename
        }
        owner
        __typename
      }
      prices {
        items {
          id
          eventId
          title
          description
          status
          priceGBP
          priceUSD
          priceEUR
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signature {
        id
        employerId
        name
        imageKey
        status
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        events {
          nextToken
          __typename
        }
        owner
        __typename
      }
      instructor {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        code
        targetAudience
        isExternal
        externalType
        eventType
        eventMode
        status
        accessLevel
        possibleActions
        providerId
        instructorId
        preRequisites
        proofOfIdentity
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        onlineURL
        maximumAttendees
        startDateTime
        endDateTime
        attendanceHours
        cpdHours
        latitude
        longitude
        eventValidityDateTime
        certificatesExpire
        certificateExpiryDateTime
        accreditationLogoId
        signatureId
        eventLogoId
        certificateTemplateId
        certificateCustomisation {
          providerLogoDimensionsHorizontal
          providerLogoDimensionsVertical
          eventLogoDimensionsHorizontal
          eventLogoDimensionsVertical
          signatureDimensionsHorizontal
          signatureDimensionsVertical
          colorBackground
          colorFontPrimary
          colorFontSecondary
          useCertificateSignerName
          certificateSignerName
          __typename
        }
        attendeeStatistics {
          booked
          onWaitingList
          attended
          noShow
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accreditationLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        certificateTemplate {
          id
          name
          imageKey
          fileKey
          pageOrientation
          placeholderFields
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        provider {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        attendees {
          nextToken
          __typename
        }
        eventLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        prices {
          nextToken
          __typename
        }
        signature {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        instructor {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventByCode = /* GraphQL */ `
  query EventByCode(
    $code: String
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        code
        targetAudience
        isExternal
        externalType
        eventType
        eventMode
        status
        accessLevel
        possibleActions
        providerId
        instructorId
        preRequisites
        proofOfIdentity
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        onlineURL
        maximumAttendees
        startDateTime
        endDateTime
        attendanceHours
        cpdHours
        latitude
        longitude
        eventValidityDateTime
        certificatesExpire
        certificateExpiryDateTime
        accreditationLogoId
        signatureId
        eventLogoId
        certificateTemplateId
        certificateCustomisation {
          providerLogoDimensionsHorizontal
          providerLogoDimensionsVertical
          eventLogoDimensionsHorizontal
          eventLogoDimensionsVertical
          signatureDimensionsHorizontal
          signatureDimensionsVertical
          colorBackground
          colorFontPrimary
          colorFontSecondary
          useCertificateSignerName
          certificateSignerName
          __typename
        }
        attendeeStatistics {
          booked
          onWaitingList
          attended
          noShow
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accreditationLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        certificateTemplate {
          id
          name
          imageKey
          fileKey
          pageOrientation
          placeholderFields
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        provider {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        attendees {
          nextToken
          __typename
        }
        eventLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        prices {
          nextToken
          __typename
        }
        signature {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        instructor {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventByEmployerAndStartDate = /* GraphQL */ `
  query EventByEmployerAndStartDate(
    $providerId: ID
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByEmployerAndStartDate(
      providerId: $providerId
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        code
        targetAudience
        isExternal
        externalType
        eventType
        eventMode
        status
        accessLevel
        possibleActions
        providerId
        instructorId
        preRequisites
        proofOfIdentity
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        onlineURL
        maximumAttendees
        startDateTime
        endDateTime
        attendanceHours
        cpdHours
        latitude
        longitude
        eventValidityDateTime
        certificatesExpire
        certificateExpiryDateTime
        accreditationLogoId
        signatureId
        eventLogoId
        certificateTemplateId
        certificateCustomisation {
          providerLogoDimensionsHorizontal
          providerLogoDimensionsVertical
          eventLogoDimensionsHorizontal
          eventLogoDimensionsVertical
          signatureDimensionsHorizontal
          signatureDimensionsVertical
          colorBackground
          colorFontPrimary
          colorFontSecondary
          useCertificateSignerName
          certificateSignerName
          __typename
        }
        attendeeStatistics {
          booked
          onWaitingList
          attended
          noShow
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accreditationLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        certificateTemplate {
          id
          name
          imageKey
          fileKey
          pageOrientation
          placeholderFields
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        provider {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        attendees {
          nextToken
          __typename
        }
        eventLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        prices {
          nextToken
          __typename
        }
        signature {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        instructor {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventByStatusAndStartDate = /* GraphQL */ `
  query EventByStatusAndStartDate(
    $status: String
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByStatusAndStartDate(
      status: $status
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        code
        targetAudience
        isExternal
        externalType
        eventType
        eventMode
        status
        accessLevel
        possibleActions
        providerId
        instructorId
        preRequisites
        proofOfIdentity
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        onlineURL
        maximumAttendees
        startDateTime
        endDateTime
        attendanceHours
        cpdHours
        latitude
        longitude
        eventValidityDateTime
        certificatesExpire
        certificateExpiryDateTime
        accreditationLogoId
        signatureId
        eventLogoId
        certificateTemplateId
        certificateCustomisation {
          providerLogoDimensionsHorizontal
          providerLogoDimensionsVertical
          eventLogoDimensionsHorizontal
          eventLogoDimensionsVertical
          signatureDimensionsHorizontal
          signatureDimensionsVertical
          colorBackground
          colorFontPrimary
          colorFontSecondary
          useCertificateSignerName
          certificateSignerName
          __typename
        }
        attendeeStatistics {
          booked
          onWaitingList
          attended
          noShow
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accreditationLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        certificateTemplate {
          id
          name
          imageKey
          fileKey
          pageOrientation
          placeholderFields
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        provider {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        attendees {
          nextToken
          __typename
        }
        eventLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        prices {
          nextToken
          __typename
        }
        signature {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        instructor {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventBooking = /* GraphQL */ `
  query GetEventBooking($id: ID!) {
    getEventBooking(id: $id) {
      id
      userId
      eventId
      status
      seatNumber
      attendance
      certificateSent
      certificateSaved
      bookingDateTime
      stripeSessionId
      paymentId
      groupsCanRead
      groupsFullControl
      blockAdminCreateNotification
      createdAt
      updatedAt
      event {
        id
        title
        description
        code
        targetAudience
        isExternal
        externalType
        eventType
        eventMode
        status
        accessLevel
        possibleActions
        providerId
        instructorId
        preRequisites
        proofOfIdentity
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        onlineURL
        maximumAttendees
        startDateTime
        endDateTime
        attendanceHours
        cpdHours
        latitude
        longitude
        eventValidityDateTime
        certificatesExpire
        certificateExpiryDateTime
        accreditationLogoId
        signatureId
        eventLogoId
        certificateTemplateId
        certificateCustomisation {
          providerLogoDimensionsHorizontal
          providerLogoDimensionsVertical
          eventLogoDimensionsHorizontal
          eventLogoDimensionsVertical
          signatureDimensionsHorizontal
          signatureDimensionsVertical
          colorBackground
          colorFontPrimary
          colorFontSecondary
          useCertificateSignerName
          certificateSignerName
          __typename
        }
        attendeeStatistics {
          booked
          onWaitingList
          attended
          noShow
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accreditationLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        certificateTemplate {
          id
          name
          imageKey
          fileKey
          pageOrientation
          placeholderFields
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        provider {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        attendees {
          nextToken
          __typename
        }
        eventLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        prices {
          nextToken
          __typename
        }
        signature {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        instructor {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      owner
      payment {
        id
        method
        stripeTransactionId
        currency
        amount
        status
        invoiceNumber
        createdAt
        updatedAt
        owner
        __typename
      }
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const listEventBookings = /* GraphQL */ `
  query ListEventBookings(
    $filter: ModelEventBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        eventId
        status
        seatNumber
        attendance
        certificateSent
        certificateSaved
        bookingDateTime
        stripeSessionId
        paymentId
        groupsCanRead
        groupsFullControl
        blockAdminCreateNotification
        createdAt
        updatedAt
        event {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        payment {
          id
          method
          stripeTransactionId
          currency
          amount
          status
          invoiceNumber
          createdAt
          updatedAt
          owner
          __typename
        }
        user {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventBookingByUserId = /* GraphQL */ `
  query EventBookingByUserId(
    $userId: ID
    $eventId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventBookingByUserId(
      userId: $userId
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        eventId
        status
        seatNumber
        attendance
        certificateSent
        certificateSaved
        bookingDateTime
        stripeSessionId
        paymentId
        groupsCanRead
        groupsFullControl
        blockAdminCreateNotification
        createdAt
        updatedAt
        event {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        payment {
          id
          method
          stripeTransactionId
          currency
          amount
          status
          invoiceNumber
          createdAt
          updatedAt
          owner
          __typename
        }
        user {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventLogo = /* GraphQL */ `
  query GetEventLogo($id: ID!) {
    getEventLogo(id: $id) {
      id
      employerId
      name
      imageKey
      status
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      events {
        items {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listEventLogos = /* GraphQL */ `
  query ListEventLogos(
    $filter: ModelEventLogoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventLogos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        employerId
        name
        imageKey
        status
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        events {
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventLogoByEmployerAndStatus = /* GraphQL */ `
  query EventLogoByEmployerAndStatus(
    $employerId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventLogoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventLogoByEmployerAndStatus(
      employerId: $employerId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employerId
        name
        imageKey
        status
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        events {
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventPrice = /* GraphQL */ `
  query GetEventPrice($id: ID!) {
    getEventPrice(id: $id) {
      id
      eventId
      title
      description
      status
      priceGBP
      priceUSD
      priceEUR
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      event {
        id
        title
        description
        code
        targetAudience
        isExternal
        externalType
        eventType
        eventMode
        status
        accessLevel
        possibleActions
        providerId
        instructorId
        preRequisites
        proofOfIdentity
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        onlineURL
        maximumAttendees
        startDateTime
        endDateTime
        attendanceHours
        cpdHours
        latitude
        longitude
        eventValidityDateTime
        certificatesExpire
        certificateExpiryDateTime
        accreditationLogoId
        signatureId
        eventLogoId
        certificateTemplateId
        certificateCustomisation {
          providerLogoDimensionsHorizontal
          providerLogoDimensionsVertical
          eventLogoDimensionsHorizontal
          eventLogoDimensionsVertical
          signatureDimensionsHorizontal
          signatureDimensionsVertical
          colorBackground
          colorFontPrimary
          colorFontSecondary
          useCertificateSignerName
          certificateSignerName
          __typename
        }
        attendeeStatistics {
          booked
          onWaitingList
          attended
          noShow
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accreditationLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        certificateTemplate {
          id
          name
          imageKey
          fileKey
          pageOrientation
          placeholderFields
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        provider {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        attendees {
          nextToken
          __typename
        }
        eventLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        prices {
          nextToken
          __typename
        }
        signature {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        instructor {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listEventPrices = /* GraphQL */ `
  query ListEventPrices(
    $filter: ModelEventPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventPrices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventId
        title
        description
        status
        priceGBP
        priceUSD
        priceEUR
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        event {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventPriceByEvent = /* GraphQL */ `
  query EventPriceByEvent(
    $eventId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEventPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventPriceByEvent(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        title
        description
        status
        priceGBP
        priceUSD
        priceEUR
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        event {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getExperienceCertificateTemplate = /* GraphQL */ `
  query GetExperienceCertificateTemplate($id: ID!) {
    getExperienceCertificateTemplate(id: $id) {
      id
      name
      imageKey
      fileKey
      pageOrientation
      placeholderFields
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listExperienceCertificateTemplates = /* GraphQL */ `
  query ListExperienceCertificateTemplates(
    $filter: ModelExperienceCertificateTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExperienceCertificateTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        imageKey
        fileKey
        pageOrientation
        placeholderFields
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getExperienceRecord = /* GraphQL */ `
  query GetExperienceRecord($id: ID!) {
    getExperienceRecord(id: $id) {
      id
      title
      description
      issueDateTime
      startDateTime
      endDateTime
      providerId
      nameProvided
      userId
      experienceEventId
      certificateTemplateId
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      provider {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      certificateTemplate {
        id
        name
        imageKey
        fileKey
        pageOrientation
        placeholderFields
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const listExperienceRecords = /* GraphQL */ `
  query ListExperienceRecords(
    $filter: ModelExperienceRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExperienceRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        issueDateTime
        startDateTime
        endDateTime
        providerId
        nameProvided
        userId
        experienceEventId
        certificateTemplateId
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        provider {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        certificateTemplate {
          id
          name
          imageKey
          fileKey
          pageOrientation
          placeholderFields
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        user {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const experienceRecordByUserId = /* GraphQL */ `
  query ExperienceRecordByUserId(
    $userId: ID
    $issueDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExperienceRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    experienceRecordByUserId(
      userId: $userId
      issueDateTime: $issueDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        issueDateTime
        startDateTime
        endDateTime
        providerId
        nameProvided
        userId
        experienceEventId
        certificateTemplateId
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        provider {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        certificateTemplate {
          id
          name
          imageKey
          fileKey
          pageOrientation
          placeholderFields
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        user {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const experienceRecordByProviderId = /* GraphQL */ `
  query ExperienceRecordByProviderId(
    $providerId: ID
    $issueDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExperienceRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    experienceRecordByProviderId(
      providerId: $providerId
      issueDateTime: $issueDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        issueDateTime
        startDateTime
        endDateTime
        providerId
        nameProvided
        userId
        experienceEventId
        certificateTemplateId
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        provider {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        certificateTemplate {
          id
          name
          imageKey
          fileKey
          pageOrientation
          placeholderFields
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        user {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userId
      title
      description
      type
      status
      groupsCanRead
      groupsCanUpdate
      createdAt
      updatedAt
      owner
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        title
        description
        type
        status
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        owner
        user {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationsByUser = /* GraphQL */ `
  query NotificationsByUser(
    $userId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByUser(
      userId: $userId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        title
        description
        type
        status
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        owner
        user {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      method
      stripeTransactionId
      currency
      amount
      status
      invoiceNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        method
        stripeTransactionId
        currency
        amount
        status
        invoiceNumber
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPendingEmployee = /* GraphQL */ `
  query GetPendingEmployee($id: ID!) {
    getPendingEmployee(id: $id) {
      id
      userId
      employerId
      emailAddress
      roleName
      accessRights {
        isAdministrator
        isCourseInstructor
        __typename
      }
      courseInstructorDetails {
        phone
        email
        aboutText
        isActive
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listPendingEmployees = /* GraphQL */ `
  query ListPendingEmployees(
    $filter: ModelPendingEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPendingEmployees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        employerId
        emailAddress
        roleName
        accessRights {
          isAdministrator
          isCourseInstructor
          __typename
        }
        courseInstructorDetails {
          phone
          email
          aboutText
          isActive
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSignatures = /* GraphQL */ `
  query GetSignatures($id: ID!) {
    getSignatures(id: $id) {
      id
      employerId
      name
      imageKey
      status
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      events {
        items {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listSignatures = /* GraphQL */ `
  query ListSignatures(
    $filter: ModelSignaturesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSignatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        employerId
        name
        imageKey
        status
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        events {
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const signaturesByEmployerAndStatus = /* GraphQL */ `
  query SignaturesByEmployerAndStatus(
    $employerId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSignaturesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    signaturesByEmployerAndStatus(
      employerId: $employerId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employerId
        name
        imageKey
        status
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        events {
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSystemStatistic = /* GraphQL */ `
  query GetSystemStatistic(
    $dateTime: String!
    $statisticName: String!
    $countryCode: String!
  ) {
    getSystemStatistic(
      dateTime: $dateTime
      statisticName: $statisticName
      countryCode: $countryCode
    ) {
      dateTime
      statisticName
      countryCode
      periodDelta
      allTimeTotal
      additionalData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSystemStatistics = /* GraphQL */ `
  query ListSystemStatistics(
    $dateTime: String
    $statisticNameCountryCode: ModelSystemStatisticPrimaryCompositeKeyConditionInput
    $filter: ModelSystemStatisticFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSystemStatistics(
      dateTime: $dateTime
      statisticNameCountryCode: $statisticNameCountryCode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        dateTime
        statisticName
        countryCode
        periodDelta
        allTimeTotal
        additionalData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStatisticsByDateAndCountry = /* GraphQL */ `
  query GetStatisticsByDateAndCountry(
    $dateTime: String
    $countryCodeStatisticName: ModelSystemStatisticGetStatisticsByDateAndCountryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSystemStatisticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStatisticsByDateAndCountry(
      dateTime: $dateTime
      countryCodeStatisticName: $countryCodeStatisticName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dateTime
        statisticName
        countryCode
        periodDelta
        allTimeTotal
        additionalData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStatisticsByNameCountryAndDate = /* GraphQL */ `
  query GetStatisticsByNameCountryAndDate(
    $statisticName: String
    $countryCodeDateTime: ModelSystemStatisticGetStatisticsByNameCountryAndDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSystemStatisticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStatisticsByNameCountryAndDate(
      statisticName: $statisticName
      countryCodeDateTime: $countryCodeDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dateTime
        statisticName
        countryCode
        periodDelta
        allTimeTotal
        additionalData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStatisticsByNameDateAndCountry = /* GraphQL */ `
  query GetStatisticsByNameDateAndCountry(
    $statisticName: String
    $dateTimeCountryCode: ModelSystemStatisticGetStatisticsByNameDateAndCountryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSystemStatisticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStatisticsByNameDateAndCountry(
      statisticName: $statisticName
      dateTimeCountryCode: $dateTimeCountryCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dateTime
        statisticName
        countryCode
        periodDelta
        allTimeTotal
        additionalData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUploadedEventRecord = /* GraphQL */ `
  query GetUploadedEventRecord($id: ID!) {
    getUploadedEventRecord(id: $id) {
      id
      title
      description
      providerName
      location
      eventDateTime
      expiryDateTime
      cpdHours
      fileName
      userId
      createdAt
      updatedAt
      owner
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const listUploadedEventRecords = /* GraphQL */ `
  query ListUploadedEventRecords(
    $filter: ModelUploadedEventRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUploadedEventRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        providerName
        location
        eventDateTime
        expiryDateTime
        cpdHours
        fileName
        userId
        createdAt
        updatedAt
        owner
        user {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const uploadedEventRecordByUserAndDate = /* GraphQL */ `
  query UploadedEventRecordByUserAndDate(
    $userId: ID
    $eventDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUploadedEventRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    uploadedEventRecordByUserAndDate(
      userId: $userId
      eventDateTime: $eventDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        providerName
        location
        eventDateTime
        expiryDateTime
        cpdHours
        fileName
        userId
        createdAt
        updatedAt
        owner
        user {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      identityId
      emailAddress
      firstName
      lastName
      countryId
      userDetail {
        accessLevel
        dateOfBirth
        avatar {
          id
          name
          imageKey
          __typename
        }
        location
        jobTitle
        phoneNumber
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      resendTemporaryPassword
      lastAcceptedTandCDateTime
      createdAt
      updatedAt
      employer {
        items {
          id
          userId
          employerId
          roleName
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      bookings {
        items {
          id
          userId
          eventId
          status
          seatNumber
          attendance
          certificateSent
          certificateSaved
          bookingDateTime
          stripeSessionId
          paymentId
          groupsCanRead
          groupsFullControl
          blockAdminCreateNotification
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      experienceRecords {
        items {
          id
          title
          description
          issueDateTime
          startDateTime
          endDateTime
          providerId
          nameProvided
          userId
          experienceEventId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      pendingEmployeeRoles {
        items {
          id
          userId
          employerId
          emailAddress
          roleName
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      uploadedEventRecords {
        items {
          id
          title
          description
          providerName
          location
          eventDateTime
          expiryDateTime
          cpdHours
          fileName
          userId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $emailAddress: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      emailAddress: $emailAddress
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

<template>
  <v-app>
    <component v-bind:is="layout"></component>
  </v-app>
</template>

<script>
  import NotSignedInLayout from '@/layouts/notsignedin-layout'
  import ExternalViewLayout from '@/layouts/externalview-layout'
  import LoginLayout from '@/layouts/login-layout'
  import UserLayout from '@/layouts/user-layout'
  import ProviderLayout from '@/layouts/provider-layout'
  import AdminLayout from '@/layouts/admin-layout'
  import ErrorLayout from '@/layouts/error-layout'
  export default {
    computed: {
      layout() {
          return this.$store.getters.layout
      }
    },
    components: {
      'notsignedin-layout': NotSignedInLayout,
      'externalview-layout': ExternalViewLayout,
      'login-layout': LoginLayout,
      'user-layout': UserLayout,
      'provider-layout': ProviderLayout,
      'admin-layout': AdminLayout,
      'error-layout': ErrorLayout
    }
  }
</script>

<style>
  @import '../css/site.css';
  @import '../css/vendor.min.css';
</style>

export const getUserDetails = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      identityId
      emailAddress
      firstName
      lastName
      countryId
      employer {
        items {
          id
          roleName
          accessRights {
            isAdministrator
            isCourseInstructor
          }
          employer {
            id
            name
            description
            logo {
              id
              name
              imageKey
            }
            isCourseProvider
            courseProviderDetails {
              description
              whyChooseUs
            }
            accreditationLogos {
              items {
                id
                name
                imageKey
                status
              }
              nextToken
            }
          }
        }
        nextToken
      }
      pendingEmployeeRoles {
        items {
          id
          userId
          employerId
          emailAddress
          roleName
          accessRights {
            isAdministrator
            isCourseInstructor
          }
          employer {
            id
            name
            logo {
              id
              name
              imageKey
            }
          }
          courseInstructorDetails {
            phone
            email
            aboutText
            isActive
          }
          createdAt
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
        }
        nextToken
      }
      bookings {
        items {
          id
          status
          seatNumber
          attendance
          certificateSent
          certificateSaved
          bookingDateTime
          createdAt
          updatedAt
          event {
            id
            title
            description
            startDateTime
            endDateTime
            cpdHours
            attendanceHours
            code
            targetAudience
            isExternal
            externalType
            eventType
            eventMode
            accessLevel
            status
            certificatesExpire
            certificateExpiryDateTime
            eventLogo {
              id
              name
              imageKey
              status
            }
            address {
              line1
              line2
              line3
              city
              region
              postalCode
              country
            }
            onlineURL
            provider {
              id
              name
              logo {
                id
                name
                imageKey
              }
              description
              address {
                line1
                line2
                line3
                city
                region
                postalCode
                country
              }
              contactDetails {
                emailBookings
                phoneBookings
              }
              courseProviderDetails {
                description
                whyChooseUs
                websiteURL
                registrationNumber
              }
            }
            accreditationLogo {
              id
              name
              imageKey
            }
            certificateTemplate {
              id
              name
              imageKey
              fileKey
            }
            certificateCustomisation {
              colorBackground
              colorFontPrimary
              colorFontSecondary
              useCertificateSignerName
              certificateSignerName
            }
            signatureId
            signature {
              id
              name
              imageKey
              status
            }
            instructor {
              id
              firstName
              lastName
            }
          }
        }
        nextToken
      }
      userDetail {
        accessLevel
        dateOfBirth
        avatar {
          id
          name
          imageKey
        }
        location
        jobTitle
        phoneNumber
      }
      lastAcceptedTandCDateTime
      experienceRecords {
        items {
          id
          title
          description
          issueDateTime
          startDateTime
          endDateTime
          providerId
          userId
          experienceEventId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        code
        targetAudience
        isExternal
        externalType
        eventType
        eventMode
        accessLevel
        status
        possibleActions
        providerId
        provider {
          id
          name
          description
          logo {
            id
            name
            imageKey
          }
        }
        instructorId
        instructor {
          id
          firstName
          lastName
          employer {
            items {
              courseInstructorDetails {
                phone
                email
                aboutText
              }
            }
            nextToken
          }
        }
        eventLogoId
        eventLogo {
          id
          name
          imageKey
          status
        }
        signatureId
        preRequisites
        proofOfIdentity
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
        }
        onlineURL
        prices {
          items {
            id
            title
            description
            priceGBP
            priceUSD
            priceEUR
            eventId
            status
          }
          nextToken
        }
        maximumAttendees
        startDateTime
        endDateTime
        cpdHours
        attendanceHours
        latitude
        longitude
        eventValidityDateTime
        certificatesExpire
        certificateExpiryDateTime
        accreditationLogoId
        accreditationLogo {
          id
          name
          imageKey
        }
        attendees {
          items {
            id
            status
          }
          nextToken
        }
        attendeeStatistics {
          booked
          onWaitingList
          attended
          noShow
        }
        certificateTemplateId
        certificateCustomisation {
          colorBackground
          colorFontPrimary
          colorFontSecondary
          useCertificateSignerName
          certificateSignerName
        }
      }
      nextToken
    }
  }
`;
export const notificationsByUser = /* GraphQL */ `
  query NotificationsByUser(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        type
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getEventDetailsForBookingScreen  = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      description
      code
      targetAudience
      isExternal
      externalType
      eventType
      eventMode
      accessLevel
      status
      possibleActions
      providerId
      provider {
        id
        name
        description
        logo {
          id
          name
          imageKey
        }
        contactDetails {
          emailBookings
          phoneBookings
        }
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
        }
      }
      instructorId
      instructor {
        id
        firstName
        lastName
        employer {
          items {
            courseInstructorDetails {
              phone
              email
              aboutText
            }
          }
          nextToken
        }
      }
      eventLogo {
        id
        name
        imageKey
        status
      }
      preRequisites
      proofOfIdentity
      address {
        line1
        line2
        line3
        city
        region
        postalCode
        country
      }
      onlineURL
      prices {
        items {
          id
          title
          description
          priceGBP
          priceUSD
          priceEUR
          eventId
          status
        }
        nextToken
      }
      maximumAttendees
      startDateTime
      endDateTime
      cpdHours
      attendanceHours
      latitude
      longitude
      eventValidityDateTime
      certificatesExpire
      certificateExpiryDateTime
      accreditationLogoId
      accreditationLogo {
        id
        name
        imageKey
      }
      attendees {
        items {
          id
          status
        }
        nextToken
      }
      attendeeStatistics {
        booked
        onWaitingList
        attended
        noShow
      }
    }
  }
`;
export const getEventProviderDetails = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      provider {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
        }
        logo {
          id
          name
          imageKey
        }
        contactDetails {
          emailBookings
          phoneBookings
        }
        accreditationLogos {
          items {
            id
            employerId
            name
            imageKey
            status
          }
          nextToken
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
        }
      }
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query GetEmployer($id: ID!) {
    getEmployer(id: $id) {
      teamMembers {
        items {
          id
          user {
            id
            firstName
            lastName
          }
          roleName
          accessRights {
            isAdministrator
            isCourseInstructor
          }
          courseInstructorDetails {
            phone
            email
            aboutText
            isActive
          }
        }
        nextToken
      }
    }
  }
`;
export const getAccreditationLogos = /* GraphQL */ `
  query GetEmployer($id: ID!) {
    getEmployer(id: $id) {
      accreditationLogos {
        items {
          id
          name
          imageKey
          status
        }
        nextToken
      }
    }
  }
`;
export const getEventLogos = /* GraphQL */ `
  query GetEmployer($id: ID!) {
    getEmployer(id: $id) {
      eventLogos {
        items {
          id
          name
          imageKey
          status
        }
        nextToken
      }
    }
  }
`;
export const getSignatures = /* GraphQL */ `
  query GetEmployer($id: ID!) {
    getEmployer(id: $id) {
      signatures {
        items {
          id
          name
          imageKey
          status
        }
        nextToken
      }
    }
  }
`;

export const getEventAndAttendees  = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      description
      code
      targetAudience
      isExternal
      externalType
      eventType
      eventMode
      accessLevel
      instructor {
        firstName
        lastName
      }
      provider {
        name
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
        }
        logo {
          imageKey
        }
        courseProviderDetails {
          websiteURL
          registrationNumber
        }
      }
      status
      address {
        line1
        line2
        line3
        city
        region
        postalCode
        country
      }
      onlineURL
      eventLogo {
        id
        name
        imageKey
        status
      }
      startDateTime
      endDateTime
      accreditationLogo {
        id
        name
        imageKey
        status
      }
      certificateTemplate {
        id
        name
        imageKey
        fileKey
      }
      certificateCustomisation {
        colorBackground
        colorFontPrimary
        colorFontSecondary
        useCertificateSignerName
        certificateSignerName
      }
      signatureId
      signature {
        id
        name
        imageKey
        status
      }
      attendees {
        items {
          id
          seatNumber
          user {
            id
            identityId
            firstName
            lastName
            emailAddress
          }
          status
          attendance
          certificateSent
          certificateSaved
        }
        nextToken
      }
      attendeeStatistics {
        booked
        onWaitingList
        attended
        noShow
      }
    }
  }
`;
export const queryEventForAttendeeListWithAttendeeNextToken = /* GraphQL */ `
  query GetEvent(
    $id: ID!
    $nextToken: String
  ) {
    getEvent(id: $id) {
      attendees(nextToken: $nextToken) {
        items {
          id
          status
          seatNumber
          attendance
          certificateSent
          certificateSaved
          user {
            id
            identityId
            emailAddress
            firstName
            lastName
          }
        }
        nextToken
      }
      attendeeStatistics {
        booked
        onWaitingList
        attended
        noShow
      }
    }
  }
`;
export const getEmployer = /* GraphQL */ `
  query GetEmployer($id: ID!) {
    getEmployer(id: $id) {
      id
      name
      description
      address {
        line1
        line2
        line3
        city
        region
        postalCode
        country
      }
      logo {
        id
        name
        imageKey
      }
      contactDetails {
        emailBookings
        phoneBookings
      }
      teamMembers {
        items {
          id
          user {
            id
            firstName
            lastName
          }
          roleName
          courseInstructorDetails {
            phone
            email
            aboutText
            isActive
          }
          employerGroups {
            items {
              id
              group {
                id
                name
                description
              }
            }
          }
          accessRights {
            isAdministrator
            isCourseInstructor
          }
        }
        nextToken
      }
      pendingTeamMembers {
        items {
          id
          userId
          employerId
          emailAddress
          roleName
          accessRights {
            isAdministrator
            isCourseInstructor
          }
          courseInstructorDetails {
            phone
            email
            aboutText
            isActive
          }
        }
        nextToken
      }
      isCourseProvider
      courseProviderDetails {
        description
        whyChooseUs
        websiteURL
        registrationNumber
      }
      accreditationLogos {
        items {
          id
          employerId
          name
          imageKey
          status
        }
        nextToken
      }
      eventLogos {
        items {
          id
          employerId
          name
          imageKey
          status
        }
        nextToken
      }
      accountSubscription {
        id
        accountTierID
        accountTier {
          id
          tierName
          lifeCycle
          maxMarkets
          maxChannels
          maxBrands
          priceMonthlyGBP
          priceYearlyGBP
        }
        discount
        paymentCurrency
      }
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $emailAddress: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      emailAddress: $emailAddress
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        emailAddress
        firstName
        lastName
        userDetail {
          avatar {
            id
            name
            imageKey
          }
          location
        }
      }
      nextToken
    }
  }
`;

export const eventPriceByEvent = /* GraphQL */ `
  query EventPriceByEvent(
    $eventId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEventPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventPriceByEvent(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        title
        description
      }
    }
  }
`;

export const eventByCode = /* GraphQL */ `
  query EventByCode(
    $code: String
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        code
        targetAudience
        isExternal
        externalType
        eventType
        eventMode
        accessLevel
        status
        possibleActions
        providerId
        instructorId
        preRequisites
        proofOfIdentity
        onlineURL
        maximumAttendees
        startDateTime
        endDateTime
        cpdHours
        attendanceHours
        latitude
        longitude
        eventValidityDateTime
        certificatesExpire
        certificateExpiryDateTime
        accreditationLogoId
        signatureId
        eventLogoId
        certificateTemplateId
        certificateCustomisation {
          providerLogoDimensionsHorizontal
          providerLogoDimensionsVertical
          eventLogoDimensionsHorizontal
          eventLogoDimensionsVertical
          signatureDimensionsHorizontal
          signatureDimensionsVertical
          colorBackground
          colorFontPrimary
          colorFontSecondary
          useCertificateSignerName
          certificateSignerName
        }
      }
      nextToken
    }
  }
`;

export const eventBookingByUserId = /* GraphQL */ `
query EventBookingByUserId(
  $userId: ID
  $eventId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEventBookingFilterInput
  $limit: Int
  $nextToken: String
) {
  eventBookingByUserId(
    userId: $userId
    eventId: $eventId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      seatNumber
      attendance
      certificateSent
      certificateSaved
      bookingDateTime
      createdAt
      updatedAt
      event {
        id
        title
        description
        startDateTime
        endDateTime
        cpdHours
        attendanceHours
        code
        targetAudience
        isExternal
        externalType
        eventType
        eventMode
        accessLevel
        status
        eventLogo {
          id
          name
          imageKey
          status
        }
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
        }
        onlineURL
        provider {
          id
          name
          logo {
            id
            name
            imageKey
          }
          description
          address {
            line1
            line2
            line3
            city
            region
            postalCode
            country
          }
          contactDetails {
            emailBookings
            phoneBookings
          }
          courseProviderDetails {
            description
            whyChooseUs
            websiteURL
            registrationNumber
          }
        }
        accreditationLogo {
          id
          name
          imageKey
          status
        }
        certificateTemplate {
          id
          name
          imageKey
          fileKey
        }
        certificateCustomisation {
          colorBackground
          colorFontPrimary
          colorFontSecondary
          useCertificateSignerName
          certificateSignerName
        }
        signatureId
        signature {
          id
          name
          imageKey
          status
        }
        instructor {
          id
          firstName
          lastName
        }
      }
    }
    nextToken
  }
}
`;
export const listEmployers = /* GraphQL */ `
  query ListEmployers(
    $filter: ModelEmployerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo {
          id
          name
          imageKey
        }
      }
      nextToken
    }
  }
`;
export const getEmployerForAdmin = /* GraphQL */ `
  query GetEmployer($id: ID!) {
    getEmployer(id: $id) {
      id
      name
      description
      logo {
        id
        name
        imageKey
      }
      isCourseProvider
      courseProviderDetails {
        description
        whyChooseUs
      }
      accreditationLogos {
        items {
          id
          name
          imageKey
          status
        }
        nextToken
      }
    }
  }
`;
export const getEventAttendeesCount = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      attendees {
        items {
          id
          userId
          status
          attendance
          certificateSent
          certificateSaved
        }
        nextToken
      }
      attendeeStatistics {
        booked
        onWaitingList
        attended
        noShow
      }
    }
  }
`;
export const getEmployeesAndTrainingRecords = /* GraphQL */ `
  query GetEmployer($id: ID!) {
    getEmployer(id: $id) {
      teamMembers {
        items {
          id
          employerGroups {
            items {
              id
              group {
                id
                name
              }
            }
            nextToken
          }
          user {
            id
            identityId
            firstName
            lastName
            emailAddress
            bookings {
              items {
                id
                status
                attendance
                certificateSent
                certificateSaved
                event {
                  id
                  title
                  description
                  startDateTime
                  endDateTime
                  attendanceHours
                  certificatesExpire
                  certificateExpiryDateTime
                  provider {
                    name
                  }
                }
              }
              nextToken
            }
            uploadedEventRecords {
              items {
                id
                title
                description
                eventDateTime
                expiryDateTime
                fileName
                providerName
                cpdHours
              }
              nextToken
            }
            experienceRecords {
              items {
                id
                title
                description
                issueDateTime
                startDateTime
                endDateTime
                experienceEventId
                provider {
                  name
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const getStatisticsByDateAndCountry = /* GraphQL */ `
  query GetStatisticsByDateAndCountry(
    $dateTime: String
    $countryCodeStatisticName: ModelSystemStatisticGetStatisticsByDateAndCountryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSystemStatisticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStatisticsByDateAndCountry(
      dateTime: $dateTime
      countryCodeStatisticName: $countryCodeStatisticName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dateTime
        statisticName
        countryCode
        periodDelta
        allTimeTotal
        additionalData
      }
      nextToken
    }
  }
`;

export const experienceRecordByProviderId = /* GraphQL */ `
  query ExperienceRecordByProviderId(
    $providerId: ID
    $issueDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExperienceRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    experienceRecordByProviderId(
      providerId: $providerId
      issueDateTime: $issueDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        issueDateTime
        startDateTime
        endDateTime
        providerId
        provider {
          name
        }
        userId
        experienceEventId
        certificateTemplateId
        nameProvided
        user {
          id
          identityId
          emailAddress
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const getEmployerGroups = /* GraphQL */ `
query GetEmployer($id: ID!) {
  getEmployer(id: $id) {
    employerGroups {
      items {
        id
        name
        description
        groupMembers {
          items {
            id
            employee {
              id
              user {
                id
                firstName
                lastName
                userDetail {
                  avatar {
                    id
                    name
                    imageKey
                  }
                }
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;

export const groupAllocationsByGroup = /* GraphQL */ `
  query GroupAllocationsByGroup(
    $groupId: ID
    $employeeId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeGroupAllocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupAllocationsByGroup(
      groupId: $groupId
      employeeId: $employeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
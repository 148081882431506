
import { API, Storage, graphqlOperation } from 'aws-amplify';
import * as Mutations from '../graphql/mutations';
import * as MutationsCustom from '../graphql/mutationsCustom';
import { v4 as uuidv4 } from 'uuid';
import { IMAGE_TYPES, IMAGE_STATUS } from '../constants/ImageConstants'

class ImageService {

  constructor(store) {
    this.$store = store
  }

  /**
   * GetEventLogoSignedURL takes two parameters (item location in S3 and the access level)
   * and returns a signed URL to access the image
   * @param  {string} imageKey The S3 key of the file you want a signed URL for
   * @param  {string} level OPTIONAL: The access level of the file (public, protected, private).  Default public.
   * @return {string}      The signed URL to access the file
   */
  async GetEventLogoSignedURL(imageKey, level) {

    //console.log(`imageKey: ${imageKey}; level: ${level}`)

    // Check that the logo isn't blank
    if (imageKey === '' || imageKey === 'undefined' || !imageKey) {
      return process.env.VUE_APP_placeholder_event_logo_url
    }
    // Build the parameters for the signed URL
    var parameters = {
      expires: 3600,
      level
    }
    // Fetch the link
    //console.log("imageKey, parameters:", imageKey, parameters)
    const result = await Storage.get(imageKey, parameters);
    //console.log(result)
    return result
  }

  /**
   * GetSignedURLForFile
   * @param  {string} imageType The type of image being requested
   * @param  {string} imageKey  The S3 key of the file you want a signed URL for
   * @param  {string} level     The access level of the file (public, protected, private).  Default public.
   * @return {string}           The signed URL to access the file
   */
  async GetSignedURLForFile(imageType, imageKey, level) {
    // Check that the logo isn't blank
    if (imageKey === '' || imageKey === 'undefined' || !imageKey) {
      return process.env.VUE_APP_placeholder_avatar_url
    }
    // Build the parameters for the signed URL
    var parameters = {
      expires: 3600,
      level
    }
    if (imageType === IMAGE_TYPES.AVATAR) {
      const splitAvatarKey = imageKey.split('/')
      parameters.level = splitAvatarKey[0]
      parameters.identityId = splitAvatarKey[1]
      return await Storage.get(splitAvatarKey.slice(2), parameters);
    }
    // Fetch the link=
    const result = await Storage.get(imageKey, parameters);
    //console.log(result)
    return result
  }


  /**
   * UploadImage uploads an image to S3 and returns the unique key
   * @param  {object} file The file object from the file picker
   * @param  {string} desiredName The image name/mouseover text
   * @param  {string} imageType The image type from the IMAGE_TYPES constant
   * @param  {string} progressCallback OPTIONAL: A callback to inform the calling process of the progress of the upload
   * @return {string} The unique key of the uploaded file
  */
  async UploadImage(file, name, imageType, progressCallback) {
    // Ensure the file is a real file
    if (!file || !file.name) {
      throw "File was not a file object"
    }
    // Generate a unique name for the file
    const newId = uuidv4()
    const fileUploadKey = `${newId}`
    // Select the upload storage level depending on image type
    const storageLevel = imageType === IMAGE_TYPES.AVATAR ? 'protected' : 'public'
    // Build the uploadParameters
    var uploadParameters = {
      level: storageLevel,
      contentType: file.type,
    }
    // Add in the progressCallback if required
    if (progressCallback) {
      uploadParameters.progressCallback = progressCallback
    }
    // Send the file to storage
    const uploadResult = await Storage.put(fileUploadKey, file, uploadParameters);
    console.log(uploadResult)
    // Build the image details for the database
    var databaseParameters = {
      id: newId,
      name: name,
      imageKey: fileUploadKey
    }
    console.log(databaseParameters)
    const employerId = await this.$store.getters.employer.id

    // Add extra details for uploading to the database for eventLogo
    if (imageType === IMAGE_TYPES.EVENT_LOGO) {
      databaseParameters.employerId = employerId,
      databaseParameters.groupsCanRead = ["USER"]
      databaseParameters.groupsCanUpdate = []
      databaseParameters.groupsFullControl = [`${employerId}`]
      const mutationParameters = {
        input: databaseParameters
      }
      const response = await API.graphql(graphqlOperation(Mutations.createEventLogo, mutationParameters));
      console.log(response)
      return
    }

    // Add extra details for uploading to the database for accreditationLogo
    if (imageType === IMAGE_TYPES.ACCREDITATION_LOGO) {
      databaseParameters.employerId = employerId,
      databaseParameters.groupsCanRead = ["USER"]
      databaseParameters.groupsCanUpdate = []
      databaseParameters.groupsFullControl = [`${employerId}`]
      const mutationParameters = {
        input: databaseParameters
      }
      const response = await API.graphql(graphqlOperation(Mutations.createAccreditationLogo, mutationParameters));
      console.log(response)
      return
    }

    // TODO: FUTURE: Add extra details for uploading to the database for providerLogo
    if (imageType === IMAGE_TYPES.EMPLOYER_LOGO) {
      console.warn("Employer logos aren't programmed yet")
      return
    }

    // TODO: FUTURE: Add extra details for uploading to the database for avatar
    if (imageType === IMAGE_TYPES.AVATAR) {
      console.warn("Avatars aren't programmed yet")
      return
    }

    // Add extra details for uploading to the database for signatures
    if (imageType === IMAGE_TYPES.CERTIFICATE_SIGNATURE) {
      databaseParameters.employerId = employerId
      databaseParameters.groupsCanRead = ["USER"]
      databaseParameters.groupsCanUpdate = [`${employerId}`]
      databaseParameters.groupsFullControl = []
      const mutationParameters = {
        input: databaseParameters
      }
      const response = await API.graphql(graphqlOperation(Mutations.createSignatures, mutationParameters));
      console.log(response)
      return
    }

    // return the image's Id
    return newId
  }

  async MakeImageHidden(imageId, imageType) {
    const parameters = {
      input: {
        id: imageId,
        status: IMAGE_STATUS.HIDDEN
      }
    }
    var result;
    if (imageType === IMAGE_TYPES.EVENT_LOGO) {
      result = await API.graphql(graphqlOperation(MutationsCustom.updateEventLogo, parameters));
    }
    if (imageType === IMAGE_TYPES.ACCREDITATION_LOGO) {
      result = await API.graphql(graphqlOperation(MutationsCustom.updateAccreditationLogo, parameters));
    }
    if (imageType === IMAGE_TYPES.CERTIFICATE_SIGNATURE) {
      result = await API.graphql(graphqlOperation(MutationsCustom.updateSignatures, parameters));
    }
    console.log("Making image hidden result:", result)
    return
  }

  async MakeImageVisible(imageId, imageType) {
    const parameters = {
      input: {
        id: imageId,
        status: IMAGE_STATUS.LIVE
      }
    }
    var result;
    if (imageType === IMAGE_TYPES.EVENT_LOGO) {
      result = await API.graphql(graphqlOperation(MutationsCustom.updateEventLogo, parameters));
    }
    if (imageType === IMAGE_TYPES.ACCREDITATION_LOGO) {
      result = await API.graphql(graphqlOperation(MutationsCustom.updateAccreditationLogo, parameters));
    }
    if (imageType === IMAGE_TYPES.CERTIFICATE_SIGNATURE) {
      result = await API.graphql(graphqlOperation(MutationsCustom.updateSignatures, parameters));
    }
    console.log("Making image live result:", result)
    return
  }

}

export default ImageService

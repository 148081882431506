
//import jsPDF from 'jspdf'
import { API, Storage } from 'aws-amplify';
import * as Queries from '../graphql/queries';

import { EVENT_MODE } from '../constants/EventConstants'
import { ReplaceStringsInTemplate } from '../helpers/certificate.helper'
import { FormatDuration, DESIRED_DURATIONS } from '../helpers/duration.helper'
import { FormatAddressObjectToString, EventDurationInMinutes } from '../helpers/event.helper'


class CertificateService {

  constructor(store) {
    this.$store = store
  }

  // Get all the certificates available to this user
  async ListCertificates() {
    //console.log("Fetching list of certificates")
    try {
      const response = await API.graphql({
        query: Queries.listCertificateTemplates
      });
      const result = response.data.listCertificateTemplates.items
      //console.log("certs before filter:", result)
      // TODO: Remove this filtering once the listCertificateTemplates API endpoint is not returning all items
      const currentEmployerId = this.$store.getters.employer.id
      let returnResult = result.filter((template) => {
        for (const group of template.groupsCanRead) {
          if (group === "USER") { return true }
          if (group === currentEmployerId) { return true }
        }
        return false
      })
      return returnResult
    } catch (e) {
      console.warn("Error getting certificates list:", e)
      // If some data was recovered, save it anyway
      if (e.data) {
        const result = e.data.listCertificateTemplates.items
        return result
      }
      return []
    }
  }

  /**
  * CheckCertificateExists checks that a certificate exists on the S3 bucket
  * @param  {string} eventId The eventId that this certificate is for
  * @param  {string} userId The userId that this certificate is for
  * @param  {string} userIdentityId The identity ID that the user who was issued the certificate has
  * @return {boolean}
  */
  async CheckCertificateExists(eventId, userId, userIdentityId) {
    try {
      const key = `${eventId}_${userId}.pdf`
      const result = await Storage.list(key, {
        pageSize: 1,
        level: 'protected',
        identityId: userIdentityId // the identityId of that user
      })
      console.log("result:", result)
      return result.results.length > 0
    } catch (err) {
      console.warn("Error checking for file existence;", err)
      return false
    }
  }


  /**
  * CreateCertificate creates a certificate PDF and shows it to the user in a separate tab
  * @param  {object} booking An oject of the booking that the user made
  * @param  {object} event An oject of the event that the user attended
  * @param  {object} attendee An object containing the details of the user who attended the event
  * @return {null}
  */
  async CreateCertificate(booking, event, attendee) {
    //console.log("CertificateService.CreateCertificate")
    //console.log("booking:", booking)
    //console.log("event:", event)

    // Fetch the certificate file from S3
    const { fileKey } = event.certificateTemplate
    var parameters = {
      download: true,
    }
    // Fetch the certificate file signed URL from S3
    const fileKeyResult = await Storage.get(fileKey, parameters);
    const certificateHTML = await fileKeyResult.Body.text().then(bodyResult => {
      // handle the String data return String
      return bodyResult
    });

    // Extract all the values from the event and booking to populate the certificate
    const { title, eventMode, address, startDateTime, instructor, provider, signature, accreditationLogo } = event
    const { colorBackground, colorFontPrimary, colorFontSecondary } = event.certificateCustomisation
    const { firstName, lastName } = attendee
    const { registrationNumber, websiteURL } = event.provider.courseProviderDetails

    const eventLocation = eventMode === EVENT_MODE.INPERSON ? FormatAddressObjectToString(address) : "Online"
    const providerLocation = FormatAddressObjectToString(provider.address)
    const eventDate = (new Date(startDateTime)).toDateString()
    const duration = FormatDuration(EventDurationInMinutes(event), DESIRED_DURATIONS.HoursMinutes)
    const instructorName = `${instructor.firstName} ${instructor.lastName}`

    // Fetch the logos
    const [providerLogoSignedURL, certificateSignatureSignedURL, accreditationLogoSignedURL] = await Promise.all([
      Storage.get(provider.logo.imageKey),
      Storage.get(signature.imageKey),
      Storage.get(accreditationLogo.imageKey)
    ])

    // Replace all the fields in the template
    const replaceDict = {
      BackgroundColor: colorBackground,
      FontColorPrimary: colorFontPrimary,
      FontColorSecondary: colorFontSecondary,
      ProviderLogoURL: providerLogoSignedURL,
      FirstName: firstName,
      LastName: lastName,
      EventTitle: title,
      EventLocation: eventLocation,
      EventDate: eventDate,
      EventDurationFormatted: duration,
      CertificateSignatureURL: certificateSignatureSignedURL,
      CertificateId: booking.id,
      InstructorName: instructorName,
      AccreditationLogoURL: accreditationLogoSignedURL,
      ProviderName: provider.name,
      ProviderAddress: providerLocation,
      ProviderWebSite: websiteURL,
      ProviderRegistrationNumber: registrationNumber
    }
    const populatedHTML = ReplaceStringsInTemplate(certificateHTML, replaceDict)

    // Create a PDF from the HTML
    let mywindow = window.open('', 'PRINT', 'height=700,width=500,top=100,left=150');
    mywindow.document.write(populatedHTML);
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
  }


  /**
  * @function
  * @name GetCertificateSignedURL returns a signed URL that points to a specified certificate
  * @param  {string} eventId The eventId that this certificate is for
  * @param  {string} userId The userId that this certificate is for
  * @param  {string} userIdentityId The identity ID that the user who was issued the certificate has
  * @return {string}
  */
  async GetCertificateSignedURL(eventId, userId, userIdentityId) {
    //console.log("GetCertificateSignedURL:", eventId, userId, userIdentityId)
    // Build the parameters for the signed URL
    const key = `${eventId}_${userId}.pdf`
    var parameters = {
      expires: 900,
      level: 'protected'
    }
    if (userIdentityId) { parameters.identityId = userIdentityId }
    // Fetch the link
    //console.log("GetCertificateSignedURL key:", key)
    //console.log("GetCertificateSignedURL parameters:", parameters)
    const result = await Storage.get(key, parameters);
    return result
  }


  /**
  * @function
  * @name GetUploadedCertificateSignedURL returns a signed URL that points to a specified certificate that the user uploaded
  * @param  {string} fileName The fileName that this certificate is stored under
  * @param  {string} userIdentityId The identity ID that the user who was issued the certificate has
  * @return {string}
  */
  async GetUploadedCertificateSignedURL(fileName, userIdentityId) {
    // Build the parameters for the signed URL
    const key = `${fileName}`
    var parameters = {
      expires: 900,
      level: 'protected'
    }
    if (userIdentityId) { parameters.identityId = userIdentityId }
    // Fetch the link
    //console.log("GetCertificateSignedURL key:", key)
    //console.log("GetCertificateSignedURL parameters:", parameters)
    const result = await Storage.get(key, parameters);
    return result
  }


}

export default CertificateService


export const IMAGE_TYPES = {
  AVATAR: "AVATAR",
  EVENT_LOGO: "EVENT_LOGO",
  ACCREDITATION_LOGO: "ACCREDITATION_LOGO",
  EMPLOYER_LOGO: "EMPLOYER_LOGO",
  CERTIFICATE_SIGNATURE: "CERTIFICATE_SIGNATURE"
}

export const IMAGE_STATUS = {
  LIVE: "LIVE",
  HIDDEN: "HIDDEN",
  DELETED: "DELETED"
}

<template>
  <v-card>
    <v-card-title>
      <div class="headline" style="color:rgba(254,138,125,1);">
      {{ isNew ? "Create Employer Group" : "Edit Employer Group" }}
      </div>
    </v-card-title>
    <v-divider style="border-width:1px;"></v-divider>


    <v-layout layout user-returned-details>

      <!-- Details Container -->
      <div class="details-container">
        <!-- Group Name Row -->
        <label class="control-label single-line" for="roleName">Group Name</label>
        <input type="text" class="form-control" id="roleName" v-model="currentGroup.name">
      </div>

    </v-layout>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small color=rgb(0,171,173) @click="CloseEditInstructorDialog">Cancel</v-btn>
      <v-btn color="rgb(0,171,173)" v-if="!isNew" small @click="UpdateGroup" class='add-button'>Save Changes</v-btn>
      <v-btn color="rgb(0,171,173)" v-if="isNew" small @click="CreateGroup" class='add-button'>Create Group</v-btn>
    </v-card-actions>

    <loading :active.sync="isProcessing" :is-full-page="true" loader="dots"></loading>

  </v-card>
</template>


<script>

  import EmployerService from '../services/employer.service'
  import EmployerGroup from '../models/employergroup.model'


  export default {

    props: {
      closePopover: Function,
      groups: Array,
      groupToEdit: {
        type: Object,
        default: () => { return null }
      },
    },

    data() {
      return {
        isProcessing: false,
        errorWhenProcessing: false,
        currentGroup: new EmployerGroup(),
      }
    },


    methods: {
      CloseEditInstructorDialog() {
        this.closePopover(false)
      },

      async CreateGroup() {
        //console.log("Creating group with details:", this.currentGroup.getDatabaseEntry())
        this.isProcessing = true;
        this.errorWhenProcessing = false
        try {
          const employerService = new EmployerService(this.$store)
          await employerService.CreateEmployerGroup(this.currentGroup)
          this.currentGroup = new EmployerGroup()
          this.closePopover(true)
        } catch (err) {
          console.warn("Error creating employer group:", err)
          this.errorWhenProcessing = true
        }
        this.isProcessing = false
      },

      async UpdateGroup() {
        //console.log("Updating group with details:", this.currentGroup.getDatabaseEntry())
        this.isProcessing = true;
        this.errorWhenProcessing = false
        try {
          const employerService = new EmployerService(this.$store)
          await employerService.UpdateEmployerGroup(this.currentGroup)
          this.currentGroup = new EmployerGroup()
          this.closePopover(true)
        } catch (err) {
          console.warn("Error updating employer group:", err)
          this.errorWhenProcessing = true
        }
        this.isProcessing = false
      },
    },

    computed: {
      isNew() {
        return this.groupToEdit === null
      },
      groupNameAlreadyExists() {
        return this.groups.find(group => group.name.toLowerCase() === this.currentGroup.name.toLowerCase())
      }
    },

    watch: {
      groupToEdit(newValue) {
        if (newValue) {
          this.currentGroup = new EmployerGroup(newValue)
        }
      }
    },

    mounted() {
      if (!this.isNew) {
        this.currentGroup = new EmployerGroup(this.groupToEdit)
      }
    }

  }
</script>




<style scoped>
.layout {
  display: block;
  margin-bottom: 2em;
  max-width: 90%;
  margin: auto;
}

.horizontal-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.single-line {
  white-space: nowrap;
}

label {
  padding-right: 1em;
}

.user-returned-details {
  margin-top: 2em;
}

.user-details-row {
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
  grid-gap: 2em;
}

.user-details-row p:last-of-type {
  margin-bottom: 0;
}

.add-button {
  margin-left: auto;
  margin-right: 0;
}

.course-instructor-details,
.details-container {
  display: grid;
  grid-gap: 0.5em 1em;
  grid-template-columns: 1fr 4fr;
  margin: 0;
  margin-bottom: 2em;
  align-items: center;
}
</style>

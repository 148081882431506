
<template>
  <v-layout flex justify-center align-content-center class="confirm-user-screen">

    <loading :active.sync="confirmationInProgress" :is-full-page="true" loader="dots"></loading>

    <div v-if="confirmationInProgress" class="info-text">
      Confirming email address; please wait...
    </div>

    <div v-if="errorConfirmingEmail" class="error-text">
      <img :src="logoImage" class="logo" style="margin-top: 0px;"/>
      <p>
        There was an error confirming your registration.  Please close this tab and click the link again.
      </p>
      <p>
        <strong>Error Message:</strong>
        {{ errorMessage }}
      </p>
    </div>

  </v-layout>
</template>

<script>
  import AuthService from '../services/auth.service'
  //import GetObjectFromParameters from '../helpers/parameters.helper'

  export default {
    name: 'ConfirmingEmail',
    data() {
      return {
        confirmationInProgress: true,
        errorConfirmingEmail: false,
        errorMessage: ''
      }
    },

    methods: {
      GoToLoginPage() {
        // Ask the router to navigate to the user landing page
        this.$router.push({ name: 'Login' })
      },
      parseSearch() {
        try {
          const search = location.href.split('?')[1]
          let jsonResult
          if (search.includes("%26")) {
            // Use only this parsing after 16th April 2022
            let fixedSearch = search.replace(/%26/g, '","')
            fixedSearch = decodeURIComponent(fixedSearch)
            fixedSearch = '{"' + fixedSearch + '"}'
            fixedSearch = fixedSearch.replace(/=/g,'":"')
            jsonResult = JSON.parse(fixedSearch, function(key, value) { return key===""?value:decodeURIComponent(value) })
          } else {
            // This is to help with codes requested before 15th March 2022.  Remove on 16th April 2022
            let fixedSearch =  search.replace(/&user_/g, '","user_')
            fixedSearch = fixedSearch.replace(/&confirmation_/g, '","confirmation_')
            fixedSearch = fixedSearch.replace(/&email_/g, '","email_')
            fixedSearch = fixedSearch.replace(/=/g, '":"')
            jsonResult = JSON.parse('{"' + fixedSearch + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
            // If the string ended in an &, it was removed by the browser; replace it.
            if (jsonResult.confirmation_code.length === 7) {
              jsonResult.confirmation_code = `${jsonResult.confirmation_code}&`
            }
          }
          return jsonResult
        } catch (err) {
          this.errorConfirmingEmail = true
          this.confirmationInProgress = false
          this.errorMessage = "The incorrect arguments were provided to this page.  Did you click the correct link to get here?"
          return null
        }
      }
    },

    computed: {
      logoImage: function() {
        let imageName = "logo"
        if (this.isFISHost) { imageName = "logo-fis" }
        return require(`@/assets/images/${imageName}.png`)
      }
    },

    async mounted() {
      this.errorConfirmingEmail = false
      this.confirmationInProgress = true
      const jsonResult = this.parseSearch()
      console.log("jsonResult:", jsonResult)
      if (!jsonResult) { return }
      try {
        const result = await AuthService.ConfirmSignUp(jsonResult.user_name, jsonResult.confirmation_code)
        if (result) {
          this.GoToLoginPage()
        }
      } catch (err) {
        console.warn("Error confirming sign up;", err)
        this.errorConfirmingEmail = true
        this.confirmationInProgress = false
        this.errorMessage = err.message
      }
    }
  }
</script>


<style scoped>

  .confirm-user-screen {
    color: white;
  }

  .info-text {
    padding-top: 15vh;
    font-weight: bold;
    font-size: 1.5em;
    max-width: 80vw;
  }

  .error-text {
    max-width: 80vw;
    background-color: white;
    color: black;
    padding: 2em;
  }

</style>

<template>
  <v-app>
    <loading :active.sync="isLoading" :is-full-page="true" loader="dots"></loading>
    <v-container v-if="is_data_fetched" container pa-0 ma-0 fluid>
      <v-img :src="require('@/images/contactdetailsbackground.png')" style="height:150px;" class="container-rounded-subheader">
        <v-layout>
          <v-flex xs12>
            <v-layout style="position:relative;">
              <v-flex x12>
                <v-layout row wrap>
                  <v-flex>
                    <table style="width:97%;">
                      <tr>
                        <td style="width:10%">
                          <v-img v-bind:src="avatarUrl" class="contact-details-logo">
                          </v-img>
                        </td>
                        <td>
                          <div style="position:relative;margin-left:5px;vertical-align:bottom;bottom:-60px;">
                            <div style="font-size: 16px;">{{ currentFirstName }} {{ currentLastName }}</div>
                            <div style="position:relative;font-weight:normal;">{{ currentJobTitle }} - {{ employerName }}</div>
                            <div style="max-height:5px;max-width:5px">
                              <v-icon style="float:left;" @click="$refs.avatarUpload.click()">photo_camera</v-icon>
                              <input style="float:left;" v-show="false" ref="avatarUpload" type="file" accept="image/png, image/gif, image/jpeg" @change="uploadAvatar">
                            </div>
                            <div style="font-size:9px;font-weight:normal;float:left;margin-top:5px;">Change profile picture</div>
                          </div>
                        </td>
                        <td>
                          <div class="personal-id-container">
                            <div class="personal-id"><strong>Personal ID: </strong>{{ id }}</div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-img>
      <v-card class="container-rounded-mini">
        <v-layout row wrap>
          <v-flex xs10 md10>

            <v-tabs v-model="selectedTab" slider-color="cyan">
              <v-tab :key="TAB_NAMES.personalDetails.key" :href="TAB_NAMES.personalDetails.href">
                <b style="text-transform: capitalize;font-size: 16px">Personal Details</b>
              </v-tab>
              <v-spacer></v-spacer>
              <v-tab :key="TAB_NAMES.employersAndInvites.key" :href="TAB_NAMES.employersAndInvites.href">
                <b style="text-transform: capitalize;font-size: 16px">Employer Management</b>
              </v-tab>
              <v-spacer></v-spacer>
            </v-tabs>


          </v-flex>
        </v-layout>
      </v-card>


      <v-layout>
        <v-flex>
          <v-tabs-items v-model="selectedTab">

            <!-- Start Personal Details Tab -->
            <v-tab-item value="tab-personaldetails" key="personaldetails">
              <v-card class="container-rounded">
                <v-layout row wrap centered>
                  <v-flex xs12 md6>
                    <form class="form-horizontal">
                      <div>
                        <br />
                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="firstName">First Name</label>
                          <input type="text" class="form-control col-sm-6" id="firstName" name="firstName" v-model="firstName" />
                          <div v-if="submitted && $v.firstName.$error" class="text-danger">
                            <small v-if="!$v.firstName.required">First Name is required</small>
                            <small v-if="!$v.firstName.minLength">First Name should be more than 1 characters.</small>
                            <small v-if="!$v.firstName.maxLength">First Name should be less than 100 characters.</small>
                          </div>
                        </div>
                        <br />
                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="lastName">Last Name</label>
                          <input type="text" class="form-control col-sm-6" id="lastName" name="lastName" v-model="lastName" />
                          <div v-if="submitted && $v.lastName.$error" class="text-danger">
                            <small v-if="!$v.lastName.required">Last Name is required</small>
                            <small v-if="!$v.lastName.minLength">Last Name should be more than 1 characters.</small>
                            <small v-if="!$v.lastName.maxLength">Last Name should be less than 100 characters.</small>
                          </div>
                        </div>
                        <br />
                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="email">Email</label>
                          <input type="email" class="form-control col-sm-6" id="email" name="email" v-model="emailAddress" readonly />
                        </div>
                        <br />
                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="phoneNumber">Phone Number</label>
                          <input type="text" class="form-control col-sm-6" id="phoneNumber" name="phoneNumber" v-model="phoneNumber" />
                        </div>
                        <br />

                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-4 control-label">DOB</label>

                          <v-menu v-model="showDateOfBirthPicker" lazy :close-on-content-click="false" offset-y>
                            <template style="width:100px" v-slot:activator="{ on }">
                              <v-text-field v-model="formattedDateOfBirth" prepend-icon="event" class="col-sm-6 ml-0 pl-0 pr-0 form-control remove-border dateFix" v-on="on" hide-details />
                            </template>
                            <v-date-picker dark class="mt-4" color="green lighten-1" event-color="green" header-color="primary" v-model="dateOfBirth" @input="showDateOfBirthPicker === false"></v-date-picker>
                          </v-menu>

                        </div>
                        <br />
                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="location">Location</label>
                          <input type="text" class="form-control col-sm-6" id="location" name="Location" v-model="user.userDetail.location">
                          <div v-if="submitted && $v.location.$error" class="text-danger">
                            <small v-if="!$v.location.required">Location is required</small>
                            <small v-if="!$v.location.maxLength">Location should be less than 500 characters.</small>
                          </div>
                        </div>
                        <br />
                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="jobTitle">Job Title</label>
                          <input type="text" class="form-control col-sm-6" id="jobTitle" name="jobTitle" v-model="jobTitle">
                          <div v-if="submitted && $v.jobTitle.$error" class="text-danger">
                            <small v-if="!$v.jobTitle.required">Job title is required</small>
                            <small v-if="!$v.jobTitle.maxLength">Job title should be less than 500 characters.</small>
                          </div>
                        </div>
                        <br />

                        <div class="form-row" style="font-weight:normal;">
                          <label class="col-sm-4 control-label" for="employerName">Employer Name</label>
                          <input type="text" class="form-control col-sm-6" id="employerName" name="employerName" v-model="employerName" readonly />
                        </div>
                        <br />
                      </div>
                    </form>
                  </v-flex>
                  <v-flex xs12 style="text-align:end;">
                    <div v-if="submitted && error_saving_details" class="text-danger">
                      <small>Please review your input. Errors are highlighted in red.</small>
                    </div>
                    <v-btn color="rgb(0,171,173)" class="mr-0" @click="savePersonalDetails" :disabled='SaveButtonDisabled'>
                      Save
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-tab-item>
            <!-- End Personal Details Tab -->

            <!-- Start Invitations Tab -->
            <v-tab-item value="tab-employersandinvites" key="employersandinvites">
              <v-card class="container-rounded pt-0 pb-2 pl-2 pr-2">
                <v-card-title class="pb-2">
                  <div class="page-header">Employers</div>
                </v-card-title>

                <!-- Cards for each existing employment -->
                <v-card v-for="(employeeRole, index) in currentEmployeeRoles" class="container-rounded m-3 pt-0 pb-2 pl-2 pr-2" style='min-height: 0px;' v-bind:key='index'>
                  <v-layout pt-2>
                    <v-flex xs2>
                      <v-container style="padding: 0;">
                        <v-layout ma-0 pa-0 style="height: 200px;">
                          <provider-logo v-bind:imageKey="providerIcon(employeeRole)" level="public" :imageType="IMAGE_TYPES.EMPLOYER_LOGO" imageStyle="background-color: transparent;"/>
                        </v-layout>
                      </v-container>
                    </v-flex>
                    <v-flex xs10 style="margin-left:10px;">
                      <v-flex align-content-start ma-0 pa-0 class='flex-direction: column;'>
                        <div>
                          <b>Organisation Name:</b>
                          {{ ` ${ employeeRole.employer.name }` }}
                        </div>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="OnLeaveEmployment(employeeRole.id, employeeRole.employer.name)" small color=rgb(0,171,173)>Leave Employment</v-btn>
                  </v-card-actions>
                </v-card>

                <div v-if='currentEmployeeRoles.length === 0' class='no-current-employer pl-3'>
                  No current employer
                </div>

              </v-card>


              <v-card v-if='pendingEmployeeInvitations.length > 0' class="container-rounded pt-0 pb-2 pl-2 pr-2">
                <v-card-title class="pb-2">
                  <div class="page-header">Employer Invitations</div>
                </v-card-title>

                <!-- Cards for each invitation -->
                <v-card v-for="(invite, index) in pendingEmployeeInvitations" class="container-rounded m-3 pt-0 pb-2 pl-2 pr-2" style='min-height: 0px;' v-bind:key='index'>
                  <v-layout pt-2>
                    <v-flex xs2>
                      <v-container style="padding: 0;">
                        <v-layout ma-0 pa-0 style="height: 200px;">
                          <provider-logo v-bind:imageKey="inviteProviderIcon(invite)" level="public" :imageType="IMAGE_TYPES.EMPLOYER_LOGO" imageStyle="background-color: transparent;"/>
                        </v-layout>
                      </v-container>
                    </v-flex>
                    <v-flex xs10 style="margin-left:10px;">
                      <v-flex align-content-start ma-0 pa-0 class='flex-direction: column;'>
                        <div>
                          <b>Organisation Name:</b>
                          {{ ` ${ invite.employer.name }` }}
                        </div>
                        <div>
                          <b>Invitation Date and Time:</b>
                          {{ ` ${ (new Date(invite.createdAt)).toLocaleString() }` }}
                        </div>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="OnRejectInvitation(invite)" small style='color: black !important;'>
                      <div style="color: grey;">Reject</div>
                    </v-btn>
                    <v-btn @click="OnAcceptInvitation(invite)" small color=rgb(0,171,173)>Accept</v-btn>
                  </v-card-actions>
                </v-card>

              </v-card>
            </v-tab-item>
            <!-- End Invitations Tab -->

          </v-tabs-items>
        </v-flex>
      </v-layout>


      <v-layout row justify-center>
        <v-dialog persistent v-model="messageDialog.show" transition="dialog-transition" max-width="450">
          <v-card>
            <v-card-text>
              <v-flex>
                <div id="message" style="font-weight:normal;">
                  {{messageDialog.message}}
                </div>
              </v-flex>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="CloseMessageDialog" small color=rgb(0,171,173)>OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog persistent v-model="errorDialogPopover.show" transition="dialog-transition" max-width="500">
          <v-card>
            <v-card-text>
              <v-flex>
                <span style="font-weight:normal;">
                  <div v-html="errorDialogPopover.message"></div>
                </span>
              </v-flex>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="CloseErrorDialog" small color=rgb(0,171,173)>Ok</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog persistent v-model="actionDialog.show" transition="dialog-transition" max-width="350">
          <v-card>
            <v-card-text>
              <v-flex>
                <span style="font-weight:normal;" v-html="actionDialog.message"></span>
              </v-flex>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="CloseActionDialog" small style='color: black !important;'>
                <div style="color: grey;">No</div>
              </v-btn>
              <v-btn @click="PerformActionDialogAction" small color=rgb(0,171,173)>Yes</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-layout>
    </v-container>
  </v-app>
</template>


<script>
  //import ProfessionalService from '../services/professional.service'

  import { validationMixin } from 'vuelidate'
  import { required, maxLength, minLength } from 'vuelidate/lib/validators'

  import UserService from '../services/user.service'
  import { GetLocaleDateFromDateTime } from '../helpers/date.helper'
  import { USER_EVENTS_PAGE_PARAMETERS } from '../constants/PageParameterConstants'
  import { Storage } from 'aws-amplify';
  import EventLogo from './eventlogo-container'
  import { IMAGE_TYPES } from '@/constants/ImageConstants'

  const TAB_NAMES = {
    personalDetails: {
      key: 'personalDetails',
      href: '#tab-personaldetails'
    },
    employersAndInvites: {
      key: 'employersAndInvites',
      href: '#tab-employersandinvites'
    }
  }

  // TODO: FUTURE: Add in reset button

  export default {
    mixins: [validationMixin],
    validations: {
      firstName: { required, maxLength: maxLength(100), minLength: minLength(2) },
      lastName: { required, maxLength: maxLength(100), minLength: minLength(2) },
      location: { maxLength: maxLength(500) },
      //employerName: { required, maxLength: maxLength(500) },
      jobTitle: { maxLength: maxLength(500) },
    },

    components: {
      'provider-logo': EventLogo
    },

    data() {
      return {
        isLoading: false,
        is_data_fetched: false,
        error_saving_details: false,
        showDateOfBirthPicker: false,
        submitted: false,
        selectedTab: 'tab-personaldetails',
        messageDialog: {
          show: false,
          message: '',
          redirect: false
        },
        actionDialog: {
          show: false,
          message: '',
          employeeId: null,
          action: null
        },
        newAvatarFilename: '',
        avatarSignedURL: null,
        errors: {
          firstName: false,
          lastName: false,
          phoneNumber: false,
          dateOfBirth: false,
          location: false,
          jobTitle: false
        },
        user: {
          id: '',
          firstName: '',
          lastName: '',
          userDetail: {
            dateOfBirth: '',
            phoneNumber: '',
            location: '',
            jobTitle: '',
          }
        },
        errorDialogPopover: {
          show: false,
          message: ''
        },
        TAB_NAMES,
        IMAGE_TYPES
      }
    },

    watch: {
      errors: function(newVal, oldVal) {
        console.log(oldVal)
        console.log(newVal)
      }
    },

    methods: {
      setFormValuesToDefault() {
        //console.log("setting form values to default")
        const userGetter = this.$store.getters.user
        this.user = {
          id: userGetter.id,
          identityId: userGetter.identityId,
          firstName: userGetter.firstName,
          lastName: userGetter.lastName,
          userDetail: {
            dateOfBirth: userGetter.userDetail.dateOfBirth,
            phoneNumber: userGetter.userDetail.phoneNumber,
            location: userGetter.userDetail.location,
            jobTitle: userGetter.userDetail.jobTitle
          }
        }
        this.GetAvatar()
      },

      CloseMessageDialog() {
        this.messageDialog.show = false;
        this.messageDialog.message = '';
        if (this.messageDialog.redirect == true) {
          this.messageDialog.redirect = false;
          this.$router.push({ name: 'UserMyEvents', params: { status: USER_EVENTS_PAGE_PARAMETERS.EVENTS } });
        }
      },
      checkForInvalidEntries() {
        // TODO: FUTURE: Improve this form checking
        this.errors.firstName = !this.firstName
        this.errors.lastName = !this.lastName

        const anyErrors = Object.keys(this.errors).filter((key) => {
          return this.errors[key]
        })

        return anyErrors.length > 0
      },


      async savePersonalDetails() {
        //console.log("saving personal details")
        //console.log(this.user)
        this.submitted = true;
        this.isLoading = true
        this.error_saving_details = false

        this.$v.$touch();
        if (this.$v.$invalid) {
          //console.warn("Not all fields are valid during save event")
          //console.warn(this.$v)
          this.error_saving_details = true
          this.isLoading = false
          return;
        }

        // Check for invalid states
        /*
        if (this.checkForInvalidEntries()) {
          this.error_saving_details = true
          console.warn("form is invalid")
          this.isLoading = false
          return;
        }
        */

        //console.log("sending data to the server")
        // Update the user details in the database
        try {
          const parameters = {
            ...this.user,
            userDetail: {
              ...this.user.userDetail,
              avatar: this.$store.getters.user.userDetail.avatar
            }
          }
          const userService = new UserService(this.$store)
          await userService.UpdateUserDetails(parameters)
          this.setFormValuesToDefault()
          this.isLoading = false
        } catch (err) {
          console.warn("Error when saving user details", err)
          this.error_saving_details = true
          this.isLoading = false
        }
      },
      async uploadAvatar(e) {
        this.isLoading = true
        const avatarName = 'avatar'

        // Ensure that you have a file before attempting to read it
        var input = e.target;
        if (!input.files || !input.files[0]) {
          //console.log("No files present")
          this.isLoading = false
          return
        }

        // Extract the file and it's details
        const file = input.files[0]

        // Check if the image is too large to display.
        const fileSize = file.size
        if (fileSize > (2 * 1024 * 1024)) {
          this.errorDialogPopover.message = `The chosen image is too large (${(fileSize / 1024).toFixed(0)} KB).  The maximum size is 2048 KB (2 MB).  Please make the image smaller and attempt to upload again.`
          this.errorDialogPopover.show = true
          this.isLoading = false
          return;
        }

        try {
          // Upload the file
          await Storage.put(avatarName, file, {
            level: 'protected',
            //contentType: 'image/png'
            progressCallback(progress) { console.log(`Uploaded: ${progress.loaded}/${progress.total}`); } //Remove this if not required.  Debug only
          });
        } catch (error) {
          console.warn('Error uploading file: ', error);
          this.errorDialogPopover.message = `There was an error updating your avatar; please try again.`
          this.errorDialogPopover.show = true
          this.isLoading = false
          return
        }

        // Build the URL from the key and the users identityId
        const avatar = `protected/${this.$store.getters.user.identityId}/${avatarName}`
        // Upload the new avatar URL to the database\
        const parameters = {
          id: this.$store.getters.user.id,
          userDetail: {
            ...this.$store.getters.user.userDetail,
            avatar: {
              id: this.$store.getters.user.id,
              name: avatarName,
              imageKey: avatar
            }
          }
        }
        // Fetch the updated user details from the database
        const userService = new UserService(this.$store)
        await userService.UpdateUserDetails(parameters)
        // Change the displayed avatar
        this.GetAvatar()
      },

      async GetAvatar() {
        try {
          const userService = new UserService(this.$store)
          const avatarSignedURL = await userService.GetUserAvatarSignedURL(this.$store.getters.user)
          this.avatarSignedURL = avatarSignedURL
          this.isLoading = false
        } catch (e) {
          console.log("Error fetching signed avatar", e)
          this.avatarSignedURL = process.env.VUE_APP_placeholder_avatar_url
          this.isLoading = false
        }
      },

      CloseErrorDialog() {
        this.errorDialogPopover.message = ''
        this.errorDialogPopover.show = false
      },
      async OnAcceptInvitation(invite) {
        console.log('accept invite:', invite)
        this.isLoading = true
        try {
          const userService = new UserService(this.$store)
          await userService.AcceptPendingEmployeeInvitation(invite)
          // Delay by one second to allow database to update
          await new Promise(resolve => setTimeout(resolve, 1000));
          await userService.GetDetails(this.$store.getters.user.id)
        } catch (err) {
          console.warn("Error when accepting invitation;", err)
          this.messageDialog.message = "There was an error when accepting the invitation, please try again."
          this.messageDialog.redirect = false
          this.messageDialog.show = true
        }
        this.isLoading = false
      },
      async OnRejectInvitation(invite) {
        console.log('reject invite:', invite)
        this.isLoading = true
        try {
          const userService = new UserService(this.$store)
          await userService.RejectPendingEmployeeInvitation(invite)
          await userService.GetDetails(this.$store.getters.user.id)
        } catch (err) {
          console.warn("Error when rejecting invitation;", err)
          this.messageDialog.message = "There was an error when rejecting the invitation, please try again."
          this.messageDialog.redirect = false
          this.messageDialog.show = true
        }
        this.isLoading = false
      },

      async OnLeaveEmployment(employeeId, employerName) {
        console.log("OnLeaveEmployment; employeeId:", employeeId)
        this.actionDialog.message = `Are you sure you want to leave the employment of ${employerName}`
        this.actionDialog.employeeId = employeeId
        this.actionDialog.show = true
      },

      async PerformActionDialogAction() {
        console.log("leaveEmploymentConfirmed; employeeId:", this.actionDialog.employeeId)
        this.isLoading = true
        this.actionDialog.show = false
        try {
          const userService = new UserService(this.$store)
          await userService.DeleteEmployeeRole(this.actionDialog.employeeId)
          await userService.GetDetails(this.$store.getters.user.id)
          this.actionDialog.employeeId = null
          this.actionDialog.message = ''
        } catch (err) {
          console.warn("Error when deleting employment;", err)
          this.messageDialog.message = "There was an error when deleting your employment, please try again."
          this.messageDialog.redirect = false
          this.messageDialog.show = true
        }
        this.isLoading = false
      },

      CloseActionDialog() {
        this.actionDialog = {
          show: false,
          message: "",
          employeeId: null,
          action: null
        }
      },

      providerIcon(employeeRole) {
        try {
          return employeeRole.employer.logo.imageKey
        } catch (err) {
          return process.env.VUE_APP_placeholder_company_logo_url
        }
      },
      inviteProviderIcon: function(invite) {
        try {
          return invite.employer.logo.imageKey
        } catch (err) {
          return process.env.VUE_APP_placeholder_company_logo_url
        }
      },
    },

    computed: {
      id: function() {
        try { return this.$store.getters.cognito.attributes.sub } catch (e) { return '' }
      },
      currentFirstName: {
        get: function() { try { return this.$store.getters.user.firstName } catch (e) { return '' } },
      },
      firstName: {
        get: function() { try { return this.user.firstName } catch (e) { return '' } },
        set: function(newValue) { this.user.firstName = newValue }
      },
      currentLastName: {
        get: function() { try { return this.$store.getters.user.lastName } catch (e) { return '' } },
      },
      lastName: {
        get: function() { try { return this.user.lastName } catch (e) { return '' } },
        set: function(newValue) { this.user.lastName = newValue }
      },
      avatarUrl: function() {
        try {
          //console.log("this.avatarSignedURL:", this.avatarSignedURL)
          if (this.avatarSignedURL) { return this.avatarSignedURL }
          return process.env.VUE_APP_placeholder_avatar_url
        } catch (e) {
          return process.env.VUE_APP_placeholder_avatar_url
        }
      },
      emailAddress: function() {
        try { return this.$store.getters.user.emailAddress } catch (e) { return '' }
      },
      phoneNumber: {
        get: function() { try { return this.user.userDetail.phoneNumber } catch (e) { return '' } },
        set: function(newValue) { this.user.userDetail.phoneNumber = newValue }
      },
      dateOfBirth: {
        get: function() { try { return this.user.userDetail.dateOfBirth } catch (e) { return '' } },
        set: function(newValue) { this.user.userDetail.dateOfBirth = newValue }
      },
      formattedDateOfBirth: function() {
        try {
          if (!this.dateOfBirth || this.dateOfBirth === '') { return '-- / -- / ----' }
          return GetLocaleDateFromDateTime(this.dateOfBirth)
        } catch (e) { return '' }
      },
      location: {
        get: function() { try { return this.user.userDetail.location } catch (e) { return '' } },
        set: function(newValue) { this.user.userDetail.location = newValue }
      },
      currentJobTitle: {
        get: function() { try { return this.$store.getters.user.userDetail.jobTitle } catch (e) { return '' } },
      },
      jobTitle: {
        get: function() { try { return this.user.userDetail.jobTitle } catch (e) { return '' } },
        set: function(newValue) { this.user.userDetail.jobTitle = newValue }
      },
      employerName: function() {
        // was called organization
        try { return this.$store.getters.employer.name } catch (e) { return "-" }
      },
      pendingEmployeeInvitations: function() {
        try {
          return this.$store.getters.pendingEmployeeRoles
        } catch (err) {
          return []
        }
      },
      currentEmployeeRoles: function() {
        try {
          return this.$store.getters.user.employer.items
        } catch (err) {
          return []
        }
      },
      SaveButtonDisabled: function() {
        try {
          const { user } = this.$store.getters
          if (user.firstName !== this.firstName) { return false }
          if (user.lastName !== this.lastName) { return false }
          if (user.userDetail.phoneNumber !== this.phoneNumber) { return false }
          if (user.userDetail.dateOfBirth !== this.dateOfBirth) { return false }
          if (user.userDetail.location !== this.location) { return false }
          if (user.userDetail.jobTitle !== this.jobTitle) { return false }
          return true
        } catch (err) {
          return true
        }
      }
    },

    async mounted() {
      this.$parent.$parent.$parent.refreshMenu('profile');
      //console.log("Loading user personal details page")
      //console.log(this.$router.history.current.hash)
      // If the user has employer invites, then show the invites tab
      try {
        if (this.pendingEmployeeInvitations.length > 0) {
          this.selectedTab = 'tab-employersandinvites'
        }
      } catch (err) {
        console.warn("Error processing user invites;", err)
        this.selectedTab = 'tab-personaldetails'
      }
      // If the hash has been provided, then that overrides the currently chosen tab
      // TODO: Add some error handling here incase an incorrect hash is provided
      try {
        const { hash } = this.$router.history.current
        if (hash) { this.selectedTab = hash.replace("#", "") }
      } catch (err) {
        console.warn("Error processing hash;", err)
        this.selectedTab = 'tab-personaldetails'
      }
      // If the user details haven't been fetched yet, fetch them
      if (!this.$store.getters.user.id) {
        await UserService.GetDetails(this.$store.getters.cognito.username)
      }

      // Prepare the page
      this.setFormValuesToDefault()
      this.is_data_fetched = true;
    }
  }
</script>
<style scoped>

  .container {
    position: relative;
    padding: 20px 0 30px 0;
  }

  body {
    background-color: rgba(243,246,248,1);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  label {
    color: rgb(0,171,173);
  }

  .container-rounded-subheader {
    padding-left: 50px;
    margin: 40px;
    margin-top: 0px;
    min-height: 40px;
    overflow: visible;
  }

  .container-rounded-mini {
    background-color: #FFFFFF;
    padding-left: 50px;
    margin-left: 40px;
    margin-right: 40px;
    min-height: 40px;
  }

  .container-rounded {
    background-color: #FFFFFF;
    padding-left: 50px;
    margin: 12px 40px;
    min-height: 350px;
    padding-right: 50px;
  }

  .upload-btn {
    max-height: 5px;
  }

  .contact-details-logo {
    border: 2px solid #fafafa;
    position: relative;
    height: 60px;
    width: 60px;
    bottom: -95px;
    background: #fafafa;
  }

  .personal-id-container {
    position: absolute;
    margin-left: 5px;
    vertical-align: bottom;
    bottom: 0;
    width: 100%;
    left: -15px;
  }

  .personal-id {
    float: left;
    font-size: 11px;
    font-weight: normal;
  }

  .theme--light.v-sheet {
    border: solid 1px #b5b5b5;
  }

  .container .v-text-field {
    padding-top: 3px;
  }

  .container .v-input__slot {
    margin-bottom: 0;
  }

  .dateFix {
    display: flex;
    flex: 1 1 auto;
    font-size: 14px;
    padding-top: 0 !important;
    align-items: flex-start;
  }

  .no-current-employer {
    font-size: 16px;
  }

  @media (min-width: 768px) {
    .contact-details-logo {
      height: 100px;
      width: 100px;
      bottom: -75px;
    }

    .personal-id-container {
      position: relative;
      margin-left: 5px;
      vertical-align: bottom;
      bottom: -80px;
    }

    .personal-id {
      float: right;
    }
  }
</style>


import { API, graphqlOperation } from 'aws-amplify';
import * as Mutations from '../graphql/mutations';

class StripeService {

  constructor() {}

  /**
   * createCheckoutSession returns a graphql promise to send a certificate to an attendee
   * NOTE: All booking IDs must be from the same event!!!
   * @param  {string} eventId     The event ID that this payment is for
   * @param  {string} priceId     The price ID that this payment is for
   * @return {string}             The URL that will go to the checkout session
   */
  async createCheckoutSession(eventId, priceId) {
    console.log("Creating checkout session")
    const parameters = {
      eventId,
      priceId,
    }
    //console.log(parameters)
    const result = await API.graphql(graphqlOperation(Mutations.createCheckoutSession, parameters));
    if (parseInt(result.data.createCheckoutSession.statusCode) !== 200) {
      throw result.data.createCheckoutSession.body
    }
    return result.data.createCheckoutSession.body
  }
}

export default StripeService

<template>
  <v-container pa-0 ma-0 fluid>
    <loading :active="!is_data_fetched" :is-full-page="true" loader="dots"></loading>
    <loading :active="is_loading" :is-full-page="true" loader="dots"></loading>
    <v-layout row wrap ma-0 justify-center align-content-center v-if="is_data_fetched">
      <v-flex xs12>
        <v-container fluid grid-list-md>

          <v-layout row>
            <v-flex xs12 md3 offset-md9 class="grid-actions">
              <div class="text-right">
                <router-link :to="{ name: `${ $store.getters.layout === LAYOUTS.ADMIN ? 'AdminCreateNewExperience' : 'ProviderCreateNewExperience' }` }" class="grid-actions-link">
                  <v-icon color="rgb(0,171,173)" style="vertical-align:middle">add_circle</v-icon> &nbsp;Issue Experience
                </router-link>
                <a>
                  <img :src="require('../images/file-download.svg')" @click="DownloadExperience" class="grid-actions-icon" />
                </a>
              </div>
            </v-flex>
          </v-layout>

          <v-layout column pa-2 ma-0 class="grid-container">
            <v-flex md1>
              <v-layout>

                <v-flex xs12 md2 pt-2 pl-2>
                  <h5 class="page-header">Issued Experience</h5>
                </v-flex>

                <v-flex xs12 md3 offset-md7 class="grid-search">
                  <v-text-field
                    v-model="search.text"
                    append-icon="search"
                    label="Search for an issued experience"
                    single-line
                    hide-details />
                </v-flex>

              </v-layout>
            </v-flex>

            <v-flex md11>

              <v-data-table
                :headers="EXPERIENCE_TABLE_HEADERS"
                :items="filteredExperience"
                :search="search.text"
                hide-actions
                :pagination.sync="pagination"
                :sort-by.sync="search.sortBy"
                :sort-desc.sync="search.sortDesc"
                :custom-sort="CustomSort"
                :custom-filter="CustomFilter"
                no-data-text="No experience certificates issued yet"
                disable-initial-sort>

                <template slot="headerCell" slot-scope="{ header }">
                  <span class="table-head font-weight-bold text--darken-3" v-text="header.text" />
                </template>

                <template v-slot:items="props">
                  <td>{{ props.item.title }}</td>
                  <td style="text-align: left">{{ props.item.user.firstName }} {{ props.item.user.lastName }}</td>
                  <td>{{ GetDateFromDateTime(props.item.issueDateTime) }}</td>
                  <td>{{ GetDateFromDateTime(props.item.startDateTime) }}</td>
                  <td>{{ GetDateFromDateTime(props.item.endDateTime) }}</td>
                  <td>{{ GetDurationFromDateTimes(props.item.startDateTime, props.item.endDateTime) }}</td>
                  <td>
                    <a v-if="props.item.user" @click="GetCertificate(props.item)" alt='View Certitificate'><img src="../images/certificate-white.png" alt="Certificate" style="height:25px;width:25px;" /></a>
                    <a v-if="props.item.user" @click="CopyExperienceDetails(props.item)" alt='Copy Details'><img src="../images/copy.png" alt="Certificate" style="height:25px;width:25px;" /></a>
                  </td>
                </template>

              </v-data-table>

              <v-layout>
                <v-flex xs12 text-right>
                  <v-pagination v-model="pagination.page" style="vertical-align:top;" :length="Math.ceil(pagination.totalItems / pagination.rowsPerPage)"></v-pagination>
                </v-flex>
              </v-layout>

            </v-flex>


          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-dialog persistent v-model="messageDialog.show" transition="dialog-transition" max-width="450">
      <v-card>
        <v-card-text>
          <v-flex>
            {{ messageDialog.message }}
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CloseMessageDialog" small color=rgb(0,171,173)>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>

  import { LAYOUTS } from '@/constants/LayoutConstants'
  import ExperienceRecordService from '@/services/experienceRecord.service'
  import ExperienceCertificateService from '@/services/experienceCertificate.service'
  import { GetLocaleDateFromDateTime } from '@/helpers/date.helper'
  import { FormatDuration, DESIRED_DURATIONS } from '@/helpers/duration.helper'
  import { GenerateExperienceCSV } from '@/helpers/experience.helper'


  const EXPERIENCE_TABLE_HEADERS = [
    { text: 'Experience',     align: 'left',      value: 'title',                       },
    { text: 'Issued To',      align: 'left',      value: 'subjectName'                  },
    { text: 'Issue Date',     align: 'center',    value: 'issueDateTime'                },
    { text: 'Start Date',     align: 'center',    value: 'startDateTime'                },
    { text: 'End Date',       align: 'center',    value: 'endDateTime'                  },
    { text: 'Duration',       align: 'center',    value: 'duration'                     },
    { text: 'Action',         align: 'center',    value: 'action',      sortable: false }
  ]


  export default {

    components: {},

    data() {
      return {
        is_data_fetched: false,
        is_loading: false,
        search: {
          text: '',
          sortDesc: true,
          sortBy: 'issueDateTime',
        },
        pagination: {
          rowsPerPage: 10,
          totalItems: 0,
          page: 1,
        },
        messageDialog: {
          show: false,
          message: ''
        },
        LAYOUTS,
        EXPERIENCE_TABLE_HEADERS
      }
    },

    watch: {
      filteredExperience: function(newVal) {
        this.pagination.totalItems = newVal.length
      },
      'pagination.page': function(newValue) {
        //console.log("WATCHER: pagination page value updated", newValue)
        // Check if the user is getting close to the end of the fetched items
        if (newValue + 1 >= Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)) {
          new ExperienceRecordService(this.$store).ExtendExperienceRecordsForEmployee()
        }
      },
    },

    methods: {
      CustomSort(items, index, isDesc) {
        //console.log(items, index, isDesc)
        try {
          const result = items.sort((a, b) => {
            // Title Sort
            if (index === "title") {
              if (!isDesc) { return a[index] < b[index] ? -1 : 1; }
              return b[index] < a[index] ? -1 : 1;
            }
            // Subject Name Sort
            if (index === "subjectName") {
              const aName = `${a.user.firstName} ${a.user.lastName}`
              const bName = `${b.user.firstName} ${b.user.lastName}`
              if (!isDesc) { return aName < bName ? -1 : 1; }
              return bName < aName ? -1 : 1;
            }
            // Date Sort
            if (index === "issueDateTime" || index === "startDateTime" || index === "endDateTime") {
              if (!isDesc) { return new Date(a[index]) < new Date(b[index]) ? -1 : 1; }
              return new Date(b[index]) < new Date(a[index]) ? -1 : 1;
            }
            // Duration Sort
            if (index === "duration") {
              const aStartDateObject = new Date(a.startDateTime)
              const aEndDateObject = new Date(a.endDateTime)
              const bStartDateObject = new Date(b.startDateTime)
              const bEndDateObject = new Date(b.endDateTime)
              const aDuration = (aEndDateObject - aStartDateObject)
              const bDuration = (bEndDateObject - bStartDateObject)
              if (!isDesc) { return aDuration < bDuration ? -1 : 1; }
              return bDuration < aDuration ? -1 : 1;
            }
          });
          return result;
        } catch (e) {
          console.warn("Error when custom sorting", e)
          return items
        }
      },

      CustomFilter(items) {
        //console.log(items)
        return items.filter((item) => {
          if (item.title.toLowerCase().includes(this.search.text.toLowerCase())) { return true }
          const issuedToName = `${item.user.firstName} ${item.user.lastName}`.toLowerCase()
          if (issuedToName.includes(this.search.text.toLowerCase())) { return true }
        })
      },

      GetDateFromDateTime(dateTimeString) {
        return GetLocaleDateFromDateTime(dateTimeString)
      },

      GetDurationFromDateTimes(startDateTime, endDateTime) {
        try {
          const startDateObject = new Date(startDateTime)
          const endDateObject = new Date(endDateTime)
          const minutes = (endDateObject - startDateObject) / ( 1000 * 60 )
          return FormatDuration(minutes, DESIRED_DURATIONS.Days)
        } catch (err) {
          console.warn("Event provided without start or end time;", err)
          return "-"
        }
      },

      async GetCertificate(certificateDetails) {
        // console.log("Getting a certificate")
        // console.log('certificateDetails:', certificateDetails)
        this.is_loading = true
        try {
          const certificateService = new ExperienceCertificateService(this.$store)
          const certificateExists = await certificateService.CheckExperienceCertificateExists(certificateDetails)
          // If the certificate doesn't exist, tell the provider and do nothing else
          if (!certificateExists) {
            this.messageDialog.message = "The certificate is unavailable because the user has not yet viewed this certificate online. Please contact us at contact@mypropass.co.uk in case you need to view a copy of this certificate."
            this.messageDialog.show = true;
            this.is_loading = false
            return
          }
          // If it does exist, fetch it
          const signedURL = await certificateService.GetExperienceCertificateSignedURL(certificateDetails)
          //console.log("signedURL:", signedURL)
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = signedURL;
          a.download = `Experience Certificate - ${certificateDetails.id}.pdf`;
          a.target = "_blank"
          a.rel = "noopener"
          a.click();
          document.body.removeChild(a);
          this.is_loading = false
        } catch (err) {
          console.warn("Unhandled error when getting certificate;", err)
        }
        this.is_loading = false
      },

      CopyExperienceDetails(experienceDetails) {
        this.$router.push({
          name: `${ this.$store.getters.layout === LAYOUTS.ADMIN ? 'AdminCreateNewExperience' : 'ProviderCreateNewExperience' }`,
          params: {
            copyDetails: experienceDetails
          }
        })
      },

      CloseMessageDialog() {
        this.messageDialog.show = false;
      },

      DownloadExperience: function () {
        GenerateExperienceCSV(this.filteredExperience)
      },
    },

    computed: {
      filteredExperience: function() {
        try {
          const { providerIssuedExperience } = this.$store.getters
          const returnMap = providerIssuedExperience.map((experience) => {
            if (experience.user) {
              return experience
            }
            return {
              ...experience,
              user: {
                firstName: experience.nameProvided,
                lastName: ""
              }
            }
          })
          return returnMap
        } catch (err) {
          console.warn("Error getting issued experience certificates;", err)
          return []
        }
      }
    },

    async mounted() {
      this.is_data_fetched = true;
      this.pagination.totalItems = this.$store.getters.providerIssuedExperience.length
    }

  }
</script>

<style scoped>

</style>

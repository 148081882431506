<template>
  <v-container pa-0 ma-0 fluid v-if="is_data_fetched">
    <v-img :src="require('../images/contactdetailsbackground.png')" style="height:150px;" class="container-rounded-subheader">
      <v-layout>
        <v-flex xs12>
          <v-layout style="position:relative;">
            <v-flex x12>
              <v-layout row wrap>
                <v-flex>
                  <table style="width:97%;">
                    <tr>
                      <td style="width:10%">
                        <event-logo v-bind:imageKey="imageKey" level="public" style="border: 2px solid #fafafa; position: relative; height: 80px; width: 80px; bottom: -95px; background: #fafafa"/>
                      </td>
                      <td>
                        <div style="position:relative;margin-left:5px;vertical-align:bottom;bottom:-80px;">
                          <div style="float:left;font-size: 16px;">{{ name }}</div>
                        </div>
                      </td>
                      <td>
                        <div class="personal-id-container">
                          <div class="personal-id"><strong>Personal ID: </strong>{{ id }}</div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-img>
    <v-layout row wrap justify-center>
      <v-flex xs10>
        <h2 style="text-align:center;color:#045c5d;font-weight:700;">{{ name }}</h2>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center>
      <v-flex xs10 md6 mt-3 style="font-weight:normal;">
        <div v-html="description"></div>
      </v-flex>
    </v-layout>
    <br />
    <br />
    <v-layout row wrap justify-center>
      <v-flex xs5>
        <h5 style="text-align: center;color: rgb(4, 92, 93);font-size: 16px;font-weight: 700;">Why Choose {{ name }}?</h5>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center>
      <v-flex xs10 md6 mt-3 style="font-weight:normal;">
        <div v-html="whyChooseUs" />
      </v-flex>
    </v-layout>
    <br />
    <v-layout row wrap justify-center>
      <v-flex xs12 md3 mt-3>
        <h2 style="text-align: center;color: rgb(4, 92, 93);font-weight: 700;">Partner / Accreditation Logos</h2>
      </v-flex>
    </v-layout>
    <br />

    <v-layout justify-center>
      <v-flex xs12>
        <div class='accreditation-container' :style="getAccreditationGridLayout">
          <div v-for="accreditation in filteredAccreditations" v-bind:key="accreditation.id">
            <div class="accreditation-holder">
              <event-logo v-bind:imageKey="accreditation.imageKey" level="public" style="height:100px;width:100px;"/>
              <!--<p class="accreditation-name">{{ accreditation.name }}</p>-->
            </div>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  //import ProviderService from '../services/provider.service'
  //import 'swiper/dist/css/swiper.css'

  import EventLogo from './eventlogo-container'
  //import { swiper, swiperSlide } from 'vue-awesome-swiper'
  import { IMAGE_STATUS } from '@/constants/ImageConstants'

  export default {
    data() {
      return {
        windowWidth: window.innerWidth,
        is_data_fetched: false,
        swiperOption: {
          slidesPerView: 5,
          spaceBetween: 50,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          breakpoints: {
            1024: {
              slidesPerView: 4,
              spaceBetween: 40
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 10
            }
          }
        },
        contactDetails: {
          id: 0,
          personalId: 0,
          logo: '',
          fileName: '',
          organizationName: '',
          postCode: '',
          address: '',
          webSite: '',
          registrationNumber: '',
          registrationAddress: '',
        }
      }
    },

    methods: {
      onResize() {
        this.windowWidth = window.innerWidth
      }

    },

    components: {
      //swiper,
      //swiperSlide,
      'event-logo': EventLogo,
    },

    computed: {
      id: function() {
        try {
          return this.$store.getters.employer.id
        } catch (err) {
          console.warn("Unable to get employer ID")
          return ""
        }
      },
      name: function() {
        try {
          return this.$store.getters.employer.name
        } catch (err) {
          console.warn("Unable to get employer name")
          return ""
        }
      },
      description: function() {
        try {
          return this.$store.getters.employer.courseProviderDetails.description
        } catch (err) {
          console.warn("Unable to get provider why choose us")
          return ""
        }
      },
      whyChooseUs: function() {
        try {
          return this.$store.getters.employer.courseProviderDetails.whyChooseUs
        } catch (err) {
          console.warn("Unable to get provider why choose us")
          return ""
        }
      },
      imageKey: function() {
        try {
          return this.$store.getters.employer.logo.imageKey
        } catch (err) {
          console.warn("Unable to get provider why choose us")
          return ""
        }
      },
      accreditations: function() {
        try {
          return this.$store.getters.employer.accreditationLogos.items
        } catch (err) {
          console.warn("Unable to get provider accreditation logos")
          return []
        }
      },
      filteredAccreditations: function() {
        try {
          return this.accreditations.filter((accreditation) => {
            return accreditation.status === IMAGE_STATUS.LIVE || !accreditation.status
          })
        } catch (err) {
          console.warn("Unable to get provider accreditation logos")
          return []
        }
      },
      getAccreditationGridLayout: function() {
        try {
          const numAccreditationLogos = this.filteredAccreditations.length
          if (this.windowWidth < 420 ) { return `grid-template-columns: repeat(${numAccreditationLogos > 2 ? 2 : numAccreditationLogos}, minmax(100px, 1fr));` }
          if (this.windowWidth < 768 ) { return `grid-template-columns: repeat(${numAccreditationLogos > 3 ? 3 : numAccreditationLogos}, minmax(100px, 1fr));` }
          if (this.windowWidth < 1024 ) { return `grid-template-columns: repeat(${numAccreditationLogos > 5 ? 5 : numAccreditationLogos}, minmax(100px, 1fr));` }
          return `grid-template-columns: repeat(${numAccreditationLogos > 6 ? 6 : numAccreditationLogos}, minmax(100px, 1fr)); max-width: 50%;`
        } catch (err) {
          return ""
        }
      }
    },


    mounted() {

      this.is_data_fetched = true;

      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      })
      /*
      ProviderService.GetContactDetails()
        .then(response => {
          console.log(response);
          if (response.data) {
            var result = response.data;
            this.contactDetails.id = result.id;
            this.contactDetails.personalId = result.personalId.toLocaleString().replace(/,/g, '-');
            this.contactDetails.logo = result.logo;
            this.contactDetails.organizationName = result.organizationName;
            this.contactDetails.address = result.address;
            this.contactDetails.postalCodeLookup = result.postalCodeLookup;
            this.contactDetails.webSite = result.webSite;
            this.contactDetails.registrationNumber = result.registrationNumber;
            this.contactDetails.registrationAddress = result.registrationAddress;
            this.is_data_fetched = true;
          }
        })
        .catch(function (err) {
          console.log(err);
        });

      ProviderService.GetAboutDetails()
        .then(response => {
          console.log(response);
          var result = response.data;
          if (result) {
            this.aboutDetails.id = result.id;
            this.aboutDetails.description = result.description;
            this.aboutDetails.whyChooseUs = result.whyChooseUs;
            this.aboutDetails.images = result.images;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
        */
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    }

  }
</script>



<style scoped>

  .readable-text {
    max-width: min(70ch, 80%) !important;
  }

  .v-btn {
    text-decoration: none;
    text-transform: capitalize;
  }
  /* style the container */

  body {
    background-color: rgba(243,246,248,1);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  .container-rounded-subheader {
    padding-left: 25px;
    margin-top: 0px;
    margin-bottom: 50px;
    min-height: 40px;
    overflow: visible;
  }

  .container-rounded-mini {
    background-color: #FFFFFF;
    padding-left: 50px;
    margin-left: 40px;
    margin-right: 40px;
    min-height: 40px;
  }

  .container-rounded {
    background-color: #FFFFFF;
    padding-left: 50px;
    margin: 40px;
    min-height: 350px;
  }

  .container-rounded-about {
    background-color: #FFFFFF;
    padding-left: 50px;
    margin: 40px;
    min-height: 500px;
  }

  .swiper-button-prev, .swiper-button-next {
    height: 24px;
  }

  .contact-details-logo {
    border: 2px solid #fafafa;
    position: relative;
    height: 60px;
    width: 60px;
    bottom: -95px;
    background: #fafafa
  }

  .personal-id-container {
    position: absolute;
    margin-left: 5px;
    vertical-align: bottom;
    bottom: 0;
    width: 100%;
    left: -15px;
  }

  .personal-id {
    float: left;
    font-size: 11px;
    font-weight: normal
  }

  .accreditation-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
  }

  .accreditation-name {
    text-align: center;
    margin-top: 0.33em;
    font-weight: bold;
  }

  .accreditation-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 1rem;
    margin: auto;
  }



  @media (min-width: 768px) {

    .container-rounded-subheader {
      margin: 0 40px 40px;
      padding-left: 50px;
    }

    .contact-details-logo {
      height: 100px;
      width: 100px;
      bottom: -75px;
    }

    .personal-id-container {
      position: relative;
      margin-left: 5px;
      vertical-align: bottom;
      bottom: -80px;
    }

    .personal-id {
      float: right;
    }
  }

</style>

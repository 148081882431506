<template>
  <v-img v-if='!imageIsLocal' v-bind:src="imageURL" contain :style="style" v-on:error="imageURL=fallbackImage" :title="imageTitle" />
  <v-img v-else v-bind:src="fallbackImage" contain :style="style" :title="imageTitle" />
</template>


<script>

  import ImageService from '@/services/image.service'
  import { IMAGE_TYPES } from '@/constants/ImageConstants'

  export default {

    data() {
      return {
        imageURL: process.env.VUE_APP_placeholder_company_logo_url,
        style: "background: #ccc; height: 85px; width: 85px"
      }
    },

    props: {
      imageKey: String,
      level: String,
      imageStyle: String,
      imageType: String,
      imageTitle: String
    },

    computed: {
      fallbackImage: function() {
        if (this.imageType === IMAGE_TYPES.AVATAR) { return process.env.VUE_APP_placeholder_avatar_url }
        if (this.imageType === IMAGE_TYPES.EVENT_LOGO) { return process.env.VUE_APP_placeholder_event_logo_url }
        if (this.imageType === IMAGE_TYPES.EMPLOYER_LOGO) { return process.env.VUE_APP_placeholder_company_logo_url }
        if (this.imageType === IMAGE_TYPES.ACCREDITATION_LOGO) { return process.env.VUE_APP_placeholder_accreditation_logo_url }
        return process.env.VUE_APP_placeholder_avatar_url
      },
      imageIsLocal: function() {
        return this.imageKey.startsWith("/images/")
      },
    },

    methods: {
      async GetImageSignedURL() {
        //console.log(this.imageType, this.imageKey)
        try {
          const imageService = new ImageService()
          if (this.imageType === IMAGE_TYPES.AVATAR) {
            const response = await imageService.GetSignedURLForFile(this.imageType, this.imageKey, this.level)
            this.imageURL = response
            return
          }
          const response = await imageService.GetEventLogoSignedURL(this.imageKey, this.level)
          this.imageURL = response
        } catch (e) {
          return process.env.VUE_APP_placeholder_event_logo_url
        }
      },
    },

    watch: {
      imageKey: function() {
        this.GetImageSignedURL()
      },
      imageStyle: function() {
        if (this.imageStyle) {
          this.style = this.imageStyle
          return
        }
        this.style = "background: #ccc; height: 85px; width: 85px"
      },
      level: function() {
        this.GetImageSignedURL()
      }
    },

    mounted() {
      //console.log("Avatar Image was mounted")
      this.GetImageSignedURL()
      if (this.imageStyle) {
        this.style = this.imageStyle
        return
      }
      this.style = "background: #ccc; height: 85px; width: 85px"
    }
  }

</script>


<style scoped>
</style>

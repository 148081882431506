<template>
  <v-container fluid pa-0>
    <v-layout ma-0>
      <v-flex md12>
        <v-card>
          <v-card-title class="pb-2">
            <div class="page-header">Manage Booking</div>
          </v-card-title>
          <v-divider class="ma-0 ml-3 mr-3" style="border-width:1px;"></v-divider>
          <v-container pa-3>
            <v-layout ma-0 justify-center align-content-center>
              <v-flex xs12>
                <v-layout style="background-color: rgba(243, 246,248, 0.7);">
                  <v-flex xs2>
                    <v-container fill-height style="padding: 0;">
                      <v-layout ma-0 pa-0>
                        <event-logo v-bind:imageKey="eventLogo" level="public" imageStyle="background-color: transparent; margin:0.33em;"/>
                      </v-layout>
                    </v-container>
                  </v-flex>
                  <v-flex xs8 style="margin-left:10px;">
                    <v-layout align-content-start ma-0 pa-0>
                      <div class="ABC_in_Scaffolding">
                        <span>{{ eventTitle }}</span><br />
                        <div class="Duration__1_hour">
                          <span v-html="eventDescription"></span><br />
                          <span>{{ eventFormattedDuration }} | {{ eventType }}</span><br />
                          Provider: <span>{{ providerName }}</span><br />
                          Instructor: <span style="color:#2385E6;">{{ instructorFullName }}</span><br />
                          <v-icon color="rgb(0,171,173)">call</v-icon> <span style="color:#2385E6;">{{ providerPhoneNumber }}</span><br />
                          <v-icon color="rgb(0,171,173)">email</v-icon> <span style="color:#2385E6;">{{ providerEmail }}</span>
                        </div>
                      </div>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <div style="min-height:10px"></div>
            <v-layout ma-0 justify-center align-content-center>
              <v-flex xs12>
                <v-layout style="background-color: rgba(243, 246,248, 0.7);">
                  <v-flex xs12 pt-2 style="margin-left:10px;margin-right:10px;">
                    <span>Your Reservation</span><br />
                    <v-divider class="mt-1 mb-2" style="border-width:1px;"></v-divider>
                  </v-flex>
                </v-layout>
                <v-layout style="background-color: rgba(243, 246,248, 0.7);">
                  <v-flex style="margin-left:10px;">
                    <v-layout ma-0 pa-0>
                      <div class="Duration__1_hour">
                        <span style="color: #999;font-weight:bold;margin-bottom:8px;display:block"> Start Date: <b style="color: #333">{{ bookingDate(parentData, "startDateTime") }} - {{ bookingTime(parentData, "startDateTime") }}</b></span>
                        <span style="color: #999;font-weight:bold;margin-bottom:8px;display:block"> End Date: <b style="color: #333">{{ bookingDate(parentData, "endDateTime") }}- {{ bookingTime(parentData, "endDateTime") }}</b></span>
                        <span v-if="isInPersonEvent(parentData)" style="color: #999;font-weight:bold;margin-bottom:8px;display:block"> Location: {{ bookingLocationInPerson }}</span>
                        <span v-if="isVirtualEvent(parentData)" style="color: #999;font-weight:bold;margin-bottom:8px;display:block"> Location: <a href="#" @click="OpenOnlineUrl(bookingLocationOnline)" small >{{ bookingLocationOnline }}</a></span>
                      </div>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
          <div class="pa-2">
            <v-btn small color=rgb(0,171,173) :disabled="isCancelBookingDisabled(this.parentData)" @click="ShowCancelBookingDialog" class="v-btn-secondary">Cancel Booking</v-btn>
            <v-btn small color=rgb(0,171,173) @click="closeManageBookingDialog" style="float: right">Close</v-btn>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog persistent v-model="cancelbookingdialog.show" transition="dialog-transition" max-width="450">
      <v-card>
        <v-card-text>
          <v-flex>
            <div style="font-weight:normal;">
              Do you want to proceed to cancel the booking? Cancellation will be in line with our terms and conditions.
            </div>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelBooking" small color=rgb(0,171,173)>Yes</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="closeManageBookingDialog" small color=rgb(0,171,173)>No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="messageDialog.show" transition="dialog-transition" max-width="450">
      <v-card>
        <v-card-text>
          <v-flex>
            <div id="message" style="font-weight:normal;">{{messageDialog.message}}</div>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeManageBookingDialog" small color=rgb(0,171,173)>OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>

  import UserService from '../services/user.service'
  import BookingService from '../services/booking.service'

  import { EventDurationInMinutes, EventTypeToDisplayText, FormatAddressObjectToString } from '../helpers/event.helper'
  import { FormatDuration, DESIRED_DURATIONS } from '../helpers/duration.helper'
  import { GetDateFromDateTime, GetTimeFromDateTime } from '../helpers/date.helper'
  import { BOOKING_STATUS } from '../constants/BookingConstants'
  import { EVENT_MODE, EVENT_STATUS } from '../constants/EventConstants'
  import EventLogo from './eventlogo-container'

  export default {
    data() {
      return {
        cancelbookingdialog: {
          show: false,
          bookingId: 0
        },
        messageDialog: {
          show: false,
          message: ''
        },
      }
    },
    props: {
      parentData: Object,
      onClose: Function
    },
    components: {
      'event-logo': EventLogo
    },
    computed: {
      eventTitle: function () {
        try { return this.parentData.event.title } catch (e) { return '' }
      },
      eventDescription: function () {
        try { return this.parentData.event.description } catch (e) { return '' }
      },
      eventLogo: function () {
        try { return this.parentData.event.eventLogo.imageKey } catch (e) { return '' }
      },
      eventFormattedDuration: function () {
        try {
          if (!this.parentData.event) { return '0 Mins' }
          const eventDuration = EventDurationInMinutes(this.parentData.event)
          return FormatDuration(eventDuration, DESIRED_DURATIONS.HoursMinutes)
        } catch (e) {
          return ''
        }
      },
      eventType: function () {
        try { return EventTypeToDisplayText[this.parentData.event.eventType] } catch (e) { return '' }
      },
      instructorFullName: function () {
        try { return `${this.parentData.event.instructor.firstName} ${this.parentData.event.instructor.lastName}` } catch (e) { return '' }
      },
      providerName: function () {
        try { return this.parentData.event.provider.name } catch (e) { return '' }
      },
      providerPhoneNumber: function () {
        try { return this.parentData.event.provider.contactDetails.phoneBookings } catch (e) { return '' }
      },
      providerEmail: function () {
        try { return this.parentData.event.provider.contactDetails.emailBookings} catch (e) { return '' }
      },
      bookingLocationInPerson: function () {
        try { return FormatAddressObjectToString(this.parentData.event.address) } catch (e) { return '' }
      },
      bookingLocationOnline: function () {
        try { return this.parentData.event.onlineURL } catch (e) { return '' }
      }
    },

    methods: {
      OpenOnlineUrl(url) {
        try {
          if (!url.match(/^[a-zA-Z]+:\/\//)) { url = 'http://' + url; }
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.target = "_blank"
          a.rel = "noopener"
          a.href = url;
          a.click();
          document.body.removeChild(a);
        } catch (e) {
          console.log("Error when opening online URL;", e)
        }
      },
      bookingDate: function (booking, dateTimeKey) {
        try { return GetDateFromDateTime(booking.event[dateTimeKey]) } catch (e) { return '' }
      },
      bookingTime: function (booking, dateTimeKey) {
        try { return GetTimeFromDateTime(booking.event[dateTimeKey]) } catch (e) { return '' }
      },
      isInPersonEvent(booking) {
        try { return booking.event.eventMode === EVENT_MODE.INPERSON } catch (e) { return false }
      },
      isVirtualEvent(booking) {
        try { return booking.event.eventMode === EVENT_MODE.VIRTUAL } catch (e) { return false }
      },
      isBookingStatusBooked(booking) {
        try {
          return booking.status === BOOKING_STATUS.BOOKED
        } catch (e) {
          return false
        }
      },
      isEventStatusScheduled(booking) {
        //console.log(event)
        try {
          return booking.event.status === EVENT_STATUS.LIVE
        } catch (e) {
          return false
        }
      },
      isCancelBookingDisabled(booking) {
        return !this.isBookingStatusBooked(booking) || !this.isEventStatusScheduled(booking)
      },
      ShowCancelBookingDialog() {
        console.log("Showing cancel booking dialog box")
        this.cancelbookingdialog.show = true;
      },
      async CancelBooking() {
        const bookingId = this.parentData.id
        console.log(this.parentData.id);
        const parameters = {
          id: bookingId
        }

        var response;
        var event;
        try {
          // Request that the booking is cancelled
          response = await BookingService.CancelBooking(parameters)
          const userService = new UserService(this.$store)
          await userService.RefreshBookings()
          console.log("booking cancelled")
          //console.log(response)
          // Extract the event
          event = response.data.updateEventBooking.event
        } catch (err) {
          console.warn("Error when cancelling booking", err)
          return
        }

        // Open the popover windows
        this.messageDialog.show = true;
        this.messageDialog.message = `Your booking for ${event.title} on ${GetDateFromDateTime(event.startDateTime)} was cancelled`;
        // Get new details for the user
        const userService = new UserService(this.$store)
        await Promise.all([userService.GetDetails(), userService.GetNotifications()])
      },
      closeManageBookingDialog() {
        try {
          this.messageDialog.show = false;
          this.messageDialog.message = "";
          this.onClose()
        } catch (err) {
          console.warn("Error when trying to close manage booking dialog;", err)
        }
      }
    },
    /*
    watch: {
      parentData: {
        // Remove this debugging watcher once the event page isn't crashing when the user clicks an event
        // the callback will be called immediately after the start of the observation
        immediate: true,
        handler (val, oldVal) {
          // do your stuff
          console.log("user manage booking parentData changed from")
          console.log(oldVal)
          console.log("to")
          console.log(val)
        }
      }
    },
    */
    mounted() {
      //console.log("User Manage Booking was mounted")
    }
  }
</script>

<style>
  .v-btn {
    text-decoration: none;
    text-transform: capitalize;
  }

  tbody tr:nth-of-type(even) {
    background-color: rgba(243, 246,248, 0.7);
  }

  .container-rounded {
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 5px;
    margin: 5px;
  }

  .container-rounded-grey {
    border-radius: 10px;
    background-color: rgba(243, 246,248, 0.7);
    padding: 5px;
    margin: 5px;
  }

  .ABC_in_Scaffolding {
    opacity: 1;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: left;
    font-family: basefont;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(39,62,75,1);
    letter-spacing: 0.2px;
  }

  .Duration__1_hour {
    opacity: 1;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: left;
    font-family: basefont;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0.2px;
    width: 100%;
  }

  .material-icons {
    font-size: 16px !important;
  }
</style>


<style scoped>

  .container-image {
    padding: 0 !important;
  }

</style>

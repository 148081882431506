<template>
  <div id="app">
    <v-app id="inspire" style="overflow:hidden;">
      <v-toolbar color="rgb(64,78,103)" fixed style="padding:0;height: 10vh;">
        <img src="../images/logo.png" style="max-height:150px;max-width:150px; margin-bottom:5px;margin-left:25px;" />
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-toolbar-title></v-toolbar-title>
        <v-spacer></v-spacer>
        <a style="text-align:center;color:white;"  @click="goToHomePage()"><img src="../images/gotohome.png" style="height:30px" /><br />Home</a>
      </v-toolbar>
      <v-container app style="padding:0;">
        <v-layout style="padding:0;">
          <v-flex>
            <router-view />
          </v-flex>
        </v-layout>
      </v-container>
      <v-footer color="rgb(64,78,103)" app style="height:10vh;">
        <v-spacer></v-spacer>
        <span class="white--text">&copy; 2019</span>
      </v-footer>
    </v-app>
  </div>
</template>
<script>

  //import UserService from '../services/user.service'

  export default {
    data() {
      return {
        userName: '',
      }
    },

    methods: {

      goToHomePage() {
        try {
          /*
          UserService.GetUser()
            .then((user) => {
            console.log('user');
            console.log(user);
              if (!!user && (user.expired == false)) {
                UserService.GotoHomePage(user);
              }
              else {
                this.$router.push({ name: 'FullPage' });
              }
            });
            */
          console.log("/src/layouts/error-layout method 'go to home page' has been stripped out")
        } catch (e) {
        console.log(e);
        }
      },
    },

    mounted() {

      try {
        /*
        UserService.GetUser()
          .then((user) => {
            console.log('user');
            console.log(user);
            if (!!user && (user.expired == false)) {
              this.userName = user.profile.name;
              var role = user.profile.role;
            }
            else {
              this.$router.push({ name: 'FullPage' });
            }
          });
          */
          console.log("/src/layouts/error-layout method get user has been stripped out")
      } catch (e) {
        console.log(e);
      }

    }
  }

</script>
<style>
  body {
    background-color: rgb(243,246,248);
  }

  .v-toolbar__extension {
    padding: 0px;
  }
</style>

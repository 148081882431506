
import { API, Storage, graphqlOperation } from 'aws-amplify';
//import { v4 as uuidv4 } from 'uuid';
import * as Mutations from '../graphql/mutations';
import * as EventQueriesCustom from '../graphql/eventQueriesCustom';
import * as QueriesCustom from '../graphql/queriesCustom';
import * as StoreActions from '../constants/StoreActions'
import { BOOKING_STATUS } from '@/constants/BookingConstants'
import { EVENT_STATUS, EVENT_POSSIBLE_ACTIONS, EVENT_TYPE } from '../constants/EventConstants'

import Event from '@/models/event.model'

class EventService {

  constructor(store) {
    this.$store = store
  }

  // Get all the events available to this user
  /*
  async ListEvents() {
    console.log("Fetching list of events")
    const parameters = {}
    try {
      const response = await API.graphql(graphqlOperation(QueriesCustom.listEvents, parameters));
      const result = response.data.listEvents
      this.$store.commit(StoreActions.SET_EVENTS, result);
      return result
    } catch (e) {
      console.warn("Error getting event list:", e)
      // If some data was recovered, save it anyway
      if (e.data) {
        const result = e.data.listEvents
        this.$store.commit(StoreActions.SET_EVENTS, result);
        return result
      }
      return {}
    }
  }
  */


  // Get the future events available to this user
  async GetFutureEventsForUser() {
    //console.log("Fetching future events for user")
    const parameters = {
      status: EVENT_STATUS.LIVE,
      startDateTime: { gt: (new Date()).toISOString() },
      sortDirection: 'ASC'
    }
    try {
      const response = await API.graphql(graphqlOperation(EventQueriesCustom.eventByStatusAndStartDate, parameters));
      //console.log("GetFutureEventsForUser response:", response)
      const result = response.data.eventByStatusAndStartDate
      this.$store.commit(StoreActions.SET_FUTURE_EVENTS, result);
      return result
    } catch (e) {
      console.warn("Error getting event list:", e)
      // If some data was recovered, save it anyway
      if (e.data) {
        const result = e.data.eventByStatusAndStartDate
        this.$store.commit(StoreActions.SET_FUTURE_EVENTS, result);
        return result
      }
      return {}
    }
  }


  // Gets more future events available to this user
  async ExtendFutureEventsForUser() {
    //console.log("Extending future events for user")
    // If there are no more events to fetch, return and do nothing
    if (!this.$store.getters.futureevents.nextToken) {
      //console.log("Requested extending future events for user, but there are no more events")
      return
    }
    const parameters = {
      status: EVENT_STATUS.LIVE,
      startDateTime: { gt: (new Date()).toISOString() },
      nextToken: this.$store.getters.futureevents.nextToken,
      sortDirection: 'ASC'
    }
    try {
      const response = await API.graphql(graphqlOperation(EventQueriesCustom.eventByStatusAndStartDate, parameters));
      //console.log("GetFutureEventsForUser response:", response)
      const result = response.data.eventByStatusAndStartDate
      this.$store.commit(StoreActions.EXTEND_FUTURE_EVENTS, result);
      return result
    } catch (e) {
      console.warn("Error getting event list:", e)
      // If some data was recovered, save it anyway
      if (e.data) {
        const result = e.data.eventByStatusAndStartDate
        this.$store.commit(StoreActions.EXTEND_FUTURE_EVENTS, result);
        return result
      }
      return {}
    }
  }


  // Get the future events available to this employee
  async GetFutureEventsForEmployee() {
    //console.log("Fetching future events sorted by date for employee")
    const parameters = {
      providerId: this.$store.getters.employer.id,
      startDateTime: { gt: (new Date()).toISOString() },
      sortDirection: 'ASC'
    }
    //console.log("GetFutureEventsForEmployee:", parameters)
    try {
      const response = await API.graphql(graphqlOperation(EventQueriesCustom.eventByEmployerAndStartDate, parameters));
      //console.log("GetFutureEventsForEmployee response:", response)
      const result = response.data.eventByEmployerAndStartDate
      this.$store.commit(StoreActions.SET_FUTURE_EVENTS, result);
      return result
    } catch (e) {
      console.warn("Error getting event list:", e)
      // If some data was recovered, save it anyway
      if (e.data) {
        const result = e.data.eventByEmployerAndStartDate
        this.$store.commit(StoreActions.SET_FUTURE_EVENTS, result);
        return result
      }
      return {}
    }
  }


  // Get more future events available to this employee
  async ExtendFutureEventsForEmployee() {
    //console.log("Extending future events for employee")
    // If there are no more events to fetch, return and do nothing
    if (!this.$store.getters.futureevents.nextToken) {
      //console.log("Requested extending future events for employee, but there are no more events")
      return
    }
    const parameters = {
      providerId: this.$store.getters.employer.id,
      startDateTime: { gt: (new Date()).toISOString() },
      nextToken: this.$store.getters.futureevents.nextToken,
      sortDirection: 'ASC'
    }
    try {
      const response = await API.graphql(graphqlOperation(EventQueriesCustom.eventByEmployerAndStartDate, parameters));
      //console.log("GetFutureEventsForEmployee response:", response)
      const result = response.data.eventByEmployerAndStartDate
      this.$store.commit(StoreActions.EXTEND_FUTURE_EVENTS, result);
      return result
    } catch (e) {
      console.warn("Error getting event list:", e)
      // If some data was recovered, save it anyway
      if (e.data) {
        const result = e.data.eventByEmployerAndStartDate
        this.$store.commit(StoreActions.EXTEND_FUTURE_EVENTS, result);
        return result
      }
      return {}
    }
  }


  // Get the past events available to this employee
  async GetPastEventsForEmployee() {
    //console.log("Fetching past events for employee")
    const parameters = {
      providerId: this.$store.getters.employer.id,
      startDateTime: { lt: (new Date()).toISOString() },
      sortDirection: 'DESC'
    }
    //console.log("GetPastEventsForEmployee:", parameters)
    try {
      const response = await API.graphql(graphqlOperation(EventQueriesCustom.eventByEmployerAndStartDate, parameters));
      //console.log("GetPastEventsForEmployee response:", response)
      const result = response.data.eventByEmployerAndStartDate
      this.$store.commit(StoreActions.SET_PAST_EVENTS, result);
      return result
    } catch (e) {
      console.warn("Error getting event list:", e)
      // If some data was recovered, save it anyway
      if (e.data) {
        const result = e.data.eventByEmployerAndStartDate
        this.$store.commit(StoreActions.SET_PAST_EVENTS, result);
        return result
      }
      return {}
    }
  }


  // Get more past events available to this employee
  async ExtendPastEventsForEmployee() {
    //console.log("Extending past events for employee")
    // If there are no more events to fetch, return and do nothing
    if (!this.$store.getters.pastevents.nextToken) {
      //console.log("Requested extending past events for employee, but there are no more events")
      return
    }
    const parameters = {
      providerId: this.$store.getters.employer.id,
      startDateTime: { lt: (new Date()).toISOString() },
      nextToken: this.$store.getters.pastevents.nextToken,
      sortDirection: 'DESC'
    }
    try {
      const response = await API.graphql(graphqlOperation(EventQueriesCustom.eventByEmployerAndStartDate, parameters));
      console.log("GetPastEventsForEmployee response:", response)
      const result = response.data.eventByEmployerAndStartDate
      this.$store.commit(StoreActions.EXTEND_PAST_EVENTS, result);
      return result
    } catch (e) {
      console.warn("Error getting event list:", e)
      // If some data was recovered, save it anyway
      if (e.data) {
        const result = e.data.eventByEmployerAndStartDate
        this.$store.commit(StoreActions.EXTEND_PAST_EVENTS, result);
        return result
      }
      return {}
    }
  }



  async GetEventLogoSignedURL(event) {
    // First, check that the event has a logo
    var logoKeyString
    try {
      logoKeyString = event.eventLogo.imageKey
    } catch (e) {
      console.warn("Error getting event logo:", e)
      return process.env.VUE_APP_placeholder_event_logo_url
    }
    // Check that the logo isn't blank
    if (logoKeyString === '') {
      return process.env.VUE_APP_placeholder_event_logo_url
    }
    // Build the parameters for the signed URL
    var parameters = {
      expires: 3600
    }
    // Fetch the link
    const result = await Storage.get(logoKeyString, parameters);
    //console.log(result)
    return result
  }


  // GetEventDetailsForBookingScreen gets all the details for a single event that a
  // user wants to book onto.  Not a lot of information is returned because it is not
  // needed.
  async GetEventDetailsForBookingScreen(eventId) {
    if (!eventId || eventId.length === 0) {
      throw "No event ID provided to GetEventDetailsForBookingScreen function"
    }
    //console.log("eventId:", eventId)
    const parameters = {
      id: eventId
    }
    try {
      const response = await API.graphql(graphqlOperation(QueriesCustom.getEventDetailsForBookingScreen, parameters));
      const result = response.data.getEvent
      return result
    } catch (e) {
      console.warn("Error getting event details:", e)
      // If some data was recovered, return it anyway as we can work with partial data
      if (e.data) {
        const result = e.data.getEvent
        return result
      }
      return {}
    }
  }


  async GetEventProviderDetails(parameters) {
    try {
      const response = await API.graphql(graphqlOperation(QueriesCustom.getEventProviderDetails, parameters));
      const result = response.data.getEvent.provider
      return result
    } catch (e) {
      console.warn("Error getting provider details:", e)
      // If some data was recovered, return it anyway as we can work with partial data
      if (e.data) {
        const result = e.data.getEvent.provider
        return result
      }
      return {}
    }
  }

  async fetchAttendeesUsingNextToken(eventId, nextToken) {
    try {
      const parameters = {
        id: eventId,
        nextToken
      }
      // Using the Attendee nextToken on the Event table query
      const response = await API.graphql(graphqlOperation(QueriesCustom.queryEventForAttendeeListWithAttendeeNextToken, parameters));
      const result = response.data.getEvent.attendees
      //console.log('fetchAttendeesUsingNextToken result:', result)
      return result
    } catch (e) {
      console.warn("Error getting event attendees:", e)
      return {
        items: [],
        nextToken: undefined
      }
    }
  }


  async GetEventAttendees(eventId) {
    try {
      const parameters = { id: eventId }
      const response = await API.graphql(graphqlOperation(QueriesCustom.getEventAndAttendees, parameters));
      const result = response.data.getEvent.attendees
      result.event = response.data.getEvent
      //console.log("result:", result)
      // If there was a nextToken for the attendees, fetch them until the list is complete
      while (result.nextToken) {
        var tempResult = await this.fetchAttendeesUsingNextToken(eventId, result.nextToken)
        result.items = result.items.concat(tempResult.items)
        result.nextToken = tempResult.nextToken
      }
      return result
    } catch (e) {
      console.warn("Error combining event attendees lists:", e)
      return []
    }
  }



  MarkEventAsCertificatesSent(eventId) {
    const parameters = {
      input: {
        id: eventId,
        status: EVENT_STATUS.CERTIFICATESSENT,
        possibleActions: [EVENT_POSSIBLE_ACTIONS.VIEWCERTIFICATES]
      }
    }
    return API.graphql(graphqlOperation(Mutations.updateEvent, parameters));
  }


  /**
  * UpdateEventDetails returns a graphql promise to update an event
  * @param  {string} eventId The eventId that is being updated
  * @param  {object} parameters A POJO containing the id and details to update
  * @return {promise} The graphql promise to update an event
  */
  async UpdateEventDetails(eventId, parameters) {
    //console.log("Requesting update event")
    //console.log(parameters)

    if (Object.keys(parameters).length === 0) {
      console.log("There were no changes made; maybe only prices were changed")
      return
    }

    const queryParameters = {
      input: {
        ...parameters,
        id: eventId
      }
    }
    // Delete the prices if provided (just in case)
    delete queryParameters.input.prices
    // Some housekeeping to ensure creation happens
    if (queryParameters.input.eventType !== EVENT_TYPE.CONFERENCE) {
       delete queryParameters.input.eventValidityDateTime
    }
    //console.log(queryParameters)

    // If more than the price changed, then send the change the event request
    if (Object.keys(queryParameters.input) !== ["id"]) {
      return API.graphql(graphqlOperation(Mutations.updateEvent, queryParameters));
    }
  }

  /**
  * CreateEvent returns a graphql promise to create an event
  * @param  {Event}   currentEvent  The event that is being created
  * @return {promise}               The graphql promise to create an event
  */
  async CreateEvent(currentEvent) {
    //console.log("Requesting create event")
    //console.log("parameters:", parameters)
    // Ensure the right object type was passed to this function
    if (!(currentEvent instanceof Event)) {
      throw 'argument currentEvent was not an instance of Event'
    }
    // Build the query parameters
    const queryParameters = {
      input: {
        ...currentEvent.getGraphQLObject(),
        groupsCanUpdate: [`${this.$store.getters.employer.id}`]
      }
    }
    // Return the promise to create the event
    console.log("queryParameters:", queryParameters)
    return API.graphql(graphqlOperation(Mutations.createEvent, queryParameters));
  }

  /**
  * CancelEvent returns a graphql promise to set an event's status to cancelled
  * @param  {string} eventId The eventId that is being updated
  * @return {promise} The graphql promise to update an event
  */
  CancelEvent(eventId) {
    //console.log("Requesting cancel event")
    console.log(eventId)
    const queryParameters = {
      input: {
        id: eventId,
        status: EVENT_STATUS.CANCELLED
      }
    }
    console.log(queryParameters)
    return API.graphql(graphqlOperation(Mutations.updateEvent, queryParameters));
  }


  /**
  * DeleteEvent returns a graphql promise to set an event's status to deleted
  * @param  {string} eventId The eventId that is being updated
  * @return {promise} The graphql promise to update an event
  */
  DeleteEvent(eventId) {
    //console.log("Requesting delete event")
    //console.log(eventId)
    const queryParameters = {
      input: {
        id: eventId,
        status: EVENT_STATUS.DELETED
      }
    }
    console.log(queryParameters)
    return API.graphql(graphqlOperation(Mutations.updateEvent, queryParameters));
  }

  /**
  * PublishEvent returns a graphql promise to set an event's status to live
  * @param  {string} eventId The eventId that is being updated
  * @return {promise} The graphql promise to update an event
  */
  PublishEvent(eventId) {
    //console.log("Requesting publish event")
    //console.log(eventId)
    const queryParameters = {
      input: {
        id: eventId,
        status: EVENT_STATUS.LIVE,
        possibleActions: [
          EVENT_POSSIBLE_ACTIONS.EDITCONTENT,
          EVENT_POSSIBLE_ACTIONS.MANAGEATTENDEES
        ]
      }
    }
    console.log("Publish Event queryParameters:", queryParameters)
    return API.graphql(graphqlOperation(Mutations.updateEvent, queryParameters));
  }

  /**
  * DownloadAttendeeList creates a csv file that the user downloads
  * @param  {string} eventId The Id of the event that is to be printed
  * @param  {string} eventName The name of the event that is to be printed (for the filename)
  * @return {null}
  */
  async DownloadAttendeeList(eventId, eventName) {
    //console.log("Creating CSV of attendees")
    //console.log("eventId:", eventId)

    const result = await this.GetEventAttendees(eventId)
    //console.log("result:", result)
    const { items, event } = result

    const headerRow = "data:text/csv;charset=utf-8,Course Title,First Name,Last Name,Email Address,Booking Reference,Status,Attendance\r\n"
    var csvContent = headerRow
    // Extract the content from each attendee
    const contentRows = items.map((booking) => {
      return `"${event.title}","${booking.user ? booking.user.firstName : "Unconfirmed" }","${booking.user ? booking.user.lastName : "User" }","${booking.user ? booking.user.emailAddress : "N/A" }","${booking.id}","${booking.status}","${booking.attendance}"`
    })
    //console.log("contentRows:", contentRows)

    // Join all the attendee rows to the heading
    for (const row of contentRows) {
      csvContent = csvContent + row + "\r\n"
    }
    //console.log("csvContent:", csvContent)

    // Create the label for the file
    const label = eventName ? eventName.replace(/ /g, "_") : "Event"

    var encodedUri = encodeURI(csvContent);
    // Encode the octothorpes because they cause the output to be truncated
    encodedUri = encodedUri.replace(/#/g, "%23")
    //console.log("encodedUri:", encodedUri)
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${label}_Attendee_list.csv`);
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file
  }

  /**
  * GetEventByCode returns the first event that has the given code
  * @param  {string} eventCode The Id of the event that is to be printed
  * @return {object} The details of the event that has the given code
  */
  async GetEventByCode(eventCode) {
    try {
      const parameters = { code: eventCode }
      const response = await API.graphql(graphqlOperation(QueriesCustom.eventByCode, parameters));
      //console.log(response)
      const result = response.data.eventByCode.items[0]
      return result
    } catch (e) {
      console.warn("Error getting event by code:", e)
      // If some data was recovered, return it anyway as we can work with partial data
      if (e.data) {
        if (e.data.eventByCode) {
          if (e.data.eventByCode.items.length > 0) {
            return e.data.eventByCode.items[0]
          }
        }
      }
      return null
    }
  }

  /**
  * GetCertificatePreview returns a file representing the PDF of a certificate
  * @param  {string} eventId The Id of the event that needs a certificate
  * @return {string} The base64 string of the file
  */
  async GetCertificatePreview(eventId) {
    try {
      const parameters = { eventId: eventId }
      const response = await API.graphql(graphqlOperation(Mutations.getExampleCertificate, parameters));
      //console.log(response)
      const result = response.data.getExampleCertificate.body
      return result
      //const file = await urltoFile(result, `Example_certificate_${eventId}.pdf`, 'application/pdf')
      //return file
      //return ""
    } catch (e) {
      console.warn("Error getting example certificate:", e)
      return null
    }
  }

  /**
  * GetEventBookedAttendeeCount returns a count of the number of live or completed bookings on a given event
  * @param  {string} eventId The Id of the event that needs a certificate
  * @return {number} The number of bookings on a course
  */
  async GetEventBookedAttendeeCount(eventId) {
    try {
      var variables = { id: eventId }
      var response = await API.graphql(graphqlOperation(QueriesCustom.getEventAttendeesCount, variables));
      //console.log(response)
      var result = response.data.getEvent.attendees
      var items = result.items
      var nextToken = result.nextToken
      // If there is a nextToken, get more users
      if (nextToken) {
        variables.nextToken = nextToken
        response = await API.graphql({
          query: QueriesCustom.getEventAttendeesCount,
          variables
        });
        result = response.data.getEvent.attendees
        items = items.concat(result.items)
        nextToken = result.nextToken
      }
      const filteredItems = items.filter((item) => {
        return item.status === BOOKING_STATUS.BOOKED || item.status === BOOKING_STATUS.COMPLETED
      })

      return filteredItems.length
    } catch (err) {
      console.warn("Error getting count of attendees:", err)
      throw err
    }
  }

  /*
  async function urltoFile(url, filename, mimeType) {
    const fetched = await fetch(url)
    const buffer = await fetched.arrayBuffer();
    return new File([buffer], filename, { type: mimeType });
  }
  */

  /**
  * RegisterManualAttendees requests that the manual attendees are added to the system and
  * given a booking + certificate for this event
  * @param  {string} eventId          The ID of the event
  * @param  {array}  manualAttendees  An array of manual users to be created
  * @return {array}                   An array of all the created bookingIDs for manual attendees
  */
  async RegisterManualAttendees(eventId, attendees) {
    const parameters = {
      eventId,
      attendees: JSON.stringify(attendees)
    }
    const response = await API.graphql(graphqlOperation(Mutations.addManualUsersToEvent, parameters));
    //console.log(response)
    const result = response.data.addManualUsersToEvent
    if (parseInt(result.statusCode) > 299) {
      throw result.body
    }
    return result.body
  }

}

export default EventService

<template>
  <v-container class='screen-content' justify-center align-content-center>

    <!-- White box -->
    <v-container class='white-box' xs12>
      <v-layout grid-container login-container>

        <!-- Left column -->
        <v-flex text-center left-shadow>
          <img :src="logoImage" class="logo" />

          <div v-if='!isFISHost' class="welcome">
            <h4>Welcome to MyProPass</h4>
            <span>Your professional passport - Let your career take off</span>
          </div>
          <div v-if='isFISHost' class="welcome">
            <h4>Welcome to your FIS Competency Passport</h4>
          </div>

          <!-- Powered by MyProPass Holder -->
          <div v-if='isFISHost' class="powered-by-mpp">
            <p>Powered by</p>
            <img src="@/assets/images/logo.png" class="powered-by-logo" />
          </div>
        </v-flex>

        <!-- Right column -->
        <v-flex right-shadow class='right-box'>
          <v-flex class="right-container xs12 md8 offset-md-2">
            <v-layout justify-center>
              <h5 style="color: #007AFF;font-size: 30px;margin-top:30px;">Forgot Password</h5>
            </v-layout>
            <br />

            <!-- Before request to reset -->
            <div v-show="!hasCompletedReset">
              <div>
                <div class="form-group">
                  <label for="email">Email</label>
                  <input id="Email" v-model="email" type="email" placeholder="Email" class="col-12 form-control" />
                </div>

              </div>
              <v-layout justify-start style="font-weight:normal;padding: 0 10px">
                <router-link :to="{ name: 'Login' }">
                  <div style="color:#a3a3a3;font-size:12px;">Want to Sign In?</div>
                </router-link>
              </v-layout>

              <div style="text-align: center;">
                <button @click="RequestResetPassword" class="btn btn-primary btn-block" type="submit" style="background-color: #F5740E;border: none;height: 60px;border-radius: 6px;width: 180px;font-size: 16px;font-weight: bold;color:#fff;margin: 30px auto">
                  Reset Password
                </button>
              </div>
            </div>

            <!-- After request to reset -->
            <div v-show="hasCompletedReset">
              <p>Thank you for resetting your password.  If this email address has an account with MyProPass then we will send an email with details on how to reset your password.</p>
              <p>Please click the button below to return to the sign in page.</p>
              <router-link :to="{ name: 'Login' }">
                <button class="btn btn-primary btn-block" type="submit" style="background-color: #F5740E;border: none;height: 60px;border-radius: 6px;width: 180px;font-size: 16px;font-weight: bold;color:#fff;margin: 30px auto">
                  Return to Sign In
                </button>
              </router-link>
            </div>

            <br />
          </v-flex>
        </v-flex>

      </v-layout>
    </v-container>

    <!-- Popover box -->
    <v-container>
      <v-dialog persistent v-model="messageDialog.show" transition="dialog-transition" max-width="350">
        <v-card>
          <v-card-text>
            <v-flex>
              <span style="font-weight:normal;" v-html="messageDialog.message"></span>
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="CloseMessageDialog" small color=rgb(0,171,173)>OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- Loading overlay -->
    <v-container>
      <loading :active.sync="isLoading" :is-full-page="true" loader="dots"></loading>
    </v-container>

  </v-container>
</template>


<script>
  import AuthService from '@/services/auth.service'
  import { isFISHost } from '@/constants/Validations'

  export default {
    name: 'ForgotPassword',
    data() {
      return {
        email: '',
        isLoading: false,
        hasCompletedReset: false,
        messageDialog: {
          show: false,
          message: ""
        },
        isFISHost
      }
    },

    methods: {
      async RequestResetPassword() {
        this.isLoading = true;
        try {
          await AuthService.RequestResetPassword(this.email);
          this.isLoading = false;
          this.hasCompletedReset = true;
        } catch (err) {
          console.warn("Error when requesting password reset;", err)
          // If this is a not authorized exception then we should send the user a temporary password so they can get back using the system
          if (err.code === "NotAuthorizedException" && err.message === "User password cannot be reset in the current state.") {
            await this.RequestNewTemporaryPassword()
            return
          }
          // If the request limit has been reached
          if (err.code === "LimitExceededException") {
            this.ShowRequestLimitExceededException()
            return
          }
          // Show an error box to the user
          this.isLoading = false;
          this.messageDialog.message = "There was an error while trying to reset your password.  Please try again."
          this.messageDialog.show = true
        }
      },

      async RequestNewTemporaryPassword() {
        try {
          const response = await AuthService.ResendUserTemporaryPassword({ email: this.email })
          const result = response.data.resendTemporaryPassword
          console.log("result:", result)
          if (result.statusCode > 299) { throw new Error(result.body)}
          this.hasCompletedReset = true
          this.isLoading = false;
        } catch (err) {
          console.warn("There was an error resending the temporary password!", err)
          this.ShowErrorDialog()
        }
      },

      ShowRequestLimitExceededException() {
        this.messageDialog.message = "There have been too many requests to reset your password.  Please try again in one hour."
        this.messageDialog.show = true
        this.isLoading = false;
      },

      ShowErrorDialog() {
        this.messageDialog.message = "There was an error processing your request. Please try again."
        this.messageDialog.show = true
        this.isLoggingIn = false;
      },

      CloseMessageDialog() {
        this.messageDialog = {
          show: false,
          message: ""
        }
      }
    },

    computed: {
      logoImage: function() {
        let imageName = "logo"
        if (this.isFISHost) { imageName = "logo-fis" }
        return require(`@/assets/images/${imageName}.png`)
      }
    },
  }
</script>


<style scoped>

</style>

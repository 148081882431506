
import { GenerateRandomEventCode } from '../helpers/event.helper'
import { DEFAULT_PRICE_ITEM } from '@/constants/EventPriceConstants'


export const EVENT_STEPPER_STATE = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  COPY: 'COPY',
  VIEWONLY: 'VIEWONLY'
}

export const EVENT_MODE = {
  VIRTUAL: 'VIRTUAL',
  INPERSON: 'INPERSON'
}

export const EVENT_ACCESS_LEVEL = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE'
}

export const EVENT_EXTERNAL_TYPE = {
  FORMAL: 'FORMAL',
  INFORMAL: 'INFORMAL'
}

export const EVENT_TYPE = {
  FREE: 'FREE',
  PAID: 'PAID',
  CONFERENCE: 'CONFERENCE'
}

export const EVENT_STATUS = {
  DRAFT: 'DRAFT',
  LIVE: 'LIVE',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  CERTIFICATESSENT: 'CERTIFICATESSENT',
  DELETED: 'DELETED'
}

export const EVENT_POSSIBLE_ACTIONS = {
  EDITCONTENT: 'EDITCONTENT',
  MANAGEATTENDEES: 'MANAGEATTENDEES',
  ISSUECERTIFICATES: 'ISSUECERTIFICATES',
  VIEWCERTIFICATES: 'VIEWCERTIFICATES',
  COPYEVENT: 'COPYEVENT',
  DELETEEVENT: 'DELETEEVENT'
}

export const EVENT_DURATION = {
  LESSTHANONEHOUR: 'LESSTHANONEHOUR',
  ONETOFOURHOURS: 'ONETOFOURHOURS',
  FOURTOEIGHTHOURS: 'FOURTOEIGHTHOURS',
  EIGHTPLUSHOURS: 'EIGHTPLUSHOURS'
}

export const EVENT_SCHEDULE = {
  WEEKDAYS: 'WEEKDAYS',
  WEEKENDS: 'WEEKENDS'
}

export const EVENT_LOCATION = {
  LESSTHANFIVEMILES: 'LESSTHANFIVEMILES',
  FIVETOTENMILES: 'FIVETOTENMILES',
  TENTOTWENTYMILES: 'TENTOTWENTYMILES',
  TWENTYPLUSMILES: 'TWENTYPLUSMILES'
}

export const EVENT_PRICE = {
  LESSTHANTWOHUNDREDPOUNDS: 'LESSTHANTWOHUNDREDPOUNDS',
  TWOHUNDREDTOTHREEHUNDREDPOUNDS: 'TWOHUNDREDTOTHREEHUNDREDPOUNDS',
  THREEHUNDREDPLUSPOUNDS: 'THREEHUNDREDPLUSPOUNDS'
}

export const ACTION_BUTTONS = {
  BOOK_SESSION: 'BOOK_SESSION',
  CONTACT_PROVIDER: 'CONTACT_PROVIDER',
  NOTIFY_ME: 'NOTIFY_ME',
  ADD_TO_CALENDAR: 'ADD_TO_CALENDAR',
  PRIVATE_EVENT: 'PRIVATE_EVENT'
}

export const EVENT_PROOF_OF_IDENTITY = {
  REQUIRED: 'REQUIRED',
  NOTREQUIRED: 'NOTREQUIRED'
}

const todayDateTime = new Date()
todayDateTime.setSeconds(0)
todayDateTime.setMilliseconds(0)

const tomorrowDate = new Date(todayDateTime)
tomorrowDate.setDate(tomorrowDate.getDate() + 1)

const anHourAhead = new Date(tomorrowDate)
anHourAhead.setHours(anHourAhead.getHours() + 1)

const eventValidityDateTime = new Date(tomorrowDate)
eventValidityDateTime.setDate(tomorrowDate.getDate() + 30)

const threeYearsDateTime = new Date(todayDateTime)
threeYearsDateTime.setFullYear(tomorrowDate.getFullYear() + 3)

export const DEFAULT_EVENT_STRUCTURE = {
  title: '',
  description: '',
  status: EVENT_STATUS.DRAFT,
  code: GenerateRandomEventCode(),
  targetAudience: '',
  isExternal: false,
  externalType: EVENT_EXTERNAL_TYPE.INFORMAL,
  eventType: EVENT_TYPE.FREE,
  eventMode: EVENT_MODE.INPERSON,
  accessLevel: EVENT_ACCESS_LEVEL.PUBLIC,
  possibleActions: [
    EVENT_POSSIBLE_ACTIONS.EDITCONTENT,
    EVENT_POSSIBLE_ACTIONS.MANAGEATTENDEES
  ],
  address: {
    line1: '',
    line2: '',
    line3: '',
    city: '',
    region: '',
    postalCode: '',
    country: '',
  },
  onlineURL: '',
  instructorId: '0',
  instructor: {
    id: '',
    firstName: '',
    lastName: '',
    employer: {
      items: [
        {
          courseInstructorDetails: {
            phone: '',
            email: '',
            aboutText: ''
          }
        }
      ],
    }
  },
  preRequisites: '',
  proofOfIdentity: EVENT_PROOF_OF_IDENTITY.NOTREQUIRED,
  accreditationLogoId: '0',
  accreditationLogo: {
      id: '',
      name: '',
      imageKey: ''
  },
  prices: {
    items: [
      DEFAULT_PRICE_ITEM
    ]
  },
  maximumAttendees: "",
  startDateTime: tomorrowDate.toISOString(),
  endDateTime: anHourAhead.toISOString(),
  eventValidityDateTime: eventValidityDateTime.toISOString(),
  certificatesExpire: false,
  certificateExpiryDateTime: threeYearsDateTime.toISOString(),
  cpdHours: 0,
  attendanceHours: 0,
  latitude: '',
  longitude: '',
  eventLogoId: '0',
  eventLogo: {
    id: '',
    name: '',
    imageKey: ''
  },
  signatureId: '0',
  certificateTemplateId: '0',
  certificateCustomisation: {
    colorBackground: "white",
    colorFontPrimary: "black",
    colorFontSecondary: "darkgrey",
    useCertificateSignerName: false,
    certificateSignerName: ""
  },
  attendees: {
    items: []
  },
  attendeeStatistics: {
    booked: 0,
    onWaitingList: 0,
    attended: 0,
    noShow: 0,
  },
  groupsCanRead: ["USER"],
  groupsCanUpdate: []
}

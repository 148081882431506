
export const NOTIFICATION_TYPES = {
  USER_USER_BOOKED_COURSE: "USER_USER_BOOKED_COURSE",
  USER_USER_CANCELLED_COURSE_BOOKING: "USER_USER_CANCELLED_COURSE_BOOKING",
  USER_USER_JOINS_WAITING_LIST: "USER_USER_JOINS_WAITING_LIST",
  USER_USER_ISSUED_WITH_CERTIFICATE_FROM_CONFERENCE: "USER_USER_ISSUED_WITH_CERTIFICATE_FROM_CONFERENCE",
  USER_USER_ADD_TO_CALENDAR: "USER_USER_ADD_TO_CALENDAR",

  USER_PROVIDER_BOOKED_COURSE: "USER_PROVIDER_BOOKED_COURSE",
  USER_PROVIDER_CANCELLED_COURSE_BOOKING: "USER_PROVIDER_CANCELLED_COURSE_BOOKING",
  USER_PROVIDER_ISSUED_WITH_CERTIFICATE_FROM_CONFERENCE: "USER_PROVIDER_ISSUED_WITH_CERTIFICATE_FROM_CONFERENCE",
  USER_PROVIDER_JOINS_WAITING_LIST: "USER_PROVIDER_JOINS_WAITING_LIST",

  PROVIDER_ATTENDEE_ISSUE_CERTIFICATE: "PROVIDER_ATTENDEE_ISSUE_CERTIFICATE",
  PROVIDER_ATTENDEE_DID_NOT_ATTEND_BOOKED_COURSE: "PROVIDER_ATTENDEE_DID_NOT_ATTEND_BOOKED_COURSE",
  PROVIDER_ATTENDEE_COURSE_LOCATION_CHANGED: "PROVIDER_ATTENDEE_COURSE_LOCATION_CHANGED",
  PROVIDER_ATTENDEE_MOVED_FROM_WAITING_LIST_TO_ATTENDING: "PROVIDER_ATTENDEE_MOVED_FROM_WAITING_LIST_TO_ATTENDING",
  PROVIDER_ATTENDEE_CANCELLED_COURSE_BOOKING: "PROVIDER_ATTENDEE_CANCELLED_COURSE_BOOKING",

  PROVIDER_PROVIDER_CANCELLED_COURSE: "PROVIDER_PROVIDER_CANCELLED_COURSE",
  SYSTEM_USER_MISSED_COURSE: "SYSTEM_USER_MISSED_COURSE",
  /*
  // OLD
  BOOK_COURSE_PROFESSIONAL: "BOOK_COURSE_PROFESSIONAL",
  BOOK_COURSE_PROVIDER: "BOOK_COURSE_PROVIDER",
  CANCEL_BOOKING_PROFESSIONAL: "CANCEL_BOOKING_PROFESSIONAL",
  CANCEL_BOOKING_PROVIDER: "CANCEL_BOOKING_PROVIDER",
  EMAIL_ATTENDEES: "EMAIL_ATTENDEES",
  EMAIL_CERTIFICATE_TO_ATTENDEES: "EMAIL_CERTIFICATE_TO_ATTENDEES",
  EVENT_CODE_PROFESSIONAL: "EVENT_CODE_PROFESSIONAL",
  EVENT_LOCATION_CHANGED: "EVENT_LOCATION_CHANGED",
  CANCEL_COURSE_PROFESSIONAL: "CANCEL_COURSE_PROFESSIONAL",
  CANCEL_COURSE_PROVIDER: "CANCEL_COURSE_PROVIDER",
  EVENT_CODE_PROVIDER: "EVENT_CODE_PROVIDER",
  BOOKING_ATTENDANCE_MISSED_PROFESSIONAL: "BOOKING_ATTENDANCE_MISSED_PROFESSIONAL",
  EVENT_ATTENDEES_CHANGED: "EVENT_ATTENDEES_CHANGED",
  ADD_TO_WAITING_LIST_PROFESSIONAL: "ADD_TO_WAITING_LIST_PROFESSIONAL",
  ADD_TO_WAITING_LIST_PROVIDER: "ADD_TO_WAITING_LIST_PROVIDER",
  CANCEL_BOOKING_WL_PROFESSIONAL: "CANCEL_BOOKING_WL_PROFESSIONAL"
  */
}

// TODO: FUTURE: The last 4 types are not used in the code yet (never were)

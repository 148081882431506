
export function ReplaceStringsInTemplate(certificateHTML, replaceDict) {
  //console.log('Template to update:', certificateHTML)
  console.log('Changes to make:', replaceDict)
  try {
    var returnString = certificateHTML
    for (const property in replaceDict) {
      returnString = returnString.replace(new RegExp(`{{${property}}}`, 'g'), replaceDict[property])
    }
    return returnString
  } catch (err) {
    console.log("Error when adding changes to template;", err)
    throw err
  }
}

/*
export function downloadFile(filePath){
  var link=document.createElement('a');
  link.href = filePath;
  link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
  link.click();
}
*/


const GOOGLE_API_KEY = 'AIzaSyBFTtP5x5yuCEphtc8ZTipKSLCkTGj6Kjk'
//const GOOGLE_MAPS_URL = 'https://maps.googleapis.com/maps/api/place/autocomplete/json'

//var axios = require('axios');

class AddressService {

  // https://cleverbeagle.com/blog/articles/tutorial-how-to-load-third-party-scripts-dynamically-in-javascript
  // https://developers.google.com/maps/documentation/javascript/places

  constructor() {
    //console.log("Make sure you call an init as well")
    this.googleLoaded = false
    this.lastSearchedString = ''
    this.lastSearchedPlaceId = ''
    this.searchResults = { predictions: [] }
    this.placeResult = {}
    this.autocompleteService = function() { console.warn("Init not called on this instance of AddressService") }
    this.placesService = function() { console.warn("Init not called on this instance of AddressService") }

  }

  async init() {
    try {
      //console.log("Init googleLoaded before:", this.googleLoaded)
      await this.loadGoogleMaps((result) => {
        //console.log("Returned from Google loading; result:", result)
        this.googleLoaded = result
      })
      //console.log("Init googleLoaded after:", this.googleLoaded)
    } catch (err) {
      console.warn("Error when loading googleMaps places API", err)
      this.googleLoaded = false
    }
    // Now call the Autocomplete service and save it
    this.autocompleteService = await new window.google.maps.places.AutocompleteService()
    this.placesService = await new window.google.maps.places.PlacesService(document.createElement('div'))
  }


  async loadGoogleMaps(callback) {
    const existingScript = document.getElementById('googleMaps');
    //console.log("Existing script:", existingScript)
    //console.log("callback:", callback)
    if (existingScript && callback) callback(true);

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`;
      script.async = true
      script.defer = true
      script.id = 'googleMaps';
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback(true);
      };
    }
  };

  /**
   * PerformAddressSearch uses the Google places API to find an address
   * @param  {String} addressSearchString  The address string to autocomplete
   * @return {Array}  An array of potential addresses based on the search term
   */
  async PerformAddressSearch(addressSearchString) {
    //console.log("Searching for address:", addressSearchString)
    if(!this.googleLoaded) {
      console.warn("Google maps was not loaded during construction")
      return ''
    }

    // If this is a second click for the same term, don't do it again
    if(this.lastSearchedString === addressSearchString) {
      return this.searchResults
    }
    // Save the searched string
    this.lastSearchedString = addressSearchString

    try {
      // Call the service to export the results
      this.searchResults = await this.autocompleteService.getPlacePredictions({
        input: addressSearchString,
        componentRestrictions: {
          country: 'gb'
        },
        types: ['address'],

      })
      //console.log("searchResults:", this.searchResults)
      // Return the predictions
      return this.searchResults.predictions
    } catch (err) {
      console.warn("There was an error fetching the address;", addressSearchString, err)
      return []
    }
  }


  /*
  AddressDetailsCallback(result) {
    this.placeResult = result
  }
  */

  ProcessPlaceResult() {
    //console.log("Current place result:", this.placeResult)

    const { address_components } = this.placeResult
    var addressLine1 = ''
    var addressLine2 = ''
    var addressLine3 = ''
    var addressCity = ''
    var addressRegion = ''
    var addressPostalCode = ''

    const premiseElements = address_components.filter((element) => {
      return element.types.includes("premise")
    })

    const streeAddressElements = address_components.filter((element) => {
      return element.types.includes("street_address")
    })

    const streetNumberElements = address_components.filter((element) => {
      return element.types.includes("street_number")
    })

    const routeElements = address_components.filter((element) => {
      return element.types.includes("route")
    })

    const localityElements = address_components.filter((element) => {
      return element.types.includes("locality")
    })

    const postalTownElements = address_components.filter((element) => {
      return element.types.includes("postal_town")
    })

    const adminAreaLvlTwoElements = address_components.filter((element) => {
      return element.types.includes("administrative_area_level_2")
    })

    const postalCodeElements = address_components.filter((element) => {
      return element.types.includes("postal_code")
    })

    if (premiseElements.length > 0) {
      addressLine1 = `${premiseElements[0].long_name}`
    }

    if (streetNumberElements.length > 0) {
      if (premiseElements.length > 0) {
        addressLine2 = `${streetNumberElements[0].long_name}`
      } else {
        addressLine1 = `${streetNumberElements[0].long_name}`
      }
    }

    if (streeAddressElements.length > 0) {
      if (premiseElements.length > 0) {
        addressLine2 = `${addressLine2} ${streeAddressElements[0].long_name}`
      } else {
        addressLine1 = `${addressLine1} ${streeAddressElements[0].long_name}`
      }
    }

    if (routeElements.length > 0) {
      if (premiseElements.length > 0) {
        addressLine2 = `${addressLine2} ${routeElements[0].long_name}`
      } else {
        addressLine1 = `${addressLine1} ${routeElements[0].long_name}`
      }
    }

    if (localityElements.length > 0) {
      if (premiseElements.length > 0) {
        addressLine3 = `${localityElements[0].long_name}`
      } else {
        addressLine2 = `${localityElements[0].long_name}`
      }
    }


    if (postalTownElements.length > 0) {
      addressCity = `${postalTownElements[0].long_name}`
    }

    if (adminAreaLvlTwoElements.length > 0) {
      addressRegion = `${adminAreaLvlTwoElements[0].long_name}`
    }

    if (postalCodeElements.length > 0) {
      addressPostalCode = `${postalCodeElements[0].long_name}`
    }

    const returnDict = {
      addressLine1,
      addressLine2,
      addressLine3,
      addressCity,
      addressRegion,
      addressPostalCode,
    }

    //console.log("returnDict:", returnDict)
    return returnDict
  }


  /**
   * PerformAddressSearch uses the Google places API to find an address
   * @param  {String} placeId  The placeId returned with the address search
   * @return {Object}  An object in the same format as the event stepper address
   */
  async GetAddressDetails(placeId) {

    //console.log("GetAddressDetails placeId:", placeId)
    //console.log("GetAddressDetails placesService:", this.placesService)

    if(!this.googleLoaded) {
      console.warn("Google maps was not loaded during construction")
      return {}
    }

    // If this is a second click for the same term, don't do it again
    if (this.lastSearchedPlaceId === placeId) {
      return this.ProcessPlaceResult()
    }
    this.lastSearchedPlaceId = placeId

    try {
      this.placeResult = await new Promise((resolve, reject) => {
        try {
          this.placesService.getDetails(
            {
              placeId,
              fields: ["address_components"]
            },
            async (result) => {
              //console.log("result in callback:", result)
              //this.placeResult = result
              resolve(result)
            }
          )
        } catch (err) {
          console.warn("Error fetching place details;", err)
          reject(err)
        }
      })
      //console.log("placeResult:", this.placeResult)
      return this.ProcessPlaceResult()
    } catch (err) {
      console.warn("There was an error getting the address details;", err)
      return {}
    }
  }

}

export default AddressService

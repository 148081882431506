<template>
  <v-app>
    <v-toolbar color="#F9F9F9" fixed style="padding:0px;">
      <div class="logo-container">
        <img :src="logoImage" class="logo header-bar-logo desktop-logo" />
        <img :src="logoImage" class="header-bar-logo mobile-logo" />
      </div>
      <div class="header-mobile-links">
        <div class="mobile-menu">
          <i class="mobile-menu-link" id="mobile-menu-link" @click="toggleMobileMenuDisplay">
          </i>
          <div class="mobile-menu-links" id="mobile-menu-links" v-if="mobileMenuDisplay">
            <router-link :to="{ name: 'UserLanding' }">
              Home
            </router-link>
            <router-link :to="{ name: 'UserMyEvents', params: { status: USER_EVENTS_PAGE_PARAMETERS.EVENTS } }"
                         exact @click="refreshMenu('events')" v-bind:class="{ 'active': activeMenu ==='events' }">
              Events
            </router-link>
            <router-link :to="{ name: 'UserMyEvents', params: { status: USER_EVENTS_PAGE_PARAMETERS.RECORDS } }"
                         exact @click="refreshMenu('records')" v-bind:class="{ 'active': activeMenu ==='records' }">
              Records
            </router-link>
            <div>
              <v-menu bottom left>
                <a slot="activator" @click="toggleNotifications" class="ma-0" id="mobileNotifications"
                   dark
                   icon>
                  Notifications
                </a>
                <v-card style="width:300px;float: left" elevation="0">
                  <v-card-text style="float: left">
                    <div v-for="notification in notifications" :key="notification.id" class="notification">
                      <div class="notification-img">
                        <v-img v-if="notification.type === NOTIFICATION_TYPES.USER_USER_BOOKED_COURSE" :src="require('../images/32x32-Book.png')"></v-img>
                        <v-img v-if="notification.type === NOTIFICATION_TYPES.USER_PROVIDER_BOOKED_COURSE" :src="require('../images/32x32-Book.png')"></v-img>
                        <v-img v-if="notification.type === NOTIFICATION_TYPES.USER_USER_CANCELLED_COURSE_BOOKING" :src="require('../images/32x32-No.png')"></v-img>
                        <v-img v-if="notification.type === NOTIFICATION_TYPES.USER_PROVIDER_CANCELLED_COURSE_BOOKING" :src="require('../images/32x32-No.png')"></v-img>
                        <v-img v-if="notification.type === NOTIFICATION_TYPES.PROVIDER_ATTENDEE_ISSUE_CERTIFICATE" :src="require('../images/32x32-Email.png')"></v-img>
                        <v-img v-if="notification.type === NOTIFICATION_TYPES.USER_USER_ISSUED_WITH_CERTIFICATE_FROM_CONFERENCE" :src="require('../images/32x32-Events Enter Code.png')"></v-img>
                        <v-img v-if="notification.type === NOTIFICATION_TYPES.PROVIDER_ATTENDEE_COURSE_LOCATION_CHANGED" :src="require('../images/32x32 Location.png')"></v-img>
                        <v-img v-if="notification.type === NOTIFICATION_TYPES.PROVIDER_ATTENDEE_CANCELLED_COURSE_BOOKING" :src="require('../images/32x32-No.png')"></v-img>
                        <v-img v-if="notification.type === NOTIFICATION_TYPES.PROVIDER_PROVIDER_CANCELLED_COURSE" :src="require('../images/32x32-No.png')"></v-img>
                        <v-img v-if="notification.type === NOTIFICATION_TYPES.USER_PROVIDER_ISSUED_WITH_CERTIFICATE_FROM_CONFERENCE" :src="require('../images/32x32-Events Enter Code.png')"></v-img>
                        <v-img v-if="notification.type === NOTIFICATION_TYPES.SYSTEM_USER_MISSED_COURSE" :src="require('../images/32x32-Profile Absent.png')"></v-img>
                        <v-img v-else :src="require('../images/events.png')"></v-img>
                      </div>
                      <div class="notification-content">
                        <span style="font-weight:bold;color:cornflowerblue">{{ notification.title }}</span><br />
                        {{ notification.description }}
                      </div>
                      <div class="notification-date">
                        {{ formatDate(notification.updatedAt) }}
                      </div>
                    </div>
                    <div v-if="notifications.length == 0">
                      <span style="font-weight:bold;color:cornflowerblue">No notifications.</span><br />
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <v-divider></v-divider>
            <router-link :to="{ name: 'UserPersonalDetails' }">
              <div>My Profile</div>
            </router-link>
            <a href="#" @click="changePassword" v-if="!isExternalLogin">
              <div>Change Password</div>
            </a>
            <router-link v-if="providerModeAvailable" :to="{ name: 'ProviderLanding' }">
              <div>Switch To Provider Mode</div>
            </router-link>
            <router-link v-if="adminModeAvailable" :to="{ name: 'AdminLanding' }">
              <div>Switch To Admin Mode</div>
            </router-link>
            <!--
            <a href="#" @click="twoStepAuthentication" v-if="!isExternalLogin">
              <div>Two Step Authentication</div>
            </a>
            -->
            <v-divider></v-divider>
            <a href="#" @click="signOut">
              <div>Log Out</div>
            </a>
          </div>
        </div>
      </div>
      <div class="header-web-links">
        <div class="notifications-link">
          <v-menu bottom left>
            <a slot="activator" @click="toggleNotifications" class="ma-0"
               dark
               icon>
              Notifications
            </a>
            <v-card style="width:300px;float: left" elevation="0">
              <v-card-text style="float: left">
                <div v-for="notification in notifications" :key="notification.id" class="notification">
                  <div class="notification-img">
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.USER_USER_BOOKED_COURSE" :src="require('../images/32x32-Book.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.USER_PROVIDER_BOOKED_COURSE" :src="require('../images/32x32-Book.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.USER_USER_CANCELLED_COURSE_BOOKING" :src="require('../images/32x32-No.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.USER_PROVIDER_CANCELLED_COURSE_BOOKING" :src="require('../images/32x32-No.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.PROVIDER_ATTENDEE_ISSUE_CERTIFICATE" :src="require('../images/32x32-Email.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.USER_USER_ISSUED_WITH_CERTIFICATE_FROM_CONFERENCE" :src="require('../images/32x32-Events Enter Code.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.PROVIDER_ATTENDEE_COURSE_LOCATION_CHANGED" :src="require('../images/32x32 Location.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.PROVIDER_ATTENDEE_CANCELLED_COURSE_BOOKING" :src="require('../images/32x32-No.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.PROVIDER_PROVIDER_CANCELLED_COURSE" :src="require('../images/32x32-No.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.USER_PROVIDER_ISSUED_WITH_CERTIFICATE_FROM_CONFERENCE" :src="require('../images/32x32-Events Enter Code.png')"></v-img>
                    <v-img v-if="notification.type === NOTIFICATION_TYPES.SYSTEM_USER_MISSED_COURSE" :src="require('../images/32x32-Profile Absent.png')"></v-img>
                    <v-img v-else :src="require('../images/events.png')"></v-img>
                  </div>
                  <div class="notification-content">
                    <span style="font-weight:bold;color:cornflowerblue">{{ notification.title }}</span><br />
                    {{ notification.description }}
                  </div>
                  <div class="notification-date">
                    {{ formatDate(notification.updatedAt) }}
                  </div>
                </div>
                <div v-if="notifications.length == 0">
                  <span style="font-weight:bold;color:cornflowerblue">No notifications.</span><br />
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <div class="profile-link" v-bind:class="{ 'active': activeMenu ==='profile' }" exact @click="refreshMenu('profile')">
          <v-menu bottom left>
            <v-btn slot="activator"
                   class="mt-1"
                   dark
                   icon>
              <b style="font-size:11px">Menu</b> <v-icon>arrow_drop_down</v-icon>
            </v-btn>
            <v-card style="width:200px;" class="profile-links" elevation="0" exact @click="refreshMenu('profile')">
              <v-card-text>
                <router-link :to="{ name: 'UserPersonalDetails' }">
                  My Profile
                </router-link>
                <a href="#" @click="changePassword" v-if="!isExternalLogin">
                  Change Password
                </a>
                <router-link v-if="providerModeAvailable" :to="{ name: 'ProviderLanding' }">
                  <div>Switch To Provider Mode</div>
                </router-link>
                <router-link v-if="adminModeAvailable" :to="{ name: 'AdminLanding' }">
                  <div>Switch To Admin Mode</div>
                </router-link>
                <!--
                <a href="#" @click="twoStepAuthentication" v-if="!isExternalLogin">
                  Two Step Authentication
                </a>
                -->
                <a href="#" @click="signOut">
                  <div>Log Out</div>
                </a>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <router-link :to="{ name: 'UserMyEvents', params: { status: USER_EVENTS_PAGE_PARAMETERS.RECORDS } }" class="records-link"
                     exact @click="refreshMenu('records')" v-bind:class="{ 'active': activeMenu ==='records' }">
          Records
        </router-link>
        <router-link :to="{ name: 'UserMyEvents', params: { status: USER_EVENTS_PAGE_PARAMETERS.EVENTS } }" class="events-link"
                     exact @click="refreshMenu('events')" v-bind:class="{ 'active': activeMenu ==='events' }">
          Events
        </router-link>
        <router-link :to="{ name: 'UserLanding' }"
                     exact @click="refreshMenu('home')" class="home-link" v-bind:class="{ 'active': activeMenu ==='home' }">
          Home
        </router-link>
        <!--<span style="color:cornflowerblue;font-size:22px;font-weight:normal;">Welcome! {{userName}}</span>-->
      </div>
    </v-toolbar>
    <v-content class="content">
      <v-container id="PageContainer" fluid pt-0 pr-3>
        <v-layout>
          <v-flex id="PageContent">
            <router-view :key="$route.fullPath" />
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>


<script>
  import { Auth } from 'aws-amplify';
  import * as StoreActions from '@/constants/StoreActions'
  import UserService from '@/services/user.service'
  import EventService from '@/services/event.service'
  import UploadedRecordService from '@/services/uploadedrecord.service'
  import { USER_EVENTS_PAGE_PARAMETERS } from '@/constants/PageParameterConstants'
  import { NOTIFICATION_TYPES } from '@/constants/NotificationConstants'
  import { isFISHost } from '@/constants/Validations'

  export default {
    data() {
      return {
        userName: '',
        isExternalLogin: false,
        mobileMenuDisplay: false,
        showNotifications: false,
        activeMenu: 'home',
        isAnAdmin: false,
        NOTIFICATION_TYPES,
        USER_EVENTS_PAGE_PARAMETERS,
        isFISHost
      }
    },

    methods: {
      async signOut() {
        await Auth.signOut()
        console.log("user is signed out")
        this.$store.commit(StoreActions.RESET_AFTER_LOGOUT);
        this.$router.push({ name: 'Login' });
      },
      changePassword() {
        this.$router.push({ name: 'UserChangePassword', params: { returnToPage: this.$router.currentRoute.name } })
      },
      twoStepAuthentication() {
        window.location.href = process.env.VUE_APP_twoStepAuthenticationPath;
      },
      toggleMobileMenuDisplay() {
        this.mobileMenuDisplay = !this.mobileMenuDisplay;
      },
      refreshMenu(activeMenu) {
        //console.log("refreshMenu", activeMenu);
        if (activeMenu) {
          this.activeMenu = activeMenu;
          return
        }
        this.activeMenu = '';
      },
      toggleNotifications() {
        this.showNotifications = !this.showNotifications
      },
      formatDate(dateTimeString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' }
        return (new Date(dateTimeString)).toLocaleString(options)
      }

    },


    computed: {
      notifications: function() {
        try {
          return this.$store.getters.notifications.items
        } catch (e) {
          console.warn("error getting notifications:", e)
          return []
        }
      },
      providerModeAvailable: function() {
        try {
          const { currentEmployeeRole, employer } = this.$store.getters
          if (!employer.isCourseProvider) { return false }
          const { accessRights } = currentEmployeeRole
          if (accessRights.isAdministrator) { return true }
          if (accessRights.isCourseInstructor) { return true }
          return false
        } catch (e) { return false }
      },
      adminModeAvailable: function() {
        return this.isAnAdmin && (window.location.hostname !== "launch.mypropass.co.uk")
      },
      logoImage: function() {
        let imageName = "logo"
        if (this.isFISHost) { imageName = "logo-fis" }
        return require(`@/assets/images/${imageName}.png`)
      }
    },


    async mounted() {
      // Get the notifications for this user
      const userService = new UserService(this.$store)
      const eventService = new EventService(this.$store)
      const uploadedRecordService = new UploadedRecordService(this.$store)
      try {
        await Promise.all([
          userService.GetNotifications(),
          eventService.GetFutureEventsForUser(),
          uploadedRecordService.GetUploadedEventsForUser()
        ])
      } catch (err) {
        console.warn("Error when getting user first login detail;", err)
      }

      try {
        // Get the current user session
        const user = await Auth.currentAuthenticatedUser();
        const userGroups = user.signInUserSession.accessToken.payload['cognito:groups']
        //console.log("user groups:", userGroups)

        // Set the admin mode boolean
        if (userGroups.includes("ADMINISTRATOR")) {
          this.isAnAdmin = true;
        }
      } catch (err) {
        console.warn("Unable to check for administrator rights", err)
      }

      try {
        // If this user has a saved identityId, do nothing
        if (this.$store.getters.user.identityId) {
          //console.log("This user already has an identityId")
          return
        }

        // Get the current user credentials
        const credentials = await Auth.currentCredentials();
        //console.log('Cognito identity ID:', credentials.identityId);

        // If they don't have an identityId, then save it
        // Update the user details in the database
        const parameters = {
          id: this.$store.getters.cognito.attributes.sub,
          identityId: credentials.identityId
        }
        //console.log("parameters:", parameters)
        await userService.UpdateUserDetails(parameters)
      } catch (err) {
        console.warn("Issue with identity ID:", err)
      }
    }
  }

</script>


<style scoped>

  .content {
    padding-top: 62px !important;
    background-color: rgb(239, 246, 254);
  }

  body {
    background-color: rgb(243,246,248);
  }

  .v-toolbar__extension {
    padding: 0px;
  }

  .v-menu__content {
    overflow: visible !important;
    contain: none;
    border-radius: 5px;
  }

  .profile-links {
    border-radius: 5px;
  }

    .profile-links:before {
      content: '';
      border-bottom-color: #999 !important;
      position: absolute;
      border: solid 10px transparent;
      top: -20px;
      right: 6px;
      z-index: 1;
    }

    .profile-links:after {
      content: '';
      border-bottom-color: #fff !important;
      position: absolute;
      border: solid 10px transparent;
      top: -20px;
      right: 6px;
      z-index: 2;
    }

  .notification {
    padding: 1px 0px 5px;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 5px;
    border-bottom: solid 1px #ccc;
    float: left;
  }

  .notification-img {
    float: left;
    width: 35px;
    height: 35px;
    background: #ccc;
    margin-right: 5px;
    margin-top: 5px;
  }

  .notification-content {
    float: left;
    width: calc(100% - 40px);
    word-wrap: break-word;
  }

  .notification-date {
    clear: both;
    float: left;
    width: 100%;
  }

  .header-bar-logo {
    max-height: 50px;
    max-width: 150px;
  }

</style>


export const en = {
  EVENT_STATUS: {
    DRAFT: 'Draft',
    LIVE: 'Live',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
    DELETED: 'Deleted',
    CERTIFICATESSENT: 'Certificates sent'
  }
}

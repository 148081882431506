
export const PERIOD_OPTIONS = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY"
}

export const METRIC_OPTIONS = {
  DELTA: "DELTA",
  ALLTIMETOTAL: "ALLTIMETOTAL"
}

export const FIXED_STATISTIC_NAMES = [
  "Cognito_Unconfirmed_Users",
  "Cognito_Confirmed_Users",
  "Certificates_Issued_By_User_Not_Confirmed_1_Certificates",
  "Certificates_Issued_By_User_Not_Confirmed_2_to_4_Certificates",
  "Certificates_Issued_By_User_Not_Confirmed_5_to_9_Certificates",
  "Certificates_Issued_By_User_Not_Confirmed_10_to_19_Certificates",
  "Certificates_Issued_By_User_Not_Confirmed_20_to_49_Certificates",
  "Certificates_Issued_By_User_Not_Confirmed_50_to_74_Certificates",
  "Certificates_Issued_By_User_Not_Confirmed_75_to_99_Certificates",
  "Certificates_Issued_By_User_Not_Confirmed_100+_Certificates",
  "Certificates_Issued_By_User_Confirmed_1_Certificates",
  "Certificates_Issued_By_User_Confirmed_2_to_4_Certificates",
  "Certificates_Issued_By_User_Confirmed_5_to_9_Certificates",
  "Certificates_Issued_By_User_Confirmed_10_to_19_Certificates",
  "Certificates_Issued_By_User_Confirmed_20_to_49_Certificates",
  "Certificates_Issued_By_User_Confirmed_50_to_74_Certificates",
  "Certificates_Issued_By_User_Confirmed_75_to_99_Certificates",
  "Certificates_Issued_By_User_Confirmed_100+_Certificates",
]

export const CERTIFICATES_BY_USER_STATISTIC_NAMES = [
  "Certificates_Issued_By_User_Not_Confirmed_1_Certificates",
  "Certificates_Issued_By_User_Not_Confirmed_2_to_4_Certificates",
  "Certificates_Issued_By_User_Not_Confirmed_5_to_9_Certificates",
  "Certificates_Issued_By_User_Not_Confirmed_10_to_19_Certificates",
  "Certificates_Issued_By_User_Not_Confirmed_20_to_49_Certificates",
  "Certificates_Issued_By_User_Not_Confirmed_50_to_74_Certificates",
  "Certificates_Issued_By_User_Not_Confirmed_75_to_99_Certificates",
  "Certificates_Issued_By_User_Not_Confirmed_100+_Certificates",
  "Certificates_Issued_By_User_Confirmed_1_Certificates",
  "Certificates_Issued_By_User_Confirmed_2_to_4_Certificates",
  "Certificates_Issued_By_User_Confirmed_5_to_9_Certificates",
  "Certificates_Issued_By_User_Confirmed_10_to_19_Certificates",
  "Certificates_Issued_By_User_Confirmed_20_to_49_Certificates",
  "Certificates_Issued_By_User_Confirmed_50_to_74_Certificates",
  "Certificates_Issued_By_User_Confirmed_75_to_99_Certificates",
  "Certificates_Issued_By_User_Confirmed_100+_Certificates",
]

export const STATISTICS_REQUIRING_PROVIDER_ID = [
  "Provider_Certificates_Issued_{{ProviderID}}",
  "Provider_Events_Created_{{ProviderID}}",
]

export const STATISTICS_COGNITO = [
  "Cognito_Unconfirmed_Users",
  "Cognito_Confirmed_Users",
]


import { API, Storage, graphqlOperation } from 'aws-amplify';
import * as Mutations from '../graphql/mutations';
import * as UploadedEventRecordQueriesCustom from '../graphql/uploadedEventRecordQueriesCustom';
import * as StoreActions from '../constants/StoreActions'

export const SUCCESS = {
  status: "SUCCESS"
}

export const FAILED = {
  status: "FAILED"
}

class UploadedRecordService {

  constructor($store) {
    this.$store = $store
  }

  /**
  * UploadCertificateFile uploads the chosen file to S3
  * @param  {String} fileName               The fileName that is stored in the database for this image
  * @param  {File} file                     The file object that is being uploaded
  * @param  {Function} progressCallback     A callback function if progress is required
  * @return {string}                        The S3 key string where the file was stored
  */
  async UploadCertificateFile(fileName, file, progressCallback) {
    const fileUploadKey = `${fileName}`
    var uploadParameters = {
      level: 'protected',
      contentType: file.type,
    }
    // Add in the progressCallback if required
    if (progressCallback) {
      uploadParameters.progressCallback = progressCallback
    }
    // Send the file to storage
    const uploadResult = await Storage.put(fileUploadKey, file, uploadParameters);
    console.log(uploadResult)
    return fileName
  }

  /**
  * CreateRecord creates a record in the UploadedEventRecord table
  * @param  {String} id         The id of the record to create
  * @param  {Object} parameters The object containing all the parameters for the event
  * @return {null}
  */
  async CreateRecord(id, parameters) {
    const createParameters = {
      input: {
        ...parameters,
        id
      }
    }
    await API.graphql(graphqlOperation(Mutations.createUploadedEventRecord, createParameters));
  }

  /**
  * UpdateRecord updates a record in the UploadedEventRecord table
  * @param  {String} id         The id of the record to update
  * @param  {Object} parameters The object containing all the parameters to update
  * @return {null}
  */
  async UpdateRecord(id, parameters) {
    const updateParameters = {
      input: {
        ...parameters,
        id
      }
    }
    await API.graphql(graphqlOperation(Mutations.updateUploadedEventRecord, updateParameters));
  }

  /**
  * DeleteRecord removes a record in the UploadedEventRecord table
  * @param  {String} id         The id of the record to delete
  * @return {null}
  */
  async DeleteRecord(id) {
    const deleteParameters = {
      input: {
        id
      }
    }
    await API.graphql(graphqlOperation(Mutations.deleteUploadedEventRecord, deleteParameters));
  }


  // Get the uploaded event records available to this user
  async GetUploadedEventsForUser() {
    //console.log("Fetching uploaded event records for user")
    const parameters = {
      userId: this.$store.getters.user.id,
      sortDirection: 'DESC'
    }
    try {
      const response = await API.graphql(graphqlOperation(UploadedEventRecordQueriesCustom.uploadedEventRecordByUserAndDate, parameters));
      //console.log("GetUploadedEventsForUser response:", response)
      const result = response.data.uploadedEventRecordByUserAndDate
      this.$store.commit(StoreActions.SET_UPLOADED_EVENTS, result);
      return result
    } catch (e) {
      console.warn("Error getting uploaded event list:", e)
      // If some data was recovered, save it anyway
      if (e.data) {
        const result = e.data.uploadedEventRecordByUserAndDate
        this.$store.commit(StoreActions.SET_UPLOADED_EVENTS, result);
        return result
      }
      return {}
    }
  }


  // Gets more uploaded event records for this user
  async ExtendUploadedEventsForUser() {
    //console.log("Extending uploaded event records for user")
    // If there are no more events to fetch, return and do nothing
    if (!this.$store.getters.uploadedevents.nextToken) {
      //console.log("Requested extending uploaded event records for user, but there are no more events")
      return
    }
    const parameters = {
      userId: this.$store.getters.user.id,
      nextToken: this.$store.getters.uploadedevents.nextToken,
      sortDirection: 'DESC'
    }
    try {
      const response = await API.graphql(graphqlOperation(UploadedEventRecordQueriesCustom.uploadedEventRecordByUserAndDate, parameters));
      //console.log("ExtendUploadedEventsForUser response:", response)
      const result = response.data.uploadedEventRecordByUserAndDate
      this.$store.commit(StoreActions.EXTEND_UPLOADED_EVENTS, result);
      return result
    } catch (e) {
      console.warn("Error getting event list:", e)
      // If some data was recovered, save it anyway
      if (e.data) {
        const result = e.data.uploadedEventRecordByUserAndDate
        this.$store.commit(StoreActions.EXTEND_UPLOADED_EVENTS, result);
        return result
      }
      return {}
    }
  }

}

export default UploadedRecordService

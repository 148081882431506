

import { ReplaceStringsInTemplate } from '@/helpers/certificate.helper'
import { ADDRESS_FIELDS, FormatAddressObjectToString } from '@/helpers/event.helper'
import { EVENT_MODE } from '@/constants/EventConstants'
import { REGISTERHTML } from '@/assets/docs/registerHTML.js'

class EventRegisterService {

  constructor() {
    this.bookingList = []
    this.event = {}
  }

  setBookingList(bookingList) {
    console.log("bookingList:", bookingList)
    this.bookingList = bookingList
  }

  setEventDetails(event) {
    console.log("event:", event)
    this.event = event
  }

  generateUserTableEntries() {

    var returnString = ""

    for(const booking of this.bookingList) {
      const userEntry = `<p style='font-size:12px;'>${booking.id}</p><p>${booking.user.firstName}</p><p>${booking.user.lastName}</p><p> </p>`
      returnString = returnString.concat(userEntry)
    }
    return returnString
  }


  generateDocument() {

    // Address fields to show definition
    const addressFieldsToShow = [
      ADDRESS_FIELDS.LINE1, ADDRESS_FIELDS.LINE2, ADDRESS_FIELDS.CITY
    ]

    // Pre processing before creating replace dict
    const startDateObject = new Date(this.event.startDateTime)
    const location = this.event.eventMode === EVENT_MODE.INPERSON ? FormatAddressObjectToString(this.event.address, addressFieldsToShow) : "Online"
    const instructorName = `${this.event.instructor.firstName} ${this.event.instructor.lastName}`

    // Replace all the fields in the template
    const replaceDict = {
      Title: this.event.title,
      StartDate: startDateObject.toLocaleDateString(),
      StartTime: startDateObject.toLocaleTimeString(),
      Location: location,
      InstructorName: instructorName,
      UserTableEntries: this.generateUserTableEntries()
    }

    // Create the HTML to show in a new print window
    const populatedHTML = ReplaceStringsInTemplate(REGISTERHTML, replaceDict)

    // Open a new window and Create a PDF from the HTML
    let mywindow = window.open('', 'PRINT', 'height=700,width=500,top=100,left=150');
    mywindow.document.write(populatedHTML);
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    return
  }

}

export default EventRegisterService

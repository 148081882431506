<template>
  <v-container>
    <loading :active.sync="uploading" :is-full-page="fullPage" loader="dots"></loading>
    <v-layout ma-0 pa-0>
      <v-flex xs12>
        <v-form ref="form" class="container-rounded"
                lazy-validation>
          <v-layout>
            <v-flex xs10 md6>
              <h6 class="page-header pt-2 mb-0">Upload Your Certificate</h6>
              </v-flex>
          </v-layout>
          <hr />
          <v-layout>
            <v-flex xs10 md12>
              <form id="uploadCertificate" method="post" class="form-horizontal" enctype="multipart/form-data">
                <v-flex class="md6">

                  <div class="form-row" style="font-weight:normal;">
                    <label class="col-sm-5 control-label" for="title">Course Title</label>
                    <input type="text" class="form-control col-sm-7" id="title" name="title" v-model="record.title">
                    <div v-if='record.title.length !== 0' class="remaining-characters">
                      Remaining Characters: {{remainingCharactersTitle}}
                    </div>
                    <div v-if="submitted && $v.record.title.$error" class="text-danger">
                      <small v-if="!$v.record.title.required">Course Title is required</small>
                      <small v-if="!$v.record.title.maxLength">Training Name should be less than 100 characters</small>
                    </div>
                  </div>

                  <div class="form-row" style="font-weight:normal;">
                    <label class="col-sm-5 control-label" for="description">Course Description</label>
                    <textarea rows="5" class="form-control col-sm-7" id="description" name="description" v-model="record.description" />
                    <div v-if='record.description.length !== 0' class="remaining-characters">
                      Remaining Characters: {{remainingCharactersDescription}}
                    </div>
                    <div v-if="submitted && $v.record.description.$error" class="text-danger">
                      <small v-if="!$v.record.description.required">Course Description is required</small>
                      <small v-if="!$v.record.description.maxLength">Training Name should be less than 2000 characters</small>
                    </div>
                  </div>

                  <div class="form-row" style="font-weight:normal;">
                    <label class="col-sm-5 control-label" for="providerName">Name of Provider</label>
                    <input type="text" class="form-control col-sm-7" id="providerName" name="providerName" v-model="record.providerName">
                    <div v-if="submitted && $v.record.providerName.$error" class="text-danger">
                      <small v-if="!$v.record.providerName.required">Provider Name is required</small>
                      <small v-if="!$v.record.providerName.maxLength">Provider Name should be less than 200 characters</small>
                    </div>
                  </div>

                  <div class="form-row" style="font-weight:normal;">
                    <label class="col-sm-5 control-label" for="location">Location</label>
                    <input type="text" class="form-control col-sm-7" id="location" name="location" v-model="record.location">
                    <div v-if="submitted && $v.record.location.$error" class="text-danger">
                      <small v-if="!$v.record.location.required">Location is required</small>
                      <small v-if="!$v.record.location.maxLength">Location should be less than 200 characters</small>
                    </div>
                  </div>

                  <div class="form-row" style="font-weight:normal;">
                    <label class="col-sm-5 control-label" for="cpdHours">CPD Hours</label>
                    <input type="text" class="form-control col-sm-7" id="cpdHours" name="cpdHours" v-model="record.cpdHours">
                    <div v-if="submitted && $v.record.cpdHours.$error" class="text-danger">
                      <small v-if="!$v.record.cpdHours.required">Location is required</small>
                    </div>
                  </div>

                  <div class="form-row" style="font-weight:normal;">
                    <label class="col-sm-5 control-label">Date of Training Completion</label>
                    <input type='date' class='form-control col-sm-7' v-model='record.eventDateTime' :max="maxDatePossible" />
                    <div v-if="submitted && $v.record.eventDateTime.$error" class="text-danger">
                      <small v-if="!$v.record.eventDateTime.required">Date Of Training Completion is required</small>
                      <small v-if="!$v.record.eventDateTime.pastDateTime">Date Of Training Completion should be less than current date time.</small>
                    </div>
                  </div>

                  <div class="form-row" style="font-weight:normal;">
                    <label class="col-sm-5 control-label">Certificate Expiry</label>
                    <input type='date' class='form-control col-sm-7' v-model='record.expiryDateTime' />
                    <div v-if="submitted && $v.record.expiryDateTime.$error" class="text-danger">
                      <!-- <small v-if="!$v.record.expiryDateTime.pastDateTime">Date Of Training Completion should be less than current date time.</small>-->
                    </div>
                  </div>

                  <div class="form-row" style="font-weight:normal;">
                    <label class="col-sm-5 control-label" for="certificate">Upload Certificate</label>
                    <input v-if="chosenImageChangeRequested" type='file' accept="image/*, .pdf, .PDF" @change="certificateFileChosen($event)">
                    <v-btn color="rgb(0,171,173)" class="change-cert-button" v-if="!chosenImageChangeRequested" @click='toggleChangeImageRequested'>Change uploaded certificate</v-btn>
                    <div v-if="submitted && $v.chosenImage.$error" class="text-danger">
                      <small v-if="!$v.chosenImage.customRequired">Certificate is required</small>
                    </div>
                  </div>

                </v-flex>
                <div class="text-right pull-right text-danger" v-if="submitted && $v.record.$invalid">
                  <small>Please review your input. Errors are highlighted in red.</small>
                </div>
                <br />
                <div>
                  <div class="text-right pull-right">
                    <v-btn color="rgb(254,138,125)" small @click="back">
                      Back
                    </v-btn>
                    <v-btn color="rgb(0,171,173)" small @click="uploadCertificate" style="float:right">
                      Submit to training records
                    </v-btn>
                  </div>
                </div>
              </form>
            </v-flex>

            <v-dialog persistent v-model="messageDialog.show" transition="dialog-transition" max-width="450">
              <v-card>
                <v-card-text>
                  <v-flex>
                    <div id="message" style="font-weight:normal;">{{ messageDialog.message }}</div>
                  </v-flex>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="closeMessageDialog" small color=rgb(0,171,173)>OK</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>


          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>


<script>

  import { validationMixin } from 'vuelidate'
  import { required, maxLength } from 'vuelidate/lib/validators'
  import UploadedRecordService from '../services/uploadedrecord.service'

  import { USER_EVENTS_PAGE_PARAMETERS, USER_EDIT_UPLOADED_EVENT_RECORD_STATUS } from '../constants/PageParameterConstants'
  import { pastDateTime } from '../constants/Validations'
  import { v4 as uuidv4 } from 'uuid';

  export default {

    mixins: [validationMixin],
    validations: {
      record: {
        title: { required, maxLength: maxLength(100) },
        description: { required, maxLength: maxLength(2000) },
        providerName: { required, maxLength: maxLength(200) },
        location: { required, maxLength: maxLength(200) },
        eventDateTime: { required, pastDateTime },
        expiryDateTime: { },
        cpdHours: { required },
      },
      chosenImage: {
        customRequired: function() {
          try {
            if (!this.chosenImageChangeRequested) { return true }
            return !!this.chosenImage
          } catch (err) {
            return false
          }
        },
      },
    },

    data() {
      return {
        submitted: false,
        uploading: false,
        fullPage: true,
        record: {
          id: '',
          title: '',
          description: '',
          providerName: '',
          location: '',
          eventDateTime: (new Date()).toISOString().split('T')[0],
          expiryDateTime: '',
          cpdHours: '0',
        },
        chosenImage: undefined,
        chosenImageChangeRequested: true,
        messageDialog: {
          show: false,
          message: ''
        }
      }
    },

    watch: {
      'record': function(newVal) {
        console.log("WATCHER: record changed to:", newVal)
      },
      'record.eventDateTime': function(newVal) {
        console.log("WATCHER: eventDateTime changed to:", newVal)
      },
      'record.expiryDateTime': function(newVal) {
        console.log("WATCHER: expiryDateTime changed to:", newVal)
      },
      'chosenImage': function(newVal) {
        console.log("WATCHER: chosenImage changed to:", newVal)
      }
    },

    components: {},


    methods: {

      back() {
        this.$router.push({ name: 'UserMyEvents', params: { status: USER_EVENTS_PAGE_PARAMETERS.RECORDS }, query: this.$route.query });
      },

      closeMessageDialog() {
        console.log("Closing the message dialog")
        this.messageDialog.message = ''
        this.messageDialog.show = false
        if (this.submitted) {
          this.back()
        }
      },

      certificateFileChosen(event) {
        console.log("File was chosen:", event)
        var files = event.target.files || event.dataTransfer.files;
        if (!files.length) {
          console.log("No files were selected")
          return
        }
        console.log("files are:", files)
        this.chosenImage = files[0]

        // Check if the image is too large to display.
        const fileSize = this.chosenImage.size
        if (fileSize > (10 * 1024 * 1024)) {
          this.messageDialog.message = `The chosen image is too large at (${(fileSize / 1024).toFixed(0)} KB).  The maximum size is 10,000 KB (10 MB).  Please make the certificate smaller and attempt to upload again.`
          this.messageDialog.show = true
          this.chosenImage = undefined
          return;
        }
      },

      async uploadCertificate() {

        this.submitted = true;
        this.uploading = true;
        // stop here if form is invalid
        this.$v.$touch();
        console.log(this.$v);
        if (this.$v.$invalid) {
          this.uploading = false;
          return;
        }

        try {
          // If this is an update, use the update function
          if (this.$route.params.status === USER_EDIT_UPLOADED_EVENT_RECORD_STATUS.UPDATE) {
            await this.updateCertificate()
          }
          // If this is a create, use the create function
          if (this.$route.params.status === USER_EDIT_UPLOADED_EVENT_RECORD_STATUS.CREATE) {
            await this.createCertificate()
          }
        } catch (err) {
          // Error displays are handled in their respective functions, just return here
          return
        }

        // On successful completion of the upload
        this.messageDialog.show = true
        this.uploading = false
      },

      async createCertificate() {
        const recordId = uuidv4()
        const splitName = this.chosenImage.name.split(".")
        const createParameters = {
          ...this.record,
          fileName: `${recordId}.${splitName[splitName.length - 1]}`,
          cpdHours: parseFloat(this.record.cpdHours),
          userId: this.$store.getters.user.id
        }
        console.log(createParameters)
        // Create the service to upload this record
        const uploadedRecordService = new UploadedRecordService(this.$store)
        try {
          await Promise.all([
            uploadedRecordService.CreateRecord(recordId, createParameters),
            uploadedRecordService.UploadCertificateFile(createParameters.fileName, this.chosenImage)
          ])
          await uploadedRecordService.GetUploadedEventsForUser()
        } catch (err) {
          // On failure of the upload
          console.warn("Unable to create record:", err)
          this.messageDialog.message = 'There was an error uploading your record.  Please try again'
          this.submitted = false
          this.messageDialog.show = true
          this.uploading = false
          throw err
        }
        this.messageDialog.message = 'Record successfully created'
        return
      },

      async updateCertificate() {
        // If this is an update, use the existing ID
        const recordId = this.record.id
        var parameters = {
          ...this.record,
          cpdHours: parseFloat(this.record.cpdHours),
          userId: this.$store.getters.user.id
        }
        // If a new file was chosen, ensure the name is correct
        if (this.chosenImage) {
          const splitName = this.chosenImage.name.split(".")
          parameters.fileName = `${recordId}.${splitName[splitName.length - 1]}`
        }
        // Print the parameters
        console.log(parameters)
        // Create the service to update this record
        const uploadedRecordService = new UploadedRecordService(this.$store)
        try {
          await uploadedRecordService.UpdateRecord(recordId, parameters)
          // If the person changed their image
          if (this.chosenImage) {
            await uploadedRecordService.UploadCertificateFile(parameters.fileName, this.chosenImage)
          }
          // Get the latest record list for the user
          await uploadedRecordService.GetUploadedEventsForUser()
        } catch (err) {
          // On failure of the upload
          console.warn("Unable to update record:", err)
          this.messageDialog.message = 'There was an error updating your record.  Please try again'
          this.submitted = false
          this.messageDialog.show = true
          this.uploading = false
          throw err
        }
        this.messageDialog.message = 'Record successfully updated'
        return
      },
      toggleChangeImageRequested() {
        this.chosenImageChangeRequested = !this.chosenImageChangeRequested
      }

    },

    computed: {
      remainingCharactersTitle: function() {
        return 100 - this.record.title.length
      },
      remainingCharactersDescription: function() {
        return 2000 - this.record.description.length
      },
      maxDatePossible: function() {
        const today = new Date()
        return today.toISOString().split('T')[0]
      }
    },

    mounted() {
      if (this.$route.params.status === USER_EDIT_UPLOADED_EVENT_RECORD_STATUS.UPDATE) {
        const { userRecord } = this.$route.params.booking
        console.log("User record:", userRecord)
        this.record = {
          ...userRecord
        }
        this.chosenImageChangeRequested = false
      }
    }

  }
</script>
<style scoped>
  .v-btn {
    text-decoration: none;
    text-transform: capitalize;
  }

  .container {
    position: relative;
    border-radius: 5px;
    padding: 20px 0 30px 0;
  }

  .Upload_Certificate_Text {
    opacity: 1;
    box-sizing: border-box;
    white-space: nowrap;
    text-align: left;
    font-family: Arial;
    font-style: normal;
    font-size: 20px;
    color: rgba(254,138,125,1);
  }

  body {
    background-color: rgba(243,246,248,1);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  .container-rounded {
    border-radius: 10px;
    background-color: #FFFFFF;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0px;
    min-height: 425px;
  }
  .form-row {
    margin-bottom: 10px !important;
    width: 100%;
  }
  input[type="text"] {
    width: 100% !important;
    border-radius: 4px;
  }

  .text-danger {
    padding-left: 5px;
  }

  .remaining-characters {
    text-align: right;
    width: 100%;
    font-size: 0.8em;
  }

  .change-cert-button > div {
    color: black;
  }

</style>

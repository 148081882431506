
import { FormatDuration, DESIRED_DURATIONS } from '@/helpers/duration.helper'

export const GenerateExperienceCSV = (filteredRecords) => {

  const headerRow = "data:text/csv;charset=utf-8,Employee Name,Employee Email,Title,Description,Experience Start Date,Experience End Date,Duration,Issue Date,Issuing Organisation,Certificate Type\r\n"
  var csvContent = headerRow
  // Extract the content from each experience record
  const contentRows = filteredRecords.map((record) => {
    const startDateObject = new Date(record.startDateTime)
    const endDateObject = new Date(record.endDateTime)
    const issueDateObject = new Date(record.issueDateTime)
    const issuedBy = record.provider.name
    const formattedEventDuration = ExperienceDurationInMinutes(startDateObject, endDateObject)
    return `"${record.user.firstName} ${record.user.lastName}","${record.user.emailAddress ? record.user.emailAddress : 'account not confirmed' }","${record.title}","${record.description.replace(/(<([^>]+)>)/gi, "")}","${startDateObject.toLocaleDateString()}","${endDateObject.toLocaleDateString()}","${formattedEventDuration}","${issueDateObject.toLocaleDateString()}","${issuedBy}","Experience"`
  })
  //console.log("contentRows:", contentRows)

  // Join all the rows to the heading
  for (const row of contentRows) {
    csvContent = csvContent + row + "\r\n"
  }
  //console.log("csvContent:", csvContent)

  // Create the label for the file
  const label = `Experience Records`

  var encodedUri = encodeURI(csvContent);
  // Encode the octothorpes because they cause the output to be truncated
  encodedUri = encodedUri.replace(/#/g, "%23")
  //console.log("encodedUri:", encodedUri)
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${label}.csv`);
  document.body.appendChild(link); // Required for FF
  link.click(); // This will download the data file
  document.body.removeChild(link);
}

function ExperienceDurationInMinutes(startDateObject, endDateObject) {
  try {
    const minutes = (endDateObject - startDateObject) / ( 1000 * 60 )
    return FormatDuration(minutes, DESIRED_DURATIONS.Days)
  } catch (err) {
    console.warn("Experience provided without start or end time;", err)
    return "-"
  }
}

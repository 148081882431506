

import { EVENT_TYPE, EVENT_MODE } from './EventConstants'

export const containsUpperCaseCharacter = (value) => {
  const pattern = new RegExp(/[A-Z]/gm)
  return pattern.test(value)
}

export const containsLowerCaseCharacter = (value) => {
  const pattern = new RegExp(/[a-z]/gm)
  return pattern.test(value)
}

export const containsNumericCharacter = (value) => {
  const pattern = new RegExp(/[0-9]/gm)
  return pattern.test(value)
}

export const containsSpecialCharacter = (value) => {
  // eslint-disable-next-line
  const pattern = new RegExp(/[\*\.\!\@\#\$\%\^\&\(\)\{\}\[\]\:\"\;\'\<\>\,\.\?\/\~\`\_\+\-\=\|\\]/gm)
  return pattern.test(value)
}

export const priceRequired = (value, vm) => {
  if (vm.eventType !== EVENT_TYPE.PAID) { return true }
  const convertedFloat = parseFloat(value)
  if (isNaN(convertedFloat)) { return false }
  if (convertedFloat > 0) { return true }
  return false;
}

export const addressRequired = (value, vm) => {
  if (vm.eventMode === EVENT_MODE.VIRTUAL) { return true }
  if (value && value.trim().length > 0) { return true }
  return false;
}

export const onlineURLRequired = (value, vm) => {
  if (vm.eventMode !== EVENT_MODE.VIRTUAL) { return true }
  if (value && value.trim().length > 0) { return true }
  return false;
}

export const attendeesRequired = (value) => {
  if (!isNaN(value) && parseInt(value) > 0) { return true }
  return false;
}

export const validDate = (value) => {
  return (new Date(value)).toString() !== "Invalid Date"
}

export const validTime = (value) => {
  try {
    const timeString = (new Date(value)).toLocaleTimeString()
    return timeString.length > 0 && timeString !== null
  } catch (err) {
    console.log("Error checking valid time;", err)
    return false
  }
}


export const pastDateTime = (value) => {
  try {
    const currentDateTime = new Date()
    const oldDateTime = new Date(value)
    return oldDateTime < currentDateTime
  } catch (err) {
    console.warn("Error checking past date time;", err)
    return false
  }
}


export const todayOrEarlierDateTime = (value) => {
  try {
    const currentDateTime = new Date()
    currentDateTime.setHours(23,59,59,999)
    const oldDateTime = new Date(value)
    return oldDateTime <= currentDateTime
  } catch (err) {
    console.warn("Error checking today or earlier date time;", err)
    return false
  }
}


export const FutureDateTime = (value) => {
  try {
    //console.log("FutureDateTime: given:", value, "after conversion:", new Date(value), "current:", new Date())
    return new Date(value) > new Date()
  } catch (err) {
    return false
  }
}

export const DateOneBeforeDateTwo = (dateOne, dateTwo, minDifferenceInMinutes) => {
  try {
    // Create Date Objects
    const dateOneDateObject = new Date(dateOne)
    const dateTwoDateObject = new Date(dateTwo)
    // Increase the minutes of the first object by the provided value
    const differenceToAdd = minDifferenceInMinutes ? minDifferenceInMinutes : 0
    dateOneDateObject.setMinutes(dateOneDateObject.getMinutes() + differenceToAdd)
    // Return the check that object one is still before object 2
    return dateOneDateObject < dateTwoDateObject
  } catch (err) {
    console.warn("DateOneBeforeDateTwo: Error when comparing two dates", err)
    return true
  }
}

export function validCSSColour(colourString) {
  //console.log("validCSSColour:", colourString)
  //eslint-disable-next-line
  const regex = /#[a-zA-Z0-9]{6}|rgb\((?:\s*\d+\s*,){2}\s*[\d]+\)|rgba\((\s*\d+\s*,){3}[\d\.]+\)|hsl\(\s*\d+\s*(\s*\,\s*\d+\%){2}\)|hsla\(\s*\d+(\s*,\s*\d+\s*\%){2}\s*\,\s*[\d\.]+\)|(black|silver|gray|whitesmoke|maroon|red|purple|fuchsia|green|lime|olivedrab|yellow|navy|blue|teal|aquamarine|orange|aliceblue|antiquewhite|aqua|azure|beige|bisque|blanchedalmond|blueviolet|brown|burlywood|cadetblue|chartreuse|chocolate|coral|cornflowerblue|cornsilk|crimson|darkblue|darkcyan|darkgoldenrod|darkgray|darkgreen|darkgrey|darkkhaki|darkmagenta|darkolivegreen|darkorange|darkorchid|darkred|darksalmon|darkseagreen|darkslateblue|darkslategray|darkslategrey|darkturquoise|darkviolet|deeppink|deepskyblue|dimgray|dimgrey|dodgerblue|firebrick|floralwhite|forestgreen|gainsboro|ghostwhite|goldenrod|gold|greenyellow|grey|honeydew|hotpink|indianred|indigo|ivory|khaki|lavenderblush|lavender|lawngreen|lemonchiffon|lightblue|lightcoral|lightcyan|lightgoldenrodyellow|lightgray|lightgreen|lightgrey|lightpink|lightsalmon|lightseagreen|lightskyblue|lightslategray|lightslategrey|lightsteelblue|lightyellow|limegreen|linen|mediumaquamarine|mediumblue|mediumorchid|mediumpurple|mediumseagreen|mediumslateblue|mediumspringgreen|mediumturquoise|mediumvioletred|midnightblue|mintcream|mistyrose|moccasin|navajowhite|oldlace|olive|orangered|orchid|palegoldenrod|palegreen|paleturquoise|palevioletred|papayawhip|peachpuff|peru|pink|plum|powderblue|rosybrown|royalblue|saddlebrown|salmon|sandybrown|seagreen|seashell|sienna|skyblue|slateblue|slategray|slategrey|snow|springgreen|steelblue|tan|thistle|tomato|turquoise|violet|wheat|white|yellowgreen|rebeccapurple)/
  //console.log(regex.test(colourString))
  return regex.test(colourString)
}

export const isValidCertificateId = (certificateId, step) => {
  //console.log("isValidCertificateId:", certificateId, step)
  if (step !== 4) { return true }
  if (!certificateId) { return false }
  if (certificateId === "0") { return false }
  if (certificateId === 0) { return false }
  return true
}


export const isLocalhost = window.location.hostname === "localhost"
export const isDevhost = window.location.hostname === "dev.mypropass.co.uk"
export const isFISHost = window.location.hostname === "fis.mypropass.co.uk" || window.location.hostname === "fisdemo.mypropass.co.uk"
export const isStaginghost = window.location.hostname === "staging.mypropass.co.uk"
export const isDemohost = window.location.hostname === "demo.mypropass.co.uk"

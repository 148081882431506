
import { API, Storage, graphqlOperation } from 'aws-amplify';
//import { Storage } from 'aws-amplify';
//import * as Queries from '../graphql/queries';
import * as QueriesCustom from '@/graphql/queriesCustom';
import * as Mutations from '@/graphql/mutations';
import * as StoreActions from '@/constants/StoreActions'
import { v4 as uuidv4 } from 'uuid';
import lodash from 'lodash'
import store from '@/store'
import EmployerGroup from '@/models/employergroup.model';

//import UserService from './user.service'

// TODO: FUTURE: Make these functions do all the store stuff here
class EmployerService {


  constructor() {
    this.$store = store
  }

  async GetCompanyLogoSignedURL(employer) {
    // First, check that the company has a logo
    var logoKeyString
    try {
      logoKeyString = employer.logo.imageKey
    } catch (e) {
      console.warn("Error getting company logo:", e)
      return process.env.VUE_APP_placeholder_company_logo_url
    }
    // Check that the logo isn't blank
    if (logoKeyString === '') {
      return process.env.VUE_APP_placeholder_company_logo_url
    }
    // Build the parameters for the signed URL
    var parameters = {
      expires: 3600
    }
    // Fetch the link
    const result = await Storage.get(logoKeyString, parameters);
    //console.log(result)
    return result
  }

  async GetDetailsForContactDetailsScreen(employerId) {
    try {
      const parameters = { id: employerId }
      const response = await API.graphql(graphqlOperation(QueriesCustom.getEmployer, parameters));
      //const userService = new UserService(this.$store)
      //await userService.GetDetails()
      const result = response.data.getEmployer
      await this.$store.commit(StoreActions.SET_EMPLOYER, result);
      return result
    } catch (e) {
      console.warn("Error getting employer details:", e)
      // If some data was recovered, return it anyway as we can work with partial data
      if (e.data) {
        const result = e.data.getEmployer
        return result
      }
      return {}
    }
  }

  /*
  // Original Add Employee that automatically uses the EmployeeRole model
  // Superceded by the new AddPendingEmployee function that uses the PendingEmployee table
  async AddEmployee(userId, employerId, userParameters) {
    try {
      const parameters = {
        input: {
          id: uuidv4(),
          userId,
          employerId,
          ...userParameters,
          groupsCanRead: ["User"],
          groupsCanUpdate: [`${userId}`],
          groupsFullControl: [`${employerId}_ADMINISTRATOR`],
        }
      }
      const response = await API.graphql(graphqlOperation(Mutations.createEmployeeRole, parameters));
      //console.log(response)
      const result = response.data.createEmployeeRole
      return result
    } catch (e) {
      console.warn("Error adding employee:", e)
      // If some data was recovered, return it anyway as we can work with partial data
      if (e.data) {
        const result = e.data.createEmployeeRole
        return result
      }
      return {}
    }
  }
  */


  async AddPendingEmployee(userId, employerId, userParameters, groupsIdsToAllocate) {
    try {
      const pendingEmployeeId = uuidv4()
      const parameters = {
        input: {
          id: pendingEmployeeId,
          userId,
          employerId,
          ...userParameters,
          groupsCanRead: ["User"],
          groupsCanUpdate: [],
          groupsFullControl: [
            `${userId}`,
            `${employerId}_ADMINISTRATOR`
          ],
        }
      }
      console.log("Adding pending employee with parameters:", parameters)
      const response = await API.graphql(graphqlOperation(Mutations.createPendingEmployee, parameters));
      //console.log(response)
      const resultCreatePendingEmployee = response.data.createPendingEmployee
      // Because the pending employee ID will become the employee ID, we can use it to allocate the groups
      for (let groupId of groupsIdsToAllocate) {
        const parameters = {
          input: {
            id: uuidv4(),
            employeeId: pendingEmployeeId,
            groupId: groupId,
            groupsCanRead: ["USER"],
            groupsCanUpdate: [],
            groupsFullControl: [`${this.$store.getters.employer.id}_ADMINISTRATOR`],
          }
        }
        console.log("Adding employee group with parameters:", parameters)
        const response = await API.graphql(graphqlOperation(Mutations.createEmployeeGroupAllocation,  parameters));
        console.log("Group Creation:", response.data.createEmployeeGroupAllocation)
      }

      return resultCreatePendingEmployee
    } catch (e) {
      console.warn("Error adding employee:", e)
      // If some data was recovered, return it anyway as we can work with partial data
      if (e.data) {
        const result = e.data.createPendingEmployee
        return result
      }
      return {}
    }
  }

  async RemoveEmployee(employeeRoleId) {
    try {
      const parameters = {
        input: {
          id: employeeRoleId,
        }
      }
      const response = await API.graphql(graphqlOperation(Mutations.deleteEmployeeRole, parameters));
      //console.log(response)
      const result = response.data.deleteEmployeeRole
      return result
    } catch (e) {
      console.warn("Error deleting employee:", e)
      // If some data was recovered, return it anyway as we can work with partial data
      if (e.data) {
        const result = e.data.deleteEmployeeRole
        return result
      }
      return {}
    }
  }

  async RemovePendingEmployee(pendingEmployeeId) {
    try {
      const parameters = {
        input: {
          id: pendingEmployeeId,
        }
      }
      const response = await API.graphql(graphqlOperation(Mutations.deletePendingEmployee, parameters));
      //console.log(response)
      const result = response.data.deletePendingEmployee
      return result
    } catch (e) {
      console.warn("Error deleting pending employee:", e)
      // If some data was recovered, return it anyway as we can work with partial data
      if (e.data) {
        const result = e.data.deletePendingEmployee
        return result
      }
      return {}
    }
  }

  async UpdateEmployee(employee) {
    try {
      const parameters = {
        input: employee
      }
      delete parameters.input.user
      const response = await API.graphql(graphqlOperation(Mutations.updateEmployeeRole, parameters));
      //console.log(response)
      const result = response.data.updateEmployeeRole
      return result
    } catch (e) {
      console.warn("Error updating employee:", e)
      // If some data was recovered, return it anyway as we can work with partial data
      if (e.data) {
        const result = e.data.updateEmployeeRole
        return result
      }
      return {}
    }
  }

  async UpdatePendingEmployee(pendingEmployee) {
    try {
      const parameters = {
        input: pendingEmployee
      }
      delete parameters.input.user
      const response = await API.graphql(graphqlOperation(Mutations.updatePendingEmployee, parameters));
      //console.log(response)
      const result = response.data.updatePendingEmployee
      return result
    } catch (e) {
      console.warn("Error updating pending employee:", e)
      // If some data was recovered, return it anyway as we can work with partial data
      if (e.data) {
        const result = e.data.updatePendingEmployee
        return result
      }
      return {}
    }
  }


  async UpdateAndMergeEmployerDetails(newDetails, originalDetails) {
    //console.log(originalDetails)
    //console.log(newDetails)
    // Merge the new into the old
    lodash.merge(originalDetails, newDetails)

    // Remove any fetched details that cannot be updated
    delete originalDetails.teamMembers
    delete originalDetails.eventLogos
    delete originalDetails.accountSubscription
    delete originalDetails.accreditationLogos
    delete originalDetails.pendingTeamMembers

    // Send the update to the server
    try {
      const updateParameters = {
        input: originalDetails
      }
      //console.log(updateParameters)
      const response = await API.graphql(graphqlOperation(Mutations.updateEmployer, updateParameters));
      //console.log(response.data.updateEmployer)
      const result = response.data.updateEmployer
      return result
    } catch (e) {
      console.warn("Error updating employer details:", e)
      // If some data was recovered, return it anyway as we can work with partial data
      if (e.data) {
        const result = e.data.updateEmployer
        return result
      }
      return {}
    }
  }

  async UpdateDetails(parameters){
    // Send the update to the server
    try {
      const updateParameters = {
        input: parameters
      }
      const response = await API.graphql(graphqlOperation(Mutations.updateEmployer, updateParameters));
      //console.log(response.data.updateEmployer)
      const result = response.data.updateEmployer
      return result
    } catch (e) {
      console.warn("Error updating employer details:", e)
      // If some data was recovered, return it anyway as we can work with partial data
      if (e.data) {
        const result = e.data.updateEmployer
        return result
      }
      return {}
    }
  }

  async UpdateLogo(logo) {
    this.$store.commit(StoreActions.SET_EMPLOYER_LOGO, logo)
  }

  async GetAllEmployeesAndTrainingRecordsForEmployer() {
    const employerId = this.$store.getters.employer.id
    try {
      const parameters = { id: employerId }
      const response = await API.graphql(graphqlOperation(QueriesCustom.getEmployeesAndTrainingRecords, parameters));
      const result = response.data.getEmployer.teamMembers
      return result
    } catch (e) {
      console.warn("Error getting employee details:", e)
      return []
    }
  }

  async CreateEmployer(employerDetails) {
    const id = uuidv4()
    const employer = {
      ...employerDetails,
      id,
      groupsCanRead: [ 'USER' ],
      groupsCanUpdate: [ `${id}_ADMINISTRATOR` ]
    }
    const response = await API.graphql(graphqlOperation(Mutations.createEmployer, { input: employer }));
    return response.data.createEmployer
  }


  async GetGroups() {
    const employerId = store.getters.employer.id
    const parameters = { id: employerId }
    const response = await API.graphql(graphqlOperation(QueriesCustom.getEmployerGroups, parameters));
    const result = response.data.getEmployer.employerGroups.items
    return result
  }

  async CreateEmployerGroup(employerGroup) {
    if (!(employerGroup instanceof EmployerGroup)) {
      throw new Error("argument employerGroup is not an instance of EmployerGroup")
    }
    const response = await API.graphql(graphqlOperation(Mutations.createEmployerGroup, { input: employerGroup.getDatabaseEntry() }));
    return response.data.createEmployerGroup
  }

  async UpdateEmployerGroup(employerGroup) {
    if (!(employerGroup instanceof EmployerGroup)) {
      throw new Error("argument employerGroup is not an instance of EmployerGroup")
    }
    const response = await API.graphql(graphqlOperation(Mutations.updateEmployerGroup, { input: employerGroup.getDatabaseEntry() }));
    return response.data.updateEmployerGroup
  }

  async RemoveEmployerGroup(employerGroupId) {
    // First remove every association with the group
    await this.RemoveAllEmployerGroupMembers(employerGroupId)
    const parameters = { input: { id: employerGroupId } }
    const response = await API.graphql(graphqlOperation(Mutations.deleteEmployerGroup, parameters));
    const result = response.data.deleteEmployerGroup
    return result
  }

  async RemoveAllEmployerGroupMembers(employerGroupId) {
    // Get all the group allocations for the given group
    let parameters = { groupId: employerGroupId }
    let response = await API.graphql(graphqlOperation(QueriesCustom.groupAllocationsByGroup, parameters));
    console.log(response)
    let results = response.data.groupAllocationsByGroup.items
    // While there is a nextToken, fetch the next results
    while (response.data.groupAllocationsByGroup.nextToken) {
      parameters.nextToken = response.data.groupAllocationsByGroup.items
      response = await API.graphql(graphqlOperation(QueriesCustom.groupAllocationsByGroup, parameters));
      results = results.concat(response.data.groupAllocationsByGroup.items)
    }
    // Now delete each group allocation
    for (let allocation of results) {
      await API.graphql(graphqlOperation(Mutations.deleteEmployeeGroupAllocation, { input: { id: allocation.id } }));
    }
  }

}

export default EmployerService

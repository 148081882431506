/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAccountSubscription = /* GraphQL */ `
  mutation CreateAccountSubscription(
    $input: CreateAccountSubscriptionInput!
    $condition: ModelAccountSubscriptionConditionInput
  ) {
    createAccountSubscription(input: $input, condition: $condition) {
      id
      accountTierID
      discount
      paymentCurrency
      groupsCanRead
      createdAt
      updatedAt
      owner
      accountTier {
        id
        tierName
        lifeCycle
        maxMarkets
        maxChannels
        maxBrands
        priceMonthlyGBP
        priceYearlyGBP
        createdAt
        updatedAt
        subscriptions {
          nextToken
          __typename
        }
        owner
        __typename
      }
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateAccountSubscription = /* GraphQL */ `
  mutation UpdateAccountSubscription(
    $input: UpdateAccountSubscriptionInput!
    $condition: ModelAccountSubscriptionConditionInput
  ) {
    updateAccountSubscription(input: $input, condition: $condition) {
      id
      accountTierID
      discount
      paymentCurrency
      groupsCanRead
      createdAt
      updatedAt
      owner
      accountTier {
        id
        tierName
        lifeCycle
        maxMarkets
        maxChannels
        maxBrands
        priceMonthlyGBP
        priceYearlyGBP
        createdAt
        updatedAt
        subscriptions {
          nextToken
          __typename
        }
        owner
        __typename
      }
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteAccountSubscription = /* GraphQL */ `
  mutation DeleteAccountSubscription(
    $input: DeleteAccountSubscriptionInput!
    $condition: ModelAccountSubscriptionConditionInput
  ) {
    deleteAccountSubscription(input: $input, condition: $condition) {
      id
      accountTierID
      discount
      paymentCurrency
      groupsCanRead
      createdAt
      updatedAt
      owner
      accountTier {
        id
        tierName
        lifeCycle
        maxMarkets
        maxChannels
        maxBrands
        priceMonthlyGBP
        priceYearlyGBP
        createdAt
        updatedAt
        subscriptions {
          nextToken
          __typename
        }
        owner
        __typename
      }
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createAccountTier = /* GraphQL */ `
  mutation CreateAccountTier(
    $input: CreateAccountTierInput!
    $condition: ModelAccountTierConditionInput
  ) {
    createAccountTier(input: $input, condition: $condition) {
      id
      tierName
      lifeCycle
      maxMarkets
      maxChannels
      maxBrands
      priceMonthlyGBP
      priceYearlyGBP
      createdAt
      updatedAt
      subscriptions {
        items {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateAccountTier = /* GraphQL */ `
  mutation UpdateAccountTier(
    $input: UpdateAccountTierInput!
    $condition: ModelAccountTierConditionInput
  ) {
    updateAccountTier(input: $input, condition: $condition) {
      id
      tierName
      lifeCycle
      maxMarkets
      maxChannels
      maxBrands
      priceMonthlyGBP
      priceYearlyGBP
      createdAt
      updatedAt
      subscriptions {
        items {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteAccountTier = /* GraphQL */ `
  mutation DeleteAccountTier(
    $input: DeleteAccountTierInput!
    $condition: ModelAccountTierConditionInput
  ) {
    deleteAccountTier(input: $input, condition: $condition) {
      id
      tierName
      lifeCycle
      maxMarkets
      maxChannels
      maxBrands
      priceMonthlyGBP
      priceYearlyGBP
      createdAt
      updatedAt
      subscriptions {
        items {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createAccreditationLogo = /* GraphQL */ `
  mutation CreateAccreditationLogo(
    $input: CreateAccreditationLogoInput!
    $condition: ModelAccreditationLogoConditionInput
  ) {
    createAccreditationLogo(input: $input, condition: $condition) {
      id
      employerId
      name
      imageKey
      status
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      owner
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      events {
        items {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateAccreditationLogo = /* GraphQL */ `
  mutation UpdateAccreditationLogo(
    $input: UpdateAccreditationLogoInput!
    $condition: ModelAccreditationLogoConditionInput
  ) {
    updateAccreditationLogo(input: $input, condition: $condition) {
      id
      employerId
      name
      imageKey
      status
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      owner
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      events {
        items {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteAccreditationLogo = /* GraphQL */ `
  mutation DeleteAccreditationLogo(
    $input: DeleteAccreditationLogoInput!
    $condition: ModelAccreditationLogoConditionInput
  ) {
    deleteAccreditationLogo(input: $input, condition: $condition) {
      id
      employerId
      name
      imageKey
      status
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      owner
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      events {
        items {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createCertificateTemplate = /* GraphQL */ `
  mutation CreateCertificateTemplate(
    $input: CreateCertificateTemplateInput!
    $condition: ModelCertificateTemplateConditionInput
  ) {
    createCertificateTemplate(input: $input, condition: $condition) {
      id
      name
      imageKey
      fileKey
      pageOrientation
      placeholderFields
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      owner
      events {
        items {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateCertificateTemplate = /* GraphQL */ `
  mutation UpdateCertificateTemplate(
    $input: UpdateCertificateTemplateInput!
    $condition: ModelCertificateTemplateConditionInput
  ) {
    updateCertificateTemplate(input: $input, condition: $condition) {
      id
      name
      imageKey
      fileKey
      pageOrientation
      placeholderFields
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      owner
      events {
        items {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteCertificateTemplate = /* GraphQL */ `
  mutation DeleteCertificateTemplate(
    $input: DeleteCertificateTemplateInput!
    $condition: ModelCertificateTemplateConditionInput
  ) {
    deleteCertificateTemplate(input: $input, condition: $condition) {
      id
      name
      imageKey
      fileKey
      pageOrientation
      placeholderFields
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      owner
      events {
        items {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createCountry = /* GraphQL */ `
  mutation CreateCountry(
    $input: CreateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    createCountry(input: $input, condition: $condition) {
      id
      englishShortName
      numericCode
      diallingCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCountry = /* GraphQL */ `
  mutation UpdateCountry(
    $input: UpdateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    updateCountry(input: $input, condition: $condition) {
      id
      englishShortName
      numericCode
      diallingCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCountry = /* GraphQL */ `
  mutation DeleteCountry(
    $input: DeleteCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    deleteCountry(input: $input, condition: $condition) {
      id
      englishShortName
      numericCode
      diallingCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEmployeeRole = /* GraphQL */ `
  mutation CreateEmployeeRole(
    $input: CreateEmployeeRoleInput!
    $condition: ModelEmployeeRoleConditionInput
  ) {
    createEmployeeRole(input: $input, condition: $condition) {
      id
      userId
      employerId
      roleName
      accessRights {
        isAdministrator
        isCourseInstructor
        __typename
      }
      courseInstructorDetails {
        phone
        email
        aboutText
        isActive
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      owner
      employerGroups {
        items {
          id
          employeeId
          groupId
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updateEmployeeRole = /* GraphQL */ `
  mutation UpdateEmployeeRole(
    $input: UpdateEmployeeRoleInput!
    $condition: ModelEmployeeRoleConditionInput
  ) {
    updateEmployeeRole(input: $input, condition: $condition) {
      id
      userId
      employerId
      roleName
      accessRights {
        isAdministrator
        isCourseInstructor
        __typename
      }
      courseInstructorDetails {
        phone
        email
        aboutText
        isActive
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      owner
      employerGroups {
        items {
          id
          employeeId
          groupId
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deleteEmployeeRole = /* GraphQL */ `
  mutation DeleteEmployeeRole(
    $input: DeleteEmployeeRoleInput!
    $condition: ModelEmployeeRoleConditionInput
  ) {
    deleteEmployeeRole(input: $input, condition: $condition) {
      id
      userId
      employerId
      roleName
      accessRights {
        isAdministrator
        isCourseInstructor
        __typename
      }
      courseInstructorDetails {
        phone
        email
        aboutText
        isActive
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      owner
      employerGroups {
        items {
          id
          employeeId
          groupId
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createEmployeeGroupAllocation = /* GraphQL */ `
  mutation CreateEmployeeGroupAllocation(
    $input: CreateEmployeeGroupAllocationInput!
    $condition: ModelEmployeeGroupAllocationConditionInput
  ) {
    createEmployeeGroupAllocation(input: $input, condition: $condition) {
      id
      employeeId
      groupId
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      employee {
        id
        userId
        employerId
        roleName
        accessRights {
          isAdministrator
          isCourseInstructor
          __typename
        }
        courseInstructorDetails {
          phone
          email
          aboutText
          isActive
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        employerGroups {
          nextToken
          __typename
        }
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        user {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      owner
      group {
        id
        name
        description
        employerId
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        groupMembers {
          nextToken
          __typename
        }
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updateEmployeeGroupAllocation = /* GraphQL */ `
  mutation UpdateEmployeeGroupAllocation(
    $input: UpdateEmployeeGroupAllocationInput!
    $condition: ModelEmployeeGroupAllocationConditionInput
  ) {
    updateEmployeeGroupAllocation(input: $input, condition: $condition) {
      id
      employeeId
      groupId
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      employee {
        id
        userId
        employerId
        roleName
        accessRights {
          isAdministrator
          isCourseInstructor
          __typename
        }
        courseInstructorDetails {
          phone
          email
          aboutText
          isActive
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        employerGroups {
          nextToken
          __typename
        }
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        user {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      owner
      group {
        id
        name
        description
        employerId
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        groupMembers {
          nextToken
          __typename
        }
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deleteEmployeeGroupAllocation = /* GraphQL */ `
  mutation DeleteEmployeeGroupAllocation(
    $input: DeleteEmployeeGroupAllocationInput!
    $condition: ModelEmployeeGroupAllocationConditionInput
  ) {
    deleteEmployeeGroupAllocation(input: $input, condition: $condition) {
      id
      employeeId
      groupId
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      employee {
        id
        userId
        employerId
        roleName
        accessRights {
          isAdministrator
          isCourseInstructor
          __typename
        }
        courseInstructorDetails {
          phone
          email
          aboutText
          isActive
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        employerGroups {
          nextToken
          __typename
        }
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        user {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      owner
      group {
        id
        name
        description
        employerId
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        groupMembers {
          nextToken
          __typename
        }
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createEmployer = /* GraphQL */ `
  mutation CreateEmployer(
    $input: CreateEmployerInput!
    $condition: ModelEmployerConditionInput
  ) {
    createEmployer(input: $input, condition: $condition) {
      id
      name
      description
      address {
        line1
        line2
        line3
        city
        region
        postalCode
        country
        __typename
      }
      logo {
        id
        name
        imageKey
        __typename
      }
      contactDetails {
        emailBookings
        phoneBookings
        __typename
      }
      isCourseProvider
      courseProviderDetails {
        description
        whyChooseUs
        websiteURL
        registrationNumber
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      createdAt
      updatedAt
      accountSubscription {
        id
        accountTierID
        discount
        paymentCurrency
        groupsCanRead
        createdAt
        updatedAt
        owner
        accountTier {
          id
          tierName
          lifeCycle
          maxMarkets
          maxChannels
          maxBrands
          priceMonthlyGBP
          priceYearlyGBP
          createdAt
          updatedAt
          owner
          __typename
        }
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      accreditationLogos {
        items {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      teamMembers {
        items {
          id
          userId
          employerId
          roleName
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      employerGroups {
        items {
          id
          name
          description
          employerId
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      eventLogos {
        items {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      pendingTeamMembers {
        items {
          id
          userId
          employerId
          emailAddress
          roleName
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signatures {
        items {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateEmployer = /* GraphQL */ `
  mutation UpdateEmployer(
    $input: UpdateEmployerInput!
    $condition: ModelEmployerConditionInput
  ) {
    updateEmployer(input: $input, condition: $condition) {
      id
      name
      description
      address {
        line1
        line2
        line3
        city
        region
        postalCode
        country
        __typename
      }
      logo {
        id
        name
        imageKey
        __typename
      }
      contactDetails {
        emailBookings
        phoneBookings
        __typename
      }
      isCourseProvider
      courseProviderDetails {
        description
        whyChooseUs
        websiteURL
        registrationNumber
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      createdAt
      updatedAt
      accountSubscription {
        id
        accountTierID
        discount
        paymentCurrency
        groupsCanRead
        createdAt
        updatedAt
        owner
        accountTier {
          id
          tierName
          lifeCycle
          maxMarkets
          maxChannels
          maxBrands
          priceMonthlyGBP
          priceYearlyGBP
          createdAt
          updatedAt
          owner
          __typename
        }
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      accreditationLogos {
        items {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      teamMembers {
        items {
          id
          userId
          employerId
          roleName
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      employerGroups {
        items {
          id
          name
          description
          employerId
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      eventLogos {
        items {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      pendingTeamMembers {
        items {
          id
          userId
          employerId
          emailAddress
          roleName
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signatures {
        items {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteEmployer = /* GraphQL */ `
  mutation DeleteEmployer(
    $input: DeleteEmployerInput!
    $condition: ModelEmployerConditionInput
  ) {
    deleteEmployer(input: $input, condition: $condition) {
      id
      name
      description
      address {
        line1
        line2
        line3
        city
        region
        postalCode
        country
        __typename
      }
      logo {
        id
        name
        imageKey
        __typename
      }
      contactDetails {
        emailBookings
        phoneBookings
        __typename
      }
      isCourseProvider
      courseProviderDetails {
        description
        whyChooseUs
        websiteURL
        registrationNumber
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      createdAt
      updatedAt
      accountSubscription {
        id
        accountTierID
        discount
        paymentCurrency
        groupsCanRead
        createdAt
        updatedAt
        owner
        accountTier {
          id
          tierName
          lifeCycle
          maxMarkets
          maxChannels
          maxBrands
          priceMonthlyGBP
          priceYearlyGBP
          createdAt
          updatedAt
          owner
          __typename
        }
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      accreditationLogos {
        items {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      teamMembers {
        items {
          id
          userId
          employerId
          roleName
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      employerGroups {
        items {
          id
          name
          description
          employerId
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      eventLogos {
        items {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      pendingTeamMembers {
        items {
          id
          userId
          employerId
          emailAddress
          roleName
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signatures {
        items {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createEmployerGroup = /* GraphQL */ `
  mutation CreateEmployerGroup(
    $input: CreateEmployerGroupInput!
    $condition: ModelEmployerGroupConditionInput
  ) {
    createEmployerGroup(input: $input, condition: $condition) {
      id
      name
      description
      employerId
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      groupMembers {
        items {
          id
          employeeId
          groupId
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateEmployerGroup = /* GraphQL */ `
  mutation UpdateEmployerGroup(
    $input: UpdateEmployerGroupInput!
    $condition: ModelEmployerGroupConditionInput
  ) {
    updateEmployerGroup(input: $input, condition: $condition) {
      id
      name
      description
      employerId
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      groupMembers {
        items {
          id
          employeeId
          groupId
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteEmployerGroup = /* GraphQL */ `
  mutation DeleteEmployerGroup(
    $input: DeleteEmployerGroupInput!
    $condition: ModelEmployerGroupConditionInput
  ) {
    deleteEmployerGroup(input: $input, condition: $condition) {
      id
      name
      description
      employerId
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      groupMembers {
        items {
          id
          employeeId
          groupId
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      title
      description
      code
      targetAudience
      isExternal
      externalType
      eventType
      eventMode
      status
      accessLevel
      possibleActions
      providerId
      instructorId
      preRequisites
      proofOfIdentity
      address {
        line1
        line2
        line3
        city
        region
        postalCode
        country
        __typename
      }
      onlineURL
      maximumAttendees
      startDateTime
      endDateTime
      attendanceHours
      cpdHours
      latitude
      longitude
      eventValidityDateTime
      certificatesExpire
      certificateExpiryDateTime
      accreditationLogoId
      signatureId
      eventLogoId
      certificateTemplateId
      certificateCustomisation {
        providerLogoDimensionsHorizontal
        providerLogoDimensionsVertical
        eventLogoDimensionsHorizontal
        eventLogoDimensionsVertical
        signatureDimensionsHorizontal
        signatureDimensionsVertical
        colorBackground
        colorFontPrimary
        colorFontSecondary
        useCertificateSignerName
        certificateSignerName
        __typename
      }
      attendeeStatistics {
        booked
        onWaitingList
        attended
        noShow
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      createdAt
      updatedAt
      accreditationLogo {
        id
        employerId
        name
        imageKey
        status
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        events {
          nextToken
          __typename
        }
        __typename
      }
      certificateTemplate {
        id
        name
        imageKey
        fileKey
        pageOrientation
        placeholderFields
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        events {
          nextToken
          __typename
        }
        __typename
      }
      provider {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      owner
      attendees {
        items {
          id
          userId
          eventId
          status
          seatNumber
          attendance
          certificateSent
          certificateSaved
          bookingDateTime
          stripeSessionId
          paymentId
          groupsCanRead
          groupsFullControl
          blockAdminCreateNotification
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      eventLogo {
        id
        employerId
        name
        imageKey
        status
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        events {
          nextToken
          __typename
        }
        owner
        __typename
      }
      prices {
        items {
          id
          eventId
          title
          description
          status
          priceGBP
          priceUSD
          priceEUR
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signature {
        id
        employerId
        name
        imageKey
        status
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        events {
          nextToken
          __typename
        }
        owner
        __typename
      }
      instructor {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      title
      description
      code
      targetAudience
      isExternal
      externalType
      eventType
      eventMode
      status
      accessLevel
      possibleActions
      providerId
      instructorId
      preRequisites
      proofOfIdentity
      address {
        line1
        line2
        line3
        city
        region
        postalCode
        country
        __typename
      }
      onlineURL
      maximumAttendees
      startDateTime
      endDateTime
      attendanceHours
      cpdHours
      latitude
      longitude
      eventValidityDateTime
      certificatesExpire
      certificateExpiryDateTime
      accreditationLogoId
      signatureId
      eventLogoId
      certificateTemplateId
      certificateCustomisation {
        providerLogoDimensionsHorizontal
        providerLogoDimensionsVertical
        eventLogoDimensionsHorizontal
        eventLogoDimensionsVertical
        signatureDimensionsHorizontal
        signatureDimensionsVertical
        colorBackground
        colorFontPrimary
        colorFontSecondary
        useCertificateSignerName
        certificateSignerName
        __typename
      }
      attendeeStatistics {
        booked
        onWaitingList
        attended
        noShow
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      createdAt
      updatedAt
      accreditationLogo {
        id
        employerId
        name
        imageKey
        status
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        events {
          nextToken
          __typename
        }
        __typename
      }
      certificateTemplate {
        id
        name
        imageKey
        fileKey
        pageOrientation
        placeholderFields
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        events {
          nextToken
          __typename
        }
        __typename
      }
      provider {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      owner
      attendees {
        items {
          id
          userId
          eventId
          status
          seatNumber
          attendance
          certificateSent
          certificateSaved
          bookingDateTime
          stripeSessionId
          paymentId
          groupsCanRead
          groupsFullControl
          blockAdminCreateNotification
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      eventLogo {
        id
        employerId
        name
        imageKey
        status
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        events {
          nextToken
          __typename
        }
        owner
        __typename
      }
      prices {
        items {
          id
          eventId
          title
          description
          status
          priceGBP
          priceUSD
          priceEUR
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signature {
        id
        employerId
        name
        imageKey
        status
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        events {
          nextToken
          __typename
        }
        owner
        __typename
      }
      instructor {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      title
      description
      code
      targetAudience
      isExternal
      externalType
      eventType
      eventMode
      status
      accessLevel
      possibleActions
      providerId
      instructorId
      preRequisites
      proofOfIdentity
      address {
        line1
        line2
        line3
        city
        region
        postalCode
        country
        __typename
      }
      onlineURL
      maximumAttendees
      startDateTime
      endDateTime
      attendanceHours
      cpdHours
      latitude
      longitude
      eventValidityDateTime
      certificatesExpire
      certificateExpiryDateTime
      accreditationLogoId
      signatureId
      eventLogoId
      certificateTemplateId
      certificateCustomisation {
        providerLogoDimensionsHorizontal
        providerLogoDimensionsVertical
        eventLogoDimensionsHorizontal
        eventLogoDimensionsVertical
        signatureDimensionsHorizontal
        signatureDimensionsVertical
        colorBackground
        colorFontPrimary
        colorFontSecondary
        useCertificateSignerName
        certificateSignerName
        __typename
      }
      attendeeStatistics {
        booked
        onWaitingList
        attended
        noShow
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      createdAt
      updatedAt
      accreditationLogo {
        id
        employerId
        name
        imageKey
        status
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        events {
          nextToken
          __typename
        }
        __typename
      }
      certificateTemplate {
        id
        name
        imageKey
        fileKey
        pageOrientation
        placeholderFields
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        events {
          nextToken
          __typename
        }
        __typename
      }
      provider {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      owner
      attendees {
        items {
          id
          userId
          eventId
          status
          seatNumber
          attendance
          certificateSent
          certificateSaved
          bookingDateTime
          stripeSessionId
          paymentId
          groupsCanRead
          groupsFullControl
          blockAdminCreateNotification
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      eventLogo {
        id
        employerId
        name
        imageKey
        status
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        events {
          nextToken
          __typename
        }
        owner
        __typename
      }
      prices {
        items {
          id
          eventId
          title
          description
          status
          priceGBP
          priceUSD
          priceEUR
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signature {
        id
        employerId
        name
        imageKey
        status
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        employer {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        events {
          nextToken
          __typename
        }
        owner
        __typename
      }
      instructor {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createEventBooking = /* GraphQL */ `
  mutation CreateEventBooking(
    $input: CreateEventBookingInput!
    $condition: ModelEventBookingConditionInput
  ) {
    createEventBooking(input: $input, condition: $condition) {
      id
      userId
      eventId
      status
      seatNumber
      attendance
      certificateSent
      certificateSaved
      bookingDateTime
      stripeSessionId
      paymentId
      groupsCanRead
      groupsFullControl
      blockAdminCreateNotification
      createdAt
      updatedAt
      event {
        id
        title
        description
        code
        targetAudience
        isExternal
        externalType
        eventType
        eventMode
        status
        accessLevel
        possibleActions
        providerId
        instructorId
        preRequisites
        proofOfIdentity
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        onlineURL
        maximumAttendees
        startDateTime
        endDateTime
        attendanceHours
        cpdHours
        latitude
        longitude
        eventValidityDateTime
        certificatesExpire
        certificateExpiryDateTime
        accreditationLogoId
        signatureId
        eventLogoId
        certificateTemplateId
        certificateCustomisation {
          providerLogoDimensionsHorizontal
          providerLogoDimensionsVertical
          eventLogoDimensionsHorizontal
          eventLogoDimensionsVertical
          signatureDimensionsHorizontal
          signatureDimensionsVertical
          colorBackground
          colorFontPrimary
          colorFontSecondary
          useCertificateSignerName
          certificateSignerName
          __typename
        }
        attendeeStatistics {
          booked
          onWaitingList
          attended
          noShow
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accreditationLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        certificateTemplate {
          id
          name
          imageKey
          fileKey
          pageOrientation
          placeholderFields
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        provider {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        attendees {
          nextToken
          __typename
        }
        eventLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        prices {
          nextToken
          __typename
        }
        signature {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        instructor {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      owner
      payment {
        id
        method
        stripeTransactionId
        currency
        amount
        status
        invoiceNumber
        createdAt
        updatedAt
        owner
        __typename
      }
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updateEventBooking = /* GraphQL */ `
  mutation UpdateEventBooking(
    $input: UpdateEventBookingInput!
    $condition: ModelEventBookingConditionInput
  ) {
    updateEventBooking(input: $input, condition: $condition) {
      id
      userId
      eventId
      status
      seatNumber
      attendance
      certificateSent
      certificateSaved
      bookingDateTime
      stripeSessionId
      paymentId
      groupsCanRead
      groupsFullControl
      blockAdminCreateNotification
      createdAt
      updatedAt
      event {
        id
        title
        description
        code
        targetAudience
        isExternal
        externalType
        eventType
        eventMode
        status
        accessLevel
        possibleActions
        providerId
        instructorId
        preRequisites
        proofOfIdentity
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        onlineURL
        maximumAttendees
        startDateTime
        endDateTime
        attendanceHours
        cpdHours
        latitude
        longitude
        eventValidityDateTime
        certificatesExpire
        certificateExpiryDateTime
        accreditationLogoId
        signatureId
        eventLogoId
        certificateTemplateId
        certificateCustomisation {
          providerLogoDimensionsHorizontal
          providerLogoDimensionsVertical
          eventLogoDimensionsHorizontal
          eventLogoDimensionsVertical
          signatureDimensionsHorizontal
          signatureDimensionsVertical
          colorBackground
          colorFontPrimary
          colorFontSecondary
          useCertificateSignerName
          certificateSignerName
          __typename
        }
        attendeeStatistics {
          booked
          onWaitingList
          attended
          noShow
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accreditationLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        certificateTemplate {
          id
          name
          imageKey
          fileKey
          pageOrientation
          placeholderFields
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        provider {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        attendees {
          nextToken
          __typename
        }
        eventLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        prices {
          nextToken
          __typename
        }
        signature {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        instructor {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      owner
      payment {
        id
        method
        stripeTransactionId
        currency
        amount
        status
        invoiceNumber
        createdAt
        updatedAt
        owner
        __typename
      }
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deleteEventBooking = /* GraphQL */ `
  mutation DeleteEventBooking(
    $input: DeleteEventBookingInput!
    $condition: ModelEventBookingConditionInput
  ) {
    deleteEventBooking(input: $input, condition: $condition) {
      id
      userId
      eventId
      status
      seatNumber
      attendance
      certificateSent
      certificateSaved
      bookingDateTime
      stripeSessionId
      paymentId
      groupsCanRead
      groupsFullControl
      blockAdminCreateNotification
      createdAt
      updatedAt
      event {
        id
        title
        description
        code
        targetAudience
        isExternal
        externalType
        eventType
        eventMode
        status
        accessLevel
        possibleActions
        providerId
        instructorId
        preRequisites
        proofOfIdentity
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        onlineURL
        maximumAttendees
        startDateTime
        endDateTime
        attendanceHours
        cpdHours
        latitude
        longitude
        eventValidityDateTime
        certificatesExpire
        certificateExpiryDateTime
        accreditationLogoId
        signatureId
        eventLogoId
        certificateTemplateId
        certificateCustomisation {
          providerLogoDimensionsHorizontal
          providerLogoDimensionsVertical
          eventLogoDimensionsHorizontal
          eventLogoDimensionsVertical
          signatureDimensionsHorizontal
          signatureDimensionsVertical
          colorBackground
          colorFontPrimary
          colorFontSecondary
          useCertificateSignerName
          certificateSignerName
          __typename
        }
        attendeeStatistics {
          booked
          onWaitingList
          attended
          noShow
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accreditationLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        certificateTemplate {
          id
          name
          imageKey
          fileKey
          pageOrientation
          placeholderFields
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        provider {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        attendees {
          nextToken
          __typename
        }
        eventLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        prices {
          nextToken
          __typename
        }
        signature {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        instructor {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      owner
      payment {
        id
        method
        stripeTransactionId
        currency
        amount
        status
        invoiceNumber
        createdAt
        updatedAt
        owner
        __typename
      }
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createEventLogo = /* GraphQL */ `
  mutation CreateEventLogo(
    $input: CreateEventLogoInput!
    $condition: ModelEventLogoConditionInput
  ) {
    createEventLogo(input: $input, condition: $condition) {
      id
      employerId
      name
      imageKey
      status
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      events {
        items {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateEventLogo = /* GraphQL */ `
  mutation UpdateEventLogo(
    $input: UpdateEventLogoInput!
    $condition: ModelEventLogoConditionInput
  ) {
    updateEventLogo(input: $input, condition: $condition) {
      id
      employerId
      name
      imageKey
      status
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      events {
        items {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteEventLogo = /* GraphQL */ `
  mutation DeleteEventLogo(
    $input: DeleteEventLogoInput!
    $condition: ModelEventLogoConditionInput
  ) {
    deleteEventLogo(input: $input, condition: $condition) {
      id
      employerId
      name
      imageKey
      status
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      events {
        items {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createEventPrice = /* GraphQL */ `
  mutation CreateEventPrice(
    $input: CreateEventPriceInput!
    $condition: ModelEventPriceConditionInput
  ) {
    createEventPrice(input: $input, condition: $condition) {
      id
      eventId
      title
      description
      status
      priceGBP
      priceUSD
      priceEUR
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      event {
        id
        title
        description
        code
        targetAudience
        isExternal
        externalType
        eventType
        eventMode
        status
        accessLevel
        possibleActions
        providerId
        instructorId
        preRequisites
        proofOfIdentity
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        onlineURL
        maximumAttendees
        startDateTime
        endDateTime
        attendanceHours
        cpdHours
        latitude
        longitude
        eventValidityDateTime
        certificatesExpire
        certificateExpiryDateTime
        accreditationLogoId
        signatureId
        eventLogoId
        certificateTemplateId
        certificateCustomisation {
          providerLogoDimensionsHorizontal
          providerLogoDimensionsVertical
          eventLogoDimensionsHorizontal
          eventLogoDimensionsVertical
          signatureDimensionsHorizontal
          signatureDimensionsVertical
          colorBackground
          colorFontPrimary
          colorFontSecondary
          useCertificateSignerName
          certificateSignerName
          __typename
        }
        attendeeStatistics {
          booked
          onWaitingList
          attended
          noShow
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accreditationLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        certificateTemplate {
          id
          name
          imageKey
          fileKey
          pageOrientation
          placeholderFields
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        provider {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        attendees {
          nextToken
          __typename
        }
        eventLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        prices {
          nextToken
          __typename
        }
        signature {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        instructor {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateEventPrice = /* GraphQL */ `
  mutation UpdateEventPrice(
    $input: UpdateEventPriceInput!
    $condition: ModelEventPriceConditionInput
  ) {
    updateEventPrice(input: $input, condition: $condition) {
      id
      eventId
      title
      description
      status
      priceGBP
      priceUSD
      priceEUR
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      event {
        id
        title
        description
        code
        targetAudience
        isExternal
        externalType
        eventType
        eventMode
        status
        accessLevel
        possibleActions
        providerId
        instructorId
        preRequisites
        proofOfIdentity
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        onlineURL
        maximumAttendees
        startDateTime
        endDateTime
        attendanceHours
        cpdHours
        latitude
        longitude
        eventValidityDateTime
        certificatesExpire
        certificateExpiryDateTime
        accreditationLogoId
        signatureId
        eventLogoId
        certificateTemplateId
        certificateCustomisation {
          providerLogoDimensionsHorizontal
          providerLogoDimensionsVertical
          eventLogoDimensionsHorizontal
          eventLogoDimensionsVertical
          signatureDimensionsHorizontal
          signatureDimensionsVertical
          colorBackground
          colorFontPrimary
          colorFontSecondary
          useCertificateSignerName
          certificateSignerName
          __typename
        }
        attendeeStatistics {
          booked
          onWaitingList
          attended
          noShow
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accreditationLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        certificateTemplate {
          id
          name
          imageKey
          fileKey
          pageOrientation
          placeholderFields
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        provider {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        attendees {
          nextToken
          __typename
        }
        eventLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        prices {
          nextToken
          __typename
        }
        signature {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        instructor {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteEventPrice = /* GraphQL */ `
  mutation DeleteEventPrice(
    $input: DeleteEventPriceInput!
    $condition: ModelEventPriceConditionInput
  ) {
    deleteEventPrice(input: $input, condition: $condition) {
      id
      eventId
      title
      description
      status
      priceGBP
      priceUSD
      priceEUR
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      event {
        id
        title
        description
        code
        targetAudience
        isExternal
        externalType
        eventType
        eventMode
        status
        accessLevel
        possibleActions
        providerId
        instructorId
        preRequisites
        proofOfIdentity
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        onlineURL
        maximumAttendees
        startDateTime
        endDateTime
        attendanceHours
        cpdHours
        latitude
        longitude
        eventValidityDateTime
        certificatesExpire
        certificateExpiryDateTime
        accreditationLogoId
        signatureId
        eventLogoId
        certificateTemplateId
        certificateCustomisation {
          providerLogoDimensionsHorizontal
          providerLogoDimensionsVertical
          eventLogoDimensionsHorizontal
          eventLogoDimensionsVertical
          signatureDimensionsHorizontal
          signatureDimensionsVertical
          colorBackground
          colorFontPrimary
          colorFontSecondary
          useCertificateSignerName
          certificateSignerName
          __typename
        }
        attendeeStatistics {
          booked
          onWaitingList
          attended
          noShow
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accreditationLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        certificateTemplate {
          id
          name
          imageKey
          fileKey
          pageOrientation
          placeholderFields
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        provider {
          id
          name
          description
          isCourseProvider
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        attendees {
          nextToken
          __typename
        }
        eventLogo {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        prices {
          nextToken
          __typename
        }
        signature {
          id
          employerId
          name
          imageKey
          status
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        instructor {
          id
          identityId
          emailAddress
          firstName
          lastName
          countryId
          groupsCanRead
          groupsCanUpdate
          resendTemporaryPassword
          lastAcceptedTandCDateTime
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createExperienceCertificateTemplate = /* GraphQL */ `
  mutation CreateExperienceCertificateTemplate(
    $input: CreateExperienceCertificateTemplateInput!
    $condition: ModelExperienceCertificateTemplateConditionInput
  ) {
    createExperienceCertificateTemplate(input: $input, condition: $condition) {
      id
      name
      imageKey
      fileKey
      pageOrientation
      placeholderFields
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateExperienceCertificateTemplate = /* GraphQL */ `
  mutation UpdateExperienceCertificateTemplate(
    $input: UpdateExperienceCertificateTemplateInput!
    $condition: ModelExperienceCertificateTemplateConditionInput
  ) {
    updateExperienceCertificateTemplate(input: $input, condition: $condition) {
      id
      name
      imageKey
      fileKey
      pageOrientation
      placeholderFields
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteExperienceCertificateTemplate = /* GraphQL */ `
  mutation DeleteExperienceCertificateTemplate(
    $input: DeleteExperienceCertificateTemplateInput!
    $condition: ModelExperienceCertificateTemplateConditionInput
  ) {
    deleteExperienceCertificateTemplate(input: $input, condition: $condition) {
      id
      name
      imageKey
      fileKey
      pageOrientation
      placeholderFields
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createExperienceRecord = /* GraphQL */ `
  mutation CreateExperienceRecord(
    $input: CreateExperienceRecordInput!
    $condition: ModelExperienceRecordConditionInput
  ) {
    createExperienceRecord(input: $input, condition: $condition) {
      id
      title
      description
      issueDateTime
      startDateTime
      endDateTime
      providerId
      nameProvided
      userId
      experienceEventId
      certificateTemplateId
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      provider {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      certificateTemplate {
        id
        name
        imageKey
        fileKey
        pageOrientation
        placeholderFields
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updateExperienceRecord = /* GraphQL */ `
  mutation UpdateExperienceRecord(
    $input: UpdateExperienceRecordInput!
    $condition: ModelExperienceRecordConditionInput
  ) {
    updateExperienceRecord(input: $input, condition: $condition) {
      id
      title
      description
      issueDateTime
      startDateTime
      endDateTime
      providerId
      nameProvided
      userId
      experienceEventId
      certificateTemplateId
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      provider {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      certificateTemplate {
        id
        name
        imageKey
        fileKey
        pageOrientation
        placeholderFields
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deleteExperienceRecord = /* GraphQL */ `
  mutation DeleteExperienceRecord(
    $input: DeleteExperienceRecordInput!
    $condition: ModelExperienceRecordConditionInput
  ) {
    deleteExperienceRecord(input: $input, condition: $condition) {
      id
      title
      description
      issueDateTime
      startDateTime
      endDateTime
      providerId
      nameProvided
      userId
      experienceEventId
      certificateTemplateId
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      provider {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      certificateTemplate {
        id
        name
        imageKey
        fileKey
        pageOrientation
        placeholderFields
        groupsCanRead
        groupsCanUpdate
        groupsFullControl
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const sendCertificatesToAttendee = /* GraphQL */ `
  mutation SendCertificatesToAttendee(
    $eventId: String!
    $bookingIds: [String]!
    $dontUpdateStatistics: Boolean
  ) {
    sendCertificatesToAttendee(
      eventId: $eventId
      bookingIds: $bookingIds
      dontUpdateStatistics: $dontUpdateStatistics
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const getCertificateForConference = /* GraphQL */ `
  mutation GetCertificateForConference(
    $eventId: String!
    $bookingId: String!
    $code: String!
    $dontUpdateStatistics: Boolean
  ) {
    getCertificateForConference(
      eventId: $eventId
      bookingId: $bookingId
      code: $code
      dontUpdateStatistics: $dontUpdateStatistics
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const getExampleCertificate = /* GraphQL */ `
  mutation GetExampleCertificate($eventId: String!) {
    getExampleCertificate(eventId: $eventId) {
      statusCode
      body
      __typename
    }
  }
`;
export const getExampleExperienceCertificate = /* GraphQL */ `
  mutation GetExampleExperienceCertificate(
    $providerId: String!
    $experienceEventId: String
    $certificateTemplateId: String!
    $title: String!
    $description: String
    $issueDateTime: String!
    $startDateTime: String!
    $endDateTime: String!
    $subjects: [String]
  ) {
    getExampleExperienceCertificate(
      providerId: $providerId
      experienceEventId: $experienceEventId
      certificateTemplateId: $certificateTemplateId
      title: $title
      description: $description
      issueDateTime: $issueDateTime
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      subjects: $subjects
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const issueExperienceCertificates = /* GraphQL */ `
  mutation IssueExperienceCertificates(
    $providerId: String!
    $experienceEventId: String!
    $certificateTemplateId: String!
    $title: String!
    $description: String
    $issueDateTime: String!
    $startDateTime: String!
    $endDateTime: String!
    $subjects: [String]!
    $issueDueToFirstLogin: Boolean
  ) {
    issueExperienceCertificates(
      providerId: $providerId
      experienceEventId: $experienceEventId
      certificateTemplateId: $certificateTemplateId
      title: $title
      description: $description
      issueDateTime: $issueDateTime
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      subjects: $subjects
      issueDueToFirstLogin: $issueDueToFirstLogin
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const resendTemporaryPassword = /* GraphQL */ `
  mutation ResendTemporaryPassword($userEmail: String!) {
    resendTemporaryPassword(userEmail: $userEmail) {
      statusCode
      body
      __typename
    }
  }
`;
export const resendConfirmationEmail = /* GraphQL */ `
  mutation ResendConfirmationEmail($userEmail: String!) {
    resendConfirmationEmail(userEmail: $userEmail) {
      statusCode
      body
      __typename
    }
  }
`;
export const createCheckoutSession = /* GraphQL */ `
  mutation CreateCheckoutSession(
    $eventId: String!
    $priceId: String!
    $stripeId: String
    $quantity: Int
  ) {
    createCheckoutSession(
      eventId: $eventId
      priceId: $priceId
      stripeId: $stripeId
      quantity: $quantity
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const addManualUsersToEvent = /* GraphQL */ `
  mutation AddManualUsersToEvent($eventId: String!, $attendees: String!) {
    addManualUsersToEvent(eventId: $eventId, attendees: $attendees) {
      statusCode
      body
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      userId
      title
      description
      type
      status
      groupsCanRead
      groupsCanUpdate
      createdAt
      updatedAt
      owner
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      userId
      title
      description
      type
      status
      groupsCanRead
      groupsCanUpdate
      createdAt
      updatedAt
      owner
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      userId
      title
      description
      type
      status
      groupsCanRead
      groupsCanUpdate
      createdAt
      updatedAt
      owner
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      method
      stripeTransactionId
      currency
      amount
      status
      invoiceNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      method
      stripeTransactionId
      currency
      amount
      status
      invoiceNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      method
      stripeTransactionId
      currency
      amount
      status
      invoiceNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPendingEmployee = /* GraphQL */ `
  mutation CreatePendingEmployee(
    $input: CreatePendingEmployeeInput!
    $condition: ModelPendingEmployeeConditionInput
  ) {
    createPendingEmployee(input: $input, condition: $condition) {
      id
      userId
      employerId
      emailAddress
      roleName
      accessRights {
        isAdministrator
        isCourseInstructor
        __typename
      }
      courseInstructorDetails {
        phone
        email
        aboutText
        isActive
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updatePendingEmployee = /* GraphQL */ `
  mutation UpdatePendingEmployee(
    $input: UpdatePendingEmployeeInput!
    $condition: ModelPendingEmployeeConditionInput
  ) {
    updatePendingEmployee(input: $input, condition: $condition) {
      id
      userId
      employerId
      emailAddress
      roleName
      accessRights {
        isAdministrator
        isCourseInstructor
        __typename
      }
      courseInstructorDetails {
        phone
        email
        aboutText
        isActive
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deletePendingEmployee = /* GraphQL */ `
  mutation DeletePendingEmployee(
    $input: DeletePendingEmployeeInput!
    $condition: ModelPendingEmployeeConditionInput
  ) {
    deletePendingEmployee(input: $input, condition: $condition) {
      id
      userId
      employerId
      emailAddress
      roleName
      accessRights {
        isAdministrator
        isCourseInstructor
        __typename
      }
      courseInstructorDetails {
        phone
        email
        aboutText
        isActive
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createSignatures = /* GraphQL */ `
  mutation CreateSignatures(
    $input: CreateSignaturesInput!
    $condition: ModelSignaturesConditionInput
  ) {
    createSignatures(input: $input, condition: $condition) {
      id
      employerId
      name
      imageKey
      status
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      events {
        items {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateSignatures = /* GraphQL */ `
  mutation UpdateSignatures(
    $input: UpdateSignaturesInput!
    $condition: ModelSignaturesConditionInput
  ) {
    updateSignatures(input: $input, condition: $condition) {
      id
      employerId
      name
      imageKey
      status
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      events {
        items {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteSignatures = /* GraphQL */ `
  mutation DeleteSignatures(
    $input: DeleteSignaturesInput!
    $condition: ModelSignaturesConditionInput
  ) {
    deleteSignatures(input: $input, condition: $condition) {
      id
      employerId
      name
      imageKey
      status
      groupsCanRead
      groupsCanUpdate
      groupsFullControl
      createdAt
      updatedAt
      employer {
        id
        name
        description
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          country
          __typename
        }
        logo {
          id
          name
          imageKey
          __typename
        }
        contactDetails {
          emailBookings
          phoneBookings
          __typename
        }
        isCourseProvider
        courseProviderDetails {
          description
          whyChooseUs
          websiteURL
          registrationNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        createdAt
        updatedAt
        accountSubscription {
          id
          accountTierID
          discount
          paymentCurrency
          groupsCanRead
          createdAt
          updatedAt
          owner
          __typename
        }
        accreditationLogos {
          nextToken
          __typename
        }
        teamMembers {
          nextToken
          __typename
        }
        owner
        employerGroups {
          nextToken
          __typename
        }
        eventLogos {
          nextToken
          __typename
        }
        pendingTeamMembers {
          nextToken
          __typename
        }
        signatures {
          nextToken
          __typename
        }
        __typename
      }
      events {
        items {
          id
          title
          description
          code
          targetAudience
          isExternal
          externalType
          eventType
          eventMode
          status
          accessLevel
          possibleActions
          providerId
          instructorId
          preRequisites
          proofOfIdentity
          onlineURL
          maximumAttendees
          startDateTime
          endDateTime
          attendanceHours
          cpdHours
          latitude
          longitude
          eventValidityDateTime
          certificatesExpire
          certificateExpiryDateTime
          accreditationLogoId
          signatureId
          eventLogoId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createSystemStatistic = /* GraphQL */ `
  mutation CreateSystemStatistic(
    $input: CreateSystemStatisticInput!
    $condition: ModelSystemStatisticConditionInput
  ) {
    createSystemStatistic(input: $input, condition: $condition) {
      dateTime
      statisticName
      countryCode
      periodDelta
      allTimeTotal
      additionalData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSystemStatistic = /* GraphQL */ `
  mutation UpdateSystemStatistic(
    $input: UpdateSystemStatisticInput!
    $condition: ModelSystemStatisticConditionInput
  ) {
    updateSystemStatistic(input: $input, condition: $condition) {
      dateTime
      statisticName
      countryCode
      periodDelta
      allTimeTotal
      additionalData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSystemStatistic = /* GraphQL */ `
  mutation DeleteSystemStatistic(
    $input: DeleteSystemStatisticInput!
    $condition: ModelSystemStatisticConditionInput
  ) {
    deleteSystemStatistic(input: $input, condition: $condition) {
      dateTime
      statisticName
      countryCode
      periodDelta
      allTimeTotal
      additionalData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUploadedEventRecord = /* GraphQL */ `
  mutation CreateUploadedEventRecord(
    $input: CreateUploadedEventRecordInput!
    $condition: ModelUploadedEventRecordConditionInput
  ) {
    createUploadedEventRecord(input: $input, condition: $condition) {
      id
      title
      description
      providerName
      location
      eventDateTime
      expiryDateTime
      cpdHours
      fileName
      userId
      createdAt
      updatedAt
      owner
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updateUploadedEventRecord = /* GraphQL */ `
  mutation UpdateUploadedEventRecord(
    $input: UpdateUploadedEventRecordInput!
    $condition: ModelUploadedEventRecordConditionInput
  ) {
    updateUploadedEventRecord(input: $input, condition: $condition) {
      id
      title
      description
      providerName
      location
      eventDateTime
      expiryDateTime
      cpdHours
      fileName
      userId
      createdAt
      updatedAt
      owner
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deleteUploadedEventRecord = /* GraphQL */ `
  mutation DeleteUploadedEventRecord(
    $input: DeleteUploadedEventRecordInput!
    $condition: ModelUploadedEventRecordConditionInput
  ) {
    deleteUploadedEventRecord(input: $input, condition: $condition) {
      id
      title
      description
      providerName
      location
      eventDateTime
      expiryDateTime
      cpdHours
      fileName
      userId
      createdAt
      updatedAt
      owner
      user {
        id
        identityId
        emailAddress
        firstName
        lastName
        countryId
        userDetail {
          accessLevel
          dateOfBirth
          location
          jobTitle
          phoneNumber
          __typename
        }
        groupsCanRead
        groupsCanUpdate
        resendTemporaryPassword
        lastAcceptedTandCDateTime
        createdAt
        updatedAt
        employer {
          nextToken
          __typename
        }
        bookings {
          nextToken
          __typename
        }
        experienceRecords {
          nextToken
          __typename
        }
        pendingEmployeeRoles {
          nextToken
          __typename
        }
        uploadedEventRecords {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      identityId
      emailAddress
      firstName
      lastName
      countryId
      userDetail {
        accessLevel
        dateOfBirth
        avatar {
          id
          name
          imageKey
          __typename
        }
        location
        jobTitle
        phoneNumber
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      resendTemporaryPassword
      lastAcceptedTandCDateTime
      createdAt
      updatedAt
      employer {
        items {
          id
          userId
          employerId
          roleName
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      bookings {
        items {
          id
          userId
          eventId
          status
          seatNumber
          attendance
          certificateSent
          certificateSaved
          bookingDateTime
          stripeSessionId
          paymentId
          groupsCanRead
          groupsFullControl
          blockAdminCreateNotification
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      experienceRecords {
        items {
          id
          title
          description
          issueDateTime
          startDateTime
          endDateTime
          providerId
          nameProvided
          userId
          experienceEventId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      pendingEmployeeRoles {
        items {
          id
          userId
          employerId
          emailAddress
          roleName
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      uploadedEventRecords {
        items {
          id
          title
          description
          providerName
          location
          eventDateTime
          expiryDateTime
          cpdHours
          fileName
          userId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      identityId
      emailAddress
      firstName
      lastName
      countryId
      userDetail {
        accessLevel
        dateOfBirth
        avatar {
          id
          name
          imageKey
          __typename
        }
        location
        jobTitle
        phoneNumber
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      resendTemporaryPassword
      lastAcceptedTandCDateTime
      createdAt
      updatedAt
      employer {
        items {
          id
          userId
          employerId
          roleName
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      bookings {
        items {
          id
          userId
          eventId
          status
          seatNumber
          attendance
          certificateSent
          certificateSaved
          bookingDateTime
          stripeSessionId
          paymentId
          groupsCanRead
          groupsFullControl
          blockAdminCreateNotification
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      experienceRecords {
        items {
          id
          title
          description
          issueDateTime
          startDateTime
          endDateTime
          providerId
          nameProvided
          userId
          experienceEventId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      pendingEmployeeRoles {
        items {
          id
          userId
          employerId
          emailAddress
          roleName
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      uploadedEventRecords {
        items {
          id
          title
          description
          providerName
          location
          eventDateTime
          expiryDateTime
          cpdHours
          fileName
          userId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      identityId
      emailAddress
      firstName
      lastName
      countryId
      userDetail {
        accessLevel
        dateOfBirth
        avatar {
          id
          name
          imageKey
          __typename
        }
        location
        jobTitle
        phoneNumber
        __typename
      }
      groupsCanRead
      groupsCanUpdate
      resendTemporaryPassword
      lastAcceptedTandCDateTime
      createdAt
      updatedAt
      employer {
        items {
          id
          userId
          employerId
          roleName
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      bookings {
        items {
          id
          userId
          eventId
          status
          seatNumber
          attendance
          certificateSent
          certificateSaved
          bookingDateTime
          stripeSessionId
          paymentId
          groupsCanRead
          groupsFullControl
          blockAdminCreateNotification
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      experienceRecords {
        items {
          id
          title
          description
          issueDateTime
          startDateTime
          endDateTime
          providerId
          nameProvided
          userId
          experienceEventId
          certificateTemplateId
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      pendingEmployeeRoles {
        items {
          id
          userId
          employerId
          emailAddress
          roleName
          groupsCanRead
          groupsCanUpdate
          groupsFullControl
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      uploadedEventRecords {
        items {
          id
          title
          description
          providerName
          location
          eventDateTime
          expiryDateTime
          cpdHours
          fileName
          userId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://2bxheheuh5fvtg4lmbhp74vidi.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-p4honklstrd3rck4zxchsu7yxq",
    "aws_cognito_identity_pool_id": "eu-west-1:689deb82-32fb-4fcb-8b6a-6d28db064308",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_rQagiBpci",
    "aws_user_pools_web_client_id": "5dvafr7dm0ae6i7dqjtu3occ9e",
    "oauth": {
        "domain": "mppsso-production.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://launch.mypropass.co.uk/sso-callback/,http://localhost:8080/sso-callback/,https://dev.mypropass.co.uk/sso-callback/,https://staging.mypropass.co.uk/sso-callback/,https://demo.mypropass.co.uk/sso-callback/",
        "redirectSignOut": "https://launch.mypropass.co.uk/sso-callback/,http://localhost:8080/sso-callback/,https://dev.mypropass.co.uk/sso-callback/,https://staging.mypropass.co.uk/sso-callback/,https://demo.mypropass.co.uk/sso-callback/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "mppweb5869f09c00d1420e9cc4168a90cf8c79104644-production",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;


<template>
  <v-container>
    <loading :active.sync="isProcessing" :is-full-page="true" loader="dots"></loading>
  </v-container>
</template>

<script>
import { Auth } from 'aws-amplify';
import * as StoreActions from '../constants/StoreActions'
import AuthService from '../services/auth.service'
import UserService from '../services/user.service'

export default {
  data() {
    return {
      isProcessing: true,
    }
  },


  methods: {
    async saveUserAndLogin(user, alertUser) {
      // Store the cognito information in the store
      this.$store.commit(StoreActions.SET_COGNITO, user);
      // Make a call to the user service to get the users details from the database
      // Fetch all the events that are currently available
      await Promise.all([
        new UserService(this.$store).GetDetails(user.attributes.sub)
      ]).catch(function(error) {
        console.log(error)
        if (alertUser) {
          alert(`Unable to Login: Could not fetch data from server.  Please try again.`);
        }
        this.isProcessing = false;
        return
      });

      // Ask the router to navigate to the user landing page
      this.isProcessing = false;
      this.$router.push({ name: 'UserLanding' })
    },
  },


  async mounted() {
    // Get the args from the
    const routeQuery = this.$route.query
    console.log("routeQuery:", routeQuery)

    // If this is the Google system responding that this user is already existing
    // because we created a linked Cognito User at the same time as the Google user,
    // then call the Google login system
    if (routeQuery.error === "invalid_request") {
      if (routeQuery.error_description.includes('Already found an entry for username')) {

        if (routeQuery.error_description.toLowerCase().includes('google')) {
          await Auth.federatedSignIn({ provider: 'Google' })
        }

        if (routeQuery.error_description.toLowerCase().includes('facebook')) {
          console.log("Got a Facebook already found")
        }

      }
    }
    // If this is a callback from a secondary login, get the current user
    if (routeQuery.code) {
      try {
        const user = await AuthService.GetCurrentAuthenticatedUser();
        console.log(user)
        console.log("User is already logged in")
        await this.saveUserAndLogin(user, false)
      } catch (error) {
        console.log("User is not logged in", error)
      }
    }

    if (Object.keys(routeQuery).length === 0) {
      console.log("User has just logged out")
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>

<style scoped>

</style>

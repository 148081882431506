
<template>
  <v-container>
    <loading :active="!is_data_fetched" :is-full-page="true" loader="dots"></loading>
    <v-layout wrap justify-center v-if="is_data_fetched">
      <v-flex xs12 md6>
        <div class="ABC_in_Scaffolding">
          <span>Issue Certificates</span><br />
          <dl class="Duration__1_hour d-list">
            <dt>Course Name:</dt><dd>{{ event.title }}</dd>
            <dt>Date of Course:</dt><dd>{{ eventDate }}</dd>
            <dt>Location:</dt><dd>{{ eventLocation }}</dd>
          </dl>
        </div>
      </v-flex>
      <v-flex xs12 md1 offset-md5 class="actions">
        <a>
          <img :src="require('../images/export.png')" @click="generatePdf" style="margin-bottom:5px;height:18px;width:18px" />
        </a>
        <!--
        <a>
          <img :src="require('../images/printer.png')" @click="print" style="margin-bottom:5px;height:18px;width:18px" />
        </a>
        -->
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card class="container-rounded-mini">
          <v-layout row wrap justify-center>
            <v-flex xs10>
              <h6 style="text-align:center;margin-top:10px;color: #5a6f95"><b>Attendance</b></h6>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 mt-3>
        <v-data-table :headers="headers"
                      :items="attendeeList"
                      hide-actions
                      :pagination.sync="pagination"
                      class="table" id="printMe">
          <template slot="headerCell" slot-scope="{ header }">
            <span class="subheading font-weight-bold text--darken-3" v-text="header.text" />
          </template>
          <template v-slot:items="props">
            <td>{{ props.item.seatNumber }}</td>
            <td>{{ props.item.user.firstName }} {{ props.item.user.lastName }}</td>
            <td style="width: 40%;">{{ props.item.id }}</td>
            <td style="text-align:center">
              <v-icon v-if="props.item.attendance === BOOKING_ATTENDANCE.ATTENDED" color="rgb(90, 179, 22)" style="max-width: 16px;">check_circle_fill</v-icon>
              <v-icon v-if="props.item.attendance !== BOOKING_ATTENDANCE.ATTENDED" color="red">cancel</v-icon>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <div style="background: #fff">
      <v-layout nowrap pt-3 pr-3>
        <v-flex xs12 text-right>
          <v-pagination style="vertical-align:top;" v-model="pagination.page" :length="pages"></v-pagination>
        </v-flex>
      </v-layout>
      <br />
      <v-layout justify-center>
        <v-dialog persistent v-model="messagedialog.show" transition="dialog-transition" max-width="450">
          <v-card>
            <v-card-text>
              <v-flex>
                <div id="message" style="font-weight:normal;" v-html="messagedialog.message" />
              </v-flex>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="CloseMessageDialog" small class="v-btn-secondary">Cancel</v-btn>
              <v-btn @click="SendCertificatesToAttendees" small color=rgb(0,171,173)>OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog persistent v-model="errormessagedialog.show" transition="dialog-transition" max-width="450">
          <v-card>
            <v-card-text>
              <v-flex>
                <div id="message" style="font-weight:normal;" v-html="errormessagedialog.message" />
              </v-flex>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="CloseErrorMessageDialog" small color=rgb(0,171,173)>OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


      </v-layout>
      <v-layout nowrap pb-3 pr-3>
        <v-flex xs12 offset-md9 md3 class="text-right">
          <!--<router-link :to="{ name: 'ProviderIssueCertificatesStep1', params: { eventId: this.event.id } }">
            <span style="color:rgba(254,138,125,1)">Modify</span>
          </router-link>-->
          <v-btn color="rgb(254,138,125)" small @click="modify">
            Modify
          </v-btn>
          <v-btn color="rgba(254,138,125,1)" small @click="OnSendCertificatesToAttendeesClick" style="margin-right: 0">
            Send Certificates to Attendees
          </v-btn>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>


<script>
  import BookingService from '@/services/booking.service'
  import EventService from '@/services/event.service'
  import UserService from '@/services/user.service'
  import * as EventHelper from '@/helpers/event.helper'

  import { LAYOUTS } from '@/constants/LayoutConstants'
  import { EVENT_MODE } from '@/constants/EventConstants'
  import { BOOKING_ATTENDANCE, BOOKING_STATUS } from '@/constants/BookingConstants'
  import { PROVIDER_ALL_EVENTS_PAGE_PARAMETERS } from '@/constants/PageParameterConstants'
  //import ProviderService from '@/services/provider.service'
  import jsPDF from 'jspdf'
  import 'jspdf-autotable';
  import { SplitUpArray } from '@/helpers/arrays.helper'

  export default {

    components: {
    },


    data() {
      return {
        is_data_fetched: false,
        error_sending_certificates: false,
        search: '',
        pagination: {
          rowsPerPage: 10,
          totalItems: 0,
          sortBy: "id"
        },
        messagedialog: {
          show: false,
          message: ''
        },
        errormessagedialog: {
          show: false,
          message: ''
        },
        headers: [
          { text: 'S.No', align: 'left', value: 'sNo' },
          { text: 'Name', align: 'center', value: 'name' },
          { text: 'Registration No', align: 'center', value: 'id' },
          { text: 'Attendance', align: 'center', value: 'Attendance' }
        ],
        selfBookedAttendees: [],
        manualAttendees: [],
        event: {},
        BOOKING_ATTENDANCE
      }
    },

    watch: {
      attendeeList: function(newVal) {
        this.pagination.totalItems = newVal.length;
      }
    },


    methods: {
      generatePdf: function () {
        // TODO: FUTURE: Add more content to the PDF pages (headings, file name, etc)
        var doc = new jsPDF();
        var rows = [];
        this.attendeeList.forEach(element => {
          var temp = [element.seatNumber, element.user.firstName + ' ' + element.user.lastName, element.id];
          rows.push(temp);
        });
        doc.autoTable({
          head: [['S.No', 'Name', 'Registration No']],
          body: rows
        });
        doc.save();
      },

      /*
      print: function () {
        var doc = new jsPDF();
        var rows = [];
        this.attendeeList.forEach(element => {
          var temp = [element.seatNumber, element.user.firstName + ' ' + element.user.lastName, element.id];
          rows.push(temp);
        });
        doc.autoTable({
          head: [['S.No', 'Name', 'Registration No']],
          body: rows
        });
        doc.autoPrint();
        doc.output('dataurlnewwindow');
      },
      */

      modify() {
        this.$router.push({
          name: `${ this.$store.getters.layout === LAYOUTS.ADMIN ? 'AdminIssueCertificatesStep1' : 'ProviderIssueCertificatesStep1' }`,
          params: {
            eventId: this.event.id,
            status: this.$route.params.status,
            selfBookedAttendees: this.selfBookedAttendees,
            manualAttendees: this.manualAttendees
          },
          query: this.$route.query });
      },

      CloseMessageDialog() {
        this.messagedialog.message = ''
        this.messagedialog.show = false;
      },

      CloseErrorMessageDialog() {
        this.errormessagedialog.message = ''
        this.errormessagedialog.show = false;
      },

      OnSendCertificatesToAttendeesClick() {
        this.messagedialog.message = "Are you sure you want to send certificates?<br /><br />Once sent, the event attendance cannot be modified further.<br /><br /><strong>NOTE:</strong> This process may take up to five minutes to complete."
        this.messagedialog.show = true;
      },

      async CreateManualAttendees(eventId, manualAttendees) {
        // Create the services required
        const eventService = new EventService(this.$store)
        if (manualAttendees.length > 50) {
          console.warn("More than 50 attendees given to CreateManualAttendees function.  This might cause issues.  Please break the attendees into smaller batches.")
        }
        return await eventService.RegisterManualAttendees(eventId, manualAttendees)
      },


      async UpdateBookingStatesForSelfBookedAttendees() {
        // Update the database with all the attended and no-show statuses for the self-booked attendees
        await Promise.all(
          this.selfBookedAttendees.map(booking => {
            const parameters = {
              id: booking.id,
              attendance: booking.attendance,
              status: BOOKING_STATUS.COMPLETED
            }
            return BookingService.UpdateBookingStatus(parameters)
          })
        ).catch(function(error) {
          console.warn("Error saving all attendance statuses:", error)
          this.error_saving_attendance = true
          this.errormessagedialog.message = "There was an error sending certificates to the users.  Please try again."
          this.errormessagedialog.show = true
          this.is_data_fetched = true
          return "FAILED"
        });
        return "SUCCESS"
      },

      // TODO: Make this entire process asynchronous by uploading the CSV file to S3 and letting it run
      SendCertificatesToAttendees: async function () {
        this.is_data_fetched = false
        this.messagedialog.message = ''
        this.messagedialog.show = false

        // Create the services required
        const eventService = new EventService(this.$store)
        const userService = new UserService(this.$store)

        let manualAttendeeBookingIds = []
        let iteration = 0
        // Tell the server about the manual attendees so they can be processed
        try {
          const splitManualAttendees = SplitUpArray(this.manualAttendees, 50)
          //console.log("splitManualAttendees:", splitManualAttendees)

          do {
            console.log(`Processing chunk ${iteration + 1} of ${splitManualAttendees.length}`)
            // Get the current chunk data
            const currentChunk = splitManualAttendees[iteration]
            // Create the promises for this chunk
            const loopResult = await this.CreateManualAttendees(this.event.id, currentChunk)
            // Add the results to the return array
            manualAttendeeBookingIds = manualAttendeeBookingIds.concat(JSON.parse(loopResult))
            // Update the iteration count
            iteration = iteration + 1
          } while (iteration < splitManualAttendees.length)
        } catch (err) {
          console.warn("Error performing manual attendee addition;", err)
          this.error_saving_attendance = true
          this.errormessagedialog.message = "There was an error creating the manual users.  Please try again."
          this.errormessagedialog.show = true
          this.is_data_fetched = true
          return
        }

        // Update the Booking states for the self-booked attendees
        const updateStatesForSelfBookedAttendeesResult = await this.UpdateBookingStatesForSelfBookedAttendees()
        // If there was an error doing this step, stop
        if (updateStatesForSelfBookedAttendeesResult === "FAILED") {
          return
        }

        // Get all the self booked attendees who need certificates
        const attendedSelfBookedAttendees = this.selfBookedAttendees.filter(booking => {
          return booking.attendance === BOOKING_ATTENDANCE.ATTENDED
        })
        // Extract all the booking IDs that will receive certificates
        const attendedSelfBookedAttendeesBookingIds = attendedSelfBookedAttendees.map(booking => {
          return booking.id
        })

        // Merge all the booking IDs together
        const allBookingIds = attendedSelfBookedAttendeesBookingIds.concat(manualAttendeeBookingIds)
        console.log("allBookingIds:", allBookingIds)

        // Send the certificates to the manual and self-booked attendees
        let resultArray = []
        iteration = 0
        try {
          const splitBookingIDs = SplitUpArray(allBookingIds, 50)
          console.log("splitBookingIDs:", splitBookingIDs)
          do {
            console.log(`Processing chunk ${iteration + 1} of ${splitBookingIDs.length}`)
            // Get the current chunk data
            const currentChunk = splitBookingIDs[iteration]
            // Create the promises for this chunk
            const loopResult = await BookingService.SendCertificatesToAttendees(this.event.id, currentChunk)
            // Add the results to the return array
            resultArray = resultArray.concat(loopResult)
            // Update the iteration count
            iteration = iteration + 1
          } while (iteration < splitBookingIDs.length)
        } catch (err) {
          console.warn("Error performing manual attendee addition;", err)
          this.error_saving_attendance = true
          this.errormessagedialog.message = "There was an error sending certificates to the users.  Please try again."
          this.errormessagedialog.show = true
          this.is_data_fetched = true
          return
        }

        // Now mark the event to indicate that certificates were sent out
        await eventService.MarkEventAsCertificatesSent(this.event.id)
        await Promise.all([
          eventService.GetFutureEventsForEmployee(),
          eventService.GetPastEventsForEmployee(),
          userService.GetDetails()
        ])
        this.is_data_fetched = true
        // Now navigate back to the all courses page
        this.$router.push({
          name: `${ this.$store.getters.layout === LAYOUTS.ADMIN ? 'AdminAllEvents' : 'ProviderAllEvents' }`,
          params: {
            status: PROVIDER_ALL_EVENTS_PAGE_PARAMETERS.RECORDS
          },
          query: this.$route.query
        });
      }
    },


    computed: {
      pages() {
        if (this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        ) return 0
        return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
      },
      eventLocation: function() {
        try {
          if (this.event.eventMode === EVENT_MODE.INPERSON) return EventHelper.FormatAddressObjectToString(this.event.address)
          return "Online"
        } catch (err) {
          return "Error fetching location"
        }
      },
      eventDate: function() {
        try {
          return ((new Date(this.event.startDateTime)).toLocaleString()).slice(0,-3)
        } catch (err) {
          return "Error fetching date"
        }
      },
      attendeeList: function() {
        try {
          // Remove all self booked attendees on waiting list or who have cancelled
          var tempAttendeeList = this.selfBookedAttendees.filter((booking) => {
            return [ BOOKING_STATUS.BOOKED, BOOKING_STATUS.COMPLETED ].includes(booking.status)
          })
          // Set all attendees as no shows if they are currently marked as event not yet held
          tempAttendeeList = tempAttendeeList.map(item => {
            return {
              ...item,
              attendance: item.attendance === BOOKING_ATTENDANCE.EVENTNOTYETHELD ? BOOKING_ATTENDANCE.NOSHOW : item.attendance
            }
          })
          // Create the attendee entries for manual attendees
          const manualAttendees = this.manualAttendees.map((attendee) => {
            return {
              id: "manual",
              seatNumber: "",
              user: {
                firstName: attendee.firstName,
                lastName: attendee.lastName
              },
              attendance: BOOKING_ATTENDANCE.ATTENDED
            }
          })
          return tempAttendeeList.concat(manualAttendees)
        } catch (err) {
          console.warn("Error fetching attendeeList;", err)
          return []
        }
      }
    },


    async mounted() {
      try {
        const { selfBookedAttendees, manualAttendees, eventDetails } = this.$route.params
        // Add the attendee list to the data
        this.selfBookedAttendees = selfBookedAttendees
        this.manualAttendees = manualAttendees
        this.event = eventDetails
      } catch (err) {
        console.warn("Unable to fetch event attendees", err)
        this.messagedialog.message = "There was an error loading this page.  Please go back to step 1 and try again."
        this.messagedialog.show = true
      }
      this.is_data_fetched = true;
    }
  }
</script>

<style scoped>

  .v-btn {
    text-decoration: none;
    text-transform: capitalize;
  }
  /* style the container */
  .container {
    position: relative;
    border-radius: 5px;
    padding: 20px 0 30px 0;
  }

  /* bottom container */
  .bottom-container {
    text-align: center;
    background-color: #666;
    border-radius: 0px 0px 4px 4px;
  }

  body {
    background-color: rgba(243,246,248,1);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  .container-rounded-mini {
    background-color: #FFFFFF;
    min-height: 40px;
    border: solid 1px #ccc !important;
    border-radius: 3px;
  }

  .container-rounded {
    background-color: #FFFFFF;
    padding-left: 50px;
    margin: 40px;
    min-height: 350px;
  }

  .table {
    margin-bottom: 0 !important;
  }

    .table table {
      border-radius: 10px;
    }

      .table table thead th, .table table tbody td {
        text-align: center
      }

    .table tbody tr {
      border-bottom: none !important;
    }

      .table tbody tr td, .table td, .table th {
        border-top: none !important;
      }

  .actions {
    text-align: right;
  }

    .actions a {
      margin-left: 10px;
    }

  th.column {
    vertical-align: middle !important;
  }

  th .subheading {
    font-size: 15px !important;
  }

  .table tbody td {
    padding-top: 10px !important;
  }

  .table th:nth-child(1), .table td:nth-child(1) {
    width: 10%;
  }

  .table th:nth-child(2), .table td:nth-child(2) {
    width: 20%;
  }

  .table th:nth-child(3), .table td:nth-child(3) {
    width: 20%;
  }

</style>

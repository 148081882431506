import Vue from 'vue'
import Vuex from 'vuex'
import router from './router/index'
import store from './store'
import { sync } from 'vuex-router-sync'
import App from './components/app-root'
import { FontAwesomeIcon } from './icons'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import ColourPicker from 'vue-colour-picker'
import VueLoadingOverlay from 'vue-loading-overlay'
import VueSocialSharing from 'vue-social-sharing'

import 'vue-loading-overlay/dist/vue-loading.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Geocoder from "@pderas/vue2-geocoder";

// Amplify stuff
import { Amplify } from 'aws-amplify'
import awsConfig from './aws-exports';
import { isLocalhost, isDevhost, isDemohost, isStaginghost } from '@/constants/Validations'

/*
https://launch.mypropass.co.uk/sso-callback/,
http://localhost:8080/sso-callback/,
https://dev.mypropass.co.uk/sso-callback/,
https://staging.mypropass.co.uk/sso-callback/,
https://demo.mypropass.co.uk/sso-callback/
*/

// Function to get the correct callback URI for social sign on
const getCallbackUrl = (urls) => {
  //console.log("Main URLS:", urls)
  //console.log("window.location.hostname:", window.location.hostname)
  const splitUrls = urls.split(",")
  if (isLocalhost) { return splitUrls[1] }
  if (isDevhost) { return splitUrls[2] }
  if (isStaginghost) { return splitUrls[3] }
  if (isDemohost) { return splitUrls[4] }
  return splitUrls[0]
}


// Update AWS config with the correct social sign on URIs
const redirectSignIn = getCallbackUrl(awsConfig.oauth.redirectSignIn)
const redirectSignOut = getCallbackUrl(awsConfig.oauth.redirectSignOut)
//console.log("redirectSignIn:", redirectSignIn)
//console.log("redirectSignOut:", redirectSignOut)
const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn,
    redirectSignOut
  }
}

// Configure Amplify
Amplify.configure(updatedAwsConfig);


Vue.use(Vuex)
Vue.use(Vuetify);
Vue.use(ColourPicker);
Vue.use(VueSocialSharing);


// Creation of GeoCoder
Vue.use(Geocoder, {
    defaultCountryCode: null, // e.g. 'CA'
    defaultLanguage:    null, // e.g. 'en'
    defaultMode:        'address', // or 'lat-lng'
    googleMapsApiKey:   process.env.VUE_APP_GOOGLE_MAPS_API_KEY
});


// Registration of global components
Vue.component('icon', FontAwesomeIcon)
Vue.component('loading', VueLoadingOverlay)
sync(store, router)

Vue.config.productionTip = false

//console.log(process.env)
//console.log("hostname:", window.location.hostname)

/*
function gtag(){
  window.dataLayer.push(arguments);
}

if (window.location.hostname !== "launch.mypropass.co.uk") {
  console.log("Setting up developer mode on datalayer")
  gtag('config', 'GTM-KB9TVXX', { 'debug_mode':true });
}
*/

const app = new Vue({
  store,
  router,
  el: '#app',
  ...App
})

// TODO: URGENT: Upgrade to API v2 (https://github.com/aws-amplify/amplify-cli/issues/5018)

export {
  app,
  router,
  store
}

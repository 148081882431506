<template>
  <v-container pa-0 ma-0 fluid v-if="is_data_fetched" class="vld-parent">
    <loading :active.sync="isLoading" :is-full-page="fullPage" loader="dots"></loading>
    <v-layout ma-0 justify-center align-content-center>
      <v-flex xs12>
        <v-container fluid grid-list-md>
          <v-layout row>
            <v-flex xs12 offset-xs8 md1 offset-md11>
              <div style="text-align: right">
                <a>
                  <img :src="require('../images/export.png')" @click="generatePdf" style="margin-bottom:5px;height:20px;width:20px" />
                </a>
                <!--
                &nbsp;&nbsp;
                <a>
                  <img :src="require('../images/printer.png')" @click="print" style="margin-bottom:5px;height:20px;width:20px" />
                </a>
                -->
              </div>
            </v-flex>
          </v-layout>
          <v-layout grid-container column pa-2 ma-0>
            <v-flex md1>
              <v-layout>
                <v-flex xs12 md6>
                  <h5 class="page-header" v-if="isEventsPage">My Scheduled Events</h5>
                  <h5 class="page-header" v-if="isRecordsPage">My Records</h5>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex grid-search xs12 md3 offset-md9>
                  <v-text-field v-model="searchText"
                                append-icon="search"
                                label="Search for an event or provider"
                                single-line
                                hide-details></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex md10>

              <v-data-table :headers="computedHeaders"
                            :items="bookings"
                            :search="searchText"
                            hide-actions
                            :pagination.sync="pagination"
                            :custom-sort="CustomSort"
                            :custom-filter="CustomFilter"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            disable-initial-sort
                            class="elevation-1">
                <template slot="headerCell" slot-scope="{ header }">
                  <span class="table-head font-weight-bold text--darken-3" v-text="header.text" />
                </template>
                <template v-slot:items="props">
                  <td>
                    <div v-if="props.item.isUploaded">{{ props.item.event.title }}</div>
                    <a v-if="!props.item.isUploaded" href="#" style="color:rgb(0,171,173);" @click="ShowManageBookingDialog(props.item)">{{ props.item.event.title }}</a>
                  </td>
                  <td v-if="isRecordsPage">
                    <div style='font-size: 22px;'>
                      <a v-if="bookingHasCertificate(props.item)" @click="GetCertificate(props.item)"><img src="../images/file-certificate.svg" alt="Certificate" style="height:22px;width:22px; transform:translateY(-2px); opacity: 0.67;" /></a>
                      <a v-if="props.item.isUploaded" @click="EditUploadedRecord(props.item)"><i class="far fa-edit" style="margin-left: 14px; color: rgba(0,0,0,0.67)"/></a>
                      <a v-if="props.item.isUploaded" @click="DeleteUploadedRecord(props.item)"><i class="far fa-trash-alt" style="margin-left: 10px; color: rgba(0,0,0,0.67)" /></a>
                    </div>
                  </td>
                  <td>{{ getDateFromDateTime(props.item.event.startDateTime) }}</td>
                  <td>
                    <template v-if="!props.item.isUploaded">{{ getTimeFromDateTime(props.item.event.startDateTime) }}</template>
                    <template v-if="props.item.isUploaded">-</template>
                  </td>
                  <td>{{ getDurationFromEvent(props.item, true) }}</td>
                  <td v-if="isEventsPage">
                    <div>
                      <span>{{ getEventLocation(props.item.event) }}</span>
                    </div>
                  </td>
                  <td v-if="isRecordsPage">
                    <div>
                      <span>{{ getCertificateExpiry(props.item) }}</span>
                    </div>
                  </td>
                  <td>{{ getBookingStatus(props.item) }}</td>
                </template>
              </v-data-table>


              <v-dialog v-model="managebookingdialog.show" transition="dialog-transition" max-width="70%">
                <user-managebooking :parentData="managebookingdialog.booking" :onClose="CloseManageBookingDialog"></user-managebooking>
              </v-dialog>
              <v-layout>
                <v-flex xs12 sm1 v-if="isEventsPage" style="margin-top:6px;">
                  <label class="col-sm-3 control-label" for="status">Status:</label>
                </v-flex>
                <v-flex xs12 sm3 text-left v-if="isEventsPage" style="margin-top:6px;">
                  <select class="form-control" id="selectedStatus" v-bind:value="selectedStatus" @change="onFilterByStatusChange($event)" style="height: auto;padding:3px 10px !important">
                    <option value="ALL">All</option>
                    <option v-for="bookingStatus in BOOKING_STATUS" v-bind:key="bookingStatus" v-bind:value="bookingStatus">
                      {{ getBookingStatusOption(bookingStatus) }}
                    </option>
                  </select>
                </v-flex>
                <v-flex xs12 text-right>
                  <v-btn color="rgb(0,171,173)" @click="UploadCertificate" v-if="isRecordsPage" style="vertical-align:top;height: 28px;">
                    Upload Certificate
                  </v-btn>
                  <v-pagination style="vertical-align:top;" v-model="pagination.page" :length="pages"></v-pagination>
                </v-flex>
              </v-layout>
              <v-layout row justify-center>
                <v-dialog persistent v-model="certificatedialog.show" transition="dialog-transition" max-width="450">
                  <v-card>
                    <v-card-text>
                      <v-flex>
                        Certificate ready to view.
                      </v-flex>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn @click="DownloadCertificate" small color=rgb(0,171,173)>View</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="CloseCertificateDialog" small color=rgb(0,171,173)>Close</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>


      <v-dialog persistent v-model="deleteUploadedEventDialog.show" transition="dialog-transition" max-width="450">
        <v-card>
          <v-card-text>
            <v-flex>
              <div id="message" style="font-weight:normal;">{{ deleteUploadedEventDialog.message }}</div>
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeDeleteUploadedEventDialog" small color=rgb(0,171,173)>Cancel</v-btn>
            <v-btn @click="confirmDeleteUploadedEvent" small color=rgb(0,171,173)>Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="deleteUploadedEventDialog.show" transition="dialog-transition" max-width="450">
        <v-card>
          <v-card-text>
            <v-flex>
              <div id="message" style="font-weight:normal;">{{ deleteUploadedEventDialog.message }}</div>
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="!deleteUploadedEventDialog.preDelete" @click="closeDeleteUploadedEventDialog" small color=rgb(0,171,173)>OK</v-btn>
            <v-btn v-if="deleteUploadedEventDialog.preDelete" @click="closeDeleteUploadedEventDialog" small color=rgb(0,171,173)>Cancel</v-btn>
            <v-btn v-if="deleteUploadedEventDialog.preDelete" @click="confirmDeleteUploadedEvent" small color=rgb(0,171,173)>Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


    </v-layout>
  </v-container>
</template>


<script>

  import CertificateService from '../services/certificate.service'
  import ExperienceCertificateService from '@/services/experienceCertificate.service'
  import UserManageBooking from './user-managebooking'
  import jsPDF from 'jspdf'

  import UserService from '../services/user.service'
  import UploadedRecordService from '../services/uploadedrecord.service'

  import { GetTimeFromDateTime, GetLocaleDateFromDateTime } from '../helpers/date.helper'
  import { EventDurationInMinutes } from '../helpers/event.helper'
  import { FormatDuration, DESIRED_DURATIONS } from '../helpers/duration.helper'
  import { USER_EVENTS_PAGE_PARAMETERS } from '../constants/PageParameterConstants'
  import { BOOKING_ATTENDANCE, BOOKING_STATUS, /*BOOKING_CERTIFICATE_STATUS*/ } from '../constants/BookingConstants'
  import { EVENT_STATUS, EVENT_MODE, EVENT_TYPE } from '../constants/EventConstants'
  import { USER_EDIT_UPLOADED_EVENT_RECORD_STATUS } from '../constants/PageParameterConstants'

  const EVENT_BOOKING_DISPLAY_TEXT = {
    ALL: 'All',
    ONWAITINGLIST: 'On Waiting list',
    BOOKED: 'Scheduled',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled'
  }


  export default {
    components: {
      'user-managebooking': UserManageBooking
    },
    data() {
      return {
        is_data_fetched: false,
        isLoading: false,
        fullPage: true,
        certificatedialog: {
          show: false,
          certificatepath: '',
          message: ''
        },
        cancelbookingdialog: {
          show: false,
          bookingId: 0
        },
        managebookingdialog: {
          show: false,
          duration: ''
        },
        deleteUploadedEventDialog: {
          show: false,
          message: "",
          deleteId: "",
          preDelete: true,
        },
        searchText: '',
        sortBy: 'eventDate',
        sortDesc: true,
        selectedStatus: "ALL",
        pagination: {
          rowsPerPage: 10,
          totalItems: 0,
          sortBy: 'eventDate',
          page: 1,
        },
        headers: [
          { text: 'Event',        align: 'left',    value: 'title'                                                                      },
          { text: 'Certificate',  align: 'center',  value: 'options', status: USER_EVENTS_PAGE_PARAMETERS.RECORDS, sortable: false      },
          { text: 'Date',         align: 'center',  value: 'eventDate'                                                                  },
          { text: 'Time',         align: 'center',  value: 'startTime'                                                                  },
          { text: 'Duration',     align: 'center',  value: 'duration'                                                                   },
          { text: 'Location',     align: 'center',  value: 'location', status: USER_EVENTS_PAGE_PARAMETERS.EVENTS                       },
          { text: 'Expiry',       align: 'center',  value: 'expires', status: USER_EVENTS_PAGE_PARAMETERS.RECORDS                       },
          { text: 'Status',       align: 'center',  value: 'status'                                                                     },
        ],
        //events: []
        BOOKING_STATUS
      }
    },

    watch: {
      bookings: function(newValue) {
        //console.log("Bookings:", newValue)
        this.pagination.totalItems = newValue.length
      },
      'pagination.page': function(newValue) {
        //console.log("WATCHER: pagination page value updated", newValue)
        // Check if the user is getting close to the end of the fetched items
        if (newValue + 1 >= Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)) {
          // Bookings refer to future and past events, so only fetch those
          new UserService(this.$store).ExtendBookingsForUser()
        }
      },
    },

    methods: {
      CustomSort(items, index, isDesc) {

        try {
          const result = items.sort((a, b) => {
            this.sortBy = index;
            this.sortDesc = isDesc;

            // Title Sort
            if (index === "title") {
              if (!isDesc) { return a.event.title < b.event.title ? -1 : 1 }
              return a.event.title > b.event.title ? -1 : 1
            }

            // Event Date Sort
            if (index === "eventDate") {
              if (!isDesc) { return (new Date(a.event.startDateTime)) < (new Date(b.event.startDateTime)) ? -1 : 1; }
              return (new Date(a.event.startDateTime)) > (new Date(b.event.startDateTime)) ? -1 : 1;
            }

            // Start Time Sort
            if (index === "startTime") {
              const aTimeOnly = a.event.startDateTime.split('T')[1]
              const bTimeOnly = b.event.startDateTime.split('T')[1]
              if (!isDesc) { return aTimeOnly < bTimeOnly ? -1 : 1 }
              return aTimeOnly > bTimeOnly ? -1 : 1
            }

            // Duration Sort
            if (index === "duration") {
              const aDuration = this.getDurationFromEvent(a, true)
              const bDuration = this.getDurationFromEvent(b, true)
              if (!isDesc) { return aDuration < bDuration ? -1 : 1 }
              return aDuration > bDuration ? -1 : 1
            }

            // Location Sort
            if (index === "location") {
              const aLocation = this.getEventLocation(a.event)
              const bLocation = this.getEventLocation(b.event)
              if (!isDesc) { return aLocation < bLocation ? -1 : 1 }
              return aLocation > bLocation ? -1 : 1
            }

            // Expires Sort
            if (index === "expires") {
              const aDateTime = a.event.certificateExpiryDateTime
              const bDateTime = b.event.certificateExpiryDateTime
              if (!isDesc) { return aDateTime < bDateTime ? -1 : 1 }
              return aDateTime > bDateTime ? -1 : 1
            }

            // Status Sort
            if (index === "status") {
              const aStatus = this.getBookingStatus(a)
              const bStatus = this.getBookingStatus(b)
              if (!isDesc) { return aStatus < bStatus ? -1 : 1 }
              return aStatus > bStatus ? -1 : 1
            }

          });
          return result;
        } catch (e) {
          console.warn("Error when custom sorting:", e)
        }
      },

      CustomFilter(items) {
        try {
          if (!this.searchText) { return items }
          const itemsToFilter = items.filter((item) => {
            return item.event.title.toLowerCase().includes(this.searchText.toLowerCase())
          });
          return itemsToFilter;
        } catch (e) {
          console.warn("Error when custom filtering;", e)
          return items
        }
      },

      onFilterByStatusChange(event) {
        //console.log("On Status Change triggered")
        //console.log(event.target.value);
        this.selectedStatus = event.target.value
      },

      ShowManageBookingDialog(booking) {
        //console.log("Showing manage booking dialog box")
        //console.log("booking:", booking);
        try {
          this.managebookingdialog = {
            ...this.managebookingdialog,
            booking
          }
          this.managebookingdialog.show = true;
        } catch (e) {
          console.warn("Error when trying to open manage booking dialog")
        }
      },

      CloseManageBookingDialog() {
        //console.log("Closing manage booking dialog box")
        this.managebookingdialog.show = false;
      },

      UploadCertificate() {
        this.$router.push({ name: 'UserUploadCertificate', params: { status: USER_EDIT_UPLOADED_EVENT_RECORD_STATUS.CREATE }, query: { searchText: this.searchText, sortBy: this.sortBy, sortDesc: this.sortDesc, pageNumber: this.pagination.page } });
      },

      SetSearchText() {
        //console.log("Set Search Text triggered")
        if (this.$route.query.searchText) {
          this.searchText = this.$route.query.searchText;
        }
      },

      SetPage() {
        //console.log("Set Page triggered")
        if (this.$route.query.pageNumber) {
          this.pagination.page = this.$route.query.pageNumber;
        }
      },

      CloseCertificateDialog() {
        //console.log("Closing certificate dialog")
        this.certificatedialog.show = false;
      },

      async GetCertificate(booking) {
        //console.log("Getting a certificate")
        //console.log("booking:", booking);
        //console.log("event:", booking.event);
        const user = this.$store.getters.user
        const certificateService = new CertificateService(this.$store)
        const experienceCertificateService = new ExperienceCertificateService(this.$store)

        var signedUrl;
        var downloadName;
        if (!booking.isUploaded && !booking.isExperience) {
          signedUrl = await certificateService.GetCertificateSignedURL(booking.event.id, user.id, user.identityId)
          downloadName = `Certificate - ${booking.id}.pdf`;
        }
        if (booking.isUploaded) {
          signedUrl = await certificateService.GetUploadedCertificateSignedURL(booking.fileName, user.identityId)
          downloadName = `${booking.fileName}`;
        }
        if (booking.isExperience) {
          const parameters = {
            experienceEventId: booking.userRecord.experienceEventId,
            user: {
              id: user.id,
              identityId: user.identityId
            }
          }
          signedUrl = await experienceCertificateService.GetExperienceCertificateSignedURL(parameters)
          downloadName = `Experience Certificate - ${booking.id}.pdf`;
        }
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = signedUrl;
        a.download = downloadName;
        a.target = "_blank"
        a.rel = "noopener"
        a.click();
        document.body.removeChild(a);
      },


      DownloadCertificate() {
        var fileURL = this.certificatedialog.certificatepath;
        var downloadLink = document.createElement('a');
        downloadLink.href = fileURL;
        downloadLink.target = "_blank";
        downloadLink.click();
        downloadLink = null;
        this.certificatedialog.show = false;
      },

      // TODO: FUTURE: Change name of the file to be the users name, document name, and current date and time
      generatePdf: function () {
        var doc = new jsPDF();
        var rows = [];
        this.bookings.forEach(element => {
          console.log(element)
          // If this is the events page, just do the standard stuff
          if (this.isEventsPage) {
            let temp = [
              element.event.title,
              this.getDateFromDateTime(element.event.startDateTime),
              this.getTimeFromDateTime(element.event.startDateTime),
              this.getDurationFromEvent(element, true),
              this.getBookingStatus(element)
            ];
            rows.push(temp);
          }
          // If this is the records page, do the record stuff (with expiries)
          if (this.isRecordsPage) {
            let expiryDate = element.event.certificateExpiryDateTime ? this.getDateFromDateTime(element.event.certificateExpiryDateTime) : null;
            if (!expiryDate && element.userRecord) {
              if (element.userRecord.expiryDateTime) {
                expiryDate = this.getDateFromDateTime(element.userRecord.expiryDateTime)
              }
            }
            let temp = [
              element.event.title,
              this.getDateFromDateTime(element.event.startDateTime),
              this.getTimeFromDateTime(element.event.startDateTime),
              this.getDurationFromEvent(element, true),
              expiryDate ? expiryDate : "N/A",
              this.getBookingStatus(element)
            ];
            rows.push(temp);
          }
        });
        // If this is the events page, add the standard headers
        if (this.isEventsPage) {
          doc.autoTable({
            head: [['Event', 'Date Complete', 'Time', 'Duration', 'Status']],
            body: rows
          });
        }
        // If this is the records page, add the record headers
        if (this.isRecordsPage) {
          doc.autoTable({
            head: [['Event', 'Date Complete', 'Time', 'Duration', 'Expiry Date', 'Status']],
            body: rows
          });
        }
        doc.save();
      },

      /*
      print: function () {
        var doc = new jsPDF();
        var rows = [];
        this.bookings.forEach(element => {
          var temp = [element.title, element.eventDate, element.startTime, element.duration, element.status];
          rows.push(temp);
        });
        doc.autoTable({
          head: [['Event', 'Date Complete', 'Time', 'Duration', 'Status']],
          body: rows
        });
        doc.autoPrint();
        doc.output('dataurlnewwindow');
      },
      */
      getDateFromDateTime(dateTimeString) {
        return GetLocaleDateFromDateTime(dateTimeString)
      },
      getTimeFromDateTime(dateTimeString) {
        return GetTimeFromDateTime(dateTimeString)
      },
      getDurationFromEvent(bookingObject, shouldFormat) {
        if (shouldFormat == null) {
          shouldFormat = true
        }
        try {
          const eventDuration = EventDurationInMinutes(bookingObject.event)
          if (shouldFormat) {
            const formatType = bookingObject.isExperience ? DESIRED_DURATIONS.Days : DESIRED_DURATIONS.ClockTimeHoursMinutes
            return FormatDuration(eventDuration, formatType)
          }
          return eventDuration
        } catch (e) {
          return ''
        }
      },
      bookingHasCertificate(booking) {
        return booking.status === BOOKING_STATUS.COMPLETED && booking.attendance === BOOKING_ATTENDANCE.ATTENDED
      },
      isPastEvent(event) {
        return new Date() - new Date(event.startDateTime) > 0
      },
      getBookingStatus(booking) {

        const { event } = booking

        if (booking.isUploaded) {
          return "Certificate From User"
        }
        if (booking.isExperience) {
          return "Certificate Available"
        }
        if (event.status === EVENT_STATUS.CANCELLED || booking.status === BOOKING_STATUS.CANCELLED) {
          return "Cancelled"
        }
        if (booking.status === BOOKING_STATUS.ONWAITINGLIST && !this.isPastEvent(event)) {
          return "Waiting List"
        }
        if (booking.status === BOOKING_STATUS.ONWAITINGLIST && this.isPastEvent(event)) {
          return "No Spaces"
        }
        if (event.status === EVENT_STATUS.LIVE && booking.status === BOOKING_STATUS.BOOKED && !this.isPastEvent(event)) {
          return "Scheduled"
        }
        if (event.status === EVENT_STATUS.LIVE && booking.status === BOOKING_STATUS.BOOKED && this.isPastEvent(event)) {
          return "Pending"
        }
        if (event.status === EVENT_STATUS.CERTIFICATESSENT && booking.attendance === BOOKING_ATTENDANCE.NOSHOW) {
          return "Absent"
        }
        if (event.status === EVENT_STATUS.CERTIFICATESSENT && booking.attendance === BOOKING_ATTENDANCE.ATTENDED) {
          return "Certificate Available"
        }
        if (event.eventType === EVENT_TYPE.CONFERENCE && booking.certificateSaved) {
          return "Certificate Available"
        }
        return "Error getting status"
      },
      getBookingStatusOption(bookingStatus) {
        return EVENT_BOOKING_DISPLAY_TEXT[bookingStatus]
      },
      getEventLocation(event) {
        if (event.eventMode === EVENT_MODE.VIRTUAL) {
          return "Online"
        }
        if (event.eventMode === EVENT_MODE.INPERSON) {
          return event.address.city
        }
      },
      getCertificateExpiry(event) {
        console.log(event)
        try {
          if (event.certificatesExpire) {
            if (event.certificateExpiryDateTime) {
              return (new Date(event.certificateExpiryDateTime)).toLocaleDateString()
            }
          }
          if (event.userRecord) {
            if (event.userRecord.expiryDateTime) {
              return (new Date(event.userRecord.expiryDateTime)).toLocaleDateString()
            }
          }
          return "-"
        } catch (err) {
          console.warn("Error getting certificate Expiry;", err)
          return "-"
        }
      },
      isBookingStatusBooked(booking) {
        try {
          return booking.status === BOOKING_STATUS.BOOKED
        } catch (e) {
          return false
        }
      },
      isEventStatusScheduled(event) {
        //console.log(event)
        try {
          return event.status === EVENT_STATUS.LIVE
        } catch (e) {
          return false
        }
      },
      isCancelBookingDisabled(booking) {
        //console.log(booking)
        return !this.isBookingStatusBooked(booking) || !this.isEventStatusScheduled(booking.event)
      },
      EditUploadedRecord(booking) {
        console.log("Booking edit requested;", booking)
        this.$router.push(
          {
            name: 'UserUploadCertificate',
            params: {
              status: USER_EDIT_UPLOADED_EVENT_RECORD_STATUS.UPDATE,
              booking
            },
            query: {
              searchText: this.searchText,
              sortBy: this.sortBy,
              sortDesc: this.sortDesc,
              pageNumber: this.pagination.page
            }
          }
        );
      },
      DeleteUploadedRecord(booking) {
        console.log("Booking delete requested;", booking)
        this.deleteUploadedEventDialog.message = `Are you sure you want to delete your uploaded event ${booking.event.title}`
        this.deleteUploadedEventDialog.show = true
        this.deleteUploadedEventDialog.deleteId = booking.id
      },
      closeDeleteUploadedEventDialog() {
        this.deleteUploadedEventDialog.message = ""
        this.deleteUploadedEventDialog.deleteId = ""
        this.deleteUploadedEventDialog.show = false
        this.deleteUploadedEventDialog.preDelete = true
      },
      async confirmDeleteUploadedEvent() {
        this.isLoading = true
        this.deleteUploadedEventDialog.show = false
        const uploadedRecordService = new UploadedRecordService(this.$store)
        await uploadedRecordService.DeleteRecord(this.deleteUploadedEventDialog.deleteId)
        await uploadedRecordService.GetUploadedEventsForUser()
        this.deleteUploadedEventDialog.message = "You have successfully deleted your uploaded event"
        this.deleteUploadedEventDialog.deleteId = ""
        this.deleteUploadedEventDialog.preDelete = false
        this.deleteUploadedEventDialog.show = true
        this.isLoading = false
      }
    },

    computed: {
      pages() {
        if (this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        ) return 0

        return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
      },
      computedHeaders() {
        return this.headers.filter(h => typeof h.status === 'undefined' || h.status == this.$route.params.status)
      },
      bookings: function() {
        if (this.isEventsPage) {
          if (this.selectedStatus === "ALL") {
            return this.$store.getters.upcomingBookings
          }
          const filteredBookings = this.$store.getters.upcomingBookings.filter((booking) => booking.status === this.selectedStatus)
          return filteredBookings
        }
        const filteredBookings = this.$store.getters.pastBookings
        //console.log("Bookings:", filteredBookings)
        return filteredBookings
      },
      isEventsPage() {
        // This was status: 2
        return this.$route.params.status == USER_EVENTS_PAGE_PARAMETERS.EVENTS
      },
      isRecordsPage() {
        // This was status: -1
        return this.$route.params.status == USER_EVENTS_PAGE_PARAMETERS.RECORDS
      },
      eventFilterOptions() {
        return {
          ALL: "ALL",
          WAITING_LIST: "WAITING_LIST",
          BOOKED: "BOOKED",
          CANCELLED: "CANCELLED"
        }
      }
    },
    /*
    <option value="-1">All</option>
    <option value="0">WaitingList</option>
    <option value="1">Booked</option>
    <option value="3">Cancelled</option>
    */

    mounted() {
      if (this.$route.params.status === USER_EVENTS_PAGE_PARAMETERS.EVENTS) {
        this.headers[0].text = "Event";
        this.$parent.$parent.$parent.refreshMenu('events');
        // Set it up so the next event to occur is at the top of the screen
        this.pagination = {
          ...this.pagination,
          descending: false,
          sortBy: 'eventDate',
          sortDesc: false,
          totalItems: this.$store.getters.upcomingBookings.length
        }
        this.sortDesc = false
      }
      if (this.$route.params.status === USER_EVENTS_PAGE_PARAMETERS.RECORDS) {
        this.headers[0].text = "Record";
        this.$parent.$parent.$parent.refreshMenu('records');
        // Set it up so the most recent event to have passed is at the top of the screen
        this.pagination = {
          ...this.pagination,
          descending: true,
          sortBy: 'eventDate',
          sortDesc: true,
          totalItems: this.$store.getters.pastBookings.length
        }
        this.sortDesc = true
      }

      this.is_data_fetched = true;
    }
  }
</script>
<style scoped>

  .v-btn {
    text-decoration: none;
    text-transform: capitalize;
  }

  tbody tr:nth-of-type(even) {
    background-color: rgba(243, 246,248, 0.7);
  }

  .container-rounded {
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 5px;
    margin: 5px;
  }

  .container-rounded-grey {
    border-radius: 10px;
    background-color: rgba(243, 246,248, 0.7);
    padding: 5px;
    margin: 5px;
  }

  .grid-container .v-datatable td {
    width: 10%;
  }

    .grid-container .v-datatable td:first-child, .grid-container .v-datatable td:last-child {
      width: 20%;
    }
</style>


import { LAYOUTS } from '@/constants/LayoutConstants'
import * as StoreActions from '../constants/StoreActions'
import store from '../store/index'

import ProviderRoutes from './provider-routes'
import AdminStatistics from '@/components/admin-system-statistics'
import AdminCreateEmployer from '@/components/admin-create-employer'

const baseUrl = process.env.BASE_URL;

// Admin routes are just the provider routes with the admin layout
const modifiedProviderRoutes = ProviderRoutes.map((providerRoute) => {
  return {
    ...providerRoute,
    name: providerRoute.name.replace("Provider", "Admin"),
    path: providerRoute.path.replace("Provider", "Admin"),
    meta: { title: providerRoute.meta.title.replace("Provider", "Admin"), requiresAuth: true, requiresProvider: false, requiresAdmin: true },
    display: providerRoute.display.replace("Provider", "Admin"),
    beforeEnter: (to, from, next) => { SetAdminLayout(to, from, next); }
  }
})

// Routes are just the provider routes with the admin layout + extra routes
export const routes = [
  ...modifiedProviderRoutes,
  {
    name: 'AdminStatistics',
    path: baseUrl + 'AdminStatistics',
    meta: { title: 'MyProPass - Admin Statistics', requiresAuth: true, requiresProvider: false, requiresAdmin: true },
    component: AdminStatistics,
    display: 'Admin Statistics',
    beforeEnter: (to, from, next) => { SetAdminLayout(to, from, next); }
  },
  {
    name: 'AdminCreateEmployer',
    path: baseUrl + 'AdminCreateEmployer',
    meta: { title: 'MyProPass - Admin Create Employer', requiresAuth: true, requiresProvider: false, requiresAdmin: true },
    component: AdminCreateEmployer,
    display: 'Admin Create Employer',
    beforeEnter: (to, from, next) => { SetAdminLayout(to, from, next); }
  }
]


async function SetAdminLayout(to, from, next) {
  //console.log("Changing layout to admin")
  await store.commit(StoreActions.SET_LAYOUT, LAYOUTS.ADMIN);
  next();
}

export default routes


import { API, graphqlOperation } from 'aws-amplify';

import * as QueriesCustom from '../graphql/queriesCustom';
import * as StoreActions from '../constants/StoreActions'

class ExperienceRecordService {

  constructor(store) {
    this.$store = store
  }


  // Get the future events available to this employee
  async GetExperienceRecordsForEmployee() {
    //console.log("Fetching future events sorted by date for employee")
    const parameters = {
      providerId: this.$store.getters.employer.id,
      sortDirection: 'ASC'
    }
    //console.log("GetExperienceRecordsForEmployee:", parameters)
    try {
      const response = await API.graphql(graphqlOperation(QueriesCustom.experienceRecordByProviderId, parameters));
      //console.log("GetExperienceRecordsForEmployee response:", response)
      const result = response.data.experienceRecordByProviderId
      this.$store.commit(StoreActions.SET_ISSUED_EXPERIENCE_CERTIFICATES, result);
      return result
    } catch (e) {
      console.warn("Error getting experience record list:", e)
      // If some data was recovered, save it anyway
      if (e.data) {
        const result = e.data.experienceRecordByProviderId
        this.$store.commit(StoreActions.SET_ISSUED_EXPERIENCE_CERTIFICATES, result);
        return result
      }
      return {}
    }
  }


  // Get more future events available to this employee
  async ExtendExperienceRecordsForEmployee() {
    //console.log("Extending future events for employee")
    // If there are no more events to fetch, return and do nothing
    if (!this.$store.getters.issuedExperience.nextToken) {
      //console.log("Requested extending future events for employee, but there are no more events")
      return
    }
    const parameters = {
      providerId: this.$store.getters.employer.id,
      nextToken: this.$store.getters.issuedExperience.nextToken,
      sortDirection: 'ASC'
    }
    try {
      const response = await API.graphql(graphqlOperation(QueriesCustom.experienceRecordByProviderId, parameters));
      //console.log("GetExperienceRecordsForEmployee response:", response)
      const result = response.data.experienceRecordByProviderId
      this.$store.commit(StoreActions.EXTEND_ISSUED_EXPERIENCE_CERTIFICATES, result);
      return result
    } catch (e) {
      console.warn("Error getting experience record list:", e)
      // If some data was recovered, save it anyway
      if (e.data) {
        const result = e.data.experienceRecordByProviderId
        this.$store.commit(StoreActions.EXTEND_ISSUED_EXPERIENCE_CERTIFICATES, result);
        return result
      }
      return {}
    }
  }

}

export default ExperienceRecordService

<template>
  <v-layout column pa-0 ma-0 v-if="is_data_fetched">
    <loading :active.sync="checkInClicked" :is-full-page="true" loader="dots"></loading>
    <v-flex xs12 md12 pa-0 ma-0>
      <v-layout row wrap pa-0 ma-0 mt-4>
        <v-flex xs12 md2 mr-lg-5>
          <v-layout column pa-0 ma-0>
            <v-flex xs12 md8>
              <v-card pa-0 ma-0>
                <v-card-title class="justify-center">
                  <v-avatar size="75px" class="align-content-center">
                    <img :src="avatar" height="75px" width="75px" style="background-color: rgba(240,240,240,1); align-content:center; object-fit:contain;" />
                  </v-avatar>
                </v-card-title>
                <v-divider></v-divider>
                <v-list three-line>
                  <v-list-tile>
                    <v-list-tile-content style="font-size:14px;text-align:center;">
                      <div style="align-content:center;width:100%" class="page-header">{{firstName}} {{lastName}}</div>
                      <div style="align-content:center;width:100%">{{jobTitle}}</div>
                      <div style="align-content:center;width:100%">{{employerName}}</div>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile>
                    <v-list-tile-content style="font-size:14px;font-weight:bold;text-align:center;">
                      <div style="align-content:center;width:100%">MyProPassID: {{username}}</div>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs12 md4 style="margin-top:10px;margin-bottom:20px;">
              <v-card pa-0 ma-0>
                <v-card-title style="font-size:14px;text-align:center;" class="pb-0 mb-3">
                  <div style="align-content:center;width:100%"><h6 class="mb-0 page-header" style="font-weight: bold">Training Summary</h6></div>
                </v-card-title>
                <div class="pa-2">

                  <div class="achievements" style="font-weight:normal;color:white;padding:5px;text-align:right;">
                    <span style="float:left">
                      <v-img :src="require('../images/icon-completed-hours.png')" style="border-style:none; float: left;margin-top: 5px;margin-right: 6px;" height="12px" width="12px"></v-img>
                      Completed:
                    </span>
                    <span>{{getFormattedDuration(CompletedMinutes)}}</span>
                  </div>

                  <!--
                  <div class="achievements" style="font-weight:normal;color:white;background-color:rgb(0,171,173);margin-top:4px;padding:5px;text-align:right">
                    <span style="float:left">
                      <v-img :src="require('../images/icon-total-hours.png')" style="border-style:none; float: left;margin-top: 5px;margin-right: 6px;" height="12px" width="12px"></v-img>
                      CPD Accredited:
                    </span>
                    <span>{{getFormattedDuration(CPDAccreditedMinutes)}}</span>
                  </div>
                  -->

                  <div class="achievements" style="font-weight:normal;color:white;background-color:#2194ba;margin-top:4px;padding:5px;text-align:right">
                    <span style="float:left">
                      <v-img :src="require('../images/icon-planned-hours.png')" style="border-style:none; float: left;margin-top: 5px;margin-right: 6px;" height="12px" width="12px"></v-img>
                      Planned:
                    </span>
                    <span>{{getFormattedDuration(PlannedMinutes)}}</span>
                  </div>

                  <div class="achievements" style="font-weight:normal;color:white;background-color:#4686C6;margin-top:4px;padding:5px;text-align:right">
                    <span style="float:left">
                      <v-img :src="require('../images/icon-total-hours.png')" style="border-style:none; float: left;margin-top: 5px;margin-right: 6px;" height="12px" width="12px"></v-img>
                      Total:
                    </span>
                    <span>{{getFormattedDuration(TotalMinutes)}}</span>
                  </div>

                  <br />
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 md7 mr-lg-5 class="container-rounded" pa-3>
          <v-layout column pa-0 ma-0>
            <v-flex xs12 md2>
              <v-layout wrap>
                <v-flex xs12>
                  <div>
                    <h4 style="color: #404e67;margin-top: 16px;font-weight: 700;text-align: center;margin-top:0;">Book a Course</h4>
                    <v-divider mt-0 mb-2></v-divider>
                  </div>
                </v-flex>
                <v-container xs12 offset-md4 md8 pb-0>
                  <v-layout>
                    <v-flex v-if="showFilter==false">
                      <table class="user-landing-search">
                        <tr>
                          <td style="vertical-align:bottom; border-style:none; width:min-content;">
                            <v-img :src="require('../images/filterfunnel.png')" style="border-style:none;" height="20px" width="14px" @click="OnToggleFilter"></v-img>
                          </td>
                          <td style="width:5px;"></td>
                          <td class="grid-search">
                            <v-text-field v-model="searchText"
                                          append-icon="search"
                                          label="Search for an event or provider"
                                          single-line
                                          hide-details></v-text-field>
                          </td>
                        </tr>
                      </table>
                    </v-flex>
                    <v-flex v-if="showFilter==true" offset-xs1 xs10 offset-md11 md1 style="font-weight:normal;">
                      <div>
                        <v-img :src="require('../images/filterfunnel.png')" style="border-style:none;float:right;" height="20px" width="14px" @click="OnToggleFilter"></v-img>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-card v-if="showFilter==true" class="filter-panel">
            <v-layout justify-center style="padding:25px 25px 0px 25px;">
              <v-flex xs12 md2>
                <p>Type</p>
                <div style="font-weight:normal;display:block">
                  <label><input type="checkbox" value="1" v-model="eventFilter.free" /> Free</label><br />
                  <label><input type="checkbox" value="2" v-model="eventFilter.paid" /> Paid</label><br />
                  <label><input type="checkbox" value="3" v-model="eventFilter.conference" /> Conference</label>
                </div>
              </v-flex>
              <v-flex xs12 md2>
                <p>Duration</p>
                <div style="font-weight:normal;">
                  <label><input type="checkbox" value="1" v-model="eventFilter.lessThanOneHour" /> &lt; 1 hr</label><br />
                  <label><input type="checkbox" value="2" v-model="eventFilter.oneToFourHours" /> 1-4 hrs</label><br />
                  <label><input type="checkbox" value="4" v-model="eventFilter.fourToEightHours" /> 4-8 hrs</label><br />
                  <label><input type="checkbox" value="8" v-model="eventFilter.eightPlusHours" /> 8+ hrs</label>
                </div>
              </v-flex>
              <v-flex xs12 md2>
                <p>Schedule</p>
                <div style="font-weight:normal;">
                  <label><input type="checkbox" value="1" v-model="eventFilter.weekdays" /> Weekdays</label><br />
                  <label><input type="checkbox" value="2" v-model="eventFilter.weekends" /> Weekends</label>
                </div>
              </v-flex>
              <!-- TODO: FUTURE: Restore the location filter -->
              <!--
              <v-flex xs12 md2>
                <p>Location</p>
                <div style="font-weight:normal;">
                  <label><input type="checkbox" value="1" v-model="eventFilter.lessThanFiveMiles" /> &lt; 5 mi</label><br />
                  <label><input type="checkbox" value="2" v-model="eventFilter.fiveToTenMiles" /> 5-10 mi</label><br />
                  <label><input type="checkbox" value="4" v-model="eventFilter.tenToTwentyMiles" /> 10-20 mi</label><br />
                  <label><input type="checkbox" value="8" v-model="eventFilter.greaterThanTwenty" /> 20+ mi</label>
                </div>
              </v-flex>
              -->
              <!--
              <v-flex xs12 md2>
                <p>Price</p>
                <div style="font-weight:normal;">
                  <label><input type="checkbox" value="1" v-model="eventFilter.lessThanTwoHundred" /> £200</label><br />
                  <label><input type="checkbox" value="2" v-model="eventFilter.twoHundredToThreeHundred" /> £200 - £300</label><br />
                  <label><input type="checkbox" value="4" v-model="eventFilter.greaterThanThreeHundred" /> > £300</label>
                </div>
              </v-flex>
              -->
            </v-layout>
            <!-- TODO: FUTURE: Restore the location filter -->
            <!--
            <v-layout style="padding:15px 25px 0px 25px;">
              <v-flex xs12>
                <div class="form-row">
                  <label class="control-label mt-1" style="margin-right: 1em;">My location</label>
                  <input type="text" class="form-control col-sm-7" id="address" name="address" v-model="eventFilter.address"/>
                  <div v-if="eventFilter.submitted && $v.eventFilter.address.$error" class="text-danger">
                    <small v-if="!$v.eventFilter.address.validAddress">Address is required because Location filter is selected.</small>
                  </div>
                </div>
              </v-flex>
            </v-layout>
            -->
            <!-- TODO: FUTURE: Restore the start and end date filters -->
            <!--
            <v-layout style="padding:0px 25px 0px 25px;">
              <v-flex xs12>
                <div class="form-row">
                  <label class="control-label mt-3">Start Date</label>
                  <v-menu ref="eventFilter.startDateMenu" v-model="eventFilter.startDateMenu" lazy :close-on-content-click="false" offset-y>
                    <template style="width:100px">
                      <v-text-field v-model="eventFilter.startDateFormatted"
                                    prepend-icon="event" class="col-sm-6" style="font-weight:normal;"
                                    @blur="eventFilter.startDate = parseDate(eventFilter.startDateFormatted)"
                                    ></v-text-field>
                    </template>
                    <v-date-picker dark class="mt-4" color="green lighten-1" event-color="green" header-color="primary" v-model="eventFilter.startDate" @input="eventFilter.startDateMenu = false"></v-date-picker>
                  </v-menu>
                  <div v-if="eventFilter.submitted && $v.eventFilter.startDate.$error" class="text-danger">
                    <small v-if="!$v.eventFilter.startDate.validDate">Start Date is invalid.</small>
                  </div>
                </div>
              </v-flex>
              <v-flex xs12>
                <div class="form-row">
                  <label class="control-label mt-3">End Date</label>
                  <v-menu ref="eventFilter.endDateMenu"
                          v-model="eventFilter.endDateMenu"
                          lazy
                          :close-on-content-click="false"
                          offset-y>
                    <template style="width:100px">
                      <v-text-field v-model="eventFilter.endDateFormatted"
                                    prepend-icon="event" class="col-sm-6" style="font-weight:normal;"
                                    @blur="eventFilter.endDate = parseDate(eventFilter.endDateFormatted)"
                                    ></v-text-field>
                    </template>
                    <v-date-picker dark class="mt-4" color="green lighten-1" event-color="green" header-color="primary" v-model="eventFilter.endDate" @input="eventFilter.endDateMenu = false"></v-date-picker>
                  </v-menu>
                  <div v-if="eventFilter.submitted && $v.eventFilter.endDate.$error" class="text-danger">
                    <small v-if="!$v.eventFilter.endDate.validDate">End Date is invalid.</small>
                    <small v-if="!$v.eventFilter.endDate.validEndTime">End Date should be greater than start date.</small>
                  </div>
                </div>
              </v-flex>
            </v-layout>
             -->
            <v-layout style="margin-top: 2em; padding:0px 25px 0px 25px;">
              <v-flex style="display:flex; justify-content:flex-end; align-items:flex-end;">
                <v-btn color="rgb(0,171,173)" small @click="ResetFilter(true)">Clear</v-btn>
                <v-btn color="rgb(0,171,173)" small @click="CloseFilter(true)">Close</v-btn>
              </v-flex>
            </v-layout>
            <br />
          </v-card>
          <v-layout class="container">
            <v-flex xs12 md12 mt-3>
              <v-layout>
                <v-flex>
                  <v-data-iterator
                    :items="events"
                    :custom-filter="customEventSearchFilter"
                    hide-actions
                    :search="searchText"
                    :pagination.sync="pagination"
                    :sort-by="sortBy"
                    :sort-desc="sortDesc"
                    :custom-sort="CustomSort"
                    content-tag="v-layout"
                    wrap >
                    <template v-slot:item="props">
                      <v-container class="container-rounded-grey pa-0 mb-3">
                        <v-layout>
                          <v-flex xs12 md3 style="max-width: 85px;">
                            <event-logo v-bind:imageKey="props.item.eventLogo.imageKey" level="public" />
                          </v-flex>
                          <v-flex xs12 class="pr-3" style="margin-left:10px">
                            <v-container ma-0 pa-0>
                              <v-layout ma-0 pa-0 class="book-course-direction">
                                <v-flex xs12 md8>
                                  <div class="ABC_in_Scaffolding">
                                    <span style="font-size: 15px;">{{ props.item.title }}</span><br />
                                    <div class="Duration__1_hour book-course-secondary-data">
                                      <span>Start Date: {{ getDateFromDateTime(props.item.startDateTime) }} {{ getTimeFromDateTime(props.item.startDateTime) }}</span>
                                      <span>End Date: {{ getDateFromDateTime(props.item.endDateTime) }} {{ getTimeFromDateTime(props.item.endDateTime) }}</span>
                                      <span>Duration: {{ getDurationFromEvent(props.item, true) }}</span>
                                      <span>Provider: {{ getProviderNameFromEvent(props.item) }}</span>
                                      <div style="display: block; font-size: 11px; line-height: 1.2;" v-if="isInPersonEvent(props.item)">Location: {{ getAddressStringFromEvent(props.item) }}</div>
                                      <div style="display: block; font-size: 11px; line-height: 1.2;" v-if="isVirtualEvent(props.item)">Location: Online</div>
                                    </div>
                                  </div>
                                </v-flex>
                                <v-flex xs12 md4 text-right class="event-button-container">
                                  <v-btn class="right-align-button" color="rgb(0,171,173)" v-if="!isConferenceEvent(props.item) && !userBookedOntoEvent(props.item) && !userOnEventWaitingList(props.item) && !eventIsPrivate(props.item)" small @click="bookCourse(props.item.id)">
                                    Book Course
                                  </v-btn>
                                  <v-btn class="right-align-button" color="rgb(0,171,173)" v-if="!isConferenceEvent(props.item) && !userBookedOntoEvent(props.item) && !userOnEventWaitingList(props.item) && eventIsPrivate(props.item)" small @click="viewCourse(props.item.id)">
                                    View Details
                                  </v-btn>
                                  <v-btn class="right-align-button" color="rgb(0,171,173)" v-if="!isConferenceEvent(props.item) && userBookedOntoEvent(props.item)" :disabled="true" small>
                                    Booked
                                  </v-btn>
                                  <v-btn class="right-align-button" color="rgb(0,171,173)" v-if="isConferenceEvent(props.item)" small @click="viewCourse(props.item.id)">
                                    View Details
                                  </v-btn>
                                  <v-btn class="right-align-button" color="rgb(0,171,173)" v-if="!isConferenceEvent(props.item) && userOnEventWaitingList(props.item) && eventHasSeatsAvailable(props.item)" small @click="bookCourse(props.item.id)">
                                    Book Course
                                  </v-btn>
                                  <v-btn class="right-align-button" color="rgb(0,171,173)" v-if="!isConferenceEvent(props.item) && userOnEventWaitingList(props.item) && !eventHasSeatsAvailable(props.item)" small @click="viewCourse(props.item.id)">
                                    Notification requested
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </template>
                  </v-data-iterator>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs12 text-right>
                  <v-pagination v-model="pagination.page" style="vertical-align:top;" :length="pages"></v-pagination>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 md2 ma-0 pa-0>
          <v-layout column pa-0 ma-0>
            <v-flex xs12 md4>
              <v-card>
                <v-card-title class="justify-center pb-0"><h4 class="page-header">Conference Event Code</h4></v-card-title>
                <v-list>
                  <v-list-tile>
                    <v-list-tile-content style="font-size:14px;text-align:center;">
                      <div style="align-content:center;width:100%">Please enter your conference event code here</div>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile-content class="align-center mpp-tile" style="height: auto;">
                    <input type="text" class="form-control" id="eventCode" v-model="eventCode">
                    <div v-if="submitted && $v.eventCode.$error" class="text-danger" style="height: auto;">
                      <small v-if="!$v.eventCode.required">Conference Event Code is required<br /></small>
                      <small v-if="!$v.eventCode.rightLength">Conference Event Code should be 8 characters<br /></small>
                    </div>
                  </v-list-tile-content>
                  <v-list-tile>
                    <v-list-tile-content class="align-center">
                      <v-btn color="rgb(0,171,173)" :block="true" @click="CheckInToConferenceEvent()" :disabled="checkInClicked">
                        Check-in
                      </v-btn>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs12 md8 fluid fluid-height style="margin-top:10px;">
              <v-card>
                <div style="padding: 16px;text-align: center;"><h6 class="page-header ma-0" style="margin-bottom:0">Scheduled Events</h6></div>
                <v-list two-line class="pl-3 pr-3">
                  <div pa-0 class="scheduled-event">
                    <v-data-iterator
                      :items="upcomingBookings"
                      :sort-by="sortBy"
                      :sort-desc="sortDesc"
                      :custom-sort="CustomSortBookings"
                      content-tag="v-layout"
                      no-data-text="No scheduled events"
                      no-results-text="No scheduled events"
                      hide-actions
                      wrap>
                      <template v-slot:item="props">
                        <div role="listitem">
                          <span style="font-size:15px;font-weight:bold;">{{ getDateFromDateTime(props.item.event.startDateTime) }}</span>
                          <a style="font-size:14px;font-weight:normal;" small @click="ShowManageBookingDialog(props.item)">
                            {{ props.item.event.title }}
                          </a>
                        </div>
                      </template>
                    </v-data-iterator>
                  </div>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>


          <v-layout row justify-center>
            <v-dialog v-model="manageBookingDialog.show" transition="dialog-transition" max-width="70%">
              <v-container fluid pa-0>
                <v-layout ma-0>
                  <v-flex md12>
                    <v-card>
                      <user-managebooking :parentData="manageBookingDialog" :onClose="CloseManageBookingDialog"></user-managebooking>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-dialog>

            <v-dialog persistent v-model="messageDialog.show" transition="dialog-transition" max-width="450">
              <v-card>
                <v-card-text>
                  <v-flex>
                    <div id="message" style="font-weight:normal;">{{ messageDialog.message }}</div>
                  </v-flex>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="CloseMessageDialog" small color=rgb(0,171,173)>OK</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog persistent v-model="cancelbookingdialog.show" transition="dialog-transition" max-width="450">
              <v-card>
                <v-card-text>
                  <v-flex>
                    <div style="font-weight:normal;">
                      Do you want to proceed to cancel the booking? Cancellation will be in line with our terms and conditions.
                    </div>
                  </v-flex>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="CancelBooking(cancelbookingdialog.bookingId)" small color=rgb(0,171,173)>Yes</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn @click="CloseCancelBookingDialog" small color=rgb(0,171,173)>No</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Terms and Conditions Dialog -->
            <v-dialog persistent v-model="accepttandcdialog.show" transition="dialog-transition" max-width="800">
              <v-card>
                <v-card-text>
                  <v-flex>
                    <div class='terms-and-conditions-content' v-html='TERMS_AND_CONDITIONS'></div>
                  </v-flex>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="OnUserRejectTermsAndConditions()" small style='color: black !important;'>
                    <div style="color: grey;">Reject</div>
                  </v-btn>
                  <v-btn @click="OnUserAcceptTermsAndConditions()" small color=rgb(0,171,173)>Accept</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>


          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>


<script>

  // TODO: FUTURE: How will the search work with pagination from the listEvents call to the back end (max 100 events)?
  // TODO: FUTURE: Add Google Analytics

  import * as StoreActions from '@/constants/StoreActions'
  //import ProfessionalService from '../services/professional.service'
  import { Auth } from 'aws-amplify';
  import UserService from '../services/user.service'
  import EventService from '../services/event.service'
  //import EmailService from '../services/email.service'
  import BookingService from '../services/booking.service'

  import UserManageBooking from './user-managebooking'
  import EventLogo from './eventlogo-container'

  import { GetDateFromDateTime, GetTimeFromDateTime, GetLocaleDateFromDateTime } from '@/helpers/date.helper'
  import { EventDurationInMinutes, FormatAddressObjectToString } from '@/helpers/event.helper'
  import { FormatDuration, DESIRED_DURATIONS } from '@/helpers/duration.helper'
  import { EVENT_MODE, EVENT_STATUS, EVENT_TYPE, EVENT_DURATION, EVENT_SCHEDULE, EVENT_ACCESS_LEVEL } from '@/constants/EventConstants'
  import { USER_EVENTS_PAGE_PARAMETERS } from '@/constants/PageParameterConstants'
  import { BOOKING_STATUS, BOOKING_ATTENDANCE, BOOKING_CERTIFICATE_STATUS } from '@/constants/BookingConstants'
  import { TERMS_AND_CONDITIONS, LAST_ISSUED_TANDC_DATETIME_STRING } from '@/constants/TermsAndConditions'

  import DateHelper from '../helpers/date.helper'
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'


  export default {

    mixins: [validationMixin],
    validations: {
      eventCode: {
        required,
        rightLength: function() {
          // Removed due to old codes not being 8 characters
          /*
          var codeToCheck = this.eventCode.replace("-", "")
          codeToCheck = codeToCheck.replace(" ", "")
          return codeToCheck.length === 8
          */
         return true
        }
      },
      eventFilter: {
        //startDate: { validDate },
        //endDate: { validDate, validEndTime  },
        //address: { validAddress }
      }
    },

    components: {
      'user-managebooking': UserManageBooking,
      'event-logo': EventLogo
    },

    data() {
      return {
        is_data_fetched: false,
        showFilter: false,
        filtersAreActive: false,
        eventFilter: {
          free: false,
          paid: false,
          conference: false,
          lessThanOneHour: false,
          oneToFourHours: false,
          fourToEightHours: false,
          eightPlusHours: false,
          weekdays: false,
          weekends: false,
          //lessThanFiveMiles: false,
          //fiveToTenMiles: false,
          //tenToTwentyMiles: false,
          //greaterThanTwenty: false,
          //lessThanTwoHundred: false,
          //twoHundredToThreeHundred: false,
          //greaterThanThreeHundred: false,
          //startDateMenu: false,
          //startDate: '',//new Date().toISOString().substr(0, 10),
          //startDateFormatted: '',//this.formatDate(new Date().toISOString().substr(0, 10)),
          //endDateMenu: false,
          //endDate: '',//new Date().toISOString().substr(0, 10),
          //endDateFormatted: '',//this.formatDate(new Date().toISOString().substr(0, 10)),
          //address: '',
          submitted: false
        },
        sortBy: 'startDateTime',
        sortDesc: true,
        pagination: {
          rowsPerPage: 5,
          page: 1,
          totalItems: 0,
          sortBy: 'startDateTime',
          sortDesc: true,
        },
        submitted: false,
        checkInClicked: false,
        eventCode: '',
        searchText: '',
        manageBookingDialog: {
          show: false,
          duration: ''
        },
        cancelbookingdialog: {
          show: false,
          bookingId: 0
        },
        messageDialog: {
          show: false,
          cancelBooking: false,
          message: ''
        },
        accepttandcdialog: {
          show: false,
          cancelBooking: false,
        },
        avatarSignedURL: process.env.VUE_APP_placeholder_avatar_url,
        TERMS_AND_CONDITIONS
      }
    },

    watch: {
      events(newValue) {
        //console.log("WATCHER: 'events' updated; new value:", newValue)
        this.pagination.totalItems = newValue.length
      },
      'pagination.page': function(newValue) {
        //console.log("WATCHER: pagination page value updated", newValue)
        // Check if the user is getting close to the end of the fetched items
        if (newValue + 1 >= Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)) {
          new EventService(this.$store).ExtendFutureEventsForUser()
        }
      },
      '$store.getters.lastAcceptedTandCDateTime': function(newValue) {
        // If the user has accepted T&Cs yet, show them the T&Cs
        try {
          const lastAcceptedTandCDateTime = new Date(newValue)
          const lastIssuedTandCDateTime = new Date(LAST_ISSUED_TANDC_DATETIME_STRING)
          if (lastAcceptedTandCDateTime >= lastIssuedTandCDateTime) {
            this.accepttandcdialog.show = false
          }
        } catch (err) {
          console.warn("WATCHER: Unable to compare dates for last T&C acceptance;", err)
        }
      }
    },

    methods: {
      CustomSort(items, index, isDesc) {
        try {
          items.sort((a, b) => {
            this.sortBy = "startDateTime";
            this.sortDesc = isDesc;
            if (!isDesc) { return new Date(a.startDateTime) < new Date(b.startDateTime) ? -1 : 1; }
            return new Date(b.startDateTime) < new Date(a.startDateTime) ? -1 : 1;
          });
          return items;
        } catch (e) {
          console.warn("Error when custom sorting")
        }
      },

      CustomSortBookings(items) {
        try {
          items.sort((a, b) => {
            return new Date(a.event.startDateTime) < new Date(b.event.startDateTime) ? -1 : 1;
          });
          return items;
        } catch (e) {
          console.warn("Error when custom sorting")
        }
      },

      ShowManageBookingDialog(properties) {
        console.log(properties);
        this.manageBookingDialog = Object.assign({}, this.manageBookingDialog, properties)
        this.manageBookingDialog.show = true;
        this.messageDialog.cancelBooking = false;
      },
      ShowCancelBookingDialog(bookingId) {
        console.log("requesting cancellation dialog with id:", bookingId)
        this.cancelbookingdialog.show = true;
        this.cancelbookingdialog.bookingId = bookingId;
      },
      CloseManageBookingDialog() {
        this.manageBookingDialog.show = false;
        this.messageDialog.cancelBooking = false;
      },
      CloseCancelBookingDialog() {
        this.cancelbookingdialog.show = false;
        this.messageDialog.cancelBooking = false;
      },
      async CancelBooking(bookingId) {
        console.log(bookingId);
        const parameters = {
          id: bookingId
        }

        var response;
        var event;
        try {
          // Request that the booking is cancelled
          response = await BookingService.CancelBooking(parameters)
          console.log("booking cancelled")
          //console.log(response)
          // Extract the event
          event = response.data.updateEventBooking.event
        } catch (err) {
          console.warn("Error when cancelling booking", err)
          return
        }

        // Open the popover windows
        this.messageDialog.show = true;
        this.messageDialog = {
          cancelBooking: true,
          message: `Your booking for ${event.title} on ${GetDateFromDateTime(event.startDateTime)} was cancelled`
        }
        // Get new details for the user
        const userService = new UserService(this.$store)
        await userService.GetDetails()
      },
      CloseMessageDialog() {
        console.log("Closing the message dialog")
        console.log("This.messageDialog;", this.messageDialog)
        console.log("This.cancelbookingdialog;", this.cancelbookingdialog)
        console.log("This.manageBookingDialog;", this.manageBookingDialog)
        this.manageBookingDialog.show = false
        this.messageDialog.message = ''
        this.messageDialog.show = false
        if (this.messageDialog.cancelBooking == true ) {
          this.cancelbookingdialog.show = false;
          if (this.$router.currentRoute.name !== 'UserLanding') {
            this.$router.push({ name: 'UserLanding', params: { status: USER_EVENTS_PAGE_PARAMETERS.EVENTS } });
          }
        }
      },

      // BEN Functions start here

      formatDate(date) {
        // TODO: FUTURE: Add some error handling to this
        const dateObject = new Date(date)
        return dateObject.toDateString()
        //return DateHelper.FormatDate(date);
      },
      getDateFromDateTime(dateTimeString) {
        return GetLocaleDateFromDateTime(dateTimeString)
      },
      getTimeFromDateTime(dateTimeString) {
        return GetTimeFromDateTime(dateTimeString)
      },
      getDurationFromEvent(eventObject, shouldFormat) {
        if (shouldFormat == null) {
          shouldFormat = true
        }
        try {
          const eventDuration = EventDurationInMinutes(eventObject)
          if (shouldFormat) {
            return FormatDuration(eventDuration, DESIRED_DURATIONS.HoursMinutes)
          }
          return eventDuration
        } catch (e) {
          return ''
        }
      },
      getFormattedDuration(timeInMinutes) {
        try {
          if (timeInMinutes > 60) {
            return FormatDuration(timeInMinutes, DESIRED_DURATIONS.HoursMinutes)
          }
          return FormatDuration(timeInMinutes, DESIRED_DURATIONS.Minutes)
        } catch (e) {
          return ''
        }
      },
      isVirtualEvent(event) {
        return event.eventMode === EVENT_MODE.VIRTUAL
      },
      isInPersonEvent(event) {
        return event.eventMode === EVENT_MODE.INPERSON
      },
      isConferenceEvent(event) {
        //console.log("isConferenceEvent:", event.eventType === EVENT_TYPE.CONFERENCE)
        return event.eventType === EVENT_TYPE.CONFERENCE
      },
      isFreeEvent(event) {
        return event.eventType === EVENT_TYPE.FREE
      },
      isPaidEvent(event) {
        return event.eventType === EVENT_TYPE.PAID
      },
      userBookedOntoEvent(event) {
        //console.log("event.id:", event.id)
        const userBookedCourses = this.$store.getters.bookings.items.map((booking) => {
          //console.log(booking)
          if (booking.status === BOOKING_STATUS.BOOKED) {
            return booking.event.id
          }
          return ''
        })
        //console.log("userBookedCourses:", userBookedCourses)
        //console.log("userBookedOntoEvent:", userBookedCourses.includes(event.id))
        return userBookedCourses.includes(event.id)
      },
      userOnEventWaitingList(event) {
        const userWaitingCourses = this.$store.getters.bookings.items.map((booking) => {
          //console.log(booking)
          if (booking.status === BOOKING_STATUS.ONWAITINGLIST) {
            return booking.event.id
          }
          return ''
        })
        //console.log("userWaitingCourses:", userWaitingCourses)
        //console.log("userOnEventWaitingList:", userWaitingCourses.includes(event.id))
        return userWaitingCourses.includes(event.id)
      },
      eventIsPrivate(event) {
        return event.accessLevel === EVENT_ACCESS_LEVEL.PRIVATE
      },
      eventHasSeatsAvailable(event) {
        const { maximumAttendees } = event
        const countUsersBookedToEvent = event.attendees.items.filter((booking) => {
          return booking.status === BOOKING_STATUS.BOOKED
        }).length
        //console.log("Booked Seats:", countUsersBookedToEvent)
        //console.log("maximumAttendees:", maximumAttendees)
        return countUsersBookedToEvent < maximumAttendees
      },
      getProviderNameFromEvent(event) {
        try { return event.provider.name } catch { return "" }
      },
      getAddressStringFromEvent(event) {
        try { return FormatAddressObjectToString(event.address) } catch { return "" }
      },
      cancelBookingIsDisabled(booking) {
        // Check if the user is actually booked onto this course.  If they are not, disable the button
        if (booking.status !== BOOKING_STATUS.ONWAITINGLIST &&  booking.status !== BOOKING_STATUS.BOOKED) {
          return true
        }
        // If they are booked on, is the course still running?  If not, disable the button
        if (booking.event.status !== EVENT_STATUS.LIVE) { return true }
        return false
      },
      customEventSearchFilter(items, searchString) {
        // Make a copy of the items for filtering against
        var filteredItemsBySearchString = items

        //console.log("searchString:", searchString)

        // First, for efficiency, check if the search string is empty and if it isn't, filter the items
        if (searchString.length > 0) {
          // Make everything lower case to make searching accurate
          const lowerCaseSearchString = searchString.toLowerCase()
          // Filter all the event items by the search string
          filteredItemsBySearchString = items.map((item) => {
            if (item.provider.name.toLowerCase().includes(lowerCaseSearchString)) {
              return item
            }
            if (item.title.toLowerCase().includes(lowerCaseSearchString)) {
              return item
            }
            return null
          }).filter(item => item != null)
        }

        // Get all the active filters
        const { activeFilterCategories } = this
        //console.log("activeFilterCategories", activeFilterCategories)

        // Make a copy of the items that can be further filtered
        var filteredItemsToReturn = filteredItemsBySearchString


        //console.log("activeFilterCategories:", activeFilterCategories)

        // Filter by type if necessary
        if (activeFilterCategories.type.length > 0) {
          filteredItemsToReturn = filteredItemsToReturn.filter((item) => {
            return activeFilterCategories.type.includes(item.eventType)
          })
        }

        // Filter by duration if necessary
        if (activeFilterCategories.duration.length > 0) {
          filteredItemsToReturn = filteredItemsToReturn.filter((item) => {
            const eventDuration = this.getDurationFromEvent(item, false)
            if (activeFilterCategories.duration.includes(EVENT_DURATION.LESSTHANONEHOUR) && eventDuration < 60) { return true }
            if (activeFilterCategories.duration.includes(EVENT_DURATION.ONETOFOURHOURS) && eventDuration >= 60 && eventDuration < 240) { return true }
            if (activeFilterCategories.duration.includes(EVENT_DURATION.FOURTOEIGHTHOURS) && eventDuration >= 240 && eventDuration < 480) { return true }
            if (activeFilterCategories.duration.includes(EVENT_DURATION.EIGHTPLUSHOURS) && eventDuration >= 480) { return true }
            return false
          })
        }

        // Filter by schedule if necessary
        if (activeFilterCategories.schedule.length > 0) {
          filteredItemsToReturn = filteredItemsToReturn.filter((item) => {
            const eventDate = new Date(item.startDateTime)
            if (activeFilterCategories.schedule.includes(EVENT_SCHEDULE.WEEKDAYS) && eventDate.getDay() >= 1 && eventDate.getDay() <= 5) { return true }
            if (activeFilterCategories.schedule.includes(EVENT_SCHEDULE.WEEKENDS) && (eventDate.getDay() === 0 || eventDate.getDay() === 6)) { return true }
            return false
          })
        }

        // TODO: FUTURE: Reinstate filter by location
        // Filter by location if necessary
        /*
        if (activeFilterCategories.location.length > 0) {
          filteredItemsToReturn = filteredItemsToReturn.filter((item) => {
            return item !== null
          })
        }
        */

        // Filter by price if necessary
        /*
        if (activeFilterCategories.price.length > 0) {
          filteredItemsToReturn = filteredItemsToReturn.filter((item) => {
            return item !== null
          })
        }

        // Filter by start date if necessary
        if (activeFilterCategories.startDate.length > 0) {
          filteredItemsToReturn = filteredItemsToReturn.filter((item) => {
            const eventDate = new Date(item.startDateTime)
            const requestedDate = new Date(activeFilterCategories.startDate[0])
            return eventDate - requestedDate >= 0
          })
        }

        // Filter by end date if necessary
        if (activeFilterCategories.endDate.length > 0) {
          filteredItemsToReturn = filteredItemsToReturn.filter((item) => {
            const eventDate = new Date(item.startDateTime)
            const requestedDate = new Date(activeFilterCategories.endDate[0])
            return eventDate - requestedDate <= 0
          })
        }
        */

        return filteredItemsToReturn
      },

      // Ben Functions end here

      parseDate(date) {
        return DateHelper.ParseDate(date);
      },
      bookCourse(eventId) {
        //console.log('eventId');
        //console.log(eventId);
        this.$router.push({ name: 'UserBookCourseDisplay', params: { eventId: eventId } });
      },
      viewCourse(eventId) {
        //console.log('eventId');
        //console.log(eventId);
        this.$router.push({ name: 'UserBookCourseDisplay', params: { eventId: eventId } });
      },


      async CheckInToConferenceEvent() {
        //console.log('CheckInToConferenceEvent');
        this.submitted = true;
        this.checkInClicked = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.checkInClicked = false;
          return;
        }

        // Upper case the event code and trim any white space from the ends
        this.eventCode = this.eventCode.toUpperCase().trim()

        // First, get the conference details using the given code
        const eventService = new EventService(this.store)
        const requestedEvent = await eventService.GetEventByCode(this.eventCode)
        console.log(requestedEvent)
        // If no event was found...
        if (!requestedEvent) {
          this.messageDialog = {
            cancelBooking: false,
            message: 'Unable to find an event with this code.  Please check the code and try again.'
          }
          this.messageDialog.show = true
          this.checkInClicked = false;
          return;
        }
        // If the event is not LIVE mode
        if (![EVENT_STATUS.LIVE, EVENT_STATUS.COMPLETED, EVENT_STATUS.CERTIFICATESSENT].includes(requestedEvent.status)) {
          this.messageDialog = {
            cancelBooking: false,
            message: 'Unable to find an event with this code.  Please check the code and try again.'
          }
          this.messageDialog.show = true
          this.checkInClicked = false;
          return;
        }
        // If the event is not a conference
        if (requestedEvent.eventType !== EVENT_TYPE.CONFERENCE) {
          this.messageDialog = {
            cancelBooking: false,
            message: 'Unable to find an event with this code.  Please check the code and try again.'
          }
          this.messageDialog.show = true
          this.checkInClicked = false;
          return;
        }
        // Check that the event isn't oversubscribed
        try {
          const liveAttendeeCount = await eventService.GetEventBookedAttendeeCount(requestedEvent.id)
          console.log("Event live attendees:", liveAttendeeCount)
          console.log("Maxmimum attendee:", requestedEvent.maximumAttendees)

          if (liveAttendeeCount >= parseInt(requestedEvent.maximumAttendees)) {
            this.messageDialog = {
              cancelBooking: false,
              message: 'This event is oversubscribed.  Please contact the event organiser for more details.'
            }
            this.messageDialog.show = true
            this.checkInClicked = false;
            return;
          }
        } catch (err) {
          console.warn("Error when getting live attendee count;", err)
          this.messageDialog = {
            cancelBooking: false,
            message: 'There was an error when checking you into the event.  Please try again.'
          }
          this.messageDialog.show = true
          this.checkInClicked = false;
          return
        }
        // Check that the event is close to finishing
        const startCertificatesFromDateObject = new Date(requestedEvent.endDateTime)
        const currentDateObject = new Date()
        // Take 15 minutes off the end time
        startCertificatesFromDateObject.setMinutes(startCertificatesFromDateObject.getMinutes() - 15)
        // If the current datetime is before the start certificates from time, tell the user they are too early
        if (currentDateObject < startCertificatesFromDateObject) {
          this.messageDialog = {
            cancelBooking: false,
            message: 'You have tried to claim your certificate for this event too early.  Please wait until the end of the event'
          }
          this.messageDialog.show = true
          this.checkInClicked = false;
          return;
        }

        // Check if user has already added this event to their calendar and therefore has a booking
        console.log("Current Bookings:", this.$store.getters.bookings.items)
        console.log("Requested Event Id:", requestedEvent.id)
        const filteredBookings = this.$store.getters.bookings.items.filter((booking) => {
          return booking.event.id === requestedEvent.id
        })

        try {
          var bookingId
          console.log("filteredBookings:", filteredBookings)


          // If the user is already booked onto the event
          if (filteredBookings.length > 0) {
            // If the user is booked onto this course, but already has a certificate...
            if ([BOOKING_CERTIFICATE_STATUS.SENTDIGITALLY, BOOKING_CERTIFICATE_STATUS.SENTPHYSICALLY].includes(filteredBookings[0].certificateSent)) {
              // Show the user a message that they have already had a certificate for this event and should check their records
              this.messageDialog = {
                cancelBooking: false,
                message: 'You have already been issued with a certificate for this event.  Please check your records to view your certificate.'
              }
              this.messageDialog.show = true
              this.checkInClicked = false;
              return;
            }

            // If the user is booked onto this course but doesn't have a certificate yet, update the record
            const result = await this.UpdateConferenceBooking(filteredBookings[0])
            console.log("Update result:", result)
            bookingId = result.data.updateEventBooking.id
          }

          // If the user isn't booked onto this course, create a booking
          if (filteredBookings.length === 0) {
            const result = await this.CreateConferenceBooking(requestedEvent)
            console.log("Create result:", result)
            bookingId = result.data.createEventBooking.id
          }

          // Request the certificate is emailed to the user and the instructor
          await BookingService.GetCertificateForConference(requestedEvent.id, bookingId, this.eventCode)

          // Get new booking details for the user
          const userService = new UserService(this.$store)
          await userService.RefreshBookings()

        } catch (err) {
          console.warn("Error when creating a booking and certificate for the user;", err)
          // Show the user a message that they have been emailed a certificate
          this.messageDialog = {
            ...this.messageDialog,
            message: 'There was an error registering your attendance.  Please try again.',
            cancelBooking: false,
          }
          this.messageDialog.show = true
          this.checkInClicked = false;
          return;
        }

        // Show the user a message that they have been emailed a certificate
        this.messageDialog = {
          cancelBooking: false,
          message: 'Certificate sent to your registered email.'
        }
        this.messageDialog.show = true;
        this.checkInClicked = false;
      },

      // Create a booking for a conference event
      async CreateConferenceBooking(requestedEvent) {
        // This makes a booking for a conference
        try {
          const extraParameters = {
            status: BOOKING_STATUS.COMPLETED,
            attendance: BOOKING_ATTENDANCE.ATTENDED
          }
          return await BookingService.CreateBooking(this.$store.getters.user.id, requestedEvent.id, requestedEvent.providerId, extraParameters)
        } catch (err) {
          console.warn("Error when creating booking to conference event", err)
          this.messageDialog = {
            cancelBooking: false,
            message: 'Unable to get certificate.  Please try again.'
          }
          this.messageDialog.show = true
          return {}
        }
      },

      // Update a booking for a conference event
      async UpdateConferenceBooking(booking) {
        // This makes a booking for a conference
        const parameters = {
          id: booking.id,
          status: BOOKING_STATUS.COMPLETED,
          attendance: BOOKING_ATTENDANCE.ATTENDED
        }
        try {
          return await BookingService.UpdateBookingStatus(parameters)
        } catch (err) {
          console.warn("Error when updating booking to conference event", err)
          this.messageDialog = {
            cancelBooking: false,
            message: 'Unable to get certificate.  Please try again.'
          }
          this.messageDialog.show = true
          return {}
        }
      },


      OnToggleFilter() {
        this.ResetFilter(false);
        this.showFilter = !this.showFilter;
        if (this.showFilter == true) {
          window.dataLayer.push({
            'event': 'UserLanding-FilterOpen'
          })
          var self = this;
          setTimeout(function () { self.SetUpAutoComplete(); }, 100);
        }
      },
      SetUpAutoComplete() {
        var input = document.getElementById('address');
        console.log('input');
        console.log(input);
        /*
        this.autocomplete = new google.maps.places.Autocomplete(input);
        this.autocomplete.addListener('place_changed', () => {
          let place = this.autocomplete.getPlace();
          if (place) {
            this.eventFilter.address = place.name + ',' + place.formatted_address;
          }
          else {
            this.eventFilter.address = input.value;
          }
          console.log(this.eventFilter.address);
        });
        */
       // TODO: FUTURE: Restore the autocomplete functionality
      },
      ResetFilter(/*isSubmit*/) {
        this.eventFilter.free = false;
        this.eventFilter.paid = false;
        this.eventFilter.conference = false;
        this.eventFilter.lessThanOneHour = false;
        this.eventFilter.oneToFourHours = false;
        this.eventFilter.fourToEightHours = false;
        this.eventFilter.eightPlusHours = false;
        this.eventFilter.weekdays = false;
        this.eventFilter.weekends = false;
        /*
        this.eventFilter.lessThanFiveMiles = false;
        this.eventFilter.fiveToTenMiles = false;
        this.eventFilter.tenToTwentyMiles = false;
        this.eventFilter.greaterThanTwenty = false;
        this.eventFilter.lessThanTwoHundred = false;
        this.eventFilter.twoHundredToThreeHundred = false;
        this.eventFilter.greaterThanThreeHundred = false;
        this.eventFilter.startDate = '';
        this.eventFilter.startDateFormatted = '';
        this.eventFilter.endDateMenu = false;
        this.eventFilter.endDate = '';
        this.eventFilter.endDateFormatted = '';
        this.eventFilter.address = '';
        */
        this.eventFilter.submitted = false;
        /*
        if (isSubmit) {
          this.OnFilterClick();
        }
        */
      },

      CloseFilter() {
        this.showFilter = false
      },

      async GetAvatar() {
        try {
          const userService = new UserService(this.$store)
          const avatarSignedURL = await userService.GetUserAvatarSignedURL(this.$store.getters.user)
          this.avatarSignedURL = avatarSignedURL
        } catch (e) {
          return process.env.VUE_APP_placeholder_avatar_url
        }
      },
      async GetEventLogoSignedURL(event) {
        try {
          const eventService = new EventService(this.$store)
          const signedURL = await eventService.GetEventLogoSignedURL(event)
          return signedURL
        } catch (e) {
          return process.env.VUE_APP_placeholder_event_logo_url
        }
      },
      async OnUserRejectTermsAndConditions() {
        await Auth.signOut()
        console.log("user is now signed out")
        this.$store.commit(StoreActions.RESET_AFTER_LOGOUT);
        this.$router.push({ name: 'Login' });
      },
      async OnUserAcceptTermsAndConditions() {
        try {
          const currentDateTime = new Date()
          const userService = new UserService(this.$store)
          const result = await userService.UpdateUserDetails({
            id: this.$store.getters.user.id,
            lastAcceptedTandCDateTime: currentDateTime.toISOString()
          })
          if (result.status === "FAILED") {
            throw result.error
          }
          this.accepttandcdialog.show = false
        } catch (err) {
          this.messageDialog.cancelBooking = false
          this.messageDialog.message = 'Error when saving T&C acceptance confirmation.  Please try again.'
          this.messageDialog.show = true
        }
      }
    },

    computed: {
      pages() {
        try {
          if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 1
          return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        } catch (err) {
          console.warn("Error getting number of pages for pagination;", err)
          return 1
        }
      },
      username: function() {
        try { return this.$store.getters.cognito.attributes.sub } catch (e) { return '' }
      },
      firstName: function() {
        try { return this.$store.getters.user.firstName } catch (e) { return '' }
      },
      lastName: function() {
        try { return this.$store.getters.user.lastName } catch (e) { return '' }
      },
      avatar: function() {
        try { return this.avatarSignedURL } catch (e) { return '' }
      },
      emailAddress: function() {
        try { return this.$store.getters.user.emailAddress } catch (e) { return '' }
      },
      phoneNumber: function() {
        try { return this.$store.getters.user.userDetail.phoneNumber } catch (e) { return '' }
      },
      userLocation: function() {
        try { return this.$store.getters.user.userDetail.location } catch (e) { return '' }
      },
      jobTitle: function() {
        try { return this.$store.getters.user.userDetail.jobTitle } catch (e) { return '' }
      },
      employerName: function() {
        try { return this.$store.getters.employer.name } catch (e) { return '' }
      },
      CompletedMinutes: function() {
        try { return this.$store.getters.completedMinutes } catch (e) { return 0 }
      },
      PlannedMinutes: function() {
        try { return this.$store.getters.plannedMinutes } catch (e) { return 0 }
      },
      CPDAccreditedMinutes: function() {
        try { return this.$store.getters.cpdAccreditedMinutes } catch (e) { return 0 }
      },
      TotalMinutes: function() {
        try { return this.CompletedMinutes + this.PlannedMinutes } catch (e) { return 0 }
      },
      events: function() {
        try { return this.$store.getters.userFutureLiveEvents } catch (e) { return [] }
      },
      eventsNextToken: function() {
        try { return this.$store.getters.events.nextToken } catch (e) { return null }
      },
      upcomingBookings: function() {
        try {
          const upcomingBookings = this.$store.getters.upcomingBookings
          return upcomingBookings.filter((booking) => {
            return booking.event.eventType !== EVENT_TYPE.CONFERENCE
          })
        } catch (e) { return [] }
      },
      activeFilterCategories: function() {
        const type = [
          this.eventFilter.free ? EVENT_TYPE.FREE : null,
          this.eventFilter.paid ? EVENT_TYPE.PAID : null,
          this.eventFilter.conference ? EVENT_TYPE.CONFERENCE : null
        ].filter((item) => item != null)

        const duration = [
          this.eventFilter.lessThanOneHour ? EVENT_DURATION.LESSTHANONEHOUR : null,
          this.eventFilter.oneToFourHours ? EVENT_DURATION.ONETOFOURHOURS : null,
          this.eventFilter.fourToEightHours ? EVENT_DURATION.FOURTOEIGHTHOURS : null,
          this.eventFilter.eightPlusHours ? EVENT_DURATION.EIGHTPLUSHOURS : null,
        ].filter((item) => item != null)

        const schedule = [
          this.eventFilter.weekdays ? EVENT_SCHEDULE.WEEKDAYS : null,
          this.eventFilter.weekends ? EVENT_SCHEDULE.WEEKENDS : null
        ].filter((item) => item != null)

        /*
        const location = [
          this.eventFilter.lessThanFiveMiles ? EVENT_LOCATION.LESSTHANFIVEMILES : null,
          this.eventFilter.fiveToTenMiles ? EVENT_LOCATION.FIVETOTENMILES : null,
          this.eventFilter.tenToTwentyMiles ? EVENT_LOCATION.TENTOTWENTYMILES : null,
          this.eventFilter.greaterThanTwenty ? EVENT_LOCATION.TWENTYPLUSMILES : null
        ].filter((item) => item != null)

        const price = [
          this.eventFilter.lessThanTwoHundred ? EVENT_PRICE.LESSTHANTWOHUNDREDPOUNDS : null,
          this.eventFilter.twoHundredToThreeHundred ? EVENT_PRICE.TWOHUNDREDTOTHREEHUNDREDPOUNDS : null,
          this.eventFilter.greaterThanThreeHundred ? EVENT_PRICE.THREEHUNDREDPLUSPOUNDS : null
        ].filter((item) => item != null)

        const startDate = [
          this.eventFilter.startDate.length > 0 ? this.eventFilter.startDate : null
        ].filter((item) => item != null)

        const endDate = [
          this.eventFilter.endDate.length > 0 ? this.eventFilter.endDate : null
        ].filter((item) => item != null)
        */

        return {
          type,
          duration,
          schedule,
          //location,
          //price,
          //startDate,
          //endDate
        }

      }
    },

    mounted() {
      try {
        this.$parent.$parent.$parent.refreshMenu('home')
      } catch (err) {
        //eslint-disable-next-line
      }
      this.is_data_fetched = true;
      // Set up the pagination
      this.pagination = {
        ...this.pagination,
        totalItems: this.$store.getters.userFutureLiveEvents.length,
        descending: false,
        sortBy: 'startDateTime',
        sortDesc: true
      }
      this.GetAvatar()

      // If the user hasn't accepted T&Cs yet, show them the T&Cs
      try {
        const lastAcceptedTandCDateTime = new Date(this.$store.getters.lastAcceptedTandCDateTime)
        const lastIssuedTandCDateTime = new Date(LAST_ISSUED_TANDC_DATETIME_STRING)
        if (lastAcceptedTandCDateTime < lastIssuedTandCDateTime) {
          this.accepttandcdialog.show = true
        }
      } catch (err) {
        console.warn("Unable to compare dates for last T&C acceptance;", err)
      }
    }

  }
</script>


<style scoped>

  * {
    font-family: basefont;
  }

  .v-btn {
    text-decoration: none;
    text-transform: capitalize;
  }

  .container-rounded {
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 5px;
    margin: 0 0 10px;
  }

  .container-rounded-grey {
    border-radius: 10px;
    background-color: rgba(243, 246,248, 0.7);
    padding: 5px;
    width: 100%;
  }


  .container-rounded-grey > .layout {
    width: 100%;
  }

  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  .ABC_in_Scaffolding {
    opacity: 1;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(39,62,75,1);
    letter-spacing: 0.2px;
  }

  .Duration__1_hour {
    opacity: 1;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(139,139,139,1);
    letter-spacing: 0.2px;
  }

  .right-align-button {
    margin-left: auto;
    margin-right: 0;
  }

  .event-button-container {
    align-items: flex-end;
    justify-content: center;
    display: flex;
    flex: 0 0 1;
    margin-bottom: 0.33em;
  }

  .img-circle {
    border-radius: 60%;
    box-sizing: border-box;
  }

  .achievements {
    margin: 0;
    padding: 0;
    list-style: none;
    border-radius: 6px;
    background: #FFA374;
  }

    .achievements li {
      padding: 2px 10px;
      color: #fff;
      width: 100%;
    }

      .achievements li span {
        float: right;
      }

  .filter-panel {
    margin-right: 60px;
    margin-left: 30px;
    margin-top: -25px;
    border-radius: 6px;
    margin-bottom: 10px;
  }

    .filter-panel label, .filter-panel p {
      margin: 0;
    }

    .filter-panel label {
      font-size: 13px;
    }

    .filter-panel:before {
      content: '';
      border-left-color: #808080 !important;
      width: 0px;
      height: 0px;
      border: solid 10px transparent;
      position: absolute;
      left: 100%;
      top: 5px;
      z-index: 1;
    }

    .filter-panel:after {
      content: '';
      border-left-color: #ffffff !important;
      width: 0px;
      height: 0px;
      border: solid 10px transparent;
      position: absolute;
      left: 100%;
      top: 5px;
      z-index: 2;
    }

  .scheduled-event div[role="listitem"] {
    background: #f5f5f5;
    padding: 0;
    margin-bottom: 7px;
    box-shadow: 0 0 3px 0px #ccc;
    width: 100%;
  }

    .scheduled-event div[role="listitem"] * {
      height: auto !important;
      display: block;
      padding: 0 10px;
      color: #019B91;
    }

  .mpp-tile {
    align-items: center;
    color: inherit;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    height: auto;
    margin: 0;
    margin-bottom: 8px;
    padding: 0 16px;
    position: relative;
    text-decoration: none;
    transition: background .3s cubic-bezier(.25,.8,.5,1);
  }
</style>

<template>
  <v-card>
    <v-card-title>
      <div class="headline" style="color:rgba(254,138,125,1);">Add Employee</div>
    </v-card-title>
    <v-divider style="border-width:1px;"></v-divider>
    <v-layout justify-center layout style="margin-bottom: 2em;">
      <v-flex horizontal-flex>
        <label class="control-label single-line" for="emailSearch">Find Employee By Email</label>
        <input type="text" class="form-control" id="emailSearch" v-model="emailSearch">
        <v-btn small color=rgb(0,171,173) @click="FindUserByEmailAddress">Search</v-btn>
      </v-flex>
    </v-layout>

    <v-layout layout error-row v-if="errorFindingUser">
      <p>No user with that email address was found.  Have they registered for MyProPass yet?</p>
    </v-layout>

    <v-layout layout error-row v-if="foundUserAlreadyInEmployer">
      <p>This individual is already part of your organisation.</p>
    </v-layout>



    <v-layout layout user-returned-details v-if="userWasFound">
      <div class="user-details-row">
        <event-logo v-bind:imageKey="foundUserAvatarImageKey" level="protected" :imageType="IMAGE_TYPES.AVATAR" style="border: 2px solid #fafafa; position: relative; height: 80px; width: 80px; background: #fafafa"/>
        <div>
          <p>{{ foundUser.firstName }} {{ foundUser.lastName }}</p>
          <p>{{ foundUser.userDetail.location }}</p>
        </div>
      </div>



      <!-- User Details Container -->
      <h5 style="margin: 2em 0 0.5em 0;" v-if="userWasFound">New Employee Details</h5>
      <div class="user-details-container" v-if="userWasFound">

        <!-- Role Name Row -->
        <label class="control-label single-line" for="roleName">Employee Job Title</label>
        <input type="text" class="form-control" id="roleName" v-model="additionalDetails.roleName" :disabled="foundUserAlreadyInEmployer">

        <!-- Access Rights Section -->
        <label class="control-label single-line" for="isCourseInstructor">Instructor?</label>
        <input type="checkbox" id="isCourseInstructor" v-model="additionalDetails.accessRights.isCourseInstructor" :disabled="foundUserAlreadyInEmployer">
        <label class="control-label single-line" for="isAdministrator">Has Admin Access?</label>
        <input type="checkbox" id="isAdministrator" v-model="additionalDetails.accessRights.isAdministrator" :disabled="foundUserAlreadyInEmployer">
      </div>

      <!-- Course Instructor Details Container -->
      <v-divider v-if="additionalDetails.accessRights.isCourseInstructor" style="border-width:1px;" />
      <h5 v-if="additionalDetails.accessRights.isCourseInstructor" style="margin: 1em 0 0.5em 0;">Instructor Details</h5>
      <div class="course-instructor-details" v-if="additionalDetails.accessRights.isCourseInstructor">

        <label class="control-label single-line" for="isActive">Active</label>
        <input type="checkbox" id="isActive" v-model="additionalDetails.courseInstructorDetails.isActive" :disabled="foundUserAlreadyInEmployer">

        <label class="control-label single-line">About Instructor</label>
        <vue-editor v-model="additionalDetails.courseInstructorDetails.aboutText" :disabled="foundUserAlreadyInEmployer"/>

      </div>

      <!-- Group Selection Section -->
      <fieldset class="group-fieldset">
        <legend>Groups</legend>
        <template v-for="group in employerGroups">
          <div class="group-row" v-bind:key="group.id">
            <input type="checkbox" :value='group.id' :disabled="foundUserAlreadyInEmployer" @change="groupChanged(group)">
            <label class="control-label single-line">{{ group.name }}</label>
          </div>
        </template>
      </fieldset>

    </v-layout>


    <v-layout justify-end class="footer">
      <!--
      <v-flex xs12 text-right>
        <div v-if="editInstructorSubmitted && $v.editInstructor.$invalid" class="text-danger" style="float:right">
          <small>Please review your input. Errors are highlighted in red.</small>
        </div>
      </v-flex>
    -->
    </v-layout>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small color=rgb(0,171,173) @click="CloseEditInstructorDialog(false)">Cancel</v-btn>
      <v-btn color="rgb(0,171,173)" small @click="AddUserAsEmployee" class='add-button' :disabled='addButtonDisabled'>Add Employee</v-btn>
      <!--<v-btn small color="rgb(0,171,173)" @click="saveInstructor">Save</v-btn>-->
    </v-card-actions>

  </v-card>
</template>


<script>

  // TODO: FUTURE: Indication that the email address is not used on the platform; invite the person and add them to the invites list
  // TODO: FUTURE: use "errorFindingUser"
  // TODO: FUTURE: use "errorAddingEmployee"
  // TODO: FUTURE: Add error checking


  import UserService from '@/services/user.service'
  import EmployerService from '@/services/employer.service'
  import EventLogo from './eventlogo-container'
  import { IMAGE_TYPES } from '@/constants/ImageConstants'
  import { VueEditor } from "vue2-editor"

  export default {

    props: {
      employees: {
        type: Array,
        default: () => { return [] }
      },
      pendingEmployees: {
        type: Array,
        default: () => { return [] }
      },
      closePopover: Function
    },

    components: {
      'event-logo': EventLogo,
      VueEditor,
    },

    data() {
      return {
        emailSearch: '',
        userWasFound: false,
        errorFindingUser: false,
        addingEmployee: false,
        errorAddingEmployee: false,
        foundUser: {
          userDetail: {}
        },
        additionalDetails: {
          roleName: '',
          accessRights: {
            isAdministrator: false,
            isCourseInstructor: false,
          },
          courseInstructorDetails: {
            phone: '',
            email: '',
            aboutText: '',
            isActive: true
          },
          emailAddress: ''
        },
        IMAGE_TYPES,
        employerGroups: [],
        selectedGroups: [],
      }
    },


    methods: {
      CloseEditInstructorDialog(reloadRequired) {
        this.emailSearch = ""
        this.userWasFound = false,
        this.errorFindingUser = false,
        this.addingEmployee = false,
        this.errorAddingEmployee = false,
        this.foundUser = {
          userDetail: {}
        }
        this.closePopover(reloadRequired)
      },

      async AddUserAsEmployee() {
        console.log("Adding user with details:", this.foundUser)
        this.addingEmployee = true;
        this.errorAddingEmployee = false
        // Add user as an employee
        try {
          // If this user is already an employee, throw an error
          if (this.foundUserAlreadyInEmployer) {
            throw new Error("User is already an employee")
          }
          const employerService = new EmployerService(this.$store)
          this.additionalDetails.emailAddress = this.foundUser.emailAddress
          const result = await employerService.AddPendingEmployee(this.foundUser.id, this.$store.getters.employer.id, this.additionalDetails, this.selectedGroups)
          console.log(result)
          this.CloseEditInstructorDialog(true)
        } catch (err) {
          console.log("Error adding employee:", err)
          this.errorAddingEmployee = true
        }
        this.addingEmployee = false
      },

      async FindUserByEmailAddress() {
        this.errorFindingUser = false
        const emailAddress = this.emailSearch
        try {
          const userService = new UserService(this.$store)
          const result = await userService.FindUserByEmailAddress(emailAddress)
          if (result.length === 0) {
            this.foundUser = {
              userDetail: {}
            }
            this.userWasFound = false
            this.errorFindingUser = true
            return
          }
          this.foundUser = result[0]
          this.userWasFound = true
        } catch (err) {
          this.foundUser = {
            userDetail: {}
          }
          this.userWasFound = false
          this.errorFindingUser = true
        }
      },

      groupChanged(group) {
        // If this group has already been selected, remove it
        if (this.selectedGroups.includes(group.id)) {
          this.selectedGroups = this.selectedGroups.filter(g => g !== group.id)
        } else {
          this.selectedGroups.push(group.id)
        }
        console.log("Selected groups:", this.selectedGroups)
      },
    },

    computed: {
      foundUserAvatarImageKey: function() {
        try {
          return this.foundUser.userDetail.avatar.imageKey
        } catch (err) {
          return process.env.VUE_APP_placeholder_avatar_url
        }
      },
      employeeUserIds: function() {
        try {
          return this.employees.map(employee => employee.user.id)
        } catch (err) {
          console.warn("Error getting employee Ids:", err)
          return []
        }
      },
      pendingEmployeeUserIds: function() {
        try {
          return this.pendingEmployees.map(employee => employee.userId)
        } catch (err) {
          console.warn("Error getting pending employee Ids:", err)
          return []
        }
      },
      foundUserAlreadyInEmployer: function() {
        try {
          if (this.employeeUserIds.includes(this.foundUser.id)) { return true }
          if (this.pendingEmployeeUserIds.includes(this.foundUser.id)) { return true }
          return false
        } catch (err) {
          return true
        }
      },
      addButtonDisabled: function() {
        try {
          if (!this.foundUser.firstName) { return true }
          if (this.foundUserAlreadyInEmployer) { return true }
          return false
        } catch (err) {
          return true
        }
      }
    },

    async mounted() {
      const employerService = new EmployerService(this.$store)
      this.employerGroups = await employerService.GetGroups(this.$store.getters.employer.id)
    }

  }
</script>




<style scoped>

  .layout {
    display: block;
    margin-bottom: 2em;
    max-width: 90%;
    margin: auto;
  }

  .horizontal-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .single-line {
    white-space: nowrap;
  }

  label {
    padding-right: 1em;
  }

  .user-returned-details {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
  }

  .error-row {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    color: darkred;
    font-weight: bold;
  }

  .user-details-row {
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    grid-gap: 2em;
  }

  .user-details-row p:last-of-type {
    margin-bottom: 0;
  }

  .add-button {
    margin-left: auto;
    margin-right: 0;
  }

  .user-details-container {
    margin: 2em 0;
  }

  .form-row {
    display: grid;
    grid-auto-flow: column;
    justify-items: start;
    align-items: center;
    justify-content: start;
    grid-gap: 1em;
    width: 100%;
    margin-bottom: 0.5em;
  }

  .text-form-row {
    grid-template-columns: auto 1fr;
  }

  .course-instructor-details,
  .user-details-container {
    display: grid;
    grid-gap: 0.5em 1em;
    grid-template-columns: 1fr 4fr;
    margin: 0;
    margin-bottom: 2em;
    align-items: center;
  }

  .group-row {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1em;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 0.5em;
  }

  .group-row label {
    margin: 0;
  }

</style>

<style>
  #quill-container {
    height: auto;
  }
</style>

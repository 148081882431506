
import { API, Storage, graphqlOperation } from 'aws-amplify';
import * as Mutations from '@/graphql/mutations';

class ExperienceCertificateService {

  constructor(store) {
    this.$store = store
  }

  /**
  * @function
  * CheckExperienceCertificateExists      Checks that a certificate exists on the S3 bucket
  * @param  {object} certificateDetails   The object of the ExperienceCertificate entry in the database (inc User details)
  * @return {boolean}
  */
  async CheckExperienceCertificateExists(certificateDetails) {
    try {
      if (!certificateDetails.experienceEventId) { throw new Error("certificateDetails.experienceEventId is required") }
      if (!certificateDetails.user) { throw new Error("certificateDetails.user is required") }
      if (!certificateDetails.user.id) { throw new Error("certificateDetails.user.id is required") }
      if (!certificateDetails.user.identityId) { throw new Error("certificateDetails.user.identityId is required") }
      const key = `${certificateDetails.experienceEventId}_${certificateDetails.user.id}.pdf`
      const result = await Storage.list(key, {
        level: 'protected',
        identityId: certificateDetails.user.identityId, // the identityId of that user
        pageSize: 10
      })
      // console.log({ resultResults: result.results })
      return result.results.length > 0
    } catch (err) {
      console.warn("Error checking for certificate existence;", err)
      return false
    }
  }


  /**
  * @function
  * @name GetExperienceCertificateSignedURL   Returns a signed URL that points to a specified certificate
  * @param  {object} certificateDetails       The object of the ExperienceCertificate entry in the database (inc User details)
  * @return {string}
  */
  async GetExperienceCertificateSignedURL(certificateDetails) {
    if (!certificateDetails.experienceEventId) { throw new Error("certificateDetails.experienceEventId is required") }
    if (!certificateDetails.user) { throw new Error("certificateDetails.user is required") }
    if (!certificateDetails.user.id) { throw new Error("certificateDetails.user.id is required") }
    if (!certificateDetails.user.identityId) { throw new Error("certificateDetails.user.identityId is required") }
    // Build the parameters for the signed URL
    const key = `${certificateDetails.experienceEventId}_${certificateDetails.user.id}.pdf`
    var parameters = {
      expires: 900,
      level: 'protected',
      identityId: certificateDetails.user.identityId // the identityId of that user
    }
    // Fetch the link
    //console.log("GetCertificateSignedURL key:", key)
    //console.log("GetCertificateSignedURL parameters:", parameters)
    const result = await Storage.get(key, parameters);
    return result
  }


  /**
  * @function
  * @name GetPreviewCertificate             Returns a signed URL that points to a specified certificate
  * @param  {object} certificateDetails     An object containing all the required information to generate an example certificate
  * @return {string}
  */
  async GetPreviewCertificate(certificateDetails) {
    console.log("certificateDetails:", certificateDetails)
    if (!certificateDetails.providerId) { throw new Error("certificateDetails.providerId is required") }
    if (!certificateDetails.title) { throw new Error("certificateDetails.title is required") }
    if (!certificateDetails.certificateTemplateId) { throw new Error("certificateDetails.certificateTemplateId is required") }
    if (!certificateDetails.startDateTime) { throw new Error("certificateDetails.startDateTime is required") }
    if (!certificateDetails.endDateTime) { throw new Error("certificateDetails.endDateTime is required") }
    if (!certificateDetails.issueDateTime) { throw new Error("certificateDetails.issueDateTime is required") }
    try {
      const response = await API.graphql(graphqlOperation(Mutations.getExampleExperienceCertificate, certificateDetails));
      //console.log("response:", response)
      const result = response.data.getExampleExperienceCertificate.body
      //console.log("result;", result)
      // If there is an error getting the string, tell the user there was an error
      if (!result || result === "ERROR") {
        console.warn("There was an error getting the example experience certificate")
        return null
      }
      // Build the download link
      var downloadLink = document.createElement('a');
      downloadLink.setAttribute('href', `data:application/pdf;base64,${result}`)
      downloadLink.setAttribute('download', `Example_experience_certificate.pdf`)
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      // Download the certificate
      downloadLink.click();
      document.body.removeChild(downloadLink);
      // Return success
      return "SUCCESS"
    } catch (err) {
      console.warn("Error getting example experience certificate:", err)
      return null
    }
  }

  /**
  * @function
  * @name IssueCertificates                 Issues all the certificates to the specified professionals
  * @param  {object} certificateDetails     An object containing all the required information to generate an example certificate
  * @return {string}
  */
  async IssueCertificates(certificateDetails) {
    console.log("certificateDetails:", certificateDetails)
    if (!certificateDetails.providerId) { throw new Error("certificateDetails.providerId is required") }
    if (!certificateDetails.experienceEventId) { throw new Error("certificateDetails.experienceEventId  is required") }
    if (!certificateDetails.certificateTemplateId) { throw new Error("certificateDetails.certificateTemplateId is required") }
    if (!certificateDetails.title) { throw new Error("certificateDetails.title is required") }
    if (!certificateDetails.startDateTime) { throw new Error("certificateDetails.startDateTime is required") }
    if (!certificateDetails.endDateTime) { throw new Error("certificateDetails.endDateTime is required") }
    if (!certificateDetails.subjects) { throw new Error("certificateDetails.subjects is required") }
    if (certificateDetails.subjects.length === 0) { throw new Error("certificateDetails.subjects must have at least one subject") }
    try {
      const response = await API.graphql(graphqlOperation(Mutations.issueExperienceCertificates, certificateDetails));
      const result = response.data.issueExperienceCertificates.body
      // If there is an error getting the string, tell the user there was an error
      if (!result || result === "ERROR") {
        console.warn("There was an error issuing the experience certificates")
        return null
      }
      return "SUCCESS"
    } catch (err) {
      console.warn("Error issuing experience certificates:", err)
      return null
    }
  }

}

export default ExperienceCertificateService

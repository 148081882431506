
export const EVENT_PRICE_STATUS = {
  LIVE: "LIVE",
  DELETED: "DELETED"
}

export const DEFAULT_PRICE_ITEM = {
  id: "0",
  eventId: "0",
  title: "Standard Price",
  description: "Standard Price",
  priceGBP: "0",
  priceUSD: "0",
  priceEUR: "0",
  status: EVENT_PRICE_STATUS.LIVE,
  groupsCanRead: ["USER"],
  groupsFullControl: []
}


export const EVENT_PRICE_SERVER_STATUS = {
  TO_CREATE: "TO_CREATE",
  TO_UPDATE: "TO_UPDATE",
  TO_DELETE: "TO_DELETE",
  NO_CHANGE: "NO_CHANGE",
}

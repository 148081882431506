<template>
  <v-card>
    <loading :active.sync="isLoading" is-full-page loader="dots"></loading>
    <v-card-title>
      <div v-if="displayType === PRICE_WINDOW_OPTIONS.CREATE" class="headline" style="color:rgba(254,138,125,1);">Add Price</div>
      <div v-if="displayType === PRICE_WINDOW_OPTIONS.UPDATE" class="headline" style="color:rgba(254,138,125,1);">Edit Price</div>
    </v-card-title>
    <v-divider style="border-width:1px;"></v-divider>

    <v-layout v-if="displayType === PRICE_WINDOW_OPTIONS.CREATE" class="price-form" justify-center layout style="margin-bottom: 2em;">
      <td class="form-row">
        <label class="col-sm-4 control-label" for="title">Title</label>
        <input id="title" type="text" class="form-control col-sm-8" v-model="title">
      </td>
      <td class="form-row">
        <label class="col-sm-4 control-label" for="description">Description</label>
        <input id="description" type="text" class="form-control col-sm-8" v-model="description">
      </td>
      <td class="form-row">
        <label class="col-sm-4 control-label" for="priceGBP">Price (GBP)</label>
        <input id="priceGBP" type="text" class="form-control col-sm-8" v-model="priceGBP">
      </td>
      <!--<td>${{ props.item.priceUSD }}</td>-->
      <!--<td>€{{ props.item.priceEUR }}</td>-->
    </v-layout>

    <v-layout v-if="displayType === PRICE_WINDOW_OPTIONS.UPDATE" class="price-form" justify-center layout style="margin-bottom: 2em;">
      <td class="form-row">
        <label class="col-sm-4 control-label" for="title">Title</label>
        <input id="title" type="text" class="form-control col-sm-8" v-model="title">
      </td>
      <td class="form-row">
        <label class="col-sm-4 control-label" for="description">Description</label>
        <input id="description" type="text" class="form-control col-sm-8" v-model="description">
      </td>
      <td class="form-row">
        <label class="col-sm-4 control-label" for="priceGBP">Price (GBP)</label>
        <input id="priceGBP" type="text" class="form-control col-sm-8" v-model="priceGBP">
      </td>
      <!--<td>${{ props.item.priceUSD }}</td>-->
      <!--<td>€{{ props.item.priceEUR }}</td>-->
    </v-layout>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small color=rgb(0,171,173) @click="CloseSelf">Cancel</v-btn>
      <!--<v-btn color="rgb(0,171,173)" small @click="AddUserAsEmployee" class='add-button'>Add Employee</v-btn>-->
      <v-btn v-if="displayType === PRICE_WINDOW_OPTIONS.CREATE" small color="rgb(0,171,173)" @click="createPrice">Create</v-btn>
      <v-btn v-if="displayType === PRICE_WINDOW_OPTIONS.UPDATE" small color="rgb(0,171,173)" @click="saveChanges">Save</v-btn>
    </v-card-actions>

    <v-dialog id="messageDialog" persistent v-model="messageDialog.show" transition="dialog-transition" max-width="450">
      <v-card>
        <v-card-text>
          <v-flex class="pa-3">
            <div style="font-weight:bold;" v-html="messageDialog.message" />
          </v-flex>
        </v-card-text>
        <v-card-actions class="text-right" style="display: block">
          <v-btn @click="CloseMessageDialog" small color=rgb(0,171,173)>Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>


<script>

  //import UserService from '../services/user.service'
  //import EmployerService from '../services/employer.service'

  import Event from '@/models/event.model'
  import EventPrice from '@/models/eventprice.model'
  import { PRICE_WINDOW_OPTIONS } from '@/constants/PageParameterConstants'


  const PRICE_TABLE_HEADERS = [
    { text: 'Title',        align: 'left', value: 'title',        sortable: false },
    { text: 'Description',  align: 'left', value: 'description',  sortable: false },
    { text: 'Price (GBP)',  align: 'left', value: 'priceGBP',     sortable: false },
    /*
    { text: 'Price (USD)',  align: 'left', value: 'priceUSD',     sortable: false },
    { text: 'Price (EUR)',  align: 'left', value: 'priceEUR',     sortable: false },
    */
  ]

  export default {

    props: {
      displayType: String,
      eventObject: Event,
      priceOption: EventPrice,
      closePopover: Function,
    },

    components: {
    },

    data() {
      return {
        originalPriceOption: {},
        newPrice: new EventPrice(),
        values: {
          title: ''
        },
        isLoading: false,
        messageDialog: {
          show: false,
          message: ""
        },
        PRICE_WINDOW_OPTIONS,
        PRICE_TABLE_HEADERS
      }
    },

    watch: {
      eventObject: function () {
        this.newPrice.setField('eventId', this.eventObject.getField('id'))
      }
    },

    methods: {
      CloseSelf() {
        this.closePopover(false)
      },
      CloseMessageDialog() {
        this.messageDialog.show = false
      },
      async createPrice() {
        console.log("create price requested")
        this.isLoading = true
        const eventPriceToAdd = new EventPrice(this.newPrice)
        try {
          await this.eventObject.addPriceOptionFromEventPrice(eventPriceToAdd)
          this.isLoading = false
          this.closePopover(false)
        } catch (err) {
          console.warn("Error adding price;", err)
          this.messageDialog.message = "There was an error creating the price.  Please try again."
          this.messageDialog.show = true
          this.isLoading = false
        }
      },
      async saveChanges() {
        console.log("changes save requested")
        this.isLoading = true
        const eventPriceToAdd = new EventPrice(this.newPrice)
        try {
          if (this.newPrice.getId() === "0") {
            await this.eventObject.addPriceOptionFromEventPrice(eventPriceToAdd, true)
          } else {
            await this.eventObject.updatePriceOptionFromEventPrice(eventPriceToAdd)
          }
          console.log("after edit, the price options are:", this.eventObject.getPriceOptions(true))
          this.closePopover(false)
        } catch (err) {
          console.warn("Error adding price;", err)
          this.messageDialog.message = "There was an error updating the price.  Please try again."
          this.messageDialog.show = true
          this.isLoading = false
        }
      }
    },


    computed: {
      // Price Title
      title: {
        get: function() {
          return this.newPrice.getField("title")
        },
        set: function(newValue) {
          //console.log(`Changing title to: ${newValue}`)
          this.newPrice.setField("title", newValue)
        }
      },
      // Price Description
      description: {
        get: function() {
          return this.newPrice.getField("description")
        },
        set: function(newValue) {
          //console.log(`Changing description to: ${newValue}`)
          this.newPrice.setField("description", newValue)
        }
      },
      // Price in GBP
      priceGBP: {
        get: function() {
          return this.newPrice.getField("priceGBP")
        },
        set: function(newValue) {
          //console.log(`Changing priceGBP to: ${newValue}`)
          this.newPrice.setField("priceGBP", newValue)
        }
      }
    },

    mounted() {
      console.log("event price popover mounted")
      console.log("existing priceOption:", this.priceOption)
      console.log("Event price options:", this.eventObject.getPriceOptions(true))
      console.log("existing event id:", this.eventObject.getField('id'))
      // If this is an update price request, copy the existing price so it can be edited
      if (this.displayType === PRICE_WINDOW_OPTIONS.UPDATE) {
        this.newPrice = new EventPrice(this.priceOption)
        console.log("new pricing option after duplication:", this.newPrice)
        return
      }
      // If this is a new event, then create a new price object
      this.newPrice = new EventPrice()
      this.newPrice.setField('eventId', this.eventObject.getField('id'))
      console.log("new pricing option from scratch:", this.newPrice)
    },

    beforeDestroy() {
      console.log("price popover being destroyed")
    }

  }
</script>




<style scoped>

  .layout {
    display: block;
    margin-bottom: 2em;
    max-width: 90%;
    margin: auto;
  }

  .horizontal-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .single-line {
    white-space: nowrap;
  }

  label {
    padding-right: 1em;
  }

  .user-returned-details {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
  }

  .error-row {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    color: darkred;
    font-weight: bold;
  }

  .user-details-row {
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    grid-gap: 2em;
  }

  .user-details-row p:last-of-type {
    margin-bottom: 0;
  }

  .add-button {
    margin-left: auto;
    margin-right: 0;
  }

  .user-details-container {
    margin: 2em 0;
  }

  .form-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 0.5em;
  }

  .text-form-row {
    grid-template-columns: auto 1fr;
  }

  .course-instructor-details,
  .user-details-container {
    display: grid;
    grid-gap: 0.5em 1em;
    grid-template-columns: 1fr 4fr;
    margin: 0;
    margin-bottom: 2em;
    align-items: center;
  }

  .price-form {
    width: 100%;
    flex-direction: column;
    display: flex;
  }

</style>

<style>
  #quill-container {
    height: auto;
  }
</style>

export const createEventBooking = /* GraphQL */ `
  mutation CreateEventBooking(
    $input: CreateEventBookingInput!
    $condition: ModelEventBookingConditionInput
  ) {
    createEventBooking(input: $input, condition: $condition) {
      id
      userId
      eventId
      event {
        id
        title
        description
        provider {
          id
          name
          description
          logo {
            id
            name
            imageKey
          }
          isCourseProvider
        }
        eventLogo {
          id
          name
          imageKey
        }
        startDateTime
        endDateTime
      }
    }
  }
`;
export const updateEventBooking = /* GraphQL */ `
  mutation UpdateEventBooking(
    $input: UpdateEventBookingInput!
    $condition: ModelEventBookingConditionInput
  ) {
    updateEventBooking(input: $input, condition: $condition) {
      id
      userId
      eventId
      status
      event {
        title
        startDateTime
      }
    }
  }
`;
export const updateEventLogo = /* GraphQL */ `
  mutation UpdateEventLogo(
    $input: UpdateEventLogoInput!
    $condition: ModelEventLogoConditionInput
  ) {
    updateEventLogo(input: $input, condition: $condition) {
      id
      name
      imageKey
      status
    }
  }
`;

export const updateAccreditationLogo = /* GraphQL */ `
  mutation UpdateAccreditationLogo(
    $input: UpdateAccreditationLogoInput!
    $condition: ModelAccreditationLogoConditionInput
  ) {
    updateAccreditationLogo(input: $input, condition: $condition) {
      id
      employerId
      name
      imageKey
      status
    }
  }
`;

export const updateSignatures = /* GraphQL */ `
  mutation UpdateSignatures(
    $input: UpdateSignaturesInput!
    $condition: ModelSignaturesConditionInput
  ) {
    updateSignatures(input: $input, condition: $condition) {
      id
      employerId
      name
      imageKey
      status
    }
  }
`;

export const createEmployeeRole = /* GraphQL */ `
  mutation CreateEmployeeRole(
    $input: CreateEmployeeRoleInput!
    $condition: ModelEmployeeRoleConditionInput
  ) {
    createEmployeeRole(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deletePendingEmployee = /* GraphQL */ `
  mutation DeletePendingEmployee(
    $input: DeletePendingEmployeeInput!
    $condition: ModelPendingEmployeeConditionInput
  ) {
    deletePendingEmployee(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteEmployeeRole = /* GraphQL */ `
  mutation DeleteEmployeeRole(
    $input: DeleteEmployeeRoleInput!
    $condition: ModelEmployeeRoleConditionInput
  ) {
    deleteEmployeeRole(input: $input, condition: $condition) {
      id
    }
  }
`;
